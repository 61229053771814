import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  limit = 10

  directory
  geopoints

  snack ="This is a test"
  show =false

  constructor() { }

  showSnack(message){
    this.snack=message;
    this.show=true;
    setTimeout(()=>{
      this.show=false;
    },3000);
  }
}
