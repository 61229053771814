import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AssetsService } from 'src/app/services/assets/assets.service';
import { MiscService } from 'src/app/services/misc/misc.service';


@Component({
  selector: 'app-create-assets',
  templateUrl: './create-assets.component.html',
  styleUrls: ['./create-assets.component.scss']
})
export class CreateAssetsComponent implements OnInit {

  constructor(public asset: AssetsService, public router: Router, public misc: MiscService) { }

  ngOnInit(): void {
    this.getAssetTypes()
  }

  type = {
    name: '',
  };
  asset_types

    // value expression
    regExps: { [key: string]: RegExp } = {
      value: /^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/,
    };

  firstForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    registration_number: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', ),
    value: new UntypedFormControl('', [Validators.required, Validators.pattern(this.regExps.value),]),
    asset_type_id: new UntypedFormControl('', [Validators.required]),
  });

  submitAsset(){
    // const withoutLeading0 = parseInt(this.firstForm.value.value, 10);

    if(this.firstForm.valid){
      this.asset.CreateAsset(this.firstForm.value)
    } else {
      return
    }
  }

  getAssetTypes(){
    this.misc.apiCall('payments', 'admin/asset_types').then((res) => {
      this.asset_types = res['asset_types']
    })
  }

  pickAssetType(e){
    this.firstForm.value.asset_type_id = e.ID
    this.type.name = e.name;
  }
}
