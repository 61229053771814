import { Component, OnInit } from '@angular/core'
import { ApiService } from 'src/app/services/api.service'
import { MessagesService } from 'src/app/services/messages.service'


@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  columns = ['name', 'platform',  'comment', 'date', 'rating']
  
  reviews
  totalRating
  total_ratings

  page       = 1
  limit      = 10
  half       = false
  half_one   = false
  half_two   = false
  half_three = false
  half_four  = false

  constructor(public api: ApiService,
              public message: MessagesService) { }

  ngOnInit(): void {
    if(localStorage.getItem('reviewsPage')){
      this.page = JSON.parse(localStorage.getItem('reviewsPage'))
    }

    if(localStorage.getItem('limit')){
      this.limit = JSON.parse(localStorage.getItem('limit'))
    }


    this.getReviews()
    this.getTotalRating()

  }

  getReviews(){
    this.api.getList(`fulfilment`, `admin/reviews?page=${this.page}&per_page=${this.limit}`).subscribe((reviews: any) => {
      // console.log(reviews)
      this.reviews = reviews.data
    })
  }

  getTotalRating(){
    this.api.getList(`fulfilment`, `admin/reviews?per_page=1000`).subscribe((reviews: any) => {
      let total = 0

      reviews.data.forEach(review => {
        total += review.rating      
      })
      
      this.total_ratings = reviews.data.length
      
      let totalRating = total / reviews.data.length
      this.totalRating = totalRating

      // console.log(totalRating)

      if(totalRating > 0 && totalRating < 1){
        this.half_one = true
      }

      if(totalRating > 1.1 && totalRating < 2){
        this.half_one = true
      }

      if(totalRating > 2.1 && totalRating < 3){
        this.half_two = true
      }

      if(totalRating > 3.1 && totalRating < 3.95){
        this.half_three = true
      }
      if(totalRating > 4 && totalRating < 5){
        this.half_four = true
      }
    })
  }

  setLimit(limit){
    this.limit = limit
    this.getReviews()

    localStorage.setItem('limit', JSON.stringify(limit))
  }

  next(){
    this.page++
    this.getReviews()

    localStorage.setItem('reviewsPage', JSON.stringify(this.page))
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.getReviews()
      localStorage.setItem('reviewsPage', JSON.stringify(this.page))
    }
  }

  start(){
    this.page = 1
    this.getReviews()
    localStorage.setItem('reviewsPage', JSON.stringify(this.page))
  }
}
