import { Component, OnInit } from '@angular/core';

import { Router ,ActivatedRoute} from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { PagerService } from '../../../services/pager.service';
import { MessagesService } from '../../../services/messages.service';
@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {

  id
  store
  search
  products
  storeProducts

  selectedTab   = 'settings'
  productSearch = false

  page = 1;
  limit = 10;
  
  showPopup     = false
  storeproducts = []

  tabs = [
    {name: 'settings'},
    {name: 'trading hours'},
    {name: 'pickers'},
    {name: 'orders'},
    {name: 'products'},
    {name: 'categories'},
    {name: 'zoning'},
  ]

  selectTab(i){
    this.selectedTab = this.tabs[i].name
    localStorage.setItem('storeTab', this.tabs[i].name)
  }

  constructor(
    public messages:MessagesService,
    private api: ApiService,
    public pager:PagerService,
    private route:ActivatedRoute,

  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getStore()
    if(localStorage.getItem('storeTab')){
      this.selectedTab = localStorage.getItem('storeTab')
    }
  }

  ngOnDestroy() {
    this.messages.directory = ''
  }

  //Get Store Info

  getStore(){
    this.api.getItem("catelog","stores",this.id).subscribe((data:any) => {
      this.store = data
      this.messages.directory = data.name
    });
  }

}
