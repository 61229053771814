import { Component, OnInit }      from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService }             from './../../../services/api.service'
import { MessagesService }        from 'src/app/services/messages.service'
import { environment as devEnv }  from '../../.././../environments/environment'

@Component({
  selector: 'app-detail',
  templateUrl: './retailer-detail.component.html',
  styleUrls: ['./retailer-detail.component.scss']
})
export class RetailerDetailComponent implements OnInit {

  id
  retailer

  page    = 1
  limit   = 10
  image   = ''
  retries = 0


  edit         = false
  shade        = false
  active       = false
  logoEdit     = false
  imageAttempt = false


  constructor(private route: ActivatedRoute,
              public  api: ApiService,
              public  router: Router,
              public  message: MessagesService
              ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getRetailer();
  }

  toggleEdit(){
    this.edit = !this.edit
  }

  editLogo(){
    this.logoEdit = !this.logoEdit
  }

  closeLogoEdit(){
    this.logoEdit = false
  }

  toggleRetailer(){
    this.active = !this.active;
  }

  retailerToggle(){
    let retailer = 
      {
        active: this.retailer.active,
        id: this.retailer.id
      }

    this.api.patch('catelog', `retailers`, retailer).subscribe((retailer: any) => {
      if(retailer.active == true){
      this.message.showSnack('Succesfully activated retailer');
      } else {
        this.message.showSnack('Succesfully de-activated retailer')
      }
      this.getRetailer();
    }, error => {
      console.log(error);
      this.message.showSnack(error.error);
    }) 
  }

  getRetailer(){
    this.api.getList('catelog', `retailers/${this.id}`).subscribe((retailer: any) => {
      this.retailer = retailer
      this.image = retailer.image
    }, error => {
      this.message.showSnack(error.error)
      if(this.retries <3 && error.status !== 401 ){
        this.retries++
        this.getRetailer()
      }
    })
  }

  updateRetailer(){
    let retailer = {
      id: this.retailer.id,
      name: this.retailer.name,
      physical_address: this.retailer.physical_address,
      latitude: this.retailer.latitude,
      longitude: this.retailer.longitude,
      email: this.retailer.email,
      phone: this.retailer.phone,
      rebate: JSON.parse(this.retailer.rebate),
      vat_num: this.retailer.vat_num

    }

    this.api.patch('catelog', `retailers`, retailer).subscribe((retailer:any) => {
      this.message.showSnack('Succesfully updated retailer');
      this.getRetailer();
      this.toggleEdit();
    }, error => {
      console.log(error);
      this.message.showSnack(error.error);
    })
  }

  removeRetailer(){
    this.api.delete('catelog', `retailers/${this.id}`).subscribe((retailer: any) => {
      this.message.showSnack('Succesfully removed retailer');
      this.router.navigate(['/retailers']);
    }, error => {
      console.log(error);
      this.message.showSnack(error.error);
    })
  }

  onAutocompleteSelected(e){
    this.retailer.linked_key = e.place_id;
    this.retailer.latitude = e.geometry.location.lat();
    this.retailer.longitude = e.geometry.location.lng();
    this.retailer.physical_address = e.formatted_address;
    this.retailer.phone_number = e.formatted_phone_number;
    this.retailer.address = true;

  }

  imageFile: {resource: string, file: any, name: string}

  imagesPreview(event) {
    if (event.target.files && event.target.files[0]) {
        let reader = new FileReader()

        reader.onload = (_event: any) => {
            this.imageFile = {
                resource: _event.target.result,
                file: event.srcElement.files[0],
                name: event.srcElement.files[0].name
            }
            if(this.imageFile.resource){
              this.imageAttempt = true
            }

            this.image = this.imageFile.resource
            this.imageFile.name = 'image.png'
        }
        reader.readAsDataURL(event.target.files[0])
    }
  }

  updateLogo(){
    this.shade = true


    // else {

    this.message.showSnack('updating retailer image')

    var myHeaders = new Headers()

    myHeaders.append("Authorization", "Bearer " + this.api.token)

    var formdata = new FormData()

    formdata.append("file", this.imageFile.file, this.imageFile.name)
    formdata.append("body", "{ \"resource\":\"retailers\", \"resource_id\":"+ this.id +",\"x_dim\":1, \"y_dim\":1}")
    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata
    }


    if(!devEnv.production){
      fetch("https://api.api-dev.quench.org.za/catalogue/v1/image", requestOptions)
        .then(response => response.text())
        .then(result =>
          setTimeout(() => {
            this.message.showSnack(`successfully updated retailer image`)
            setTimeout(() => {
              this.editLogo()
              this.getRetailer()
              this.shade = false
            }, 3100)
          }, 6000))
        .catch(error => {
          this.message.showSnack(error.error)
        })
      }

      if(devEnv.production){
        fetch("https://api.quench.org.za/catalogue/v1/image", requestOptions)
          .then(response => response.text())
          .then(result =>
            setTimeout(() => {
              this.message.showSnack(`successfully updated retailer image`)
              setTimeout(() => {
                this.getRetailer()
                this.editLogo()
                this.shade = false
              }, 3100)
            }, 6000))
          .catch(error => {
            this.message.showSnack(error.error)
          })
        }
      // }

  }

}
