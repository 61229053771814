import { MiscService } from './../../../services/misc/misc.service';
import { PostsService } from './../../../services/posts/posts.service';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { PagerService } from '../../../services/pager.service';
import { MessagesService } from 'src/app/services/messages.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss'],
})
export class PostsComponent implements OnInit {
  Columns = ['drag', 'image', 'title', 'type'];

  isDisChemUser = false;
  background = `var(--quenchPink)`;


  constructor(
    private api: ApiService,
    public pager: PagerService,
    public message: MessagesService,
    public auth: AuthService,
    public posts: PostsService,
    public misc: MiscService
  ) {}

  ngOnInit(): void {
    if (
      this.auth.dis_chem_role ||
      (localStorage.getItem('dis-chem-role') &&
        localStorage.getItem('dis-chem-role') == 'true')
    ) {
      this.isDisChemUser = true;
      this.misc.silo =  { id: 6, name: "Deliverd", active: true, tag: "deliverd" }
      this.background = `var(--dischemGreen)`
    }

    this.posts.getPosts();
  }

  setSilo(asilo) {
    localStorage.removeItem('currentSection');
    this.misc.silo = asilo;
    this.posts.getPosts();
    localStorage.setItem('currentSilo', JSON.stringify(asilo));
  }
}
