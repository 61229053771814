<div *ngIf='delete' id="shade"></div>

<table >
  <tr>
      <td>
        <mat-form-field >
          <mat-label>Silo</mat-label>
          <mat-select [(ngModel)]="silo_id" name="silo_id"  (selectionChange)="siloChanged()">
            <mat-option *ngFor="let silo of silos" [value]="silo.id">
              {{silo.tag}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    <td>


        <mat-form-field >
          <mat-label>Type</mat-label>
          <mat-select [(ngModel)]="fee_type" name="fee_type"  (selectionChange)="changeType()">
            <mat-option *ngFor="let feeType of feeTypes" [value]="feeType.value">
              {{feeType.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </td>

      <td>
        <mat-form-field class="example-full-width">
          <mat-label>{{unit_label}}</mat-label>
          <input matInput [placeholder]="unit_label" name="untilValue" type="number" [(ngModel)]="untilValue"  #ctrl="ngModel">
        </mat-form-field>
      </td>

      <td>
        <mat-form-field class="example-full-width">
          <mat-label>Amount</mat-label>
          <input matInput placeholder="enter fee" name="amount" type="number"  [(ngModel)]="amount"  #ctrl="ngModel">
        </mat-form-field>
      </td>

      <td >
        <button *ngIf="state=='insert'" mat-flat-button class='button' (click)="createFee()">Create New Fee</button>
        <button *ngIf="state=='update'" mat-flat-button class='button' (click)="updateFee()">Update Fee</button>
      </td>
  </tr>




  <tr *ngFor="let fee of fees">
    <td>{{fee.silo_id}}</td>
    <td>{{fee.fee_type}}</td>
    <td>{{fee.until_value}} {{ fee.fee_type == 'driver_fee' ? ' km ':' items '}}</td>
    <td>R {{fee.amount}}</td>
    <td><mat-icon class='action' (click)="editFee(fee)">edit</mat-icon></td>
    <td><mat-icon class='action'  (click)="deleteCheck(fee)">delete_outlined</mat-icon></td>

  </tr>

</table>

<div id="dialog">
  <div class='dialog' *ngIf='delete'>
    <span>
      <p>Are you sure you want to delete this Fee?</p>
      <mat-icon class='action' (click)='deleteCheck()'>close</mat-icon>
    </span>
    <button mat-flat-button class='button' (click)='deleteFee()' >Confirm</button>
  </div>
</div>

<div id="pager" >
  <div class="page"> 

  <div> Per page:</div>
  <div (click)="setLimit(10)" class="limit" >10</div>
  <div (click)="setLimit(15)" class="limit" >15</div>
  <div (click)="setLimit(20)" class="limit" >20</div>
  <div (click)="setLimit(50)" class="limit" >50</div>
  <div>Page:</div>
  <mat-icon (click)='previous()'>skip_previous</mat-icon>
  <div>{{page}}</div>
    <mat-icon (click)='next()'>skip_next</mat-icon>
    <div> </div>
  </div>
</div>

