import { Router } from '@angular/router';
import { Component, OnInit , ElementRef, ViewChild} from '@angular/core'
import { ApiService } from 'src/app/services/api.service'
import { AuthService } from 'src/app/services/auth.service'
import { MessagesService } from 'src/app/services/messages.service'
import { PagerService } from 'src/app/services/pager.service'
import { PusherService } from 'src/app/services/pusher.service'

@Component({
  selector: 'messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  @ViewChild('scrollMe') private myScrollContainer: ElementRef

  inputTouch = false

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }


  name        = ''
  page        = 1
  users       = []
  limit       = 10
  search      = ''
  message     = ''
  selected    = ''

  cart_id

  
  reply 
  count
  adminUser
  selectedUser
  currentCustomer
  lastMessageInChat
  selectedUserMessages

  num  = 20
  rows = 1
  margin

  constructor(
    public api:ApiService,
    public pager:PagerService,
    private pusherService: PusherService,
    public auth: AuthService,
    public messageService: MessagesService,
    public router: Router

  ) { }

  ngOnInit(): void {

    if(localStorage.getItem('messagesPage')){
      this.page = JSON.parse(localStorage.getItem('messagesPage'))
    }

    if(localStorage.getItem('limit')){
      this.limit = JSON.parse(localStorage.getItem('limit'))
    }

    if(localStorage.getItem('messagesSearch')){
      this.search = localStorage.getItem('messagesSearch')
      this.listUsers()
    }  else {
      this.listUsers()
    }

    this.getMessageCount()
    this.getAdminUser()
    this.listToPusher()
  }

  // ------------
  //   TEXTAREA
  // ------------

  textareaExpand(e){
    let margin = `${this.num}px`

    if(e.length < 80){
      this.rows = 1
      this.num = 20
      this.scrollToBottom()
    }

    if(e.length > 80){
      this.rows = 5
      this.num = 80
      this.scrollToBottom()
    }

    if(e.length > 400){
      this.rows = 8
      this.num = 125
      this.scrollToBottom()
    }

    this.margin = margin
  }

  // -------------
  //     CHATS
  // -------------

  listUsers(){
    localStorage.setItem('messagesSearch', this.search)
    this.api.getList("fulfilment",`admin/messages?page=${this.page}&per_page=${this.limit}&search=${this.search}`).subscribe((data:any)=>{
      if(data.data.length === 0 && this.page > 1){
        this.page--
        this.listUsers()
      } else if(data.data.length === 0 && this.search.length > 1){
        this.messageService.showSnack('No results found')
      }

      this.users = data.data
    })
  }

  searchReset(){
    this.page = 1
    this.listUsers()
    localStorage.setItem('messagesPage', JSON.stringify(this.page))
  }

  clearSearch(){
    this.search = ''
    this.listUsers()
  }


  getMessageCount(){
    this.api.getList("fulfilment","admin/messagescount").subscribe((data:any)=>{
      this.count = data.not_read
    })
  }
  // -----------
  //  MESSAGES
  // -----------

  // get for sender name info
  getUserInfo(user){
    this.api.get('auth', `admin/users`, user.uid).subscribe((data: any) => {
      this.currentCustomer = `${data.user.first_name} ${data.user.last_name}`
      // console.log(data)
    })
  }


  listUserMessages(user){
    this.getUserInfo(user)
    // console.log(user)

    if(user.not_read > 0){
      this.api.patch("fulfilment","admin/messages/"+user.uid+"/read","").subscribe((data:any)=>{
        this.listUsers()
        this.getMessageCount()
        this.selected = user.uid
      })
    }
    
    this.selected = user.uid
    this.selectedUser = user


    this.api.getList("fulfilment","admin/messages/"+user.uid).subscribe((data:any)=>{

      // console.log(data)

      let dates = []
      let messages = []

      data.data.forEach(message => {
        // console.log(message)
        const date = new Date( message.CreatedAt ).toDateString()

          if(dates.includes(date)){
           messages.forEach(object => {
             if(object.cart_id !== ""){
               this.cart_id = object.cart_id
             }

             if(object.date.includes(date)){
              object.messages.push(message) 
             }
           })
          } else {
            dates.push(date)
            let newDate = {
              date: date,
              messages: [message]
            }
            messages.push(newDate)
          }
      })

      this.selectedUserMessages = messages

      // get last message in messages arr
      let length           = this.selectedUserMessages.length - 1
      let lastDateInArray  = this.selectedUserMessages[length].messages
      let lastIndex        = lastDateInArray.length - 1
      this.lastMessageInChat = lastDateInArray[lastIndex]


      this.cart_id = user.cart_id

      setTimeout(() => {
        this.scrollToBottom()
      }, 300)

    })


  }

  navigateCart(){
    // this.router.navigate([`/cart/${this.cart_id}`])
    // console.log(this.selectedUser)
}

  scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight
    } catch(err) { }
  }

  getAdminUser(){
    this.api.getList(`auth`, `user/profile`).subscribe((data: any) => {
      this.adminUser = data.user
    })
  }


  adminReply(){
    this.rows = 1
    this.margin= `${20}px`

    let reply = {
      uid: this.selectedUser.uid,
      sender_name: this.currentCustomer,
      content: this.reply,
      origin: this.selectedUser.origin,
      // cart_id: this.cart_id
    }

    // console.log(reply)
    
    this.api.post("fulfilment","admin/messages", reply).subscribe((data:any)=>{
        this.reply =''
        this.listUserMessages(this.selectedUser)
    })
  }

  listToPusher(){
    this.pusherService.channel.bind('message-status-event', data => {
      this.getMessageCount()
      this.listUsers()
      if(this.selectedUser){
        this.listUserMessages(this.selectedUser)
      }
    })
  }

  // ngOnDestroy(): void {
  //   this.pusherService.channel.unsubscribe()
  // }


  // =================
  //   PAGINATION
  // =================

  setLimit(limit){
    this.limit = limit
    this.listUsers()
    localStorage.setItem('limit', JSON.stringify(limit))
  }

  next(){
    if(this.users.length >= this.limit){
    this.page++
    this.listUsers()
    } else {
      this.messageService.showSnack('No more users to show')
    }

    localStorage.setItem('messagesPage', JSON.stringify(this.page))
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.listUsers()
    }

    localStorage.setItem('messagesPage', JSON.stringify(this.page))
  }

  start(){
    this.page = 1
    this.listUsers()

    localStorage.setItem('messagesPage', JSON.stringify(this.page))
  }
}
