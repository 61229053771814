<table mat-table [dataSource]="accounts.account_summary_data" matSort>

    <ng-container matColumnDef="user_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User ID</th>
        <td mat-cell *matCellDef="let account">
          <p *ngIf="account.user_id">{{account.user_id}}</p>
          <p *ngIf="!account.user_id" class="empty">user_id not specified</p>
        </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let account">
          <p *ngIf="account.name">{{account.name}}</p>
          <p *ngIf="!account.name" class="empty">name not specified</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mobile number</th>
        <td mat-cell *matCellDef="let account">
          <p *ngIf="account.mobile">{{ account.mobile }}</p>
          <p *ngIf="!account.mobile" class="empty">Mobile number not specified</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let account">
          <p *ngIf="account.email">{{ account.email }}</p>
          <p *ngIf="!account.email" class="empty">email not specified</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let account">
          <p *ngIf="account.status">{{ account.status }}</p>
          <p *ngIf="!account.status" class="empty">status not specified</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="verified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Verified</th>
        <td mat-cell *matCellDef="let account">
          <p *ngIf="account.verified">{{ account.verified }}</p>
          <p *ngIf="!account.verified" class="empty">verified not specified</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="is_fleet_driver">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Is fleet driver</th>
        <td mat-cell *matCellDef="let account">
          <p *ngIf="account.is_fleet_driver">{{ account.is_fleet_driver }}</p>
          <p *ngIf="!account.is_fleet_driver" class="empty">Is fleet driver not specified</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="fleet_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fleet ID</th>
        <td mat-cell *matCellDef="let account">
          <p *ngIf="account.fleet_id">{{ account.fleet_id }}</p>
          <p *ngIf="!account.fleet_id" class="empty">fleet ID not specified</p>
        </td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row class="action" *matRowDef="let account; columns: Columns"></tr>

  </table>
