import { MiscService } from './services/misc/misc.service';
import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { AuthService } from './services/auth.service';
import { MessagesService } from './services/messages.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  isDischemUser = false
  title = 'admin';

  constructor(
    public messages:MessagesService,
    public auth:AuthService,
    public misc: MiscService,
    @Inject(DOCUMENT) private document: Document
  ){

  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }

  ngOnInit(){
    if (
      this.auth.dis_chem_role ||
      (localStorage.getItem('dis-chem-role') &&
        localStorage.getItem('dis-chem-role') == 'true')
    ) {
      this.isDischemUser = true;
    }

  }

  getRole(){
      if (
      this.auth.dis_chem_role ||
      (localStorage.getItem('dis-chem-role') &&
        localStorage.getItem('dis-chem-role') == 'true')
    ) {
      this.isDischemUser = true
      return true
    } 
  }

}

