import { Component, OnInit } from '@angular/core'

import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop'
import { Router } from '@angular/router'
import { ApiService } from '../../../services/api.service'
import { PagerService } from '../../../services/pager.service'
import { MessagesService } from 'src/app/services/messages.service'
import { AuthService } from 'src/app/services/auth.service'

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  Columns = ['drag', 'icon', 'name', 'active']

  inputTouch = false

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }

  silo
  category

  page       = 1
  silos      = []
  limit      = 1000
  search     = ''
  retries    = 0
  categories = []

  constructor(
    private router: Router,
    private api: ApiService,
    public pager:PagerService,
    public message: MessagesService,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    this.getSilos()

      if(localStorage.getItem('categoriesPage')){
        this.page = JSON.parse(localStorage.getItem('categoriesPage'))
      }


      if(localStorage.getItem('categorySearch')){
        this.search = localStorage.getItem('categorySearch')
        this.searchCategories(this.search)
      } else {
      this.getCategories(this.search)
      }
  }

  getSilos(){
    this.api.getList("catelog","silos").subscribe((data:any)=>{
      this.silos = data
      
      if(localStorage.getItem('categorySilo')){
        this.silo = JSON.parse(localStorage.getItem('categorySilo'))
        } else {
          this.silo = data[0]
        }
        this.setSilo(this.silo)
    })
  }

  // =============
  //   SILO SET
  // =============

  setSilo(silo){
    this.page = 1
    localStorage.setItem('categoriesPage', JSON.stringify(this.page))
    this.silo = silo
    this.search = ''
    this.getCategories(this.search)
    localStorage.setItem('categorySilo', JSON.stringify(silo))
  }

  // =============
  //    TOGGLE
  // =============

  toggleCategory(category){
    let cat = [
      {
        id: category.id,
        active: category.active
      }
    ]

    this.api.patch(`catelog`, `categories`, cat).subscribe((category: any) => {
      if(category[0].active){
      this.message.showSnack(`Successfully activated ${category[0].name}`)
      } else {
      this.message.showSnack(`Successfully deactivated ${category[0].name}`)
      }
    }, error => {
      this.message.showSnack(error.error)
    })
  }

 getCategories(e){
    this.api.getList("catelog", "silos/"+this.silo.id+`/categories?page=${this.page}&per_page=${10000}`)
    .subscribe((data:any)=>{
      this.categories = data
      // console.log(data)
    }, error => {
      this.message.showSnack(error.error)
      if(this.retries < 3){
        if(error.status !== 401){
          this.retries++
          this.getCategories(this.search)
        }
      }
    })
  }

  searchCategories(e){
    localStorage.setItem('categorySearch', e)
    if(e.length > 0){
    this.api.getList(`catelog`, `categories?per_page=${10000}&silo_id=${this.silo.id}&search=${e}`).subscribe((data: any) => {
      this.categories = data
    }, error => {
      this.message.showSnack(error.error)
    })
  } else {
    this.getCategories(this.search)
  }

  }

  clearSearch(){
    this.search = ''
    this.getCategories(this.search)
    localStorage.removeItem('categorySearch')
  }


// =================
//   DRAG AND DROP
// =================

drop(event: CdkDragDrop<string[]>) {
  moveItemInArray(this.categories, event.previousIndex, event.currentIndex)

  var list=[]

  this.categories.forEach(category=>{
    list.push(category.id)
  })

  let categories = {
    table: 'categories',
    ids: list
  }

  this.api.patch("catelog","sort", categories).subscribe(data=>{
    this.message.showSnack('Succesfully updated positions of categories')
    this.getCategories(this.search)
  },error=>{ })
}


// =================
//    PAGINATION
// =================

  setLimit(limit){
    if(this.categories.length !== 0){
      this.limit = limit 
      this.getCategories(this.search)
    }
  }

  next(){
    if(this.categories.length !== 0){
    this.page++
    this.getCategories(this.search)
    }
    localStorage.setItem('categoriesPage', JSON.stringify(this.page))
  }

  previous(){
      if(this.page > 1){
        this.page--
        this.getCategories(this.search)
      }
    localStorage.setItem('categoriesPage', JSON.stringify(this.page))
  }


}
