<div class="addresses">
  <div class="address-card" *ngFor='let address of addresses' (click)='addressDialog(address)'>
    <span class="default" *ngIf='address.default_address'>default</span>
    <h4>{{address.label}}</h4>
    <p class='address'>{{address.full_address}}</p>
    <hr>
    <div class="more">
      <p class="address">{{address.CreatedAt | date: 'MMM d, y - HH:MM:ss '}}</p>
      <mat-icon class='action' matTooltip='more details'>north_east</mat-icon>
    </div>
  </div>
</div>