<table
  mat-table
  [dataSource]="accounts.user_accounts"
  matSort
  class="asset_table"
>
  <ng-container matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
    <td mat-cell *matCellDef="let account">
      <p>{{ account.name }}</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="mobile">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>mobile</th>
    <td mat-cell *matCellDef="let account">
      <p>{{ account.mobile }}</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>email</th>
    <td mat-cell *matCellDef="let account">
      <p *ngIf="account.email">
        {{ account.email }}
      </p>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>status</th>
    <td mat-cell *matCellDef="let account">
      <p *ngIf="account.status">
        {{ account.status }}
      </p>
    </td>
  </ng-container>

  <ng-container matColumnDef="verified">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Verified</th>
    <td mat-cell *matCellDef="let account">
      <mat-icon *ngIf="account.verified" class="active">check</mat-icon>
      <mat-icon *ngIf="!account.verified" class="inactive">clear</mat-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="Columns"></tr>
  <tr mat-row class="action" *matRowDef="let account; columns: Columns" routerLink="/users/{{account.user_id}}"></tr>
</table>

<div id="pager" >
  <div class="page" >
    <div>Per page:</div>
    <span class="silo-filter pagination">
      <button mat-button [matMenuTriggerFor]="paginate">
        <span>{{ limit }}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #paginate="matMenu">
        <button mat-menu-item (click)="setLimit(10)">10</button>
        <button mat-menu-item (click)="setLimit(20)">20</button>
        <button mat-menu-item (click)="setLimit(50)">50</button>
      </mat-menu>
    </span>

    <div
      (click)="start()"
      matTooltip="Skip to start"
      class="action"
      *ngIf="page > 1"
    >
      back to start
    </div>
    <p (click)="previous()" class="action">previous</p>
    <div>{{ page }}</div>
    <p (click)="next()" class="action">next</p>
    <div></div>
  </div>
</div>