<div *ngIf='shade' id='shade' class='shade'> 
  <mat-spinner class='spinner'></mat-spinner> 
</div>

<div class="back action" routerLink="/products">
  <mat-icon>arrow_back</mat-icon>
  <p>products</p>
</div>

<div class="create-card" [style.height]="product_type == 'market' ? '620px' : '620px' " >

  <div class="header">
    <h3>create new product</h3>
    <mat-icon routerLink="/products">clear</mat-icon>
  </div>

    <!-- ---------------------- -->
    <!--  COLLECT PRODUCT INFO  --> 
    <!-- ---------------------- -->

  <div class="first-form"  [style.left]="productInfo  ? '-900px' : '0' ">

    <mat-radio-group aria-label="Select an option" [(ngModel)]="product_type" (change)='selectProductType()'>
      <mat-radio-button value="standard">standard product</mat-radio-button>
      <mat-radio-button value="market">market product</mat-radio-button>
    </mat-radio-group>  

    <br>

    <span class='market-note' *ngIf="product_type == 'market' ">* please note that all product dimensions are rounded down to the closest whole number</span>


    <form #product = 'ngForm' (ngSubmit)='submitProductInfo(product.value)'>
      
      <mat-form-field class='field' [style.margin-top]="product_type == 'market' ? '15px' : '0' " appearance='outline'>
        <mat-label>product name</mat-label>
        <input type="text" matInput ngModel placeholder="gouda cheese" name='name' required >
      </mat-form-field>

      <mat-form-field class='field' appearance='outline'>
        <mat-label>product description</mat-label>
        <textarea type="text" matInput ngModel placeholder="this is a cool product" name='description'></textarea>
      </mat-form-field>

      <div class="form flex-items">
        <mat-form-field  appearance='outline'>
          <mat-label>product price(zar)</mat-label>
          <input type="number" matInput [(ngModel)]='price' placeholder="100" name='price' required >
        </mat-form-field>
        <mat-form-field  appearance='outline'>
          <mat-label>product barcode</mat-label>
          <input type="text" matInput ngModel placeholder="12345678" name='barcode' required>
        </mat-form-field>
        <mat-form-field  appearance='outline'>
          <mat-label>product size</mat-label>
          <input type="text" matInput ngModel placeholder="900g" name='size' maxlength="15" >
        </mat-form-field>
        <mat-form-field  appearance='outline'>
          <mat-label>product sku code</mat-label>
          <input type="text" matInput ngModel placeholder="6042243241" name='sku_code'>
        </mat-form-field>
      </div>

      <div class="form" *ngIf="product_type == 'market' ">
        <mat-form-field  appearance='outline'>
          <mat-label>product length(cm)</mat-label>
          <input type="number" matInput [(ngModel)]="length" placeholder="10cm" name='length' required (blur)="dimensionsRoundUp()">
        </mat-form-field>
        <mat-form-field  appearance='outline'>
          <mat-label>product width(cm)</mat-label>
          <input type="number" matInput [(ngModel)]="width" placeholder="10cm" name='width' required (blur)="dimensionsRoundUp()">
        </mat-form-field>
        <mat-form-field  appearance='outline'>
          <mat-label>product height(cm)</mat-label>
          <input type="number" matInput [(ngModel)]="height" placeholder="10cm" name='height' required (blur)="dimensionsRoundUp()">
        </mat-form-field>
        <mat-form-field  appearance='outline'>
          <mat-label>product weight(kg)</mat-label>
          <input type="number" matInput [(ngModel)]="weight" placeholder="2kg" name='weight' required (blur)="dimensionsRoundUp()">
        </mat-form-field>
      </div>

      <div class="retailer-field field" *ngIf="product_type == 'market' ">
        <mat-form-field appearance='outline'>
          <mat-label>Retailer</mat-label>
          <mat-select [(ngModel)]="selectedValue" name="retailer" class='select-panel'>
            <input class='select-search' type="text" placeholder="Search Retailers" [(ngModel)]="search" (input)='getRetailers()' >
              <mat-option *ngFor="let retailer of retailers" [value]="retailer" >
                  {{retailer.name}}
              </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-icon class='help material-icons-outlined action' matTooltip='why is a retailer necessary? This is to help us keep our data clean and to make life easier further along the line, since products can be difficult to find if they are not linked to a retailer.'>help_outline</mat-icon>
      </div>

  <!-- ----------------- -->
  <!--    SILO FILTER    -->
  <!-- ----------------- -->

  <mat-form-field  *ngIf="product_type == 'standard'" appearance='outline' >
    <mat-label>silo</mat-label>
    <mat-select [(ngModel)]="silo" name="silo" class='select-panel' required>
        <mat-option *ngFor="let silo of silos" [value]="silo.id" (click)='selectSilo(silo)'>
            {{silo.name}}
        </mat-option>
    </mat-select>
  </mat-form-field> 

    <mat-form-field class='field'  appearance='outline' *ngIf='silo_id == 3' >
      <mat-label>maximum quantity allowed</mat-label>
      <input type="text" matInput ngModel placeholder="3" name='max_quantity' [(ngModel)]="max_qty" (blur)="dimensionsRoundUp()">
    </mat-form-field>

    <mat-form-field class='field'  appearance='outline' *ngIf='silo_id == 3' >
      <mat-label>otc number(if applicable)</mat-label>
      <input type="text" matInput ngModel placeholder="12321321" name='otc' [(ngModel)]="otc" (blur)="dimensionsRoundUp()">
    </mat-form-field>


      <mat-form-field appearance='outline' class='field' *ngIf='selectedValue || silo_id'>
        <mat-label>Parent Category</mat-label>
          <mat-select  [(ngModel)]="category" name="category" required>
            <input class='select-search' type="text" placeholder="Search Categories" [(ngModel)]="search" (input)='getCategories()' >
            <mat-option *ngFor="let category of categories" [value]="category" >
              {{category.name}}
            </mat-option>
          </mat-select>
      </mat-form-field>

      <div class="btn">
        <button mat-flat-button class='button' type='submit'>next</button>      
      </div>

    </form>
  </div>

    <!-- ------------------ -->
    <!--     ADD IMAGE      --> 
    <!-- ------------------ -->

  <div class="second-form" [style.left]="productInfo  ? '0' : '900px' " *ngIf='!addCategories'>

      <mat-icon class='action' (click)='backToProductInfo()'>arrow_back</mat-icon>

      <h3>Almost there! Please upload an image for this product</h3>

      <div class='image'>
        <img class="preview-image" [src]="imageFile ? imageFile.resource : '../assets/placeholders/logo-placeholder.png'">
        <br>
        <button class="custom-file-upload" mat-button type="button" onclick="document.querySelector('#exampleInputFile').click()">Upload Image</button>
        <input type="file" id="exampleInputFile" (change)="imagesPreview($event)" style="display: none;">
      </div>

      <div class="btn product-info-button">
        <button mat-flat-button class='button' (click)='submitProduct()' disabled="{{imageAttempt ? false : true}}">Save</button>
      </div>

  </div>

    <!-- --------------------- -->
    <!--  ADD MORE CATEGORIES  --> 
    <!-- --------------------- -->

  <div class="third-form" [style.left]="addCategories  ? '0' : '2000px' ">
    <h3>Please select some subcategories for this product  
    <span class='alternative'>*alternatively if you would like to add this product to another main category, you can do so on the product detail page</span>
  </h3>
    <!-- <input class='retailer-search' type="text" placeholder="Search Categories" [(ngModel)]="search"  (input)='getCategories()' > -->


        <mat-chip-list selectable multiple>
          <mat-chip #c="matChip" *ngFor="let category of categories" [value]="category" (click)="toggleSelection(c)" [style.background]="c.selected ? 'var(--quenchPink)' : '' ">
            <mat-icon *ngIf="c.selected">check</mat-icon>
            {{category.name}}
          </mat-chip>
        </mat-chip-list>

          <p *ngIf='categoriesArr.length > 0' class='adding'> Adding: </p>

          <mat-chip-list aria-label="Fish selection">
            <mat-chip id='selectedCat' #c="matChip" [value]="category" *ngFor='let category of categoriesArr'  >{{category.name}}
              <mat-icon matChipRemove (click)='toggleSelection(c)'>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
          <span *ngIf='categoriesArr.length == 10' class='more'>...& {{extraCount}} more</span>

          <div class="btn product-info-button">
            <button mat-flat-button class='button skip' (click)='skipCategoryAdd()'>skip</button>
            <button mat-flat-button class='button' (click)=' addProductCategories()'>Save</button>
          </div>

  </div>

</div>
