<div class="search">
  <mat-icon
    (click)="searchCategories(search)"
    matTooltip="search"
    class="search-icon"
    [style.left]="inputTouch ? '-20px' : '10px'"
    >search</mat-icon
  >
  <mat-icon
    *ngIf="search"
    (click)="clearSearch()"
    matTooltip="clear"
    class="clear-icon"
    >clear</mat-icon
  >
  <input
    matInput
    (keydown.enter)="searchCategories(search)"
    [(ngModel)]="search"
    placeholder="Search"
    #input
    (blur)="onBlur()"
    (focus)="onFocus()"
    [style.left]="inputTouch ? '10px' : '35px'"
  />
</div>

<div class="stores">
  <div class="silos">
    <div
      (click)="setSilo(asilo)"
      *ngFor="let asilo of silos"
      [class]="asilo.name == silo.name ? 'isBold' : ''"
    >
      {{ asilo.name }}
    </div>
  </div>

  <table class="table">
    <thead>
      <tr>
        <th class="header" id="sort">Sort</th>
        <th class="header" id="icon">Icon</th>
        <th class="header">Name</th>
        <th class="header"></th>
      </tr>
    </thead>
    <tbody cdkDropList (cdkDropListDropped)="drop($event)">
      <tr
        class="action"
        *ngFor="let category of categories"
        cdkDrag
        cdkDragLockAxis="y"
      >
        <th class="sort">
          <p><mat-icon>sort</mat-icon></p>
        </th>
        <th routerLink="/categories/{{ category.id }}" class="icon">
          <p>
            <img
              src="{{ category.image }}"
              onerror="this.src='assets/placeholders/placeholder-img.png' "
            />
          </p>
        </th>
        <th routerLink="/categories/{{ category.id }}">
          <p>{{ category.name }}</p>
        </th>
        <th class="slider">
          <p>
            <mat-slide-toggle
              [(ngModel)]="category.active"
              (change)="toggleCategory(category)"
            ></mat-slide-toggle>
          </p>
        </th>
      </tr>
    </tbody>
  </table>

  <!-- <table  mat-table  
          [dataSource]='categories' 
          cdkDropList
          (cdkDropListDropped)="drop($event)">

    <ng-container matColumnDef="drag">
      <th mat-header-cell *matHeaderCellDef > Sort </th>
      <td mat-cell  
          *matCellDef="let category"
          routerLink='/categories/{{category.id}}' > 
          <mat-icon>sort</mat-icon> 
        </td>
    </ng-container>
          
    <ng-container matColumnDef="icon">
      <th mat-header-cell *matHeaderCellDef > Icon </th>
      <td mat-cell  
          *matCellDef="let category" 
          routerLink='/categories/{{category.id}}' 
          id='prod-img'> 
          <img  src={{category.image}} alt=""> 
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell  *matHeaderCellDef> Title </th>
      <td mat-cell 
          *matCellDef="let category" 
          routerLink='/categories/{{category.id}}' 
          class='pointer' 
          id='title'> 
          <p>{{category.name}}</p> 
      </td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell  *matHeaderCellDef > </th>
      <td mat-cell  
          *matCellDef="let category" > 
          <mat-slide-toggle [(ngModel)]="category.active" (change)="toggleCategory(category)"></mat-slide-toggle>
      </td>    
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row class='action' cdkDrag  *matRowDef="let category; columns: Columns;"></tr>
  </table> -->
</div>

<div id="pager">
  <button
    mat-flat-button
    class="button"
    id="{{ auth.expand ? 'create-expand' : 'create' }}"
    routerLink="/create-category"
  >
    New
  </button>

  <div class="page">
    <!-- <div> Per page:</div>
  <div (click)="setLimit(10)" class="limit" >10</div>
  <div (click)="setLimit(15)" class="limit" >15</div>
  <div (click)="setLimit(20)" class="limit" >20</div>
  <div (click)="setLimit(50)" class="limit" >50</div>
  <div>Page:</div>
  <mat-icon (click)='previous()'>skip_previous</mat-icon>
  <div>{{page}}</div>
    <mat-icon (click)='next()'>skip_next</mat-icon>
    <div> </div> -->
  </div>
</div>
