<table mat-table [dataSource]='contracts.contracts' matSort>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let contract" > {{contract.name}} </td>
    </ng-container>

    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> duration </th>
      <td mat-cell *matCellDef="let contract"   > {{contract.start_date.substring(0,10)}} - <br>  {{contract.end_date.substring(0,10)}} </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> amount </th>
      <td mat-cell *matCellDef="let contract"> {{contract.amount}} </td>
    </ng-container>

    <ng-container matColumnDef="frequency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> frequency </th>
      <td mat-cell *matCellDef="let contract"> {{contract.frequency}} </td>
    </ng-container>

    <ng-container matColumnDef="no_of_payments">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> number of payments </th>
      <td mat-cell *matCellDef="let contract"> {{contract.no_of_payments}} </td>
    </ng-container>
  
<!-- 
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> status </th>
      <td mat-cell *matCellDef="let contract"> {{contract.status}} </td>
    </ng-container> -->
  

    <ng-container matColumnDef="user_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> user </th>
      <td mat-cell *matCellDef="let contract"> {{contract.user_id}} </td>
    </ng-container>
  

    <ng-container matColumnDef="asset_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> asset </th>
      <td mat-cell *matCellDef="let contract"> {{contract.asset_id}} </td>
    </ng-container>
  

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
      <td mat-cell *matCellDef="let contract"> 
        <mat-icon (click)="updateContract(contract)">edit</mat-icon> </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row *matRowDef="let contract; columns: Columns;" class='action'></tr>
  </table>


  <div id="pager">
    <div class="page">
      <button
        id="{{ auth.expand ? 'create-expand' : 'create' }}"
        mat-flat-button
        class="button"
        routerLink="/create-contracts"
      >
      New Contract
      </button>
      <div>Per page:</div>
      <span class="silo-filter pagination">
        <button mat-button [matMenuTriggerFor]="paginate">
          <span>{{ contracts.limit }}</span>
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #paginate="matMenu">
          <button mat-menu-item (click)="setLimit(10)">10</button>
          <button mat-menu-item (click)="setLimit(20)">20</button>
          <button mat-menu-item (click)="setLimit(50)">50</button>
        </mat-menu>
      </span>
  
      <div
        (click)="start()"
        matTooltip="Skip to start"
        class="action"
        *ngIf="contracts.page > 1"
      >
        back to start
      </div>
      <p (click)="previous()" class="action">previous</p>
      <div>{{ contracts.page }}</div>
      <p (click)="next()" class="action">next</p>
      <div></div>
    </div>
  </div>
  
