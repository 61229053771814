<div class="heading">
    <div></div>
    <h4>Edit Product Dimensions</h4>
    <!-- <mat-icon (click)='closeDialog()' >clear</mat-icon> -->
</div>

  <div class="edit-fields">
      <div class="fields">
        <mat-form-field appearance="outline" class='field'>
          <mat-label>length(cm)</mat-label>
          <input   matInput placeholder="10" [(ngModel)]="length" (blur)="dimensionsRoundUp()" type='number' name='length' required>
        </mat-form-field>
       <mat-form-field appearance="outline" class='field'>
          <mat-label>width(cm)</mat-label>
          <input  matInput placeholder="10" [(ngModel)]="width" (blur)="dimensionsRoundUp()"  type='number' name='width'required>
        </mat-form-field>
       <mat-form-field appearance="outline" class='field'>
          <mat-label>height(cm)</mat-label>
          <input  matInput placeholder="10" [(ngModel)]="height" (blur)="dimensionsRoundUp()" type='number' name='height' required>
        </mat-form-field>
       <mat-form-field appearance="outline" class='field'>
          <mat-label>weight(cm)</mat-label>
          <input  matInput placeholder="10" [(ngModel)]="weight" (blur)="dimensionsRoundUp()" type='number' name='weight' required>
        </mat-form-field>
      </div>
  </div>

  <div class="save">
      <button mat-flat-button class='button cancel' (click)='closeDialog()'> Cancel</button>
      <button mat-flat-button class='button' (click)='updateProduct()'> Save</button>
  </div>
