<!-- die tabel ontvang n array as datasource hier, waar jy al die array data kan access -->
<table mat-table [dataSource]="accounts.account_payments" matSort>
  
    <!-- die column name wat jy define het moet hier ingesit word -->
    <!-- date column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let payment">
        <!-- angular het cool pipes soos die date pipe sodat jy die date kan format in die html -->
        <!-- die substring pipe laat jou toe om n string te edit soos jy dit wil he -->
        <p *ngIf="payment.date">{{ payment.date.substring(0,10) }}</p>
      </td>
    </ng-container>
  
        <!-- account_name column -->

    <ng-container matColumnDef="account_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Name</th>
      <td mat-cell *matCellDef="let payment">
        <p *ngIf="payment.account_name">{{ payment.account_name }}</p>
        <p *ngIf="!payment.account_name" class="empty">account name not specified</p>
      </td>
    </ng-container>
  
        <!-- account_number column -->

    <ng-container matColumnDef="account_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Number</th>
      <td mat-cell *matCellDef="let payment">
        <p *ngIf="payment.account_number">{{ payment.account_number }}</p>
        <p *ngIf="!payment.account_number" class="empty">account number not specified</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
      <td mat-cell *matCellDef="let payment">
        <p *ngIf="payment.amount">{{ payment.amount }}</p>
        <p *ngIf="!payment.amount" class="empty">Amount not specified</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="payment_method">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment method</th>
      <td mat-cell *matCellDef="let payment">
        <p *ngIf="payment.payment_method">{{ payment.payment_method }}</p>
        <p *ngIf="!payment.payment_method" class="empty">Payment method not specified</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let payment">
        <p *ngIf="payment.status">{{ payment.status }}</p>
        <p *ngIf="!payment.status" class="empty">Status not specified</p>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row class="action" *matRowDef="let payment; columns: Columns"></tr>
  </table>
  