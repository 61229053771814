<table mat-table [dataSource]="payments.batches" matSort>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Batch Date</th>
    <td mat-cell *matCellDef="let batch">
      <p *ngIf="batch.date">{{ batch.date.substring(0,10) }}</p>
      <p *ngIf="!batch.date" class="empty">empty</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="payment_method">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Method</th>
    <td mat-cell *matCellDef="let batch">
      <p *ngIf="batch.payment_method_id">{{ batch.payment_method_id }}</p>
      <p *ngIf="!batch.payment_method_id" class="empty">no payment method provided</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let batch">
      <p *ngIf="batch.status">{{ batch.status }}</p>
      <p *ngIf="!batch.status" class="empty">no status</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>amount</th>
    <td mat-cell *matCellDef="let batch">
      <p>{{ batch.amount | currency: "R ":"code" }}</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="download">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td mat-cell *matCellDef="let batch">
      <mat-icon
        (click)="downloadFile(batch)"
        >download</mat-icon
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="navigate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td mat-cell *matCellDef="let batch" routerLink="/batch-payment/{{batch.ID}}">
      <mat-icon matTooltip="view batch payments">arrow_forward</mat-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="Columns"></tr>
  <tr
    mat-row
    class="action"
    *matRowDef="let batch; columns: Columns"
    routerLink="/batch-payment/{{ batch.ID }}"
  ></tr>
</table>
 

<div id="pager">
  <div class="page">
    <div class="actions"  id="{{ auth.expand ? 'create-expand' : 'create' }}">
      <button mat-flat-button (click)="viewPaymentMethods()">
        View Payment Methods
      </button>
      <button mat-flat-button class="button" routerLink="/payment-capture">
        Capture payment
      </button>
    </div>
    <div>Per page:</div>
    <span class="silo-filter pagination">
      <button mat-button [matMenuTriggerFor]="paginate">
        <span>{{ limit }}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #paginate="matMenu">
        <button mat-menu-item (click)="setLimit(10)">10</button>
        <button mat-menu-item (click)="setLimit(20)">20</button>
        <button mat-menu-item (click)="setLimit(50)">50</button>
      </mat-menu>
    </span>

    <div
      (click)="start()"
      matTooltip="Skip to start"
      class="action"
      *ngIf="page > 1"
    >
      back to start
    </div>
    <p (click)="previous()" class="action">previous</p>
    <div>{{ page }}</div>
    <p (click)="next()" class="action">next</p>
    <div></div>
  </div>
</div>
