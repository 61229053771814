<div class="search">
  <mat-icon (click)='searchReset()' matTooltip='search' class='search-icon' [style.left]="inputTouch ? '-20px' : '10px' ">search</mat-icon>
  <mat-icon *ngIf='search' (click)='clearSearch()'  matTooltip='clear' class='clear-icon'>clear</mat-icon>
  <input matInput (keydown.enter)='searchReset()' [(ngModel)]="search" placeholder="Search"  #input (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
</div>

<div class="silos">
  <div (click)="setSilo(asilo)" *ngFor="let asilo of silos" [class]="asilo.name == silo.name ?  'isBold' : '' ">{{asilo.tag}}</div>
</div>

    <table  mat-table [dataSource]='products' matSort>

      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
        <td mat-cell *matCellDef="let product" id='img'> 
          <img id='prod-img' src={{product.image}} alt="" onerror="this.src='assets/placeholders/placeholder-img.png' "> 
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let product"> 
          <p *ngIf='product.name'>{{product.name}} </p>
          <p *ngIf='!product.name'>no name </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
        <td mat-cell *matCellDef="let product"> 
          <p *ngIf='product.size'>{{product.size}} </p>
          <p *ngIf='!product.size' class='no-size'>no size specified </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Price </th>
        <td mat-cell *matCellDef="let product"> {{product.price   | currency:'R ': 'code'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row 
          routerLink='/products/{{product.id}}' 
          class='action'
          *matRowDef="let product; columns: displayedColumns;"></tr>
    </table>

    <!-- Mobile -->

    <div class='mobile'>
      <mat-expansion-panel hideToggle  *ngFor='let product of products '>
        <mat-expansion-panel-header >
          <mat-panel-title>
            <p class='title'>{{product.name}}</p>
          </mat-panel-title>
        </mat-expansion-panel-header>
          <span class="flex">
            <mat-title>Name:</mat-title>
            <p>{{product.name}}</p>
          </span>
          <span class="flex">
            <mat-title>Image:</mat-title>
            <p><img src={{product.image}} alt=""></p>
          </span>
          <span class="flex">
            <mat-title>size:</mat-title>
            <p>	{{product.size}} </p>
          </span>
          <span class="flex">
            <mat-title>Price:</mat-title>
            <p>	{{product.price}}</p>
          </span>
        </mat-expansion-panel>
    </div>

    <div id="pager" >
      <div class="page"> 

       <button id="{{auth.expand ? 'create-expand': 'create'}}" mat-flat-button class='button' routerLink='/create-product' >New</button>
  
      <div> Per page:</div> 
      <span class="silo-filter pagination">
        <button mat-button [matMenuTriggerFor]="paginate">
          <span>{{limit}}</span>
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #paginate="matMenu" >
          <button mat-menu-item (click)="setLimit(10)">10</button>
          <button mat-menu-item (click)="setLimit(20)">20</button>
          <button mat-menu-item (click)="setLimit(50)">50</button>
        </mat-menu>
      </span>
  
      <div (click)='start()' matTooltip="Skip to start" class='action' *ngIf='page > 1'>back to start</div>
      <p (click)='previous()' class='action'>previous</p>
      <div>{{page}}</div>
        <p (click)='next()' class='action'>next</p>
        <div> </div>
      </div>
    </div>
  

