import { Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { ApiService } from './../../../services/api.service'
import { MessagesService } from './../../../services/messages.service'
import { environment as devEnv } from '../../../../environments/environment'


@Component({
  selector: 'app-mall-create',
  templateUrl: './mall-create.component.html',
  styleUrls: ['./mall-create.component.scss']
})
export class MallCreateComponent implements OnInit {

  name
  phone

  mall      = {}
  shade     = false
  mallInfo  = false
  mall_id   = 1
  image     = '../assets/images/error_image_1500.png'

  constructor(public api: ApiService,
              public message: MessagesService,
              public router: Router) { }

  ngOnInit(): void {
  }

  submitMallInfo(i){
    this.mall['name'] = i.name
    this.mall['description'] = i.description
    this.mall['email'] = i.email
    this.mall['phone'] = i.phone

    if(i.name && i.email && i.phone && this.mall['physical_address']){
      this.mallInfo = true
    }

    if(!this.mall['physical_address'] && i.name && i.email && i.phone){
      this.message.showSnack('please select an address for this mall')
    }
  }

  back(){
    this.mallInfo = false
  }

  submitMall(){
    this.mall['image'] = 'image'
    let mall = [this.mall]
    
    this.api.post('catelog', `malls`, mall).subscribe((mall: any) => {
      this.shade = true
      this.message.showSnack(`succesfully created mall ${this.mall['name']}`)
      this.mall_id = mall[0].id
      this.imageUpload()
    })
  }

  onAutocompleteSelected(e){
    this.mall['latitude'] = e.geometry.location.lat()
    this.mall['longitude'] = e.geometry.location.lng()
    this.mall['physical_address'] = e.formatted_address
    this.name = e.name
    this.phone = e.formatted_phone_number
  }

  // =====================
  //   PRODUCT IMAGE
  // =====================

  imageAttempt = false
  imageFormat = false

  imageFile: {resource: string, file: any, name: string}


  imagePreview(event) {
    if (event.target.files && event.target.files[0]) {
        let reader = new FileReader()

        reader.onload = (_event: any) => {
            this.imageFile = {
                resource: _event.target.result,
                file: event.srcElement.files[0],
                name: event.srcElement.files[0].name
            }

            if(this.imageFile.name.includes('png') || this.imageFile.name.includes('jpg') || this.imageFile.name.includes('jpeg') ||  this.imageFile.name.includes('webp') ){
              this.imageFile.name = 'image.png'
              this.imageFormat = true
              this.image = this.imageFile.resource
          } else {
            this.message.showSnack(`oops!   that is an unsupported image type`)
          }

        }
        reader.readAsDataURL(event.target.files[0])
    }
  }

  imageUpload(){
    setTimeout(() => {
      this.message.showSnack('uploading banner image')
    }, 4000)
    var myHeaders = new Headers()

    myHeaders.append("Authorization", "Bearer " + this.api.token)

    var formdata = new FormData()

    formdata.append("file", this.imageFile.file, this.imageFile.name)
    formdata.append("body", "{ \"resource\":\"malls\", \"resource_id\":"+ this.mall_id +",\"x_dim\":1, \"y_dim\":1}")
    // console.log("body", "{ \"resource\":\"malls\", \"resource_id\":"+ this.mall_id +",\"x_dim\":1, \"y_dim\":1}")

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata
    }

    // Dev environment

    if(!devEnv.production){
    fetch("https://api.api-dev.quench.org.za/catalogue/v1/image", requestOptions)
    .then(response => response.text())
    .then(result => {
      setTimeout(() => {
        this.message.showSnack('banner image Uploaded')
        this.shade = false
        this.router.navigate([`/malls/${this.mall_id}`])

      }, 9000)
    })
      
    .catch(error => console.log('error', error))
    
    }

    // production environment
    else if(devEnv.production){
      fetch("https://api.quench.org.za/catalogue/v1/image", requestOptions)
      .then(response => response.text())
      .then(result =>
        setTimeout(() => {
          this.message.showSnack('banner image Uploaded')
          this.shade = false
          this.router.navigate([`/malls/${this.mall_id}`])
        }, 9000))
      .catch(error => console.log('error', error))
    }
  }

}
