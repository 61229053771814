<table  mat-table [dataSource]='requests' matSort>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
      <td mat-cell *matCellDef="let request"> 
        <P *ngIf='request.CreatedAt'>{{request.CreatedAt.substring(0,10) }}</P> 
        <P *ngIf='request.CreatedAt' class='order-time'>{{request.CreatedAt.substring(11,16) }}</P> 
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
      <td mat-cell *matCellDef="let request"> {{request.action}} </td>
    </ng-container>

    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> response code </th>
      <td mat-cell *matCellDef="let request"> 
          <p *ngIf='request.reponse_code == 200'  class='okay'>{{request.reponse_code}}</p> 
          <p *ngIf='request.reponse_code == 401'  class='unauth'>{{request.reponse_code}}</p> 
      </td>
    </ng-container>

    <ng-container matColumnDef="url">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> URL </th>
      <td mat-cell *matCellDef="let request"> <p> {{request.url}} </p></td>
    </ng-container>

    <ng-container matColumnDef="result">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> result </th>
        <td mat-cell *matCellDef="let request" > <p class='results action' (click)='resultsDialog(request)'>results</p> </td>
      </ng-container>

    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row 
        *matRowDef="let request; columns: Columns;"></tr>
  </table>