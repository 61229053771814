<div class="back action" routerLink="/posts">
  <mat-icon>arrow_back</mat-icon>
  <p>posts</p>
</div>

<div class="detail" *ngIf="posts.post">
  <div class="images">
    <img
      class="image"
      src="{{ posts.post.image }}"
      onerror="this.src='assets/placeholders/logo-placeholder.png' "
      alt=""
    />
    <img *ngIf="!isDischemUser" class="desktopImage" src="{{ posts.post.desktopImage }}" alt="" />
    <span *ngIf="!isDischemUser" class="edit-images-button action" (click)="updateImages()"
      >update images</span
    >
    <span *ngIf="isDischemUser" class="edit-images-button action" (click)="updateImages()"
    >update image</span
  >
  </div>

  <hr />
  <div class="title">
    <h4>{{ posts.post.title }}</h4>
    <span class="update-button action" (click)="editPost()">update </span>
  </div>
  <p class="body">{{ posts.post.body }}</p>
  <hr />
</div>

<table class="table">
  <thead>
    <tr>
      <th class="header" id="sort">Sort</th>
      <th class="header" id="icon"></th>
      <th class="header" id="icon">Name</th>
      <th class="header"></th>
    </tr>
  </thead>
  <tbody cdkDropList (cdkDropListDropped)="posts.SortProducts($event)">
    <tr
      class="action"
      *ngFor="let item of posts.postdata"
      cdkDrag
      cdkDragLockAxis="y"
    >
      <th class="sort">
        <p><mat-icon>sort</mat-icon></p>
      </th>
      <th class="image">
        <img
          class="img"
          src="{{ item.image }}"
          alt=""
          onerror="this.src='assets/placeholders/placeholder-img.png' "
        />
      </th>
      <th
      >
        <p>{{ item.name }}</p>
      </th>
      <ng-template #postTitle
        ><th>
          <p>{{ item.title }}</p>
        </th>
      </ng-template>
      <th>
        <mat-icon
          class="product-delete"
          (click)="removePostProduct(item)"
          matTooltip="remove item from section"
          >delete_outline</mat-icon
        >
      </th>
    </tr>
  </tbody>
</table>

<div id="pager">
  <div class="page">
    <button
      id="{{ auth.expand ? 'create-expand' : 'create' }}"
      mat-flat-button
      class="button"
      (click)="addPostProduct()"
    >
      add products
    </button>
  </div>
</div>
