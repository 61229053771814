import { ApiService }                           from 'src/app/services/api.service'
import { MessagesService }                      from 'src/app/services/messages.service'
import { environment as devEnv }                from '../../../../environments/environment'
import { ActivatedRoute, Router }               from '@angular/router'
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef}             from '@angular/material/dialog'
import { Component, OnInit, Inject, EventEmitter } from '@angular/core'


declare const google: any;

@Component({
  selector: 'app-store-detail',
  templateUrl: './store-detail.component.html',
  styleUrls: ['./store-detail.component.scss'],
})
export class StoreDetailComponent implements OnInit {

  ID
  id

  // pause variables
  reason
  fulfimentStore

  loop                = false
  paused              = false
  fulfilment_store    = false
  slot_capacity       = 0
  store_slot_capacity = false

  // store details

  name
  store
  image
  store_name
  retailer_id

  edit                = false
  page                = 1
  limit               = 10
  search              = ''
  active              = false
  retries             = 0

  edit_store_name       = false

  editStoreName(){
    this.edit_store_name = !this.edit_store_name
  }


  constructor(public api: ApiService,
              public message: MessagesService,
              public route: ActivatedRoute,
              public router: Router,
              public dialog: MatDialog,) { 
              }

  ngOnInit(): void {
    this.id = JSON.parse(this.route.snapshot.paramMap.get('id'))
    this.getStore()
    this.checkIfFulfilmentStore()
    // this.getPauseStores()
    }

    // ========================
  //      STORE PAUSE
  // ========================
  
  checkIfFulfilmentStore(){
    this.api.getList("fulfilment", `admin/pause_store_by_id/${this.id}` ).subscribe((data: any) =>{
      if(data.store){

        // console.log(data.store)
        
        if(data.store.slot_capacity > 0){
          this.store_slot_capacity = true
        } else {
          this.store_slot_capacity = false
        }
        this.slot_capacity = data.store.slot_capacity
        this.fulfimentStore = data.store
        this.fulfilment_store = true
        this.ID = data.store.ID

        // console.log(data.store)

        this.loop = data.store.loop
        this.checkIfPaused()
      } else {
        return
      }
    }, error => {
        return error
    })
}

  checkIfPaused(){
    this.reason = this.fulfimentStore.reason
    if(this.fulfimentStore.pause){
      this.paused = true
    }
  }

  pausePlayStore(){
    if(!this.fulfimentStore){
      this.createPauseDialog()
    } 

    else {
      let pause = {
        pause: this.paused
      }

      this.api.patch('fulfilment', `admin/pause_stores/${this.ID}`, pause) .subscribe((data:any) => {
        this.message.showSnack('Store pause has been updated ')
        this.checkIfFulfilmentStore()
      },(error:any) => {
        if(error.status === 400){
        }
      })
    }

  }

  createPauseDialog() {
    // Create and store dialog object
  const dialog = this.dialog.open(PauseStoreDialog, {
    width: '90vw',
    height: '230px',
      data: {
        store_id: Number(this.id),
        name: this.store_name,
        reason: this.reason,
        store: this.fulfimentStore
      }
    })

    // Create subscription
  dialog.afterClosed().subscribe(() => {

    setTimeout(() => {
      this.checkIfFulfilmentStore()
      }, 400)
    })
  }

  TimeSlots() {
    // Create and store dialog object
  const dialog = this.dialog.open(StoreTimeSlots, {
    width: '50vw',
    height: '250px',
      data: {
        store_id: this.id,
        name: this.store_name,
        store: this.fulfimentStore,
        loop: this.loop,
        fulfilment_store: this.fulfilment_store,
        slot_capacity: this.slot_capacity
      }
    })

    // Create subscription
  dialog.afterClosed().subscribe(() => {
    this.checkIfFulfilmentStore()
    })
  }

  // ========================
  //        STORE
  // ========================

  async getStore(){
    this.api.get('catelog', `stores`, this.id).subscribe((data: any) => {

      this.image                  = data.banner_image
      this.name                   = data.name
      this.retailer_id            = data.retailer_id
      
      this.store      = data
      this.store_name = data.name

    }, (error:any) => {
      this.message.showSnack(error.error)
      if(this.retries < 3){
        if(error.status !== 401){
          this.retries++
          this.getStore()
        }
      }
    })
  }

  fullfilment_store = {
    loop: false
  }


  // ========================
  //    STORE LOOP TOGGLE
  // ========================


  toggleLoopStatus(){
    let loop = {
      store_id: this.store.id,
      name: this.store.name,
      loop: this.loop
    }

    if(this.fulfilment_store){
        this.api.patch(`fulfilment`, `admin/stores/${this.fulfimentStore.ID}`, loop).subscribe((data: any) => {
          this.message.showSnack(`Succefully updated store on loop platform`)
          this.checkIfFulfilmentStore()
        })
    } else {
      this.api.post('fulfilment', `admin/pause_stores`, loop).subscribe((data:any) => {
        this.api.patch(`fulfilment`, `admin/stores/${data.data.ID}`, loop).subscribe((data: any) => {
          this.message.showSnack(`Succefully updated store on loop platform`)
          this.checkIfFulfilmentStore()
        })
      })
    }
  }

  toggleStoreScheduling(){
    let loop = {
      store_id: this.id,
    }

    if(this.slot_capacity > 0){
      loop["slot_capacity"] = 0

      this.api.patch(`fulfilment`, `admin/stores/${this.fulfimentStore.ID}`, loop).subscribe((data: any) => {
        this.message.showSnack(`Succesfully deactivated store scheduling`)
        this.slot_capacity = 0
      })

    } else {
      this.TimeSlots()
    }
  }


  // ========================
  //    UPDATE STORE BANNER
  // ========================

  banner_image

  banner_update = false

  editBanner(){
    this.banner_update = !this.banner_update
  }

  updateBannerImage(){
    this.imageUpload()
  }

  imageFormat = false
  imageFile: {resource: string, file: any, name: string}

  imagesPreview(event) {
    if (event.target.files && event.target.files[0]) {
        let reader = new FileReader()

        reader.onload = (_event: any) => {
            this.imageFile = {
                resource: _event.target.result,
                file: event.srcElement.files[0],
                name: event.srcElement.files[0].name
            }

            if(this.imageFile.name.includes('png') || this.imageFile.name.includes('jpg') || this.imageFile.name.includes('jpeg') ||  this.imageFile.name.includes('webp') ){
              this.imageFile.name = 'image.png'
              this.imageFormat = true
              this.image = this.imageFile.resource
          } else {
            this.message.showSnack(`oops!   that is an unsupported image type`)
          }

        }
        reader.readAsDataURL(event.target.files[0])
    }
  }

  imageUpload(){
    this.message.showSnack('Updating banner image')

    setTimeout(() => {
      this.message.showSnack('Updating banner image')
    }, 3000)

    var myHeaders = new Headers()

    myHeaders.append("Authorization", "Bearer " + this.api.token)

    var formdata = new FormData()

    formdata.append("file", this.imageFile.file, this.imageFile.name)
    formdata.append("body", "{ \"resource\":\"stores\", \"resource_id\":"+ this.id +",\"x_dim\":1, \"y_dim\":1, \"resource_field\": \" banner_image\", \"ForDesktop\":false}\n\n");

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata
    }

    if(!devEnv.production){
      fetch("https://api.api-dev.quench.org.za/catalogue/v1/image", requestOptions)
        .then(response => response.text())
        .then(result =>
          setTimeout(() => {
            this.message.showSnack('succesfully updated banner image')

            setTimeout(() => {
              this.getStore()

              setTimeout(() => {
                this.editBanner()
              }, 2500)

            }, 6000)

          }, 10000)
          )
        .catch(error => {
          this.message.showSnack(error.error)
        })
      }

      // production env 
      else if(devEnv.production){
        fetch("https://api.quench.org.za/catalogue/v1/image", requestOptions)
        .then(response => response.text())
        .then(result =>
          setTimeout(() => {
            if(result == ''){
              this.message.showSnack('something went wrong')
            } else {
              this.message.showSnack('succesfully updated banner image')
            }

            setTimeout(() => {
              this.getStore()

              setTimeout(() => {
                this.editBanner()
              }, 1000)

            }, 1500)

          }, 3000)
          )
        .catch(error => {
          this.message.showSnack(error.error)
        })
      }
    }


  // ========================
  //    STORE STATE TOGGLE
  // ========================

  storeStateToggle(store){
    let storeState = {
      id: this.id,
      active: store.active
    }


    this.api.patch('catelog', `stores`, storeState).subscribe((store: any) => {
      if(store.active == true){
        this.message.showSnack('Succesfully Activated Store')
      } else {
        this.message.showSnack('Successfully De-activated Store')
      }
      this.getStore()
    } , error => {
      this.message.showSnack(error.error);
    })
    }

  ​}


@Component({
  selector: 'pause-store-dialog',
  templateUrl: 'pause-store-dialog.html',
  styleUrls: ['pause-store-dialog.component.scss']
})
export class PauseStoreDialog {
  store_id
  name
  reason
  currentReason
  store

  constructor(@Inject(MAT_DIALOG_DATA) public data: { store_id, name,reason, store }, private api : ApiService, private message: MessagesService) {

  }

  ngOnInit(): void {
    this.store_id = this.data.store_id
    this.name = this.data.name
    this.currentReason = this.data.reason
    if(this.data.reason){
      this.reason = this.currentReason
    }
    if(this.data.store !== undefined){
      this.store = this.data.store
      console.log(this.data.store)
    }
  }

  createPause(){
      if(!this.currentReason){
      var data = {
        store_id: this.store_id,
        name: this.name,
        pause: true,
        reason: this.reason
      }
      
      // console.log(data)
      this.api.post('fulfilment', `admin/pause_stores`, data).subscribe((data:any) => {})
    } else {
      let reason =     {
        name: this.name,
        pause: true,
        reason: this.reason
      }
  
      this.api.patch('fulfilment', `admin/pause_stores/${this.store.ID}`, reason).subscribe((data:any) => {
        console.log(data)
      },(error:any) => {
        if(error.status === 400){
          return
        }
      });
  
    }
  }


}

@Component({
  selector: 'store-time-slots-dialog',
  templateUrl: 'store-time-slots.html',
  styleUrls: ['pause-store-dialog.component.scss']
})
export class StoreTimeSlots {

  heading = ""
  currentCapacity = 0

  slot_capacities = [0, 1, 2, 3, 4, 5, "more"]
  more = false

  slot_cap
  more_slots

  onAdd       = new EventEmitter()


  constructor(@Inject(MAT_DIALOG_DATA) public data: { store_id, name, store , loop, fulfilment_store, slot_capacity}, 
                                       private api : ApiService, 
                                       private message: MessagesService, 
                                       public dialogRef: MatDialogRef<StoreTimeSlots>) {
  }

  ngOnInit(): void {
    if(this.data.slot_capacity > 1){
      this.heading = "update store slot capacity"
      this.currentCapacity = this.data.slot_capacity
    } else {
      this.heading = "What is this stores slot capacity?"
    }
  }

  selectSlot(slot){
    if (slot == "more"){
      this.more = true
    }
  }

  setSlotCapacity(){
    let loop = {
      store_id: this.data.store_id,
      name: this.data.name,
      loop: this.data.loop,
      slot_capacity: this.slot_cap
    }

    if(this.more){
      loop.slot_capacity = this.more_slots
    }
    
    if(this.data.fulfilment_store){
      this.api.patch(`fulfilment`, `admin/stores/${this.data.store.ID}`, loop).subscribe((data: any) => {
        this.message.showSnack(`Succesfully updated store slot capacity`)
        this.closeDialog()
      })
    } else {
    this.api.post('fulfilment', `admin/pause_stores`, loop).subscribe((data:any) => {
      this.api.patch(`fulfilment`, `admin/stores/${data.data.ID}`, loop).subscribe((data: any) => {
        this.data.store = data.data
        this.data.fulfilment_store = true
        this.setSlotCapacity()
      })
     })
    }
  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

}