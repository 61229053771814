import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ApiService } from 'src/app/services/api.service'
import { MessagesService } from 'src/app/services/messages.service'

@Component({
  selector: 'app-retailer-stores',
  templateUrl: './retailer-stores.component.html',
  styleUrls: ['./retailer-stores.component.scss']
})
export class RetailerStoresComponent implements OnInit {
  Columns = ['name', 'address', 'email', 'phone']
  
  id
  stores
  allStores
  searchTerm

  page    = 1
  limit   = 10
  search  = false
  retries = 0

  
  constructor(public api: ApiService,
              public route: ActivatedRoute,
              public message: MessagesService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getRetailerStores()
  }

  getRetailerStores(){
    this.api.getList('catelog', `stores/${this.id}/retailer?page=${this.page}&per_page=${this.limit}`).subscribe((stores:any) => {
      console.log(stores)
      this.stores = stores
    }, error => {
      this.message.showSnack(error.error)
      if(this.retries <3 && error.status !== 401){
        this.retries++
        this.getRetailerStores()
      }
    })
  }



  setLimit(limit){
    this.limit = limit
    this.getRetailerStores()
  }

  next(){
    this.page++
    this.getRetailerStores()
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.getRetailerStores()

    }
  }

}
