<div class="heading">
  <div></div>
  <h4>Edit Post Images</h4>
  <mat-icon (click)="closeDialog()">clear</mat-icon>
</div>

<div class="edit-body">
  <img
    class="edit-image"
    src="{{image}}"
    onerror="this.src='assets/placeholders/logo-placeholder.png' "
    alt=""
  />
  <span
    class="edit-images-button action"
    onclick="document.querySelector('#mobileInputFile').click()"
    >update mobile image</span
  >
  <input
    type="file"
    id="mobileInputFile"
    (change)="mobileImagePreview($event)"
    style="display: none"
  />

  <span *ngIf="!isDischemUser">
    <br />
    <img
      class="edit-image"
      src="{{desktopImage}}"
      onerror="this.src='assets/placeholders/logo-placeholder.png' "
      alt=""
    />
    <span
      class="edit-images-button action"
      onclick="document.querySelector('#desktopInputFile').click()"
      >update desktop image</span
    >
    <input
      type="file"
      id="desktopInputFile"
      (change)="desktopImagePreview($event)"
      style="display: none"
    />
  </span>
</div>

<div class="save">
  <button mat-flat-button class="button cancel" (click)="closeDialog()">
    Cancel
  </button>
  <button mat-flat-button class="button" (click)="updateImages()">Save</button>
</div>
