<div class="search">
    <mat-icon (click)='getCarts(search)' matTooltip='search' class='search-icon' [style.left]="inputTouch ? '-20px' : '10px' ">search</mat-icon>
    <mat-icon *ngIf='search' (click)='clearSearch()'  matTooltip='clear' class='clear-icon'>clear</mat-icon>
    <input matInput (keydown.enter)='getCarts(search)' [(ngModel)]="search" placeholder="Search"  #input (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
  </div>



<table mat-table [dataSource]='open_carts' matSort>

  <ng-container matColumnDef="placed">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Placed</th>
    <td mat-cell *matCellDef="let cart" >
      <P *ngIf='cart.CreatedAt'>{{cart.CreatedAt | date: 'MMM d, y'}}</P> 
      <P *ngIf='cart.CreatedAt' class='order-time'>{{cart.CreatedAt | date: 'h:mm:ss a'}}</P> 
      <P *ngIf='!cart.CreatedAt'>No Order Date</P> 
    </td>
  </ng-container>

  <ng-container matColumnDef="store">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Store</th>
    <td mat-cell *matCellDef="let cart">
      <p *ngIf='cart.store_name'>{{cart.store_name}}</p>
      <p *ngIf='!cart.store_name'>No Store Name</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="customer">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
    <td mat-cell *matCellDef="let cart">
      <span class="customer">
        <mat-icon>person</mat-icon>
        <p *ngIf='cart.customer_name' matTooltip='{{cart.uid}}'>{{cart.customer_name}}</p>
        <p *ngIf='!cart.customer_name'>No Customer Name</p>
      </span>
    </td>
  </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let cart">
        <p *ngIf="cart.status && cart.status == 'OPEN' "  id='open-status' class='status'> {{cart.status}}</p>
        <p *ngIf='!cart.status'> No Status</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class='text-align'>Phone</th>
      <td mat-cell *matCellDef="let cart" class='text-align'> 
        <p *ngIf='cart.customer_phone'>{{cart.customer_phone}}</p>
        <p *ngIf='!cart.customer_phone'>No Phone Number</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="origin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Origin</th>
      <td mat-cell *matCellDef="let cart"> 
        <p *ngIf='cart.origin'>{{cart.origin}}</p>
        <p *ngIf='!cart.origin'>Origin not recorded</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="distance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class='text-align'>Distance</th>
      <td mat-cell *matCellDef="let cart" class='text-align'>
        <p *ngIf='cart.distance_text'> {{cart.distance_text}}</p> 
        <p *ngIf='!cart.distance_text'>No Distance</p> 
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
      <td mat-cell *matCellDef="let cart" class='amount'>
        <p *ngIf='cart.amount'> R {{cart.amount | number:"1.2-2" }}</p> 
        <p *ngIf='!cart.amount'>No Amount</p> 
      </td>
    </ng-container>

  <tr mat-header-row *matHeaderRowDef="Columns"></tr>
  <tr mat-row class='action' routerLink='/cart/{{cart.ID}}' *matRowDef="let cart; Columns: Columns;"></tr>
</table>


<div id="pager" >
  <div class="page"> 


  <div> Per page:</div> 
  <span class="silo-filter pagination">
    <button mat-button [matMenuTriggerFor]="paginate">
      <span>{{limit}}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #paginate="matMenu" >
      <button mat-menu-item (click)="setLimit(10)">10</button>
      <button mat-menu-item (click)="setLimit(20)">20</button>
      <button mat-menu-item (click)="setLimit(50)">50</button>
    </mat-menu>
  </span>

  <div (click)='start()' matTooltip="Skip to start" class='action' *ngIf='page > 1'>back to start</div>
  <p (click)='previous()' class='action'>previous</p>
  <div>{{page}}</div>
    <p (click)='next()' class='action'>next</p>
    <div> </div>
  </div>
</div>
