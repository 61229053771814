import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService }        from './../../../services/api.service'
import { AuthService }       from './../../../services/auth.service'
import { MessagesService }   from './../../../services/messages.service'
import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-dashboard-daily',
  templateUrl: './dashboard-daily.component.html',
  styleUrls: ['./dashboard-daily.component.scss']
})
export class DashboardDailyComponent implements OnInit {


  currentDayOrdersIsUp    = false
  currentMonthOrdersIsUp  = false
  currentDaySignUpsIsUp   = false
  currentMontlySignUpIsUp = false


  quickStats          = []
  lastWeekOrders      = [] 
  lastWeekSignUps     = []
  threeMonthsOrders   = []
  threeMonthSignUps   = []


  signUpText            = 'Quench sign ups over last week'
  orderText             = 'Quench orders over last week'
  threeMonthsOrderText  = 'Quench orders over last 3 months'
  threeMonthsSignUpText = 'Quench sign ups over last 3 months'


  constructor(private api: ApiService,
              private message: MessagesService,
              public auth: AuthService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getSaleStats()
    this.getOrderStats()
    this.getCustomerStats()
    this.getTrackingStats()


    this.spinner.show()

    
    // chart responsiveness
    if(window.innerWidth > 1100){
      this.width = window.innerWidth/2 - 100
      } else {
        this.width = window.innerWidth - 50
      }
  }

  width  = 800
  height = 300

      // chart responsiveness
      onResize(event){
        var w = event.target.innerWidth
    
    
        if(w > 1100){
          this.width = w/2 - 100
        } else {
          this.width = w - 50
        }
    }

    // CHART OPTION SETTINGS
    quench = {
      pointSize: 5,
      curveType: 'function',
      colors: ['#ff4081', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
      chartArea:{left:50,top:10,right:10,bottom:60 ,width: '100%', height: '100%'},
    }

  getOrderStats(){
    this.api.getList("fulfilment","dashboard/stores").subscribe((result:any)=>{

      // last seven day orders
      let sevenDaySlice   = result.days.length - 7

      let dailyOrders = result.days.reverse().splice(sevenDaySlice)

      dailyOrders.forEach((day:any)=>{
        this.lastWeekOrders.push([day.effective_date.substring(0,10), day.total ])
      })

      let dayLength = dailyOrders.length
      if(dailyOrders[dayLength - 1].total > dailyOrders[dayLength - 2].total){
        this.currentDayOrdersIsUp = true
      }

      // last three month orders
      let threeMonthSlice  = result.months.length - 3
      let monthlyOrders   = result.months.splice(threeMonthSlice)

      monthlyOrders.forEach((month:any)=>{
        this.threeMonthsOrders.push([month.effective_date.substring(0,10), month.total ])
      })

      let monthLength = monthlyOrders.length
      if(monthlyOrders[monthLength - 1].total > monthlyOrders[monthLength - 2].total){
        this.currentMonthOrdersIsUp = true
      }
    })
  }

  getCustomerStats(){
    this.api.getList(`auth`, `admin/reports/users`).subscribe((result: any) => {
      // console.log(result)

      // last weeks sign ups
      let week = result.signups_per_day
      let daysLength  = result.signups_per_day.length

      if(result.signups_per_day[daysLength - 1].total > result.signups_per_day[daysLength - 2].total){
        this.currentDaySignUpsIsUp = true
      } 


      if(result.signups_per_day.length > 6){
        let slice = result.signups_per_day.length - 7
        week = result.signups_per_day.slice(slice)
      }

      week.forEach(day => {
        this.lastWeekSignUps.push([day.day.substring(0,10), day.total])
      })

        // last three months sign ups
        let threeMonthSlice  = result.signups_per_month.length - 3
        let monthlyOrders   = result.signups_per_month.splice(threeMonthSlice)

        monthlyOrders.forEach((month:any)=>{
          this.threeMonthSignUps.push([month.month.substring(0,10), month.total ])
        })

        let monthLength = monthlyOrders.length

        if(monthlyOrders[monthLength - 1].total > monthlyOrders[monthLength - 2].total){
          this.currentMontlySignUpIsUp = true
        }

    })
  }


  getTrackingStats(){
    this.api.getList(`tracking`, `admin/dashboard`).subscribe((result: any) => {

      let total_active_sessions
      let view_to_cart
      let cart_to_paid

      result.data.forEach(item => {
        if(item.hasOwnProperty('total_active_sessions')){
          total_active_sessions = item.total_active_sessions
        }
        if(item.hasOwnProperty('conversion')){
          view_to_cart = item.conversion.view_to_cart
          cart_to_paid = item.conversion.cart_to_paid
        }
      })

      let activeSessions = {
        name: 'active sessions',
        data: total_active_sessions,
        image: '/assets/images/active_sessions.svg'
      }

      let viewToCart = {
        name: 'conversion rate view to cart',
        data: view_to_cart,
        image: '/assets/images/view_to_cart.svg',
        tag: 'conversion'
      }

      let cartToPaid = {
        name: 'conversion rate cart to paid',
        data: cart_to_paid,
        image: '/assets/images/cart_to_paid.svg',
        tag: 'conversion'
      }

      

      this.quickStats.push(activeSessions, viewToCart, cartToPaid)
      // console.log(this.quickStats)
    })
  }

  getSaleStats(){
    this.api.getList(`fulfilment`, `dashboard/sales`).subscribe((result: any) => {
      // console.log(result)
    })
  }
}
