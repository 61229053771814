import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

// pusher.service.ts
declare const Pusher: any;

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  pusher: any;
  channel: any;

  constructor() {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      encrypted: true,
      auth: {
        headers: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('AUTH_TOKEN'),
        },
      },
    });
    this.channel = this.pusher.subscribe('admin-channel');
  }

  unsubscribe() {
    this.pusher.unsubscribe('admin-channel');
  }

  subscribe() {}
}
