<div class="flex-items" >

  <div class="item-one">

    <div class="card" *ngIf='user'>

      <div class=" action customer-status" >
        <span class=' heading'>
          <h3>{{user.first_name}} {{user.last_name}} &nbsp; </h3>
        </span>
        <mat-slide-toggle id='mat-icon-title' [(ngModel)]="user.active" (change)="toggleUserStatus()"></mat-slide-toggle>
      </div>

      <div class="roles">
        <p class='role default-address' *ngFor='let role of currentRoles'>{{role.name}}</p>
        <mat-icon class='edit-roles action' (click)='rolesDialog()'>edit</mat-icon>
      </div>

      <hr>

      <div class="contact">
          <mat-icon>event</mat-icon>
          <p>{{user.CreatedAt | date: 'MMM d, y - h:mm a'}}</p>
      </div>
      <div class="contact">
          <mat-icon class='material-icons-outlined'>email</mat-icon>
          <input *ngIf='updateUserEmail' type="text" placeholder="email here" [(ngModel)]='email'>
          <button *ngIf='updateUserEmail' mat-flat-button (click)='saveUser()'>save</button>
          <mat-icon *ngIf='updateUserEmail' (click)='updateEmail() ' class='update action'>clear</mat-icon>
          <p *ngIf='!updateUserEmail'>{{user.email}}</p>

          <!-- check if email is verified -->
          <div class="verified" *ngIf='user.email_verified'>
            <mat-icon class='true verified'>check_circle_outline</mat-icon>
            <p class='true'  >verified</p>
          </div>
          <div class="verified" *ngIf='!user.email_verified'>
            <mat-icon class='false verified'>clear</mat-icon>
            <p class='false'  >unverified</p>
          </div>

          <mat-icon *ngIf='!updateUserEmail' matTooltip='update user email' class='update action' (click)='updateEmail()'>edit</mat-icon>
      </div>    
      <div class="contact">
        <mat-icon class='material-icons-outlined'>phone</mat-icon>
        <input *ngIf='updateUserPhone' type="text" placeholder="email here" [(ngModel)]='phone'>
        <button *ngIf='updateUserPhone' mat-flat-button (click)='saveUser()'>save</button>
        <mat-icon *ngIf='updateUserPhone' (click)='updatePhone() ' class='update action'>clear</mat-icon>
        <p *ngIf='!updateUserPhone'>{{user.phone}}</p>

        <!-- check if phone is verified -->
        <div class="verified" *ngIf='user.phone_verified'>
          <mat-icon class='true verified'>check_circle_outline</mat-icon>
          <p class='true'  >verified</p>
        </div>
        <div class="verified" *ngIf='!user.phone_verified'>
          <mat-icon class='false verified'>clear</mat-icon>
          <p class='false'  >unverified</p>
        </div>

        <mat-icon *ngIf='!updateUserPhone' matTooltip='update user email' class='update action' (click)='updatePhone()'>edit</mat-icon>
      </div>  
      <div class="contact action" *ngIf='!viewMore' (click)='expandUserInfo()'>
        <mat-icon class='material-icons-outlined'>arrow_circle_down</mat-icon>
        <p>view more...</p>
      </div>  
      <div class="contact action" *ngIf='viewMore' (click)='expandUserInfo()'>
        <mat-icon class='material-icons-outlined'>arrow_circle_up</mat-icon>
        <p>view less</p>
      </div>  
      <div class="contact action view-more" *ngIf='viewMore'>
        <p>Loop reference</p>
        <p *ngIf="user.reverse_reference !== '' ">{{user.reverse_reference}}</p>
        <p *ngIf="user.reverse_reference == '' " class="empty">reference not found</p>
      </div>  
      <div class="contact action view-more" *ngIf='viewMore'>
        <p>Last login</p>
        <p>{{user.last_login | date: 'MMM d, y - h:mm a' }}</p>
      </div>  
      <div class="contact action view-more" *ngIf='viewMore'>
        <p>Brand</p>
        <p>{{user.brand}}</p>
      </div>  
      <div class="contact action view-more" *ngIf='viewMore'>
        <p>Brand id</p>
        <p>{{user.brand_id}}</p>
      </div>  
      <div class="contact action view-more" *ngIf='viewMore'>
        <p>Migrated</p>
        <p>{{user.migrated}}</p>
      </div>  
      <div class="contact action view-more" *ngIf='viewMore'>
        <p>Remember</p>
        <p *ngIf="user.remember">
          <mat-icon class='true'> task_alt</mat-icon>
        </p>
        <p *ngIf="!user.remember">
          <mat-icon class='false'>clear</mat-icon>
          </p>
      </div>  
    </div>

    <div class="card">
      <h5>Additional information</h5>
      <hr>

      <!-- nearest depots -->
      <div class="contact action" *ngIf='user_wallet' (click)='nearestDepots()'>
        <mat-icon>store</mat-icon>
        <p>Nearest Stores</p>
        <mat-icon class='update' *ngIf='!showNearestDepots' >expand_more</mat-icon>
        <mat-icon class='update' *ngIf='showNearestDepots'>expand_less</mat-icon>
      </div>  
      <div class="contact no-hover loyalties" *ngIf='showNearestDepots'>
        <div class="roles no-margin" *ngFor='let depot of nearestStores'>
          <p  class='role default-address' >{{depot.name}}</p>
        </div>
      </div>

      <!-- wallet -->
      <div class="contact action" *ngIf='user_wallet' (click)='updateWallet()'>
        <mat-icon>account_balance_wallet</mat-icon>
        <p>Wallet</p>
        <mat-icon class='update' *ngIf='!updateUserWallet' >expand_more</mat-icon>
        <mat-icon class='update' *ngIf='updateUserWallet'>expand_less</mat-icon>
      </div>  
      <div class="contact no-hover" *ngIf='updateUserWallet'>
        <p>R {{user_wallet.amount | number:'1.2-2'}}</p>
        <mat-icon class='action' (click)='updateWalletAmount()'>edit</mat-icon>
      </div>  
  
      <!-- roles -->
      <div class="contact action"(click)='updateRoles()'>
        <mat-icon>people</mat-icon>
        <p>Roles</p>
        <mat-icon class='update' *ngIf='!updateUserRoles' >expand_more</mat-icon>
        <mat-icon class='update' *ngIf='updateUserRoles'>expand_less</mat-icon>
      </div>  
      <div class="contact no-hover" *ngIf='updateUserRoles'>
        <div class="roles no-margin">
          <p class='role default-address' *ngFor='let role of currentRoles'>{{role.name}}</p>
        </div>
        <mat-icon class='action' (click)='rolesDialog()'>edit</mat-icon>
      </div> 
  
          <!-- Loyalty -->
        <div class="contact action"(click)='updateLoyalty()'>
          <mat-icon>card_membership</mat-icon>
          <p>Loyalty</p>
          <mat-icon class='update' *ngIf='!updateUserLoyalty' >expand_more</mat-icon>
          <mat-icon class='update' *ngIf='updateUserLoyalty'>expand_less</mat-icon>
        </div>  
        <div class="contact no-hover loyalties" *ngIf='updateUserLoyalty'>
          <div class="roles no-margin" *ngFor='let loyalty of loyalties'>
            <p class='role default-address' >{{loyalty.loyalty_type}}</p>
            <p  >{{loyalty.loyalty_number}}</p>
            <mat-icon class='action' (click)="deleteLoyalty(loyalty)">delete_outline</mat-icon>
          </div>
        </div> 
        <p (click)='addLoyalty()' class='loyalty-button action' mat-flat-button *ngIf='updateUserLoyalty && loyalties.length < 2'><mat-icon>add_circle_outline</mat-icon> add Loyalty</p>
  
    </div>

  </div>


<!-- user general details -->
<div class="card" *ngIf='addressDetails'>

    <div class=" action customer-status" >
      <span class=' heading'>
        <h3>Address details </h3>
        <mat-icon class='true'  *ngIf='nearestStores.length > 0'>check_circle_outline</mat-icon>
        <p class='true' *ngIf='nearestStores.length > 0'>in zone</p>
        <mat-icon class='false'  *ngIf='nearestStores.length == 0'>clear</mat-icon>
        <p class='false' *ngIf='nearestStores.length == 0'>not in zone</p>
      </span>
    </div>

    <hr>

    <div class="contact">
        <mat-icon>location_on</mat-icon>
        <p>{{addressDetails.full_address}}</p>
        <span class='default-address' *ngIf='addressDetails.default_address'>default</span>
    </div>
    <div class="contact">
      <mat-icon class='material-icons-outlined'></mat-icon>
      <p *ngIf='addressDetails.province'>{{addressDetails.province}}</p>
      <p *ngIf='!addressDetails.province'>no province provided</p>
    </div>  
    <div class="contact">
      <mat-icon class='material-icons-outlined'></mat-icon>
      <p *ngIf='addressDetails.city'>{{addressDetails.city}}</p>
      <p *ngIf='!addressDetails.city'>no city provided</p>
    </div>  
    <div class="contact">
      <mat-icon class='material-icons-outlined'></mat-icon>
      <p *ngIf='addressDetails.suburb'> {{addressDetails.suburb}}</p>
      <p *ngIf='!addressDetails.suburb'> no suburb provided</p>
    </div>  
    <div class="contact">
      <mat-icon class='material-icons-outlined'></mat-icon>
      <p *ngIf='addressDetails.postal'>{{addressDetails.postal}}</p>
      <p *ngIf='!addressDetails.postal'>no postal code provided</p>
    </div>  
    <div class="contact">
        <mat-icon class='material-icons-outlined'>local_offer</mat-icon>
        <p *ngIf='addressDetails.label'>{{addressDetails.label}}</p>
        <p *ngIf='!addressDetails.label'>no address label provided</p>
    </div>     
    <div class="contact action" *ngIf='!viewMoreAddress' (click)='expandUserAddress()'>
      <mat-icon class='material-icons-outlined'>arrow_circle_down</mat-icon>
      <p>view more...</p>
    </div>  
    <div class="contact action" *ngIf='viewMoreAddress' (click)='expandUserAddress()'>
      <mat-icon class='material-icons-outlined'>arrow_circle_up</mat-icon>
      <p>view less</p>
    </div>  
    <div class="contact action view-more" *ngIf='viewMoreAddress'>
      <p > Created</p>
      <p>{{addressDetails.CreatedAt | date: 'MMM d, y - h:mm a' }}</p>
    </div>  
    <div class="contact action view-more" *ngIf='viewMoreAddress'>
      <p> last updated</p>
      <p>{{addressDetails.UpdatedAt | date: 'MMM d, y - h:mm a' }}</p>
    </div>  
    <div class="contact action view-more" *ngIf='viewMoreAddress'>
      <p>  Street</p>
      <p *ngIf='addressDetails.street'>{{addressDetails.street}}</p>
      <p *ngIf='!addressDetails.street'>no street provided</p>
    </div>  
    <div class="contact action view-more" *ngIf='viewMoreAddress'>
      <p>  Building</p>
      <p *ngIf='addressDetails.building'>{{addressDetails.building}}</p>
      <p *ngIf='!addressDetails.building'>no building provided</p>
    </div>  
    <div class="contact action view-more" *ngIf='viewMoreAddress'>
      <p> latitude</p>
      <p>{{addressDetails.latitude}}</p>
    </div>  
    <div class="contact action view-more" *ngIf='viewMoreAddress'>
      <p>  longitude</p>
      <p>{{addressDetails.longitude}}</p>
    </div>  

  </div>  

</div>


  <app-user-messages></app-user-messages>


