<div id="shade" *ngIf='shade || logoEdit' (click)='closeLogoEdit()'></div>

<div *ngIf='retailer' class="detail">

  <h1>{{retailer.name}}</h1>


  <div class="info" >

    <div class="image">
      <div class="vertical-center">
        <img src='{{retailer.image}}' alt="" onerror="this.src='assets/placeholders/placeholder.png' " id='image'>
        <input type="file" id="exampleInputFile" (change)="imagesPreview($event)" style="display: none;" >
      </div>
      <button mat-flat-button class=button (click)='editLogo()'>edit</button>

    </div>

      <div class="fields">
          <div class="field">
              <mat-icon>location_on</mat-icon>
              <p *ngIf='retailer.physical_address'>{{retailer.physical_address}}</p>
              <p *ngIf='!retailer.physical_address'>no address</p>
          </div>
          <div class="field">
              <mat-icon>email</mat-icon>
              <p *ngIf='retailer.email'>{{retailer.email}}</p>
              <p *ngIf='!retailer.email'>no email</p>
          </div>
          <div class="field">
              <mat-icon>phone</mat-icon>
              <p *ngIf='retailer.phone'>{{retailer.phone}}</p>
              <p *ngIf='!retailer.phone'>no phone</p>
          </div>
          <div class="field">
              <mat-icon>receipt_long</mat-icon>
              <p *ngIf='retailer.vat_num'>{{retailer.vat_num}}</p>
              <p *ngIf='!retailer.vat_num'>n/a</p>
          </div>
      </div>

  </div>

  <div class="logo-edit" *ngIf='logoEdit'>
    <div class="center">

      <div class="heading">
        <div></div>
        <h4>Edit Retailer Logo</h4>
        <mat-icon (click)='editLogo()'>clear</mat-icon>
      </div>
      <hr>

      <div class="image">
        <img  src="{{image}}" onerror="this.src='assets/placeholders/placeholder.png' ">
        <input type="file" id="exampleInputFile" (change)="imagesPreview($event)" style="display: none;" >
      </div>
        <button mat-flat-button class='button upload material-icons-outlined' onclick="document.querySelector('#exampleInputFile').click()" ><mat-icon>add</mat-icon>  upload image</button>
        <button mat-flat-button class='button save ' (click)='updateLogo()' [disabled]="imageAttempt ? 'false' : 'true'"><mat-icon>save</mat-icon> save</button>
    </div>
  </div>

