import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigate-user-accounts',
  templateUrl: './navigate-user-accounts.component.html',
  styleUrls: ['./navigate-user-accounts.component.scss']
})
export class NavigateUserAccountsComponent implements OnInit {

  current_page

  account_pages = [
    // {
    //   name: "summary",
    //   // route: "/list-accounts"
    // },
    {
      name: "accounts",
      // route: "/list-user-accounts"
    },
    {
      name: "earnings",
      // route: "/list-user-accounts"
    },
    {
      name: "payments",
      // route: "/list-user-accounts"
    },
    {
      name: "assets",
      // route: "/list-user-accounts"
    },
  ]

  constructor() { }

  ngOnInit(): void {
    if(localStorage.getItem('userAccountPage')){
      this.current_page = localStorage.getItem('userAccountPage');
    } else {
      this.current_page = 'summary'
    }
  }

// soos ons gesien het, die selectTab func ontvang die click event se data en dan set ons die current_page equal aan die tab name
// en dan save ons dit ook in localstorage om seker te maak as page refresh of as iemand weggaan van page dat dit die tab state onthou
  selectTab(tab){
    this.current_page = tab.name
    localStorage.setItem('userAccountPage', tab.name);
  }


}
