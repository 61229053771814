import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-retailer',
  templateUrl: './retailer.component.html',
  styleUrls: ['./retailer.component.scss']
})
export class RetailerComponent implements OnInit {

  id;  
  retailer;
  selectedTab = 'detail'


  selectTab(i){
    this.selectedTab = this.tabs[i].name
    localStorage.setItem('retailersTab', this.tabs[i].name)
  }

  tabs = [
    {name: 'detail'},
    // {name: 'metrics', },
    {name: 'stores',},
    {name: 'products'},
    // {name: 'orders'},
    {name: 'admins'},

  ]

  constructor(public api: ApiService,
              public route: ActivatedRoute,
              public message: MessagesService) { }

  ngOnInit(): void {
    if(localStorage.getItem('retailersTab')){
      this.selectedTab = localStorage.getItem('retailersTab')
    }
    this.id = this.route.snapshot.paramMap.get('id')
    this.getRetailer()
  }

  getRetailer(){
    this.api.getList('catelog', `retailers/${this.id}`).subscribe((retailer: any) => {
      this.retailer = retailer
      this.message.directory = this.retailer.name
    })
  }

  ngOnDestroy() {
    this.message.directory = '';
  }

}
