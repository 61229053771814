<div class="back action" routerLink="/payments">
    <mat-icon>arrow_back</mat-icon>
    <p>payments</p>
  </div>


<table mat-table [dataSource]="payments.batchPayment" matSort>
  
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let payment">
        <p *ngIf="payment.date">{{ payment.date.substring(0,10) }}</p>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>driver</th>
      <td mat-cell *matCellDef="let payment">
        <p *ngIf="payment.user_id">{{ payment.user_id }}</p>
        <p *ngIf="!payment.user_id" class="empty">user not specified</p>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let payment">
        <p>{{payment.status}}</p>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="payment_method">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>payment method</th>
      <td mat-cell *matCellDef="let payment">
        <p >{{payment.payment_method}}</p>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="account">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Name</th>
      <td mat-cell *matCellDef="let payment">
        <p *ngIf="payment.account_name">{{ payment.account_name }}</p>
        <p *ngIf="!payment.account_name" class="empty">no account provided</p>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="account_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Account number</th>
      <td mat-cell *matCellDef="let payment">
        <p *ngIf="payment.account_number">{{ payment.account_number }}</p>
        <p *ngIf="!payment.account_number" class="empty">no account number provided</p>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="bank">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Bank Name</th>
      <td mat-cell *matCellDef="let payment">
        <p *ngIf="payment.bank">{{payment.bank}}</p>
        <p *ngIf="!payment.bank">{{payment.bank}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="branch_code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Bank Branch Code</th>
      <td mat-cell *matCellDef="let payment">
        <p *ngIf="payment.branch_code">{{payment.branch_code}}</p>
        <p *ngIf="!payment.branch_code">{{payment.branch_code}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="mobile">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact</th>
      <td mat-cell *matCellDef="let payment">
        <p *ngIf="payment.mobile_number">{{payment.mobile_number}}</p>
        <p *ngIf="!payment.mobile_number" class="empty">contact not provided</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
      <td mat-cell *matCellDef="let payment">
        <p *ngIf="payment.amount">{{payment.amount | currency: "R ":"code"}}</p>
        <p *ngIf="!payment.amount" class="empty">amount not provided<</p>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row class="action" *matRowDef="let payment; columns: Columns"></tr>
  </table>
  