<div
  id="shade"
  *ngIf="banner_update"
  class="action"
  (click)="closeStoreEdit()"
></div>

<div *ngIf="store" class="detail">
  <div class="store-closed" *ngIf="paused">
    <p>*Please note that this store is closed due to:</p>
    <span>{{ reason }}</span>
    <br />
    <button
      mat-flat-button
      class="update-all-button"
      (click)="createPauseDialog()"
    >
      update
    </button>
  </div>

  <div
    class="image"
    id="banner"
    *ngIf="store.silo_id == 5 || store.silo_id == 20"
  >
    <img
      src="{{ store.banner_image }}"
      alt=""
      onerror="this.src='assets/images/error_image_1500.png' "
      class="banner"
    />
    <mat-icon (click)="editBanner()" class="action">edit</mat-icon>
  </div>

  <div class="headings">
    <div class="name">
      <h2>{{ store.name }}</h2>
    </div>
    <span *ngIf="store.active" class="store-state active">active</span>
    <span *ngIf="!store.active" class="store-state inactive">inactive</span>
  </div>

  <!-- ============================ -->
  <!--       STORE SETTINGS         -->
  <!-- ============================ -->

  <hr />
  <div class="name action">
    <h4>Store settings</h4>
  </div>
  <hr />

  <div class="flex-item">
    <div>
      <span *ngIf="store.active">deactivate store</span>
      <span *ngIf="!store.active">activate store</span>
    </div>
    <div>
      <mat-slide-toggle
        [(ngModel)]="store.active"
        (change)="storeStateToggle(store)"
      ></mat-slide-toggle>
    </div>
  </div>

  <div class="flex-item">
    <div>
      <span>pause/play store</span>
    </div>
    <div>
      <mat-slide-toggle
        [(ngModel)]="paused"
        (change)="pausePlayStore()"
      ></mat-slide-toggle>
    </div>
  </div>

  <div class="flex-item last">
    <div>
      <span *ngIf="!loop">activate on loop</span>
      <span *ngIf="loop">deactivate loop store</span>
    </div>
    <div>
      <mat-slide-toggle
        [(ngModel)]="loop"
        (change)="toggleLoopStatus()"
      ></mat-slide-toggle>
    </div>
  </div>

  <!-- ============================ -->
  <!--        STORE DETAILS         -->
  <!-- ============================ -->

  <hr />
  <div class="name action">
    <h4>Store Scheduling</h4>
    <mat-slide-toggle
      [(ngModel)]="store_slot_capacity"
      (change)="toggleStoreScheduling()"
    ></mat-slide-toggle>
  </div>
  <hr />

  <div class="flex-item last">
    <div>
      <span *ngIf="slot_capacity === 0"
        >This store has no available time slots</span
      >
      <span *ngIf="slot_capacity > 0"
        >store slot capacity: {{ slot_capacity }}</span
      >
    </div>
    <div>
      <button
        mat-flat-button
        class="time-slot-add"
        (click)="TimeSlots()"
        *ngIf="slot_capacity === 0"
      >
        add time slots
      </button>
      <button
        mat-flat-button
        class=""
        (click)="TimeSlots()"
        *ngIf="slot_capacity > 0"
      >
        update
      </button>
    </div>
  </div>

  <app-store-details></app-store-details>

  <!-- ============================= -->
  <!--        ADDRESS DETAILS        -->
  <!-- ============================= -->

  <app-store-address></app-store-address>

  <!-- ============================= -->
  <!--       BANNER  UPDATE          -->
  <!-- ============================= -->

  <div class="edit" *ngIf="banner_update" id="address-update">
    <div class="heading">
      <div></div>
      <h4>Update Store Banner</h4>
      <mat-icon (click)="editBanner()">clear</mat-icon>
    </div>

    <div class="edit-fields">
      <div class="fields">
        <form class="image-upload">
          <h5>app preview</h5>

          <img
            src="{{ image }}"
            onerror="this.src='assets/images/error_image_1500.png';"
            alt=""
          />
          <input
            type="file"
            id="exampleInputFile"
            (change)="imagesPreview($event)"
            style="display: none"
          />
          <div class="market-card">
            <h2>{{ store.name }}</h2>
            <h4>{{ store.physical_address }}</h4>
            <img src="{{ store.image }}" alt="" />
          </div>
          <p
            onclick="document.querySelector('#exampleInputFile').click()"
            mat-flat-button
          >
            browse files
          </p>
          <span class="recommended-size"
            >*recommended image dimensions 1500 * 500</span
          >
        </form>
      </div>

      <div class="save">
        <button mat-flat-button class="button cancel" (click)="editBanner()">
          Cancel
        </button>
        <button mat-flat-button class="button" (click)="updateBannerImage()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
