<div >
    <p>{{heading}}</p>
    <div>

        <mat-form-field appearance='outline' class="field">
          <mat-label>Slot Capacity</mat-label>
          <mat-select [(ngModel)]="slot_cap" name="slot_cap" class='select-panel' (selectionChange)="selectSlot(slot_cap)">
              <mat-option *ngFor="let slot_cap of slot_capacities" [value]="slot_cap" >
                  {{slot_cap}}
              </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class='field' appearance='outline' *ngIf="more">
          <mat-label>Please Provide a store slot capacity</mat-label>
          <input type="number" matInput [(ngModel)]="more_slots"  placeholder="driver had accident" name='slot_cap' required>
        </mat-form-field>

        
    </div>
  </div>
  <div class='btn' style="position: absolute; right: 15px; bottom: 5px;">
    <button  class="button" mat-flat-button (click)='setSlotCapacity()' *ngIf='currentCapacity < 1'>add </button>
    <button  class="button" mat-flat-button (click)='setSlotCapacity()' *ngIf='currentCapacity > 0'>update </button>
  </div>
