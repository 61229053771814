import { Router }             from '@angular/router'
import { ApiService }         from 'src/app/services/api.service'
import { AuthService }        from 'src/app/services/auth.service'
import { PagerService }       from './../../../services/pager.service'
import { MessagesService }    from 'src/app/services/messages.service'
import { Component, OnInit }  from '@angular/core'

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  displayedColumns: string[] = ['image', 'name', 'size', 'price']

  inputTouch = false

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }

  page     = 1
  limit    = 10
  search   = ''
  retries  = 0
  products = []



  constructor(
    private router: Router,
    private api: ApiService,
    public pager:PagerService,
    public message: MessagesService,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    if(localStorage.getItem('productsPage')){
      this.page = JSON.parse(localStorage.getItem('productsPage'))
    }

    if(localStorage.getItem('limit')){
      this.limit = JSON.parse(localStorage.getItem('limit'))
    }

    if(localStorage.getItem('productSearch')){
      this.search = localStorage.getItem('productSearch')
      this.getSilos()
    }  else {
      this.getSilos()
    }

    this.pager.resource = 'products'
  }

  getProducts(){
    localStorage.setItem('productSearch', this.search)
    this.api.getList('catelog', `products?page=${this.page}&per_page=${this.limit}&search=${this.search}&silo_id=${this.silo.id}`).subscribe((products: any) => {
      this.products = products
      }, error => {
        this.message.showSnack(error.error)
        if(this.retries < 3){
          if(error.status !== 401){
          this.retries++
          this.getProducts()
          }
        }
      })
   }

   searchReset(){
     this.page = 1
     this.getProducts()
     localStorage.setItem('productsPage', JSON.stringify(this.page))
   }

   silos
   silo

   getSilos(){
    return new Promise (resolve => {
      this.api.getList("catelog","silos").subscribe((silos:any)=>{
      this.silos = silos

      let all = {
        id: 0,
        tag: 'all'
      }

      this.silos.unshift(all)
       
      // set current silo
      if(localStorage.getItem('productsTab') && localStorage.getItem('productSearch')){
          this.silo = JSON.parse(localStorage.getItem('productsTab'))
          this.search = localStorage.getItem('productSearch')
          this.getProducts()

      } else if(localStorage.getItem('productsTab')){
          this.silo = JSON.parse(localStorage.getItem('productsTab'))
          this.getProducts()
      } else if(this.silo == undefined){
        this.silo = silos[0]
        localStorage.setItem('productsTab', JSON.stringify(this.silo))
        this.getProducts()
      }
      resolve('success')
    })
    })
  }

  setSilo(asilo){
    this.silo = asilo
    this.search = ''
    localStorage.removeItem('productSearch')
    this.getSilos()
    localStorage.setItem('productsTab', JSON.stringify(asilo))
    this.page = 1
    localStorage.setItem('productsPage', JSON.stringify(this.page))
  }

   clearSearch(){
     this.page = 1
    this.search = ''
    this.getProducts()
    localStorage.setItem('productsPage', JSON.stringify(this.page))
  }


  //  =====================
  //      PAGINATION
  // ======================

  setLimit(limit){
    this.limit = limit 
    this.getProducts()
    localStorage.setItem('limit', JSON.stringify(limit))
  }

  next(){
    if(this.products.length < this.limit){
      this.message.showSnack('no more products to show')
    } else {
    this.page++
      this.getProducts()

    }
  localStorage.setItem('productsPage', JSON.stringify(this.page))
  }

  previous(){
    if(this.page > 1){
      this.page--
        this.getProducts()
    }
    localStorage.setItem('productsPage', JSON.stringify(this.page))
  }

  start(){
    this.page = 1;
      this.getProducts()

    localStorage.setItem('productsPage', JSON.stringify(this.page))
  }

}

