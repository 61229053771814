<div class="dashboard">


    <td class="charts" (window:resize)="onResize($event)">
    
        <div class="chart" >
        <h3>Average orders per hour</h3>

        <google-chart #chart *ngIf="average_hourly_orders.length > 0"
                        [type]="'ColumnChart'"
                        [data]="average_hourly_orders"
                        [options]="quench"
                        [width]="width"
                        [height]="height"
                        id='average-desktop'
                                    >
        </google-chart>

        </div>
    </td>

    <div class="retailer-turnovers">
        <h3>Retailers total monthly orders and turnovers</h3>

        <div class="retailers">
            <div class='retailer' *ngFor='let item of retailer_turnover'>
                <p class='name'>{{item.retailer}}</p>
                <p class='turnover'>R {{item.total_turnover | number:"1.2-2"}} </p>
                <p class='total-orders'>{{item.total_orders}} orders</p>
                <img src="{{item.image}}" alt=""  onerror="this.src='assets/placeholders/logo-placeholder.png';">
            </div>
        </div>
 
    </div>


    <div class="retailer-turnovers">
        <h3>Retailers total monthly turnovers</h3>

        <div class="retailers-graphs">
            <span  *ngFor='let item of retailer_monthly_turnover'>
                <div class='retailer-turnover' *ngIf='item.retailer'  >
                        <div class="header">
                            <img src={{item.image}} alt="">
                            <p class='name'>{{item.retailer}}</p>
                        </div>
                        <google-chart #chart 
                            [type]="'ColumnChart'"
                            [data]="item.turnovers"
                            [options]="quench"
                            [width]="retailer_width"
                            [height]="height"
                            id='average-desktop'
                                        >
                        </google-chart>
                </div>
            </span>
        </div>
 
    </div>

</div>
