  <div mat-dialog-content>
    <p>Why do you want to pause this store?</p>
    <div>
      <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Reason</mat-label>
          <input matInput [(ngModel)]="reason" placeholder="store has been closed due to unforeseen circumstances">
        </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions style="justify-content: end;">
    <button mat-dialog-close  class="button" mat-flat-button (click)="createPause()" *ngIf='!currentReason'>Pause Store</button>
    <button mat-dialog-close class='button cancel' mat-flat-button *ngIf='currentReason'>cancel</button>
    <button mat-dialog-close class='button'  mat-flat-button (click)='createPause()' *ngIf='currentReason'>update reason</button>
  </div>

