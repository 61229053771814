import { CartNavigationComponent } from './../cart-navigation/cart-navigation.component';
import { AuthService } from './../../../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { PusherService } from 'src/app/services/pusher.service';
import { MessagesService } from 'src/app/services/messages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, EventEmitter } from '@angular/core';

import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { MiscService } from 'src/app/services/misc/misc.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  status = [
    {
      display_name: 'CANCEL ORDER',
      api_call: 'CANCELLED',
      info: 'cancel customer order',
    },
    {
      display_name: 'FAIL PAYMENT',
      api_call: 'FAILED',
      info: 'fail customer payment if customer was unable to pay',
    },
  ];

  statuses = [];
  nucleusTracking = ['action'];

  id;
  cart;
  items;
  origin;
  picker;
  search;
  actions;
  requests;
  picking_duration;

  isDisChemUser = false;

  user;

  picker_uid;
  picker_name;
  destination;
  statusoverride;
  nucleusActions;

  market_cart = false;
  paymentItems = [];

  page = 1;
  limit = 8;
  retries = 0;
  no_name = false;
  products = [];
  resource = `admin/carts`;
  replacing = false;
  removePicker = false;

  pdfTable: any;

  constructor(
    public api: ApiService,
    public message: MessagesService,
    public route: ActivatedRoute,
    public pusher: PusherService,
    public dialog: MatDialog,
    public router: Router,
    public auth: AuthService,
    public cartNav: CartNavigationComponent
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    if (
      this.auth.dis_chem_role ||
      (localStorage.getItem('dis-chem-role') &&
        localStorage.getItem('dis-chem-role') == 'true')
    ) {
      this.isDisChemUser = true;
      this.resource = `dischem/carts`;
    } else {
      this.resource = 'admin/carts';
    }

    this.getCart();
    this.listToPusher();
    this.getCartActions();
  }

  createWaybill() {
    this.api
      .post('fulfilment', `retailers/carts/${this.cart.ID}/create_waybill`, '')
      .subscribe((data: any) => {
        return;
      });
  }

  navigateStore() {
    if (!this.isDisChemUser) {
      this.router.navigate([`stores/${this.cart.store_id}`]);
    }
  }

  navigateUser() {
    if (!this.isDisChemUser) {
      this.router.navigate([`users/${this.cart.uid}`]);
    }
  }

  convertSecsToMins(time) {
    var minutes = time / 60;
    var seconds = time % 60;

    var m = minutes.toFixed(0);

    this.picking_duration = `${minutes.toFixed(0)}:${seconds.toFixed(0)}`;
  }

  getCart() {
    this.api.get('fulfilment', this.resource, this.id).subscribe(
      (cart: any) => {
        this.status.pop();
        this.paymentItems = [];
        // console.log(cart)

        this.convertSecsToMins(cart.cart.picking_duration);

        this.picker_uid = cart.cart.picker_uid;
        this.picker_name = cart.cart.picker_name;

        if (cart.cart.status == 'CANCELLED') {
          this.status[0].display_name = 'ORDER CANCELLED';
        }

        if (cart.cart.status == 'FAILED') {
          this.status[1].display_name = 'PAYMENT FAILED';
        }

        if (cart.cart.silo_id == 5) {
          this.market_cart = true;
        }

        if (cart.cart.silo_id == 5) {
          this.api
            .getList('fulfilment', `admin/requests/carts/${this.id}`)
            .subscribe((data: any) => {
              this.requests = data.data;
            });
        }

        var alphabet = /[a-zA-Z]/g;

        if (!alphabet.test(cart.cart.customer_name)) {
          this.no_name = true;
        }

        if (
          cart.cart.status == 'PLACED' ||
          cart.cart.status == 'PICKING' ||
          cart.cart.status == 'DISPATCHED' ||
          (cart.cart.status == 'PAID' && cart.cart.status !== 'CANCELLED')
        ) {
          let complete_payment = {
            display_name: 'COMPLETE ORDER',
            api_call: 'complete_order',
            info: 'mark order as complete',
          };
          this.status.push(complete_payment);
        }

        if (cart.cart.status == 'CANCELLED') {
          this.status[0].api_call = '';
          this.status[0].info = 'This order has been cancelled';
        }

        if (cart.cart.status == 'COMPLETED') {
          this.status.shift();
          let completed_status = {
            display_name: 'ORDER COMPLETED',
            api_call: '',
            info: 'This order has reached its final state',
          };
          this.status.push(completed_status);
        }

        if (cart.cart.delivery_partner_reference == "" && cart.cart.status != "PLACED"){
          let retry_picup = {
            display_name: 'RETRY SHIPMENT',
            api_call: 'picup',
            info: 'retry shipment creation in CIL',
          };
          this.status.push(retry_picup);
        }

        if (cart.cart.silo_id == 5 && cart.cart.status == 'PICKING') {
          let market_manual_pay = {
            display_name: 'Market: update if customer paid via eft',
            api_call: 'PAID',
            info: 'complete payment if customer paid via eft',
          };

          this.status.push(market_manual_pay);
        }

        this.statusoverride = cart.cart.status;
        this.cart = cart.cart;
        this.items = cart.items;

        cart.items.forEach((item) => {
          if (
            item.item_type == 'picker_fee' ||
            item.item_type == 'picker_tip' ||
            item.item_type == 'delivery_fee' ||
            item.item_type == 'driver_tip' ||
            item.item_type == 'promo_code_discount'
          ) {
            this.paymentItems.push(item);
          }
        });

        this.destination = {
          lat: cart.cart.customer_latitude,
          lng: cart.cart.customer_longitude,
        };
        this.origin = {
          lat: cart.cart.store_latitude,
          lng: cart.cart.store_longitude,
        };
      },
      (error) => {
        if (this.retries < 3) {
          if (error.status !== 401) {
            this.retries++;
            // this.getCart()
          }
        }
      }
    );
  }

  goToLink(url: string) {
    window.open(`https://drive.quench.mobi/orders/${url}/fulfilment`, '_blank');
  }

  createDelivery(cart) {
    this.api
      .getList('fulfilment', `admin/orders/${cart}/create_delivery`)
      .subscribe((data: any) => {
        this.message.showSnack('Delivery created!');
      });
  }

  getCartActions() {
    this.api
      .getList('fulfilment', `app/carts/${this.id}/actions`)
      .subscribe((data: any) => {
        if (data.data.length !== 0) {
          this.actions = data.data;
        }
      });
  }

  cancelOrderReason() {
    const dialog = this.dialog.open(cancelOrderReasonDialog, {
      width: '800px',
      height: '450px',
      data: {
        cart: this.cart,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.getCart();
    });
  }

  setStatus(status) {
    let stat = {
      status: status.api_call,
    };

    // to complete payment - if customer paid via eft
    if (
      status.display_name == 'ORDER CANCELLED' ||
      status.display_name == 'ORDER COMPLETED'
    ) {
      return;
    } else if (status.display_name == "RETRY SHIPMENT"){
      this.api
      .post('fulfilment', `admin/carts/${this.id}/picup`, "")
      .subscribe((data: any) => {
        this.message.showSnack('Shipment Creation Retried');

        setTimeout(() => {
          location.reload();
        },3000);
      });
      
    }else if (status.display_name == 'COMPLETE PAYMENT') {
      this.api
        .patch('fulfilment', `admin/carts/${this.id}/complete_cart`, stat)
        .subscribe((data: any) => {
          this.message.showSnack('Order payment Completed');
          this.statusoverride = data.cart.status;
        });

      // to complete order
    } else if (status.display_name == 'COMPLETE ORDER') {
      this.api
        .patch(
          `fulfilment`,
          `admin/carts/${this.id}/complete_cart_and_order`,
          ''
        )
        .subscribe((data: any) => {
          this.message.showSnack('Order Marked As Complete');
          this.cartNav.getCart();
        });
    } else if (status.display_name == 'CANCEL ORDER') {
      this.cancelOrderReason();
    } else {
      this.api.patch('fulfilment', `carts/${this.id}`, stat).subscribe(
        (data: any) => {
          this.cart = data.cart;

          if (data.cart.status == 'PAID') {
            this.message.showSnack('order marked as complete');
          } else {
            this.message.showSnack('order payment failed');
          }

          this.cartNav.getCart();

          this.statusoverride = data.cart.status;
        },
        (error) => {
          this.message.showSnack('Could not update');
        }
      );
    }
  }

  recheckCartForPicker() {
    this.api
      .get('fulfilment', this.resource, this.id)
      .subscribe((cart: any) => {
        this.picker_uid = cart.cart.picker_uid;
        this.picker_name = cart.cart.picker_name;
        this.cart.picker_name = cart.cart.picker_name;
      });
  }

  listToPusher() {
    this.pusher.channel.bind('cart-event-' + this.id, (data) => {
      if (this.cart.picker_name == '') {
        this.recheckCartForPicker();
      }
      // clear data and populate again
      this.getCart();
    });
  }

  removePickerDialog() {
    this.removePicker = !this.removePicker;
  }

  removePickerFromOrder() {
    this.api
      .patch('fulfilment', `admin/carts/${this.id}/release`, ``)
      .subscribe(
        (picker: any) => {
          this.removePickerDialog();
          this.message.showSnack(`Succesfully removed picker from cart`);
          // this.getCart()
        },
        (error) => {
          this.message.showSnack(error.error);
        }
      );
  }

  updateInstructionDialog() {
    const dialog = this.dialog.open(InstructionDialog, {
      width: '650px',
      height: '300px',
      data: {
        cart: this.cart,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.getCart();
    });
  }

  createInvoiceDialog() {
    const dialog = this.dialog.open(InvoiceDialog, {
      width: '800px',
      maxWidth: '800px',
      data: {
        cart: this.cart,
        items: this.items,
      },
    });
  }

  manualTripDialog() {
    const dialog = this.dialog.open(ManualTripDialog, {
      width: '800px',
      height: '65vh',
      data: {
        cart: this.cart,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.getCart();
    });
  }
}

// ============================
//  INSTRUCTION UPDATE DIALOG
// ============================

@Component({
  selector: 'instruction-update-dialog',
  templateUrl: 'instruction-update-dialog.html',
  styleUrls: ['../../../../components/dialogs/update-dialog.component.scss'],
})
export class InstructionDialog {
  cart;
  instruction;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { cart },
    private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<InstructionDialog>
  ) {}
  ngOnInit(): void {
    this.cart = this.data.cart;
    this.instruction = this.cart.customer_instruction;
  }

  // get cart after success
  onAdd = new EventEmitter();

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  updateInstruction() {
    let data = {
      instruction: this.instruction,
    };
    this.api
      .patch(
        'fulfilment',
        `admin/carts/${this.cart.ID}/update_loop_instruction`,
        data
      )
      .subscribe((data: any) => {
        this.closeDialog();
      });
  }
}

@Component({
  selector: 'manual-trip-dialog',
  templateUrl: 'manual-trip-dialog.html',
  styleUrls: ['../../../../components/dialogs/update-dialog.component.scss'],
})
export class ManualTripDialog {
  page = 1;
  limit = 10;
  other = false;
  driver_search = '';

  order;
  mobile;
  reason;
  driver;
  driver_name = ""
  drivers;
  driver_type;

  dispatch_types = [
    {
      id: 0,
      type: 'Uber',
    },
    {
      id: 1,
      type: 'Freelance',
    },
  ];

  manual_trip_reasons = [
    {
      id: 0,
      reason: 'Manually Batching Order Together',
    },
    {
      id: 1,
      reason: 'No Quench Driver Available',
    },
    {
      id: 2,
      reason: 'Incompatible Driver Phone',
    },
    {
      id: 3,
      reason: 'Driver Refused to Accept Trip',
    },
    {
      id: 4,
      reason: 'Loadshedding',
    },
    {
      id: 5,
      reason: 'Driver has no Data',
    },
    {
      id: 6,
      reason: 'Bike Issue',
    },
    {
      id: 7,
      reason: 'Driver No Petrol',
    },
    {
      id: 8,
      reason: 'other',
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { cart },
    private api: ApiService,
    private message: MessagesService,
    public misc: MiscService,
    public dialogRef: MatDialogRef<ManualTripDialog>
  ) {}
  ngOnInit(): void {
    // console.log(this.data.cart);
    // this.cart = this.data.cart
    this.order = this.data.cart.ID;
  }

  // get cart after success
  onAdd = new EventEmitter();

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  async getDrivers(e) {
    let drivers = await this.misc.apiCall(
      `auth`,
      `admin/drivers?search=${e.target.value}&per_page=${20}`
    );
    this.drivers = drivers['users'];
  }

  selectReason(reason) {
    if (reason && reason.reason == 'other') {
      this.other = true;
    } else {
      this.other = false;
    }
  }

  selectDriver(driver){
    this.driver_name = `${driver.first_name} ${driver.last_name}`
    this.driver = driver
    this.mobile = driver.phone
  }

  autoFillDriverDetails(driver) {
    this.mobile = driver.mobile;
  }

  submitManualTrip(info) {

    if (this.driver !== undefined){

      let d = {
        driver_id: this.driver.ID,
        name: this.driver_name,
        mobile: this.driver.phone,
        loop_driver_id: info.driver_loop_id,
        reason: info.reason.reason,
      };

      if (this.other) {
        d.reason = info.other_reason;
      }

      console.log(d)

      this.api
        .patch(`fulfilment`, `admin/carts/${this.order}/assign_driver`, d)
        .subscribe((data: any) => {
          // console.log(data)
          this.message.showSnack(`succesfully logged a manual trip for cart`);
          this.closeDialog();
        });
    } else {
      this.message.showSnack(`Please pick a driver`);
    }
  }
}

// ============================
//   INVOICE RESEND DIALOG
// ============================

@Component({
  selector: 'invoice-dialog',
  templateUrl: 'invoice.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InvoiceDialog {
  store_id;
  cart;
  items;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { cart; items },
    private api: ApiService,
    private message: MessagesService
  ) {}

  ngOnInit(): void {
    let items = [];
    this.cart = this.data.cart;
    this.data.items.forEach((item) => {
      if (
        item.item_type !== 'out_of_stock' &&
        item.item_type !== 'alternative_product' &&
        item.item_type !== 'promo_code_discount'
      ) {
        items.push(item);
      }
    });
    this.items = items;
  }

  invoiceResend() {
    this.api
      .getList(`fulfilment`, `admin/orders/${this.cart.ID}/send_invoice`)
      .subscribe(
        (data: any) => {},
        (error) => {
          this.message.showSnack(error.error);
        }
      );
  }

  exportAsPDF(div_id) {
    let height = 80;

    this.items.forEach((item) => {
      height += 10;
    });

    let data = document.getElementById(div_id);
    html2canvas(data).then((canvas) => {
      let imgFile = canvas.toDataURL('image/jpeg', 0.3);
      var doc = new jspdf.jsPDF('p', 'mm', 'a4', true);
      doc.addImage(imgFile, 'JPEG', 5, 0, 210, height, undefined, 'FAST');
      doc.save(`${this.cart.customer_name} - ${this.cart.order_number}.pdf`);
    });
  }
}

// ============================
//  ORDER CANCEL REASON DIALOG
// ============================

@Component({
  selector: 'cancel-reason-dialog',
  templateUrl: 'cancel-reason-dialog.html',
  styleUrls: ['cancel-reason-dialog.scss'],
})
export class cancelOrderReasonDialog {
  cart;
  instruction;

  chosenReason;

  cancel_order_reasons = [
    'Customer request: Order taking too long',
    'Customer request: Changed mind',
    'Customer request: Too many OOS items',
    'Customer issue: Not available to receive delivery',
    'Customer issue: Payment failure',
    'Customer issue: Incorrect address',
    'Customer issue: No ID provided',
    'Store issue: No pickers',
    'Store issue: Store closed',
    'Store issue: POS offline',
    'Driver issue: No drivers',
    'Driver issue: Bad weather',
    'Driver issue: Accident',
    'Store issue: Picking delays',
    'Customer issue: Duplicate order',
    'other',
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { cart },
    private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<cancelOrderReasonDialog>
  ) {}
  ngOnInit(): void {
    this.cart = this.data.cart;
  }

  // get cart after success
  onAdd = new EventEmitter();

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  selectReason(reason) {
    this.chosenReason = reason;
  }

  cancelOrderWithReason(e) {
    if (this.chosenReason == 'other') {
      this.chosenReason = e.other_reason;
    }

    let cancel = {
      status: 'CANCELLED',
      cancel_reason: this.chosenReason,
    };

    if (this.chosenReason != '') {
      this.api
        .patch(`fulfilment`, `carts/${this.cart.ID}`, cancel)
        .subscribe((res) => {
          this.closeDialog();
        });
    }
  }
}
