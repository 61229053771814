<div class="heading">
  <div></div>
  <h4>Edit Section Details</h4>
  <mat-icon (click)="closeDialog()">clear</mat-icon>
</div>

<div class="edit-fields">
  <mat-form-field class="field" appearance="outline">
    <mat-label>section title</mat-label>
    <input
      type="text"
      matInput
      [(ngModel)]="title"
      placeholder="gouda cheese"
      name="title"
      required
    />
  </mat-form-field>

  <mat-form-field
    class="field"
    appearance="outline"
    *ngIf="!data.isDisChemUser"
  >
    <mat-label>Silo</mat-label>
    <mat-select [(ngModel)]="silo_id" name="silo_id">
      <mat-option *ngFor="let silo of silos" [value]="silo.id">
        {{silo.tag}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="field" appearance="outline">
    <mat-label>Type</mat-label>
    <mat-select [(ngModel)]="type" name="type">
      <mat-option *ngFor="let type of types" [value]="type">
        {{type}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="save">
  <button mat-flat-button class="button cancel" (click)="closeDialog()">
    Cancel
  </button>
  <button
    mat-flat-button
    class="button"
    (click)="update()"
  >
    Save
  </button>
</div>
