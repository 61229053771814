<div class="split_tables">
  <!-- assets table -->
  <table mat-table [dataSource]="assets.assets" matSort class="asset_table">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
      <td mat-cell *matCellDef="let asset">
        <p>{{ asset.name }}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="registration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Registration number
      </th>
      <td mat-cell *matCellDef="let asset">
        <p>{{ asset.registration_number }}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="asset_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>asset type</th>
      <td mat-cell *matCellDef="let asset">
        <p *ngIf="asset.asset_type_id">
          {{ asset.asset_type_id }}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="desc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>description</th>
      <td mat-cell *matCellDef="let asset">
        <p *ngIf="asset.description">
          {{ asset.description }}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Value</th>
      <td mat-cell *matCellDef="let asset">
        <p *ngIf="asset.value">
          {{ asset.value | currency: "R ":"code" }}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let asset">
        <mat-icon (click)="updateAsset(asset)">edit</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row class="action" *matRowDef="let asset; columns: Columns"></tr>
  </table>
</div>

<div id="pager">
  <div class="page">
    <div class="actions" id="{{ auth.expand ? 'create-expand' : 'create' }}">
      <button mat-flat-button (click)="createAssetType()">
        New Asset Type
      </button>
      <button mat-flat-button (click)="viewAssetTypes()">
        View Asset Types
      </button>
      <button mat-flat-button class="button" routerLink="/create-assets">
        New Asset
      </button>
    </div>
    <div>Per page:</div>
    <span class="silo-filter pagination">
      <button mat-button [matMenuTriggerFor]="paginate">
        <span>{{ limit }}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #paginate="matMenu">
        <button mat-menu-item (click)="setLimit(10)">10</button>
        <button mat-menu-item (click)="setLimit(20)">20</button>
        <button mat-menu-item (click)="setLimit(50)">50</button>
      </mat-menu>
    </span>

    <div
      (click)="start()"
      matTooltip="Skip to start"
      class="action"
      *ngIf="page > 1"
    >
      back to start
    </div>
    <p (click)="previous()" class="action">previous</p>
    <div>{{ page }}</div>
    <p (click)="next()" class="action">next</p>
    <div></div>
  </div>
</div>
