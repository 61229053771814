<!-- ---------------- -->
<!--     SIDE-NAV     -->
<!-- ---------------- -->

<div class="navbar" [style.width]="auth.expand ? '220px' : '65px' " (window:resize)="onResize($event)" >

  <img src='../../../assets/logo.png' class='action logo' id='logo' (click)='expandSideNav()' [style.right]="auth.expand ? '-160px' : '0' ">
  <div class="center-logo">
    <img src='../../../assets/images/quench-black-logo.png' 
          class='action logo' 
          id='full-logo'
          (click)='expandSideNav()' 
          [style.left]="auth.expand ? '-8px' : '-160px' ">
          <mat-icon class='action' (click)='expandSideNav()'>clear</mat-icon>
  </div>
  <div class='navigation-item' 
      *ngFor="let item of sidenav" 
      [ngClass]="item.label == 'logout' ? 'test' : 'action' " 
      (click)="setRoute(item)"
      routerLink={{item.path}}>
    <mat-icon [ngClass]="item.icon == 'lock' ? 'logout' : '' " [ngClass]="item.class" >{{item.icon}}</mat-icon>
    <p  class='action' *ngIf="auth.expand" >{{item.label}}</p>
     </div>

</div>

<!-- ---------------- -->
<!-- MOBILE-SIDE-NAV  -->
<!-- ---------------- -->

<div class="mobile-side-nav"  id="{{mobile_expand ? 'mobile-expand': 'mobile-collapse'}}" >

  <div class="center-logo">
    <img src='../../../assets/images/quench-black-logo.png' 
          class='action logo' 
          id='full-logo'>
        <mat-icon class='action' (click)='expandMobileNav()'>clear</mat-icon>
  </div>
  <div class='navigation-item' 
      *ngFor="let item of sidenav" 
      [ngClass]="item.label == 'logout' ? 'test' : 'action' " 
      (click)="setRoute(item)"
      routerLink={{item.path}}>
    <mat-icon [ngClass]="item.icon == 'lock' ? 'logout' : '' " [ngClass]="item.class" >{{item.icon}}</mat-icon>
    <p  class='action' >{{item.label}}</p>
     </div>

</div>


<!-- ---------------- -->
<!-- TOP-NAVIGATION   -->
<!-- ---------------- -->

<div class="top-navbar" >
  <div class="top-bar-content">
    <mat-icon class='burger-menu action' (click)='expandMobileNav()'>menu</mat-icon>
    <div class="submenus" [style.left]="auth.expand ? '240px' : '90px' ">
      <div class='submenu' *ngFor="let item of subItems" 
      routerLink={{item.path}}
      [class]="item.label == menu ? 'isBold' : '' "
      (click)='setSubMenu(item)'>
      {{item.label}}
      </div>
    </div>
  </div>
  <span matTooltip={{auth.user}} class='user' *ngIf='!isDisChemUser'>{{auth.user | slice:0:1}}</span>
</div>





