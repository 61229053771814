import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-silo-create',
  templateUrl: './silo-create.component.html',
  styleUrls: ['./silo-create.component.scss']
})
export class SiloCreateComponent implements OnInit {

  constructor(public api: ApiService,
              public message: MessagesService,
              public router: Router) { }

  ngOnInit(): void {
  }

  createSilo(silo){
    let newSilo = [
      {
        name: silo.name,
        tag: silo.tag
      }
    ]
    this.api.post(`catelog`, `silos`, newSilo).subscribe((silo: any) => {
      this.message.showSnack((`Succesfully created Silo ${silo.name}`))
      this.router.navigate(['/silos'])
    }, error => {
      this.message.showSnack(error.error);
    })
  }

}
