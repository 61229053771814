<div class="add-card"  >
  
    <div class="header">
      <h3>add product to promotional code</h3>
      <mat-icon (click)='closeDialog()'>clear</mat-icon>
    </div>

    <div class="top">
        <div class="search" >
            <mat-icon (click)='getProducts()' matTooltip='search' class='search-icon' [style.left]="inputTouch ? '-20px' : '10px' ">search </mat-icon>
            <mat-icon *ngIf='search' (click)='clearSearch()'  matTooltip='clear' class='clear-icon'>clear</mat-icon>
            <input matInput (keydown.enter)='getProducts()' [(ngModel)]="search" placeholder="Search products"  #input (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
        </div>

        <div class='filter' >
            <mat-form-field >
            <mat-label>Filter</mat-label>
            <mat-select  [(ngModel)]="silo_id">
                <mat-option (click)='getProducts()' *ngFor="let silo of silos" [value]="silo.id">
                {{silo.name}}
                </mat-option>
            </mat-select>
            </mat-form-field>
        </div>
    </div>
      
        <table  mat-table [dataSource]='searchData' matSort >

            <ng-container matColumnDef="image">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
            <td mat-cell *matCellDef="let item"  class='image'> 
                <img class='img' src={{item.image}} alt="" onerror="this.src='assets/placeholders/placeholder-img.png' ">
            </td>
            </ng-container>

            <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let item"> 
                <p > {{item.name}} </p>
            </td>
            </ng-container>

            <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> size </th>
            <td mat-cell *matCellDef="let item"> 
                <p > {{item.size}} </p>
            </td>
            </ng-container>

            <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> price </th>
            <td mat-cell *matCellDef="let item"> 
                <p > {{item.price}} </p>
            </td>
            </ng-container>

            <ng-container matColumnDef="check">
            <th mat-header-cell  *matHeaderCellDef> </th>
            <td mat-cell  *matCellDef="let item" > 
                <mat-icon 
                (click)='chosenProduct(item)'
                [style.color]="item === promoproduct ? 'var(--quenchPink)': '' "
                class='check-product'
                >check</mat-icon>
            </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="Columns"></tr>
            <tr mat-row class='action'  *matRowDef="let item; columns: Columns;"></tr>
        </table>


        <div class='promoproduct' *ngIf='promoproduct'>
            <img src={{promoproduct.image}} onerror="this.src='assets/placeholders/placeholder-img.png' ">
            <p> Add {{promoproduct.name}} to promocode?</p>
            <button mat-flat-button (click)='cancelProduct()' >cancel</button>
        </div>
      




    <div class="btn">
        <div (click)='start()' matTooltip="Skip to start" class='action' *ngIf='page > 1'>back to start</div>
        <p (click)='previous()' class='action'>previous</p>
        <div>{{page}}</div>
          <p (click)='next()' class='action'>next</p>
          <div> </div>
        <button mat-flat-button class='button ' (click)='closeDialog()'>Confirm</button>
    </div>
  
</div>
  

