<!-- ------------- -->
<!--    SEARCH     -->
<!-- ------------- -->

<div class="search">
  <mat-icon
    (keydown.enter)="searchReset()"
    matTooltip="search"
    class="search-icon material-icons-outlined"
    [style.left]="inputTouch ? '-20px' : '10px'"
    >search</mat-icon
  >
  <mat-icon
    *ngIf="search"
    (click)="clearSearch()"
    matTooltip="clear"
    class="clear-icon"
    >clear</mat-icon
  >
  <input
    matInput
    (keydown.enter)="searchReset()"
    [(ngModel)]="search"
    placeholder="Search"
    (blur)="onBlur()"
    (focus)="onFocus()"
    [style.left]="inputTouch ? '10px' : '35px'"
  />
</div>

<!-- ----------------- -->
<!--  STATUS FILTER    -->
<!-- ----------------- -->

<div class="silos">
  <mat-chip-list aria-label="Fish selection">
    <mat-chip
      *ngFor="let status of statuses"
      (click)="setStatus(status)"
      [style.color]="astatus == status ? '#fff' : ''"
      [style.background]="astatus == status ? background : '#eee'"
      >{{ status }}</mat-chip
    >
  </mat-chip-list>
</div>

<!-- ----------------- -->
<!--    SILO FILTER    -->
<!-- ----------------- -->

<span class="silo-filter" *ngIf="!isDisChemUser">
  <button mat-button [matMenuTriggerFor]="menu">
    <span>{{ silo_name }}</span>
    <mat-icon>expand_more</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let silo of silos" (click)="selectSilo(silo)">
      {{ silo.name }}
    </button>
  </mat-menu>
</span>

<!-- ----------------- -->
<!--      CARTS        -->
<!-- ----------------- -->
<table mat-table [dataSource]="carts" matSort>
  <ng-container matColumnDef="placed">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Placed</th>
    <td mat-cell *matCellDef="let cart">
      <P *ngIf="cart.order_date">{{ cart.order_date.substring(0, 10) }}</P>
      <P *ngIf="cart.order_date" class="order-time">{{
        cart.order_date.substring(14, 19)
      }}</P>
      <P *ngIf="!cart.order_date">No Order Date</P>
    </td>
  </ng-container>

  <ng-container matColumnDef="completed">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Last updated</th>
    <td mat-cell *matCellDef="let cart">
      <P *ngIf="cart.order_date">{{ cart.UpdatedAt.substring(0, 10) }}</P>
      <P *ngIf="cart.order_date" class="order-time">{{
        cart.UpdatedAt.substring(11, 16)
      }}</P>
      <p *ngIf="!cart.UpdatedAt">No updated time</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="schedule">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Scheduled time</th>
    <td mat-cell *matCellDef="let cart">
      <P *ngIf="cart.is_schedule">{{
        cart.schedule_end_time.substring(0, 10)
      }}</P>
      <P *ngIf="cart.is_schedule" class="order-time"
        >{{ cart.schedule_start_time.substring(11, 16) }} -
        {{ cart.schedule_end_time.substring(11, 16) }}</P
      >
      <p *ngIf="!cart.is_schedule">No scheduled time</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="completed">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Last updated</th>
    <td mat-cell *matCellDef="let cart">
      <P *ngIf="cart.order_date">{{ cart.UpdatedAt.substring(0, 10) }}</P>
      <P *ngIf="cart.order_date" class="order-time">{{
        cart.UpdatedAt.substring(11, 16)
      }}</P>
      <p *ngIf="!cart.UpdatedAt">No updated time</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="store">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Store</th>
    <td mat-cell *matCellDef="let cart">
      <p *ngIf="cart.store_name">{{ cart.store_name }}</p>
      <p *ngIf="!cart.store_name">No Store Name</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="customer">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
    <td mat-cell *matCellDef="let cart">
      <p
        *ngIf="cart.customer_name"
        matTooltip="{{ cart.uid }}"
        class="cust-name"
      >
        {{ cart.customer_name }}
      </p>
      <p *ngIf="!cart.customer_name">No Customer Name</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="order_num">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Number</th>
    <td mat-cell *matCellDef="let cart">
      <p *ngIf="cart.order_number">{{ cart.order_number }}</p>
      <p *ngIf="!cart.order_number">No Order Number</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="driver">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td mat-cell *matCellDef="let cart" class="amount">
      <span *ngIf="cart.status == 'DISPATCHED' || cart.status == 'COMPLETED'">
        <mat-icon
          *ngIf="cart.driver_name"
          class="driver"
          matTooltip="{{ cart.driver_name }}"
          >sports_motorsports</mat-icon
        >
        <mat-icon
          *ngIf="!cart.driver_name"
          class="no-driver"
          matTooltip="no driver assigned"
          >sports_motorsports</mat-icon
        >
      </span>
    </td>
  </ng-container>

  <!-- ASSIGN COLORS TO DIFFERENT STATUSES -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let cart">
      <p
        *ngIf="cart.status && cart.status == 'PLACED'"
        id="placed-status"
        class="status"
      >
        {{ cart.status }}
      </p>
      <p
        *ngIf="cart.status && cart.status == 'PICKING'"
        id="picking-status"
        class="status"
      >
        {{ cart.status }}
      </p>
      <p
        *ngIf="cart.status && cart.status == 'OPEN'"
        id="open-status"
        class="status"
      >
        {{ cart.status }}
      </p>
      <p
        *ngIf="cart.status && cart.status == 'CANCELLED'"
        id="cancelled-status"
        class="status"
      >
        {{ cart.status }}
      </p>
      <p
        *ngIf="cart.status && cart.status == 'PAID'"
        id="paid-status"
        class="status"
      >
        {{ cart.status }}
      </p>
      <p
      *ngIf="cart.status && cart.status == 'READY FOR COLLECTION'"
      id="dispatched-status"
      class="status"
    >
      {{ cart.status }}
    </p>
      <p
        *ngIf="cart.status && cart.status == 'DISPATCHED'"
        id="dispatched-status"
        class="status"
      >
        {{ cart.status }}
      </p>
      <p
        *ngIf="cart.status && cart.status == 'FAILED'"
        id="cancelled-status"
        class="status"
      >
        {{ cart.status }}
      </p>
      <p
        *ngIf="cart.status && cart.status == 'REPLACE'"
        id="cancelled-status"
        class="status"
      >
        {{ cart.status }}
      </p>
      <p
        *ngIf="cart.status && cart.status == 'COMPLETED'"
        id="completed-status"
        class="status"
      >
        {{ cart.status }}
      </p>
      <p
      *ngIf="cart.status && cart.status == 'RETURNED'"
      id="returned-status"
      class="status"
    >
      {{ cart.status }}
    </p>
      <p *ngIf="!cart.status">No Status</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="cancel_reason">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-align">
      <p *ngIf="astatus == 'CANCELLED'">Reason</p>
    </th>
    <td mat-cell *matCellDef="let cart" class="text-align">
      <p *ngIf="cart && cart.cancel_reason !== ''">{{ cart.cancel_reason }}</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="phone">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-align">
      Phone
    </th>
    <td mat-cell *matCellDef="let cart" class="text-align">
      <p *ngIf="cart.customer_phone">{{ cart.customer_phone }}</p>
      <p *ngIf="!cart.customer_phone">No Phone Number</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="origin">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Origin</th>
    <td mat-cell *matCellDef="let cart">
      <p *ngIf="cart.origin">{{ cart.origin }}</p>
      <p *ngIf="!cart.origin">Origin not recorded</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="distance">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-align">
      Distance
    </th>
    <td mat-cell *matCellDef="let cart" class="text-align">
      <p *ngIf="cart.distance_text">{{ cart.distance_text }}</p>
      <p *ngIf="!cart.distance_text">No Distance</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
    <td mat-cell *matCellDef="let cart" class="amount">
      <p *ngIf="cart.amount">R {{ cart.amount | number: "1.2-2" }}</p>
      <p *ngIf="!cart.amount">No Amount</p>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="Columns"></tr>
  <tr
    mat-row
    class="action"
    routerLink="/cart/{{ cart.ID }}"
    *matRowDef="let cart; columns: Columns"
    [ngClass]="cart.status == 'DISPATCHED' ? 'dispatched' : ''"
  ></tr>
</table>

<div id="pager">
  <div class="page">
    <div>Per page:</div>
    <span class="silo-filter pagination">
      <button mat-button [matMenuTriggerFor]="paginate">
        <span>{{ limit }}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #paginate="matMenu">
        <button mat-menu-item (click)="setLimit(10)">10</button>
        <button mat-menu-item (click)="setLimit(20)">20</button>
        <button mat-menu-item (click)="setLimit(50)">50</button>
      </mat-menu>
    </span>

    <div
      (click)="start()"
      matTooltip="Skip to start"
      class="action"
      *ngIf="page > 1"
    >
      back to start
    </div>
    <p (click)="previous()" class="action">previous</p>
    <div>{{ page }}</div>
    <p (click)="next()" class="action">next</p>
    <div></div>
  </div>
</div>

<!-- ------------------- -->
<!--    MOBILE CARTS     -->
<!-- ------------------- -->

<div class="mobile">
  <mat-expansion-panel hideToggle *ngFor="let cart of carts">
    <mat-expansion-panel-header>
      <mat-panel-title id="mat-title-content">
        <p id="name">{{ cart.customer_name }}</p>
        <p class="cart-number-mobile">{{ cart.order_number }}</p>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <span class="flex">
      <mat-title class="matTitle">Placed:</mat-title>
      <p>{{ cart.order_date }}</p>
    </span>
    <span class="flex">
      <mat-title class="matTitle">Store:</mat-title>
      <p>{{ cart.store_name }}</p>
    </span>
    <span class="flex">
      <mat-title class="matTitle">Status:</mat-title>
      <p
        *ngIf="cart.status && cart.status == 'PLACED'"
        id="placed-status"
        class="status"
      >
        {{ cart.status }}
      </p>
      <p
        *ngIf="cart.status && cart.status == 'OPEN'"
        id="open-status"
        class="status"
      >
        {{ cart.status }}
      </p>
      <p
        *ngIf="cart.status && cart.status == 'CANCELLED'"
        id="cancelled-status"
        class="status"
      >
        {{ cart.status }}
      </p>
      <p
        *ngIf="cart.status && cart.status == 'PAID'"
        id="paid-status"
        class="status"
      >
        {{ cart.status }}
      </p>
      <p
        *ngIf="cart.status && cart.status == 'DISPATCHED'"
        id="dispatched-status"
        class="status"
      >
        {{ cart.status }}
      </p>
      <p *ngIf="!cart.status">No Status</p>
    </span>
    <span class="flex">
      <mat-title class="matTitle">origin:</mat-title>
      <p *ngIf="cart.origin">{{ cart.origin }}</p>
      <p *ngIf="!cart.origin">Origin not recorded</p>
    </span>
    <span class="flex">
      <mat-title class="matTitle">Phone:</mat-title>
      <p>{{ cart.customer_phone }}</p>
    </span>
    <span class="flex">
      <mat-title class="matTitle">Distance:</mat-title>
      <p>{{ cart.distance_text }}</p>
    </span>
    <span class="flex">
      <mat-title class="matTitle">Amount:</mat-title>
      <p>R {{ cart.amount | number: "1.2-2" }}</p>
    </span>
    <div class="view-button">
      <button mat-flat-button class="button" routerLink="/cart/{{ cart.ID }}">
        view detail
      </button>
    </div>
  </mat-expansion-panel>
</div>
