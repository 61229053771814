import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MiscService } from '../misc/misc.service';

@Injectable({
  providedIn: 'root'
})
export class ContractsService {
  contracts

  page = 1
  limit = 10

  constructor(public misc: MiscService, public router: Router) { }

  getContracts(){
    this.misc.apiCall(`payments`, `admin/contracts?page=${this.page}&per_page=${this.limit}`).then((res: any) => {
      // console.log(res)
      this.contracts = res['contracts']
    })
  }

  createContract(obj){
    this.misc.apiPost(`payments`, `admin/contracts`, obj).then((res: any) => {
      this.router.navigate(["contracts"])
      return
    })
  }

  updateContract(id, obj){
    this.misc.apiPatch('payments', `admin/contracts/${id}`, obj).then((res: any) => {
      return
    })
  }
}
