<div class="back action" routerLink="/contracts">
  <mat-icon>arrow_back</mat-icon>
  <p>contracts</p>
</div>

<div class="create-card">
  <div class="header">
    <h3>Create new Contract</h3>
    <mat-icon routerLink="/contracts">clear</mat-icon>
  </div>

  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="firstForm">
      <form [formGroup]="firstForm">
        <mat-form-field appearance="outline" class="field">
          <mat-label>Contract Name</mat-label>
          <input
            type="text"
            matInput
            ngModel
            placeholder=""
            name="name"
            required
            formControlName="name"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="field">
          <mat-label>Contract Date Range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              name="start_date"
              placeholder="Start date"
              ngModel
              formControlName="start_date"
            />
            <input
              matEndDate
              name="end_date"
              placeholder="End date"
              ngModel
              formControlName="end_date"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          class="field"
          *ngIf="
            firstForm.value.start_date !== '' && firstForm.value.end_date !== ''
          "
        >
          <mat-label>Payment Frequency</mat-label>
          <mat-select
            ngModel
            name="frequency"
            class="select-panel"
            (selectionChange)="selectFrequency($event.value)"
            formControlName="frequency"
          >
            <mat-select-trigger class="selection">
              &nbsp;{{ frequency }}
            </mat-select-trigger>
            <mat-option
              *ngFor="let frequency of payment_frequencies"
              [value]="frequency"
            >
              {{ frequency.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="field"
          *ngIf="firstForm.value.frequency !== ''"
        >
          <mat-label>No of Payments</mat-label>
          <input
            type="text"
            matInput
            [(ngModel)]="firstForm.value.no_of_payments"
            placeholder=""
            name="no_of_payments"
            required
            formControlName="no_of_payments"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="field">
          <mat-label>Total Amount</mat-label>
          <input
            type="number"
            matInput
            [(ngModel)]="firstForm.value.amount"
            placeholder="10 000"
            name="amount"
            required
            formControlName="amount"
          />
          <span matPrefix class="code">zar </span>
        </mat-form-field>

        <mat-form-field appearance="outline" class="field">
          <mat-label>Driver name</mat-label>
          <input
            type="string"
            matInput
            [(ngModel)]="driver"
            placeholder="John Doe"
            name="user_id"
            required
            formControlName="user_id"
            [matAutocomplete]="auto"
            (input)="getDrivers($event)"
          />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option
              *ngFor="let driver of drivers"
              [value]="driver"
              (click)="selectDriver(driver)"
            >
              <div class="driver-dropdown-info">
                <span> {{ driver.first_name }} {{ driver.last_name }} </span>
                <span> {{ driver.email }} </span>
                <span class="driver-contact"> {{ driver.phone }} </span>
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="field"
          *ngIf="
            firstForm.value.start_date !== '' && firstForm.value.end_date !== ''
          "
        >
          <mat-label>Link Asset </mat-label>
          <mat-select
            ngModel
            name="asset_id"
            class="select-panel"
            (selectionChange)="selectAsset($event.value)"
            formControlName="asset_id"
          >
            <mat-select-trigger class="selection">
              &nbsp;{{ asset }}
            </mat-select-trigger>
            <mat-option
              *ngFor="let asset of assets.assets"
              [value]="asset"
              [routerLink]="
                asset.name == 'create new asset' ? ['/create-assets'] : []
              "
            >
              <mat-icon
                *ngIf="asset.name == 'create new asset'"
                style="margin-right: 2px"
                >add_circle</mat-icon
              >
              <mat-icon
                *ngIf="asset.name !== 'create new asset'"
                style="margin-right: 2px"
                >attach_money</mat-icon
              >
              {{ asset.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="save">
          <button
            mat-flat-button
            class="button"
            matStepperNext
            (click)="submitContractInfo()"
          >
            Next
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step>
      <div class="driver-overview">
        <h5>Review Contract details</h5>

        <p *ngIf="contract">
          <span>Contract name</span>
          <span class="confirm-detail">{{ contract.name }}</span>
        </p>

        <p *ngIf="contract">
          <span>Contract duration</span>
          <span class="confirm-detail"
            >{{ contract.start_date | date: "dd/MM/YYYY" }} -
            {{ contract.end_date | date: "dd/MM/YYYY" }}</span
          >
        </p>

        <p *ngIf="contract">
          <span> Contract frequency</span>
          <span class="confirm-detail">{{ contract.frequency }}</span>
        </p>

        <p *ngIf="contract">
          <span>Number of Payments</span>
          <span class="confirm-detail">{{ contract.no_of_payments }}</span>
        </p>

        <p *ngIf="contract">
          <span>Contract Amount</span>
          <span class="confirm-detail">{{
            contract.amount | currency: "R":"code"
          }}</span>
        </p>

        <p *ngIf="contract">
          <span> Linked Driver</span>
          <span class="confirm-detail">{{ driver }}</span>
        </p>

        <p *ngIf="contract">
          <span>Linked Asset</span>
          <span class="confirm-detail">{{ asset }}</span>
        </p>
      </div>
      <div class="save">
        <button mat-flat-button class="button" (click)="submitContract()">
          Submit
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
