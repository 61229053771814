<div *ngIf='categories'>

    <table  mat-table  [dataSource]='categories' >

        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef > Icon </th>
          <td mat-cell  *matCellDef="let category" > <img class='icon'  src={{category.image}} alt=""> </td>
        </ng-container>


        <ng-container matColumnDef="name">
          <th mat-header-cell  *matHeaderCellDef> Title </th>
          <td mat-cell  *matCellDef="let category" routerLink='/categories/{{category.category_id}}'  class='pointer' id='title'> <p>{{category.name}}</p> </td>
        </ng-container>

        <ng-container matColumnDef="add">
          <th mat-header-cell  *matHeaderCellDef> </th>
          <td mat-cell  
          *matCellDef="let category" 
          class='pointer' 
          (click)='removeProductCategory(category)'> <mat-icon>delete_outline</mat-icon> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="Columns"></tr>
        <tr mat-row class='action' *matRowDef="let category; columns: Columns;"></tr>

    </table>

    <div *ngIf='categories.length === 0' class='no-data'>
      <img  class='none' src="assets/images/undraw_No_data.svg" alt="">
      <h3>There are no Categories linked to this product</h3>
    </div>

  <div id="pager" >
    <div class="page"> 

    <button id="{{auth.expand ? 'create-expand': 'create'}}" mat-flat-button class='button' (click)='addProductCategories()' >add to categories</button>

    <div> Per page:</div> 
    <span class="silo-filter pagination">
      <button mat-button [matMenuTriggerFor]="paginate">
        <span>{{limit}}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #paginate="matMenu" >
        <button mat-menu-item (click)="setLimit(10)">10</button>
        <button mat-menu-item (click)="setLimit(20)">20</button>
        <button mat-menu-item (click)="setLimit(50)">50</button>
      </mat-menu>
    </span>

    <div (click)='start()' matTooltip="Skip to start" class='action' *ngIf='page > 1'>back to start</div>
    <p (click)='previous()' class='action'>previous</p>
    <div>{{page}}</div>
      <p (click)='next()' class='action'>next</p>
      <div> </div>
    </div>
  </div>

</div>