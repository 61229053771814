<div class="stores">

  <div *ngIf=!add>

<table  mat-table  
        [dataSource]='categories' >

  <ng-container matColumnDef="icon">
    <th mat-header-cell *matHeaderCellDef > Icon </th>
    <td matTooltip='go to category' 
        class='action' 
        mat-cell  
        *matCellDef="let category" 
        routerLink='/categories/{{category.category_id}}'> 
      <img  id='prod-img' src={{category.icon}} alt=""> 
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell  *matHeaderCellDef> Title </th>
    <td matTooltip='go to category' 
        class='action' 
        mat-cell  
        *matCellDef="let category" 
        routerLink='/categories/{{category.category_id}}' 
        id='title'> 
      <p>{{category.name}}</p> 
    </td>
  </ng-container>

  <ng-container matColumnDef="add">
    <th mat-header-cell  *matHeaderCellDef> <mat-icon class='action' (click)='toggleAdd()'>add_circle</mat-icon></th>
    <td mat-cell  *matCellDef="let category" class='action' id='title'> <mat-icon (click)='removeStoreCategory(category)'>delete_outline</mat-icon> </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="Columns"></tr>
  <tr  mat-row *matRowDef="let category; columns: Columns;"></tr>
</table>

</div>

<div *ngIf='add'>

  <mat-form-field  *ngIf='add'>
    <mat-label>Search</mat-label>
    <input matInput (input)='searchCategories($event.target.value)' #input>
  </mat-form-field>


<table  mat-table  
[dataSource]='allCategories'>

<ng-container matColumnDef="icon">
<th mat-header-cell *matHeaderCellDef > </th>
<td mat-cell  *matCellDef="let category"> <img  id='prod-img' src={{category.image}} alt=""> </td>
</ng-container>

<ng-container matColumnDef="name">
<th mat-header-cell  *matHeaderCellDef> Title </th>
<td mat-cell  *matCellDef="let category" class='action' id='title'> <p>{{category.name}}</p> </td>
</ng-container>

<ng-container matColumnDef="add">
  <th mat-header-cell  *matHeaderCellDef><mat-icon class='action' (click)='toggleAdd()'>close</mat-icon></th>
  <td mat-cell  *matCellDef="let category" > <mat-icon class='action' (click)='addStoreCategory(category)'>add_circle</mat-icon>
  </td>
  </ng-container>
  
<tr mat-header-row *matHeaderRowDef="Columns"></tr>
<tr mat-row  
class='action'
*matRowDef="let category; columns: Columns;"></tr>
</table>
</div>

<div id="pager" >
  <div class="page"> 

  <div> Per page:</div>
  <div (click)="setLimit(10)" class="limit" >10</div>
  <div (click)="setLimit(15)" class="limit" >15</div>
  <div (click)="setLimit(20)" class="limit" >20</div>
  <div (click)="setLimit(50)" class="limit" >50</div>
  <div>Page:</div>
  <mat-icon (click)='previous()'>skip_previous</mat-icon>
  <div>{{page}}</div>
    <mat-icon (click)='next()'>skip_next</mat-icon>
    <div> </div>
</div>
</div>

