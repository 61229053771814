<table mat-table [dataSource]="partners" matSort>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let partner">{{ partner.name }}</td>
  </ng-container>

  <ng-container matColumnDef="callback_url">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>callback URL</th>
    <td mat-cell *matCellDef="let partner">{{ partner.callback_url }}</td>
  </ng-container>

  <ng-container matColumnDef="max_delivery_distance">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Max delivery distance
    </th>
    <td mat-cell *matCellDef="let partner">
      {{ partner.max_delivery_distance }}
    </td>
  </ng-container>

  <ng-container matColumnDef="active">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td mat-cell *matCellDef="let partner">
      <mat-slide-toggle
        [(ngModel)]="partner.active"
        (change)="togglePartner(partner)"
      ></mat-slide-toggle>
    </td>
  </ng-container>

  <ng-container matColumnDef="edit_partner">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td mat-cell *matCellDef="let partner">
      <mat-icon class="action" (click)="updatePartner(partner)">edit</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="copy_api_key">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td mat-cell *matCellDef="let partner">
      <mat-icon (click)="copyApiKey()" [cdkCopyToClipboard]="partner.api_key">content_copy</mat-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="Columns"></tr>
  <tr mat-row *matRowDef="let partner; columns: Columns" class="action"></tr>
</table>

<div id="pager">
  <div class="page">
    <button
      id="{{ auth.expand ? 'create-expand' : 'create' }}"
      mat-flat-button
      class="button"
      routerLink="/partners-create"
    >
      New
    </button>

    <div>Per page:</div>
    <div (click)="setLimit(10)" class="limit">10</div>
    <div (click)="setLimit(15)" class="limit">15</div>
    <div (click)="setLimit(25)" class="limit">25</div>
    <div (click)="setLimit(50)" class="limit">50</div>
    <div>Page:</div>
    <mat-icon (click)="previous()">skip_previous</mat-icon>
    <div>{{ page }}</div>
    <mat-icon (click)="next()">skip_next</mat-icon>
    <div></div>
  </div>
</div>
