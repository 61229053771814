import { Router } from '@angular/router'
import { MessagesService } from './../../../services/messages.service'
import { ApiService } from 'src/app/services/api.service'
import { Component, OnInit } from '@angular/core'
import { environment as devEnv } from '../../../../environments/environment'


@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {

  id;
  silos;
  silo
  page = 1;
  limit = 10;
  silo_id = 5
  category_id;
  selectedValue
  category_name;
  shade = false;
  categories = []
  imageFile: {resource: string, file: any, name: string};

  constructor(public api: ApiService,
              public message: MessagesService,
              public router: Router) { }

  ngOnInit(): void {
    this.getSilos();
  }


  getSilos(){
    this.api.getList('catelog', 'silos').subscribe((silos: any) => {
      this.silos = silos;
    }, error => {
      this.message.showSnack(error.error);
    })
  }

  categoryInfo = false
  category

  backToCategoryInfo(){
    this.categoryInfo = false
  }

  imageAttempt = false

  submitcategoryInfo(category){
    let newCategory = [
      {
      name: category.name,
      silo_id: category.silo,
      position: 1,
      linked_key: JSON.stringify(Math.random()),
      image: 'https://images.unsplash.com/photo-1588345921523-c2dcdb7f1dcd?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8d2hpdGV8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
      active: false
      }
    ]

    this.categoryInfo = true

    this.category = newCategory

  }

    submitCategory(){
        this.api.post('catelog', `categories`, this.category).subscribe((category: any) => {
          this.message.showSnack(`Successfully created category ${category[0].name}`)
          // console.log(category)
          this.category_id = category[0].id
          this.category_name = category[0].name
          this.imageUpload()
        }, error => {
          this.message.showSnack(error.error)
        })
  
    }

  imageUpload(){
    this.shade = true
    setTimeout(() => {
      this.message.showSnack(`Uploading Image for ${this.category_name}`)
    }, 3000);

    // console.log(this.category_id)


    let body: string = "{ \"resource\":\"categories\", \"resource_id\":" + this.category_id + ",\"x_dim\":1, \"y_dim\":1}"

    var myHeaders = new Headers();

    myHeaders.append("Authorization", "Bearer " + this.api.token);

    var formdata = new FormData();

    formdata.append("file", this.imageFile.file, this.imageFile.name);
    formdata.append("body", "{ \"resource\":\"categories\", \"resource_id\":"+ this.category_id +",\"x_dim\":1, \"y_dim\":1}");

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata
    };

    // development environment

    if(!devEnv.production){
      fetch("https://api.api-dev.quench.org.za/catalogue/v1/image", requestOptions)
        .then(response => response.text())
        .then(result =>
          setTimeout(() => {
            this.message.showSnack(`Succesfully created category ${this.category_name}`)
            this.router.navigate([`/categories/${this.category_id}`])
          }, 8000))
        .catch(error => console.log('error', error))
    }

    //  production environment
    
    else if(devEnv.production){
      fetch("https://api.quench.org.za/catalogue/v1/image", requestOptions)
      .then(response => response.text())
      .then(result =>
        setTimeout(() => {
          this.message.showSnack(`Succesfully created category ${this.category_name}`)
          this.router.navigate([`/categories/${this.category_id}`])
        }, 8000))
      .catch(error => console.log('error', error))
    }
  }

  imagesPreview(event) {
    if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();

        reader.onload = (_event: any) => {
            this.imageFile = {
                resource: _event.target.result,
                file: event.srcElement.files[0],
                name: event.srcElement.files[0].name
            };
            this.imageFile.name = 'image.png'
        };
        this.imageAttempt = true
        reader.readAsDataURL(event.target.files[0]);
        // console.log(reader.readAsDataURL(event.target.files[0]))
    }
  }

}
