

  <span id="zonesMap" *ngIf="!zonesService?.loading">
        
    <span id="actionBarTop">
      <span id="editAreaToggle" (click)="toggleAreaEdit()"> <h2>{{zoneArea}}</h2> <mat-icon>edit</mat-icon> </span>
      <span id="editArea" *ngIf="zonesService.editAreaToggle">

        <mat-form-field id="areaInput" appearance="outline">
          <mat-label>Area</mat-label>
          <input [formControl]="zoneAreaContorl" [(ngModel)]="zoneArea" matInput placeholder={{zoneArea}}>
        </mat-form-field>

      </span>
    </span>

    <span id="actionBarBottom">
      <span id="actions">
  
          <span id="actionInputs">

            <mat-form-field id="citiesSelect" appearance='outline' *ngIf="zonesService?.cities?.length > 0">

              <mat-label>select city</mat-label>

              <mat-select [(value)]="selectedCity" (selectionChange)="lookupByCity($event?.value)">
      
                <input class="select-search" placeholder="search cities" type="text" matInput [formControl]="citySearch" (keydown)="$event?.stopPropagation()">
      
                <mat-option *ngFor="let filterCity of (zonesService?.filteredCities?.length > 0 ? zonesService?.filteredCities : zonesService?.cities)" [value]="filterCity?.city" >
                  {{filterCity.city}}
                </mat-option>
                
              </mat-select>

          </mat-form-field>
  
          </span>
  
          <span id="actionButtons">

              <button mat-button *ngIf="zonesService.edit" id="editZoneBtn" (click)="updatePolygon()">update zone</button>
              <button mat-button *ngIf="zonesService.edit" id="editZoneBtn" (click)="deleteZone(zone)"> <mat-icon>delete_outline</mat-icon> delete zone</button>
              <button mat-button id="editZoneBtn" (click)="editToggle()"> <mat-icon>drive_file_rename_outline</mat-icon> edit zone</button>
  
          </span>
  
      </span>
  
    </span>
  
    <span style="height: 10px; display:flex"></span>
  
      <agm-map 
      *ngIf="zonesService.mapsLoaded" 
      [zoom]="zonesService.zoom" 
      [latitude]="zonesService.lat" 
      [longitude]="zonesService.lng"
      (mapReady)="onMapReady($event)">
      </agm-map>

    </span>