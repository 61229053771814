import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ApiService } from 'src/app/services/api.service'
import { MessagesService } from 'src/app/services/messages.service'

@Component({
  selector: 'app-retailer-products',
  templateUrl: './retailer-products.component.html',
  styleUrls: ['./retailer-products.component.scss']
})
export class RetailerProductsComponent implements OnInit {
  Columns = ['image' ,'name', 'size', 'price', 'add']

  id
  search
  products
  page = 1
  limit = 10
  allProducts
  retries = 0
  add = false

  constructor(public api: ApiService,
              public message: MessagesService,
              public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getRetailerProducts()
  }

  toggleAdd(){
    this.add = !this.add
    this.page = 1
  }

  getRetailerProducts(){
    this.api.getList('catelog', `retailerproducts/${this.id}?page=${this.page}&per_page=${this.limit}`).subscribe((products: any) => {
      this.products = products;
    }, error => {
      this.message.showSnack(error.error)
      if(this.retries < 3 && error.status !== 401){
        this.retries++
        this.getRetailerProducts()
      }
    })
  }

  searchProducts(e){
    this.search = e
    this.api.getList('catelog', `products?page=${this.page}&per_page=${this.limit}&search=${e}`).subscribe((products: any) => {
      this.allProducts = products;
    })
  }

  addRetailerProduct(p){
    let product = [
      {
      product_id: p.id,
      retailer_id: JSON.parse(this.id)
    }
  ]


    this.api.post('catelog', `retailerproducts`, product).subscribe((product: any) => {
      this.message.showSnack('Succesfully added product to retailer');
      this.toggleAdd();
      this.getRetailerProducts();
    }, error => {
      console.log(error);
      this.message.showSnack(error.error);
    })
  }

  setLimit(limit){
    this.limit = limit
    if(this.add){
      this.searchProducts(this.search);
    } else {
    this.getRetailerProducts();
    }
  }

  next(){
    this.page++
    if(this.add){
      this.searchProducts(this.search);
    } else {
    this.getRetailerProducts();
    }
  }

  previous(){
    if(this.page > 1){
      this.page--
      if(this.add){
        this.searchProducts(this.search);
      } else {
      this.getRetailerProducts();
      }
    }
  }
}
