import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MessagesService } from 'src/app/services/messages.service';
import { PagerService } from 'src/app/services/pager.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-exports',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.scss']
})
export class OrdersExportsComponent implements OnInit {
  // Columns = ['placed', 'customer', 'store', 'status', 'number', 'amount']
  exports = ['orders', 'tracking', 'dis-chem white label', 'pickers', 'drivers', 'supplier orders']

  inputTouch = false

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }

  orders
  date_to
  date_from
  
  search       = ''
  start_hour   = 0
  start_minute = 0
  end_hour     = 0
  end_minute   = 0
  current      = "orders"


  constructor(public api: ApiService,
              public message: MessagesService,
              public pager: PagerService) { }

  ngOnInit(): void {
    if(localStorage.getItem('exportType')){
      this.setExportType(localStorage.getItem('exportType'))
    }
  }

  // ====================
  //     EXPORT TYPE
  // ====================

  setExportType(type){
    this.current   = type
    this.date_from = ''
    this.date_to   = ''
    this.start_hour = 0
    this.start_minute = 0
    this.end_hour     = 0
    this.end_minute   = 0
    localStorage.setItem('exportType', type)
  }

  // ====================
  //      INCREASE
  // ====================

  startHourIncrease(){
    if(this.start_hour < 23){
    this.start_hour++
    } else {
      this.start_hour = 0
    }
  }

  startMinuteIncrease(){
    if(this.start_minute < 45){
    this.start_minute += 15
    } else {
      this.start_minute = 0
    }
  }

  endHourIncrease(){
    if(this.end_hour < 23){
    this.end_hour++
    } else {
      this.end_hour = 0
    }
  }

  endMinuteIncrease(){
    if(this.end_minute < 45){
    this.end_minute += 15
    } else {
      this.end_minute = 0
    }
  }

  // ====================
  //      DECREASE
  // ====================

  startHourDecrease(){
    if(this.start_hour > 0){
    this.start_hour--
    } else {
      this.start_hour = 23
    }
  }

  startMinuteDecrease(){
    if(this.start_minute > 0){
    this.start_minute -= 15
    } else {
      this.start_minute = 45
    }
  }


  endHourDecrease(){
    if(this.end_hour > 0){
    this.end_hour--
    } else {
      this.end_hour = 23
    }
  }

  endMinuteDecrease(){
    if(this.end_minute > 0){
    this.end_minute -= 15
    } else {
      this.end_minute = 45
    }
  }

  export(){
    let sh
    let eh
    let sm
    let em

    if(this.start_hour < 10){
      sh = `0${this.start_hour}`
    } else {
      sh = this.start_hour
    }

    if(this.end_hour < 10){
      eh = `0${this.end_hour}`
    } else {
      eh = this.end_hour
    }

    if(this.start_minute == 0){
      sm = `0${this.start_minute}`
    } else {
      sm = this.start_minute
    }

    if(this.end_minute == 0){
      em = `0${this.end_minute}`
    } else {
      em = this.end_minute
    }

    const format = 'yyyy-MM-dd'
    const locale = 'en-US';

    let sd = formatDate(this.date_from, format, locale)
    let fd = formatDate(this.date_to, format, locale)

    let st =  `${sh}:${sm}`
    let et = `${eh}:${em}`

    let service
    let url 
    let download 

    // check type
    if(this.current == 'orders'){
      // console.log('orders')
      service  = 'fulfilment'
      url      = 'admin/reports/orders'
      download = 'orders-export'
    } 
    
    else if(this.current == 'tracking'){
      // console.log('tracking')
      service  = 'tracking'
      url      = 'admin/reports'
      download = 'tracking-export'
    } 
    
    else if(this.current == 'dis-chem white label'){
      // console.log('dis-chem')
      service  = 'fulfilment'
      url      = 'admin/reports/whitelabel/dischem'
      download = 'dis-chem'
    }

    else if(this.current == 'pickers'){
      // console.log('pickers')
      service  = 'fulfilment'
      url      = 'admin/reports/pickers'
      download = 'pickers'
    }

    else if(this.current == 'drivers'){
      // console.log('drivers')
      service  = 'fulfilment'
      url      = 'admin/reports/drivers'
      download = 'drivers'
    }

    else if(this.current == 'supplier orders'){
      // console.log('suppliers')
      service  = 'fulfilment'
      url      = 'admin/reports/supplier/summary'
      download = 'supplier orders'
    }
    
    // make call
      this.api.export(service, `${url}?date_from=${sd}T${st}&date_to=${fd}T${et}&search=${this.search}`)
      .subscribe((result: any) => {

        var blob = new Blob([result], {type: 'text/csv;charset=utf-8;'})

        var downloadURL = window.URL.createObjectURL(blob)
        var link = document.createElement('a')
        link.href = downloadURL
        link.download = `${download} ${sd} - ${fd}.csv`
        link.click()
      })
  }

}
