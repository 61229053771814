
  <span id="zonesList" *ngIf="!zonesService?.loading">
        
      <span id="actionBarTop">
        <h2>Delivery Zones List</h2>
      </span>

      <span id="actionBarBottom">
        <span id="actions">

          <span id="actionButtons">

            <button mat-button *ngIf="!zonesService.addingZone" id="editZoneBtn" (click)="addPolygon()"> <mat-icon>add_circle_outline</mat-icon> add zone</button>

          </span>

        </span>

      </span>

      <span>

        <table mat-table [dataSource]="zonesService.zones" class="mat-elevation-z8">
              
          <ng-container  matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef> City: </th>
            <td mat-cell (click)="navZone(zone)" *matCellDef="let zone"> {{zone.city}}  <p *ngIf="!zone.city" class="empty">city not specified</p> </td>
          </ng-container>
        
          <ng-container matColumnDef="area">
            <th mat-header-cell *matHeaderCellDef> Area: </th>
            <td mat-cell (click)="navZone(zone)" *matCellDef="let zone"> {{zone.area}} </td>
          </ng-container>
        
          <ng-container matColumnDef="updated_by">
            <th mat-header-cell *matHeaderCellDef> Updated by: </th>
            <td mat-cell (click)="navZone(zone)" *matCellDef="let zone"> {{zone.updated_by}} </td>
          </ng-container>

          <ng-container matColumnDef="updated_at">
            <th mat-header-cell *matHeaderCellDef> Updated at: </th>
            <td mat-cell (click)="navZone(zone)" *matCellDef="let zone"> {{zone.updated_at}} </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let zone"> <button mat-flat-button id="deleteZoneButton" (click)="deleteZone(zone.id)"> <mat-icon matTooltip="delete zone" class="mat-icon-outlined">delete_outline</mat-icon></button> </td>
          </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <!-- <span id="zoneCard">
        <span id="zoneInner" (click)="navZone(zone)">
          <span id="zoneLine"> <h5>City: </h5> <h4>{{zone.city}}</h4> </span> 
          <span id="zoneLine"> <h5>Area: </h5>  <h4>{{zone.area}}</h4> </span> 
          <span id="zoneLine"> <h5>Updated by: </h5> <h4>{{zone.updated_by}}</h4>  </span>
          <span id="zoneLine"> <h5>Created at: </h5> <h4>{{zone.created_at | date:'full' }}</h4> </span>
          <span id="zoneLine"> <h5>Updated at: </h5> <h4>{{zone.updated_at | date:'full' }}</h4> </span>
        </span>
        <span id="zoneActions">
          <button mat-flat-button id="deleteZoneButton" (click)="deleteZone(zone.id)"> <mat-icon class="mat-icon-outlined">delete_outline</mat-icon> delete zone</button>
        </span>
        </span> -->

      </span>

        <span id="noZones" *ngIf="noZones">
          <span id="iconBox">
            <mat-icon>not_listed_location</mat-icon>
          </span>
          <h3>There are no delivery zones.</h3>
        </span>

  </span>