<div class='action' id="shade" *ngIf='shade' ></div>

<div class="banner-edit" >
    <div class="center">
        <div class="heading">
            <div></div>
            <h4>Edit Mall Banner</h4>
            <mat-icon (click)='closeDialog()'>clear</mat-icon>
        </div>
        <hr>
    
        <div class="image">
            <img  src="{{image}}" onerror="this.src='assets/images/error_image_1500.png' ">
            <input type="file" id="exampleInputFile" (change)="imagePreview($event)" style="display: none;" >
        </div>

        <button mat-flat-button class='button upload material-icons-outlined' onclick="document.querySelector('#exampleInputFile').click()" ><mat-icon>add</mat-icon>  upload image</button>
        <button mat-flat-button class='button save ' (click)='updateBanner()' [disabled]="imageFormat ? 'false' : 'true'"><mat-icon>save</mat-icon> save</button>
    </div>
</div>