import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoopBranchMapService } from 'src/app/services/loopmap/loopbranchmap.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-create-branchmap',
  templateUrl: './create-branchmap.component.html',
  styleUrls: ['./create-branchmap.component.scss']
})
export class CreateBranchmapComponent implements OnInit {

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public message: MessagesService,
    public branchservice:LoopBranchMapService) { }
  
  postInfo = false;
  loop_id;
  partner_branch_id;
  post;
  desktopAttempt = true;
  ngOnInit(): void {
    // createBranchMap();
  }

  linkFormBranchMap(i) {
    this.post = i;
    console.log(i);
    // this.postInfo = true;
  }

  submitBranchMap(postin) {
    if(postin.partner_branch_id && postin.loop_id) {
    console.log(this.post);
    this.branchservice.createLoopBranchMap(postin).then(
      (data: any) => {
        console.log(data);
        this.message.showSnack(data["message"])
        
        this.router.navigate(['/list-branchmap']);
      },
      (error) => {
        alert(JSON.stringify(error));
      }
    );
    }
  }
}
