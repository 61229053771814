<div class="back action" routerLink="/silos">
  <mat-icon>arrow_back</mat-icon>
  <p>silos</p>
</div>

<div class="create-card"  >

  <div class="header">
    <h3>create new silo</h3>
    <mat-icon routerLink='/silos'>clear</mat-icon>
  </div>

  <form #silo = 'ngForm' (ngSubmit)='createSilo(silo.value)'>
            
      <mat-form-field class='field' appearance='outline'>
          <mat-label>silo name</mat-label>
          <input type="text" matInput ngModel placeholder="woolworths" name='name' required>
      </mat-form-field>

      <mat-form-field class='field' appearance='outline'>
          <mat-label>silo tag</mat-label>
          <input type="text" matInput ngModel placeholder="woolworths" name='tag' required>
      </mat-form-field>


        <div class="btn">
          <button mat-flat-button class='button' type='submit' >save</button>      
        </div>

  </form>
</div>