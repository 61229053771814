import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MessagesService } from 'src/app/services/messages.service';
import { MiscService } from 'src/app/services/misc/misc.service';

@Component({
  selector: 'app-finances',
  templateUrl: './finances.component.html',
  styleUrls: ['./finances.component.scss']
})
export class FinancesComponent implements OnInit {
  
  id;
  uid;
  account_id;
  driver;
  driver_verified;
  driver_is_fleet_driver;
  driver_fleet_id;
  driver_name;
  user_id;
  driver_email;
  driver_status;
  account_payments
  account_assets
  accounts_earnings
  account_bank
  account_name
  account_number
  branch_code
  default_account
  verified
  editAccount
  bank_name
  delete
  earning_totals
  fee_total
  delivery_total
  manual_total
  tip_total
  dates
  start_date
  end_date
  
  //columns for payments
  Columns = [
    'date',
    'account_name',
    'account_number',
    'amount',
    'status',
    'payment_method'
  ]
  Columns2 = [
    'asset_id',
    'asset_name',
    'start_date',
    'end_date',
    'amount',
    'frequency',
    'no_of_payments'
  ]
  Columns3 = [
    'date',
    'amount',
    'description',
    'distance',
    'status',
    'earning_type_id',
    'order_id',
  ]
  Columns4 = [
   // 'bank_id',
    'account_name',
    'bank_name',
    'account_number',
    'branch_code',
    'verified',
    'default_account' ,
    'CreatedAt' ,  
  ]
  Columns5 = [
    'earning_type',
    'total_amount',
  ]
  page    = 1
  limit   = 5
  pagePayment = 1
  limitPayment = 5
  pageAsset = 1
  limitAsset = 5
  TotalEarningsForm = new FormGroup({  
    start_at: new FormControl('' ),
    end_at: new FormControl(''),
  });
  constructor( public messages: MessagesService,
    public route: ActivatedRoute,
    public api: ApiService,
    public misc: MiscService,
    public message: MessagesService) { }

  ngOnInit(): void {
    
    this.id = this.route.snapshot.paramMap.get('id');
    this.getDrivers();
    this.getUserAccountPayments(this.id)
    this.getUserAccountAssets(this.id)
    this.getUserEarnings(this.id)
    this.getBankingDetails(this.id)
    this.getEarningTotals(this.id)
  }


  // ===============
  //    DRIVER
  // ===============


  getDrivers() {
    this.api.getList('payments', `admin/user_accounts/${this.id}/summary`).subscribe(
      (data: any) => {
        this.driver = data.data;
        this.driver_verified =  data.data.verified;
        this.driver_is_fleet_driver = data.data.is_fleet_driver;
        this.driver_name = data.data.name;
        this.user_id = data.data.user_id;
        this.driver_status = data.data.status;
        this.driver_email = data.data.email;
      },
      (error) => {
        this.message.showSnack(JSON.stringify(error));
      }
    );
  }

  toggleFleetDriver(){
    // assign driver_is_fleet_driver value to current driver
    this.driver.is_fleet_driver = this.driver_is_fleet_driver
      this.misc.apiPatch('payments', `admin/user_accounts/${this.driver.ID}`, this.driver).then((res: any) => {
        this.message.showSnack('succesfully updated driver details')
      })
  }

  async getUserAccountPayments(id){
    await this.misc.apiCall(`payments`, `admin/user_accounts/${id}/payments?per_page=${this.limitPayment}&page=${this.pagePayment}`).then((res: any) => {
      this.account_payments = res['data']
    })
  }

  async getUserAccountAssets(id){
    await this.misc.apiCall(`payments`, `admin/user_accounts/${id}/assets`).then((res: any) => {
      this.account_assets = res['data']
    })
  }
  async getUserEarnings(id){
    await this.misc.apiCall(`payments`, `admin/user_accounts/${id}/earnings?per_page=${this.limit}&page=${this.page}`).then((res: any) => {
      this.accounts_earnings = []
      this.accounts_earnings = res['data']
    })
  }

  async getBankingDetails(id){
    await this.misc.apiCall(`payments`, `admin/user_accounts/${this.id}/accounts`).then((res: any) => {
      this.account_bank = []
      this.account_bank = res['data']
 
    })
  }
  async getEarningTotals(id){
    await this.misc.apiCall(`payments`, `admin/user_accounts/${this.id}/total_earnings?start_date=${this.start_date}&end_date=${this.end_date}`).then((res: any) => {
      this.earning_totals = res
 this.fee_total = Object(this.earning_totals)["Fee Total"]
 this.delivery_total = Object(this.earning_totals)["Delivery Total"]
 this.manual_total = Object(this.earning_totals)["Manual Total"]
 this.tip_total = Object(this.earning_totals)["Tip Total"]
    })
  }

// ==================================
//    PAGINATION EARNINGS
// ==================================

setLimitEarnings(limit){
  this.limit = limit
  this.getUserEarnings(this.id)
   
  localStorage.setItem('limit', JSON.stringify(limit))
}

nextEarnings(){
  this.page++
  this.getUserEarnings(this.id)
  localStorage.setItem('usersPage', JSON.stringify(this.page))
}

previousEarnings(){
  if(this.page > 1){
    this.page--
    this.getUserEarnings(this.id) 
  }
  localStorage.setItem('usersPage', JSON.stringify(this.page))
}

startEarnings(){
  this.page = 1
  this.getUserEarnings(this.id)
  localStorage.setItem('usersPage', JSON.stringify(this.page))
}
// ==================================
//    PAGINATION PAYMENTS
// ==================================

setLimitPayments(limitPayment){
  this.limitPayment = limitPayment
  this.getUserAccountPayments(this.id)
   
  localStorage.setItem('limit', JSON.stringify(limitPayment))
}

nextPayments(){
      this.pagePayment++

      this.getUserAccountPayments(this.id)
  
  localStorage.setItem('usersPage', JSON.stringify(this.pagePayment))
}

previousPayments(){
  if(this.pagePayment > 1){
    this.pagePayment--
    this.getUserAccountPayments(this.id) 
  }
  localStorage.setItem('usersPage', JSON.stringify(this.pagePayment))
}

startPayments(){
  this.pagePayment = 1
  this.getUserAccountPayments(this.id)
  localStorage.setItem('usersPage', JSON.stringify(this.pagePayment))
}

// ==================================
//    PAGINATION ASSETS
// ==================================

setLimitAssets(limitAsset){
  this.limitAsset = limitAsset
  this.getUserAccountAssets(this.id)
   
  localStorage.setItem('limit', JSON.stringify(limitAsset))
}

nextAssets(){
      this.pageAsset++

      this.getUserAccountAssets(this.id)
  
  localStorage.setItem('usersPage', JSON.stringify(this.pageAsset))
}

previousAssets(){
  if(this.pageAsset > 1){
    this.pageAsset--
    this.getUserAccountAssets(this.id) 
  }
  localStorage.setItem('usersPage', JSON.stringify(this.pageAsset))
}

startAssets(){
  this.pageAsset = 1
  this.getUserAccountAssets(this.id)
  localStorage.setItem('usersPage', JSON.stringify(this.pageAsset))
}

editBank(account_bank){
  this.editAccount = true
  this.uid = account_bank.ID
  this.account_name = account_bank.account_name
  this.account_number = account_bank.account_number
  this.branch_code = account_bank.branch_code
}

updateBank(){
  let bank= {
    account_name: this.account_name,
     account_number: this.account_number,
     branch_code: this.branch_code,
     default_account: this.default_account,
     verified: this.verified
  };
  this.api.patch('payments', `admin/accounts/${this.uid}`,bank).subscribe((data: any)=>{
    this.messages.showSnack('Succesfully updated driver');
   this.getBankingDetails(this.id)
  },error=>{
   /// alert(JSON.stringify(error));
   this.message.showSnack(JSON.stringify(error))
  })
}
UpdateDefault(){
  if (this.default_account==true){
    this.default_account = false;
    this.updateBank();
  }else{
    this.default_account = true;
    this.updateBank();
  }
}
UpdateVerified(){
  if (this.verified==true){
    this.verified = false;
    this.updateBank();
  }else{
    this.verified = true;
    this.updateBank();
  }
}
deleteBankAccount(account_bank){
  this.uid = account_bank.ID
  this.api.delete("payments", `admin/accounts/${this.uid}`).subscribe((account_bank:any)=>{
    this.message.showSnack(`Succesfully removed bank account`);
    this.deleteCheck(account_bank);
    this.getBankingDetails(this.id);
  })
}
deleteCheck(account_bank){
  this.delete = !this.delete
  this.account_bank = account_bank
}
setData(){
  return {  
    "start_at":  this.TotalEarningsForm.value.start_at, 
    "end_at":  this.TotalEarningsForm.value.end_at, 
  } 
}

searchEarnings(){ 
const format = 'yyyy-MM-dd'
const locale = 'en-US';
this.dates = this.setData()
this.end_date = formatDate(Object(this.dates)["end_at"], format, locale)
this.start_date = formatDate(Object(this.dates)["start_at"], format, locale)
this.misc.apiCall(`payments`, `admin/user_accounts/${this.id}/total_earnings?start_date=${this.start_date}&end_date=${this.end_date}`).then((res: any) => {
this.earning_totals = res
this.fee_total = Object(this.earning_totals)["Fee Total"]
this.delivery_total = Object(this.earning_totals)["Delivery Total"]
this.manual_total = Object(this.earning_totals)["Manual Total"]
this.tip_total = Object(this.earning_totals)["Tip Total"]
})
}

searchEarningsToday(){
  const now = new Date();
  const tomorrow = new Date(now);
  tomorrow.setDate(tomorrow.getDate() + 1)
  const format = 'yyyy-MM-dd'
  const locale = 'en-US';
  this.start_date = formatDate(now, format, locale)
  this.end_date = formatDate(tomorrow, format, locale)
  this.misc.apiCall(`payments`, `admin/user_accounts/${this.id}/total_earnings?start_date=${this.start_date}&end_date=${this.end_date}`).then((res: any) => {
    this.earning_totals = res
    this.fee_total = Object(this.earning_totals)["Fee Total"]
    this.delivery_total = Object(this.earning_totals)["Delivery Total"]
    this.manual_total = Object(this.earning_totals)["Manual Total"]
    this.tip_total = Object(this.earning_totals)["Tip Total"]
    })
}

searchEarningsYesterday(){
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1)
  const format = 'yyyy-MM-dd'
  const locale = 'en-US';
  this.end_date = formatDate(now, format, locale)
  this.start_date = formatDate(yesterday, format, locale)
  this.misc.apiCall(`payments`, `admin/user_accounts/${this.id}/total_earnings?start_date=${this.start_date}&end_date=${this.end_date}`).then((res: any) => {
    this.earning_totals = res
    this.fee_total = Object(this.earning_totals)["Fee Total"]
    this.delivery_total = Object(this.earning_totals)["Delivery Total"]
    this.manual_total = Object(this.earning_totals)["Manual Total"]
    this.tip_total = Object(this.earning_totals)["Tip Total"]
    })
    console.log("this.earning_totals",this.earning_totals)
}
  }

