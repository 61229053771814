<div class="heading">
    <div></div>
    <h4>Create Sub Category</h4>
    <mat-icon (click)='closeDialog()' >clear</mat-icon>
</div>

<div class="edit-fields">
    <mat-form-field class='field' appearance='outline'>
        <mat-label>name</mat-label>
        <input type="text" matInput [(ngModel)]='name' placeholder="a new category" name='name'>
      </mat-form-field>

</div>

<div class="save">
    <button mat-flat-button class='button cancel' (click)='closeDialog()'> Cancel</button>
    <button mat-flat-button class='button' (click)='createCategory()'> Save</button>
</div>