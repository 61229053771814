<div class="header">
  <h3> All earning types</h3>
  <mat-icon (click)="closeDialog()" class="action">clear</mat-icon>
</div>
<hr>


<!-- earnings type table -->
<table
  mat-table
  [dataSource]="earnings.earning_types"
  matSort
>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
    <td mat-cell *matCellDef="let earnings_type">
      <p>{{ earnings_type.name }}</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
    <td mat-cell *matCellDef="let earnings_type">
      <p>{{ earnings_type.description }}</p>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="earnings_type_columns" style="display: none;"></tr>
  <tr
    mat-row
    class="action"
    *matRowDef="let earnings_type; columns: earnings_type_columns"
  ></tr>
</table>

