<div class="container">
    <div class="row">
        <div class="column">
            <div class="flex-items">
                <div class="item-one">
                    <div class="card" *ngIf='driver'style="width:87%; height:84%"  >
                        <div class=" action customer-status">
                            <span class=' heading'>
                                <h3> DRIVER DETAILS &nbsp; <mat-icon>account_box</mat-icon>
                                </h3>
                            </span>
                        </div>
                        <div class="contact">
                            <p>
                                <mat-icon class="mat-icon2">label</mat-icon><b>Driver Name : </b>{{this.driver_name}}
                            </p>
                        </div>
                        <div class="contact">
                            <p>
                                <mat-icon class="mat-icon2">label</mat-icon><b>Driver user ID :</b> {{this.user_id}}
                            </p>
                        </div>
                        <div class="contact">
                            <p>
                                <mat-icon class="mat-icon2">label</mat-icon><b>Driver Status :</b>
                                {{this.driver_status}}
                            </p>
                        </div>
                        <div class="contact">
                            <p>
                                <mat-icon class="mat-icon2">label</mat-icon><b>Driver Verified :</b>
                                {{this.driver_verified}}
                            </p>
                            <div class="verified" *ngIf='this.driver_verified'>
                                <mat-icon class='true verified'>check_circle_outline</mat-icon>
                                <p class='true'>verified</p>
                            </div>
                        </div>
                        <div class="contact">
                            <p>
                                <mat-icon class="mat-icon2">label</mat-icon><b>Fleet Driver :</b>
                                {{this.driver_is_fleet_driver}}
                            </p>
                            <div class="verified" *ngIf='driver_is_fleet_driver'>
                                <mat-icon class='true verified'>verified</mat-icon>
                            </div>
                            <div class="not_verified" *ngIf='!driver_is_fleet_driver'>
                                <mat-icon class='true verified'>block</mat-icon>
                            </div>
                            <mat-slide-toggle
                            [(ngModel)]="driver_is_fleet_driver"
                            (change)="toggleFleetDriver()"
                          ></mat-slide-toggle>
                
                        </div>
                        <div class="contact" *ngIf='this.driver_is_fleet_driver'>
                            <p>
                                <mat-icon class="mat-icon2">label</mat-icon><b>Driver Fleet ID :
                                </b>{{this.driver_fleet_id}}
                            </p>
                        </div>
                        <div class="contact">
                            <p>
                                <mat-icon class="mat-icon2">label</mat-icon><b>Driver Email :</b> {{this.driver_email}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ---------- END OF FIRST CARD ------------ -->

        <div class="column">
            <div class="flex-items" >
    
                <div class="item-one">
                    <div class="card2" *ngIf='driver'>
    
                        <div class="action customer-status">
                            <span class=' heading'>
                                <h3> TOTAL EARNINGS SUMMARY  &nbsp; <mat-icon>money</mat-icon>
                                </h3>
                            </span>
                        </div>
                        <hr>
                        <div class="table-container2">
                            <table style="width:100%">
                                <tr>
                                  <th>Fee Total:</th>
                                  <td>{{fee_total}}</td>
                                </tr>
                                <tr>
                                  <th>Incentive Total:</th>
                                  <td>{{delivery_total}}</td>
                                </tr>
                                <tr>
                                  <th>Manual Total:</th>
                                  <td>{{manual_total}}</td>
                                </tr>
                                <tr>
                                  <th>Tip Total:</th>
                                  <td>{{tip_total}}</td>
                                  </tr>
                              </table>
                   
                        </div>
                        <form [formGroup]="TotalEarningsForm">
                            <button (click)="searchEarningsToday()" mat-raised-button   class="button" style="bottom: 4px;">TODAY</button>
                            <button (click)="searchEarningsYesterday()" mat-raised-button   class="button" style="margin-left: 30px; margin-right: 30px;  bottom: 4px;">YESTERDAY</button>
                        <mat-form-field appearance="outline" class="field" style="width:220px;">
                            <mat-label>Active date range</mat-label>
                            <mat-date-range-input [rangePicker]="picker" >
                              <input
                                matStartDate
                                name="active_from"
                                placeholder="Start date" 
                                formControlName="start_at"
                              />
                              <input
                                matEndDate
                                name="active_until"
                                placeholder="End date" 
                                formControlName="end_at"
                              />
                            </mat-date-range-input>
                            <mat-datepicker-toggle
                              matSuffix
                              [for]="picker"
                            ></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                          </mat-form-field>
                          <button (click)="searchEarnings()" mat-raised-button   class="button" style="margin-left: 30px; bottom: 4px;">SEARCH</button>
                        </form>
                       
                    </div>
    
                </div>
            </div>
        </div>
     </div>

    <!-- ---------- END OF SECOND CARD ------------ -->
    <div class="row">

        <div class="column">
            <div class="flex-items"  >
    
                <div class="item-one">
                    <div class="card2" *ngIf='driver' style="width:610px;" >
    
                        <div class=" action customer-status">
                            <span class='heading'>
                                <h3> DRIVER BANK DETAILS &nbsp; <mat-icon>payment</mat-icon>
                                </h3>
                                <button *ngIf="!editAccount" mat-flat-button class="button"  routerLink="/create-finances/{{this.id}}">
                                    ADD BANK ACCOUNT
                                  </button>
                            </span>
                        </div>
                        <hr>
                        <div class="table-container"  >
                            <table mat-table [dataSource]="account_bank">
    
                                <ng-container matColumnDef="bank_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Bank Name</th>
                                    <td mat-cell *matCellDef="let bank">
                                        <p *ngIf="bank.bank_name">{{ bank.bank_name }}</p>
                                        <p *ngIf="!bank.bank_name" class="empty">Bank Name not specified</p>
                                    </td>
                                </ng-container>
    
                                <!-- account_number column -->
    
                                <ng-container matColumnDef="account_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Name</th>
                                    <td mat-cell *matCellDef="let account_bank">
                                        <p *ngIf="account_bank.account_name">{{ account_bank.account_name }}</p>
                                        <p *ngIf="!account_bank.account_name" class="empty">Account Name not specified
                                        </p>
                                    </td>
                                </ng-container>
    
                                <ng-container matColumnDef="account_number">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Number</th>
                                    <td mat-cell *matCellDef="let bank">
                                        <p *ngIf="bank.account_number">{{ bank.account_number }}</p>
                                        <p *ngIf="!bank.account_number" class="empty">Account Number not specified</p>
                                    </td>
                                </ng-container>
    
                                <ng-container matColumnDef="branch_code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Branch Code</th>
                                    <td mat-cell *matCellDef="let bank">
                                        <p *ngIf="bank.branch_code">{{ bank.branch_code }}</p>
                                        <p *ngIf="!bank.branch_code" class="empty">Branch Code not specified
                                        </p>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="CreatedAt">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Added At</th>
                                    <td mat-cell *matCellDef="let bank">
                                        <p title="{{bank.updated_by}}">{{ bank.CreatedAt }} </p>
                                      
                                    </td>
                                </ng-container>
    
                                <ng-container matColumnDef="verified">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style=" margin-left:  auto;
                                    margin-right: auto;
                                    text-align: center">Verified</th>
                                    <td mat-cell *matCellDef="let bank">
                                        <p *ngIf="bank.verified"style=" margin-left:  auto;
                                        margin-right: auto;
                                        text-align: center"><mat-icon>checkmark</mat-icon></p>
                                        <p *ngIf="!bank.verified" class="empty"style=" margin-left:  auto;
                                        margin-right: auto;
                                        text-align: center" >   </p>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="default_account">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style=" margin-left:  auto;
                                    margin-right: auto;
                                    text-align: center">Default Account</th>
                                    <td mat-cell *matCellDef="let bank">
                                        <p *ngIf="bank.default_account" style=" margin-left:  auto;
                                        margin-right: auto;
                                        text-align: center" ><mat-icon>checkmark</mat-icon></p>
                                        <p *ngIf="!bank.default_account" class="empty"style=" margin-left:  auto;
                                        margin-right: auto;
                                        text-align: center" > </p>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="edit">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                    <td mat-cell *matCellDef="let account_bank">
                                        <mat-icon class='action' (click)="editBank(account_bank)">edit</mat-icon> 
                    
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="delete">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                                    <td mat-cell *matCellDef="let account_bank">
                                        <mat-icon class='action' (click)="deleteCheck(account_bank)">delete</mat-icon> 
                    
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="Columns4"></tr>
                                <tr mat-row class="action" *matRowDef="let account_bank; Columns: Columns4"></tr>
                            </table>
                            
                        </div> 
                        <table *ngIf="editAccount">
                            <td>
                                <mat-form-field>
                                  <input matInput placeholder="enter name" name="account_name" type="text"  [(ngModel)]="account_name"  #ctrl="ngModel"  fxFlex="50">
                                </mat-form-field>
                              </td>
                              <td>
                                <mat-form-field >
                                  
                                  <input matInput placeholder="Account Number" name="account_number" type="text"  [(ngModel)]="account_number"  #ctrl="ngModel" fxFlex="50">
                                </mat-form-field>
                              </td>
                              <td>
                                <mat-form-field >
                             
                                  <input matInput placeholder="Branch Code" name="branch_code" type="text"  [(ngModel)]="branch_code"  #ctrl="ngModel" fxFlex="50">
                                </mat-form-field>
                              </td>
                              <td>
                                
                                <mat-checkbox matTooltip='update user Default Account' class='update action' (click)='UpdateDefault()'fxFlex="25">Default {{default_account}}</mat-checkbox>
                              </td>
                              <td>
                                
                                <mat-checkbox matTooltip='update user Default Account' class='update action' (click)='UpdateVerified()'fxFlex="25">verified {{verified}}</mat-checkbox>
                              </td>
                          
                
                              <td>
                                <button mat-flat-button class='button'(click)="updateBank()" >Update Bank</button>
                              </td>
                    
                            </table>
                            <!-- matStepperNext (click)="submitPartnerInfo()" (click)="submitPartner() -->
                         
                              <div id="dialog">
                                <div class='dialog' *ngIf='delete'>
                                  <span>
                                    <p>Are you sure you want to delete this banking details?</p>
                                    <mat-icon class='action' (click)='deleteCheck(account_bank)'>close</mat-icon>
                                  </span>
                                  <button mat-flat-button class='button' (click)='deleteBankAccount(account_bank)' >Confirm</button>
                                </div>
                              </div>
                    </div>
                    
                </div>
              
            </div>
        
        </div>

        <!-- ---------- END OF THIRD CARD ------------ -->
        <div class="column">
            <div class="flex-items">

                <div class="item-one">
                    <div class="card4" *ngIf='driver' >

                        <div class=" action customer-status">
                            <span class=' heading'>
                                <h3> DRIVER EARNINGS &nbsp; <mat-icon>money</mat-icon>
                                </h3>
                            </span>
                        </div>
                        <hr>
                        <div class="table-container">
                            <table mat-table [dataSource]="accounts_earnings" matSort>

                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                                    <td mat-cell *matCellDef="let earning">
                                        <p *ngIf="earning.date">{{ earning.date.substring(0,10) }}</p>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="amount">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
                                    <td mat-cell *matCellDef="let earning">
                                        <p *ngIf="earning.amount">{{ earning.amount }}</p>
                                        <p *ngIf="!earning.amount" class="empty">Amount not specified</p>
                                    </td>
                                </ng-container>


                                <ng-container matColumnDef="description">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                                    <td mat-cell *matCellDef="let earning">
                                        <p *ngIf="earning.description">{{ earning.description }}</p>
                                        <p *ngIf="!earning.description" class="empty">Description not specified</p>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="distance">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Distance</th>
                                    <td mat-cell *matCellDef="let earning">
                                        <p *ngIf="earning.distance">{{ earning.distance }}</p>
                                        <p *ngIf="!earning.distance" class="empty">Distance not specified</p>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="earning_type_id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Earning type ID</th>
                                    <td mat-cell *matCellDef="let earning">
                                        <p *ngIf="earning.earning_type_id">{{ earning.earning_type_id }}</p>
                                        <p *ngIf="!earning.earning_type_id" class="empty">Earning type ID not specified
                                        </p>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                                    <td mat-cell *matCellDef="let earning">
                                        <p *ngIf="earning.status">{{ earning.status }}</p>
                                        <p *ngIf="!earning.status" class="empty">Status not specified</p>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="order_id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Order ID</th>
                                    <td mat-cell *matCellDef="let earning">
                                        <p *ngIf="earning.order_id">{{ earning.order_id }}</p>
                                        <p *ngIf="!earning.order_id" class="empty">Order ID not specified</p>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="Columns3"></tr>
                                <tr mat-row class="action" *matRowDef="let earning; columns: Columns3"></tr>
                            </table>
                        </div>
                        Per page:
                        <span class="same">
                            <button mat-button [matMenuTriggerFor]="paginate">
                                <span>{{limit}}</span>
                                <mat-icon>arrow_drop_down</mat-icon>
                            </button>
                            <mat-menu #paginate="matMenu">
                                <button mat-menu-item (click)="setLimitEarnings(5)">5</button>
                                <button mat-menu-item (click)="setLimitEarnings(10)">10</button>
                                <button mat-menu-item (click)="setLimitEarnings(15)">15</button>
                            </mat-menu>
                        </span>

                        <span (click)='startEarnings()' matTooltip="Skip to start" class='action' *ngIf='page > 1'
                            style="padding-right:30px;">back to start</span>
                        <span (click)='previousEarnings()' class='action'>previous</span>
                        <span style="padding-left:30px;">{{page}}</span>
                        <span (click)='nextEarnings()' class='action' style="padding-left:30px;">next</span>
                        <div> </div>
                    </div>

                </div>
            </div>
        </div>
        
    </div>
     <!-- ---------- END OF FOURTH CARD ------------ -->
     <div class="row">
    <div class="column">
        <div class="flex-items" >

            <div class="item-one">
                <div class="card2" *ngIf='driver' style="width:610px;">

                    <div class=" action customer-status">
                        <span class=' heading'>
                            <h3> DRIVER PAYMENTS &nbsp; <mat-icon>monetization_on</mat-icon>
                            </h3>
                        </span>
                    </div>
                    <hr>
                    <div class="table-container">
                        <table mat-table [dataSource]="account_payments">

                            <ng-container matColumnDef="date">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                                <td mat-cell *matCellDef="let payment">
                                    <!-- angular het cool pipes soos die date pipe sodat jy die date kan format in die html -->
                                    <!-- die substring pipe laat jou toe om n string te edit soos jy dit wil he -->
                                    <p *ngIf="payment.date">{{ payment.date.substring(0,10) }}</p>
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="account_name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Name</th>
                                <td mat-cell *matCellDef="let payment">
                                    <p *ngIf="payment.account_name">{{ payment.account_name }}</p>
                                    <p *ngIf="!payment.account_name" class="empty">account name not specified</p>
                                </td>
                            </ng-container>

                            <!-- account_number column -->

                            <ng-container matColumnDef="account_number">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Number</th>
                                <td mat-cell *matCellDef="let payment">
                                    <p *ngIf="payment.account_number">{{ payment.account_number }}</p>
                                    <p *ngIf="!payment.account_number" class="empty">account number not specified
                                    </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="amount">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
                                <td mat-cell *matCellDef="let payment">
                                    <p *ngIf="payment.amount">{{ payment.amount }}</p>
                                    <p *ngIf="!payment.amount" class="empty">Amount not specified</p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="payment_method">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment method</th>
                                <td mat-cell *matCellDef="let payment">
                                    <p *ngIf="payment.payment_method">{{ payment.payment_method }}</p>
                                    <p *ngIf="!payment.payment_method" class="empty">Payment method not specified
                                    </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                                <td mat-cell *matCellDef="let payment">
                                    <p *ngIf="payment.status">{{ payment.status }}</p>
                                    <p *ngIf="!payment.status" class="empty">Status not specified</p>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="Columns"></tr>
                            <tr mat-row class="action" *matRowDef="let payment; columns: Columns"></tr>
                        </table>
                    </div>
                    Per page:
                    <span class="same">
                        <button mat-button [matMenuTriggerFor]="paginate">
                            <span>{{limitPayment}}</span>
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>
                        <mat-menu #paginate="matMenu">
                            <button mat-menu-item (click)="setLimitPayments(5)">5</button>
                            <button mat-menu-item (click)="setLimitPayments(10)">10</button>
                            <button mat-menu-item (click)="setLimitPayments(15)">15</button>
                        </mat-menu>
                    </span>

                    <span (click)='startPayments()' matTooltip="Skip to start" class='action'
                        *ngIf='pagePayment > 1' style="padding-right:30px;">back to start</span>
                    <span (click)='previousPayments()' class='action'>previous</span>
                    <span style="padding-left:30px;">{{pagePayment}}</span>
                    <span (click)='nextPayments()' class='action' style="padding-left:30px;">next</span>
                    <!-- <button mat-flat-button class="button"  routerLink="/bank-view/{{id}}"style="left: 190px;">View Bank Details</button> -->
                    
                </div>

            </div>
        </div>
    </div>
    <!-- ---------- END OF FIFTH CARD ------------ -->

    <div class="column">
        <div class="flex-items">

            <div class="item-one">
                <div class="card3" *ngIf='driver' style="width: 87%;">

                    <div class=" action customer-status">
                        <span class=' heading'>
                            <h3> DRIVER ASSETS &nbsp; <mat-icon>motorcycle</mat-icon>
                            </h3>
                        </span>
                    </div>
                    <hr>
                    <div class="table-container">
                        <table mat-table [dataSource]="account_assets" matSort>
                            <ng-container matColumnDef="asset_name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
                                <td mat-cell *matCellDef="let asset">
                                    <p *ngIf="asset.asset_name">{{ asset.asset_name}}</p>
                                    <p *ngIf="!asset.asset_name" class="empty">Assets not specified</p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="asset_id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset ID</th>
                                <td mat-cell *matCellDef="let asset">
                                    <p *ngIf="asset.asset_id">{{ asset.asset_id}}</p>
                                    <p *ngIf="!asset.asset_id" class="empty">asset_id not specified</p>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="start_date">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Start date</th>
                                <td mat-cell *matCellDef="let asset">
                                    <p *ngIf="asset.start_date">{{ asset.start_date.substring(0,10) }}</p>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="end_date">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>End date</th>
                                <td mat-cell *matCellDef="let asset">
                                    <p *ngIf="asset.end_date">{{ asset.end_date.substring(0,10) }}</p>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="amount">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
                                <td mat-cell *matCellDef="let asset">
                                    <p *ngIf="asset.amount">{{ asset.amount}}</p>
                                    <p *ngIf="!asset.amount" class="empty">Amount not specified</p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="frequency">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Frequency</th>
                                <td mat-cell *matCellDef="let asset">
                                    <p *ngIf="asset.frequency">{{ asset.frequency}}</p>
                                    <p *ngIf="!asset.frequency" class="empty">Frequency not specified</p>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="no_of_payments">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>No of payments</th>
                                <td mat-cell *matCellDef="let asset">
                                    <p *ngIf="asset.no_of_payments">{{ asset.no_of_payments}}</p>
                                    <p *ngIf="!asset.no_of_payments" class="empty">No of payments not specified</p>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="Columns2"></tr>
                            <tr mat-row class="action" *matRowDef="let asset; columns: Columns2"></tr>
                        </table>

                    </div>
                    Per page:
                    <span class="same">
                        <button mat-button [matMenuTriggerFor]="paginate">
                            <span>{{limitAsset}}</span>
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>
                        <mat-menu #paginate="matMenu">
                            <button mat-menu-item (click)="setLimitAssets(5)">5</button>
                            <button mat-menu-item (click)="setLimitAssets(10)">10</button>
                            <button mat-menu-item (click)="setLimitAssets(15)">15</button>
                        </mat-menu>
                    </span>

                    <span (click)='startAssets()' matTooltip="Skip to start" class='action' *ngIf='pageAsset > 1'
                        style="padding-right:30px;">back to start</span>
                    <span (click)='previousAssets()' class='action'>previous</span>
                    <span style="padding-left:30px;">{{pageAsset}}</span>
                    <span (click)='nextAssets()' class='action' style="padding-left:30px;">next</span>

                </div>
            </div>
        </div>
    </div>












</div>
    </div>
    