

  <span id="zonesMap" *ngIf="!zonesService?.loading">
        
    <span id="actionBarTop">
       <h2>{{zone?.area}}</h2>
    </span>

    <span id="actionBarBottom">
      <span id="actions">
  
          <span id="actionInputs">

            <span id="actionButtons">

              <button mat-button *ngIf="!zonesService.addingZone" id="editZoneBtn" (click)="addPolygon()"><mat-icon>add_circle_outline</mat-icon> create zone</button>
              <button mat-button *ngIf="zonesService.addingZone" id="editZoneBtn" (click)="savePolygon()">save zone</button>
              <!-- <button mat-button *ngIf="zonesService.edit" id="editZoneBtn" (click)="deleteZone(zone)"> <mat-icon>delete_outline</mat-icon> delete zone</button>
              <button mat-button id="editZoneBtn" (click)="editToggle()"> <mat-icon>drive_file_rename_outline</mat-icon> edit zone</button> -->
  
            </span>
  
            <mat-form-field id="area" *ngIf="zonesService.addingZone">
                <input type="text" name ="area" placeholder="area name" matInput ngModel [(ngModel)]="zonesService.selectedArea" value="">
             </mat-form-field>

            <mat-form-field id="citiesSelect" appearance='outline' *ngIf="zonesService.cities?.length > 0">
                <mat-label>select city</mat-label>
                <mat-select [(value)]="selectedCity" (selectionChange)="lookupByCity($event.value)">
        
                  <input class="select-search" placeholder="Search cities" type="text" aria-label="city" matInput [formControl]="citySearch" (keydown)="$event.stopPropagation()">
        
                  <mat-option *ngFor="let filterCity of (zonesService.filteredCities.length > 0 ? zonesService.filteredCities : zonesService.cities)" [value]="filterCity.city" >
                    {{filterCity.city}}
                  </mat-option>
                  
                </mat-select>
            </mat-form-field>
  
          </span>
  
      </span>
  
    </span>
  
    <span style="height: 10px; display:flex"></span>
  
      <agm-map 
      *ngIf="zonesService.mapsLoaded" 
      [zoom]="zonesService.zoom" 
      [latitude]="zonesService.lat" 
      [longitude]="zonesService.lng"
      (mapReady)="onMapReady($event)">
      </agm-map>
    </span>