<div *ngIf='delete' id="shade"></div>

<div class="search">
    <mat-icon (click)=' getNotifications(search)' matTooltip='search' class='search-icon' [style.left]="inputTouch ? '-20px' : '10px' ">search</mat-icon>
    <mat-icon *ngIf='search' (click)='clearSearch()'  matTooltip='clear' class='clear-icon'>clear</mat-icon>
    <input matInput (keydown.enter)=' getNotifications(search)' [(ngModel)]="search" placeholder="Search"  #input (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
  </div>

  <div *ngIf='!send'>
    <div *ngIf="!edit">
        <div class="stores" *ngIf=!create>
            <table  mat-table [dataSource]='notifications' matSort>

                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                    <td mat-cell *matCellDef="let notification" > {{notification.title}} </td>
                </ng-container>
            
                <ng-container matColumnDef="body">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Content </th>
                    <td mat-cell *matCellDef="let notification" (click)='toggleSend(notification)'> {{notification.body}} </td>
                </ng-container>

                <ng-container matColumnDef="sent_at">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Sent_at </th>
                    <td mat-cell *matCellDef="let notification"> <p *ngIf='notification.sent'>{{notification.sent_at}}</p> </td>
                </ng-container>
            
                <ng-container matColumnDef="sent">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Sent </th>
                    <td mat-cell *matCellDef="let notification">
                        <mat-icon [style.color]="notification.sent ? '' : '#dfdbdb' ">done</mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="send">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                    <td mat-cell *matCellDef="let notification"> 
                        <mat-icon *ngIf='!notification.sent' matTooltip='more' [matMenuTriggerFor]="menu">more_horiz</mat-icon> 
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)='toggleEdit(notification)'> 
                              <mat-icon>edit</mat-icon>
                              <span>edit</span>
                            </button>
                            <button mat-menu-item  (click)='deleteDialog(notification)'>
                              <mat-icon>delete_outline</mat-icon>
                              <span>delete</span>
                            </button>
                            <button (click)='toggleSend(notification)' mat-menu-item>
                              <mat-icon>send</mat-icon>
                              <span >Send Now</span>
                            </button>
                        </mat-menu>
                        <mat-icon matTooltip='duplicate' *ngIf=notification.sent (click)='duplicate(notification)'>content_copy</mat-icon>
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="tempColumns"></tr>
                <tr mat-row 
                    class='action'
                    *matRowDef="let notification; columns: tempColumns;">
                </tr>
            </table>

            <div id="pager" >

                <button id="{{auth.expand ? 'create-expand': 'create'}}" mat-flat-button class='button' routerLink='/push-create'  >New</button>

                <div class="page"> 

                <div> Per page:</div>
                <div (click)="setLimit(10)" class="limit" >10</div>
                <div (click)="setLimit(15)" class="limit" >15</div>
                <div (click)="setLimit(20)" class="limit" >20</div>
                <div (click)="setLimit(50)" class="limit" >50</div>
                <div>Page:</div>
                <mat-icon  matTooltip="Skip to start" (click)='start()'>arrow_left</mat-icon>
                <mat-icon (click)='previous()'>skip_previous</mat-icon>
                <div>{{page}}</div>
                <mat-icon (click)='next()'>skip_next</mat-icon>
                <div> </div>
                </div>
            </div>
        </div>
    </div>

    <!-- ============================ -->
    <!--    SEND PUSH NOTIFICATION    -->
    <!-- ============================ -->

    <div id="dialog">
        <div class='dialog' *ngIf='send'>
          <span>

            <mat-icon class='action' (click)='toggleSend()' >close</mat-icon>
          </span>
          <button mat-flat-button class='button'  >Confirm</button>
        </div>
    </div>

    <!-- ============================ -->
    <!--   DELETE PUSH NOTIFICATION   -->
    <!-- ============================ -->

    <div id="dialog">
        <div class='dialog' *ngIf='delete'>
          <span>
            <p>Are you sure you want to delete <br>{{notification.title}}?</p>
            <mat-icon class='action' (click)='deleteDialog()'>close</mat-icon>
          </span>
          <button mat-flat-button class='button' (click)='removeNotification()' >Confirm</button>
        </div>
    </div>

    <!-- ============================ -->
    <!--    EDIT PUSH NOTIFICATION    -->
    <!-- ============================ -->

    <div *ngIf='edit'>
      
      <div class="back action" routerLink="/push">
        <mat-icon>arrow_back</mat-icon>
        <p>push notifications</p>
      </div>
      
      <div class="create-card"  >
      
        <div class="header">
          <h3>update push notification</h3>
          <mat-icon (click)='toggleEdit()'>clear</mat-icon>
        </div>
                        
            <mat-form-field class='field' appearance='outline'>
                <mat-label>push notification title</mat-label>
                <input type="text" matInput [(ngModel)]='title' placeholder="everyday snacks" name='title' required>
            </mat-form-field>
      
            <mat-form-field class='field' appearance='outline'>
                <mat-label>push notification body</mat-label>
                <textarea type="text" name='body' matInput [(ngModel)]='body' rows=4 required placeholder="write something here" ></textarea>
              </mat-form-field>
      
      
              <div class="btn">
                <button mat-flat-button class='button' (click)='updateNotification()' >save</button>      
              </div>
      
      </div>

    </div>
</div>


    <!-- ======================== -->
    <!--   CONFIRM SEND DIALOG    -->
    <!-- ======================== -->
    <div *ngIf='send'>
      <div class="container">
        <div class="modal" id="msg" >
      </div>
      <span (click)='toggleSend()' class='back'>
        <mat-icon>arrow_back</mat-icon>
        <p>go back</p>
      </span>
        <div class="bezzel">
          <div class="screen">
            <div class="speaker"></div>
            <span class='date'>
                <p class='time'>{{time}}</p>
                <p class='day'>{{day.substring(0,10)}} <span> 0</span>{{day.substring(15,17)}} {{day.substring(11,16)}}</p>
            </span>
            <div class="message">
                <span class="header">
                    <img src='../../../../assets/logo.png' alt="" id="logo">
                    <h4>Quench</h4>
                </span>
                <p>{{notification.title}}</p>
                <h5>{{notification.body}}</h5>
            </div>
          </div>
        <span class='send'>
            <p *ngIf='!notification.sent'>* Please confirm that the details above are correct before sending</p>
            <div class="btn">
                <button mat-flat-button class='button'  *ngIf='!notification.sent' (click)='sendNotification()'>Send Push Notification Now</button>
                <br>
                <button mat-flat-button class='button'  *ngIf='notification.sent'  (click)='duplicate(notification)' >Duplicate Push Notification</button>
            </div>
        </span>
        </div>
      </div>
      
      </div>
      


  

