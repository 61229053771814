<div class="dashboard">
  
  
    <!-- ======================== -->
    <!--     TOP STATISTICS       -->
    <!-- ======================== -->
  
    <!-- order and customer -->
    <div class="charts">
      <div class="totals" id='top-stats' *ngFor='let stat of topStats'>
        <div class="total">
          <img src={{stat.image}} alt="">
          <span>
            <h2>{{stat.data}}</h2>
            <p>{{stat.name}}</p>
          </span>
        </div>
      </div>
    </div>
    
      <!-- tracking -->
    
      <div class="charts">
      <div class="totals" id='bottom-stats' *ngFor='let stat of trackingStats'>
        <div class="total">
          <img src={{stat.image}} alt="">
          <span>
            <h2 *ngIf="stat.tag !== 'time'">{{stat.data}} 
              <span *ngIf="stat.tag == 'conversion'">%</span>
            </h2>
            <h2 *ngIf="stat.tag == 'time'">{{stat.data.substring(0,8)}}</h2>
            <p>{{stat.name}}</p>
          </span>
        </div>
      </div>
    </div>
    
      <!-- ======================== -->
    <!--     QUENCH ORDERS        -->
    <!-- ======================== -->
  
    <h4>ORDERS </h4>
  
    <td class="charts" (window:resize)="onResize($event)">
  
      <div class="chart">
        <h3>Quench orders per month</h3>
        <google-chart #chart *ngIf="ordersPerMonth.length > 0"
                      [type]="'LineChart'"
                      [data]="ordersPerMonth"
                      [options]="quench"
                      [width]="width"
                      [height]="height"
                                       >
        </google-chart>
      </div>
    
      <div class="chart">
        <h3>Quench orders over last 30 days</h3>
        <google-chart #chart *ngIf="ordersPerDay.length > 0"
                      [type]="'LineChart'"
                      [data]="ordersPerDay"
                      [options]="quench"
                      [width]="width"
                      [height]="height"
                                      >
        </google-chart>
      </div>
  
    </td>
  
    <!-- ================== -->
    <!--       STORES       -->
    <!-- ================== -->
  
    <td class="store-stats">
  
      <div class="store">
  
        <h3>Top 10 Stores</h3>
        <table>
          <tr *ngFor="let store of stores; let odd = odd"  [style.background]="odd ? '' : '#f2f2f2' ">
            <td>{{store.store_name}}</td>
            <td >{{store.total}}</td>
          </tr>
        </table>
      </div>
  
      <div class="store">
        <h3>Total Orders Per Retailer</h3>
        <table>
          <tr *ngFor="let retailer of retailers; let odd = odd" [style.background]="odd ? '' : '#f2f2f2' ">
            <td>{{retailer.retailer}}</td>
            <td style="text-align: right;padding-right:20px;">{{retailer.total}}</td>
          </tr>
        </table>
      </div>
  
    </td>
  
     <!-- ========================== -->
    <!--      QUENCH CUSTOMERS      -->
    <!-- ========================== -->
  
    <h4>QUENCH CUSTOMERS</h4>
  
    <td class="charts" (window:resize)="onResize($event)">
  
      <div class="chart">
        <h3>Quench customer sign-ups per month</h3>
        <google-chart #chart *ngIf="quenchUsersSignUpPM.length > 0"
                      [type]="'ColumnChart'"
                      [data]="quenchUsersSignUpPM"
                      [options]="quench"
                      [width]="width"
                      [height]="height"
                                      >
        </google-chart> 
      </div>
  
      <div class="chart">
        <h3> Quench customer sign-ups per day</h3>
        <google-chart #chart *ngIf="quenchUsersSignUpPD.length > 0"
                      [type]="'ColumnChart'"
                      [data]="quenchUsersSignUpPD"
                      [options]="quench"
                      [width]="width"
                      [height]="height"
                                        >
        </google-chart> 
      </div>
  
    </td>
  
    <!-- ========================== -->
    <!--     DIS-CHEM CUSTOMERS     -->
    <!-- ========================== -->
  
    <h4>DIS-CHEM CUSTOMERS</h4>
  
    <td class="charts" (window:resize)="onResize($event)">
  
      <div class="chart">
        <h3>Dis-Chem customer sign-ups per month</h3>
        <google-chart #chart *ngIf="disChemUsersSignUpPM.length > 0"
                      [type]="'ColumnChart'"
                      [data]="disChemUsersSignUpPM"
                      [options]="dischem"
                      [width]="width"
                      [height]="height"
                                      >
        </google-chart> 
      </div>
  
      <!-- dischem colors-->
  
      <div class="chart">
        <h3> Dis-Chem customer sign-ups per day</h3>
        <google-chart #chart *ngIf="disChemUsersSignUpPD.length > 0"
                      [type]="'ColumnChart'"
                      [data]="disChemUsersSignUpPD"
                      [options]="dischem"
                      [width]="width"
                      [height]="height"
                                        >
        </google-chart> 
      </div>
  
    </td>
      
  
    <!-- ==================  -->
    <!--      TRACKING       -->
    <!-- ==================  -->
  
    <h4>TRACKING</h4>
  
  
  <!-- product stats -->
  
  <h5>Products</h5>
    <td class="store-stats">
  
      <div *ngFor ="let data of tracking;" class="store">
        <h3 >Top 25 {{data.name}} Products</h3>
        <div class='table'>
          <table>
            <tr *ngFor='let product of data.data; let odd = odd' [style.background]="odd ? '' : '#f2f2f2' ">
              <td>{{product.name}}</td>
              <td >{{product.total}}</td>
            </tr>
          </table>
        </div>
      </div>
    </td>  
  
     <!-- banner stats -->
  
     <h5>Banners</h5>
     <td class="store-stats" id='banner-stats'>
   
       <div *ngFor ="let data of banners" class="store" id='banners'>
         <h3 >Top {{data.name}} Banner Clicks</h3>
         <div class='table'>
           <table>
             <tr *ngFor='let banner of data.data; let odd = odd'[style.background]="odd ? '' : '#f2f2f2' "  >
               <td>{{banner.name}}</td>
               <td >{{banner.total}}</td>
             </tr>
           </table>
         </div>
       </div>
     </td>

    <!-- ===========================  -->
    <!--   RETAILER DELIVERY TIMES    -->
    <!-- ===========================  -->
   
      <div class="retailer-turnovers">
        <h3>Retailers average daily delivery times</h3>

        <div class="retailers">
            <div class='retailer' *ngFor='let item of retailer_average_delivery_times'>
              <p class='name' *ngIf='item.name'>{{item.name}}</p>
              <p class='name' *ngIf='!item.name'>No Name</p>
                <p class='total-orders'>{{item.time}}</p>
                <img src="{{item.image}}" alt=""  onerror="this.src='assets/placeholders/logo-placeholder.png';">
            </div>
        </div>
 
    </div>
  </div>
  
