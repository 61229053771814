<form #product = 'ngForm' (ngSubmit)='submitProduct(product.value)'>
    <div class="form">
        <span class="split">
            <h3>Create a Product</h3>
            <mat-form-field >
                <mat-label>Name</mat-label>
                <input name='name' type='text' matInput  ngModel required>
            </mat-form-field>
            <mat-form-field >
                <mat-label>Description</mat-label>
                <input name='description' type='text' matInput  ngModel>
            </mat-form-field>
            <mat-form-field >
                <mat-label>Price</mat-label>
                <input name='price' type='text' matInput  ngModel required>
            </mat-form-field>
            <mat-form-field >
                <mat-label>Size</mat-label>
                <input name='size' type='text' matInput  ngModel>
            </mat-form-field>
            </span>
            <span class="split">
            <h3 id="back"><mat-icon class='action' routerLink='/retailers/{{id}}'>close</mat-icon></h3>
            <mat-form-field >
                <mat-label>Barcode</mat-label >
                <input name='barcode' type='text' matInput  ngModel required>
            </mat-form-field>
            <mat-form-field >
                <mat-label>Sku</mat-label>
                <input name='sku' type='text' matInput  ngModel required>
            </mat-form-field>
            <mat-form-field >
                <mat-label>Category</mat-label>
                <mat-select [(ngModel)]="selectedValue" name="category">
                    <mat-option *ngFor="let cat of categories" [value]="cat.id">
                        {{cat.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field >
                <mat-label>Rebate</mat-label>
                <input name='rebate' type='text' matInput  ngModel required>
            </mat-form-field>
            </span>
        </div>
            
        <div id='image'>
            <img class="preview-image" [src]="imageFile ? imageFile.resource : '../assets/placeholder.png'">
            <br>
            <button class="custom-file-upload" mat-button type="button" onclick="document.querySelector('#exampleInputFile').click()">Upload Image</button>
            <input type="file" id="exampleInputFile" (change)="imagesPreview($event)" style="display: none;">
        </div>

        <div class='btn'>
            <button type='submit' mat-flat-button>Create</button>
        </div>
</form>

  