<div class="search">
  <mat-icon
    (click)="driver.searchReset()"
    matTooltip="search"
    class="search-icon"
    [style.left]="misc.inputTouch ? '-20px' : '10px'"
    >search</mat-icon
  >
  <mat-icon
    *ngIf="driver.search"
    (click)="driver.clearSearch()"
    matTooltip="clear"
    class="clear-icon"
    >clear</mat-icon
  >
  <input
    (keydown.enter)="driver.searchReset()"
    matInput
    [(ngModel)]="driver.search"
    placeholder="Search drivers"
    #input
    (blur)="misc.onBlur()"
    (focus)="misc.onFocus()"
    [style.left]="misc.inputTouch ? '10px' : '35px'"
  />
</div>

<div *ngIf="driver.drivers">
  <table mat-table [dataSource]='driver.drivers' matSort>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let user" (click)='navigateRoute(user)'> {{user.first_name}} {{user.last_name}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let user"  (click)='navigateRoute(user)' > {{user.email}} </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> phone </th>
      <td mat-cell *matCellDef="let user"> {{user.phone}} </td>
    </ng-container>

    <ng-container matColumnDef="last active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> last active </th>
      <td mat-cell *matCellDef="let user"> {{user.last_login | date: 'MMM d, y - h:mm a'}} </td>
    </ng-container>

    <ng-container matColumnDef="verified">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Verified </th>
      <td mat-cell *matCellDef="let user"><mat-icon class='verified' *ngIf='user.active; else inactive'>verified_user</mat-icon>
      <ng-template #inactive><mat-icon>gpp_bad</mat-icon> </ng-template></td>
    </ng-container>
  

    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row *matRowDef="let user; columns: Columns;" class='action'></tr>
  </table>
</div>

<div id="pager">
  <div class="page">
    <button
      id="{{ auth.expand ? 'create-expand' : 'create' }}"
      mat-flat-button
      class="button"
      routerLink="/drivers-create"
    >
      New
    </button>

    <div>Per page:</div>
    <span class="silo-filter pagination">
      <button mat-button [matMenuTriggerFor]="paginate">
        <span>{{ driver.limit }}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #paginate="matMenu">
        <button mat-menu-item (click)="driver.setLimit(10)">10</button>
        <button mat-menu-item (click)="driver.setLimit(20)">20</button>
        <button mat-menu-item (click)="driver.setLimit(50)">50</button>
      </mat-menu>
    </span>

    <div
      (click)="driver.start()"
      matTooltip="Skip to start"
      class="action"
      *ngIf="driver.page > 1"
    >
      back to start
    </div>
    <p (click)="driver.previous()" class="action">previous</p>
    <div>{{ driver.page }}</div>
    <p (click)="driver.next()" class="action">next</p>
    <div></div>
  </div>
</div>
