import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-faq-create',
  templateUrl: './faq-create.component.html',
  styleUrls: ['./faq-create.component.scss']
})
export class FaqCreateComponent implements OnInit {

  constructor(public api: ApiService,
              public message: MessagesService,
              public router: Router) { }

  ngOnInit(): void {
  }

  createFaq(faq){
    let FAQ = [
      {
        heading: faq.heading,
        title: faq.title,
        body: faq.body,
        position: 1
      }
    ]

    this.api.post(`catelog`, `faqs`, FAQ).subscribe((faq: any) => {
      this.message.showSnack(`Successfully created ${faq[0].heading}`);
      this.router.navigate([`/faqs`]);
    }, error => {
      this.message.showSnack(error.error);
    })
  }
}
