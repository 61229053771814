<div class="search">
  <mat-icon
    (click)="posts.getPosts()"
    matTooltip="search"
    class="search-icon"
    [style.left]="misc.inputTouch ? '-20px' : '10px'"
    >search</mat-icon
  >
  <mat-icon
    *ngIf="posts.search"
    (click)="posts.clearSearch()"
    matTooltip="clear"
    class="clear-icon"
    >clear</mat-icon
  >
  <input
    matInput
    (keydown.enter)="posts.getPosts()"
    [(ngModel)]="posts.search"
    placeholder="Search"
    #input
    (blur)="misc.onBlur()"
    (focus)="misc.onFocus()"
    [style.left]="misc.inputTouch ? '10px' : '35px'"
  />
</div>

<div class="silos" *ngIf="!isDisChemUser">
  <div
    (click)="setSilo(asilo)"
    *ngFor="let asilo of misc.silos"
    [class]="asilo.name == misc.silo.name ? 'isBold' : ''"
  >
    {{ asilo.name }}
  </div>
</div>

<!-- <mat-form-field  class='siloMobile' >
  <mat-label >{{silo.name}}</mat-label>
  <mat-select [(ngModel)]="misc.silo" (selectionChange)="setSilo(silo)">
    <mat-option *ngFor="let silo of misc.silos" [value]="silo">
      {{silo.name}}
    </mat-option>
  </mat-select>
</mat-form-field> -->

<table class="table">
  <thead>
    <tr>
      <th class="header" id="sort">Sort</th>
      <th class="header" id="icon"></th>
      <th class="header">Name</th>
      <th class="header">Type</th>
    </tr>
  </thead>
  <tbody cdkDropList (cdkDropListDropped)="posts.drop($event)">
    <tr
      [ngClass]="{ banners: post.position < 6 }"
      class="action"
      *ngFor="let post of posts.posts"
      cdkDrag
      cdkDragLockAxis="y"
    >
      <th class="sort">
        <p><mat-icon>sort</mat-icon></p>
      </th>
      <th routerLink="/posts/{{ post.id }}" class="icon">
        <img src="{{ post.image }}" alt="" />
      </th>
      <th routerLink="/posts/{{ post.id }}">
        <p>{{ post.title }}</p>
      </th>
      <th class="slider">
        <span id="type">
          <p *ngIf="post.position < 6">
            banner - Do not reposition unless instructed to do so
          </p>
        </span>
      </th>
    </tr>
  </tbody>
</table>

<div id="pager">
  <button
    mat-flat-button
    class="button"
    id="{{ auth.expand ? 'create-expand' : 'create' }}"
    routerLink="/create-post"
  >
    New
  </button>
  <div class="page"></div>
</div>

<!-- <div class='mobile'>
    <mat-expansion-panel hideToggle  *ngFor='let post of posts '>
      <mat-expansion-panel-header >
        <mat-panel-title>
          <div class="mobileTitle">
            <img src={{post.image}} alt="">
            <p class='title'>{{post.title}}</p>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <span >
        <p>	{{post.title}} </p>
      </span>
      <span >
        <mat-title id='postHeading'>Description:</mat-title>
        <p>	{{post.body}} </p>
      </span>
      </mat-expansion-panel>
  </div> -->
