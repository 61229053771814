import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-distant-fees',
  templateUrl: './distant-fees.component.html',
  styleUrls: ['./distant-fees.component.scss']
})
export class DistantFeesComponent implements OnInit {

  toDistance=0;
  fromDistance=0;
  amount=0;
  id:any="";
  showForm=false;


  fees:any=[];
  constructor(
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.listFees()
  }

  listFees(){
    this.close()
    this.api.getList("payments","admin/fees?page").subscribe((data:any)=>{
     
      this.fees = data.fees
    })
  }
  add(){
    this.id=""
    this.showForm=true
  }
  save(){
    let data ={
      "to_distance":this.toDistance,
      "from_distance":this.fromDistance,
      "amount":this.amount
    }
    this.api.post("payments","admin/fees",data).subscribe((data:any)=>{
 
      this.listFees()
    })
  }
  edit(fee:any){
    this.showForm=true
    this.toDistance = fee.to_distance 
    this.fromDistance = fee.from_distance 
    this.amount = fee.amount 
    this.id = fee.ID
  }
  update(){

    let data ={
      "to_distance":this.toDistance,
      "from_distance":this.fromDistance,
      "amount":this.amount
    }
    this.api.patch("payments","admin/fees/"+ this.id, data).subscribe((data:any)=>{
      this.listFees()
    })
  }
  
  delete(fee:any){
    this.api.delete("payments","admin/fees/"+ fee.ID).subscribe((data:any)=>{
      this.listFees()
    })
  }
  close(){
    this.showForm=false;
  }
}
