import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountsService } from 'src/app/services/accounts/accounts.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  // hier define ons die Columns wat ons wil gebruik vir die html. 
  // hierdie 3 is net n voorbeeld, besluit al die fields wat jy in die tabel wil he en add dit hier
  Columns = [
    'date',
    'account_name',
    'account_number',
    'amount',
    'status',
    'payment_method'
  ]

  constructor(public route: ActivatedRoute, public accounts: AccountsService) { }


  ngOnInit(): void {
    // die activated route laat jou toe om n url parameter te access
    // so hier vat ons n snapshot van die id in die url sodat ons dit kan in pass in die getUserAccountPayments function
    let id = this.route.snapshot.paramMap.get('id')

    // kyk hoe werk hierdie function en dan kan jy dit gebruik vir die ander pages/components
    // die accounts service hanteer al die verskillende api calls vir die account endpoints, so jy behoort
    // al die functions vir hierdie task daar te sit. hierdie function is n goeie voorbeeld.
    this.accounts.getUserAccountPayments(id)
  }

}
