import { Injectable } from '@angular/core';
import { MiscService } from '../misc/misc.service';

@Injectable({
  providedIn: 'root'
})
export class BanksService {

  banks

  constructor(public misc: MiscService) { }

  async getBanks(){
    await this.misc.apiCall(`payments`, `admin/banks`).then(
      (res: any) => {
        this.banks = res.banks
        console.log(this.banks)
      }
    )
  }
  
  updateBank(id, obj){
    this.misc.apiPatch('payments', `admin/banks/${id}`, obj).then((res: any) => {
      return
    })
  }
}
