<div *ngIf='shade' id='shade' class='shade'> 
  <mat-spinner class='spinner'></mat-spinner> 
</div>

<div class="back action" routerLink="/stores">
  <mat-icon>arrow_back</mat-icon>
  <p>stores</p>
</div>

<div class="create-card"  >

  <div class="header">
    <h3>create new store</h3>
    <mat-icon routerLink='/stores'>clear</mat-icon>
  </div>

    <!-- ---------------------- -->
    <!--   COLLECT MALL INFO    --> 
    <!-- ---------------------- -->

    <div class="first-form" [style.left]="storeInfo  ? '-900px' : '0' ">
        
      <form #store = 'ngForm' (ngSubmit)='submitStoreInfo(store.value)'>

        <mat-form-field appearance="outline" class='field'>
          <mat-label>address lookup</mat-label>
          <input matInput 
                placeholder="12 bob street" 
                matGoogleMapsAutocomplete
                type="address,place"
                country="za"
                (onAutocompleteSelected)="onAutocompleteSelected($event)"
                required
                >
        </mat-form-field>
      
          <mat-form-field class='field' appearance='outline'>
            <mat-label>store name</mat-label>
            <input type="text" matInput [(ngModel)]="name" placeholder="gouda cheese" name='name' required>
          </mat-form-field>

          <div class="form">
            <mat-form-field  appearance='outline'>
              <mat-label>email</mat-label>
              <input type="email" matInput ngModel placeholder="johndoe@gmail.com" name='email' required>
            </mat-form-field>
            <mat-form-field  appearance='outline'>
              <mat-label>phone</mat-label>
              <input type="text" matInput [(ngModel)]="phone"  placeholder="021 558 9304" name='phone' required>
            </mat-form-field>
            <mat-form-field  appearance='outline'>
              <mat-label>vat number</mat-label>
              <input type="text" matInput ngModel placeholder="12345678" name='vat_number'>
            </mat-form-field>
            <mat-form-field  appearance='outline'>
              <mat-label>liquor license number</mat-label>
              <input type="text" matInput ngModel placeholder="1234567" name='liquor_license_number' >
            </mat-form-field>
          </div>

          <!-- <div class="form">

          </div> -->

          <div class="form">
            <mat-form-field  appearance='outline'>
              <mat-label>url</mat-label>
              <input type="text" matInput [(ngModel)]="url" placeholder="www.quench.mobi" name='url' >
            </mat-form-field>
            <mat-form-field  appearance='outline'>
              <mat-label>loop branch code</mat-label>
              <input type="text" matInput ngModel placeholder="qa9103932" name='loop_branch_code' >
            </mat-form-field>
            <mat-form-field  appearance='outline'>
              <mat-label>api key</mat-label>
              <input type="text" matInput ngModel placeholder="23432432432" name='api_link' >
            </mat-form-field>
          </div>

          <div class="form">
            <mat-form-field  appearance='outline' class='field'>
              <mat-label>slogan</mat-label>
              <textarea type="text" matInput ngModel placeholder="the finest wines in town" name='about' ></textarea>
            </mat-form-field>
          </div>    
    
          <div class="btn">
            <button mat-flat-button class='button' type='submit' >next</button>      
          </div>

      </form>

    </div>

    <!-- ------------------ -->
    <!--      FILTERS       --> 
    <!-- ------------------ -->

    <div class="second-form "   
        [style.left]="storeInfo  ? '0' : '900px' "  
        *ngIf='!storeFilters'
        >

      <mat-icon class='action' (click)='back()'>arrow_back</mat-icon>

      <!-- ----------------- -->
      <!--    SILO FILTER    -->
      <!-- ----------------- -->

      <mat-form-field class='field' appearance='outline' >
        <mat-label>silo</mat-label>
        <mat-select [(ngModel)]="silo" name="silo" class='select-panel' required>
            <mat-option *ngFor="let silo of silos" [value]="silo.id" >
                {{silo.name}}
            </mat-option>
        </mat-select>
      </mat-form-field> 

      <mat-form-field class='field' appearance='outline' >
        <mat-label>Trip Event Trigger</mat-label>
        <mat-select [(ngModel)]="trip_event_trigger" name="trip_event_trigger" class='select-panel' required>
            <mat-option *ngFor="let trip_event_trigger of trip_event_triggers" [value]="trip_event_trigger" >
                {{trip_event_trigger}}
            </mat-option>
        </mat-select>
      </mat-form-field> 

      
      
      <div class="btn">
        <button mat-flat-button class='button' (click)='submitStoreFilters()' *ngIf='silo === 5'>next</button>      
        <button mat-flat-button class='button' (click)='submitStoreFilters()' *ngIf='silo !== 5'>confirm</button>      
      </div>

    </div>

    <!-- ------------------ -->
    <!--   IMAGE UPLOAD     --> 
    <!-- ------------------ -->

    <div class="third-form" [style.left]="storeFilters && silo === 5  ? '0' : '900px' " >

        <mat-icon class='action' (click)='backToFilters()'>arrow_back</mat-icon>

        <h3>Almost there! Please upload a banner image for this store</h3>
        <span class='recommended-size'  >*recommended image dimensions 1500 * 500</span>


        <div class='image'>
          <img class="preview-image" src='{{image}}'>
          <br>
          <button class="custom-file-upload" mat-button type="button" onclick="document.querySelector('#exampleInputFile').click()">Upload Image</button>
          <input type="file" id="exampleInputFile" (change)="imagePreview($event)" style="display: none;">
        </div>

        <div class="btn product-info-button">
          <button mat-flat-button class='button' (click)='submitStore()' disabled="{{imageFormat ? false : true}}">Save</button>
        </div>

    </div>

</div>