<div class="add-card"  >
  
    <div class="header">
      <h3>add product to stores</h3>
      <mat-icon (click)='closeDialog()'>clear</mat-icon>
    </div>

    <div class="select-all" *ngIf="store_amount == '' ">
        <mat-radio-group aria-label="Select an option" [(ngModel)]="store_amount" (change)="radioChange()">
            <mat-radio-button value="allstores">add to all stores of a specified silo</mat-radio-button>
            <mat-radio-button value="individualStores">add to specific stores</mat-radio-button>
          </mat-radio-group>  
    </div>

    <div class="back action"  (click)='StoreAmountChange()' *ngIf="store_amount !== '' ">
        <mat-icon>arrow_back</mat-icon>
        <p>choose another option</p>
    </div>

    <div *ngIf='!confirm'>

        <span *ngIf="store_amount !== '' ">

            <div class="top">
                <div class='filter' >
                    <mat-form-field class='filter-field'>
                    <mat-label>Filter silo</mat-label>
                    <mat-select  [(ngModel)]="silo_id">
                        <mat-option (click)='setSilo(silo)' *ngFor="let silo of silos" [value]="silo.id">
                        {{silo.name}}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                </div>

                <div class="search" *ngIf="store_amount == 'individualStores' ">
                    <mat-icon (click)='getStores()' matTooltip='search' class='search-icon' [style.left]="inputTouch ? '-20px' : '10px' ">search </mat-icon>
                    <mat-icon *ngIf='search' (click)='clearSearch()'  matTooltip='clear' class='clear-icon'>clear</mat-icon>
                    <input matInput (keydown.enter)='getStores()' [(ngModel)]="search" placeholder="Search"  #input (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
                </div>

            </div>

            <p *ngIf="store_amount == 'allstores' " class='all-stores-check'>Are you sure you want to add this product to all {{silo.name}} stores? </p>
            
            <table *ngIf="store_amount == 'individualStores' ">
                <thead>
                    <tr >
                        <th ></th>
                        <th ></th>
                        <th ></th>
                    </tr>
                </thead>
                <tbody>
                <tr class='action' *ngFor="let store of stores">
                    <th ><p>{{store.name}}</p></th>
                    <th ><p>{{store.physical_address}}</p></th>
                    <th ><p><mat-icon (click)='toggleSelection(store)'>add_circle_outlined</mat-icon> </p></th>
                </tr>
                </tbody>
            </table>

            <div class="add-list" *ngIf="store_amount == 'individualStores' ">
                <mat-chip-list aria-label="Fish selection">
                    <p *ngIf='storesArr.length > 0'>adding :</p>
                <mat-chip id='selectedCat' #c="matChip" id='chosen-category' [value]="store" *ngFor='let store of storesArr'  >{{store.name}}
                    <mat-icon matChipRemove (click)='toggleSelection(store)'>cancel</mat-icon>
                </mat-chip>
                </mat-chip-list>
            </div>
            
                <div class="btn" *ngIf="store_amount == 'individualStores' ">
                    <div (click)='start()' matTooltip="Skip to start" class='action' *ngIf='page > 1'>back to start</div>
                    <p (click)='previous()' class='action'>previous</p>
                    <div>{{page}}</div>
                      <p (click)='next()' class='action'>next</p>
                      <div> </div>
                    <button mat-flat-button class='button ' (click)='confirmPromo()' [disabled]="storesArr.length > 0 ? 'false' : 'true'">next</button>
                </div>
                <div class="btn" *ngIf="store_amount == 'allstores' ">
                    <button mat-flat-button class='button confirm-bottom' (click)='confirmPromo()'  [disabled]="storesArr.length > 0 ? 'false' : 'true'">next</button>
                </div>

        </span>
    </div>

    <form #promo = 'ngForm' (ngSubmit)='addStores(promo.value)' *ngIf='confirm' >

        <h3 class='confirm'>Confirm promo price and quantity</h3>

        <div class="center promo-fields">
            <mat-form-field appearance="outline">
                <mat-label>stock amount</mat-label>
                <input matInput placeholder="50" [(ngModel)]="qty" name='qty' type='number' required >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label *ngIf='!promoProduct'> product price</mat-label>
                <mat-label *ngIf='promoProduct'> original price</mat-label>
                <input matInput placeholder="99.99" [(ngModel)]="price" name='price' type='number' required>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf='promoProduct'>
                <mat-label>promotional price</mat-label>
                <input matInput placeholder="89.99" [(ngModel)]="promo_price" name='promo_price' type='number' required>
            </mat-form-field>

            <p class='checkbox'><mat-checkbox [(ngModel)]="promoProduct">This is a promotional product</mat-checkbox></p>
        </div>

        <div class="btn">
            <button mat-flat-button class='button confirm-bottom' type='submit'>Confirm</button>
        </div>
    </form>


</div>



  

