<div class="back action" [routerLink]="'/users/' + user_id">
  <mat-icon>arrow_back</mat-icon>
  <p>drivers</p>
</div> 
<div class="create-card">
  <div class="header">
    <h3>Create new Bank for driver</h3>
    <mat-icon [routerLink]="'/users/' + user_id">close</mat-icon>
  </div>
  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="firstForm">
      <form [formGroup]="firstForm">
        <mat-label>Payout type</mat-label>
        <mat-form-field appearance="outline" class="field">
          <mat-select name="payout_types" class="select-panel" placeholder="Select type"
            (selectionChange)="handlePayoutTypeChange($event.source.value)" required>
            <mat-option *ngFor="let type of payout_types" [value]="type">
              <p *ngIf="type">{{ type }}</p>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- conditionally show bank account setup -->
        <div *ngIf="displayBankSetup">


          <mat-label>Bank name </mat-label>
          <mat-form-field appearance="outline" class="field">
            <mat-select [(ngModel)]="bank" name="bank_name" class="select-panel" placeholder="Select bank"
              (selectionChange)="autofillBranchCode(bank)" formControlName="bank_name" required>
              <mat-option *ngFor="let bank of banks.banks" [value]="bank">
                <p *ngIf="bank">{{ bank.name }}</p>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-label> Branch Code </mat-label>
          <mat-form-field appearance="outline" class="field">
            <input type="text" matInput [(ngModel)]="branch_code" placeholder="Select bank to fill in branch code"
              name="branch_code" readonly="{{ other ? false : true }}" formControlName="branch_code" />
          </mat-form-field>
          <mat-label> Account Name </mat-label>
          <mat-form-field appearance="outline" class="field">
            <input type="text" matInput ngModel placeholder="Enter name of account" name="account_name"
              formControlName="account_name" required autocomplete="off" />
          </mat-form-field>
          <mat-label> Account Number </mat-label>
          <mat-form-field appearance="outline" class="field">
            <input type="text" inputmode="numeric" [pattern]="pattern" matInput ngModel
              [placeholder]="accNumberPlaceholder" name="account_number" formControlName="account_number" required
              autocomplete="off" />
          </mat-form-field>

          <div *ngIf="number.errors!=null  && number.value!='' " class="account-err">
            {{accNumberPlaceholder}}
          </div>

          <br />
          <br />
          <div class="next">
            <button [disabled]="firstForm.status == 'INVALID' ? true :false" mat-flat-button class="button"
              matStepperNext (click)="submitDriverBankInfo()">
              NEXT
            </button>
          </div>

        </div>


        <!-- conditionally show ewallet account setup -->
        <div *ngIf="displayEwalletSetup">

          <mat-label> Account Name </mat-label>
          <mat-form-field appearance="outline" class="field">
            <input type="text" matInput ngModel placeholder="Enter name of account" name="account_name"
              formControlName="account_name" required autocomplete="off" />
          </mat-form-field>
          <mat-label> Mobile Number </mat-label>
          <mat-form-field appearance="outline" class="field">
            <input type="text" inputmode="numeric" [pattern]="pattern" matInput ngModel
              [placeholder]="accNumberPlaceholder" name="account_number" formControlName="account_number" required
              autocomplete="off" />
        </mat-form-field>

        <div *ngIf="number.errors!=null  && number.value!='' " class="account-err">
          {{accNumberPlaceholder}}
        </div>
 
        <br />
        <br />
        <div class="next">
          <button
            [disabled]="firstForm.status == 'INVALID' ? true :false"
            mat-flat-button
            class="button"
            matStepperNext
            (click)="submitDriverBankInfo()"
          >
            NEXT
          </button>
        </div>

        </div>


      </form>
    </mat-step>
    <mat-step>
      <div class="driver-overview">
        <h5>Review driver bank details</h5>

        <p *ngIf="driver">
          <span>PAYOUT TYPE</span>
          <span class="confirm-detail">{{ driver.account_type }}</span>
        </p>
        <p *ngIf="driver">
          <span>BANK NAME</span>
          <span class="confirm-detail">{{ driver.bank_name }}</span>
        </p>

        <p *ngIf="driver">
          <span>BRANCH CODE</span>
          <span class="confirm-detail">{{ driver.branch_code }}</span>
        </p>

        <p *ngIf="driver">
          <span>ACCOUNT NAME</span>
          <span class="confirm-detail">{{ driver.account_name }}</span>
        </p>

        <p *ngIf="driver">
          <span>ACCOUNT NUMBER</span>
          <span class="confirm-detail">{{ driver.account_number }}</span>
        </p>
      </div>

      <div>
        <div class="back save">
          <button mat-flat-button matStepperPrevious>Back</button>
        </div>
        <div class="save">
          <button
            mat-flat-button
            class="button"
            matStepperNext
            (click)="createDriverBank()"
          >
            ADD BANK ACCOUNT
          </button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
