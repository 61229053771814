<div class="back action" routerLink="/categories">
  <mat-icon>arrow_back</mat-icon>
  <p>categories</p>
</div>

<div class="create-card" >

  <div class="header">
    <h3>create new Category</h3>
    <mat-icon routerLink="/categories">clear</mat-icon>
  </div>

    <!-- ---------------------- -->
    <!--  COLLECT PRODUCT INFO  --> 
    <!-- ---------------------- -->

  <div class="first-form" [style.left]="categoryInfo  ? '-900px' : '0' " >

    <form #category = 'ngForm' (ngSubmit)='submitcategoryInfo(category.value)'>
      
      <mat-form-field class='field'  appearance='outline'>
        <mat-label>category name</mat-label>
        <input type="text" matInput ngModel placeholder="wine & dine" name='name' required >
      </mat-form-field>

      <!-- ----------------- -->
      <!--    SILO FILTER    -->
      <!-- ----------------- -->

      <mat-form-field  appearance='outline' >
        <mat-label>silo</mat-label>
        <mat-select [(ngModel)]="silo" name="silo" class='select-panel' required>
            <mat-option *ngFor="let silo of silos" [value]="silo.id" >
                {{silo.name}}
            </mat-option>
        </mat-select>
      </mat-form-field> 


      <div class="btn">
        <button mat-flat-button class='button' type='submit'>next</button>      
      </div>

    </form>
  </div>

    <!-- ------------------ -->
    <!--     ADD IMAGE      --> 
    <!-- ------------------ -->

  <div class="second-form" [style.left]="categoryInfo  ? '0' : '900px' " >

      <mat-icon class='action' (click)='backToCategoryInfo()'>arrow_back</mat-icon>

      <h3>Almost there! Please upload an Icon for this category</h3>

      <div class='image'>
        <img class="preview-image" [src]="imageFile ? imageFile.resource : '../assets/placeholders/logo-placeholder.png'">
        <br>
        <button class="custom-file-upload" mat-button type="button" onclick="document.querySelector('#exampleInputFile').click()">Upload Image</button>
        <input type="file" id="exampleInputFile" (change)="imagesPreview($event)" style="display: none;">
      </div>

      <div class="btn product-info-button">
        <button mat-flat-button class='button' (click)='submitCategory()' disabled="{{imageAttempt ? false : true}}">Save</button>
      </div>

  </div>


</div>
