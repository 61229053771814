import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-store-categories',
  templateUrl: './store-categories.component.html',
  styleUrls: ['./store-categories.component.scss']
})
export class StoreCategoriesComponent implements OnInit {
  Columns = ['icon', 'name', 'add'];

  
  id
  page = 1
  limit = 10
  categories
  search = ''
  retries = 0
  add = false
  allCategories
  

  constructor(public api: ApiService,
              public route: ActivatedRoute,
              public message: MessagesService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getCategories()
    localStorage.setItem('storeTab', 'categories');
  }

  toggleAdd(){
    this.add = !this.add;
    if(this.add){
      this.getAllCategories();
    } else {
      this.getCategories();
    }
    this.page = 1;
  }

  getCategories(){
    this.api.getList("catelog","stores/"+this.id+`/categories?page=${this.page}&per_page=${this.limit}`).subscribe((data:any)=>{
      this.categories=data;
    }, error => {
      this.message.showSnack(error.error)
      if(this.retries < 3){
        if(error.status !== 401){
          this.retries++
          this.getCategories()
        }
      }
    })
  }

  getAllCategories(){
    this.api.getList('catelog', `app/categories?page=${this.page}&per_page=${this.limit}`).subscribe((categories: any) => {
      this.allCategories = categories;
    })
  }
  
  searchCategories(search) {
    if(search == ''){
      this.page = 1;
      this.getAllCategories();
    }
    this.search = search;
    this.api
      .getList(
        'catelog',
        `categories?page=${this.page}&per_page=${this.limit}&search=${this.search}`
      )
      .subscribe((categories: any) => {
        this.allCategories = categories;
      });
  }

  addStoreCategory(cat){
     let category = [{
       category_id: cat.id,
       store_id: JSON.parse(this.id)
     }]

    this.api.post('catelog', `storecategories`, category).subscribe((data: any) => {
      console.log(data);
      this.message.showSnack('successfully added category to your store')
    }, error => {
      this.message.showSnack(error.error)
    })
  }

  removeStoreCategory(cat){
    this.api.delete('catelog', `storecategories/${cat.store_category_id}`).subscribe((category: any) => {
      this.message.showSnack('Successfully removed category from store')
      this.getCategories()
    }, error => {
      this.message.showSnack(error.error)
    })
  }


  setLimit(limit){
    this.limit = limit 
    if(this.add){
      this.getAllCategories();
    } else {
    this.getCategories();
    }
  }

  next(){
    this.page++
    if(this.add){
      this.getAllCategories();
    } else {
    this.getCategories();
    }
  }

  previous(){
    if(this.page > 1){
      this.page--
      if(this.add){
        this.getAllCategories()
      } else {
      this.getCategories()
      }
    }
  }



}
