import { Component, OnInit } from '@angular/core'

import { PagerService } from '../../services/pager.service'
import { Router } from '@angular/router'
import { AuthService } from '../../services/auth.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email=''
  password=''
  showPassword = false
  resetPassword = false

  constructor(
    public auth:AuthService,
    private router:Router,
    private pager:PagerService
  ) { }

  ngOnInit(): void {
  }

  toggleShowPassword(){
    this.showPassword = !this.showPassword
  }

  login(){
    this.auth.login(this.email,this.password)
  }

  forgotPassword(){
    this.resetPassword = true
  }

}
