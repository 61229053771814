import { Injectable } from '@angular/core';
import { MiscService } from '../misc/misc.service';

@Injectable({
  providedIn: 'root',
})
export class LoopBranchMapService {
  branchmapsList;
  limit = 20;
  page = 1;
  search = "";
  constructor(public misc: MiscService) {}

  setLimit(limit){
    this.limit = limit
  }
  next(){
    if(this.branchmapsList.length >= this.limit){
    this.page++
    this.getLoopBranchMaps();
    }
  }
 resetSearch(){
  this.limit = 20
  this.page = 1
  this.search = ''
 }
  previous(){
    if(this.page > 1){
      this.page--
      this.getLoopBranchMaps();
    }
  }
  async createLoopBranchMap(obj){
    return await this.misc.apiPost(`godrive`, `admin/branchmaps`, obj)
  }
  async getLoopBranchMapbyId(obj) {
    await this.misc
      .apiPost('godrive', `admin/branchmap`, obj)
      .then((res: any) => {
        // console.log(res)
        return res;
      });
  }

  async getLoopBranchMaps(){
    console.log("getting API call")
    await this.misc.apiCall(`godrive`, `admin/branchmaps?page=${this.page}&per_page=${this.limit}&search=${this.search}`).then((res: any) => {
      console.log(res)
      this.branchmapsList = res["branchmaps"]
    })
  }

  updateLoopBranchMap(obj,id){
    this.misc.apiPatch('godrive', `admin/branchmaps/${id}`, obj).then((res: any) => {
      return res
    })
  }
  async deleteLoopbranchMap(id){
    this.misc.apiDelete(`godrive`, `admin/branchmaps/${id}`).then((data: any) => {
      return data
    })
  }
}
