import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MessagesService } from 'src/app/services/messages.service';
import { ZonesService } from 'src/app/services/zones/zones.service';


@Component({
  selector: 'app-zones-list',
  templateUrl: './zones-list.component.html',
  styleUrls: ['./zones-list.component.scss']
})

export class ZonesListComponent implements OnInit {
  noZones = false;
  displayedColumns = ['city', 'area', 'updated_by', 'updated_at', 'actions'];
  
  constructor(
    public zonesService: ZonesService,
    private messageService: MessagesService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getZones();
  }

  async getZones(){
    
    await this.zonesService.getZones()
    .then((res:any) => {
      let zones = res?.zones
      if(zones != []){
        this.noZones = false;
        this.zonesService.zones = res.zones;
        this.zonesService.loading = false;
        // console.log(this.zonesService.zones)
      }
      if(zones == null){
        this.noZones = true;
      }
    })
    .catch((err:any) => {
      console.log(err)
    })

  }

  deleteZone(zoneId:any){
    this.zonesService.deleteZone(zoneId)
    .then((res:any) => {
      console.log(res)
      this.messageService.showSnack('Zone deleted.')
      this.getZones();
    })
    .catch((err:any) => {

    })
  }

  navZone(zone:any){
    
    console.log(zone)

    let zoneId = zone.id
    localStorage.setItem('activeZone', JSON.stringify(zone))
    location.replace(`zone/${zoneId}`)

  }

  addPolygon(){
    this.router.navigate(['new-zone'])
  }
  
}
