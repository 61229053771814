import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { PusherService } from 'src/app/services/pusher.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagesService } from 'src/app/services/messages.service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cart-overview',
  templateUrl: './cart-overview.component.html',
  styleUrls: ['./cart-overview.component.scss']
})
export class CartOverviewComponent implements OnInit {

  status = [
    {
      display_name: 'CANCEL ORDER',
      api_call: "CANCELLED",
      info: 'cancel customer order'
    },
    {
      display_name: 'FAIL PAYMENT',
      api_call: "FAILED",
      info: 'fail customer payment if customer was unable to pay'
    }
  ]

  statuses              = []
  // Columns               = ['name', 'price', 'quantity', 'picked', 'oos', 'time']
  // Actions               = ['action', 'item',  'name']
  nucleusTracking       = ['action']

  id
  cart
  items
  origin
  picker
  search
  actions
  requests

  user

  picker_uid
  destination
  // nucleusActions
  statusoverride

  market_cart         = false
  paymentItems        = []
  // productItems        = []
  // pickedItems         = []
  // unfulfilledItems    = []
  // out_of_stock_items  = []




  page          = 1
  slips         = []
  limit         = 8
  products      = []
  retries       = 0
  no_name       = false
  replacing     = false
  removePicker  = false

  resource      = `admin/carts`
  isDisChemUser = false

  // progressValue = 0

  pdfTable: any

  constructor(public api: ApiService,
              public message: MessagesService,
              public route: ActivatedRoute,
              public pusher: PusherService,
              public dialog: MatDialog,
              public router: Router,
              public auth: AuthService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')

    if(this.auth.dis_chem_role || localStorage.getItem('dis-chem-role') && localStorage.getItem('dis-chem-role') == 'true'){
      this.isDisChemUser = true
      this.resource = `dischem/carts`
    }

      // this.getCart()
  }

  getCart(){
    // console.log(this.resource)

    this.api.get('fulfilment', this.resource, this.id).subscribe((cart: any) => {

      this.cart = cart.cart

    })
  }

}
