<!-- <div class="search">
    <input matInput  #input>
</div> -->

<div class='stores'>
  
      <table mat-table [dataSource]='cards' matSort>
  
        <ng-container matColumnDef="brand">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
          <td mat-cell *matCellDef="let card" class='pointer'> {{card.brand}}  </td>
        </ng-container>
  
        <ng-container matColumnDef="holder">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let card" class='pointer' > {{card.holder}} </td>
        </ng-container>
  
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Number</th>
          <td mat-cell *matCellDef="let card"> {{card.number}} </td>
        </ng-container>

        <ng-container matColumnDef="default_card">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Default</th>
          <td mat-cell *matCellDef="let card"><mat-icon *ngIf='card.default_card'>checked</mat-icon></td>
        </ng-container>

        <ng-container matColumnDef="expiry">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Expires</th>
            <td mat-cell *matCellDef="let card">{{card.expiry_month}}/{{card.expiry_year}}  </td>
        </ng-container>

        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
            <td mat-cell *matCellDef="let card"> {{card.CreatedAt}} </td>
        </ng-container>

        <ng-container matColumnDef="valid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Secured</th>
            <td mat-cell *matCellDef="let card" > <mat-icon *ngIf='card.secured; else unsecure'>verified_user</mat-icon>
              <ng-template #unsecure><mat-icon>gpp_bad</mat-icon></ng-template> 
            </td>
        </ng-container>
    
  
        <tr mat-header-row *matHeaderRowDef="Columns"></tr>
        <tr mat-row *matRowDef="let card; columns: Columns;"></tr>
      </table>

      <div id="pager" >
        <div class="page"> 
      
        <div> Per page:</div>
        <div (click)="setLimit(10)" class="limit" >10</div>
        <div (click)="setLimit(15)" class="limit" >15</div>
        <div (click)="setLimit(20)" class="limit" >20</div>
        <div (click)="setLimit(50)" class="limit" >50</div>
        <div>Page:</div>
        <mat-icon (click)='previous()'>skip_previous</mat-icon>
        <div>{{page}}</div>
          <mat-icon (click)='next()'>skip_next</mat-icon>
          <div> </div>
        </div>
      </div>

</div>