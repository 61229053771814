import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MiscService } from '../misc/misc.service';

@Injectable({
  providedIn: 'root',
})
export class EarningsService {
  earning_types;
  earnings

  constructor(public misc: MiscService, public router: Router) {}

  async getEarningTypes() {
    await this.misc
      .apiCall(`payments`, `admin/earning_types`)
      .then((res: any) => {
        // console.log(res)
        this.earning_types = res.earning_types;
        // console.log(this.earning_types);
      });
  }

  async createEarningTypes(obj) {
    await this.misc
      .apiPost('payments', `admin/earning_types`, obj)
      .then((res: any) => {
        // console.log(res)
        return;
      });
  }

  async getEarnings(page, limit, search){
    await this.misc.apiCall(`payments`, `admin/earnings?page=${page}&per_page=${limit}&search=${search}`).then((res: any) => {
      // console.log(res)
      this.earnings = res.earnings
    })
  }

  async createEarning(obj){
    await this.misc.apiPost(`payments`, `admin/earnings`, obj).then((res: any) => {
      this.router.navigate(['/earnings'])
      return
    })
  }
}
