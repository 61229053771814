<div class="back action" routerLink="/partners">
  <mat-icon>arrow_back</mat-icon>
  <p>partners</p>
</div>

<div class="create-card">
  <div class="header">
    <h3>Create new Partner</h3>
    <mat-icon routerLink="/partners">clear</mat-icon>
  </div>
  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="firstForm">
      <form [formGroup]="firstForm">
        <mat-form-field appearance="outline" class="field">
          <mat-label>partner name</mat-label>
          <input type="text" matInput ngModel placeholder="John Smith" name="name" required formControlName="name" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="field">
          <mat-label>partner callback_url</mat-label>
          <input type="text" matInput ngModel placeholder="https://calbackurl.com" name="callback_url" required
            formControlName="callback_url" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="field">
          <mat-label>partner max_deliver_distance</mat-label>
          <input type="Number" matInput ngModel placeholder="3287" name="max_delivery_distance" required
            formControlName="max_delivery_distance" minlength="1" maxlength="5" />
        </mat-form-field>
        <button mat-flat-button class="button" matStepperNext (click)="submitPartnerInfo()" (click)="submitPartner()">
          Create
        </button>
      </form>
    </mat-step>
  </mat-horizontal-stepper>

</div>
