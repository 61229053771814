<div class="dashboard">

  <div class="charts">
    <div class="totals" id='bottom-stats' *ngFor='let stat of quickStats'>
      <div class="total">
        <img src={{stat.image}} alt="">
        <span>
          <h2 *ngIf="stat.tag !== 'time'">{{stat.data}} 
            <span *ngIf="stat.tag == 'conversion'">%</span>
          </h2>
          <h2 *ngIf="stat.tag == 'time'">{{stat.data.substring(0,8)}}</h2>
          <p>{{stat.name}}</p>
        </span>
      </div>
    </div>
  </div>
  
  

    <td class="charts" (window:resize)="onResize($event)">
      
        <div class="chart">
          <h3>quench orders over past 7 days
            <mat-icon class='up'   *ngIf='currentDayOrdersIsUp && lastWeekOrders.length > 0 '>trending_up</mat-icon>
            <mat-icon class='down' *ngIf='!currentDayOrdersIsUp && lastWeekOrders.length > 0'>trending_down</mat-icon>
          </h3>
          <span *ngIf='lastWeekOrders.length > 0; else noOrders'>
            <google-chart #chart *ngIf="lastWeekOrders.length > 0"
                          [type]="'LineChart'"
                          [data]="lastWeekOrders"
                          [options]="quench"
                          [width]="width"
                          [height]="height"
                                          >
            </google-chart>
          </span>
          <ng-template #noOrders>
            <ngx-spinner
            type = 'ball-pulse'
            [fullScreen]='false'
            bdColor=''
            color='var(--quenchPink)'
            size='small'
            ></ngx-spinner>
          </ng-template>
        </div>

        <div class="chart">
          <h3>quench orders over three months
            <mat-icon class='up'   *ngIf='currentMonthOrdersIsUp && threeMonthsOrders.length > 0 '>trending_up</mat-icon>
            <mat-icon class='down' *ngIf='!currentMonthOrdersIsUp && threeMonthsOrders.length > 0'>trending_down</mat-icon>
          </h3>
          <span *ngIf='threeMonthsOrders.length > 0; else noMonths'>
            <google-chart #chart *ngIf="threeMonthsOrders.length > 0"
                          [type]="'ColumnChart'"
                          [data]="threeMonthsOrders"
                          [options]="quench"
                          [width]="width"
                          [height]="height"
                                          >
            </google-chart>
          </span>
          <ng-template #noMonths>
            <ngx-spinner
            type = 'ball-pulse'
            [fullScreen]='false'
            bdColor=''
            color='var(--quenchPink)'
            size='small'
            ></ngx-spinner>
          </ng-template>
        </div>
    
      </td>

      <td class="charts" (window:resize)="onResize($event)">
      
        <div class="chart">
          <h3>quench sign ups over past 7 days
            <mat-icon class='up'   *ngIf='currentDaySignUpsIsUp && lastWeekSignUps.length > 0 '>trending_up</mat-icon>
            <mat-icon class='down' *ngIf='!currentDaySignUpsIsUp && lastWeekSignUps.length > 0'>trending_down</mat-icon>
          </h3>
          <span *ngIf='lastWeekSignUps.length > 0; else noSignUps'>
            <google-chart #chart *ngIf="lastWeekSignUps.length > 0"
                          [type]="'LineChart'"
                          [data]="lastWeekSignUps"
                          [options]="quench"
                          [width]="width"
                          [height]="height"
                                          >
            </google-chart>
          </span>
          <ng-template #noSignUps>
            <ngx-spinner
            type = 'ball-pulse'
            [fullScreen]='false'
            bdColor=''
            color='var(--quenchPink)'
            size='small'
            ></ngx-spinner>
          </ng-template>
        </div>
        <div class="chart">
          <h3>quench sign up over three months
            <mat-icon class='up'   *ngIf='currentMontlySignUpIsUp && threeMonthSignUps.length > 0 '>trending_up</mat-icon>
            <mat-icon class='down' *ngIf='!currentMontlySignUpIsUp && threeMonthSignUps.length > 0'>trending_down</mat-icon>
          </h3>
          <span *ngIf='threeMonthSignUps.length > 0; else noMonthSignUps'>
            <google-chart #chart *ngIf="threeMonthSignUps.length > 0"
                          [type]="'ColumnChart'"
                          [data]="threeMonthSignUps"
                          [options]="quench"
                          [width]="width"
                          [height]="height"
                                          >
            </google-chart>
          </span>
          <ng-template #noMonthSignUps>
            <ngx-spinner
            type = 'ball-pulse'
            [fullScreen]='false'
            bdColor=''
            color='var(--quenchPink)'
            size='small'
            ></ngx-spinner>
          </ng-template>
        </div>
    
      </td>
</div>

