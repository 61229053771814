import { Component, OnInit } from '@angular/core';
import { AccountsService } from 'src/app/services/accounts/accounts.service';

@Component({
  selector: 'app-list-user-accounts',
  templateUrl: './list-user-accounts.component.html',
  styleUrls: ['./list-user-accounts.component.scss']
})
export class ListUserAccountsComponent implements OnInit {
  Columns = [
    'user',
    'mobile',
    'email',
    'status',
    'verified',
  ];
  page=1;
  limit=10;
  constructor(public accounts: AccountsService) { }

  ngOnInit(): void {
    this.getUserAccouts()
  }
  getUserAccouts(){
    this.accounts.getUserAccounts(this.page)
  }
  setLimit(i){
    this.limit=i
  }
  previous(){
    if(this.page < 2){
      return
    }
    this.page--
    this.getUserAccouts()

  }
  next(){
    this.page++;
    this.getUserAccouts()

  }

}
