import { ApiService } from './../../../../services/api.service';
import { MessagesService } from 'src/app/services/messages.service';
import { Component, OnInit } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  id;
userroles = []
isDriver = false
  tabs = [
    {name: 'details'},
    {name: 'transactions'},
    {name: 'addresses'},
    {name: 'cards'},
    {name: 'orders'},
    {name: 'payments'},
    // {name: 'favourites'},
  ]


  navigation_crumb

  selectedTab = 'details'

  selectTab(tab){
    this.selectedTab =tab.name
    localStorage.setItem('userTab', tab.name);

  }

  constructor(
    public messages: MessagesService,
    public route: ActivatedRoute,
    public api: ApiService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getUser();
    if(localStorage.getItem('userTab')){
      this.selectedTab = localStorage.getItem('userTab');
    }

    this.navigation_crumb = localStorage.getItem('customer_navigation')
  }

  ngOnDestroy() {
    this.messages.directory = '';
    // localStorage.removeItem('userTab')
  }

  getUser(){
    this.api.get("auth","admin/users",this.id).subscribe((data:any)=>{
   if(data.userroles != []){
     data.userroles.forEach(user =>{
       this.userroles.push(user.name)
     })
     this.userroles.forEach(role =>{
       if(role == 'Driver'){
        this.isDriver = true
       }
     })
   }
      let user_name = `${data.user.first_name} ${data.user.last_name}`
      this.messages.directory = user_name
    },error=>{
      console.log(JSON.stringify(error));
    });
  }

}
