<div class="header">
    <h3>Update Payment method</h3>
    <mat-icon (click)="closeDialog()" class="action">clear</mat-icon>
  </div>
  <hr />
  
  <form #payment = 'ngForm' (ngSubmit)='updatePaymentMethod(payment.value)'>
    <mat-form-field appearance="outline" class="field">
      <mat-label>Payment name</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="name"
        placeholder="EFT"
        name="name"
        required
      />
    </mat-form-field>

  
    <mat-form-field appearance="outline" class="field">
      <mat-label>Payment description</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="description"
        placeholder="for electronic transfers"
        name="description"
      />
    </mat-form-field>
  
  
    <div class="save">
      <button mat-flat-button class="button">submit</button>
    </div>
  </form>
  