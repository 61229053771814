import { Component, OnInit } from '@angular/core'
import { ApiService } from 'src/app/services/api.service'
import { AuthService } from 'src/app/services/auth.service'
import { MessagesService } from 'src/app/services/messages.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  submenus = [
    {
      name: 'daily/weekly', 
      icon: 'bar_chart'
    }, 
  {
    name: 'overview', 
    icon: 'analytics'
  }, 
  {
  name: 'sales',
  icon: 'trending_up'
  },
  {
  name: 'market',
  icon: 'store'
  },
  // {
  // name: 'orders',
  // icon: 'local_shipping'
  // },
  // {
  // name: 'tracking',
  // icon: 'leaderboard'
  // },
  // {
  // name: 'customers',
  // icon: 'people'
  // }
]

selected_sub_menu = 'overview'

navigateDashboard(submenu){
  this.selected_sub_menu = submenu.name
  localStorage.setItem('dashboard_menu', submenu.name)
}

  constructor(private api: ApiService,
              private message: MessagesService,
              public auth: AuthService) {}

  ngOnInit() {
    if(localStorage.getItem('dashboard_menu')){
      this.selected_sub_menu = localStorage.getItem('dashboard_menu')
    }
  }




}
