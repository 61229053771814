import { ActivatedRoute } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { ApiService } from 'src/app/services/api.service'
import { MessagesService } from 'src/app/services/messages.service'

@Component({
  selector: 'app-retailer-admins',
  templateUrl: './retailer-admins.component.html',
  styleUrls: ['./retailer-admins.component.scss']
})
export class RetailerAdminsComponent implements OnInit {
  Columns = ['name', 'email', 'phone', 'add']


  inputTouch = false

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }

  id
  search = ''
  users

  page    = 1
  limit   = 10
  admins  = []

  add = false 


  constructor(public api: ApiService,
              public route: ActivatedRoute,
              public message: MessagesService
              ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getAdmins()
    this.getUsers(this.search)
  }

  getAdmins(){
    this.api.getList('catelog', `retailerusers/${this.id}/retailer`).subscribe((admins: any) => {
      // console.log(admins)
      this.admins = admins
      // admins.forEach(item => {
      //   this.api.get('auth', `admin/users`, item.uid).subscribe((user: any) => {
      //     this.admins.push(user)
      //   })
      // })
    })
  }

  getUsers(e){
    this.api.getList('auth', `admin/users?page=${this.page}&per_page=${this.limit}&search=${this.search}`).subscribe((users: any) => {
      this.users = users.users
    }, error => {
      this.message.showSnack(error.error)
    })
  }

  addUserToggle(){
    this.add = !this.add
  }

  addUser(i){
    let user = [
      {
      retailer_id: JSON.parse(this.id),
      role: 'retailer',
      uid: i.ID,
      name: `${i.first_name} ${i.last_name}`,
      email: i.email,
      phone: i.phone
    }
  ]

    this.api.post(`catelog`, `retailerusers`, user).subscribe((data: any) => {
      this.message.showSnack(`Succesfully added ${i.first_name} ${i.last_name} as a retailer user`)
      this.addUserToggle()
      this.getAdmins()
    })
  }

  removeAdmin(i){
    this.api.delete(`catelog`, `retailerusers/${i.id}`).subscribe((data: any) => {
      console.log(data)
      this.message.showSnack(`Succesfully removed user from retailer`)
      this.getAdmins()
    })
  }

  setLimit(limit){
    this.limit = limit
    this.getUsers(this.search)
  }

  next(){
    this.page++ 
    this.getUsers(this.search)
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.getUsers(this.search)
    }
  }

  clearSearch(){
    this.search = ''
    this.getUsers(this.search)
  }
}
