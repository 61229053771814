<table mat-table [dataSource]="accounts.accounts_earnings" matSort>
  
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let earning">
          <p *ngIf="earning.date">{{ earning.date.substring(0,10) }}</p>
        </td>
      </ng-container>
    
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
      <td mat-cell *matCellDef="let earning">
        <p *ngIf="earning.amount">{{ earning.amount }}</p>
        <p *ngIf="!earning.amount" class="empty">Amount not specified</p>
      </td>
    </ng-container>


    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let earning">
        <p *ngIf="earning.description">{{ earning.description }}</p>
        <p *ngIf="!earning.description" class="empty">Description not specified</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="distance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Distance</th>
      <td mat-cell *matCellDef="let earning">
        <p *ngIf="earning.distance">{{ earning.distance }}</p>
        <p *ngIf="!earning.distance" class="empty">Distance not specified</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="earning_type_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Earning type ID</th>
      <td mat-cell *matCellDef="let earning">
        <p *ngIf="earning.earning_type_id">{{ earning.earning_type_id }}</p>
        <p *ngIf="!earning.earning_type_id" class="empty">Earning type ID not specified</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let earning">
        <p *ngIf="earning.status">{{ earning.status }}</p>
        <p *ngIf="!earning.status" class="empty">Status not specified</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="order_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Order ID</th>
        <td mat-cell *matCellDef="let earning">
          <p *ngIf="earning.order_id">{{ earning.order_id }}</p>
          <p *ngIf="!earning.order_id" class="empty">Order ID not specified</p>
        </td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row class="action" *matRowDef="let earning; columns: Columns"></tr>
  </table>
  
