    <!-- ------------------ -->
    <!--  RECREATE INVOICE  --> 
    <!-- ------------------ -->
<div id="invoice-preview" >

  <!-- <mat-icon (click)='createInvoiceDialog()'>close</mat-icon> -->
    
          <div id="invoice">
  
  
              <div class="invoice-header">
                  <div class="company-info">
                      <p>Quench Delivery PTY LTD</p>
                      <p>Rouxcor House</p>
                      <p>37 Mark Street</p>
                      <p>Stellenbosch</p>
                      <p>7600</p>
                      <p>Vat No: 4570282907</p>
                      <br>
                      <p>INV: #108847</p>
                  </div>
                  <div class="logo">
                      <img src="../../../assets/images/quench-pink.png" alt="">
                  </div>
              </div>
  
              <hr>
  
              <h2>Thank you for your order {{cart.customer_name}}.</h2>
              <h3>Order: {{cart.order_number}}</h3>
              <h3>Date: {{cart.order_date}}</h3>
              
  
              <table class="table">
  
                  <thead>
                  <tr>
                      <th  >DESCRIPTION</th>
                      <th  >QTY</th>
                      <th  >Price</th>
                      <th id='total'>Total</th>
                  </tr>
                  </thead>
                  <tbody >
                  <tr  *ngFor="let item of items" >
                      <th ><p>{{item.name}}</p></th>
                      <th ><p>{{item.quantity}}</p></th>
                      <th ><p>R {{ item.price | number:"1.2-2" }}</p></th>
                      <th id='total'><p>R {{item.price*item.quantity | number:"1.2-2" }}</p></th>
                  </tr>
                  </tbody>
              </table>
  
              <div class="total">
                  <h3>Order Total</h3>
                  <p>R {{cart.amount | number:"1.2-2" }}</p>
              </div>
          </div>
  
          <div class="btn">
              <!-- <button mat-flat-button class='button' (click)='invoiceResend()'>Send Invoice</button> -->
              <button mat-flat-button class='button' (click)="exportAsPDF('invoice')">Download as PDF</button>
          </div>
</div>