import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReasonsService } from 'src/app/services/reasons/reasons.service';

@Component({
  selector: 'app-create-reason',
  templateUrl: './create-reason.component.html',
  styleUrls: ['./create-reason.component.scss']
})
export class CreateReasonComponent implements OnInit {

  constructor(public reasons: ReasonsService, public router: Router) { }

  ngOnInit(): void {}

  firstForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('',),
  });

  async submitReason(){
    await this.reasons.createReason(this.firstForm.value)

    this.router.navigate(['/reasons'])
  }

}
