import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountsService } from 'src/app/services/accounts/accounts.service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {

  Columns = [
    'asset_id',
    'asset_name',
    'start_date',
    'end_date',
    'amount',
    'frequency',
    'no_of_payments',
    'status'

  ]

  constructor(public route: ActivatedRoute, public accounts: AccountsService) { }

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id')
    this.accounts.getUserAccountAssets(id)
  }

}
