import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MiscService } from '../misc/misc.service';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  assets = []
  asset_types

  constructor(private misc: MiscService, public router: Router) {}

  async getAssets(page, limit, search) {
    await this.misc.apiCall(`payments`, `admin/assets?page=${page}&per_page=${limit}&search=${search}`).then((res: any) => {
      this.assets = res.assets;
      // console.log(this.assets);
    });
  }


  async CreateAsset(obj) {
    await this.misc.apiPost("payments", `admin/assets`, obj).then((res: any) => {
      this.router.navigate(['/assets'])
      // console.log(res)
      return
    })
  }

  async createAssetType(obj){
    await this.misc.apiPost("payments", `admin/asset_types`, obj).then((res: any) => {
      // console.log(res)
      return
    })
  }

  getAssetTypes(){
    this.misc.apiCall('payments', 'admin/asset_types?per_page=1000').then((res) => {
      this.asset_types = res['asset_types']
      // console.log(this.asset_types)
    })
  }

  removeAsset(id){
    this.misc.apiDelete('payments', `admin/assets/${id}`).then((res: any) => {
      return
    })
  }

  updateAsset(id, obj){
    this.misc.apiPatch('payments', `admin/assets/${id}`, obj).then((res: any) => {
      return
    })
  }

  updateAssetType(id, obj){
    this.misc.apiPatch('payments', `admin/asset_types/${id}`, obj).then((res: any) => {
      return
    })
  }
}
