<div class="search">
  <mat-icon class='search-icon' [style.left]="inputTouch ? '-20px' : '10px' ">search</mat-icon>
  <mat-icon *ngIf='search' (click)='clearSearch()'  matTooltip='clear' class='clear-icon'>clear</mat-icon>
  <input matInput (keydown.enter)='searchReset()' [(ngModel)]="search" placeholder="Search"  #input (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
</div>
    
    
    <table  mat-table [dataSource]='storeProducts' matSort>

      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
        <td mat-cell *matCellDef="let product" id='img' routerLink='/store-product/{{product.store_product_id}}'> <img id='prod-img' src={{product.image}} alt=""> </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let product" routerLink='/store-product/{{product.store_product_id}}' > {{product.name}} </td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
        <td mat-cell *matCellDef="let product"> 
          <p  *ngIf='product.size'>{{product.size}} </p>
          <p  *ngIf='!product.size' class='empty'>size not specified </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="was_price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> was price </th>
        <td mat-cell *matCellDef="let product">
           <p *ngIf='product.was_price'>{{product.was_price}} </p>
           <p *ngIf='!product.was_price' class='none'>n/a </p>
          </td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> current price </th>
        <td mat-cell *matCellDef="let product"> 
          <p >{{product.price}} </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="qty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> QTY </th>
        <td mat-cell *matCellDef="let product"> {{product.qty}} </td>
      </ng-container>

      <ng-container matColumnDef="add">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> <mat-icon class='action' ></mat-icon> </th>
        <td mat-cell *matCellDef="let product"> 
          <mat-icon matTooltip='more' [matMenuTriggerFor]="menu">more_horiz</mat-icon> 
          <mat-menu #menu="matMenu" >
              <button mat-menu-item (click)='addDialog(product)'> 
                <mat-icon>edit</mat-icon>
                <span>update store product</span>
              </button>
              <button mat-menu-item  (click)='deleteDialog(product)'>
                <mat-icon>delete_outline</mat-icon>
                <span>remove product from store</span>
              </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="Columns"></tr>
      <tr mat-row 
          class='action'
          *matRowDef="let product; columns: Columns;"></tr>
    </table>


  
    <div id="pager" >
      <div class="page"> 

       <button id="{{auth.expand ? 'create-expand': 'create'}}" mat-flat-button class='button' (click)='addStoreProduct()' >add products to store</button>
  
      <div> Per page:</div> 
      <span class="silo-filter pagination">
        <button mat-button [matMenuTriggerFor]="paginate">
          <span>{{limit}}</span>
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #paginate="matMenu" >
          <button mat-menu-item (click)="setLimit(10)">10</button>
          <button mat-menu-item (click)="setLimit(20)">20</button>
          <button mat-menu-item (click)="setLimit(50)">50</button>
        </mat-menu>
      </span>
  
      <div (click)='start()' matTooltip="Skip to start" class='action' *ngIf='page > 1'>back to start</div>
      <p (click)='previous()' class='action'>previous</p>
      <div>{{page}}</div>
        <p (click)='next()' class='action'>next</p>
        <div> </div>
      </div>
    </div>