<table  mat-table [dataSource]='malls' matSort>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let mall" routerLink='/malls/{{mall.id}}' id='mall-name'> <p>{{mall.name}} </p></td>
    </ng-container>

    <!-- Address Column -->
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
      <td mat-cell *matCellDef="let mall" routerLink='/malls/{{mall.id}}'> 
        <p> {{mall.physical_address}} </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let mall" routerLink="/malls/{{mall.id}}"> {{mall.email}} </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
      <td mat-cell *matCellDef="let mall" routerLink="/malls/{{mall.id}}"> {{mall.phone}} </td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> active </th>
      <td mat-cell *matCellDef="let mall" >
        <p *ngIf='mall.active'  class='active'><mat-icon>check</mat-icon> </p>
        <p *ngIf='!mall.active' class='inactive'><mat-icon>clear</mat-icon> </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="more">
      <th mat-header-cell  *matHeaderCellDef> </th>
      <td mat-cell  *matCellDef="let mall" >      
        <mat-icon matTooltip='more' [matMenuTriggerFor]="menu" class='action'>more_horiz</mat-icon> 
        <mat-menu #menu="matMenu">
          <button mat-menu-item routerLink="/malls/{{mall.id}}">
            <mat-icon class='material-icons-outlined'>arrow_forward</mat-icon>
            <span >view mall info </span>
          </button>
          <button mat-menu-item (click)='toggleMallStatus(mall)'>
            <mat-icon class='material-icons-outlined' *ngIf='mall.active'>toggle_off</mat-icon>
            <span *ngIf='mall.active'>deactivate section</span>
            <mat-icon class='material-icons-outlined' *ngIf='!mall.active'>toggle_on</mat-icon>
            <span *ngIf='!mall.active'>activate section</span>
          </button>
          <button mat-menu-item (click)='removeMall(mall)'>
            <mat-icon >delete_outline</mat-icon>
            <span >delete mall</span>
          </button>
        </mat-menu>
      </td>
  </ng-container>


    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row class='action'  *matRowDef="let mall; columns: Columns;"></tr>
  </table>

  <div id="pager" >

    <button mat-flat-button class='button' id="{{auth.expand ? 'create-expand': 'create'}}" routerLink='/create-mall'>New</button>

    <div class="page">  

    <div> Per page:</div>
    <div (click)="setLimit(10)" class="limit" >10</div>
    <div (click)="setLimit(15)" class="limit" >15</div>
    <div (click)="setLimit(20)" class="limit" >20</div>
    <div (click)="setLimit(50)" class="limit" >50</div>
    <div>Page:</div>
    <mat-icon (click)='previous()'>skip_previous</mat-icon>
    <div>{{page}}</div>
      <mat-icon (click)='next()'>skip_next</mat-icon>
      <div> </div>
    </div>
  </div>

