import { Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { ApiService } from '../../services/api.service'
import { PagerService } from '../../services/pager.service'
import { MessagesService } from './../../services/messages.service'

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  // ------------------
  //   TABLE COLUMNS
  // ------------------

  Columns = ['name', 'email', 'phone', 'last active', 'verified']

  inputTouch = false

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }

  // -----------------------
  //   COMPONENT VARIABLES
  // ----------------------

  count
  users

  page    = 1
  limit   = 10
  search  = ''

  constructor( private api:ApiService,
              private message: MessagesService,
              private router: Router
  ) { }


  ngOnInit(): void {
    if(localStorage.getItem('usersPage')){
      this.page = JSON.parse(localStorage.getItem('usersPage'))
    }
    
    if(localStorage.getItem('limit')){
      this.limit = JSON.parse(localStorage.getItem('limit'))
    }

    if(localStorage.getItem('userSearch')){
      this.search = localStorage.getItem('userSearch')
    } 

    this.getUsers()

  }

// =================
//      USERS
// =================


retries = 0

  getUsers(){
    localStorage.setItem('userSearch', this.search)
    this.api.getList('auth', `admin/users?page=${this.page}&per_page=${this.limit}&search=${this.search}`).subscribe((users: any) => {
      console.log(users)
      if(users.result_count == 0){
        this.page--
        this.getUsers()
        this.message.showSnack('no more users to show')
      }
      this.users = users.users
      this.count = users.total
    }, error => {
      this.message.showSnack(error.error)
      if(this.retries < 3){
        if(error.status  !== 401){
          this.retries++
          this.getUsers()
        }
      }
    })
  }

  navigateRoute(user){
    localStorage.removeItem('userTab')
    this.router.navigate([`/users/${user.ID}`])
    localStorage.setItem('customer_navigation', 'users')
  }

  searchReset(){
    this.page = 1
    this.getUsers()
    localStorage.setItem('usersPage', JSON.stringify(this.page))
  }


  clearSearch(){
    this.search = ''
    this.page  = 1
    this.getUsers()
    localStorage.setItem('usersPage', JSON.stringify(this.page))
  }



// =================
//    PAGINATION
// =================

  setLimit(limit){
    this.limit = limit
    this.getUsers()
     
    localStorage.setItem('limit', JSON.stringify(limit))
  }

  next(){
        this.page++

        this.getUsers()
    
    localStorage.setItem('usersPage', JSON.stringify(this.page))
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.getUsers()  
    }
    localStorage.setItem('usersPage', JSON.stringify(this.page))
  }

  start(){
    this.page = 1
    this.getUsers()
    localStorage.setItem('usersPage', JSON.stringify(this.page))
  }

}
