import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MiscService } from 'src/app/services/misc/misc.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-dischem-users',
  templateUrl: './dischem-users.component.html',
  styleUrls: ['./dischem-users.component.scss']
})
export class DischemUsersComponent implements OnInit {
  Columns = ['name', 'email', 'phone', 'verified'];


  constructor(public users: UsersService, public misc: MiscService, public router: Router) { }

  ngOnInit(): void {
    this.users.getDischemUsers()
  }

  navigateRoute(user){
    this.router.navigate([`/users/${user.ID}`])
    localStorage.setItem('customer_navigation', 'dischem')
  }

  clearSearch(){
    this.users.dischem_users_search = ''
    this.users.getDischemUsers()
  }

  searchReset(){
      this.users.dischem_users_page = 1
      this.users.getDischemUsers()
    }
  
// =================
//    PAGINATION
// =================

  searching(){
    this.users.dischem_users_page = 1;
  }
  
    setLimit(limit){
          this.users.dischem_users_limit = limit
          if(this.users.dischem_users_search == ''){
            this.users.getDischemUsers()
      }
    }
  
    next(){
          this.users.dischem_users_page++
          if(this.users.dischem_users_search == ''){
            this.users.getDischemUsers()
      }
    }
  
    previous(){
      if(this.users.dischem_users_page > 1){
        this.users.dischem_users_page--
        if(this.users.dischem_users_search == ''){
          this.users.getDischemUsers()
        }   
      }
    }

}
