import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-partners-create',
  templateUrl: './partners-create.component.html',
  styleUrls: ['./partners-create.component.scss']
})
export class PartnersCreateComponent implements OnInit {

  constructor(
    public api: ApiService,
    public message: MessagesService,
    public router: Router
  ) {}

  ngOnInit(): void {
  }
partner;
  name;
  callback_url;
  max_delivery_distance;

  firstForm = new FormGroup({
    name: new FormControl(''),
    callback_url: new FormControl(''),
    max_delivery_distance: new FormControl(''),
  });

  submitPartnerInfo() {
    this.partner = { ...this.firstForm.value };
    this.partner.name = this.partner.name;
    this.partner.callback_url= this.partner.callback_url;
    this.partner.max_delivery_distance = this.partner.max_delivery_distance;
    this.partner.active = true
  }

  submitPartner(){
    this.api.post('godrive',`admin/partners`,this.partner).subscribe((data:any)=>{
      this.message.showSnack(`Succesfully added partner ${this.partner.name}`);
      this.router.navigate([`partners`]);
    }, error => {
      this.message.showSnack(error.error.message)
    });
    
  }
}
