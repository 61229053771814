import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { PaymentsService } from 'src/app/services/payments/payments.service';

@Component({
  selector: 'app-list-batches',
  templateUrl: './list-batches.component.html',
  styleUrls: ['./list-batches.component.scss'],
})
export class ListBatchesComponent implements OnInit {
  Columns = [
    'date',
    'payment_method',
    'status',
    'amount',
    'download',
    'navigate'
  ];

  constructor(
    public payments: PaymentsService,
    public auth: AuthService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getPaymentBatches();
  }

  getPaymentBatches(){
    this.payments.getPaymentBatches(this.page);

  }

  page=1;
  limit=10;
  setLimit(i){
    this.limit=i
  }
  previous(){
    if(this.page < 2){
      return
    }
    this.page--;
    this.getPaymentBatches()

  }
  next(){
    this.page++;
    this.getPaymentBatches()
  }


  downloadFile(batch) { 
    event.stopPropagation();
    this.payments.downloadPaymentFile(
      batch.ID,
      batch.payment_method_id
    );
  }

  createPaymentMethod() {
    const dialog = this.dialog.open(createPaymentMethodDialog, {
      width: '650px',
      height: '250px',
    });

    dialog.afterClosed().subscribe(() => {
      this.payments.getPaymentMethods();
    });
  }

  viewPaymentMethods() {
    const dialog = this.dialog.open(viewPaymentMethodsDialog, {
      width: '800px',
      height: '600px',
    });
  }
}

// ==============================
//   VIEW PAYMENT METHODS DIALOG
// ==============================
@Component({
  selector: 'view-payment-methods-dialog',
  templateUrl: 'view-payment-methods-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class viewPaymentMethodsDialog {
  payment_methods_columns = [
    'name', 
    'description',  
    'account_number'
  ];

  constructor(
    public payments: PaymentsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<viewPaymentMethodsDialog>
  ) {}

  ngOnInit(): void {
    this.payments.getPaymentMethods();
  }

  onAdd = new EventEmitter();

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  // // earning type create dialog
  editPaymentMethod(method) {
    const dialog = this.dialog.open(editPaymentMethodDialog, {
      width: '650px',
      height: '250px',
      data: {
        method: method,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.payments.getPaymentMethods();
    });
  }
}

@Component({
  selector: 'create-payment-method-dialog',
  templateUrl: 'create-payment-method-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class createPaymentMethodDialog {
  constructor(
    private payments: PaymentsService,
    public dialogRef: MatDialogRef<createPaymentMethodDialog>
  ) {}

  firstForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl(''),
  });

  onAdd = new EventEmitter();

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  submitPaymentMethod() {
    // console.log(this.firstForm.value)
    this.payments.CreatePaymentMethod(this.firstForm.value);
    this.closeDialog();
  }
}

@Component({
  selector: 'edit-payment-method-dialog',
  templateUrl: 'edit-payment-method-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class editPaymentMethodDialog {

  name
  description

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { method },
    private payments: PaymentsService,
    public dialogRef: MatDialogRef<editPaymentMethodDialog>
  ) {}

  ngOnInit(): void {
    this.name = this.data.method.name
    this.description = this.data.method.description
  }



  onAdd = new EventEmitter();

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  updatePaymentMethod(payment) {
    this.payments.updatePaymentMethod(this.data.method.ID, payment)
    this.closeDialog();
  }



}

