<div *ngIf='shade' id='shade' class='shade'> 
    <mat-spinner class='spinner'></mat-spinner> 
  </div>
  
  <div class="back action" routerLink="/malls">
    <mat-icon>arrow_back</mat-icon>
    <p>malls</p>
  </div>
  
  <div class="create-card"  >
  
    <div class="header">
      <h3>create new mall</h3>
      <mat-icon routerLink='/malls'>clear</mat-icon>
    </div>
  
      <!-- ---------------------- -->
      <!--   COLLECT MALL INFO    --> 
      <!-- ---------------------- -->

      <div class="first-form" [style.left]="mallInfo  ? '-900px' : '0' ">
          
        <form #product = 'ngForm' (ngSubmit)='submitMallInfo(product.value)'>

                
          <mat-form-field appearance="outline" class='field'>
            <mat-label>address lookup</mat-label>
            <input matInput 
                  placeholder="12 bob street" 
                  matGoogleMapsAutocomplete
                  type="address,place"
                  country="za"
                  (onAutocompleteSelected)="onAutocompleteSelected($event)"
                  required
                  >
            </mat-form-field>
        
            <mat-form-field class='field' appearance='outline'>
              <mat-label>mall name</mat-label>
              <input type="text" matInput [(ngModel)]="name"  placeholder="gouda cheese" name='name' required>
            </mat-form-field>

      
            <mat-form-field class='field' appearance='outline'>
              <mat-label>description</mat-label>
              <input type="text" 
                    matInput 
                    ngModel 
                    placeholder="a shopping centre located in Cape Town, South Africa." 
                    name='description'>
            </mat-form-field>
      
            <div class="form">
                <mat-form-field  appearance='outline'>
                  <mat-label>email</mat-label>
                  <input type="email" matInput ngModel placeholder="johndoe@gmail.com" name='email' required>
                </mat-form-field>
                <mat-form-field  appearance='outline'>
                  <mat-label>phone</mat-label>
                  <input type="text" matInput [(ngModel)]="phone"  placeholder="021 558 9304" name='phone' required>
                </mat-form-field>
              </div>
      
              <div class="btn">
                <button mat-flat-button class='button' type='submit'>next</button>      
              </div>

        </form>

      </div>

    <!-- ------------------ -->
    <!--     ADD IMAGE      --> 
    <!-- ------------------ -->

  <div class="second-form" [style.left]="mallInfo  ? '0' : '900px' " >

        <mat-icon class='action' (click)='back()'>arrow_back</mat-icon>

        <h3>Almost there! Please upload a banner image for this mall</h3>
        <span class='recommended-size'  >*recommended image dimensions 1500 * 500</span>


        <div class='image'>
        <img class="preview-image" src='{{image}}'>
        <br>
        <button class="custom-file-upload" mat-button type="button" onclick="document.querySelector('#exampleInputFile').click()">Upload Image</button>
        <input type="file" id="exampleInputFile" (change)="imagePreview($event)" style="display: none;">
        </div>

        <div class="btn product-info-button">
        <button mat-flat-button class='button' (click)='submitMall()' disabled="{{imageFormat ? false : true}}">Save</button>
        </div>

    </div>
  
  </div>
