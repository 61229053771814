

<div class="split_tables">
    <!-- assets table -->
    <table mat-table [dataSource]="accounts.accounts" matSort class="asset_table">
  
      <ng-container matColumnDef="user_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
        <td mat-cell *matCellDef="let account">
          <p>{{ account.user_id }}</p>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="bank_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
         Bank
        </th>
        <td mat-cell *matCellDef="let account">
          <p>{{ account.bank_id }}</p>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="account_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>account name</th>
        <td mat-cell *matCellDef="let account">
          <p *ngIf="account.account_name">
            {{ account.account_name }}
          </p>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="account_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>account number</th>
        <td mat-cell *matCellDef="let account">
          <p *ngIf="account.account_number">
            {{ account.account_number }}
          </p>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="branch_code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Branch code</th>
        <td mat-cell *matCellDef="let account">
          <p *ngIf="account.branch_code">
            {{ account.branch_code }}
          </p>
        </td>
      </ng-container>
  
  
      <ng-container matColumnDef="verified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Verified</th>
        <td mat-cell *matCellDef="let account">
            <mat-icon *ngIf="account.verified" class="active">check</mat-icon>
            <mat-icon *ngIf="!account.verified" class="inactive">clear</mat-icon>
        </td>
      </ng-container>
  
  
      <ng-container matColumnDef="default_account">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>default account</th>
        <td mat-cell *matCellDef="let account">
            <mat-icon *ngIf="account.default_account" class="active">check</mat-icon>
            <mat-icon *ngIf="!account.default_account" class="inactive">clear</mat-icon>
        </td>
      </ng-container>
  
  
  
      <tr mat-header-row *matHeaderRowDef="Columns"></tr>
      <tr mat-row class="action" *matRowDef="let account; columns: Columns"></tr>
    </table>
  
  </div>
  

  <div id="pager" >
    <div class="page" >
      <div>Per page:</div>
      <span class="silo-filter pagination">
        <button mat-button [matMenuTriggerFor]="paginate">
          <span>{{ limit }}</span>
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #paginate="matMenu">
          <button mat-menu-item (click)="setLimit(10)">10</button>
          <button mat-menu-item (click)="setLimit(20)">20</button>
          <button mat-menu-item (click)="setLimit(50)">50</button>
        </mat-menu>
      </span>
  
      <div
        (click)="start()"
        matTooltip="Skip to start"
        class="action"
        *ngIf="page > 1"
      >
        back to start
      </div>
      <p (click)="previous()" class="action">previous</p>
      <div>{{ page }}</div>
      <p (click)="next()" class="action">next</p>
      <div></div>
    </div>
  </div>