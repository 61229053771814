<div class="back action" routerLink="/{{navigation_crumb}}">
  <mat-icon>arrow_back</mat-icon>
  <p *ngIf="navigation_crumb == 'users' ">customers</p>
  <p *ngIf="navigation_crumb == 'admins' ">admins</p>
  <p *ngIf="navigation_crumb == 'dischem' ">dischem</p>
  <p *ngIf="navigation_crumb == 'drivers' ">drivers</p>
</div>

<div class="tabs">
  <div (click)='selectTab(tab)' *ngFor="let tab of tabs; let i = index" [class]="selectedTab == tab.name ? 'selectedTab' : '' ">
    <div id='tab' *ngIf="tab.name == 'finances' && isDriver || tab.name != 'finances'">
      <p id='tab-name'>{{tab.name}}</p>
    </div>
  </div>
</div>

<app-user-detail *ngIf="selectedTab == 'details'"></app-user-detail>
<app-transactions *ngIf="selectedTab == 'transactions'"></app-transactions>
<app-addresses *ngIf="selectedTab == 'addresses' "></app-addresses>
<app-cards *ngIf="selectedTab == 'cards'"></app-cards>
<app-user-orders *ngIf="selectedTab == 'orders' "></app-user-orders>
<app-finances *ngIf="selectedTab == 'payments' "></app-finances>
<!-- <app-favourites *ngIf="selectedTab == 'favourites' "></app-favourites> -->

