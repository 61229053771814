import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { AgmMap, MapsAPILoader, PolygonManager  } from '@agm/core';
import { MessagesService } from 'src/app/services/messages.service';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { Location } from '@angular-material-extensions/google-maps-autocomplete';
import { Component, OnInit, ViewChild , NgZone, ElementRef } from '@angular/core';

@Component({
  selector: 'app-zones-map',
  templateUrl: './zones-map.component.html',
  styleUrls: ['./zones-map.component.scss']
})

export class ZonesMapComponent implements OnInit {
  @ViewChild(AgmMap) AGMmap: any
  @ViewChild('searchLocation') searchElementRef: ElementRef;
  citySearch = new FormControl();
  constructor(
    public zonesService: ZonesService,
    public router: Router,
    public messageService: MessagesService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
    ) {
      this.mapsAPILoader.load().then(() => {
        this.zonesService.lat = -29.630621
        this.zonesService.lng = 24.088321
        this.zonesService.mapsLoaded = true;
        this.getZones();
      });

      this.citySearch.valueChanges.subscribe(newValue => {
        let searchCity:string = newValue
        if(searchCity != ''){
         if(this.filterCities(searchCity) != []){
          this.zonesService.filteredCities = this.filterCities(searchCity)
          if(this.zonesService.filteredCities.length <= 0){
            this.messageService.showSnack('No cities found, please try again')
           }
         }   
        }
       });
     }

  ngOnInit(): void {
 
  }

  ngAfterViewInit() {

  }

  navSingleZone(zone:any){
    let zoneId = zone.id
    localStorage.setItem('activeZone', JSON.stringify(zone))
    location.replace(`zone/${zoneId}`)
  }


  editToggle(){
    this.zonesService.edit = !this.zonesService.edit
    this.zonesService.addingZone = false;
    console.log(this.AGMmap)
    console.log(this.zonesService.polygon)
    this.removePolygons()
    this.getZones()
  }

  filterCities(search: string) {
 return this.zonesService.cities.filter(city => city.city.toLowerCase().includes(search.toLowerCase()))
  }

  lookupByCity(selectedCity){

    let matchingCity = false;

    this.zonesService.cities.forEach((city:any) => {
        if(city.city == selectedCity && !matchingCity){
          matchingCity = true
          selectedCity = city
        }
    })

    this.zonesService.zoom = 9
    this.zonesService.lat = Number(selectedCity.lat)
    this.zonesService.lng = Number(selectedCity.lng)

  }

  selectRegion(){
    console.log(this.zonesService.selectedPlace)
    if(this.zonesService.selectedPlace.name == "All"){
      this.zonesService.zoom = 5
    } else {
      this.zonesService.zoom = 9
    }

    this.zonesService.lat = this.zonesService.selectedPlace.lat
    this.zonesService.lng = this.zonesService.selectedPlace.lng
  }

  drawPolygons(){
    this.zonesService?.zones?.forEach((zone:any, i) => {
      if(this.zonesService.zones[i] != undefined){
        
        this.zonesService.zones[i].geopoints.forEach((geopoint:any) => {
          geopoint['lat'] = geopoint.latitude
          geopoint['lng'] = geopoint.longitude
        });
  
        this.zonesService.polygon = new google.maps.Polygon({
          paths: this.zonesService?.zones[i].geopoints,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
        })
    
        this.zonesService.polygon.addListener('click', (event) => {
          this.navSingleZone(this.zonesService.zones[i])
        })
  
        this.zonesService.polygon.addListener('dblclick', (event) => {
          console.log(event);
        })
    
        this.zonesService.polygon.strokeColor = '#000'
        this.zonesService.polygon.fillColor = '#000'
      
        this.zonesService.polygons.push(this.zonesService.polygon)
        this.zonesService.polygon?.setMap(this.AGMmap)
       }
    })
  }

  addPolygon(){
    this.router.navigate(['new-zone'])
    // this.zonesService.addingZone = true

    // this.zonesService.newPolygon = [
    //   {
    //     "lat": this.zonesService.lat,
    //     "lng": this.zonesService.lng
    //   },
    //   {
    //     "lat": this.zonesService.lat - 0.1,
    //     "lng": this.zonesService.lng - 0.1
    //   },
    //   {
    //     "lat": this.zonesService.lat - 0.1,
    //     "lng": this.zonesService.lng + 0.1
    //   },
    //   {
    //     "lat": this.zonesService.lat,
    //     "lng": this.zonesService.lng
    //   }
    // ]


    // this.zonesService.polygon = new google.maps.Polygon({
    //   paths: this?.zonesService?.newPolygon,
    //   strokeColor: '#FF0000',
    //   strokeOpacity: 0.8,
    //   strokeWeight: 2,
    //   fillColor: '#FF0000',
    //   fillOpacity: 0.35,
    //   editable: true,
    //   draggable: true,
    // });

    // this.zonesService.polygon.getPaths().forEach((path:any) => {

    //   google.maps.event.addListener(path, 'insert_at', function () {

    //     let geopoints = []

    //     const len = path.getLength();
    //     for (let i = 0; i < len; i++) {
    //       geopoints.push(
    //         path.getAt(i).toJSON()
    //       )
    //     }
    //     // this.polygon.paths = geopoints;
    //     // console.log(geopoints)
    //     localStorage.setItem('geopoints', JSON.stringify(geopoints))
    //   })

    //   google.maps.event.addListener(path, 'set_at', function () {

    //     let geopoints = []

    //     const len = path.getLength();
    //     for (let i = 0; i < len; i++) {
    //       geopoints.push(
    //         path.getAt(i).toJSON()
    //       )
    //     }
    //     // this.polygon.paths = geopoints;
    //     // console.log(geopoints)
        
    //     localStorage.setItem('geopoints', JSON.stringify(geopoints))
    //   })

    //   google.maps.event.addListener(path,'set_at', (event:any) => {
    //     // this.getAddress()
    //   })

    //   google.maps.event.addListener(path,'insert_at', (event:any) => {
    //     // this.getAddress()
    //   })
    
    // })

    // this.zonesService.polygon?.setMap(this.AGMmap);
  }

  savePolygon(){

    this.zonesService.lat = this.zonesService.newPolygon[0].lat;
    this.zonesService.lng = this.zonesService.newPolygon[0].lng;

    this.getAddress()
    .then((res:any) => {

      this.zonesService.newPolygon = JSON.parse(localStorage.getItem('geopoints'))

      this.zonesService.newPolygon.forEach((newGeopoint:any) => {
        newGeopoint["latitude"] =  Number(JSON.stringify(newGeopoint.lat).slice(0,10))
        newGeopoint["longitude"] = Number(JSON.stringify(newGeopoint.lng).slice(0,10))
        delete newGeopoint.lat
        delete newGeopoint.lng
      });
  
      if(this.zonesService.selectedArea != "" && this.zonesService.selectedArea.length > 3){
        this.zonesService.saveZone(this.zonesService.newPolygon, this.zonesService.city, this.zonesService.selectedArea)
        .then((res:any) => {
            console.log(res)
        })
        .catch((err:any) => {
          console.log(err)
          if(err.status == 200){
            this.editToggle();
            this.zonesService.addingZone = false;
            this.zonesService.toggleAdd = false;
            setTimeout(() => {
              localStorage.removeItem('zones')
                this.zonesService.polygon.setMap(null)
                this.zonesService.loading = true;
                this.getZones();
            }, 2000);
            this.messageService.showSnack(err.statusText)
          } else if (err.status == 500){
            this.messageService.showSnack(err.statusText)
          }
        })
      } else {
        this.messageService.showSnack('Area cannot be blank.')
      }
    })
    .catch((err:any) => {
      console.log(err)
    })

    
  }

  setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.zonesService.lat = position.coords.latitude;
        this.zonesService.lng = position.coords.longitude;
      });
    }
  }

  getZones(){
    this.zonesService.getZones()
    .then((res:any) => {
      this.zonesService.loading = false;
      if(res.zones != [] && res.zones != null){
        this.zonesService.zones = res.zones;
        this.drawPolygons()
        if(this.zonesService.edit){
          this.editPolygons()
          } else {
            this.drawPolygons()
         }
      }
    })
    .catch((err:any) => {
      console.log(err)
    })
  }

  onMapReady(map) {
    this.AGMmap = map
    setTimeout(() => {
      this.drawPolygons()
    }, 800)
  }

  editPolygons(){
    let zones = []
    
    this.zonesService?.zones?.forEach(zone => {

      zone?.geopoints?.forEach((geopoint,i) => {

        let zone = this.zonesService?.zones[i]

        if(this.zonesService.zones[i] != undefined){

          this.zonesService.polygon = new google.maps.Polygon({
            paths: this.zonesService.zones[i].geopoints,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            editable: true,
            draggable: true,
          })
  
          this.zonesService.polygons.push(this.zonesService.polygon)
          this.zonesService.polygon?.setMap(this.AGMmap)
      
            this.zonesService.polygon.getPaths().forEach(path => {
              google.maps.event.addListener(path, 'insert_at', function () {
                    
                let geopoints = []
                const len = path.getLength();
                for (let i = 0; i < len; i++) {
                  geopoints.push(
                    path.getAt(i).toJSON()
                  )
                }
      
                var isZone =zones.map(z => z.id).indexOf(zone.id)
      
                if(isZone === -1){
                  zones.push(zone)
                  zones[zones.length -1].geopoints = geopoints
                } else {
                  zones[isZone].geopoints = geopoints
                }
      
                localStorage.setItem('zones', JSON.stringify(zones))
      
              })
          
              // add points
                google.maps.event.addListener(path, 'set_at', function () {
      
                  let geopoints = []
          
                  const len = path.getLength();
  
                  for (let i = 0; i < len; i++) {
                    geopoints.push(
                      path.getAt(i).toJSON()
                    )
                  } 
      
                  var isZone = zones.map(z => z.id).indexOf(zone.id)
      
                  if(isZone === -1){
                   zones.push(zone)
                   zones[zones.length -1].geopoints = geopoints
                  } else {
                    zones[isZone].geopoints = geopoints
                  }  
      
                  localStorage.setItem('zones', JSON.stringify(zones))
          
                  // add start point again if start point moved
                  let l = geopoints.length - 1
  
                  if(geopoints[0].lat !== geopoints[l].lat){
                     geopoints.push(geopoints[0])
                  }
          
                  localStorage.setItem('geopoints', JSON.stringify(geopoints))
                })
            })
            
        }
      }
      );      
    });
    
    // for(var i = 0; i < this.geopoints.length; i++){
     
    // }
  }

  // gets address from input
  async onLocationSelected(location: Location) {
    this.zonesService.zoom = 18
    this.zonesService.lat = location.latitude;
    this.zonesService.lng = location.longitude;
    this.getAddress();
  }

  updatePolygon(){
    let zones = JSON.parse(localStorage.getItem('zones'))
 
    zones.forEach(z => {
 
      z.geopoints.forEach((geopoint:any) => {
        console.log(geopoint.lat)
        geopoint.lat =  Number(JSON.stringify(geopoint.lat).slice(0,10))
        geopoint.lng = Number(JSON.stringify(geopoint.lng).slice(0,10))
        geopoint["latitude"] =  Number(JSON.stringify(geopoint.lat).slice(0,10))
        geopoint["longitude"] = Number(JSON.stringify(geopoint.lng).slice(0,10))
      });

      // console.log(z.geopoints)

      let zone = {
        id: z.id,
        geopoints: z.geopoints
      }

      this.zonesService.updateZone(zone)
      .then((res:any) => {
        console.log(res)
        if(zones.length < 2){
          this.messageService.showSnack('Succesfully updated zone.')
        } 
        else {
         this.messageService.showSnack('Succesfully updated zones.')
        }
       setTimeout(() => {
         localStorage.removeItem('zones')
         this.redrawPolygons()
       }, 2000);
      })
      .catch((err:any) => {
        console.log(err)
        this.messageService.showSnack('Geofence not updated, points are incorrect. Please try again.')
      })
    })
 
   }

   async getAddress() {
    var latlng = new google.maps.LatLng(this.zonesService.lat, this.zonesService.lng);
      // This is making the Geocode request
      var geocoder = new google.maps.Geocoder();

      await geocoder.geocode({ 'location': latlng },  (results:any, status:any) => {
 
        if (status !== google.maps.GeocoderStatus.OK) {
            alert(status);
        }

        if (status == google.maps.GeocoderStatus.OK) {
  
         if(results != [] ){
            // console.log(results[0])
            this.zonesService.city = results[0]?.address_components[1]?.long_name
          } 
          else if(results == []){
          
            }      
        }(error:any) => {
          console.log(error);
        }
      }
    );
  }

   redrawPolygons(){
    this.zonesService.polygons.forEach((poly:any) => {
      poly.setMap(null)
    })
    this.zonesService.edit = false
    this.getZones()
  }

  removePolygons(){
    this.zonesService.polygons.forEach((poly:any) => {
      poly.setMap(null)
    })
  }
  
}
