<div *ngIf='active' id='shade'></div>
  
<div class="stores">

    <div *ngIf='!edit'>
  
        <table  mat-table  
                [dataSource]='silos' 
                cdkDropList
                cdkDropListOrientation="vertical"
                (cdkDropListDropped)="drop($event)">
                
            
            <ng-container matColumnDef="sort">
            <th mat-header-cell *matHeaderCellDef > Sort </th>
            <td mat-cell  *matCellDef="let silo"> <mat-icon>sort</mat-icon> </td>
            </ng-container>
        
            <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef > ID </th>
            <td mat-cell  *matCellDef="let silo"> <p>{{silo.id}}</p> </td>
            </ng-container>
        
            <ng-container matColumnDef="name">
            <th mat-header-cell  *matHeaderCellDef> Name </th>
            <td mat-cell  *matCellDef="let silo" id='title'> <p>{{silo.name}}</p> </td>
            </ng-container>
        
            <ng-container matColumnDef="tag">
            <th mat-header-cell  *matHeaderCellDef> Tag </th>
            <td mat-cell  *matCellDef="let silo"> <p  id='silo' > {{silo.tag}} </p></td>
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell  *matHeaderCellDef> Active </th>
                <td mat-cell  *matCellDef="let silo" >      
                  <mat-slide-toggle [(ngModel)]="silo.active" (change)="toggleSiloStatus(silo)"></mat-slide-toggle>
                </td>
            </ng-container>
            
            <ng-container matColumnDef="edit">
                <th mat-header-cell  *matHeaderCellDef>  </th>
                <td mat-cell  *matCellDef="let silo" (click)='toggleEdit(silo)'> <mat-icon>edit</mat-icon></td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="Columns"></tr>
            <tr mat-row  id='drag' class='action' cdkDrag *matRowDef="let silo; columns: Columns;"></tr>
        </table>
    </div>

    <!-- edit form -->
  
    <div *ngIf='edit'>
      <form class='form'>
        <div class="split">
          <span id='form-heading'>
            <h3>Update Silo {{silo.name}}</h3>
            <mat-icon class='action' (click)='toggleEdit()'>close</mat-icon>
          </span>
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input type="text" matInput name='name' [(ngModel)]="silo.name" >
            </mat-form-field>
            <mat-form-field>
              <mat-label>Tag</mat-label>
              <input type="text" matInput name='tag' [(ngModel)]="silo.tag">
            </mat-form-field>
            <div class="btn">
              <button class='button' mat-flat-button (click)='updateSilo()'>Update Silo</button>
            </div>
        </div>
      </form>
    </div>
</div>
  
  <div id="pager" >
    <button mat-flat-button  class='button' id="{{auth.expand ? 'create-expand': 'create'}}" routerLink='/silo-create'>New</button>
    <div class="page"> </div>
  </div>