
<div *ngIf="auth.isLoggedIn">

<div class="container" >
  <div class="side-nav" [style.width]="auth.expand ? '200px' : '80px' ">
    <app-sidenav ></app-sidenav>
  </div>
  <div class="content" [style.left]="auth.expand ? '240px' : '90px' " [style.width]="auth.expand ? 'calc(100vw - 240px)' : 'calc(100vw - 110px)' ">
    <router-outlet></router-outlet>
  </div>
</div>

</div>

<div *ngIf="!auth.isLoggedIn">
  <app-login></app-login>
</div>

<div id="snackbar" [style.bottom]="messages.show ? '60px':'-80px' ">
  <div>{{messages.snack}}</div>
</div>