import { ActivatedRoute }                                                 from '@angular/router'
import { ApiService }                                                     from 'src/app/services/api.service'
import { MessagesService }                                                from 'src/app/services/messages.service'
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef }                       from '@angular/material/dialog'
import { Component, OnInit , ElementRef, ViewChild, Inject, EventEmitter} from '@angular/core'

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
  headings = ['type',  'amount', 'note', 'reference', 'order', 'status']

  inputTouch = false

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }

  id
  amount
  reason
  peachId
  user_transactions

  page    = 1
  limit   = 10
  search  = ''
  refund  = false
  retries = 0

  constructor(public api: ApiService,
              public route: ActivatedRoute,
              private message: MessagesService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getUserTransactions()
  }

  transactions        = []
  transactionDates    = []
  sortedTransactions  = []

    getUserTransactions(){
      this.api.getList("payments", `admin/users/${this.id}/transactions?page=${this.page}&per_page=${this.limit}&search=${this.search}`)
      .subscribe((data:any)=>{


        // sorts through transactions and correlating dates
        let dates = []
        let trans = []
  
        data.transactions.forEach(transaction => {
          const date = new Date( transaction.CreatedAt ).toDateString()
  
            if(dates.includes(date)){
             trans.forEach(object => {
               if(object.date.includes(date)){
                object.transactions.push(transaction) 
               }
             })
            } else {
              dates.push(date)
              let newDate = {
                date: date,
                transactions: [transaction]
              }
              trans.push(newDate)
            }
        })

        this.sortedTransactions = trans
        console.log(this.sortedTransactions)

      },error=>{
        this.message.showSnack(error.error)
        if(this.retries < 3){
          if(error.status !== 401){
            this.retries++
            this.getUserTransactions()
          }
        }
      })
    }


        // open dialog for wallet update
    openRefundDialog(transaction){
      const dialog = this.dialog.open(RefundFialog, {
        width: '550px',
        height: '260px',
        data: {
          user: this.id,
          transaction: transaction
        }
      })
  
      dialog.afterClosed().subscribe(() => {
        this.getUserTransactions()
      })
    }


    clearSearch(){
      this.search = ''
      this.getUserTransactions()
    }

    start(){
      this.page = 1
      this.getUserTransactions()
    }

    setLimit(limit){
      this.limit = limit
      this.getUserTransactions()
    }

    next(){
      this.page++
      this.getUserTransactions()    
    }
    
    previous(){
      if(this.page > 1){
        this.page--
        this.getUserTransactions()
      }
    }
  
}

// =================
//   WALLET UPDATE
// =================
@Component({
  selector: 'delete-dialog',
  templateUrl: 'user-transaction-refund.html',
  styleUrls: ['../../../../components/dialogs/delete-dialog.component.scss']
})

export class RefundFialog {

  id
  amount
  reason
  transaction

  // get section info after success
  onAdd       = new EventEmitter()

    constructor(@Inject(MAT_DIALOG_DATA) 
    public data: { user, transaction }, 
    private api : ApiService, 
    private message: MessagesService,  
    public dialogRef: MatDialogRef<RefundFialog>) {
  }

  ngOnInit(): void {
    this.id = this.data.user
    this.transaction = this.data.transaction
    this.amount = this.data.transaction.amount
  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

  fixCommaAmount(){
    this.amount = JSON.parse(this.amount.replaceAll(',', '.'))
    this.amount = this.amount.toFixed(2)
  }

  RefundUser(){
    // added
    let refundAmount: number = Number(parseFloat(this.amount).toFixed(2))
    let refund = {
      peach_id: this.transaction.peach_id,
      amount: refundAmount,
      note: this.reason
    }

    this.api.post(`payments`, `process/refund`, refund).subscribe((refund: any) => {
      this.message.showSnack(`Succesfully refunded user`)
      this.closeDialog()
    }, error => {
      console.log(error)
    })

  }
}
