    <form #faq = 'ngForm' (ngSubmit)='createFaq(faq.value)'>
      <span>
        <h3>Create new FAQ</h3>
        <mat-icon class='action' routerLink='/faqs'>close</mat-icon>
      </span>
      <mat-form-field>
        <mat-label>Question</mat-label>
        <input type="text" matInput  ngModel name='title' >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Heading</mat-label>
        <input type="text" matInput ngModel name='heading' >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Answer</mat-label>
        <textarea type='text' name='body' matInput ngModel cols="10" rows="3"></textarea>
      </mat-form-field>
      <div class="btn">
        <button class='button' mat-flat-button type='submit'>Create new FAQ</button>
      </div>
    </form>
