import { formatCurrency, formatDate, formatNumber } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AssetsService } from 'src/app/services/assets/assets.service';
import { ContractsService } from 'src/app/services/contracts/contracts.service';
import { MiscService } from 'src/app/services/misc/misc.service';

@Component({
  selector: 'app-create-contract',
  templateUrl: './create-contract.component.html',
  styleUrls: ['./create-contract.component.scss'],
})
export class CreateContractComponent implements OnInit {
  constructor(
    public contracts: ContractsService,
    public misc: MiscService,
    public assets: AssetsService,
    public router: Router
  ) {}

  page = 1
  limit = 10
  search = ''

  ngOnInit(): void {
    this.assets.getAssets(this.page, this.limit, this.search);

    let assetCreate = {
      name: "create new asset"
    }

    setTimeout(() => {
      this.assets.assets.push(assetCreate)
      // console.log(this.assets.assets)
    }, 4000)
  }

  contract;
  user_id
  frequency;
  no_of_payments;
  drivers;
  driver;

  asset = {
    id: '',
    name: '',
  };

  payment_frequencies = [
    {
      name: 'weekly',
      days: 7,
    },
    {
      name: 'fortnightly',
      days: 14,
    },
    {
      name: 'monthly',
      days: 30,
    },
    {
      name: 'yearly',
      days: 365,
    },
  ];

  firstForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    start_date: new UntypedFormControl('', [Validators.required]),
    end_date: new UntypedFormControl('', [Validators.required]),
    frequency: new UntypedFormControl('', [Validators.required]),
    no_of_payments: new UntypedFormControl('', [Validators.required]),
    amount: new UntypedFormControl('', [Validators.required]),
    user_id: new UntypedFormControl('', [Validators.required]),
    asset_id: new UntypedFormControl('', [Validators.required]),
  });


  selectFrequency(e) {
    this.frequency = e.name;
    // console.log(this.firstForm.value.start_date);
    // calculate difference in days
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    let sd = new Date(
      formatDate(this.firstForm.value.start_date, format, locale)
    );
    let ed = new Date(
      formatDate(this.firstForm.value.end_date, format, locale)
    );
    var time = ed.getTime() - sd.getTime();
    var days = time / (1000 * 3600 * 24); //Diference in Days.

    // calculate amount of payments based on payment frequency
    let no_of_payments = days / e.days;
    this.firstForm.value.no_of_payments = Math.ceil(no_of_payments)
  }


  async getDrivers(e) {
    let drivers = await this.misc.apiCall(
      `auth`,
      `admin/drivers?search=${e.target.value}&per_page=${20}`
    );
    this.drivers = drivers['users'];
  }

  selectDriver(driver) {
    this.driver = `${driver.first_name} ${driver.last_name}`;
    this.user_id = driver.ID;
  }

  selectAsset(e) {
    this.asset = e.name;
    this.firstForm.value.asset_id = e.ID;
  }

  submitContractInfo() {
    this.firstForm.value.frequency = this.frequency;
    this.firstForm.value.user_id = this.user_id
    this.contract = this.firstForm.value
  }

  submitContract() {
    this.contracts.createContract(this.contract);    
  }
}
