import { MiscService } from './../../../services/misc/misc.service';
import { PostsService } from './../../../services/posts/posts.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from '../../../services/api.service';
import { PagerService } from '../../../services/pager.service';
import { MessagesService } from '../../../services/messages.service';
import { environment as devEnv } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit, EventEmitter } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})
export class PostComponent implements OnInit {
  displayedColumns = ['image', 'name', 'size', 'action'];

  id;
  post;
  silos;
  postproducts;

  isDischemUser = false;
  background = `var(--quenchPink)`;

  page = 1;
  limit = 10;

  mobileImageFile: { resource: string; file: any; name: string };
  desktopImageFile: { resource: string; file: any; name: string };

  constructor(
    public message: MessagesService,
    private router: Router,
    private api: ApiService,
    public pager: PagerService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public auth: AuthService,
    public posts: PostsService,
    public misc: MiscService
  ) {}

  ngOnInit(): void {
    if (
      this.auth.dis_chem_role ||
      (localStorage.getItem('dis-chem-role') &&
        localStorage.getItem('dis-chem-role') == 'true')
    ) {
      this.isDischemUser = true;
      this.background = `var(--dischemGreen)`;
    }

    this.id = this.route.snapshot.paramMap.get('id');

    this.posts.getPost(this.id);
  }

  // remove array data
  ngOnDestroy(): void {
    this.posts.postdata = [];
  }

  // ==============
  //   EDIT POST
  // ==============

  editPost() {
    const dialog = this.dialog.open(editPostDialog, {
      width: '800px',
      height: '80vh',
      data: {
        post: this.posts.post,
        isDisChemUser: this.isDischemUser,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.posts.getPost(this.id);
    });
  }

  // ========================
  //   REMOVE POST PRODUCT
  // ========================

  removePostProduct(product) {
    const dialog = this.dialog.open(removePostProductDialog, {
      width: '550px',
      height: '200px',
      data: {
        product: product,
        isDisChemUser: this.isDischemUser,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.posts.getPost(this.id);
    });
  }

  // ========================
  //   REMOVE POST PRODUCT
  // ========================

  addPostProduct() {
    const dialog = this.dialog.open(addPostProductsDialog, {
      width: '92vw',
      height: '80vh',
      data: {
        post: this.posts.post,
        isDisChemUser: this.isDischemUser,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.posts.getPost(this.id);
    });
  }

  // ========================
  //   EDIT POST IMAGES
  // ========================

  updateImages() {
    const dialog = this.dialog.open(updateImagesDialog, {
      width: '1200px',
      height: '88vh',
      data: {
        post: this.posts.post,
        isDisChemUser: this.isDischemUser,
      },
    });

    dialog.afterClosed().subscribe(() => {
      setTimeout(() => {
        this.posts.getPost(this.id);
      }, 1000);
    });
  }
}

// ==============
//   EDIT POST
// ==============
@Component({
  selector: 'delete-dialog',
  templateUrl: 'post-edit-dialog.html',
  styleUrls: [
    '../../products/product/product-detail/update-product-dialog.component.scss',
  ],
})
export class editPostDialog {
  post;
  title;
  body;
  silos;
  silo_id;

  isDisChemUser = false;
  background = `var(--quenchPink)`;

  // get section info after success
  onAdd = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { post },
    private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<editPostDialog>,
    public misc: MiscService
  ) {}

  ngOnInit(): void {
    let isDischemUser = localStorage.getItem('dis-chem-role');

    if (isDischemUser) {
      this.isDisChemUser = true;
      this.background = `var(--dischemGreen)`;
    } else {
      this.getSilos();
      this.silo_id = this.data.post.silo_id;
    }

    this.post = this.data.post;
    this.title = this.data.post.title;
    this.body = this.data.post.body;
  }

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  getSilos() {
    this.api.getList('catelog', 'silos').subscribe(
      (silos: any) => {
        this.silos = silos;
      },
      (error) => {
        this.message.showSnack(error.error);
      }
    );
  }

  updatePost() {
    let apicall = ``;

    let updatedPost = [
      {
        id: JSON.parse(this.post.id),
        title: this.title,
        body: this.body,
      },
    ];

    if (this.isDisChemUser) {
      apicall = `dischem/posts`;
    } else {
      apicall = `posts`;
      updatedPost[0]['silo_id'] = this.silo_id;
    }

    console.log(updatedPost);

    this.api.patch('catelog', `${apicall}`, updatedPost).subscribe(
      (data: any) => {
        this.message.showSnack(`Successfully updated Post ${this.post.title}`);
        this.closeDialog();
      },
      (error) => {
        this.message.showSnack(error.error);
      }
    );
  }
}

// ========================
//   REMOVE POST PRODUCT
// ========================
@Component({
  selector: 'delete-dialog',
  templateUrl: '../../../components/dialogs/delete-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class removePostProductDialog {
  inputTouch = false;
  heading = `post Product`;
  body = `Are you sure you want to remove this post product? You can always add it again later.`;

  product;

  isDisChemUser = false;
  background = `var(--quenchPink)`;

  // get section info after success
  onAdd = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { product; isDisChemUser },
    private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<removePostProductDialog>
  ) {}

  ngOnInit(): void {
    this.product = this.data.product;

    if (this.data.isDisChemUser) {
      this.isDisChemUser = true;
      this.background = `var(--dischemGreen)`;
    }
  }

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  removeItem() {
    let apicall = ``;
    if (this.data.isDisChemUser) {
      apicall = `dischem/posts/products/${this.product.post_product_id}`;
    } else {
      apicall = `postproducts/${this.product.post_product_id}`;
    }
    this.api.delete('catelog', `${apicall}`).subscribe((data: any) => {
      this.message.showSnack(`succesfully deleted post product`);
      this.closeDialog();
    });
  }
}

// ========================
//   ADD POST PRODUCT
// ========================

@Component({
  selector: 'post-addProducts-dialog',
  templateUrl: 'post-add-products.html',
  styleUrls: ['../../pocket-mall/mall-stores/store-add.component.scss'],
})
export class addPostProductsDialog {
  Columns = ['image', 'name', 'add'];

  post;
  silos;
  silo_id;
  retailers;
  searchData;
  isDischemUser = false;
  background = `var(--quenchPink)`;

  page = 1;
  limit = 10;
  search = '';
  retailer_id = 0;

  retailerSearch = '';

  // get section info after success
  onAdd = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { post; isDisChemUser },
    private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<addPostProductsDialog>,
    public misc: MiscService
  ) {}

  ngOnInit(): void {
    this.post = this.data.post;

    this.silo_id = this.data.post.silo_id;

    if (this.data.isDisChemUser) {
      this.isDischemUser = true;
      this.background = `var(--dischemGreen)`;
    }

    this.getRetailers();

    this.getProducts();
  }

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  // ========================
  //    GET ITEMS TO ADD
  // ========================

  // get products to add
  getProducts() {
    let apicall = ``;

    if (this.data.isDisChemUser) {
      apicall = `dischem/products`;
    } else {
      apicall = `products`;
    }

    this.api
      .getList(
        'catelog',
        `${apicall}?page=${this.page}&per_page=${this.limit}&retailer_id=${this.retailer_id}&search=${this.search}`
      )
      .subscribe((products: any) => {
        this.searchData = products;
      });
  }

  // =====================
  //    APPLY FILTER
  // =====================

  selectRetailer() {
    this.page = 1;
    this.getProducts();
  }

  getRetailers() {
    this.api
      .getList(
        'catelog',
        `retailers?page=${this.page}&per_page=${100}&search=${
          this.retailerSearch
        }`
      )
      .subscribe(
        (retailers: any) => {
          let all = {
            id: 0,
            name: 'All',
          };

          retailers.unshift(all);
          this.retailers = retailers;
        },
        (error) => {
          this.message.showSnack(error.error);
        }
      );
  }

  // ========================
  //  ARRAY OF ITEMS TO ADD
  // ========================

  itemsArr = [];

  toggleSelection(cat) {
    var storeRemove = this.itemsArr.map((item) => item.id).indexOf(cat.id);

    if (storeRemove !== -1) {
      this.itemsArr.splice(storeRemove, 1);
    } else if (storeRemove === -1) {
      this.itemsArr.push(cat);
    }
  }

  addProductsToPost() {
    let newItemsArr = [];

    this.itemsArr.forEach((item) => {
      const data = {
        product_id: item.id,
        post_id: this.post.id,
        position: 0,
      };

      newItemsArr.push(data);
    });

    let apicall = ``;

    if (this.isDischemUser) {
      apicall = `dischem/posts/products`;
    } else {
      apicall = `postproducts`;
    }

    this.api
      .post('catelog', `${apicall}`, newItemsArr)
      .subscribe((data: any) => {
        this.message.showSnack('succesfully added products to post');
        this.closeDialog();
      });
  }

  setLimit(limit) {
    this.limit = limit;
    this.getProducts();
  }

  start() {
    this.page = 1;
    this.getProducts();
  }

  next() {
    this.page++;
    this.getProducts();
  }

  previous() {
    if (this.page > 1) {
      this.page--;
      this.getProducts();
    }
  }
}

// ====================
//   EDIT POST IMAGES
// ====================
@Component({
  selector: 'post-images-update-dialog',
  templateUrl: 'post-images-update-dialog.html',
  styleUrls: ['post.component.scss'],
})
export class updateImagesDialog {
  image;
  desktopImage;

  isDischemUser = false;

  mobileImageFile: { resource: string; file: any; name: string };
  desktopImageFile: { resource: string; file: any; name: string };

  // get section info after success
  onAdd = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { post; isDisChemUser },
    private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<updateImagesDialog>
  ) {}

  ngOnInit(): void {
    this.image = this.data.post?.image;
    this.desktopImage = this.data.post?.desktopImage;
    this.isDischemUser = this.data.isDisChemUser;
  }

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  mobileImagePreview(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.onload = (_event: any) => {
        this.mobileImageFile = {
          resource: _event.target.result,
          file: event.srcElement.files[0],
          name: event.srcElement.files[0].name,
        };
        if (
          this.mobileImageFile.name.includes('png') ||
          this.mobileImageFile.name.includes('jpg') ||
          this.mobileImageFile.name.includes('jpeg') ||
          this.mobileImageFile.name.includes('webp') ||
          this.mobileImageFile.name.includes('JPG') ||
          this.mobileImageFile.name.includes('PNG') ||
          this.mobileImageFile.name.includes('JPEG')
        ) {
          this.mobileImageFile.name = 'image.png';
          this.image = this.mobileImageFile.resource;
        } else {
          this.message.showSnack(`oops!   that is an unsupported image type`);
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  desktopImagePreview(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.onload = (_event: any) => {
        this.desktopImageFile = {
          resource: _event.target.result,
          file: event.srcElement.files[0],
          name: event.srcElement.files[0].name,
        };
        if (
          this.desktopImageFile.name.includes('png') ||
          this.desktopImageFile.name.includes('jpg') ||
          this.desktopImageFile.name.includes('jpeg') ||
          this.desktopImageFile.name.includes('webp') ||
          this.desktopImageFile.name.includes('JPG') ||
          this.desktopImageFile.name.includes('PNG') ||
          this.desktopImageFile.name.includes('JPEG')
        ) {
          this.desktopImageFile.name = 'image.png';
          this.desktopImage = this.desktopImageFile.resource;
        } else {
          this.message.showSnack(`oops!   that is an unsupported image type`);
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  updateImages() {
    this.message.showSnack(`updating post images`);

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + this.api.token);

    var formdata = new FormData();
    formdata.append(
      'file',
      this.mobileImageFile.file,
      this.mobileImageFile.name
    );
    formdata.append(
      'body',
      '{ "resource":"posts", "resource_id":' +
        this.data.post.id +
        ',"x_dim":1, "y_dim":1}'
    );
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
    };

    // if (!devEnv.production) {
      fetch(
        `${environment.catalogue}image`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          var myHeaders = new Headers();
          myHeaders.append('Authorization', 'Bearer ' + this.api.token);

          if (this.isDischemUser) {
            setTimeout(() => {
              this.closeDialog();
            }, 5000);
          } 

          var formdata = new FormData();
          formdata.append(
            'file',
            this.desktopImageFile.file,
            this.desktopImageFile.name
          );
          formdata.append(
            'body',
            '{ "resource":"posts", "resource_id":' +
              this.data.post.id +
              ', "x_dim":1, "y_dim":1,"for_desktop":true, "image_key":' +
              result +
              '}'
          );

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
          };

          fetch(
            `${environment.catalogue}image`,
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => {
              setTimeout(() => {
                `Successfully updated Post ${this.data.post.title}`;
                this.closeDialog();
              }, 10000);
            })
            .catch((error) => console.log('error', error));
        })
        .catch((error) => console.log('error', error));
    }

}
