<div class="header">
    <h3>update user wallet</h3>
    <mat-icon class='action' (click)='closeDialog()'>clear</mat-icon>
</div>

<hr>

    <mat-form-field appearance='outline' class='field'>
        <mat-label>Amount</mat-label>
        <input matInput type="text" [(ngModel)]="amount"  #ctrl="ngModel" >
    </mat-form-field>
    <br>
    <mat-form-field appearance='outline' class='field'>
        <mat-label>Reason</mat-label>
        <input matInput type="text" [(ngModel)]="reason"  #ctrl="ngModel" >
    </mat-form-field>



<div class="btn">
    <button mat-flat-button (click)='updateWalletAmount("deduct")' class='wallet-button'>deduct</button>
    <button mat-flat-button class='button wallet-button' (click)='updateWalletAmount("add")' >add</button>
</div>