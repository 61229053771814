<div class="header">
  <h3>Cancel Order With Reason</h3>
  <mat-icon (click)="closeDialog()" class="action">clear</mat-icon>
</div>
<hr />

<form #reason="ngForm" (ngSubmit)="cancelOrderWithReason(reason.value)">
  <div class="cancel-reasons">
    <ol type="1">
      <li
        (click)="selectReason(reason)"
        *ngFor="let reason of cancel_order_reasons"
        [style.background]="chosenReason == reason  ? '#dfdbdb' : '' "
      >
        {{reason}}
      </li>
    </ol>
    <mat-form-field
      class="field"
      appearance="outline"
      *ngIf="chosenReason == 'other'"
    >
      <mat-label>Please give more details</mat-label>
      <textarea
        ngModel
        type="text"
        matInput
        placeholder="driver had an accident"
        name="other_reason"
        required
      ></textarea>
    </mat-form-field>
  </div>

  <div class="save">
    <button mat-flat-button class="button" type="submit">Confirm</button>
  </div>
</form>
