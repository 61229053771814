import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ZonesService {
  cities = [
    {
      "city": "Johannesburg", 
      "lat": "-26.2044", 
      "lng": "28.0416"
    }, 
    {
      "city": "Vereeniging", 
      "lat": "-26.6736", 
      "lng": "27.9319"
    }, 
    {
      "city": "Pietermaritzburg", 
      "lat": "-29.5833", 
      "lng": "30.4167"
    }, 
    {
      "city": "Pretoria", 
      "lat": "-25.7464", 
      "lng": "28.1881"
    }, 
    {
      "city": "Durban", 
      "lat": "-29.8583", 
      "lng": "31.0250"
    }, 
    {
      "city": "Cape Town", 
      "lat": "-33.9250", 
      "lng": "18.4250"
    }, 
    {
      "city": "Welkom", 
      "lat": "-27.9831", 
      "lng": "26.7208"
    }, 
    {
      "city": "East London", 
      "lat": "-33.0153", 
      "lng": "27.9116"
    }, 
    {
      "city": "Randburg", 
      "lat": "-26.0936", 
      "lng": "28.0064"
    }, 
    {
      "city": "Roodepoort", 
      "lat": "-26.1625", 
      "lng": "27.8725"
    }, 
    {
      "city": "Port Elizabeth", 
      "lat": "-33.9581", 
      "lng": "25.6000"
    }, 
    {
      "city": "Bloemfontein", 
      "lat": "-29.1000", 
      "lng": "26.2167"
    }, 
    {
      "city": "Centurion", 
      "lat": "-25.8603", 
      "lng": "28.1894"
    }, 
    {
      "city": "Springs", 
      "lat": "-26.2547", 
      "lng": "28.4428"
    }, 
    {
      "city": "Sandton", 
      "lat": "-26.1070", 
      "lng": "28.0517" 
    }, 
    {
      "city": "Polokwane", 
      "lat": "-23.9000", 
      "lng": "29.4500"
    }, 
    {
      "city": "Klerksdorp", 
      "lat": "-26.8667", 
      "lng": "26.6667"
    }, 
    {
      "city": "Rustenburg", 
      "lat": "-25.6667", 
      "lng": "27.2336"
    }, 
    {
      "city": "Kimberley", 
      "lat": "-28.7386", 
      "lng": "24.7586"
    }, 
    {
      "city": "Bhisho", 
      "lat": "-32.8494", 
      "lng": "27.4381"
    }, 
    {
      "city": "Benoni", 
      "lat": "-26.1883", 
      "lng": "28.3206"
    }, 
    {
      "city": "George", 
      "lat": "-33.9667", 
      "lng": "22.4500"
    }, 
    {
      "city": "Middelburg", 
      "lat": "-25.7684", 
      "lng": "29.4783"
    }, 
    {
      "city": "Vryheid", 
      "lat": "-27.7694", 
      "lng": "30.7914"
    }, 
    {
      "city": "Potchefstroom", 
      "lat": "-26.7150", 
      "lng": "27.1033"
    }, 
    {
      "city": "Umtata", 
      "lat": "-31.5800", 
      "lng": "28.7900"
    }, 
    {
      "city": "Brits", 
      "lat": "-25.6167", 
      "lng": "27.7667"
    }, 
    {
      "city": "Alberton", 
      "lat": "-26.2672", 
      "lng": "28.1219"
    }, 
    {
      "city": "Upington", 
      "lat": "-28.4572", 
      "lng": "21.2425"
    }, 
    {
      "city": "Paarl", 
      "lat": "-33.7242", 
      "lng": "18.9558"
    }, 
    {
      "city": "Queenstown", 
      "lat": "-31.9000", 
      "lng": "26.8833"
    }, 
    {
      "city": "Mmabatho", 
      "lat": "-25.8500", 
      "lng": "25.6333"
    }, 
    {
      "city": "Kroonstad", 
      "lat": "-27.6456", 
      "lng": "27.2317"
    }, 
    {
      "city": "Uitenhage", 
      "lat": "-33.7500", 
      "lng": "25.4000"
    }, 
    {
      "city": "Bethal", 
      "lat": "-26.4500", 
      "lng": "29.4500"
    }, 
    {
      "city": "Worcester", 
      "lat": "-33.6450", 
      "lng": "19.4436"
    }, 
    {
      "city": "Vanderbijlpark", 
      "lat": "-26.6992", 
      "lng": "27.8356"
    }, 
    {
      "city": "Grahamstown", 
      "lat": "-33.2996", 
      "lng": "26.5200"
    }, 
    {
      "city": "Standerton", 
      "lat": "-26.9500", 
      "lng": "29.2500"
    }, 
    {
      "city": "Brakpan", 
      "lat": "-26.2353", 
      "lng": "28.3700"
    }, 
    {
      "city": "Thohoyandou", 
      "lat": "-22.9500", 
      "lng": "30.4833"
    }, 
    {
      "city": "Saldanha", 
      "lat": "-32.9978", 
      "lng": "17.9456"
    }, 
    {
      "city": "Tzaneen", 
      "lat": "-23.8333", 
      "lng": "30.1667"
    }, 
    {
      "city": "Graaff-Reinet", 
      "lat": "-32.2522", 
      "lng": "24.5406"
    }, 
    {
      "city": "Oudtshoorn", 
      "lat": "-33.5833", 
      "lng": "22.2000"
    }, 
    {
      "city": "Mossel Bay", 
      "lat": "-34.1833", 
      "lng": "22.1333"
    }, 
    {
      "city": "Port Shepstone", 
      "lat": "-30.7411", 
      "lng": "30.4547"
    }, 
    {
      "city": "Knysna", 
      "lat": "-34.0356", 
      "lng": "23.0489"
    }, 
    {
      "city": "Vryburg", 
      "lat": "-26.9500", 
      "lng": "24.7333"
    }, 
    {
      "city": "Ladysmith", 
      "lat": "-28.5539", 
      "lng": "29.7825"
    }, 
    {
      "city": "Kuilsrivier", 
      "lat": "-34.0333", 
      "lng": "18.7000"
    }, 
    {
      "city": "Beaufort West", 
      "lat": "-32.3500", 
      "lng": "22.5833"
    }, 
    {
      "city": "Aliwal North", 
      "lat": "-30.7000", 
      "lng": "26.7000"
    }, 
    {
      "city": "Volksrust", 
      "lat": "-27.3667", 
      "lng": "29.8833"
    }, 
    {
      "city": "Musina", 
      "lat": "-22.3381", 
      "lng": "30.0417"
    }, 
    {
      "city": "Vredenburg", 
      "lat": "-32.9000", 
      "lng": "17.9833"
    }, 
    {
      "city": "Malmesbury", 
      "lat": "-33.4500", 
      "lng": "18.7333"
    }, 
    {
      "city": "Lebowakgomo", 
      "lat": "-24.3050", 
      "lng": "29.5650"
    }, 
    {
      "city": "Cradock", 
      "lat": "-32.1833", 
      "lng": "25.6167"
    }, 
    {
      "city": "De Aar", 
      "lat": "-30.6500", 
      "lng": "24.0167"
    }, 
    {
      "city": "Ulundi", 
      "lat": "-28.3350", 
      "lng": "31.4161"
    }, 
    {
      "city": "Jeffreys Bay", 
      "lat": "-34.0333", 
      "lng": "24.9167"
    }, 
    {
      "city": "Lichtenburg", 
      "lat": "-26.1500", 
      "lng": "26.1667"
    }, 
    {
      "city": "Hermanus", 
      "lat": "-34.4167", 
      "lng": "19.3000"
    }, 
    {
      "city": "Carletonville", 
      "lat": "-26.3581", 
      "lng": "27.3981"
    }, 
    {
      "city": "Mahikeng", 
      "lat": "-25.8653", 
      "lng": "25.6442"
    }, 
    {
      "city": "Nelspruit", 
      "lat": "-25.4745", 
      "lng": "30.9703"
    }
  ]

  places = [
    {
      name: "All",
      lat: -29.630621,
      lng: 24.088321
    },
    {
      name: "Cape Town",
      lat: -33.9321,
      lng: 18.8602
    },
    {
      name: "Garden Route",
      lat:  -34.017430,
      lng:  22.807603
    },
    {
      name: "KZN",
      lat: -29.547945,
      lng: 31.135022
    },
    {
      name: "Gauteng",
      lat: -25.960000,
      lng: 28.130000
    }
  ]
  
  geo;
  city;
  polygon
  address; 
  placeName; 
  selectedPlace;
  
  zones = []
  markers = [];
  polygons = []
  newPolygon = []
  pointList    = []
  filteredCities = []
  
  edit = false;
  loading = true;
  toggleAdd = false;
  addingZone = false;
  mapsLoaded = false;
  editAreaToggle = false;

  drawingManager: any;
  selectedShape: any;

  selectedArea = ''

  lat: number
  lng: number
  zoom: number = 5
  token = localStorage.getItem("AUTH_TOKEN")
  headers = {
    'Authorization': 'Bearer '+ this.token,
    'Content-Type': 'application/json',
    'x-api-key':  environment.apiKey
  }

  constructor(
    private httpService: HttpClient
  ) { 

  }

  async getZones(){
    let url = `${environment.drive}admin/zones/`
    return await this.httpService.request(`GET`, url, { headers: this.headers }).toPromise();
  }

  // async getUserforZone(){
  //   let url = `${environment.authentication}user/profile`
  //   return await this.httpService.request(`GET`, url, { headers: this.headers }).toPromise();
  // }

  async updateZone(zone:any){
    let id = zone.id
    let url = `${environment.drive}admin/zones/${id}`
    return await this.httpService.request(`PATCH`, url, { body: zone, headers: this.headers }).toPromise();
  }

  async saveZone(newZones:any, city:any, area:any){
    let zones = []
    let zone = {
      "city": city,
      "area" : area,
      "geopoints": newZones
    }
    localStorage.setItem('activeZone', JSON.stringify(zone))
    zones.push(zone);
    let url = `${environment.drive}admin/zones`
    return await this.httpService.request(`POST`, url, { body: zones, headers: this.headers }).toPromise();
  }
  async deleteZone(zoneId:any){
    let url = `${environment.drive}admin/zones/${zoneId}`
    return await this.httpService.request(`DELETE`, url, { headers: this.headers }).toPromise();
  }
}
