<div class="header">
  <h3>Update Reason</h3>
  <mat-icon (click)="closeDialog()" class="action">clear</mat-icon>
</div>
<hr />

<form #reason="ngForm" (ngSubmit)="updateReason(reason.value)">
  <mat-form-field appearance="outline" class="field">
    <mat-label>partner name</mat-label>
    <input
      type="text"
      matInput
      [(ngModel)]="name"
      placeholder="John Smith"
      name="name"
      required
    />
  </mat-form-field>
  <mat-form-field appearance="outline" class="field">
    <mat-label>partner callback_url</mat-label>
    <input
      type="text"
      matInput
      [(ngModel)]="callback_url"
      placeholder="https://calbackurl.com"
      name="callback_url"
      required
    />
  </mat-form-field>
  <mat-form-field appearance="outline" class="field">
    <mat-label>partner max_deliver_distance</mat-label>
    <input
      type="Number"
      matInput
      [(ngModel)]="max_delivery_distance"
      placeholder="3287"
      name="max_delivery_distance"
      required
      minlength="1"
      maxlength="5"
    />
  </mat-form-field>

  <div class="save">
    <button mat-flat-button class="button">submit</button>
  </div>
</form>
