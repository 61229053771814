import { Component, OnInit } from '@angular/core'
import { ApiService } from 'src/app/services/api.service'
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop'
import { MessagesService } from 'src/app/services/messages.service'
import { AuthService } from 'src/app/services/auth.service'


@Component({
  selector: 'app-silos',
  templateUrl: './silos.component.html',
  styleUrls: ['./silos.component.scss']
})
export class SilosComponent implements OnInit {
  Columns = ['sort', 'id', 'name', 'tag', 'active', 'edit']

  silo
  silos

  edit    = false
  page    = 1
  limit   = 10
  active  = false
  retries = 0

  constructor(public api: ApiService,
              public message: MessagesService,
              public auth: AuthService) { }

  ngOnInit(): void {
    this.getSilos()
  }

// =================
//     TOGGLE
// =================

  toggleEdit(silo){
    if(this.edit){
      this.getSilos()
    }
    this.edit = !this.edit
    this.silo = silo
  }


  toggleSiloStatus(silo){
    let updatedSilo = {
      id: silo.id,
      active: silo.active
    }

    this.api.patch(`catelog`, `silos`, updatedSilo).subscribe((silo: any) => {
      if(silo.active){
      this.message.showSnack(`Succesfully activated ${silo.name}`);
      } else {
        this.message.showSnack(`Succesfully de-activated ${silo.name}`);
      }
      this.getSilos();
    }, error =>{
      this.message.showSnack(error.error);
     })
    }

// =================
//     SILOS
// =================

  getSilos(){
    this.api.getList('catelog', `silos`).subscribe((silos: any) => {
      this.silos = silos
    }, error => {
      this.message.showSnack(error.error)
      if(this.retries < 3 && error.status !== 401){
        this.retries++
        this.getSilos()
      }
    })
  }

  updateSilo(){
    let silo = {
      id: this.silo.id,
      name: this.silo.name,
      tag: this.silo.tag
    }

    this.api.patch(`catelog`, `silos`, silo).subscribe((silo: any) => {
      this.message.showSnack(`Successfully updated ${silo.name}`);
      this.toggleEdit(silo);
    }, error => {
      this.message.showSnack(error.error);
    })
  }

// =================
//   DRAG AND DROP
// =================

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.silos, event.previousIndex, event.currentIndex);

    var list=[]
    this.silos.forEach(post=>{
          list.push(post.id)
          console.log(list)
    });
    this.api.patch("catelog","sort", {
      "table":"silos",
      "ids":list
    }).subscribe(data=>{
     this.message.showSnack('Updating Silos')
      this.getSilos();
    },error=>{ 
      console.log(error)
    });  
  }

  setLimit(limit){}
  next(){}
  previous(){}
}
