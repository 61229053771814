  <!-- ------------- -->
  <!--    SEARCH     -->
  <!-- ------------- -->

  <div class="search">
    <mat-icon (click)='getStoreOrders()' matTooltip='search' class='search-icon material-icons-outlined' [style.left]="inputTouch ? '-20px' : '10px' ">search</mat-icon>
    <mat-icon *ngIf='search' (click)='clearSearch()'  matTooltip='clear' class='clear-icon' >clear</mat-icon>
    <input matInput (keydown.enter)='getStoreOrders()' [(ngModel)]="search" placeholder="Search"  (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
  </div>
    
  
    <!-- ----------------- -->
    <!--      orders        -->
    <!-- ----------------- -->
      <table mat-table [dataSource]='store_orders' matSort>
  
          <ng-container matColumnDef="placed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Placed</th>
          <td mat-cell *matCellDef="let order" >
            <P *ngIf='order.order_date'>{{order.order_date.substring(0,10) }}</P> 
            <P *ngIf='order.order_date' class='order-time'>{{order.order_date.substring(14,19) }}</P> 
            <P *ngIf='!order.order_date'>No Order Date</P> 
          </td>
        </ng-container>
  
        <ng-container matColumnDef="store">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Store</th>
          <td mat-cell *matCellDef="let order">
            <p *ngIf='order.store_name'>{{order.store_name}}</p>
            <p *ngIf='!order.store_name'>No Store Name</p>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="customer">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
          <td mat-cell *matCellDef="let order">
              <p *ngIf='order.customer_name' matTooltip='{{order.uid}}'>{{order.customer_name}}</p>
              <p *ngIf='!order.customer_name'>No Customer Name</p>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="order_num">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Number</th>
          <td mat-cell *matCellDef="let order"> 
            <p *ngIf='order.order_number'>{{order.order_number}}</p>
            <p *ngIf='!order.order_number'>No Order Number</p>
          </td>
        </ng-container>
  
        <!-- ASSIGN COLORS TO DIFFERENT STATUSES -->
          <ng-container matColumnDef="status" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let order" >
              <p *ngIf="order.status && order.status == 'PLACED' "          id='placed-status'       class='status'> {{order.status}}</p>
              <p *ngIf="order.status && order.status == 'PICKING' "         id='picking-status'       class='status'> {{order.status}}</p>
              <p *ngIf="order.status && order.status == 'OPEN' "            id='open-status'         class='status'> {{order.status}}</p>
              <p *ngIf="order.status && order.status == 'CANCELLED' "       id='cancelled-status'    class='status'> {{order.status}}</p>
              <p *ngIf="order.status && order.status == 'PAID' "            id='paid-status'         class='status'> {{order.status}}</p>
              <p *ngIf="order.status && order.status == 'DISPATCHED' "      id='dispatched-status'   class='status'> {{order.status}}</p>
              <p *ngIf="order.status && order.status == 'FAILED' "          id='cancelled-status'    class='status'> {{order.status}}</p>
              <p *ngIf="order.status && order.status == 'REPLACE' "         id='cancelled-status'    class='status'> {{order.status}}</p>
              <p *ngIf="order.status && order.status == 'COMPLETED' "       id='completed-status'   class='status'> {{order.status}}</p>
              <p *ngIf='!order.status'> No Status</p>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class='text-align'>Phone</th>
            <td mat-cell *matCellDef="let order" class='text-align'> 
              <p *ngIf='order.customer_phone'>{{order.customer_phone}}</p>
              <p *ngIf='!order.customer_phone'>No Phone Number</p>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="origin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Origin</th>
            <td mat-cell *matCellDef="let order"> 
              <p *ngIf='order.origin'>{{order.origin}}</p>
              <p *ngIf='!order.origin'>Origin not recorded</p>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="distance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class='text-align'>Distance</th>
            <td mat-cell *matCellDef="let order" class='text-align'>
              <p *ngIf='order.distance'> {{order.distance }}m</p> 
              <p *ngIf='!order.distance'>No Distance</p> 
            </td>
          </ng-container>
  
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
            <td mat-cell *matCellDef="let order" class='amount'>
              <p *ngIf='order.amount'> R {{order.amount | number:"1.2-2" }}</p> 
              <p *ngIf='!order.amount'>No Amount</p> 
            </td>
          </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="Columns"></tr>
        <tr mat-row class='action' 
            routerLink='/cart/{{order.cart_id}}' 
            *matRowDef="let order; columns: Columns;"       
            [ngClass]="order.status == 'DISPATCHED' ? 'dispatched' : '' "
        ></tr>
      </table>
  
    <div id="pager" >
      <div class="page"> 
  
  
      <div> Per page:</div> 
      <span class="silo-filter pagination">
        <button mat-button [matMenuTriggerFor]="paginate">
          <span>{{limit}}</span>
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #paginate="matMenu" >
          <button mat-menu-item (click)="setLimit(10)">10</button>
          <button mat-menu-item (click)="setLimit(20)">20</button>
          <button mat-menu-item (click)="setLimit(50)">50</button>
        </mat-menu>
      </span>
  
      <div (click)='start()' matTooltip="Skip to start" class='action' *ngIf='page > 1'>back to start</div>
      <p (click)='previous()' class='action'>previous</p>
      <div>{{page}}</div>
        <p (click)='next()' class='action'>next</p>
        <div> </div>
      </div>
    </div>
  
    <!-- ------------------- -->
    <!--    MOBILE orderS     -->
    <!-- ------------------- -->
  
    <div class='mobile'>
      <mat-expansion-panel hideToggle  *ngFor='let order of store_orders '>
        <mat-expansion-panel-header >
          <mat-panel-title id='mat-title-content'>
            <p id='name'>{{order.customer_name}}  </p> 
            <p class='order-number-mobile'>{{order.order_number}}</p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span class="flex">
          <mat-title class='matTitle'>Placed:</mat-title>
          <p>{{order.order_date}}</p>
        </span>
        <span class="flex">
          <mat-title class='matTitle'>Store:</mat-title>
          <p>{{order.store_name}}</p>
        </span>
        <span class="flex">
          <mat-title class='matTitle'>Status:</mat-title>
          <p *ngIf="order.status && order.status == 'PLACED' "     id='placed-status'       class='status'> {{order.status}}</p>
          <p *ngIf="order.status && order.status == 'OPEN' "       id='open-status'         class='status'> {{order.status}}</p>
          <p *ngIf="order.status && order.status == 'CANCELLED' "  id='cancelled-status'    class='status'> {{order.status}}</p>
          <p *ngIf="order.status && order.status == 'PAID' "       id='paid-status'         class='status'> {{order.status}}</p>
          <p *ngIf="order.status && order.status == 'DISPATCHED' " id='dispatched-status'   class='status'> {{order.status}}</p>
          <p *ngIf='!order.status'> No Status</p>
        </span>
          <span class="flex">
            <mat-title class='matTitle'>origin:</mat-title>
            <p *ngIf='order.origin'>{{order.origin}}</p>
            <p *ngIf='!order.origin'>Origin not recorded</p>
          </span>
          <span class="flex">
            <mat-title class='matTitle'>Phone:</mat-title>
            <p>	{{order.customer_phone}}</p>
          </span>
          <span class="flex">
            <mat-title class='matTitle'>Distance:</mat-title>
            <p>	{{order.distance_text}}</p>
          </span>
          <span class="flex">
            <mat-title class='matTitle'>Amount:</mat-title>
            <p>	R {{order.amount | number:"1.2-2" }}</p>
          </span>
          <div class="view-button">
            <button mat-flat-button class='button'  routerLink='/order/{{order.ID}}'>view detail</button>
          </div>
        </mat-expansion-panel>
    </div>
  
  
  