import { ActivatedRoute } from '@angular/router';
import { PaymentsService } from 'src/app/services/payments/payments.service';
import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-list-payment-batch',
  templateUrl: './list-payment-batch.component.html',
  styleUrls: ['./list-payment-batch.component.scss']
})
export class ListPaymentBatchComponent implements OnInit {
  Columns = [
    'date',
    'user',
    'status',
    'payment_method',
    'account',
    'account_number',
    'bank',
    'branch_code',
    'mobile',
    'amount'
  ];


  constructor(public payments: PaymentsService,
              public route: ActivatedRoute,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id')
    this.payments.getPaymentBatch(id)
  }

  viewBankDetails(driver) {
    const dialog = this.dialog.open(viewBankDetailsDialog, {
      width: '50vw',
      height: '40vh',
      data: {
        driver: driver
      },
    });
  }
}

@Component({
  selector: 'bank-details-dialog',
  templateUrl: './bank-details-dialog/bank-details-dialog.html',
  styleUrls: ['./bank-details-dialog/bank-details-dialog.scss'],
})

export class viewBankDetailsDialog {
  inputTouch = false;
  heading = `section`;
  body = `Are you sure you want to delete this section?`;

  driver;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { driver },
    public dialogRef: MatDialogRef<viewBankDetailsDialog>,
  ) {}

  ngOnInit(): void {
    this.driver = this.data.driver
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
