<div class="header">
    <h3>Delete {{heading}}</h3>
    <mat-icon class='action' (click)='closeDialog()'>clear</mat-icon>
</div>

<hr>

<p>{{body}}</p>


<div class="btn">
    <button mat-flat-button (click)='closeDialog()'>cancel</button>
    <button mat-flat-button class='button' (click)='removeItem()'>delete</button>
</div>