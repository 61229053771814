<div class="header">
    <h3>update user roles</h3>
    <mat-icon class='action' (click)='closeDialog()'>clear</mat-icon>
</div>
<hr>


<div class="roles action" *ngFor='let role of allRoles' [ngClass]="role.name == selectedName ? 'selected' : '' " (click)='chooseRole(role)'>
    <mat-icon class='true' >people</mat-icon>
    <p >{{role.name}}</p>
</div>

<span class='tag'>*please select the role that you want to add or remove</span>
<div class="btn">
    <button mat-raised-button class='button' [disabled]="selectedName == '' ? true : false" (click)='updateRoles()'>
        <span *ngIf='addRole; else removeRole'>Add Role</span>
        <ng-template #removeRole><span>Remove Role</span></ng-template>
    </button> 

</div>