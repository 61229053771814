import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-user-orders',
  templateUrl: './user-orders.component.html',
  styleUrls: ['./user-orders.component.scss']
})
export class UserOrdersComponent implements OnInit {
  Columns = ['placed', 'number', 'store', 'origin', 'amount', 'status']

  id
  orders
  page = 1
  limit = 10
  retries = 0

  constructor(public api: ApiService,
              public message: MessagesService,
              public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getOrders()
  }

  getOrders(){
    this.api.getList('fulfilment', `admin/users/${this.id}/orders?page=${this.page}&per_page=${this.limit}`)
    .subscribe((orders: any) => {
      this.orders = orders.data
    }, error => {
      this.message.showSnack(error.error)
      if(this.retries < 3){
        if(error.status !== 401){
          this.retries++
          this.getOrders()
        }
      }
    })
  }

  

  setLimit(limit){
    this.limit = limit
    this.getOrders()
  }
  next(){
    this.page++
    this.getOrders()
  }
  previous(){
    if(this.page > 1){
      this.page--
      this.getOrders()
    }
  }

}
