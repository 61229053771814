<div class="back action" routerLink="/earnings">
  <mat-icon>arrow_back</mat-icon>
  <p>earnings</p>
</div>

<div class="create-card">
  <div class="header">
    <h3>Capture manual earning</h3>
    <mat-icon routerLink="/payments">clear</mat-icon>
  </div>

  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="firstForm">
      <form [formGroup]="firstForm">
        <mat-form-field appearance="outline" class="field">
          <mat-label>Driver name</mat-label>
          <input
            type="string"
            matInput
            [(ngModel)]="driver"
            placeholder="John Doe"
            name="user_id"
            required
            formControlName="user_id"
            [matAutocomplete]="auto"
            (input)="getDrivers($event)"
          />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option
              *ngFor="let driver of drivers"
              [value]="driver"
              (click)="selectDriver(driver)"
            >
              <div class="driver-dropdown-info">
                <span> {{ driver.first_name }} {{ driver.last_name }} </span>
                <span> {{ driver.email }} </span>
                <span class="driver-contact"> {{ driver.phone }} </span>
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="outline" class="field">
          <mat-label>payment description </mat-label>
          <input
            type="string"
            matInput
            ngModel
            placeholder="Delivery from Woolwoths Cape Town "
            name="description"
            formControlName="description"
          />
          <mat-error
            *ngIf="
              firstForm.controls['description'].invalid &&
              (firstForm.controls['description'].dirty ||
                firstForm.controls['description'].touched)
            "
            >Please enter a payment description
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="field">
          <mat-label>payment amount </mat-label>
          <input
            type="number"
            matInput
            ngModel
            placeholder="350"
            name="amount"
            formControlName="amount"
          />
          <span matPrefix class="code">zar </span>
          <mat-error
            *ngIf="
              firstForm.controls['amount'].invalid &&
              (firstForm.controls['amount'].dirty ||
                firstForm.controls['amount'].touched)
            "
            >Please enter a payment amount
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="field">
          <mat-label>reason for manual capture</mat-label>
          <mat-select
            ngModel
            name="asset_type_id"
            class="select-panel"
            (selectionChange)="selectReason($event.value)"
            formControlName="reason_id"
          >
            <mat-select-trigger class="selection">
              &nbsp;{{ reason }}
            </mat-select-trigger>
            <mat-option
              *ngFor="let reason of reasons.reasons"
              [value]="reason.name"
            >
              {{ reason.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="field">
          <mat-label>Order reference</mat-label>
          <input
            type="string"
            matInput
            [(ngModel)]="ord.order_number"
            placeholder="1653464097029182"
            name="order_id"
            formControlName="order_id"
            [matAutocomplete]="order"
            (input)="getOrders($event)"
          />
          <mat-autocomplete autoActiveFirstOption #order="matAutocomplete">
            <mat-option
              *ngFor="let order of orders"
              [value]="order.ID"
              (click)="selectOrder(order)"
            >
              <div class="order">
                <span>{{ order.order_number }} </span
                ><span>{{ order.customer_name }} </span>
                <span>{{ order.CreatedAt.substring(0, 10) }} </span
                ><span class="amount">
                  {{ order.amount | currency: "R ":"code" }}</span
                >
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <div class="next">
          <button
            mat-flat-button
            class="button"
            matStepperNext
            (click)="submitEarningInfo()"
          >
            Next
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step>
      <div class="driver-overview">
        <h5>Review driver details</h5>

        <p *ngIf="earning">
          <span>Driver</span>
          <span class="confirm-detail">{{ earning.user_id }}</span>
        </p>

        <p *ngIf="earning">
          <span>Description</span>
          <span class="confirm-detail">{{ earning.description }}</span>
        </p>

        <p *ngIf="earning">
          <span>Amount</span>
          <span class="confirm-detail">{{ earning.amount }}</span>
        </p>

        <p *ngIf="earning">
          <span>reason</span>
          <span class="confirm-detail">{{ earning.reason_id }}</span>
        </p>

        <p *ngIf="earning && earning.order_id !== ''">
          <span>order reference</span>
          <span class="confirm-detail">{{ ord.order_number }}</span>
        </p>
      </div>

      <div>
        <div class="back save">
          <button mat-flat-button class="button" matStepperPrevious>
            Back
          </button>
        </div>
        <div class="save">
          <button
            mat-flat-button
            class="button"
            matStepperNext
            (click)="submitEarning()"
          >
            Save
          </button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
