import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MessagesService } from 'src/app/services/messages.service';
import { ApiService } from '../../../services/api.service';
import { PagerService } from '../../../services/pager.service';

@Component({
  selector: 'app-storelist',
  templateUrl: './storelist.component.html',
  styleUrls: ['./storelist.component.scss']
})
export class StorelistComponent implements OnInit {
  Columns = ['name', 'address', 'email', 'phone', 'active']

  inputTouch = false

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }

  silo
  silos
  page    = 1
  limit   = 10
  stores  = []
  retries = 0
  search  = ''
  active  = false


  constructor(
    private api: ApiService,
    public pager:PagerService,
    public message: MessagesService,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    if(localStorage.getItem('storesPage')){
      this.page = JSON.parse(localStorage.getItem('storesPage'))
    }

    if(localStorage.getItem('limit')){
      this.limit = JSON.parse(localStorage.getItem('limit'))
    }

    this.pager.resource = 'Stores'

    this.getSilos()

  }

  // =================
  //      SILOS
  // =================

  getSilos(){
    return new Promise (resolve => {
      this.api.getList("catelog","silos").subscribe((silos:any)=>{
      this.silos = silos
       
      // set current silo
      if(localStorage.getItem('storesTab') && localStorage.getItem('storeSearch')){
          this.silo = JSON.parse(localStorage.getItem('storesTab'))
          this.search = localStorage.getItem('storeSearch')
          this.getStores()

      } else if(localStorage.getItem('storesTab')){
          this.silo = JSON.parse(localStorage.getItem('storesTab'))
          this.getStores()
      } else if(this.silo == undefined){
        this.silo = silos[0]
        localStorage.setItem('storesTab', JSON.stringify(this.silo))
        this.getStores()
      }
      resolve('success')
    })
    })
  }

  setSilo(asilo){
    this.silo = asilo
    localStorage.removeItem('storeSearch')
    this.search = ''
    this.getSilos()
    localStorage.setItem('storesTab', JSON.stringify(asilo))
    this.page = 1
    localStorage.setItem('storesPage', JSON.stringify(this.page));
  }

  // =================
  //      STORES
  // =================

 getStores(){
  localStorage.setItem('storeSearch', this.search)
  this.api.getList('catelog', `stores?silo_id=${this.silo.id}&page=${this.page}&per_page=${this.limit}&search=${this.search}`)
  .subscribe((stores:any) =>{
      this.stores = stores
    }, error => {
      this.message.showSnack(error.error)
      if(this.retries <3){
        if(error.status !== 401){
        this.retries++
        this.getStores()
        }
      }
    })
  }

  searchReset(){
    this.page = 1
    this.getStores()
    localStorage.setItem('storesPage', JSON.stringify(this.page))
  }


  clearSearch(){
    this.page = 1
    this.search = ''
    this.getStores()
    localStorage.setItem('storesPage', JSON.stringify(this.page))
  }


  toggleStoreStatus(store){
    let updatedStore = {
      id: store.id,
      active: store.active
    }

    this.api.patch('catelog', `stores`, updatedStore).subscribe((store: any) => {
      if(store.active == true){
        this.message.showSnack('Succesfully Activated Store')
      } else {
        this.message.showSnack('Successfully De-activated Store')
      }
      this.getStores()
    } , error => {
      this.message.showSnack(error.error);
    })
  }

  // =================
  //   PAGINATION
  // =================

  setLimit(limit){
    this.limit = limit
    this.getStores()
    localStorage.setItem('limit', JSON.stringify(limit))
  }

  next(){
    if(this.stores.length < this.limit){
      this.message.showSnack('no more stores to show')
    } else {
    if(this.stores.length !== 0){
    this.page++
      this.getStores()
  }
}
  localStorage.setItem('storesPage', JSON.stringify(this.page));
  }

  previous(){
      if( this.page > 1){
        this.page--;
        this.getStores()
    }
    localStorage.setItem('storesPage', JSON.stringify(this.page));
  }

  start(){
    this.page = 1;
    this.getStores()
    localStorage.setItem('storesPage', JSON.stringify(this.page));

  }

}
