import { AuthService } from 'src/app/services/auth.service';
import { MessagesService } from '.././../../../services/messages.service'
import { Component, Inject, OnInit, EventEmitter  } from '@angular/core'
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


import { ApiService } from '../../../../services/api.service'
import { PagerService } from '../../../../services/pager.service'
import { Router ,ActivatedRoute} from '@angular/router'
import { environment as devEnv } from '../../../../../environments/environment'
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop'


@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  // displayedColumns: string[] = ['image', 'name', 'price', 'action']

  menus
  currentMenu  = 'products'

  selectMenu(menu){
    this.currentMenu = menu
    localStorage.setItem('category_menu', menu)
  }

  id
  name
  item
  silo
  silos
  image
  parent
  category
  linked_key
  category_id
  subCategory

  add          = false
  edit         = false
  page         = 1
  data         = []
  items        = []
  shade        = false
  limit        = 10
  search       = ''
  retries      = 0

  imageFile: {resource: string, file: any, name: string}

  categoryStores = false

  constructor(
    public pager:PagerService,
    private api:ApiService,
    private route:ActivatedRoute,
    public message: MessagesService,
    public router: Router,
    public auth: AuthService,
    public dialog: MatDialog
    ) { }

  ngOnInit(): void {
    if(localStorage.getItem('category_menu')){
      this.currentMenu = localStorage.getItem('category_menu')
    }
    // navigate to same url with different params
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false
    }

    if(localStorage.getItem('limit')){
      this.limit = JSON.parse(localStorage.getItem('limit'))
    }
    
      this.id = this.route.snapshot.paramMap.get('id')
      this.getCategory()
      this.getSubCategories()
  }



  // activate or deactivate category

  toggleCategory(category){
    let cat = [
      {
        id: category.id,
        active: category.active
      }
    ]

    this.api.patch(`catelog`, `categories`, cat).subscribe((category: any) => {
      if(category[0].active){
      this.message.showSnack(`Successfully activated ${category[0].name}`)
      } else {
      this.message.showSnack(`Successfully deactivated ${category[0].name}`)
      }
    }, error => {
      this.message.showSnack(error.error)
    })
  }

// ---------------
//    SILOS
// ---------------- 

getSilos(){
  this.api.getList('catelog', 'silos').subscribe((silos: any) => {
    silos.forEach(silo => {
      if(silo.id == this.category.silo_id)
      this.silo = silo.name
    })

  }, error => {
    this.message.showSnack(error.error)
  })
}

// ----------------
//    CATEGORY
// ---------------- 

  getCategory(){
    this.api.getItem("catelog","categories",this.id).subscribe((data:any)=>{

      if(data.silo_id == 5){
        this.menus = ['stores']
      }
      else {
        this.menus = ['products', 'subcategories']
      }
      this.category = data
      this.linked_key = data.linked_key
      this.name = data.name
      this.silo = data.silo_id
      this.image = data.image
      if(data.parent_id > 0){
        this.getParentName()
      }

      if(this.category.silo_id == 5 ){
        this.categoryStores = true
      }

      this.getSilos()

  
    }, error => {
      this.message.showSnack(error.error)
      if(this.retries < 3){
        if(error.status !== 401){
          this.retries++
          this.getCategory()
        }
      }
    })
  }

  getParentName(){
    this.api.get(`catelog`, `categories`, this.category.parent_id).subscribe((parent: any) => {
      this.parent = parent.name
    })
  }

// =================
//   EDIT CATEGORY 
// =================

  editCategory(){
    const dialog = this.dialog.open(editCategoryDialog, {
      width: '800px',
      height: 'calc(100vh - 300px)',
      data: {
        category: this.category,
      }
    })

    dialog.afterClosed().subscribe(() => {
      this.getCategory()
    })
  }

  // =================
  //   EDIT CATEGORY 
  // =================

  updateCategoryImage(){
    const dialog = this.dialog.open(categoryImageUpdateDialog, {
      width: '600px',
      height: '350px',
      data: {
        category: this.category,
      }
    })

    dialog.afterClosed().subscribe(() => {
      this.getCategory()
    })
  }


// ---------------
//  SUB CATEGORIES
// --------------- 
    
  getSubCategories(){
    this.api.getList("catelog",`categories/${this.id}/categories?per_page=${100}`)
    .subscribe((data:any)=>{
    })
  }

  createSubCategory(subcat){
    let subcategory = [
      {
        name: subcat.title,
        parent_id: this.category.id,
        silo_id: this.category.silo_id,
        image: this.category.image,
        position: 1,
        linked_key: JSON.stringify(Math.random()),
        active: true
      }
    ]

    this.api.post(`catelog`, `categories`, subcategory).subscribe((subcat: any) => {
      this.message.showSnack(`Successfully added Sub-category ${subcat.name} to ${this.category.name}`)
      this.getSubCategories()
    }, error => {
      this.message.showSnack(error.error)
    })
  }

  subCat(cat){
    this.router.navigate([`/categories/${cat.id}`])
  }

}



// ==============
//   EDIT POST  
// ==============
@Component({
  selector: 'category-edit-dialog',
  templateUrl: 'category-edit-dialog.html',
  styleUrls: ['../../../products/product/product-detail/update-product-dialog.component.scss']
})

export class editCategoryDialog {


  category
  name
  silo
  silos
  linked_key
  silo_id


  // get section info after success
  onAdd       = new EventEmitter()


  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: { category }, 
              private api : ApiService, 
              private message: MessagesService,  
              public dialogRef: MatDialogRef<editCategoryDialog>) {
  }

  ngOnInit(): void {
    this.getSilos()
    this.linked_key = this.data.category.linked_key
    this.name = this.data.category.name
    this.silo_id = this.data.category.silo_id
  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

  getSilos(){
    this.api.getList('catelog', 'silos').subscribe((silos: any) => {
      this.silos = silos
    }, error => {
      this.message.showSnack(error.error)
    })
  }

  updateCategory(){
    let updatedCat = [
      {
      id: JSON.parse(this.data.category.id),
      name: this.name,
      silo_id: this.silo_id,
      linked_key: this.linked_key,
      }
    ]

      this.api.patch('catelog', `categories`, updatedCat).subscribe((data: any) => {
          this.message.showSnack(`Successfully updated Post ${this.data.category.name}`)
          this.closeDialog()
      }, error => {
        this.message.showSnack(error.error)
      })

  }

}


// ==============
//   EDIT POST  
// ==============
@Component({
  selector: 'category-image-update-dialog',
  templateUrl: 'category-image-update-dialog.html',
  styleUrls: ['../../../../components/dialogs/delete-dialog.component.scss']
})

export class categoryImageUpdateDialog {


  category
  image
  imageFile: {resource: string, file: any, name: string}


  // get section info after success
  onAdd       = new EventEmitter()


  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: { category }, 
              private api : ApiService, 
              private message: MessagesService,  
              public dialogRef: MatDialogRef<categoryImageUpdateDialog>) {
  }

  ngOnInit(): void {
    this.image = this.data.category.image
  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

  // ----------------------
  //     IMAGE UPDATE
  // ----------------------

  updateImage(){
      this.message.showSnack(`Updating image for ${this.data.category.name}`)

    let body: string = "{ \"resource\":\"categories\", \"resource_id\":" + this.data.category.id + ",\"x_dim\":1, \"y_dim\":1}"

    var myHeaders = new Headers()

    myHeaders.append("Authorization", "Bearer " + this.api.token)

    var formdata = new FormData()

    formdata.append("file", this.imageFile.file, this.imageFile.name)
    formdata.append("body", "{ \"resource\":\"categories\", \"resource_id\":"+ this.data.category.id +",\"x_dim\":1, \"y_dim\":1}")

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata
    }

    if(!devEnv.production){

  fetch("https://api.api-dev.quench.org.za/catalogue/v1/image", requestOptions)
    .then(response => response.text())
    .then(result =>
      setTimeout(() => {
        this.message.showSnack('Icon updated')
        this.closeDialog()
      }, 8000))
    .catch(error => console.log('error', error))
    }

    // production environment
    else if (devEnv.production){
      fetch("https://api.quench.org.za/catalogue/v1/image", requestOptions)
      .then(response => response.text())
      .then(result =>
        setTimeout(() => {
          this.message.showSnack('Icon updated')
          this.closeDialog()
        }, 8000))
      .catch(error => console.log('error', error))
    }
  }


  imagesPreview(event) {
    // this.newImage = true
    if (event.target.files && event.target.files[0]) {
        let reader = new FileReader()

        reader.onload = (_event: any) => {
            this.imageFile = {
                resource: _event.target.result,
                file: event.srcElement.files[0],
                name: event.srcElement.files[0].name
            }
            this.image = this.imageFile.resource
            this.imageFile.name = 'image.png'
        }
        reader.readAsDataURL(event.target.files[0])
    }
  }


}
