<hr />
<div class="name action">
  <h4>Store Details</h4>
  <span
    class="update-all-button"
    matTooltip="update multiple fields"
    (click)="updateAllFields()"
    *ngIf="!allFields"
    >update
  </span>
</div>
<hr />

<span *ngIf="store">
  <div class="flex-store-details">
    <!-- store name -->
    <div class="flex-item">
      <div>
        <p *ngIf="!editName || allFields" class="field">store name</p>
        <p *ngIf="store.name && !editName">{{ store.name }}</p>
        <p *ngIf="!store.name && !editName">n/a</p>
        <input
          matInput
          type="text"
          matInputAutofocus
          [(ngModel)]="name"
          *ngIf="editName"
        />
      </div>
      <button mat-flat-button *ngIf="editName && !allFields" (click)="patch()">
        update
      </button>
      <mat-icon (click)="updateStore('name')" *ngIf="!editName">edit</mat-icon>
      <mat-icon (click)="cancelUdpate('name')" *ngIf="editName && !allFields"
        >close</mat-icon
      >
    </div>

    <!-- email -->
    <div class="flex-item">
      <div>
        <p *ngIf="!editEmail || allFields" class="field">email</p>
        <p *ngIf="store.email && !editEmail">{{ store.email }}</p>
        <p *ngIf="!store.email && !editEmail">n/a</p>
        <input
          matInput
          type="text"
          matInputAutofocus
          [(ngModel)]="email"
          *ngIf="editEmail"
        />
      </div>
      <button mat-flat-button *ngIf="editEmail && !allFields" (click)="patch()">
        update
      </button>
      <mat-icon (click)="updateStore('email')" *ngIf="!editEmail"
        >edit</mat-icon
      >
      <mat-icon (click)="cancelUdpate('email')" *ngIf="editEmail && !allFields"
        >close</mat-icon
      >
    </div>

    <!-- phone -->
    <div class="flex-item">
      <div>
        <p *ngIf="!editPhone || allFields" class="field">phone</p>
        <p *ngIf="store.phone && !editPhone">{{ store.phone }}</p>
        <p *ngIf="!store.phone && !editPhone">n/a</p>
        <input
          matInput
          type="text"
          matInputAutofocus
          [(ngModel)]="phone"
          *ngIf="editPhone"
        />
      </div>
      <button mat-flat-button *ngIf="editPhone && !allFields" (click)="patch()">
        update
      </button>
      <mat-icon (click)="updateStore('phone')" *ngIf="!editPhone"
        >edit</mat-icon
      >
      <mat-icon (click)="cancelUdpate('phone')" *ngIf="editPhone && !allFields"
        >close</mat-icon
      >
    </div>

    <!-- vat number -->
    <div class="flex-item">
      <div>
        <p *ngIf="!editVat || allFields" class="field">vat_number</p>
        <p *ngIf="store.vat_number && !editVat">{{ store.vat_number }}</p>
        <p *ngIf="!store.vat_number && !editVat">n/a</p>
        <input
          matInput
          type="text"
          matInputAutofocus
          [(ngModel)]="vat_num"
          *ngIf="editVat"
        />
      </div>
      <button mat-flat-button *ngIf="editVat && !allFields" (click)="patch()">
        update
      </button>
      <mat-icon (click)="updateStore('vat')" *ngIf="!editVat">edit</mat-icon>
      <mat-icon (click)="cancelUdpate('vat')" *ngIf="editVat && !allFields"
        >close</mat-icon
      >
    </div>

    <!-- liquor license -->
    <div class="flex-item">
      <div>
        <p *ngIf="!editLiquor || allFields" class="field">
          liquor_license_number
        </p>
        <p *ngIf="store.liquor_license_number && !editLiquor">
          {{ store.liquor_license_number }}
        </p>
        <p *ngIf="!store.liquor_license_number && !editLiquor">n/a</p>
        <input
          matInput
          type="text"
          matInputAutofocus
          [(ngModel)]="liquor_license_number"
          *ngIf="editLiquor"
        />
      </div>
      <button
        mat-flat-button
        *ngIf="editLiquor && !allFields"
        (click)="patch()"
      >
        update
      </button>
      <mat-icon (click)="updateStore('liquor')" *ngIf="!editLiquor"
        >edit</mat-icon
      >
      <mat-icon
        (click)="cancelUdpate('liquor')"
        *ngIf="editLiquor && !allFields"
        >close</mat-icon
      >
    </div>

    <!-- api link -->
    <div class="flex-item">
      <div>
        <p *ngIf="!editApi || allFields" class="field">api_link</p>
        <p *ngIf="store.api_link && !editApi">{{ store.api_link }}</p>
        <p *ngIf="!store.api_link && !editApi">n/a</p>
        <input
          matInput
          type="text"
          matInputAutofocus
          [(ngModel)]="api_link"
          *ngIf="editApi"
        />
      </div>
      <button mat-flat-button *ngIf="editApi && !allFields" (click)="patch()">
        update
      </button>
      <mat-icon (click)="updateStore('api')" *ngIf="!editApi">edit</mat-icon>
      <mat-icon (click)="cancelUdpate('api')" *ngIf="editApi && !allFields"
        >close</mat-icon
      >
    </div>

    <!-- loop -->
    <div class="flex-item">
      <div>
        <p *ngIf="!editLoop || allFields" class="field">loop_branch_code</p>
        <p *ngIf="store.loop_branch_code && !editLoop">
          {{ store.loop_branch_code }}
        </p>
        <p *ngIf="!store.loop_branch_code && !editLoop">n/a</p>
        <input
          matInput
          type="text"
          matInputAutofocus
          [(ngModel)]="loop_branch_code"
          *ngIf="editLoop"
        />
      </div>
      <button mat-flat-button *ngIf="editLoop && !allFields" (click)="patch()">
        update
      </button>
      <mat-icon (click)="updateStore('loop')" *ngIf="!editLoop">edit</mat-icon>
      <mat-icon (click)="cancelUdpate('loop')" *ngIf="editLoop && !allFields"
        >close</mat-icon
      >
    </div>

    <!-- slogan -->
    <div class="flex-item">
      <div>
        <p *ngIf="!editSlogan || allFields" class="field">about</p>
        <p *ngIf="store.about && !editSlogan">{{ store.about }}</p>
        <p *ngIf="!store.about && !editSlogan">n/a</p>
        <input
          matInput
          type="text"
          matInputAutofocus
          [(ngModel)]="slogan"
          *ngIf="editSlogan"
        />
      </div>
      <button
        mat-flat-button
        *ngIf="editSlogan && !allFields"
        (click)="patch()"
      >
        update
      </button>
      <mat-icon (click)="updateStore('slogan')" *ngIf="!editSlogan"
        >edit</mat-icon
      >
      <mat-icon
        (click)="cancelUdpate('slogan')"
        *ngIf="editSlogan && !allFields"
        >close</mat-icon
      >
    </div>

    <!-- url -->
    <div class="flex-item">
      <div>
        <p *ngIf="!editUrl || allFields" class="field">url link</p>
        <p *ngIf="store.url && !editUrl">{{ store.url }}</p>
        <p *ngIf="!store.url && !editUrl">n/a</p>
        <input
          matInput
          type="text"
          matInputAutofocus
          [(ngModel)]="url"
          *ngIf="editUrl"
        />
      </div>
      <button mat-flat-button *ngIf="editUrl && !allFields" (click)="patch()">
        update
      </button>
      <mat-icon (click)="updateStore('url')" *ngIf="!editUrl">edit</mat-icon>
      <mat-icon (click)="cancelUdpate('url')" *ngIf="editUrl && !allFields"
        >close</mat-icon
      >
    </div>

    <!-- silo -->
    <div class="flex-item last">
      <div>
        <p *ngIf="!editSilo || allFields" class="field">silo</p>
        <p *ngIf="store.silo_id && !editSilo">{{ store.silo_id }}</p>
        <p *ngIf="!store.silo_id && !editSilo">n/a</p>

        <mat-form-field appearance="outline" *ngIf="editSilo">
          <mat-label>Silo</mat-label>
          <mat-select [(ngModel)]="silo" name="silo" required>
            <mat-option *ngFor="let silo of silos" [value]="silo.id">
              {{ silo.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <button mat-flat-button *ngIf="editSilo && !allFields" (click)="patch()">
        update
      </button>
      <mat-icon (click)="updateStore('silo')" *ngIf="!editSilo">edit</mat-icon>
      <mat-icon (click)="cancelUdpate('silo')" *ngIf="editSilo && !allFields"
        >close</mat-icon
      >
    </div>

    <!-- Warehouse -->
    <div class="flex-item">
      <div>
        <p *ngIf="!editWarehouseCode || allFields" class="field">Warehouse code</p>
        <p *ngIf="store.warehouse_code && !editWarehouseCode">{{ store.warehouse_code }}</p>
        <p *ngIf="!store.warehouse_code && !editWarehouseCode">n/a</p>
        <input
          matInput
          type="text"
          matInputAutofocus
          [(ngModel)]="warehouse_code"
          *ngIf="editWarehouseCode"
        />
      </div>
      <button
        mat-flat-button
        *ngIf="editWarehouseCode && !allFields"
        (click)="patch()"
      >
        update
      </button>
      <mat-icon (click)="updateStore('warehouse_code')" *ngIf="!editWarehouseCode"
        >edit</mat-icon
      >
      <mat-icon
        (click)="cancelUdpate('warehouse_code')"
        *ngIf="editWarehouseCode && !allFields"
        >close</mat-icon
      >
    </div>
  

    <!-- delivery partner -->
    <div class="flex-item last">
      <div>
        <p *ngIf="!editDelPrtnr || allFields" class="field">Delivery Partner</p>
        <p *ngIf="(retailer && !editDelPrtnr) || store.delivery_partner">
          {{ store.delivery_partner }}
        </p>
        <p *ngIf="!store.delivery_partner && !editDelPrtnr">n/a</p>

        <mat-form-field appearance="outline" *ngIf="editDelPrtnr">
          <mat-label>{{ delivery_partner }}</mat-label>
          <mat-select [(ngModel)]="delivery_partner" name="delivery_partner">
            <mat-option
              *ngFor="let delivery_partner of delivery_partners"
              [value]="delivery_partner"
            >
              {{ delivery_partner }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <button
        mat-flat-button
        *ngIf="editDelPrtnr && !allFields"
        (click)="patch()"
      >
        update
      </button>
      <mat-icon (click)="updateStore('delivery')" *ngIf="!editDelPrtnr"
        >edit</mat-icon
      >
      <mat-icon
        (click)="cancelUdpate('delivery')"
        *ngIf="editDelPrtnr && !allFields"
        >close</mat-icon
      >
    </div>

       <!-- trip event trigger -->
       <div class="flex-item last">
        <div>
          <p *ngIf="!editTripEventTrigger || allFields" class="field"  matTooltip="determines at which point the driver allocation is triggered">Trip Event trigger</p>
          <p *ngIf="(retailer && !editTripEventTrigger) || store.delivery_partner">
            {{ store.trip_event_trigger }}
          </p>
          <p *ngIf="!store.trip_event_trigger && !editTripEventTrigger">n/a</p>
  
          <mat-form-field appearance="outline" *ngIf="editTripEventTrigger">
            <mat-label>{{ trip_event_trigger }}</mat-label>
            <mat-select [(ngModel)]="trip_event_trigger" name="trip_event_trigger">
              <mat-option
                *ngFor="let trip_event_trigger of trip_event_triggers"
                [value]="trip_event_trigger"
              >
                {{ trip_event_trigger }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button
          mat-flat-button
          *ngIf="editTripEventTrigger && !allFields"
          (click)="patch()"
        >
          update
        </button>
        <mat-icon (click)="updateStore('trigger_event')" *ngIf="!editTripEventTrigger"
          >edit</mat-icon
        >
        <mat-icon
          (click)="cancelUdpate('trigger_event')"
          *ngIf="editTripEventTrigger && !allFields"
          >close</mat-icon
        >
      </div>

  </div>

  <div class="buttons" *ngIf="allFields">
    <button mat-flat-button (click)="cancelUdpate($event)">cancel</button>
    <button mat-flat-button class="button" (click)="patch()">update</button>
  </div>
</span>
