import { AppComponent } from './../../app.component';
import { MessagesService } from 'src/app/services/messages.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import { PagerService } from '../../services/pager.service';
import { PusherService } from 'src/app/services/pusher.service';
import { ApiService } from 'src/app/services/api.service';
import { Injectable } from '@angular/core';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
@Injectable({
  providedIn: 'root',
})
export class SidenavComponent implements OnInit {
  public sidenav;
  public isDisChemUser = false;

  navigationItems = [
    {
      label: 'analytics',
      icon: 'dashboard',
      path: '/dashboard',
      class: 'material-icons-outlined',
      items: [
        {
          label: 'analytics',
          path: `/dashboard`,
        },
        {
          label: 'Exports',
          path: '/order-exports',
        },
      ],
    },
    {
      label: 'carts',
      icon: 'shopping_bag',
      path: '/carts',
      class: 'material-icons-outlined',
      items: [
        {
          label: 'open orders',
          path: '/carts',
        },
        {
          label: 'completed orders',
          path: '/orders',
        },
        {
          label: 'open carts',
          path: '/open_carts',
        },
        {
          label: 'deliveries',
          path: '/list-all-deliveries',
        },
      ],
    },
    {
      label: 'messages',
      icon: 'chat',
      path: '/messages',
      class: 'material-icons-outlined',
      items: [
        {
          label: 'Messages',
          path: '/messages',
        },
      ],
    },
    {
      label: 'customers',
      icon: 'person',
      path: '/users',
      class: 'material-icons-outlined',
      items: [
        {
          label: 'customers',
          path: '/users',
        },
        {
          label: 'admins',
          path: '/admins',
        },
        {
          label: 'drivers',
          path: '/drivers',
        },
        {
          label: 'dischem',
          path: '/dischem',
        },
      ],
    },
    {
      label: 'products',
      icon: 'qr_code',
      path: '/products',
      class: 'material-icons-outlined',
      items: [
        {
          label: 'products',
          path: '/products',
        },
        {
          label: 'categories',
          path: '/categories',
        },
      ],
    },
    {
      label: 'stores',
      icon: 'storefront',
      path: '/stores',
      class: 'material-icons-outlined',
      items: [
        {
          label: 'stores',
          path: '/stores',
        },
        {
          label: 'retailers',
          path: '/retailers',
        },
        {
          label: 'zones',
          path: '/geofences',
        },
      ],
    },
    {
      label: 'marketing',
      icon: 'local_offer',
      path: '/sections',
      class: 'material-icons-outlined',
      items: [
        {
          label: 'sections',
          path: '/sections',
        },
        {
          label: 'posts',
          path: '/posts',
        },
        {
          label: 'silos',
          path: '/silos',
        },
        {
          label: 'promotional',
          path: '/promos',
        },
        {
          label: 'push',
          path: '/push',
        },
        {
          label: 'reviews',
          path: '/reviews',
        },
      ],
    },
    {
      label: 'zones',
      icon: 'mode_of_travel',
      path: '/zones-list',
      class: 'material-icons-outlined',
      items: [
        {
          label: 'zones list',
          path: '/zones-list',
        },
        {
          label: 'zones map',
          path: '/zones-map',
        }
      ],
    },
    {
      label: 'settings',
      icon: 'settings',
      path: '/partners',
      class: 'material-icons-outlined',
      items: [
        // {
        //   label:'scheduling',
        //   path:'/scheduling'
        // },
        {
          label: 'partners',
          path: '/partners',
        },
        {
          label: 'fees',
          path: '/fees',
        },
        {
          label: 'trading hours',
          path: '/trading_hours',
        },
        {
          label: 'Public Holidays',
          path: '/public_holidays',
        },
        {
          label: 'Loop Branch Map',
          path:'/list-branchmap'
        },
        {
          label: "F.A.Q'S",
          path: '/faqs',
        },
      ],
    },
    {
      icon: 'lock',
      label: 'logout',
      class: 'material-icons-outlined',
    },
  ];

  disChemNavItems = [
    {
      label: 'carts',
      icon: 'shopping_bag',
      path: '/carts',
      class: 'material-icons-outlined',
      items: [
        {
          label: 'carts',
          path: '/carts',
        },
      ],
    },
    {
      label: 'marketing',
      icon: 'local_offer',
      path: '/sections',
      class: 'material-icons-outlined',
      items: [
        {
          label: 'sections',
          path: '/sections',
        },
        {
          label: 'posts',
          path: '/posts',
        },
      ],
    },
    {
      icon: 'lock',
      label: 'logout',
      class: 'material-icons-outlined',
    },
  ];

  constructor(
    public auth: AuthService,
    public pager: PagerService,
    private router: Router,
    public message: MessagesService,
    public route: ActivatedRoute,
    public pusherService: PusherService,
    public api: ApiService,
    public app: AppComponent
  ) {}

  ngOnInit(): void {
    if (
      localStorage.getItem('dis-chem-role') &&
      localStorage.getItem('dis-chem-role') == 'true'
    ) {
      this.sidenav = this.disChemNavItems;
      this.isDisChemUser = true;
      this.app.loadStyle('dischem.css');

      if (localStorage.getItem('subItems')) {
        this.subItems = JSON.parse(localStorage.getItem('subItems'));
      } else {
        this.subItems = this.disChemNavItems[0].items;
        this.menu = this.disChemNavItems[0].label;
      }
    } else {
      this.app.loadStyle('admin.css');
      if (
        localStorage.getItem('finance-role') &&
        localStorage.getItem('finance-role') == 'true'
      ) {
        this.navigationItems.pop();
        let payment = {
          label: 'Payments',
          icon: 'attach_money',
          path: '/payments',
          class: 'material-icons-outlined',
          items: [
            {
              label: 'payments',
              path: '/payments',
            },
            {
              label: 'earnings',
              path: '/earnings',
            },
            /*
            {
              label: 'assets',
              path: '/assets',
            },
            {
              label: 'contracts',
              path: '/contracts',
            },
            */
            {
              label: 'incentives',
              path: '/incentives',
            },
            {
              label: 'reasons',
              path: '/reasons',
            },
            {
              label: 'fees',
              path: '/distances-fees',
            }, 
            {
              label: 'banks',
              path: '/banks',
            },
          ],
        };
        let logout = {
          icon: 'lock',
          label: 'logout',
          class: 'material-icons-outlined',
        };

        this.navigationItems.push(payment, logout);
      }

      this.sidenav = this.navigationItems;

      if (localStorage.getItem('subItems')) {
        this.subItems = JSON.parse(localStorage.getItem('subItems'));
      } else {
        this.subItems = this.navigationItems[0].items;
      }
    }

    if (localStorage.getItem('currentMenu')) {
      this.menu = localStorage.getItem('currentMenu');
    }

    if (localStorage.getItem(`user`)) {
      this.auth.user = localStorage.getItem(`user`);
    }
  }

  onResize(event) {
    var w = event.target.innerWidth;

    if (w < 1100) {
      this.auth.expand = false;
    }
  }

  menu = 'analytics';
  mobile_expand = false;
  subItems;

  expandSideNav() {
    this.auth.expand = !this.auth.expand;
  }

  expandMobileNav() {
    this.mobile_expand = !this.mobile_expand;
  }

  setRoute(item) {
    if (item.label == 'logout') {
      this.auth.logout();
    } else {
      if (item.items) {
        console.log(item.items);
        this.subItems = item.items;
        localStorage.setItem('subItems', JSON.stringify(this.subItems));
        this.menu = item.items[0].label;
      }
      localStorage.setItem('currentMenu', this.menu);
      this.mobile_expand = false;
    }
  }

  setSubMenu(item) {
    this.menu = item.label;
    localStorage.setItem('currentMenu', this.menu);
  }
}
