import { Injectable } from '@angular/core';
import { MiscService } from '../misc/misc.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveriesService {

  currentDelivery 
  deliveries
  page = 1
  limit = 10
  search = ''

  constructor(public misc: MiscService) { }

  async getAllDeliveries(){
    await this.misc.apiCall(`godrive`, `admin/deliveries?page=${this.page}&per_page=${this.limit}&search=${this.search}`).then((res: any) => {
      this.deliveries = res['deliveries']
      // console.log(this.deliveries)
    })
  }

  async getSingleDelivery(id){
    await this.misc.apiCall(`godrive`, `admin/deliveries/${id}`).then((res: any) => {
      this.currentDelivery = res['delivery']
      console.log(this.currentDelivery)
    })
  }
}
