

  <div class="search" >
    <mat-icon (click)='getUsers(search)' matTooltip='search' class='search-icon'>search</mat-icon>
    <mat-icon  (click)='clearSearch()'  matTooltip='clear' class='clear-icon'>clear</mat-icon>
    <input matInput (keydown.enter)='getUsers(search)' [(ngModel)]="search" placeholder="Search"  #input>
  </div>
  

  <table  mat-table [dataSource]='admins' matSort *ngIf='!add'>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let admin" class='pointer'> {{admin.name}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Emal </th>
      <td mat-cell *matCellDef="let admin" > {{admin.email}} </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
      <td mat-cell *matCellDef="let admin" > {{admin.phone}} </td>
    </ng-container>

    <ng-container matColumnDef="add">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <mat-icon class='action'  (click)='addUserToggle()'>add_circle</mat-icon> </th>
      <td mat-cell *matCellDef="let admin" > <mat-icon (click)='removeAdmin(admin)'>delete_outline</mat-icon> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row 
        *matRowDef="let admin; columns: Columns;" 
        class='action'></tr>
  </table>

  <table  mat-table [dataSource]='users' matSort *ngIf='add'>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let user" class='pointer'> {{user.first_name}}{{user.last_name}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Emal </th>
      <td mat-cell *matCellDef="let user" > {{user.email}} </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
      <td mat-cell *matCellDef="let user" > {{user.phone}} </td>
    </ng-container>

    <ng-container matColumnDef="add">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <mat-icon class='action' (click)='addUserToggle()'>clear</mat-icon> </th>
      <td mat-cell *matCellDef="let user" > <mat-icon (click)='addUser(user)'>add_circle</mat-icon> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row 
        *matRowDef="let user; columns: Columns;" 
        class='action'></tr>
  </table>


<div id="pager" >
  <div class="page"> 

  <div> Per page:</div>
  <div (click)="setLimit(10)" class="limit" >10</div>
  <div (click)="setLimit(15)" class="limit" >15</div>
  <div (click)="setLimit(20)" class="limit" >20</div>
  <div (click)="setLimit(50)" class="limit" >50</div>
  <div>Page:</div>
  <mat-icon (click)='previous()'>skip_previous</mat-icon>
  <div>{{page}}</div>
    <mat-icon (click)='next()'>skip_next</mat-icon>
    <div> </div>
  </div>
