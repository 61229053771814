<div class="header">
    <h3>update user wallet</h3>
    <mat-icon class='action' (click)='closeDialog()'>clear</mat-icon>
</div>

<hr>

<mat-form-field appearance="outline" class='field'>
    <mat-label>Loyalty Types</mat-label>
    <mat-select [(ngModel)]="loyalty_type">
      <mat-option *ngFor="let type of loyaltyTypes" [value]="type.name">
        {{type.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

    <br>
    <mat-form-field appearance='outline' class='field'>
        <mat-label>loyalty number</mat-label>
        <input matInput type="text" [(ngModel)]="loyalty_number"  #ctrl="ngModel" >
    </mat-form-field>



<div class="btn">
    <button mat-flat-button  class='wallet-button' (click)='closeDialog()'>cancel</button>
    <button mat-flat-button class='button wallet-button' (click)='addLoyalty()' >add</button>
</div>