import { AuthService } from 'src/app/services/auth.service';
import { PusherService } from 'src/app/services/pusher.service';
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute }                  from '@angular/router'
import { ApiService }                      from 'src/app/services/api.service'


@Component({
  selector: 'app-cart-navigation',
  templateUrl: './cart-navigation.component.html',
  styleUrls: ['./cart-navigation.component.scss']
})
export class CartNavigationComponent implements OnInit {  

  id
  cart

  selectedTab = 'overview'


  tabs = [
    {name: 'overview'},
    {name: 'shipping & payment'},
    {name: 'actions & tracking'},
  ]

  isDischemUser    = false
  resource         = 'admin/carts'

  constructor(public api: ApiService,
              public route: ActivatedRoute,
              public pusher: PusherService,
              public auth: AuthService,
              ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    if(this.auth.dis_chem_role || localStorage.getItem('dis-chem-role') && localStorage.getItem('dis-chem-role') == 'true'){
      this.isDischemUser = true
      this.resource = `dischem/carts`
    } else {
      this.resource = "admin/carts"
      let api_requests = {
        name: 'api requests'
      }
      this.tabs.push(api_requests)
    }
    this.getCart()
    this.listToPusher()

    if(localStorage.getItem('cartTab')){
      this.selectedTab = localStorage.getItem('cartTab');
    }
  }

  selectTab(i){
    this.selectedTab = this.tabs[i].name
    localStorage.setItem('cartTab', this.tabs[i].name)
  }

  getCart(){
    this.api.get('fulfilment', this.resource, this.id).subscribe((cart: any) => {
      // console.log(cart)
      this.cart = cart.cart
    })
  }

  listToPusher(){
    this.pusher.channel.bind('cart-event-'+this.id, data => {
      // console.log(data)

      // clear data and populate again
      this.getCart()
    })
  }

}
