import { ActivatedRoute } from '@angular/router';
import { ApiService } from './../../../services/api.service';
import { MessagesService } from './../../../services/messages.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mall-navigation',
  templateUrl: './mall-navigation.component.html',
  styleUrls: ['./mall-navigation.component.scss']
})
export class MallNavigationComponent implements OnInit {

  id

  selectedTab = 'detail'



  tabs = [
    {name: 'detail'},
    {name: 'stores'},
    {name: 'zones'},
    {name: 'sections'},
  ]

  constructor(    public messages:MessagesService,
                  private api: ApiService,
                  private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')

    if(localStorage.getItem('mallTab')){
      this.selectedTab = localStorage.getItem('mallTab')
    }
  }

  selectTab(i){
    this.selectedTab = this.tabs[i].name;
    localStorage.setItem('mallTab', this.tabs[i].name)
  }

}
