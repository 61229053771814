<div class="back action" routerLink="/accounts">
  <mat-icon>arrow_back</mat-icon>
  <p>accounts</p>
</div>

<div class="tabs">
  <div
    (click)="selectTab(page)"
    *ngFor="let page of account_pages; let i = index"
    [class]="current_page == page.name ? 'selectedTab' : ''"
  >
    <div id="tab">
      <p id="tab-name">{{ page.name }}</p>
    </div>
  </div>
</div>


<!-- hier define ons net watter page geload word gebaseer op die current_page -->
<app-summary *ngIf="current_page == 'summary'"></app-summary>
<app-assets *ngIf="current_page == 'assets'"></app-assets>
<app-payments *ngIf="current_page == 'payments'"></app-payments>
<app-earnings *ngIf="current_page == 'earnings'"></app-earnings>
<app-accounts *ngIf="current_page == 'accounts'"></app-accounts>
