import { Component, Inject, OnInit, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { MessagesService } from 'src/app/services/messages.service';
import { ReasonsService } from 'src/app/services/reasons/reasons.service';

@Component({
  selector: 'app-list-reasons',
  templateUrl: './list-reasons.component.html',
  styleUrls: ['./list-reasons.component.scss']
})
export class ListReasonsComponent implements OnInit {
  Columns = [
    "name",
    "description",
    "edit",
    "delete"
  ]

  constructor(public reason: ReasonsService, public auth: AuthService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.reason.getReasons()
  }

  updateReason(reason) {
    const dialog = this.dialog.open(UpdateReasonDialog, {
      width: '850px',
      height: '600px',
      data: {
        reason: reason,
      },
    });

    dialog.afterClosed().subscribe(() => {
      setTimeout(() => {
        this.reason.getReasons();
      }, 1000);
    });
  }

// ============================
//  DELETE REASON DIALOG
// ============================

deleteReason(reason){
  const dialog = this.dialog.open(DeleteReasonDialog, {
    width: '550px',
    height: '200px',
    data: {
      reason: reason,
    }
  })

  dialog.afterClosed().subscribe(() => {
    setTimeout(() => {
      this.reason.getReasons();
    }, 1000);
  })
}

}


// ============================
//    ASSET UPDATE DIALOG
// ============================

@Component({
  selector: 'update-reason-dialog',
  templateUrl: 'update-reason-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class UpdateReasonDialog {
  name;
  description;


  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { reason },
    private reason: ReasonsService,
    public dialogRef: MatDialogRef<UpdateReasonDialog>
  ) {}

  ngOnInit(): void {
    this.name = this.data.reason.name;
    this.description = this.data.reason.description;
  }

  // get section info after success
  onAdd = new EventEmitter();

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  updateReason(reason) {
    this.reason.updateReason(this.data.reason.ID, reason);
    this.closeDialog();
  }
}

@Component({
  selector: 'delete-dialog',
  templateUrl: '../../../components/dialogs/delete-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss']
})

export class DeleteReasonDialog {

    
  inputTouch  = false
  heading     = `reason`
  body        = `Are you sure you want to delete this reason? This action cannot be undone.`

  reason

  // get section info after success
  onAdd       = new EventEmitter()
  
  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }


  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: { reason }, 
              private api : ApiService, 
              private message: MessagesService,  
              private reasonsservice: ReasonsService,
              public dialogRef: MatDialogRef<DeleteReasonDialog>) {

  }

  ngOnInit(): void {
    this.reason = this.data.reason
  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

  removeItem(){
    this.reasonsservice.deleteReason(this.reason.ID)
    this.closeDialog();
  }
}
