import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MessagesService } from 'src/app/services/messages.service';
import { PagerService } from 'src/app/services/pager.service';
import { PusherService } from 'src/app/services/pusher.service';

@Component({
  selector: 'app-open-carts',
  templateUrl: './open-carts.component.html',
  styleUrls: ['./open-carts.component.scss']
})
export class OpenCartsComponent implements OnInit {
  Columns = ['placed', 'store', 'customer', 'status', 'origin', 'phone','distance', 'amount']

  inputTouch = false

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }

  page  = 1
  limit = 10
  search = ''
  state = 'OPEN'
  retries = 0
  open_carts 

  constructor(  public api:ApiService,
                public pager:PagerService,
                public message: MessagesService,
                private pusherService: PusherService,
                ) { }

  ngOnInit(): void {
    if(localStorage.getItem('openCartsPage')){
      this.page = JSON.parse(localStorage.getItem('openCartsPage'))
    }

    if(localStorage.getItem('limit')){
      this.limit = JSON.parse(localStorage.getItem('limit'))
    }

    this.getCarts(this.search)
    this.listToPusher()
  }

  getCarts(e){
    localStorage.setItem('cartSearch', e)
    this.search = e
    

    this.api.getList("fulfilment", `admin/carts?status=${this.state}&page=${this.page}&per_page=${this.limit}&search=${this.search}`)
    .subscribe((carts:any) =>{
      // console.log(carts)

        if(carts.data.length === 0 && this.page > 1){
          this.page--
          this.getCarts(this.search)
        } 
        else if(carts.data.length === 0 && this.search.length > 1){
          this.message.showSnack('No results found')
        }
      
        this.open_carts = carts.data

      }, error => {
        this.message.showSnack(error.error)
        if(this.retries < 3 && error.status !== 401){
          this.getCarts(this.search)
          this.retries++
      }
    })
  }


  clearSearch(){
    this.search = ''
    this.getCarts(this.search)
  }

    // =================
  //   LIVE UPDATE
  // =================

  listToPusher(){
    this.pusherService.channel.bind('cart-status-event', data => {
      this.getCarts(this.search)
    }, error => {
      // console.log(error)
    })
  }

   // =================
  //   PAGINATION
  // =================

  setLimit(limit){
    this.limit = limit
    this.getCarts(this.search)

    localStorage.setItem('limit', JSON.stringify(limit))
  }

  next(){
    if(this.open_carts.length >= this.limit){
      this.page++
      this.getCarts(this.search)
      }

  localStorage.setItem('openCartsPage', JSON.stringify(this.page))
  }

  previous(){
    if(this.page > 1){
      this.page--

      this.getCarts(this.search)
    }

    localStorage.setItem('openCartsPage', JSON.stringify(this.page))
  }

  start(){
    this.page = 1
    this.getCarts(this.search)

    localStorage.setItem('openCartsPage', JSON.stringify(this.page))
  }


}
