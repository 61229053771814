import { Component, Inject, OnInit } from '@angular/core'
import { ActivatedRoute }    from '@angular/router'
import { MAT_DIALOG_DATA }   from '@angular/material/dialog'
import { MatDialog }         from '@angular/material/dialog'
import { ApiService }        from 'src/app/services/api.service'



@Component({
  selector: 'app-nucleus-waybill-tracking',
  templateUrl: './nucleus-waybill-tracking.component.html',
  styleUrls: ['./nucleus-waybill-tracking.component.scss']
})
export class NucleusWaybillTrackingComponent implements OnInit {

  Columns = ['date', 'action', 'code', 'url', 'result']


  id
  requests

  constructor(public api: ApiService,
              public route: ActivatedRoute,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getWaybillCalls()
  }

  

  getWaybillCalls(){
    this.api.getList('fulfilment', `admin/requests/carts/${this.id}`).subscribe((data:any) => {
      // console.log(data)
      this.requests = data.data

      this.requests.forEach(item => {
        // console.log(item)
        // item['result'] = JSON.parse(item.body).ResultSets
      })
    })
  }

  request


  resultsDialog(request){
    const dialog = this.dialog.open(ResultsDialog, {
      width: '800px',
      height: '600px',
      data: {
        result: request,
      }
    })
  }
}

@Component({
  selector: 'result-dialog',
  templateUrl: 'result-dialog.html',
  styleUrls: ['./result-dialog.component.scss']
})
export class ResultsDialog {

  body
  results




  constructor(@Inject(MAT_DIALOG_DATA) public data: { result,  }, private api : ApiService) {

  }

  ngOnInit(): void {
    // console.log(this.data.result)
    this.results = JSON.parse(this.data.result.data)
    this.body = this.data.result.body
  }

}


