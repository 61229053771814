import { MessagesService } from './../../services/messages.service';
import { AuthService } from './../../services/auth.service';
import { ApiService } from './../../services/api.service';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { AgmMap, PolygonManager  } from '@agm/core';


@Component({
  selector: 'app-all-geofences',
  templateUrl: './all-geofences.component.html',
  styleUrls: ['./all-geofences.component.scss']
})
export class AllGeofencesComponent implements OnInit {

  selectedRegion
  selectedSilo
  zone
  test

  showEditButton = false

  polygon
  polygons = []

  edit = false
  geopoints
  markers = []
  drawingManager: any
  selectedShape: any

  pointList    = []
  selectedArea = 0

  lat: number =  -29.630621
  lng: number = 24.088321
  zoom:number = 5

  silos

  areas = [
    {
      name: "All",
      lat: -29.630621,
      lng: 24.088321
    },
    {
      name: "Cape Town",
      lat: -33.9321,
      lng: 18.8602
    },
    {
      name: "Garden Route",
      lat:  -34.017430,
      lng:  22.807603
    },
    {
      name: "KZN",
      lat: -29.547945,
      lng: 31.135022
    },
    {
      name: "Gauteng",
      lat: -25.960000,
      lng: 28.130000
    }
  ]

  selectRegion(){
    if(this.selectedRegion.name == "All"){
      this.zoom = 5
    } else {
      this.zoom = 9
    }

    this.lat = this.selectedRegion.lat
    this.lng = this.selectedRegion.lng
  }

  @ViewChild(AgmMap) map: any

  constructor(public api: ApiService,
              public auth: AuthService,
              public message: MessagesService) { }

  ngOnInit(): void {
    this.getAllZones()
    this.getSilos()
  }

  editToggle(){
    this.edit = !this.edit
    this.removePolygons()
    this.getAllZones()
  }

  getAllZones(){
    this.api.getList(`catelog`, `allzones?per_page=1000`).subscribe((data: any) => {
      console.log(data)

      if(this.selectedSilo == undefined || this.selectedSilo.name == "All"){
      this.geopoints = data
      this.drawAllPolygons()
      }

      else if (this.selectedSilo){
        this.geopoints = []
        let geopoints = []
        data.forEach(geopoint => {
          let silo_id = geopoint.silo_id
          if(silo_id == this.selectedSilo.id){
            geopoints.push(geopoint)
          }
        })
        this.geopoints = geopoints
        // console.log(this.geopoints)


        if(this.edit){
        this.editSiloPolygons()
        } else {
          this.drawSiloPolygons()
        }
      }
    })
  }

// =================
//     SILOS
// =================

  getSilos(){
    this.api.getList('catelog', `silos`).subscribe((silos: any) => {
      let all = {
        id: 0, 
        name: "All", 
      }
      silos.unshift(all)
      this.silos = silos
    }, error => {
      this.message.showSnack(error.error)
    })
  }

  siloSelect(){
    this.removePolygons()
    this.getAllZones()
    this.showEditButton = true
  }

  onMapReady(map) {
    this.map = map
    setTimeout(() => {
      this.drawAllPolygons()
    }, 800)
    
  }

  removePolygons(){
    this.polygons.forEach(poly => {
      poly.setMap(null)
    })
  }


// =================
//  DRAWING POLYGONS
// =================

drawAllPolygons(){

  for(var i = 0; i < this.geopoints.length; i++){
    
  this.polygon = new google.maps.Polygon({
    paths: this.geopoints[i].geopoints,
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
  })

  let store_name = this.geopoints[i].store_name.toLowerCase()
  if(store_name.includes("woolworths")){
    this.polygon.strokeColor = '#000'
    this.polygon.fillColor = '#000'
  }
  if(store_name.includes('dis-chem')){
    this.polygon.strokeColor = 'green'
    this.polygon.fillColor = 'green'
  }
  if(store_name.includes('deliverd')){
    this.polygon.strokeColor = '#3944e3'
    this.polygon.fillColor = '#3944e3'
  }

  this.polygons.push(this.polygon)
  this.polygon.setMap(this.map)

  }

}


  drawSiloPolygons(){
    for(var i = 0; i < this.geopoints.length; i++){
      
      this.polygon = new google.maps.Polygon({
        paths: this.geopoints[i].geopoints,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
      })
  
      let silo_id = this.geopoints[i].silo_id

  
      if(silo_id == 2){
        this.polygon.strokeColor = '#000'
        this.polygon.fillColor = '#000'
      }
      if(silo_id == 3){
        this.polygon.strokeColor = 'green'
        this.polygon.fillColor = 'green'
      }
      if(silo_id === 12 || silo_id == 6){
        this.polygon.strokeColor = '#3944e3'
        this.polygon.fillColor = '#3944e3'
      }
  
      this.polygons.push(this.polygon)
      this.polygon.setMap(this.map)
  
      }
  }


  // construct polygons for specific silo for edit
  editSiloPolygons(){
    let zones = []
    for(var i = 0; i < this.geopoints.length; i++){
      let zone = this.geopoints[i]

    this.polygon = new google.maps.Polygon({
      paths: this.geopoints[i].geopoints,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      editable: true,
      draggable: true,
    })

    let store_name = this.geopoints[i].store_name.toLowerCase()
    if(store_name.includes("woolworths")){
      this.polygon.strokeColor = '#000'
      this.polygon.fillColor = '#000'
    }
    if(store_name.includes('dis-chem')){
      this.polygon.strokeColor = 'green'
      this.polygon.fillColor = 'green'
    }
    if(store_name.includes('deliverd')){
      this.polygon.strokeColor = '#3944e3'
      this.polygon.fillColor = '#3944e3'
    }

    this.polygons.push(this.polygon)
      this.polygon.setMap(this.map)

      this.polygon.getPaths().forEach(path => {
        google.maps.event.addListener(path, 'insert_at', function () {
              
          let geopoints = []
          const len = path.getLength();
          for (let i = 0; i < len; i++) {
            geopoints.push(
              path.getAt(i).toJSON()
            )
          }

          var isZone = zones.map(z => z.id).indexOf(zone.id)

          if(isZone === -1){
            zones.push(zone)
            zones[zones.length -1].geopoints = geopoints
          } else {
            zones[isZone].geopoints = geopoints
          }

          localStorage.setItem('zones', JSON.stringify(zones))

        })
    
        // add points
          google.maps.event.addListener(path, 'set_at', function () {


            let geopoints = []
    
            const len = path.getLength();
            for (let i = 0; i < len; i++) {
              geopoints.push(
                path.getAt(i).toJSON()
              )
            } 

            var isZone = zones.map(z => z.id).indexOf(zone.id)

            if(isZone === -1){
              zones.push(zone)
              zones[zones.length -1].geopoints = geopoints
            } else {
              zones[isZone].geopoints = geopoints
            }  

            localStorage.setItem('zones', JSON.stringify(zones))
    
            // add start point again if start point moved
            let l = geopoints.length - 1
            if(geopoints[0].lat !== geopoints[l].lat){
              geopoints.push(geopoints[0])
              }
    
              localStorage.setItem('geopoints', JSON.stringify(geopoints))
          })
        })
    }
  }

  updatePolygon(){
   let zones = JSON.parse(localStorage.getItem('zones'))

   zones.forEach(z => {

     let zone = {
       id: z.id,
       store_id: z.store_id,
       geopoints: z.geopoints
     }
     this.api.patch(`catelog`, 'zones', zone).subscribe((data: any) => {
       if(zones.length < 2){
       this.message.showSnack('Succesfully updated geofence')
       } else {
        this.message.showSnack('Succesfully updated geofences')
       }

      setTimeout(() => {
        localStorage.removeItem('zones')
        this.redrawPolygons()
      }, 2000);
     })
   })

  }

  redrawPolygons(){
    this.polygons.forEach(poly => {
      poly.setMap(null)
    })

    this.edit = false
    this.getAllZones()

  }
}
