import { Component, Inject, OnInit, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { BanksService } from 'src/app/services/banks/banks.service';

@Component({
  selector: 'app-list-banks',
  templateUrl: './list-banks.component.html',
  styleUrls: ['./list-banks.component.scss'],
})
export class ListBanksComponent implements OnInit {
  Columns = ['name', 'branch_code', 'swift_code', 'account_number_regex', 'edit'];

  constructor(
    public banks: BanksService,
    public auth: AuthService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.banks.getBanks();
  }

  updateBank(bank) {
    const dialog = this.dialog.open(UpdateBankDialog, {
      width: '850px',
      height: '600px',
      data: {
        bank: bank,
      },
    });

    dialog.afterClosed().subscribe(() => {
      setTimeout(() => {
        this.banks.getBanks();
      }, 1000);
    });
  }
}

// ============================
//    ASSET UPDATE DIALOG
// ============================

@Component({
  selector: 'update-bank-dialog',
  templateUrl: 'update-bank-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class UpdateBankDialog {
  name;
  branch_code;
  swift_code;
  account_number_length;
  account_number_regex;


  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { bank },
    private bank: BanksService,
    public dialogRef: MatDialogRef<UpdateBankDialog>
  ) {}

  ngOnInit(): void {
    this.name = this.data.bank.name;
    this.branch_code = this.data.bank.branch_code;
    this.swift_code = this.data.bank.swift_code;
    this.account_number_length = this.data.bank.account_number_length;
    this.account_number_regex = this.data.bank.account_number_regex;
  }

  // get section info after success
  onAdd = new EventEmitter();

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  updateBank(bank) {
    this.bank.updateBank(this.data.bank.ID, bank);
    this.closeDialog();
  }
}
