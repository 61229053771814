import { CreateDriversComponent } from './pages/drivers/create-drivers/create-drivers.component';
import { PushCreateComponent } from './pages/marketing/push/push-create/push-create.component';
import { AllGeofencesComponent } from './pages/all-geofences/all-geofences.component';
import { NgModule }                       from '@angular/core'
import { SectionComponent }               from './pages/sections/section/section.component';
import { SchedulingComponent }            from './pages/scheduling/scheduling.component';
import { Routes, RouterModule }           from '@angular/router'
import { CreateSectionComponent }         from './pages/sections/create-section/create-section.component';
import { FinanceGaurd, RoleGaurd }                       from './services/authentication/role.gaurd'


import { UserComponent }                  from './pages/users/user/user-page/user.component'
import { FeesComponent }                  from './components/admin/fees/fees.component'
import { PostComponent }                  from './pages/posts/post/post.component'
import { PushComponent }                  from './pages/marketing/push/push.component'
import { FaqsComponent }                  from './components/admin/fees/faq/faqs/faqs.component'
import { CartsComponent }                 from './pages/carts/carts/carts.component'
import { OrderComponent }                 from './pages/orders/order/order.component'
import { UsersComponent }                 from './pages/users/users.component'
import { StoreComponent }                 from './pages/stores/store/store.component'
import { PostsComponent }                 from './pages/posts/posts/posts.component'
import { MallsComponent }                 from './pages/pocket-mall/malls/malls.component'
import { SilosComponent }                 from './pages/silos/silos/silos.component'
import { AdminsComponent }                from './pages/users/admins/admins/admins.component'
import { OrdersComponent }                from './pages/orders/orders.component'
import { ReviewsComponent }               from './pages/marketing/reviews/reviews.component'
import { ProductsComponent }              from './pages/products/products/products.component'
import { SectionsComponent }              from './pages/sections/sections-list/sections.component'
import { CategoryComponent }              from './pages/categories/category/category/category.component'
import { AddStoreComponent }              from './pages/stores/add-store/add-store.component'
import { MessagesComponent }              from './pages/messages/messages.component'
import { RetailerComponent }              from './pages/retailers/retailer/retailer.component'
import { DashboardComponent }             from './pages/dashboard/dashboard-navigate/dashboard.component'
import { StorelistComponent }             from  './pages/stores/storelist/storelist.component'
import { AddressesComponent }             from './pages/users/user/addresses/addresses.component'
import { OpenCartsComponent }             from './pages/carts/open-carts/open-carts.component'
import { SuppliersComponent }             from './pages/suppliers/suppliers/suppliers.component'
import { FaqCreateComponent }             from './components/admin/fees/faq/faq-create/faq-create.component'
import { CategoriesComponent }            from './pages/categories/categories/categories.component'
import { CreatePostComponent }            from './pages/posts/create-post/create-post.component'
import { SiloCreateComponent }            from './pages/silos/silo-create/silo-create.component'
import { AddRetailerComponent }           from './pages/retailers/add-retailer/add-retailer.component'
import { ListdriversComponent }           from './pages/drivers/listdrivers/listdrivers.component'
import { AddCategoryComponent }           from './pages/categories/add-category/add-category.component'
import { TradingHoursComponent }          from './pages/settings/trading-hours/trading-hours.component'
import { PromotionalsComponent }          from './pages/marketing/promotional/promotionals/promotionals.component'
import { RetailersListComponent }         from './pages/retailers/retailers-list/retailers-list.component'
import { OrdersExportsComponent }         from './pages/dashboard/exports/exports.component'
import { ProductCreateComponent }         from './pages/products/product-create/product-create.component'
import { CartNavigationComponent }        from './pages/carts/cart/cart-navigation/cart-navigation.component'
import { PublicHolidaysComponent }        from './pages/settings/public-holidays/public-holidays.component'
import { ProductNavigateComponent }       from './pages/products/product/product-navigate/product-navigate.component'
import { CreatePromotionalComponent }     from './pages/marketing/promotional/create-promotional/create-promotional.component'
import { RetailerProductCreateComponent } from './pages/retailers/retailer-product-create/retailer-product-create.component'
import { ListBatchesComponent } from './pages/payments/list-batches/list-batches.component';
import { ListPaymentBatchComponent } from './pages/payments/list-payments/list-payment-batch.component';
import { PaymentCaptureComponent } from './pages/payments/payment-capture/payment-capture/payment-capture.component';
import { ListAssetsComponent } from './pages/assets/list-assets/list-assets.component';
import { ListEarningsComponent } from './pages/earnings/list-earnings/list-earnings.component';
import { CreateAssetsComponent } from './pages/assets/create-assets/create-assets/create-assets.component';
import { ListReasonsComponent } from './pages/reasons/list-reasons/list-reasons.component';
import { CreateReasonComponent } from './pages/reasons/create-reason/create-reason.component';
import { ListBanksComponent } from './pages/banks/list-banks/list-banks.component';
import { CreateBanksComponent } from './pages/banks/create-banks/create-banks.component';
import { CreateEarningComponent } from './pages/earnings/create-earning/create-earning.component';
import { ListContractsComponent } from './pages/contracts/list-contracts/list-contracts.component';
import { CreateContractComponent } from './pages/contracts/create-contract/create-contract.component';
import { ListAccountsComponent } from './pages/accounts/list-accounts/list-accounts.component';
import { ListUserAccountsComponent } from './pages/accounts/list-user-accounts/list-user-accounts.component';
import { AccountsNavigateComponent } from './pages/accounts/accounts-navigate/accounts-navigate.component';
import { NavigateUserAccountsComponent } from './pages/payments/user-accounts/navigate-user-accounts/navigate-user-accounts.component';
import { IncentivesComponent } from './pages/payments/incentives/incentives.component';
import { DistantFeesComponent } from './pages/payments/distant-fees/distant-fees.component';
import { PartnersComponent } from './pages/settings/partners/partners.component';
import { PartnersCreateComponent } from './pages/settings/partners-create/partners-create.component';
import { CreateFinancesComponent } from './pages/users/user/create-finances/create-finances.component';

import { ZoneComponent } from './pages/zones/zone/zone.component';
import { ZonesListComponent } from './pages/zones/zones-list/zones-list.component';
import { ZonesMapComponent } from './pages/zones/zones-map/zones-map.component';
import { NewZoneComponent } from './pages/zones/new-zone/new-zone.component';
import { IncentiveComponent } from './pages/payments/incentive/incentive.component';
import { CreateBranchmapComponent } from './pages/branchmap/create-branchmap/create-branchmap.component';
import { ListBranchmapComponent } from './pages/branchmap/list-branchmap/list-branchmap.component';
import { EditBranchmapComponent } from './pages/branchmap/edit-branchmap/edit-branchmap.component';

import { ListDeliveriesComponent } from './pages/deliveries/list-deliveries/list-deliveries.component';
import { ListSingleDeliveryComponent } from './pages/deliveries/list-single-delivery/list-single-delivery.component';
import { DischemUsersComponent } from './pages/users/dischem-users/dischem-users.component';
 


const routes: Routes = [
  { path:'',                                component: DashboardComponent, canActivate: [RoleGaurd]},
  { path:'faqs',                            component: FaqsComponent ,canActivate: [RoleGaurd]},
  { path:'fees',                            component: FeesComponent, canActivate: [RoleGaurd] },
  { path:'push',                            component: PushComponent, canActivate: [RoleGaurd] },
  { path:'users',                           component: UsersComponent, canActivate: [RoleGaurd] },
  { path:'silos',                           component: SilosComponent, canActivate: [RoleGaurd] },
  { path:'malls',                           component: MallsComponent, canActivate: [RoleGaurd] },
  { path:'admins',                          component: AdminsComponent, canActivate: [RoleGaurd] },
  { path:'stores',                          component: StorelistComponent, canActivate: [RoleGaurd] },
  { path:'orders',                          component: OrdersComponent, canActivate: [RoleGaurd] },
  { path:'promos',                          component: PromotionalsComponent, canActivate: [RoleGaurd] },
  { path:'drivers',                         component: ListdriversComponent, canActivate: [RoleGaurd] },
  { path:'reviews',                         component: ReviewsComponent, canActivate: [RoleGaurd] },
  { path:'messages',                        component: MessagesComponent, canActivate: [RoleGaurd] },
  { path:'messages',                        component: MessagesComponent, canActivate: [RoleGaurd] },
  { path:'products',                        component: ProductsComponent, canActivate: [RoleGaurd] },
  { path:'users/:id',                       component: UserComponent, canActivate: [RoleGaurd] },
  { path:'dashboard',                       component: DashboardComponent, canActivate: [RoleGaurd] },
  { path:'geofences',                       component: AllGeofencesComponent, canActivate: [RoleGaurd] },
  { path:'addresses',                       component: AddressesComponent, canActivate: [RoleGaurd]},
  { path:'suppliers',                       component: SuppliersComponent, canActivate: [RoleGaurd] },
  { path:'stores/:id',                      component: StoreComponent, canActivate: [RoleGaurd] },
  { path:'scheduling',                      component: SchedulingComponent , canActivate: [RoleGaurd]},
  { path:'orders/:id',                      component: OrderComponent, canActivate: [RoleGaurd] },
  { path:'retailers',                       component: RetailersListComponent , canActivate: [RoleGaurd]},
  { path:'faq-create',                      component: FaqCreateComponent, canActivate: [RoleGaurd] },
  { path:'open_carts',                      component: OpenCartsComponent, canActivate: [RoleGaurd] },
  { path:'categories',                      component: CategoriesComponent, canActivate: [RoleGaurd] },
  { path:'push-create',                     component: PushCreateComponent, canActivate: [] },
  { path:'silo-create',                     component: SiloCreateComponent, canActivate: [RoleGaurd] },
  { path:'products/:id',                    component: ProductNavigateComponent, canActivate: [RoleGaurd] },
  { path:'create-store',                    component: AddStoreComponent , canActivate: [RoleGaurd]},
  { path:'retailer-add',                    component: AddRetailerComponent, canActivate: [RoleGaurd] },
  { path:'create-promo',                    component: CreatePromotionalComponent, canActivate: [RoleGaurd] },
  { path:'retailers/:id',                   component: RetailerComponent , canActivate: [RoleGaurd]},
  { path:'order-exports',                   component: OrdersExportsComponent, canActivate: [RoleGaurd] },
  { path:'trading_hours',                   component: TradingHoursComponent , canActivate: [RoleGaurd]},
  { path:'drivers-create',                  component: CreateDriversComponent, canActivate: [RoleGaurd] },
  { path:'categories/:id',                  component: CategoryComponent , canActivate: [RoleGaurd]},
  { path:'create-product',                  component: ProductCreateComponent, canActivate: [RoleGaurd] },
  { path:'public_holidays',                 component: PublicHolidaysComponent, canActivate: [RoleGaurd]},
  { path:'create-retailer',                 component: AddRetailerComponent, canActivate: [RoleGaurd] },
  { path:'create-category',                 component: AddCategoryComponent , canActivate: [RoleGaurd]},
  { path:'create-retailer-product/:id',     component: RetailerProductCreateComponent, canActivate: [RoleGaurd] },
  { path:'partners',                        component: PartnersComponent, canActivate: [RoleGaurd] },
  
  
  // FINANCE ROUTES

  // Assets
  { path:'assets',                          component: ListAssetsComponent, canActivate: [FinanceGaurd] },
  { path:'create-assets',                   component: CreateAssetsComponent, canActivate: [FinanceGaurd] },

  // Accounts
  { path:'accounts',                        component: AccountsNavigateComponent, canActivate: [FinanceGaurd] },
  { path:'user-accounts/:id',                   component: NavigateUserAccountsComponent, canActivate: [FinanceGaurd] },

  // Banks
  { path:'banks',                           component: ListBanksComponent, canActivate: [FinanceGaurd] },
  { path:'create-banks',                    component: CreateBanksComponent, canActivate: [FinanceGaurd] },

  // Contracts
  { path:'contracts',                       component: ListContractsComponent, canActivate: [FinanceGaurd] },
  { path:'create-contracts',                component: CreateContractComponent, canActivate: [FinanceGaurd] },
  
  
  // Earnings
  { path:'earnings',                        component: ListEarningsComponent, canActivate: [FinanceGaurd] },
  { path:'create-earnings',                 component: CreateEarningComponent, canActivate: [FinanceGaurd] },

  // Payments
  { path:'payments',                        component: ListBatchesComponent, canActivate: [FinanceGaurd], },
  { path:'payment-capture',                 component: PaymentCaptureComponent, canActivate: [FinanceGaurd] },
  { path:'batch-payment/:id',               component: ListPaymentBatchComponent, canActivate: [FinanceGaurd] },
  { path:'incentives',                      component: IncentivesComponent, canActivate: [FinanceGaurd] },

  { path:'incentives/:id',                  component: IncentiveComponent, canActivate: [FinanceGaurd] },
  { path:'distances-fees',                  component: DistantFeesComponent, canActivate: [FinanceGaurd] },
  { path:'create-finances/:id',             component: CreateFinancesComponent, canActivate: [FinanceGaurd]},

  // Reasons
  { path:'reasons',                         component: ListReasonsComponent, canActivate: [FinanceGaurd] },
  { path:'create-reasons',                  component: CreateReasonComponent, canActivate: [FinanceGaurd] },

  //Partners
  {path:'partners',                         component: PartnersComponent, canActivate: []},
  {path:'partners-create',                  component: PartnersCreateComponent, canActivate: []},

  // Zones
  { path: 'zone/:id',                       component: ZoneComponent },
  { path: 'zones-list',                     component: ZonesListComponent },
  { path: 'zones-map',                      component: ZonesMapComponent },
  { path: 'new-zone',                       component: NewZoneComponent },

  { path:'carts',                           component: CartsComponent },
  { path:'cart/:id',                        component: CartNavigationComponent },
  { path:'sections',                        component: SectionsComponent},
  { path:'sections/:id',                    component: SectionComponent },
  { path:'sections_create',                 component: CreateSectionComponent},
  { path:'posts',                           component: PostsComponent },
  { path:'posts/:id',                       component: PostComponent },
  { path:'create-post',                     component: CreatePostComponent },
  { path:'list-all-deliveries',             component: ListDeliveriesComponent },
  { path:'list-single-delivery/:id',        component: ListSingleDeliveryComponent },

  // branch-map
  { path:'create-branchmap',                component: CreateBranchmapComponent },
  { path:'list-branchmap',                  component: ListBranchmapComponent},
  { path:'edit-branchmap/:id',              component: EditBranchmapComponent },
  { path:'dischem',                         component: DischemUsersComponent },

]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
