import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MiscService } from 'src/app/services/misc/misc.service';

@Component({
  selector: 'app-create-banks',
  templateUrl: './create-banks.component.html',
  styleUrls: ['./create-banks.component.scss']
})
export class CreateBanksComponent implements OnInit {

  constructor(public misc: MiscService, public router: Router) { }

  ngOnInit(): void {
  }


  firstForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    branch_code: new UntypedFormControl('', [Validators.required]),
    swift_code: new UntypedFormControl('', [Validators.required]),
    account_number_length:new UntypedFormControl('', [Validators.required]),
    account_number_regex:  new UntypedFormControl('', [Validators.required]),
  });

  async AddBank(){
    
    await this.misc.apiPost('payments', "admin/banks", this.firstForm.value)
  
    this.router.navigate(['/banks'])
  }
}
