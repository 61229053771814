import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-branchmap',
  templateUrl: './edit-branchmap.component.html',
  styleUrls: ['./edit-branchmap.component.scss']
})
export class EditBranchmapComponent implements OnInit {

  constructor(public route: ActivatedRoute,) { }
  id
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
  }

}
