import { Injectable } from '@angular/core';
import { MiscService } from '../misc/misc.service';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  accounts
  user_accounts
  account_summary
  account_summary_data = []
  account_assets
  account_payments
  accounts_earnings

  constructor(public misc: MiscService) { }

  async getAccounts(page){
    await this.misc.apiCall(`payments`, `admin/accounts?page=${page}`).then((res: any) => {
      this.accounts = res[`accounts`]
    })
  }

  async getUserAccounts(page){
    await this.misc.apiCall(`payments`, `admin/user_accounts?page=${page}`).then((res: any) => {
      this.user_accounts = res[`user_accounts`]
    })
  }

  async getUserAccountSummary(id){
    await this.misc.apiCall(`payments`, `admin/user_accounts/${id}/summary`).then((res: any) => {
      this.account_summary = res
      if (this.account_summary_data.length <= 0){
        this.account_summary_data.push(res['data'])
      }
    
      console.log("this.account_summary_data :",this.account_summary_data)
    })
  }

  async getUserAccountAssets(id){
    await this.misc.apiCall(`payments`, `admin/user_accounts/${id}/assets`).then((res: any) => {
      console.log(res)
      this.account_assets = res['data']

      // console.log(this.account_summary)
      // console.log(this.account_summary_data)
    })
  }

  async getUserEarnings(id){
    await this.misc.apiCall(`payments`, `admin/user_accounts/${id}/earnings`).then((res: any) => {
      console.log(res)
      this.accounts_earnings = res['data']
console.log(res)
      
      // console.log(this.account_summary)
      // console.log(this.account_summary_data)
    })
  }


  // hierdie function ontvang n id van die account payments page sodat ons die spesifieke data kan kry vir die specified id
  async getUserAccountPayments(id){
    // 
    await this.misc.apiCall(`payments`, `admin/user_accounts/${id}/payments`).then((res: any) => {
      // na ons die api call doen assign ons die response data na die appropriate variable
      // in die geval is die variable account_payments
      // hierdie data gaan ons gebruik in die html, maar eers gaan ons die Columns define wat ons wil geburuik in die html (sien die payments.components.ts)
      this.account_payments = res['data']
      console.log(this.account_payments)
    })
  }
}
