import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})
export class AdminsComponent implements OnInit {

  inputTouch = false

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }

  Columns = ['name', 'email', 'phone', 'verified'];

  admins
  count
  users = []
  page = 1
  limit = 10
  search = ''
  userSearch = false

  constructor(public api: ApiService,
              public message: MessagesService,
              public router: Router) { }

  ngOnInit(): void {
    this.getAdmins();
  }

// =================
//      ADMINS
// =================

  getAdmins(){
    this.api.getList(`auth`, `admin/admins?page=${this.page}&per_page=${this.limit}&search=${this.search}`).subscribe((admins: any) => {
      this.admins = admins.users;
      this.count = admins.total
    })
  }

  navigateRoute(user){
    this.router.navigate([`/users/${user.ID}`])
    localStorage.setItem('customer_navigation', 'admins')
  }

  clearSearch(){
    this.search = ''
    this.getAdmins()
  }

  searchReset(){
      this.page = 1
      this.getAdmins()
      localStorage.setItem('adminsPage', JSON.stringify(this.page))
    }
  
// =================
//    PAGINATION
// =================

  searching(){
    this.page = 1;
  }
  
    setLimit(limit){
          this.limit = limit
          if(this.search == ''){
          this.getAdmins()
      }
    }
  
    next(){
          this.page++
          if(this.search == ''){
            this.getAdmins()
      }
    }
  
    previous(){
      if(this.page > 1){
        this.page--
        if(this.search == ''){
          this.getAdmins()
        }   
      }
    }
}
