<div class="add-card"  >
  
    <div class="header">
      <h3>add sections to mall</h3>
      <mat-icon (click)='closeDialog()'>clear</mat-icon>
    </div>

    <div class="top">
        <div class="search" >
            <mat-icon (click)='getSections()' matTooltip='search' class='search-icon' [style.left]="inputTouch ? '-20px' : '10px' ">search </mat-icon>
            <mat-icon *ngIf='search' (click)='clearSearch()'  matTooltip='clear' class='clear-icon'>clear</mat-icon>
            <input matInput (keydown.enter)='getSections()' [(ngModel)]="search" placeholder="Search sections"  #input (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
        </div>
    </div>
      
        <table  mat-table [dataSource]='sections' matSort >

            <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let item" > 
                <p> {{item.title}} </p>
            </td>
            </ng-container>

            <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
            <td mat-cell *matCellDef="let item" > 
                <p> {{item.type}} </p>
            </td>
            </ng-container>

            <ng-container matColumnDef="add">
            <th mat-header-cell  *matHeaderCellDef> </th>
            <td mat-cell  *matCellDef="let item" > 
                <mat-icon (click)='toggleSelection(item)'>add_circle_outline</mat-icon>
            </td>
        </ng-container>

            <tr mat-header-row *matHeaderRowDef="Columns"></tr>
            <tr mat-row class='action'  *matRowDef="let item; columns: Columns;"></tr>
        </table>


        <div class="add-list">
            <mat-chip-list aria-label="Fish selection">
                <p *ngIf='itemsArr.length > 0'>adding :</p>
            <mat-chip id='selectedCat' #c="matChip" id='chosen-category' [value]="item" *ngFor='let item of itemsArr'  >
                <p > {{item.title}} </p>
                <mat-icon matChipRemove (click)='toggleSelection(item)'>cancel</mat-icon>
            </mat-chip>
            </mat-chip-list>
        </div>


    <div class="btn">
        <button mat-flat-button class='button' (click)='addItems()'>Confirm</button>
    </div>
  
</div>
  

