import { Component, OnInit } from '@angular/core'
import { ApiService } from 'src/app/services/api.service'
import { AuthService } from 'src/app/services/auth.service'
import { MessagesService } from 'src/app/services/messages.service'

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  Columns = ['question','body', 'edit', 'delete']

  id
  faq
  faqs = []
  page = 1
  limit = 10
  retries = 0
  edit = false
  delete = false

  constructor(public api: ApiService,
              public message: MessagesService,
              public auth: AuthService) { }

  ngOnInit(): void {
    this.getFaqs()
  }

  toggleEdit(faq){
    if(this.edit){
      this.getFaqs()
    }
    this.faq = faq
    this.edit = !this.edit;
  }

  deleteCheck(faq){
    this.delete = !this.delete;
    this.faq = faq
  }

  getFaqs(){
    this.api.getList(`catelog`, `faqs?page=${this.page}&per_page=${this.limit}`).subscribe((faqs: any) => {
      this.faqs = faqs
    }, error => {
      this.message.showSnack(error.error)
      if(this.retries < 3 && error.status !== 401){
        this.retries++
        this.getFaqs()
      }
    })
  }

  updateFaq(){
    // console.log(this.faq.body)
    let FAQ = {
      id: this.faq.id,
      heading: this.faq.heading,
      title: this.faq.title,
      body: this.faq.body
    }

    this.api.patch(`catelog`, `faqs`, FAQ).subscribe((faq: any) => {
      this.message.showSnack(`Successfully updated ${faq.heading}`);
      this.toggleEdit(faq);
    }, error => {
      // console.log(error);
      this.message.showSnack(error.error);
    })
  }

  deleteFaq(){
    this.api.delete(`catelog`, `faqs/${this.faq.id}`).subscribe((faq: any) => {
      this.message.showSnack(`Successfully removed FAQ`);
      this.getFaqs();
      this.deleteCheck(faq);
    }, error => {
      // console.log(error);
      this.message.showSnack(error.error);
    })
  }

  // ======================
  //      PAGINATION
  // ======================


  searchFaqs(e){}
  setLimit(limit){
    this.limit = limit;
    this.getFaqs();
  }

  next(){
    if(this.faqs.length < this.limit){
      this.message.showSnack('no more faqs to show');
    } else {
    this.page++
    this.getFaqs();
    }
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.getFaqs();
    }
  }
}
