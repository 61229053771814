import { Component, OnInit } from '@angular/core'
import { ApiService } from 'src/app/services/api.service'
import { MessagesService } from 'src/app/services/messages.service'
import * as moment from 'moment'
import { AuthService } from 'src/app/services/auth.service'


@Component({
  selector: 'app-push',
  templateUrl: './push.component.html',
  styleUrls: ['./push.component.scss']
})
export class PushComponent implements OnInit {
  Columns = ['title', 'body', 'date', 'time', 'sent', 'edit']
  tempColumns = ['title', 'body', 'sent_at', 'sent', 'send']

  inputTouch = false

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }

  body
  time
  title
  notification
  notifications
  day

  page   = 1
  edit   = false
  send   = false
  limit  = 10
  search = ''
  create = false
  delete = false


  constructor(private api: ApiService,
              private message: MessagesService,
              public auth: AuthService) { }

  ngOnInit(): void {
    if(localStorage.getItem('pushSearch')){
      this.search = localStorage.getItem('pushSearch')
    }

    if(localStorage.getItem('pushPage')){
      this.page = JSON.parse(localStorage.getItem('pushPage'))
    }

    if(localStorage.getItem('limit')){
      this.limit = JSON.parse(localStorage.getItem('limit'))
    }

    this.getNotifications(this.search)
    this.time = moment().format('HH:mm')
    this.day = moment().format('LLLL')
  }

  // =================
  //      TOGGLE
  // =================

  toggleCreate(){
    this.create = !this.create
  }

  toggleEdit(note){
    this.edit = !this.edit
    this.getNotifications(this.search)
    this.notification = note
    this.title = note.title
    this.body  = note.body
  }

  toggleSend(note){
    this.send = !this.send
    this.notification = note
  }

  deleteDialog(note){
    this.delete = !this.delete
    this.notification = note
  }

  
  
  // =================
  //      GET
  // =================

  getNotifications(e){
    localStorage.setItem('pushSearch', e)
    this.api.getList(`fulfilment`, `admin/notifications?page=${this.page}&per_page=${this.limit}&search=${e}`).subscribe((notifications: any) => {
      // console.log(notifications)
      this.notifications = notifications.notifications
    })
  }

  clearSearch(){
    this.search = ''
    this.getNotifications(this.search)
  }


  // =================
  //      PUSH
  // =================

  sendNotification(){
    let notification = {
      title: this.title,
      body: this.body,
    }

    this.api.post(`fulfilment`, `admin/notifications/${this.notification.ID}/send_to_all`, "").subscribe((note: any) => {
      this.message.showSnack(`Succesfully sent push notification`)
      this.getNotifications(this.search)
      this.toggleSend(this.notification)
    })
  }
  

  // =================
  //     UPDATE
  // =================

  updateNotification(){
    
    let notification = {
      title: this.title,
      body: this.body,
    }

    this.api.patch(`fulfilment`, `admin/notifications/${this.notification.ID}`, notification).subscribe((note: any) => {
      this.message.showSnack(`succesfully updated push notification`)
      this.toggleEdit(note)
    }, error => {
      this.message.showSnack(error.statusText)
    })

  }

  removeNotification(){
    this.api.delete(`fulfilment`, `admin/notifications/${this.notification.ID}`).subscribe((data: any) => {
      this.message.showSnack('Successfully removed push notification')
      this.getNotifications(this.search)
      this.deleteDialog(this.notification)
    })
  }

  duplicate(note){
    let notification = {
      title: note.title,
      body: note.body,
    }

    this.api.post(`fulfilment`, `admin/notifications`, notification).subscribe((note: any) => {
      this.message.showSnack(`succesfully duplicated push notification`)
      this.getNotifications(this.search)
      if(this.send){
        this.toggleSend(this.notification)
      }
    }, error => {
      // console.log(error)
      this.message.showSnack(error.statusText)
    })

  }

  // =================
  //    PAGINATION
  // =================

  setLimit(limit){
    this.limit = limit
    this.getNotifications(this.search)
    localStorage.setItem('limit', JSON.stringify(limit))
  }

  next(){
    this.page++
    this.getNotifications(this.search)
    localStorage.setItem('pushPage', JSON.stringify(this.page))
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.getNotifications(this.search)
    }
    localStorage.setItem('pushPage', JSON.stringify(this.page))
  }

  start(){
    this.page = 1;
      this.getNotifications(this.search)

    localStorage.setItem('productsPage', JSON.stringify(this.page))
  }
}
