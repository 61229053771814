<div class="search">
  <mat-icon
    (click)="searchReset()"
    matTooltip="search"
    class="search-icon"
    [style.left]="misc.inputTouch ? '-20px' : '10px'"
    >search</mat-icon
  >
  <mat-icon
    *ngIf="search"
    (click)="clearSearch()"
    matTooltip="clear"
    class="clear-icon"
    >clear</mat-icon
  >
  <input
    matInput
    (keydown.enter)="searchReset()"
    [(ngModel)]="search"
    placeholder="Search"
    #input
    (blur)="misc.onBlur()"
    (focus)="misc.onFocus()"
    [style.left]="misc.inputTouch ? '10px' : '35px'"
  />
</div>
 

<table mat-table [dataSource]="earnings.earnings">
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>date</th>
    <td mat-cell *matCellDef="let earning">
      <p>{{ earning.CreatedAt.substring(0, 10) }}</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="user_name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Driver</th>
    <td mat-cell *matCellDef="let earning">{{ earning.user_name }}</td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>description</th>
    <td mat-cell *matCellDef="let earning">{{ earning.description }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>status</th>
    <td mat-cell *matCellDef="let earning">{{ earning.status }}</td>
  </ng-container>

  <ng-container matColumnDef="earning_type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>type</th>
    <td mat-cell *matCellDef="let earning">
      <p>{{ earning.earning_type }}</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>amount</th>
    <td mat-cell *matCellDef="let earning">
      <p>{{ earning.amount | currency: "R ":"code" }}</p>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="Columns"></tr>
  <tr mat-row *matRowDef="let earning; columns: Columns" class="action"></tr>
</table>

<div id="pager">
  <div class="page">
    <div class="actions"  id="{{ auth.expand ? 'create-expand' : 'create' }}">
      <button mat-flat-button (click)="viewEarningTypes()">
        View Earning Types
      </button>
      <button mat-flat-button class="button" routerLink="/create-earnings">
        New Earning
      </button>
    </div>
    <div>Per page:</div>
    <span class="silo-filter pagination">
      <button mat-button [matMenuTriggerFor]="paginate">
        <span>{{ limit }}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #paginate="matMenu">
        <button mat-menu-item (click)="setLimit(10)">10</button>
        <button mat-menu-item (click)="setLimit(20)">20</button>
        <button mat-menu-item (click)="setLimit(50)">50</button>
      </mat-menu>
    </span>

    <div
      (click)="start()"
      matTooltip="Skip to start"
      class="action"
      *ngIf="page > 1"
    >
      back to start
    </div>
    <p (click)="previous()" class="action">previous</p>
    <div>{{ page }}</div>
    <p (click)="next()" class="action">next</p>
    <div></div>
  </div>
</div>
