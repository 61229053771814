<div class="title">
  <h1>Incentives</h1>
  <button mat-raised-button class="button" (click)="add()">
    ADD INCENTIVE
  </button>
</div>
<table>
  <tr>
    <th>Name</th>
    <th>Type</th>
    <th>Action</th>
    <th>When</th>
    <th>silo ID</th>
    <th>Start</th>
    <th>End</th>
    <th>From</th>
    <th>Until</th>
    <th>Amount</th>
    <th colspan="3">Manage</th>
  </tr>
  <tbody>
    <tr *ngFor="let incentive of incentives">
      <td
        class="pointer"
        title="Add drivers"
      >
        {{ incentive.name }}
      </td>
      <td>{{ incentive.type }}</td>
      <td>{{ incentive.driver_action }}</td>
      <td>{{ incentive.when }}</td>
      <td>
        <p *ngIf="incentive.silo_id !== ''">{{ incentive.silo_id }}</p>
        <p *ngIf="incentive.silo_id == ''" class="empty">all</p>
      </td>
      <td>{{ incentive.start_at }}</td>
      <td>{{ incentive.end_at }}</td>
      <td>{{ incentive.active_from | date: "longDate" }}</td>
      <td>{{ incentive.active_until | date: "longDate" }}</td>

      <td>{{ incentive.amount | currency: "R " }}</td>
      <td>
        <mat-icon
          [routerLink]="'/incentives/' + incentive.ID"
          class="material-icons-outlined"
          *ngIf="!incentive.all_drivers"
          title="Add drivers"
          >settings</mat-icon
        >
      </td>
      <td>
        <mat-icon (click)="edit(incentive)" class="material-icons-outlined"
          >edit</mat-icon
        >
      </td>
      <td>
        <mat-icon (click)="delete(incentive)" class="material-icons-outlined"
          >delete</mat-icon
        >
      </td>
    </tr>
  </tbody>
</table>

<div class="mask" *ngIf="showForm">
  <div class="form">
    <div class="title">

      <h2 *ngIf="id==''">Add Incentive </h2>

      <h2 *ngIf="id !=''">Edit Incentive </h2>
    
      <mat-icon *ngIf="id==''" (click)="add()">close</mat-icon>
      <mat-icon *ngIf="id !='' " (click)="close()">close</mat-icon>
    </div>
    <form [formGroup]="incentiveForm">
      <mat-form-field appearance="outline" class="field">
        <mat-label>Name</mat-label>
        <input
          type="text"
          matInput
          placeholder=""
          id="name"
          name="name"
          required
          autocomplete="off"
          formControlName="name"
        />
      </mat-form-field>

      <mat-form-field appearance="outline" class="field">
        <mat-label>Type</mat-label>
        <mat-select
          name="type"
          class="select-panel"
          formControlName="type"
          (selectionChange)="selectType($event.value)"
        >
          <mat-select-trigger class="selection">
            &nbsp;{{ incentive_type }}
          </mat-select-trigger>
          <mat-option *ngFor="let type of types" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="field">
        <mat-label>Amount</mat-label>
        <input
          type="number"
          matInput
          placeholder=""
          name="amount"
          required
          autocomplete="off"
          formControlName="amount"
        />
      </mat-form-field>


      <div *ngIf="incentive_type == 'guarantee'">
 

        <mat-form-field appearance="outline" class="field">
          <mat-label>Active date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              name="active_from"
              placeholder="Start date"
              formControlName="active_from"
            />
            <input
              matEndDate
              name="active_until"
              placeholder="End date"
              formControlName="active_until"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        </div>

      <div *ngIf="incentive_type == 'incentive'">
        <mat-form-field appearance="outline" class="field">
          <mat-label>Action</mat-label>
          <mat-select
            name="driver_action"
            class="select-panel"
            formControlName="driver_action"
            (selectionChange)="selectAction($event.value)"
          >
            <mat-select-trigger class="selection">
              &nbsp;{{ action }}
            </mat-select-trigger>
            <mat-option *ngFor="let action of driverActions" [value]="action">
              {{ action }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="field">
          <mat-label>When</mat-label>
          <mat-select
            name="when"
            class="select-panel"
            formControlName="when"
            (selectionChange)="selectWhen($event.value)"
          >
            <mat-select-trigger class="selection">
              &nbsp;{{ when }}
            </mat-select-trigger>
            <mat-option *ngFor="let when of whenOptions" [value]="when">
              {{ when }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Start Time</mat-label>
          <input
            type="time"
            matInput
            name="start_at"
            min="08:00"
            max="18:00"
            id="start_at"
            formControlName="start_at"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="showEndTime">
          <mat-label>End Time</mat-label>
          <input
            type="time"
            matInput
            name="end_at"
            min="08:00"
            max="18:00"
            formControlName="end_at"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="field">
          <mat-label>Active date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              name="active_from"
              placeholder="Start date"
              formControlName="active_from"
            />
            <input
              matEndDate
              name="active_until"
              placeholder="End date"
              formControlName="active_until"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <mat-slide-toggle
          class="field"
          formControlName="all_drivers"
        >
          All Drivers</mat-slide-toggle
        >
        <mat-slide-toggle
          class="field"
          formControlName="silo_specific"
          [(ngModel)]="silo_specific"
          (change)="onToggle($event)"
        >
          This incentive is silo specific</mat-slide-toggle
        >

        <mat-form-field appearance="outline"  class="field" *ngIf="silo_specific">
          <mat-label>silo</mat-label>
          <mat-select [(ngModel)]="misc.silo" formControlName="silo_id">
            <mat-option *ngFor="let silo of misc.silos" [value]="silo.id">
              {{ silo.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <button (click)="save()" mat-raised-button class="button"  *ngIf="id==''">CREATE</button>
    <button (click)="save()" mat-raised-button class="button"  *ngIf="id!=''">SAVE</button>
  </div>
</div>

<div id="pager">
  <div class="page">
    <div>Per page:</div>
    <span class="silo-filter pagination">
      <button mat-button [matMenuTriggerFor]="paginate">
        <span>{{ limit }}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #paginate="matMenu">
        <button mat-menu-item (click)="setLimit(10)">10</button>
        <button mat-menu-item (click)="setLimit(20)">20</button>
        <button mat-menu-item (click)="setLimit(50)">50</button>
      </mat-menu>
    </span>

    <div
      (click)="start()"
      matTooltip="Skip to start"
      class="action"
      *ngIf="page > 1"
    >
      back to start
    </div>
    <p (click)="previous()" class="action">previous</p>
    <div>{{ page }}</div>
    <p (click)="next()" class="action">next</p>
    <div></div>
  </div>
</div>
