
<div class="search">
  <mat-icon (click)='searchReset()' matTooltip='search' class='search-icon' [style.left]="inputTouch ? '-20px' : '10px' ">search </mat-icon>
  <mat-icon *ngIf='branchservice.search' (click)='clearSearch()'  matTooltip='clear' class='clear-icon'>clear</mat-icon>
  <input matInput (keydown.enter)='searchReset()' [(ngModel)]="branchservice.search" placeholder="Search"  #input (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
</div>

<!-- ================== -->
<!--     BRANCHMAP      -->
<!-- ================== -->

<table class="table">
  <thead>
    <tr>
      <th class="header" id="sort">Loop ID</th>
      <th class="header" id="icon">Partner ID</th>
      <th class="header"></th>
    </tr>
  </thead>
  <tbody cdkDropList>
    <tr
      class="action"
      *ngFor="let section of branchservice.branchmapsList"
      cdkDrag
      cdkDragLockAxis="y"
    >
      <th routerLink="/edit-branchmap/{{ section.id }}">
        <p>{{ section.loop_id }}</p>
      </th>
      <th>{{ section.partner_branch_id }}</th>
    
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <th class="more">
        <mat-icon matTooltip="more" [matMenuTriggerFor]="menu" class="action"
          >more_horiz</mat-icon
        >
        <mat-menu #menu="matMenu">
        
          <button mat-menu-item (click)="updateBranchMap(section)">
            <mat-icon class="material-icons-outlined">edit</mat-icon>
            <span>edit branch map </span>
          </button>
          <button mat-menu-item (click)="removeBranchMap(section)">
            <mat-icon>delete_outline</mat-icon>
            <span>delete branch map</span>
          </button>
        </mat-menu>
      </th>
    </tr>
  </tbody>
</table>

<!-- New Section button -->

<div id="pager">
  <button
    mat-flat-button
    class="button"
    id="{{true ? 'create-expand' : 'create' }}"
    routerLink="/create-branchmap"
  >
    New
  </button>
  <div class="page"></div>
</div>
<div id="pager" >
  <div class="page"> 

    <button mat-flat-button class='button' id="{{auth.expand ? 'create-expand': 'create'}}" routerLink='/create-branchmap'>New</button>

  <div> Per page:</div> 
  <span class="silo-filter pagination">
    <button mat-button [matMenuTriggerFor]="paginate">
      <span>{{branchservice.limit}}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #paginate="matMenu" >
      <button mat-menu-item (click)="setLimit(10)">10</button>
      <button mat-menu-item (click)="setLimit(20)">20</button>
      <button mat-menu-item (click)="setLimit(50)">50</button>
    </mat-menu>
  </span>

  <div (click)='start()' matTooltip="Skip to start" class='action' *ngIf='branchservice.page > 1'>back to start</div>
  <p (click)='previous()' class='action'>previous</p>
  <div>{{branchservice.page}}</div>
    <p (click)='next()' class='action'>next</p>
    <div> </div>
  </div>
</div>