import { ApiService }                               from 'src/app/services/api.service'
import { AuthService }                              from './../../../services/auth.service'
import { MessagesService }                          from './../../../services/messages.service'
import { Component, Inject, OnInit, EventEmitter }  from '@angular/core'
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'


@Component({
  selector: 'app-malls',
  templateUrl: './malls.component.html',
  styleUrls: ['./malls.component.scss']
})
export class MallsComponent implements OnInit {
  Columns = ['name', 'address', 'email', 'phone', 'active', 'more']

  malls
  id

  page    = 1
  limit   = 10
  search  = ''


  constructor(public api: ApiService,
              public message: MessagesService,
              public auth: AuthService,
              public dialog: MatDialog
              ) { }

  ngOnInit(): void {
    this.getAllMalls()

    if(localStorage.getItem('mallsPage')){
      this.page = JSON.parse(localStorage.getItem('mallsPage'))
    }
  }


  getAllMalls(){
    this.api.getList(`catelog`, `malls?search=${this.search}&page=${this.page}&per_page=${this.limit}`).subscribe((malls: any) => {
      this.malls = malls
    })
  }


  // ====================
  // MALL STATUS TOGGLE
  // ====================

  toggleMallStatus(mall){
    let updatedStore = {
      id: mall.id,
      active: false
    }

    if(mall.active == false){
      updatedStore.active = true
    }

    this.api.patch('catelog', `malls/${mall.id}`, updatedStore).subscribe((store: any) => {
      if(store.active == true){
        this.message.showSnack('Succesfully Activated mall')
      } else {
        this.message.showSnack('Successfully De-activated mall')
      }
      this.getAllMalls()
    } , error => {
      this.message.showSnack(error.error);
    })
  }


  // =================
  //     PAGINATION
  // =================

  setLimit(limit){
    this.limit = limit
    this.getAllMalls()
    localStorage.setItem('limit', JSON.stringify(limit))
  }

  next(){
    this.page++
    this.getAllMalls()
    localStorage.setItem('mallsPage', JSON.stringify(this.page))
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.getAllMalls()
      localStorage.setItem('mallsPage', JSON.stringify(this.page))
    }
  }

  // =======================
  //  MALL DELETE  DIALOG
  // =======================



    // open dialog for banner update
    removeMall(mall){
      const dialog = this.dialog.open(RemoveMallDialog, {
        width: '550px',
        height: '200px',
        data: {
          mall: mall
        }
      })
  
      dialog.afterClosed().subscribe(() => {
        this.getAllMalls()
      })
    }
}


@Component({
  selector: 'delete-dialog',
  templateUrl: '../../../components/dialogs/delete-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss']
})

export class RemoveMallDialog {

  
  inputTouch  = false
  heading     = `mall`
  body        = `Are you sure you want to delete this mall?`

  mall

  // get section info after success
  onAdd       = new EventEmitter()
  
  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }


  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: { mall }, 
              private api : ApiService, 
              private message: MessagesService,  
              public dialogRef: MatDialogRef<RemoveMallDialog>) {

  }

  ngOnInit(): void {
    this.mall = this.data.mall

  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

  removeItem(){
    this.api.delete('catelog', `malls/${this.mall.id}`).subscribe((data: any) => {
      this.message.showSnack(`succesfully removed mall`)
      this.closeDialog()
    })
  }

}
