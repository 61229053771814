import { ActivatedRoute, Router }     from '@angular/router'
import { Component, Inject, OnInit, EventEmitter }  from '@angular/core'
import { MAT_DIALOG_DATA }            from '@angular/material/dialog'
import { ApiService }                 from './../../../services/api.service'
import { MatDialog, MatDialogRef }    from '@angular/material/dialog'
import { MessagesService }            from './../../../services/messages.service'
import { environment as devEnv }      from '../../.././../environments/environment'


@Component({
  selector: 'app-mall-detail',
  templateUrl: './mall-detail.component.html',
  styleUrls: ['./mall-detail.component.scss']
})
export class MallDetailComponent implements OnInit {

  id
  mall
  image
  
  // mall models
  lng
  lat
  name
  desc
  phone
  email
  address

  edit          = false

  constructor(public api: ApiService,
              public message: MessagesService,
              public route: ActivatedRoute,
              public router: Router,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.id = JSON.parse(this.route.snapshot.paramMap.get('id'))
    this.getMall()
  }

  getMall(){
    this.api.getList(`catelog`, `malls/${this.id}`).subscribe((mall: any) => {
      // console.log(mall)
      this.mall   = mall
      this.image  = mall.image

      this.name     = mall.name
      this.email    = mall.email
      this.phone    = mall.phone
      this.address  = mall.physical_address
      this.desc     = mall.description
    
    })
  }

  editMall(){
    this.edit = !this.edit
  }

  updateMall(){
    let mall = {
      id: this.id,
      name: this.name,
      description: this.desc,
      email: this.email,
      phone: this.phone,
      physical_address: this.address,
      latitude: this.mall.latitude,
      longitude: this.mall.longitude,
    }

    this.api.patch(`catelog`, `malls/${this.id}`, mall).subscribe((mall: any) => {
      this.message.showSnack(`successfully updated ${this.mall.name}`)
      this.editMall()
      this.getMall()
    })
  }
  
  toggleMallStatus(){
    let updatedStore = {
      id: this.id,
      active: this.mall.active
    }

    this.api.patch('catelog', `malls/${this.id}`, updatedStore).subscribe((store: any) => {
      if(store.active == true){
        this.message.showSnack('Succesfully Activated mall')
      } else {
        this.message.showSnack('Successfully De-activated mall')
      }
      this.getMall()
    } , error => {
      this.message.showSnack(error.error);
    })
  }

  onAutocompleteSelected(e){
    this.mall.latitude = e.geometry.location.lat();
    this.mall.longitude = e.geometry.location.lng();
    this.address = e.formatted_address;
  }

  // open dialog for banner update
  updateBannerDialog(){
    const dialog = this.dialog.open(bannerUpdateDialog, {
      width: '550px',
      maxWidth: '550px',
      data: {
        image: this.image,
        mall: this.mall
      }
    })

    dialog.afterClosed().subscribe(() => {
      this.getMall()
    })
  }


}


 // ==========================
  //   BANNER UPDATE DIALOG
  // =========================

  @Component({
    selector: 'invoice-dialog',
    templateUrl: 'banner-update.html',
    styleUrls: ['./banner-update.component.scss']
  })
  export class bannerUpdateDialog {

    // getMall after succesfull update
    onAdd = new EventEmitter()

    mall
    image
    imageFormat = false
    imageFile: {resource: string, file: any, name: string}

    shade = false
  
  
  
    constructor(@Inject(MAT_DIALOG_DATA) 
                public data: { image, mall },  
                private api : ApiService, 
                private message: MessagesService, 
                public dialogRef: MatDialogRef<bannerUpdateDialog> ) {
                }
  
    ngOnInit(): void {
      this.mall   = this.data.mall
      this.image  = this.mall.image
    }

    closeDialog() {
      this.dialogRef.close()
      this.onAdd.emit()
    }

    getMall(){
      this.api.getList(`catelog`, `malls/${this.mall.id}`).subscribe((mall: any) => {
        // console.log(mall)
        this.mall   = mall
        this.image  = mall.image
      })
    }
  

  // ========================
  //       IMAGE PATCH
  // ========================

    imagePreview(event) {
      if (event.target.files && event.target.files[0]) {
          let reader = new FileReader()

          reader.onload = (_event: any) => {
              this.imageFile = {
                  resource: _event.target.result,
                  file: event.srcElement.files[0],
                  name: event.srcElement.files[0].name
              }

              if(this.imageFile.name.includes('png') || this.imageFile.name.includes('jpg') || this.imageFile.name.includes('jpeg') ||  this.imageFile.name.includes('webp') ){
                this.imageFile.name = 'image.png'
                this.imageFormat = true
                this.image = this.imageFile.resource
            } else {
              this.message.showSnack(`oops!   that is an unsupported image type`)
            }

          }
          reader.readAsDataURL(event.target.files[0])
      }
    }


    updateBanner(){
      this.shade = true

      this.message.showSnack('updating mall banner image')
      var myHeaders = new Headers()

      myHeaders.append("Authorization", "Bearer " + this.api.token)
  
      var formdata = new FormData()
  
      formdata.append("file", this.imageFile.file, this.imageFile.name)
      formdata.append("body", "{ \"resource\":\"malls\", \"resource_id\":"+ this.mall.id +",\"x_dim\":1, \"y_dim\":1}")
      var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata
      }
  
  
      if(!devEnv.production){
        fetch("https://api.api-dev.quench.org.za/catalogue/v1/image", requestOptions)
          .then(response => response.text())
          .then(result =>
            setTimeout(() => {
              this.message.showSnack(`successfully updated mall image`)
              setTimeout(() => {
                this.closeDialog()
                this.shade = false
              }, 3100)
            }, 8000))
          .catch(error => {
            this.message.showSnack(error.error)
          })
        }
  
        if(devEnv.production){
          fetch("https://api.quench.org.za/catalogue/v1/image", requestOptions)
            .then(response => response.text())
            .then(result =>
              setTimeout(() => {
                this.message.showSnack(`successfully updated mall image`)
                setTimeout(() => {
                  this.closeDialog()
                }, 3100)
              }, 8000))
            .catch(error => {
              this.message.showSnack(error.error)
            })
        }
    }
  
  }
