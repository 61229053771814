import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PusherService } from 'src/app/services/pusher.service';
import { ApiService } from 'src/app/services/api.service';
import { MessagesService } from 'src/app/services/messages.service';
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  id;
  user;
  email;
  phone;
  loyalties;
  user_wallet;
  addressDetails;

  currentRoles = [];
  nearestStores = [];

  chat = false;
  viewMore = false;
  updateUserEmail = false;
  viewMoreAddress = false;
  updateUserPhone = false;
  updateUserWallet = false;
  updateUserRoles = false;
  updateUserLoyalty = false;
  showNearestDepots = false;

  expandUserInfo() {
    this.viewMore = !this.viewMore;
  }

  expandUserAddress() {
    this.viewMoreAddress = !this.viewMoreAddress;
  }

  constructor(
    public api: ApiService,
    public route: ActivatedRoute,
    public message: MessagesService,
    public pusherService: PusherService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getUserBasicInfo();
    this.getUserWallet();
  }

  // =============
  //    UPDATES
  // =============

  updateEmail() {
    this.updateUserEmail = !this.updateUserEmail;
  }

  updatePhone() {
    this.updateUserPhone = !this.updateUserPhone;
  }

  updateWallet() {
    this.updateUserWallet = !this.updateUserWallet;
  }

  updateRoles() {
    this.updateUserRoles = !this.updateUserRoles;
  }

  updateLoyalty() {
    this.updateUserLoyalty = !this.updateUserLoyalty;
  }

  nearestDepots() {
    this.showNearestDepots = !this.showNearestDepots;
  }

  saveUser() {
    let user = {
      email: this.email,
      phone: this.phone,
      email_verified: this.user.email_verified,
      phone_verified: this.user.phone_verified,
    };

    this.api
      .patch(`auth`, `admin/users/${this.id}`, user)
      .subscribe((user: any) => {
        this.message.showSnack('Succesfully updated user');
        this.getUserBasicInfo();
        this.updateUserEmail = false;
        this.updateUserPhone = false;
      });
  }

  // ===================
  //  GENERAL USER INFO
  // ===================

  getUserBasicInfo() {
    this.api.get('auth', `admin/users`, this.id).subscribe((data: any) => {
      // console.log(data)
      this.user = data.user;
      this.email = data.user.email;
      this.phone = data.user.phone;
      this.addressDetails = data.address;
      this.currentRoles = data.userroles;
      this.loyalties = data.loyalty_profiles;

      if (data.address.latitude){
        this.GetNearestDepots(data.address);
      }
    });
  }

  // ===============
  //   USER STATUS
  // ===============

  toggleUserStatus() {
    let user = {
      active: this.user.active,
      email_verified: true,
      phone_verified: true,
    };

    this.api.patch('auth', `admin/users/${this.id}`, user).subscribe(
      (user: any) => {
        if (user.user.active == false) {
          this.message.showSnack(
            `succesfully de-activated ${user.user.first_name} ${user.user.last_name}`
          );
        } else {
          this.message.showSnack(
            `succesfully activated ${user.user.first_name} ${user.user.last_name}`
          );
        }
        this.getUserBasicInfo();
      },
      (error) => {
        this.message.showSnack(error.error.message);
      }
    );
  }

  // ===============
  //    WALLET
  // ===============

  getUserWallet() {
    this.api.get('payments', 'admin/users', this.id + '/wallets').subscribe(
      (data: any) => {
        this.user_wallet = data.wallet;
      },
      (error) => {
        alert(JSON.stringify(error));
      }
    );
  }

  // ==================
  //   NEAREST STORES
  // ==================

  GetNearestDepots(address) {
    this.api
      .getList(
        'catelog',
        'nearest?latitude=' +
          address.latitude +
          '&longitude=' +
          address.longitude
      )
      .subscribe(
        (data: any) => {
          data.forEach((store) => {
            if (
              store.silo_id == 1 ||
              store.silo_id == 2 ||
              store.silo_id == 3
            ) {
              this.nearestStores.push(store);
            }
          });
        },
        (error) => {
          this.message.showSnack(error.error);
        }
      );
  }

  // ===============
  //    DIALOGS
  // ===============

  // open dialog for wallet update
  updateWalletAmount() {
    const dialog = this.dialog.open(updateWalletDialog, {
      width: '550px',
      height: '260px',
      data: {
        wallet: this.user_wallet,
        user: this.id,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.getUserWallet();
    });
  }

  // open dialog for roles update
  rolesDialog() {
    const dialog = this.dialog.open(updateRolesDialog, {
      width: '550px',
      height: 'max-content',
      data: {
        roles: this.currentRoles,
        user: this.id,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.getUserBasicInfo();
    });
  }

  // delete loyalty dialog
  deleteLoyalty(loyalty) {
    const dialog = this.dialog.open(deleteLoyaltyDialog, {
      width: '550px',
      height: '260px',
      data: {
        loyalty: loyalty,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.getUserBasicInfo();
    });
  }

  // add loyalty dialog
  addLoyalty() {
    const dialog = this.dialog.open(addLoyaltyDialog, {
      width: '550px',
      height: '260px',
      data: {
        user: this.user,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.getUserBasicInfo();
    });
  }
}

// =================
//   WALLET UPDATE
// =================
@Component({
  selector: 'delete-dialog',
  templateUrl: 'user-wallet-update.html',
  styleUrls: ['../../../../components/dialogs/delete-dialog.component.scss'],
})
export class updateWalletDialog {
  id;
  amount;
  reason;

  // get section info after success
  onAdd = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { wallet; user },
    private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<updateWalletDialog>
  ) {}

  ngOnInit(): void {
    this.id = this.data.user;
  }

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  updateWalletAmount(i) {
    let wallet = {
      note: this.reason,
    };

    if (i == 'deduct') {
      wallet['amount'] = -this.amount;
    } else if (i == 'add') {
      wallet['amount'] = JSON.parse(this.amount);
    }

    this.api
      .patch('payments', `admin/users/${this.id}/wallets`, wallet)
      .subscribe(
        (credit: any) => {
          this.message.showSnack('succesfully added Credit to User');
          this.closeDialog();
        },
        (error) => {
          this.message.showSnack(error.error);
        }
      );
  }
}

// =================
//   ROLES UPDATE
// =================
@Component({
  selector: 'roles-dialog',
  templateUrl: 'user-roles-update.html',
  styleUrls: ['user-roles-update.component.scss'],
})
export class updateRolesDialog {
  id;
  allRoles;

  selectedName = '';
  selected;

  addRole = false;
  currentRoles = [];

  // get section info after success
  onAdd = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { roles; user },
    private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<updateRolesDialog>
  ) {}

  ngOnInit(): void {
    this.id = this.data.user;

    this.getAllRoles();
    this.convertRolesToNames();
  }

  convertRolesToNames() {
    this.data.roles.forEach((role) => {
      this.currentRoles.push(role.name);
    });
  }

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  getAllRoles() {
    this.api.getList('auth', `admin/roles`).subscribe((data: any) => {
      this.allRoles = data.roles;
    });
  }

  chooseRole(i) {
    this.selectedName = i.name;

    // check if role is included to render add or remove button
    let isIncluded = this.currentRoles.includes(i.name);
    // get role ID from user for deleting role - allRole id's do not match userrole id's
    this.data.roles.forEach((role) => {
      if (role.name == i.name) {
        this.selected = role;
      }
    });

    if (!isIncluded) {
      this.addRole = true;
    } else {
      this.addRole = false;
    }
  }

  updateRoles() {
    let userrole = {
      user_id: this.id,
      role: this.selectedName,
    };

    if (this.addRole) {
      this.api
        .post('auth', 'admin/userroles', userrole)
        .subscribe((role: any) => {
          if (userrole.role == 'Finance') {
            localStorage.setItem('finance-role', 'true');
            location.reload();
          }
          this.message.showSnack(`Successfully added new role`);
          this.closeDialog();
        });
    } else {
      this.api
        .delete('auth', `admin/userroles/${this.selected.ID}`)
        .subscribe((role: any) => {
          if (userrole.role == 'Finance') {
            localStorage.removeItem('finance-role');
            location.reload();
          }
          this.message.showSnack(`Succesfully removed role`);
          this.closeDialog();
        });
    }
  }
}

@Component({
  selector: 'delete-dialog',
  templateUrl: '../../../../components/dialogs/delete-dialog.html',
  styleUrls: ['../../../../components/dialogs/delete-dialog.component.scss'],
})
export class deleteLoyaltyDialog {
  inputTouch = false;
  heading = `loyalty`;
  body = `Are you sure you want to delete this loyalty?.`;

  loyalty;

  // get section info after success
  onAdd = new EventEmitter();

  onFocus() {
    this.inputTouch = true;
  }

  onBlur() {
    this.inputTouch = false;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { loyalty },
    private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<deleteLoyaltyDialog>
  ) {}

  ngOnInit(): void {
    this.loyalty = this.data.loyalty;
  }

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  removeItem() {
    this.api
      .delete(`auth`, `admin/loyalty/${this.loyalty.ID}`)
      .subscribe((loyalty: any) => {
        this.message.showSnack(`Successfully removed loyalty`);
        this.closeDialog();
      });
  }
}

@Component({
  selector: 'delete-dialog',
  templateUrl: 'user-loyalty-add.html',
  styleUrls: ['../../../../components/dialogs/delete-dialog.component.scss'],
})
export class addLoyaltyDialog {
  heading = `loyalty`;
  body = `Are you sure you want to delete this loyalty?.`;

  user;
  loyalties;
  loyalty_type;
  loyalty_number;

  // get section info after success
  onAdd = new EventEmitter();

  loyaltyTypes = [
    {
      id: 0,
      name: 'Woolworths',
    },
    {
      id: 1,
      name: 'Dis-Chem',
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { user },
    private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<addLoyaltyDialog>
  ) {}

  ngOnInit(): void {
    this.user = this.data.user;
  }

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  addLoyalty() {
    let loyalty = {
      user_id: this.user.ID,
      loyalty_type: this.loyalty_type,
      loyalty_number: this.loyalty_number,
    };

    this.api.post(`auth`, `admin/loyalty`, loyalty).subscribe(
      (loyalty: any) => {
        this.message.showSnack(`Successfully added loyalty card`);
        this.closeDialog();
      },
      (error) => {
        this.message.showSnack(error.error.error);
      }
    );
  }
}
