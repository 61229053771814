<div class="back action" routerLink="/list-branchmap">
    <mat-icon>arrow_back</mat-icon>
    <p>Branch Maps</p>
  </div>
  
  <div class="create-card"  >
  
    <div class="header">
      <h3>Create New Branchmap</h3>
      <mat-icon routerLink="/list-branchmap">clear</mat-icon>
    </div>
  
      <!-- ---------------------- -->
      <!--  COLLECT BRANCH MAP INFO  --> 
      <!-- ---------------------- -->
  
    <div class="first-form"  *ngIf='!postInfo'>
      <form #post = 'ngForm' (ngSubmit)='linkFormBranchMap(post.value)'>
  
        <mat-form-field class='field' appearance='outline'>
          <mat-label>BranchID</mat-label>
          <input type="text" matInput [(ngModel)]='loop_id' placeholder="The greatest cheesecake" name='loop_id'>
        </mat-form-field>
  
        <mat-form-field class='field' appearance='outline'>
          <mat-label>LoopID</mat-label>
          <textarea type="text" matInput [(ngModel)]='partner_branch_id' placeholder="this is a description" name='partner_branch_id'></textarea>
        </mat-form-field>
  
      
    
  
      <div class="btn product-info-button">
        <button type="submit" mat-flat-button class='button' (click)='submitBranchMap(post.value)' disabled="{{desktopAttempt ? false : true}}">
          <span *ngIf='!pulse'>ADD</span>
         
        </button>
      </div>
    </form>
    </div>
  </div>
  <ngx-spinner
  type = 'ball-pulse'
  [fullScreen]='false'
  bdColor=''
  color='var(--quenchPink)'
  size='small'
  ></ngx-spinner>