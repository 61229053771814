<div class="tabs">
  <div
    (click)="selectTab(page)"
    *ngFor="let page of account_pages; let i = index"
    [class]="current_page == page.name ? 'selectedTab' : ''"
  >
    <div id="tab">
      <p id="tab-name">{{ page.name }}</p>
    </div>
  </div>
</div>
 
<app-list-accounts *ngIf="current_page == 'drivers bank accounts'"></app-list-accounts>
<app-list-user-accounts *ngIf="current_page == 'drivers'"></app-list-user-accounts>


