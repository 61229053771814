<div class="back action" routerLink="/push">
    <mat-icon>arrow_back</mat-icon>
    <p>push notifications</p>
  </div>
  
  <div class="create-card"  >
  
    <div class="header">
      <h3>create new push notification</h3>
      <mat-icon routerLink='/push'>clear</mat-icon>
    </div>
  
    <form #push = 'ngForm' (ngSubmit)='createNotification(push.value)'>
              
        <mat-form-field class='field' appearance='outline'>
            <mat-label>push notification title</mat-label>
            <input type="text" matInput ngModel placeholder="everyday snacks" name='title' required>
        </mat-form-field>
  
        <mat-form-field class='field' appearance='outline'>
            <mat-label>push notification body</mat-label>
            <textarea type="text" name='body' matInput ngModel rows=4 required placeholder="write something here" ></textarea>
          </mat-form-field>
  
  
          <div class="btn">
            <button mat-flat-button class='button' type='submit' >save</button>      
          </div>
  
    </form>
  </div>