import { Component, OnInit } from '@angular/core';

import { ApiService } from '../../../services/api.service';
import { PagerService } from '../../../services/pager.service';
import { MessagesService } from 'src/app/services/messages.service';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-retailers',
  templateUrl: './retailers-list.component.html',
  styleUrls: ['./retailers-list.component.scss']
})
export class RetailersListComponent implements OnInit {
  Columns = ['name', 'address', 'email', 'phone', 'active']

  inputTouch = false

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }

  page = 1
  retailers
  limit = 10
  search = ''
  retries = 0

  constructor(
    public pager:PagerService,
    public api: ApiService,
    public message: MessagesService,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    this.pager.resource = 'Retailers'

    if(localStorage.getItem('limit')){
      this.limit = JSON.parse(localStorage.getItem('limit'))
    }

    if(localStorage.getItem('retailersPage')){
      this.page = JSON.parse(localStorage.getItem('retailersPage'))
    }

    if(localStorage.getItem('retailerSearch')){
      this.search = localStorage.getItem('retailerSearch')
    }
    
    this.getRetailers()
  }

  getRetailers(){
    localStorage.setItem('retailerSearch', this.search)
    this.api.getList('catelog', `retailers?page=${this.page}&per_page=${this.limit}&search=${this.search}`)
    .subscribe((retailers: any) => {
      console.log(retailers)

      if(retailers === null){
        this.page--
        this.getRetailers()
        localStorage.setItem('retailersPage', JSON.stringify(this.page))
        this.message.showSnack('no more retailers to show')
      }

      this.retailers = retailers

    }, error => {
      this.message.showSnack(error.error)
      if(this.retries < 3){
        if(error.status !== 401){
          this.retries++
          this.getRetailers()
        }
      }
    })
  }

  searchReset(){
    this.page = 1
    this.getRetailers()
    localStorage.setItem('retailersPage', JSON.stringify(this.page))
  }

  clearSearch(){
    this.page = 1
    this.search = ''
    this.getRetailers()

    localStorage.setItem('retailersPage', JSON.stringify(this.page))
  }

  toggleRetailerStatus(retailer){
    let retail = 
      {
        active: retailer.active,
        id: retailer.id
      }
       
      this.api.patch('catelog', `retailers`, retail).subscribe((retailer: any) => {
      if(retailer.active){
      this.message.showSnack(`Succesfully activated ${retailer.name}`)
      } else {
        this.message.showSnack(`Successfully de-activated ${retailer.name}`);
      }
    }, error => {
      this.message.showSnack(error.error);
    })
    }


  setLimit(limit){
    this.limit = limit
    this.getRetailers()
    localStorage.setItem('limit', JSON.stringify(limit))
  }

  next(){
    this.page++
    this.getRetailers()
    localStorage.setItem('retailersPage', JSON.stringify(this.page))
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.getRetailers()
      localStorage.setItem('retailersPage', JSON.stringify(this.page))
    }
  }
  
  start(){
    this.page = 1
    this.getRetailers()
    localStorage.setItem('retailersPage', JSON.stringify(this.page))
  }

}
