<div *ngIf='shade' id='shade' class='shade'> 
  <mat-spinner class='spinner'></mat-spinner> 
</div>

<div class="back action" routerLink="/retailers">
  <mat-icon>arrow_back</mat-icon>
  <p>retailers</p>
</div>

<div class="create-card"  >

  <div class="header">
    <h3>create new retailer</h3>
    <mat-icon routerLink='/retailers'>clear</mat-icon>
  </div>

    <!-- ---------------------- -->
    <!--   COLLECT MALL INFO    --> 
    <!-- ---------------------- -->

    <div class="first-form" [style.left]="retailerInfo  ? '-900px' : '0' ">
        
      <form #retailer = 'ngForm' (ngSubmit)='submitRetailerInfo(retailer.value)'>

        <!-- <div class="agm-map">
          <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="14" *ngIf='latitude && longitude'>
            <agm-marker [latitude]="latitude" [longitude]="longitude" ></agm-marker>
          </agm-map>
        </div> -->

        <mat-form-field appearance="outline" class='field'>
          <mat-label>address lookup</mat-label>
          <input matInput 
                placeholder="12 bob street" 
                matGoogleMapsAutocomplete
                type="address,place"
                country="za"
                (onAutocompleteSelected)="onAutocompleteSelected($event)"
                required
                ngModel
                name='physical_address'
                >
        </mat-form-field>
      
          <mat-form-field class='field' appearance='outline'>
            <mat-label>retailer name</mat-label>
            <input type="text" matInput [(ngModel)]="name" placeholder="gouda cheese" name='name' required>
          </mat-form-field>

          <div class="form">
            <mat-form-field  appearance='outline'>
              <mat-label>email</mat-label>
              <input type="email" matInput ngModel placeholder="johndoe@gmail.com" name='email' required>
            </mat-form-field>
            <mat-form-field  appearance='outline'>
              <mat-label>phone</mat-label>
              <input type="text" matInput [(ngModel)]="phone"  placeholder="021 558 9304" name='phone' required>
            </mat-form-field>
            <mat-form-field  appearance='outline'>
              <mat-label>vat number</mat-label>
              <input type="text" matInput [(ngModel)]="vat"  placeholder="12345" name='vat_num' >
            </mat-form-field>
          </div>

    
          <div class="btn">
            <button mat-flat-button class='button' type='submit' >next</button>      
          </div>

      </form>

    </div>

    <!-- ------------------ -->
    <!--   IMAGE UPLOAD     --> 
    <!-- ------------------ -->

    <div class="third-form" [style.left]="retailerInfo  ? '0' : '900px' " >

        <mat-icon class='action' (click)='back()'>arrow_back</mat-icon>

        <h3>Almost there! Please upload a banner image for this store</h3>
        <span class='recommended-size'  >*recommended image dimensions 280px * 270px</span>


        <div class='image'>
          <img class="preview-image" src='{{image}}'>
          <br>
          <button class="custom-file-upload" mat-button type="button" onclick="document.querySelector('#exampleInputFile').click()">Upload Image</button>
          <input type="file" id="exampleInputFile" (change)="imagePreview($event)" style="display: none;">
        </div>

        <div class="btn product-info-button">
          <button mat-flat-button class='button' (click)='submitRetailer()' disabled="{{imageFormat ? false : true}}">Save</button>
        </div>

    </div>

</div>