import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountsService } from 'src/app/services/accounts/accounts.service';

@Component({
  selector: 'app-earnings',
  templateUrl: './earnings.component.html',
  styleUrls: ['./earnings.component.scss']
})
export class EarningsComponent implements OnInit {
  Columns = [
    'date',
    'amount',
    'description',
    'distance',
    'status',
    'earning_type_id',
    'order_id',
  ]
  constructor(public route: ActivatedRoute, public accounts: AccountsService) { }

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id')
    this.accounts.getUserEarnings(id)
}
}
