<div class="back action" routerLink="/list-all-deliveries">
  <mat-icon>arrow_back</mat-icon>
  <p >deliveries</p>
</div>

<div class="delivery-info" *ngIf="deliveries.currentDelivery">
  <div class="customer-info">
    <p *ngIf="deliveries.currentDelivery.customer_name != '' ">{{deliveries.currentDelivery.customer_name}}</p>
    <p *ngIf="deliveries.currentDelivery.customer_name == '' " class="empty">no customer name</p>
    <p *ngIf="deliveries.currentDelivery.customer_phone != '' ">{{deliveries.currentDelivery.customer_phone}}</p>
    <p *ngIf="deliveries.currentDelivery.customer_phone == '' " class="empty">no customer contact</p>
  </div>
  <hr>
  <span class="route">
    <h3>{{ deliveries.currentDelivery.pick_up_address }}</h3>
    -
    <h3>{{ deliveries.currentDelivery.drop_off_address }}</h3>
  </span>
  <span class="distance" *ngIf="deliveries.currentDelivery.distance != '' ">{{deliveries.currentDelivery.distance}}m </span>
  <span class="empty" *ngIf="deliveries.currentDelivery.distance == '' ">no distance calculated </span>
  <p class="delivery-note" *ngIf="deliveries.currentDelivery.delivery_note != '' ">{{deliveries.currentDelivery.delivery_note}} </p>
  <p class="empty" *ngIf="deliveries.currentDelivery.delivery_note == '' ">no delivery note </p>
  <p class="delivery-note" *ngIf="deliveries.currentDelivery.store_reference != '' ">{{deliveries.currentDelivery.store_reference}} </p>
  <p class="empty" *ngIf="deliveries.currentDelivery.store_reference == '' ">no store reference </p>
</div>
<div class="delivery-statuses"></div>
