<div class="back action" routerLink="/stores">
  <mat-icon>arrow_back</mat-icon>
  <p>stores</p>
</div>

<div class="tabs">
  <div (click)='selectTab(i)' *ngFor="let tab of tabs; let i = index" [class]="selectedTab == tab.name ? 'selectedTab' : '' ">
    <div id='tab'>
      <p id='tab-name'>{{tab.name}}</p>
    </div>
  </div>
</div>

<app-store-detail *ngIf="selectedTab == 'settings'"></app-store-detail>
<app-shoppers *ngIf="selectedTab == 'pickers'"></app-shoppers>
<app-store-orders *ngIf="selectedTab == 'orders' "></app-store-orders>
<app-store-categories *ngIf="selectedTab == 'categories'"></app-store-categories>
<app-store-products *ngIf="selectedTab == 'products'"></app-store-products>
<app-store-geofencing *ngIf="selectedTab == 'zoning'"></app-store-geofencing>
<app-store-trading-hours *ngIf="selectedTab == 'trading hours'"></app-store-trading-hours>

