import { Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { ApiService } from '../../../services/api.service'
import { MessagesService } from 'src/app/services/messages.service'
import { environment as devEnv } from '../../../../environments/environment'


@Component({
  selector: 'app-add-store',
  templateUrl: './add-store.component.html',
  styleUrls: ['./add-store.component.scss']
})
export class AddStoreComponent implements OnInit {


  url
  mall
  silo
  store
  phone
  name
  malls
  silos
  latitude
  longitude
  silo_name
  linked_key
  physical_address

  zoom         = 14
  limit        = 10
  shade        = false
  image        = '../assets/images/error_image_1500.png'
  search       = ''
  store_id     = 0
  storeInfo    = false
  storeFilters = false
  trip_event_trigger
  trip_event_triggers = ["PLACED", "PAID", "READY FOR COLLECTION"]

  constructor(
    private api:ApiService,
    private router:Router,
    public message: MessagesService
  ) { }

  ngOnInit(): void {
    this.getSilos()
  }

  // ======================
  //   ADDRESS INFORMATION
  // ======================

  onAutocompleteSelected(e){
    this.linked_key = e.place_id
    this.latitude = e.geometry.location.lat()
    this.longitude = e.geometry.location.lng()
    this.phone = e.formatted_phone_number
    this.name = e.name
    this.url = e.website
    this.physical_address = e.formatted_address
  }

  // =====================
  //   GET FILTERS INFO 
  // =====================

  getSilos(){
    this.silos = [
      {
        id:3,
        name:"Dis-Chem",
        active:true,
        tag:"pharmaceutical",
        position:1
      },
      {
        id: 6,
        name:"DeliverD",
        active:false,
        tag:"deliverd",
        position:6
      },
    ]
  }


  // =====================
  //   STORE INFORMATION
  // =====================

  back(){
    this.storeInfo = false
  }

  submitStoreInfo(i){

    if(this.name && this.physical_address && i.email && this.phone){
      this.storeInfo = true
      this.store = i
      this.store.physical_address = this.physical_address
    }


  }

  backToFilters(){
    this.storeFilters = false
  }

  submitStoreFilters(){
    this.storeFilters = true

    if(this.silo !== 5){
    this.submitStore()
    }
  }

  submitStore(){
    this.store['silo_id']    = this.silo
    this.store['latitude']   = this.latitude
    this.store['longitude']  = this.longitude
    this.store['active']     = false
    this.store['linked_key'] = JSON.stringify(Date.now())
    this.store['trip_event_trigger'] = this.trip_event_trigger

    this.api.post('catelog', 'stores', [this.store]).subscribe((data: any) =>{
      
        this.store_id = data[0].id
        this.message.showSnack('successfully created store')

        if(this.silo === 5){
        this.imageUpload()
        } else {
          this.router.navigate([`/stores/${this.store_id}`])
        }

      }, error => {
        this.message.showSnack(error.error)
      })
  }


 // =====================
  //   PRODUCT IMAGE
  // =====================

  imageAttempt = false
  imageFormat = false

  imageFile: {resource: string, file: any, name: string}


  imagePreview(event) {
    if (event.target.files && event.target.files[0]) {
        let reader = new FileReader()

        reader.onload = (_event: any) => {
            this.imageFile = {
                resource: _event.target.result,
                file: event.srcElement.files[0],
                name: event.srcElement.files[0].name
            }

            if(this.imageFile.name.includes('png') || this.imageFile.name.includes('jpg') || this.imageFile.name.includes('jpeg') ||  this.imageFile.name.includes('webp') ){
              this.imageFile.name = 'image.png'
              this.imageFormat = true
              this.image = this.imageFile.resource
          } else {
            this.message.showSnack(`oops!   that is an unsupported image type`)
          }

        }
        reader.readAsDataURL(event.target.files[0])
    }
  }

  imageUpload(){
    setTimeout(() => {
      this.message.showSnack('uploading banner image')
    }, 4000)
    var myHeaders = new Headers()

    myHeaders.append("Authorization", "Bearer " + this.api.token)

    var formdata = new FormData()

    formdata.append("file", this.imageFile.file, this.imageFile.name)
    formdata.append("body", "{ \"resource\":\"stores\", \"resource_id\":"+ this.store_id +",\"x_dim\":1, \"y_dim\":1}")

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata
    }

    // Dev environment

    if(!devEnv.production){
    fetch("https://api.api-dev.quench.org.za/catalogue/v1/image", requestOptions)
    .then(response => response.text())
    .then(result => {
      setTimeout(() => {
        this.message.showSnack('banner image Uploaded')
        this.shade = false
        this.router.navigate([`/stores/${this.store_id}`])

      }, 9000)
    })
      
    .catch(error => console.log('error', error))
    
    }

    // production environment
    else if(devEnv.production){
      fetch("https://api.quench.org.za/catalogue/v1/image", requestOptions)
      .then(response => response.text())
      .then(result =>
        setTimeout(() => {
          this.message.showSnack('banner image Uploaded')
          this.shade = false
          this.router.navigate([`/stores/${this.store_id}`])
        }, 9000))
      .catch(error => console.log('error', error))
    }
  }

  
}
