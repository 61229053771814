<div class="back action" routerLink="/promos">
  <mat-icon>arrow_back</mat-icon>
  <p>promocodes</p>
</div>

<div class="create-card"  >

  <div class="header">
    <h3>create new Promotional Code</h3>
    <mat-icon routerLink='/promos'>clear</mat-icon>
  </div>

  <form #promo = 'ngForm' (ngSubmit)='createPromoCode(promo.value)'>
            
      <mat-form-field class='field' appearance='outline'>
          <mat-label>PromoCode</mat-label>
          <input type="text" matInput ngModel placeholder="Festive100" name='code' required>
      </mat-form-field>

      <mat-form-field class='field' appearance='outline'>
          <mat-label>Description</mat-label>
          <textarea type="text" matInput ngModel placeholder="Get R100 off today only" name='description' required></textarea>
      </mat-form-field>

      <div class="form flex-items">
        <mat-form-field  appearance='outline'>
          <mat-label>amount</mat-label>
          <input type="number" matInput ngModel placeholder="100" name='amount' required >
        </mat-form-field>
        <mat-form-field  appearance='outline'>
          <mat-label>quantity</mat-label>
          <input type="number" matInput ngModel placeholder="12345678" name='quantity' required>
        </mat-form-field>
        <mat-form-field  appearance='outline'>
          <mat-label>min amount</mat-label>
          <input type="number" matInput ngModel placeholder="900g" name='min_order_amount' maxlength="15" >
        </mat-form-field>
      </div>

      <mat-form-field appearance='outline' class='field'> 
        <mat-label>Silos</mat-label>
          <mat-select [(ngModel)]="siloValue" name="silo" multiple>
            <mat-option *ngFor="let silo of silos" [value]="silo.id">
              {{silo.tag}}
            </mat-option>
          </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class='field'>
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate name='start_date' placeholder="Start date" ngModel>
          <input matEndDate name='end_date' placeholder="End date" ngModel>
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <div class=" form flex-items">
        <mat-checkbox name='active' [(ngModel)]='active'>Activate Now</mat-checkbox>
        <mat-checkbox name='active'  (click)='addSectionItems()' [(ngModel)]='product'>Add Promo Product</mat-checkbox>
        <mat-checkbox name='multiple_uses' [(ngModel)]='multiple_uses'>Use multiple times</mat-checkbox><br>
        <mat-checkbox name='first_order'  [(ngModel)]='first_order'> First customer order only</mat-checkbox>
      </div>

      <div class='promoproduct' *ngIf='promoproduct'>
        <h5>You are adding the following product to this promocode</h5>
        <img src={{promoproduct.image}} onerror="this.src='assets/placeholders/placeholder-img.png' ">
        <p> Add {{promoproduct.name}} to promocode?</p>
        <button mat-flat-button (click)='cancelProduct()' >cancel</button>
    </div>


        <div class="btn">
          <button mat-flat-button class='button' type='submit' >save</button>      
        </div>

  </form>
</div>