import { Component, OnInit } from '@angular/core'
import { ApiService } from '../../services/api.service'
import { Router ,ActivatedRoute} from '@angular/router'
import { PagerService } from '../../services/pager.service'
import { PusherService } from '../../services/pusher.service'
import { MessagesService } from 'src/app/services/messages.service'

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  Columns = ['placed', 'store', 'customer', 'status', 'origin', 'number', 'amount']


  inputTouch = false

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }

  silos
  silo_id = '0'
  silo_name = 'Silos'

  // =================
  //  FILTER STATUSES
  // =================

  astatus = 'ALL'
  current_api_call = 'orders'


  orders
  status
  page = 1
  count = 0
  limit = 10
  carts = []
  search = ''
  mobileSearch

  
  constructor(
    public api:ApiService,
    public pager:PagerService,
    public message: MessagesService
  ) { }


  ngOnInit(): void {
    if(localStorage.getItem('ordersPage')){
      this.page = JSON.parse(localStorage.getItem('ordersPage'))
    }

    if(localStorage.getItem('limit')){
      this.limit = JSON.parse(localStorage.getItem('limit'))
    }

    if(localStorage.getItem('orderSearch')){
      this.search = localStorage.getItem('orderSearch')
      this.getOrders()
    } else {
      this.getOrders()
    }

  }

  toggleMobileSearch(){
    this.mobileSearch = !this.mobileSearch
  }

  getOrders(){
    localStorage.setItem('orderSearch', this.search)
    this.api.getList('fulfilment', `admin/orders?page=${this.page}&per_page=${this.limit}&search=${this.search}`)
    .subscribe((orders: any) => {
      // console.log(orders)

      if(orders.data.length == 0 && this.page >1){
        this.page--
        this.getOrders()
      } 
      else if(orders.data.length === 0 && this.search.length > 1){
        this.message.showSnack('No results found')
      }

      this.orders = orders.data
    }, error => {
      this.message.showSnack(error.error)
      if(this.count < 3){
        if(error.status !== 401){
        this.count++
        this.getOrders()
        }
      }
    })
  }


  clearSearch(){
    this.page = 1
    this.search = ''
    this.getOrders()
  }

  searchReset(){
    this.page = 1
    this.getOrders()
    localStorage.setItem('ordersPage', JSON.stringify(this.page))
  }


  // =================
  //   PAGINATION
  // =================

  setLimit(limit){
    this.limit = limit

      this.getOrders()


    localStorage.setItem('limit', JSON.stringify(limit))
  }

  next(){
      if(this.orders.length >= this.limit ){
      this.page++

      this.getOrders()
    }
    localStorage.setItem('ordersPage', JSON.stringify(this.page))
  }

  previous(){
    if(this.page > 1){
      this.page--

      this.getOrders()

     }
     localStorage.setItem('ordersPage', JSON.stringify(this.page))
  }

  start(){
    this.page = 1

    this.getOrders()

    localStorage.setItem('ordersPage', JSON.stringify(this.page))
  }
}
