export const environment = {
  catalogue: "https://api.quench.org.za/catalogue/v1/",
  pay: "https://api.quench.org.za/pay/v1/",
  fulfilment: "https://api.quench.org.za/fulfilment/v1/",
  authentication: "https://api.quench.org.za/auth/v1/",
  drive: "https://api.quench.org.za/godrive/v1/",
  apiKey: "QuenchMobi",
  production: true,
  googlemapskey: 'AIzaSyA8AOsH5g9KSIkobo6MZofuvn__nb7kwlM',
  pusher: {
    app_id: "1205739",
    key: "8d533a2748ec6d25b10b",
    secret: "b1a36c37d57f9ca4a4b4",
    cluster: "eu"
  }
};
