<div *ngIf='sections.length > 0'>

    <table  mat-table [dataSource]='sections' matSort>

        <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> title </th>
        <td mat-cell *matCellDef="let section"  id='section-title'> <p>{{section.title}} </p></td>
        </ng-container>

        <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> type </th>
        <td mat-cell *matCellDef="let section" > 
            <p> {{section.type}} </p>
        </td>
        </ng-container>

        <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
        <td mat-cell *matCellDef="let section" > 
            <p *ngIf='section.active'  class='active'><mat-icon>check</mat-icon> </p>
            <p *ngIf='!section.active' class='inactive'><mat-icon>clear</mat-icon> </p>
        </td>
        </ng-container>

        <ng-container matColumnDef="more">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
            <td mat-cell *matCellDef="let section" class='more'> 
                <mat-icon matTooltip='more' [matMenuTriggerFor]="menu" class='action'>more_horiz</mat-icon> 
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)='toggleSectionStatus(section)'>
                    <mat-icon class='material-icons-outlined' *ngIf='section.active'>toggle_off</mat-icon>
                    <span *ngIf='section.active'>deactivate section</span>
                    <mat-icon class='material-icons-outlined' *ngIf='!section.active'>toggle_on</mat-icon>
                    <span *ngIf='!section.active'>activate section</span>
                  </button>
                  <button mat-menu-item (click)='removeMallSection(section)'>
                    <mat-icon >delete_outline</mat-icon>
                    <span >remove section from mall</span>
                  </button>
                  <button mat-menu-item >
                    <mat-icon class='material-icons-outlined'>edit</mat-icon>
                    <span routerLink='/sections'>edit section </span>
                  </button>
                </mat-menu>
            </td>
          </ng-container>


        <tr mat-header-row *matHeaderRowDef="Columns" ></tr>
        <tr mat-row class='action'  *matRowDef="let section; columns: Columns;"  ></tr>
  </table>


</div>

<div id="pager" >
  <div class="page"> 

   <button id="{{auth.expand ? 'create-expand': 'create'}}" mat-flat-button class='button' (click)='addSectionsToMall()' >add sections to mall</button>
  </div>
</div>