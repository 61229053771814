import { MiscService } from './../../../services/misc/misc.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { ApiService } from './../../../services/api.service'
import { MessagesService } from './../../../services/messages.service'

@Component({
  selector: 'app-create-section',
  templateUrl: './create-section.component.html',
  styleUrls: ['./create-section.component.scss']
})
export class CreateSectionComponent implements OnInit {

  isDisChemUser = false
  background = `var(--quenchPink)`;


  malls
  silos
  silo_id

  search = ''
  
  types

  constructor(public api: ApiService,
              public message: MessagesService,
              public router: Router,
              public auth: AuthService,
              public misc: MiscService) { }

  ngOnInit(): void {
    if(this.auth.dis_chem_role || localStorage.getItem('dis-chem-role') && localStorage.getItem('dis-chem-role') == 'true'){
      this.isDisChemUser = true
      this.background = `var(--dischemGreen)`

      // section type stores not available for dischem sections
      this.types = [
        'products',
        'posts',
      ]
    } else {
      this.misc.getSilos()
      this.types = [
        'products',
        'posts',
        'stores',
      ]
    }
  }

  createSection(section){
    section['active'] = false
    section['position'] = 1
    let newSection = [section]

    let apicall = ``

    if(this.isDisChemUser){
      apicall = `dischem/sections`
    } else {
      apicall = 'sections'
    }
      this.api.post("catelog", `${apicall}`, newSection).subscribe((data:any)=>{
        this.message.showSnack(`succesfully created new section`)
          this.router.navigate([`sections/${data[0].id}`])
      })
    }
}
