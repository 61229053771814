import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { Injectable } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Injectable({
  providedIn: 'root',
})
export class MiscService {
  silo;
  silos;

  inputTouch = false;

  constructor(public api: ApiService, public auth: AuthService) {}

  async apiCall(service, resource) {
    let data = await this.api.getList(service, resource).toPromise();

    // console.log(data)

    return data;
  }

  async apiPost(service, resource, obj){
    let data = await this.api.post(service, resource, obj).toPromise();

    return data
  }

  async apiPatch(service, resource, obj){
    let data = await this.api.patch(service, resource, obj).toPromise();

    return data
  }

  async apiDelete(service, resource){
    let data = await this.api.delete(service, resource).toPromise();

    return data
  }

  async exportCall(service, resource) {
    let response = await this.api.export(service, resource).toPromise()

    return response
  }

  isDischemUser() {
    if (
      this.auth.dis_chem_role ||
      (localStorage.getItem('dis-chem-role') &&
        localStorage.getItem('dis-chem-role') == 'true')
    ) {
      return true;
    } else {
      return false;
    }
  }
  // =========
  //   SILOS
  // =========

  async getSilos() {
    let s = await this.apiCall('catelog', 'silos');

    //  set current silo
    if (localStorage.getItem('currentSilo')) {
      this.silo = JSON.parse(localStorage.getItem('currentSilo'));
    } else if (localStorage.getItem('currentSilo')) {
      this.silo = JSON.parse(localStorage.getItem('currentSilo'));
    } else if (this.silo == undefined) {
      this.silo = s[0];
      localStorage.setItem('currentSilo', JSON.stringify(this.silo));
    }

    this.silos = s;
  }

  // =========
  //  SEARCH
  // =========

  onFocus() {
    this.inputTouch = true;
  }

  onBlur() {
    this.inputTouch = false;
  }

  // move drop here
}
