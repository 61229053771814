import { SectionsService } from './../../../services/sections/sections.service';
import { MiscService } from './../../../services/misc/misc.service';
import { ApiService } from '../../../services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { PagerService } from '../../../services/pager.service';
import { MessagesService } from '../../../services/messages.service';
import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss'],
})
export class SectionsComponent implements OnInit {
  displayedColumns = ['sort', 'image', 'title', 'remove'];
  allProds = ['image', 'title', 'remove'];

  background = `var(--quenchPink)`;
  isDischemUser = false;

  type;
  silo;
  section;
  silo_id;
  product;
  retailers;
  silos;

  constructor(
    public pager: PagerService,
    public message: MessagesService,
    private api: ApiService,
    public auth: AuthService,
    public dialog: MatDialog,
    public misc: MiscService,
    public sections: SectionsService
  ) {}

  ngOnInit(): void {
    if (
      this.auth.dis_chem_role ||
      (localStorage.getItem('dis-chem-role') &&
        localStorage.getItem('dis-chem-role') == 'true')
    ) {
      this.isDischemUser = true;
      this.background = `#35c27e`;
    }

    this.sections.getSections();
  }

  // ===============
  //    SET SILO
  // ===============

  setSilo(asilo) {
    localStorage.removeItem('currentSection');
    this.misc.silo = asilo;
    this.sections.getSections();
    localStorage.setItem('currentSilo', JSON.stringify(asilo));
  }

  // =======================
  // SECTION DELETE  DIALOG
  // =======================

  // open dialog for banner update
  removeSection(section) {
    const dialog = this.dialog.open(RemoveSectionDialog, {
      width: '550px',
      height: '200px',
      data: {
        section: section,
        isDisChemUser: this.isDischemUser,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.sections.getSections();
    });
  }

  // =======================
  // S  ECTION EDIT DIALOG
  // =======================

  // open dialog for banner update
  editSection(section) {
    const dialog = this.dialog.open(editSectionDialog, {
      width: '800px',
      height: '450px',
      data: {
        section: section,
        isDisChemUser: this.isDischemUser,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.sections.getSections();
    });
  }
}

@Component({
  selector: 'delete-dialog',
  templateUrl: '../../../components/dialogs/delete-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class RemoveSectionDialog {
  inputTouch = false;
  heading = `section`;
  body = `Are you sure you want to delete this section?`;

  section;

  // get section info after success
  onAdd = new EventEmitter();

  onFocus() {
    this.inputTouch = true;
  }

  onBlur() {
    this.inputTouch = false;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { section; isDisChemUser },
    private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<RemoveSectionDialog>,
    public sections: SectionsService
  ) {}

  ngOnInit(): void {
    this.section = this.data.section;
  }

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  removeItem() {
    this.sections.deleteSection(this.section);
    this.closeDialog();
  }
}

@Component({
  selector: 'update-section-dialog',
  templateUrl: './update-section-dialog.html',
  styleUrls: ['../../../components/dialogs/update-dialog.component.scss'],
})
export class editSectionDialog {
  type;
  mall;
  malls;
  title;
  section;
  silo_id;
  silos;

  search = '';

  types = [];

  isDischemUser = false;
  background = `var(--quenchPink)`;

  // get section info after success
  onAdd = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { section; isDisChemUser },
    private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<editSectionDialog>
  ) {}

  ngOnInit(): void {
    this.section = this.data.section;
    this.title = this.section.title;
    this.silo_id = this.section.silo_id;
    this.type = this.section.type;

    if (this.data.isDisChemUser) {
      this.types = ['products', 'posts'];
      this.isDischemUser = true;
      this.background = `var(--dischemGreen)`;
    } else {
      this.types = ['products', 'posts', 'stores'];
    }

    this.api.getList('catelog', `silos`).subscribe((data: any) => {
      this.silos = data;
    });

    this.api
      .getList('catelog', `malls?search=${this.search}`)
      .subscribe((data: any) => {
        this.malls = data;
      });
  }

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  update() {
    let section = {
      id: this.section.id,
      title: this.title,
      type: this.type,
    };

    let apicall = '';

    if (this.data.isDisChemUser) {
      apicall = 'dischem/sections';
    } else {
      apicall = 'sections';
      section['silo_id'] = this.silo_id;
    }

    this.api.patch(`catelog`, `${apicall}`, section).subscribe((data: any) => {
      this.message.showSnack('succesfully updated section');
      this.closeDialog();
    });
  }
}
