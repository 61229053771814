<div class="add-card">
  <div class="header">
    <h3>add {{section.type}} to section</h3>
    <mat-icon (click)="closeDialog()">clear</mat-icon>
  </div>

  <div class="top">
    <div class="search">
      <mat-icon
        (click)="actions()"
        matTooltip="search"
        class="search-icon"
        [style.left]="misc.inputTouch ? '-20px' : '10px' "
        >search
      </mat-icon>
      <mat-icon
        *ngIf="search"
        (click)="clearSearch()"
        matTooltip="clear"
        class="clear-icon"
        >clear</mat-icon
      >
      <input
        matInput
        (keydown.enter)="actions()"
        [(ngModel)]="search"
        placeholder="Search {{section.type}}"
        #input
        (blur)="misc.onBlur()"
        (focus)="misc.onFocus()"
        [style.left]="misc.inputTouch ? '10px' : '35px' "
      />
    </div>

    <div class="retailer-field field" *ngIf="!data.isdischemUser">
      <mat-form-field>
        <mat-label>Retailer Filter</mat-label>
        <mat-select
          [(ngModel)]="retailer_id"
          name="retailer"
          class="select-panel"
        >
          <input
            class="select-search"
            type="text"
            placeholder="Search Retailers"
            [(ngModel)]="retailerSearch"
            (input)="getRetailers()"
          />
          <mat-option
            *ngFor="let retailer of retailers"
            [value]="retailer.id"
            (click)="selectRetailer()"
          >
            {{retailer.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-icon
        class="help material-icons-outlined action"
        matTooltip="why is a retailer necessary? This is to help us keep our data clean and to make life easier further along the line, since products can be difficult to find if they are not linked to a retailer."
        >help_outline</mat-icon
      >
    </div>

    <!-- <div class='filter' >
            <mat-form-field >
            <mat-label>Silo Filter</mat-label>
            <mat-select  [(ngModel)]="silo_id">
                <mat-option (click)='selectSilo()' *ngFor="let silo of silos" [value]="silo.id">
                {{silo.name}}
                </mat-option>
            </mat-select>
            </mat-form-field>
        </div> -->
  </div>

  <table mat-table [dataSource]="searchData" matSort>
    <!-- <mat-spinner class='spinner'></mat-spinner>  -->

    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td
        mat-cell
        *matCellDef="let item"
        routerLink="/items/{{item.id}}"
        class="image"
      >
        <img
          class="img"
          src="{{item.image}}"
          alt=""
          onerror="this.src='assets/placeholders/placeholder-img.png' "
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let item" routerLink="/items/{{item.id}}">
        <p *ngIf="section.type == 'products' || section.type == 'stores' ">
          {{item.name}}
        </p>
        <p *ngIf="section.type == 'posts' ">{{item.title}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="add">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item">
        <mat-icon (click)="toggleSelection(item)">add_circle_outlined</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row class="action" *matRowDef="let item; columns: Columns;"></tr>
  </table>

  <div class="add-list">
    <mat-chip-list aria-label="Fish selection">
      <p *ngIf="itemsArr.length > 0">adding :</p>
      <mat-chip
        id="selectedCat"
        #c="matChip"
        id="chosen-category"
        [value]="item"
        *ngFor="let item of itemsArr"
      >
        <p *ngIf="section.type == 'products' || section.type == 'stores' ">
          {{item.name}}
        </p>
        <p *ngIf="section.type == 'posts' ">{{item.title}}</p>
        <mat-icon matChipRemove (click)="toggleSelection(item)"
          >cancel</mat-icon
        >
      </mat-chip>
    </mat-chip-list>
  </div>

  <div class="btn">
    <button
      mat-flat-button
      class="button"
      (click)="addItemsToSection() "
    >
      confirm
    </button>
  </div>
</div>

<div id="pager">
  <div class="page">
    <div>Per page:</div>
    <span class="silo-filter pagination">
      <button mat-button [matMenuTriggerFor]="paginate">
        <span>{{limit}}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #paginate="matMenu">
        <button mat-menu-item (click)="setLimit(10)">10</button>
        <button mat-menu-item (click)="setLimit(20)">20</button>
        <button mat-menu-item (click)="setLimit(50)">50</button>
      </mat-menu>
    </span>

    <div
      (click)="start()"
      matTooltip="Skip to start"
      class="action"
      *ngIf="page > 1"
    >
      back to start
    </div>
    <p (click)="previous()" class="action">previous</p>
    <div>{{page}}</div>
    <p (click)="next()" class="action">next</p>
    <div></div>
  </div>
</div>
