import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EarningsService } from 'src/app/services/earnings/earnings.service';
import { MiscService } from 'src/app/services/misc/misc.service';
import { ReasonsService } from 'src/app/services/reasons/reasons.service';

@Component({
  selector: 'app-payment-capture',
  templateUrl: './payment-capture.component.html',
  styleUrls: ['./payment-capture.component.scss'],
})
export class PaymentCaptureComponent implements OnInit {
  constructor(public misc: MiscService, public reasons: ReasonsService, public earnings: EarningsService, public router: Router) {}

  ngOnInit(): void {
    this.reasons.getReasons();
    this.earnings.getEarningTypes()
  }

  earning
  ord = {
    order_number: '',
    ID: ''
  }
  driver
  drivers;
  other = false;
  reason;
  allReasons;
  orders 
  earning_type
  user_id




  firstForm = new UntypedFormGroup({
    user_id: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl('', Validators.required),
    amount: new UntypedFormControl('', Validators.required),
    reason_id: new UntypedFormControl('', Validators.required),
    order_id: new UntypedFormControl(''),
  });

  async getDrivers(e) {
    let drivers = await this.misc.apiCall(
      `auth`,
      `admin/drivers?search=${e.target.value}&per_page=${20}`
    );
    this.drivers = drivers['users'];
  }

  selectDriver(driver) {
    this.driver = `${driver.first_name} ${driver.last_name}`;
    this.user_id = driver.ID;
  }


  async getOrders(e){
    let orders = await this.misc.apiCall(
      'fulfilment',
      `admin/carts?per_page=${10}&search=${e.target.value}`
    )

    this.orders = orders['data']
  }

  selectOrder(order){
    this.ord = order
  }


  selectReason(reason) {
    this.reason = reason
  }

  selectEarningtype(earning) {
    this.earning_type = earning
  }

  submitEarningInfo() {
    this.earning = { ...this.firstForm.value };
    this.earning.order_id = this.ord.ID
    // console.log(this.earning)
    this.earning.user_id = this.user_id
    this.earning.status = 'unpaid'
    this.earning.earning_type = 'manual'
  }

  submitEarning(){
    this.earnings.createEarning(this.earning)
  }
}
