import { ApiService }        from 'src/app/services/api.service'
import { ActivatedRoute }    from '@angular/router'
import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-product-navigate',
  templateUrl: './product-navigate.component.html',
  styleUrls: ['./product-navigate.component.scss']
})
export class ProductNavigateComponent implements OnInit {

  id
  product

  selectedTab = 'product'


  tabs = [
    { name: 'product'},
    { name: 'categories'},
    // { name: 'stores'},
  ]

  constructor(public route: ActivatedRoute,
              public api: ApiService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')

    if(localStorage.getItem('productTab')){
      this.selectedTab = localStorage.getItem('productTab');
    }

    this.getProduct()
  }

  selectTab(i){
    this.selectedTab = this.tabs[i].name
    localStorage.setItem('productTab', this.tabs[i].name)
  }

  getProduct(){
    this.api.getItem('catelog', 'products', this.id).subscribe((product: any) => {
      this.product = product
    })
  }

}
