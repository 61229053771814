import { ApiService } from './../../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { MessagesService } from 'src/app/services/messages.service';
import { Router } from '@angular/router';
import { environment as devEnv } from '../../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss'],
})
export class CreatePostComponent implements OnInit {
  post;
  silos;

  title;
  body;
  silo_id;
  post_id;

  isDischemUser = false;

  pulse = false;
  postInfo = false;
  mobileImage = '../assets/placeholders/logo-placeholder.png';
  desktopImage = '../assets/placeholders/logo-placeholder.png';
  mobileAttempt = false;
  desktopAttempt = false;
  mobileImageSubmit = false;

  mobileImageFile: { resource: string; file: any; name: string };
  desktopImageFile: { resource: string; file: any; name: string };

  constructor(
    public api: ApiService,
    public message: MessagesService,
    public router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    let isDischemUser = localStorage.getItem('dis-chem-role');

    if (isDischemUser) {
      this.isDischemUser = true;
    } else {
      this.getSilos();
    }
  }

  getSilos() {
    this.api.getList('catelog', 'silos').subscribe(
      (silos: any) => {
        this.silos = silos;
      },
      (error) => {
        this.message.showSnack(error.error);
      }
    );
  }

  submitPostInfo(i) {
    this.post = i;
    this.postInfo = true;
  }

  backToPostInfo() {
    this.postInfo = false;
  }

  mobileImagePreview(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.onload = (_event: any) => {
        this.mobileImageFile = {
          resource: _event.target.result,
          file: event.srcElement.files[0],
          name: event.srcElement.files[0].name,
        };
        if (
          this.mobileImageFile.name.includes('png') ||
          this.mobileImageFile.name.includes('jpg') ||
          this.mobileImageFile.name.includes('jpeg') ||
          this.mobileImageFile.name.includes('webp') ||
          this.mobileImageFile.name.includes('JPG') ||
          this.mobileImageFile.name.includes('PNG') ||
          this.mobileImageFile.name.includes('JPEG')
        ) {
          this.mobileImageFile.name = 'image.png';
          this.mobileAttempt = true;
          this.mobileImage = this.mobileImageFile.resource;
        } else {
          this.message.showSnack(`oops!   that is an unsupported image type`);
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  submitMobileImage() {
    this.mobileImageSubmit = !this.mobileImageSubmit;
  }

  desktopImagePreview(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.onload = (_event: any) => {
        this.desktopImageFile = {
          resource: _event.target.result,
          file: event.srcElement.files[0],
          name: event.srcElement.files[0].name,
        };
        if (
          this.desktopImageFile.name.includes('png') ||
          this.desktopImageFile.name.includes('jpg') ||
          this.desktopImageFile.name.includes('jpeg') ||
          this.desktopImageFile.name.includes('webp') ||
          this.desktopImageFile.name.includes('JPG') ||
          this.desktopImageFile.name.includes('PNG') ||
          this.desktopImageFile.name.includes('JPEG')
        ) {
          this.desktopImageFile.name = 'image.png';
          this.desktopAttempt = true;
          this.desktopImage = this.desktopImageFile.resource;
        } else {
          this.message.showSnack(`oops!   that is an unsupported image type`);
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  submitPost() {
    this.post['position'] = 1;
    this.post['image'] = 'placeholder';
    this.spinner.show();
    this.pulse = true;
    this.desktopAttempt = false;

    let apicall = ``

    if(this.isDischemUser){
      apicall = `dischem/posts`

    } else {
      apicall = `posts`
    }

      this.api.post('catelog', `${apicall}`, [this.post]).subscribe(
        (data: any) => {
          this.message.showSnack(`succesfully created post`);
          this.post_id = data[0].id;
          this.imageUpload();
        },
        (error) => {
          this.message.showSnack(error.error);
          this.spinner.hide();
          this.desktopAttempt = true;
        }
      );

  }

  imageUpload() {
    setTimeout(() => {
      this.message.showSnack(`uploading post images`);
    }, 3500);

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + this.api.token);

    var formdata = new FormData();
    formdata.append(
      'file',
      this.mobileImageFile.file,
      this.mobileImageFile.name
    );
    formdata.append(
      'body',
      '{ "resource":"posts", "resource_id":' +
        this.post_id +
        ',"x_dim":1, "y_dim":1}'
    );
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
    };

    if (!devEnv.production) {
      fetch(
        'https://api.api-dev.quench.org.za/catalogue/v1//image',
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          var myHeaders = new Headers();
          myHeaders.append('Authorization', 'Bearer ' + this.api.token);

          if(this.isDischemUser){
            setTimeout(() => {
              this.router.navigate([`posts`])
            }, 5000)
          }

          var formdata = new FormData();
          formdata.append(
            'file',
            this.desktopImageFile.file,
            this.desktopImageFile.name
          );
          formdata.append(
            'body',
            '{ "resource":"posts", "resource_id":' +
              this.post_id +
              ', "x_dim":1, "y_dim":1,"for_desktop":true, "image_key":' +
              result +
              '}'
          );

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
          };

          fetch(
            'https://api.api-dev.quench.org.za/catalogue/v1//image',
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => {
              setTimeout(() => {
                this.message.showSnack('succesfully created post');
                this.postInfo = false;
                this.spinner.hide();
                this.title = '';
                this.body = '';
                this.silo_id = '';
                this.router.navigate([`posts`])
              }, 10000);
            })
            .catch((error) => console.log('error', error));
        })
        .catch((error) => console.log('error', error));
    }

    // production environment
    else if (devEnv.production) {
      fetch('https://api.quench.org.za/catalogue/v1//image', requestOptions)
        .then((response) => response.text())
        .then((result) => {
          var myHeaders = new Headers();
          myHeaders.append('Authorization', 'Bearer ' + this.api.token);

          var formdata = new FormData();
          formdata.append(
            'file',
            this.desktopImageFile.file,
            this.desktopImageFile.name
          );
          formdata.append(
            'body',
            '{ "resource":"posts", "resource_id":' +
              this.post_id +
              ', "x_dim":1, "y_dim":1,"for_desktop":true, "image_key":' +
              result +
              '}'
          );

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
          };

          fetch('https://api.quench.org.za/catalogue/v1//image', requestOptions)
            .then((response) => response.text())
            .then((result) => {
              setTimeout(() => {
                this.message.showSnack('succesfully created post');
                this.postInfo = false;
                this.spinner.hide();
                this.title = '';
                this.body = '';
                this.silo_id = '';
                this.router.navigate([`posts`])
              }, 10000);
            })
            .catch((error) => console.log('error', error));
        })
        .catch((error) => console.log('error', error));
    }
  }
}
