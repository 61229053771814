<div class="search">
  <mat-icon class='search-icon' [style.left]="inputTouch ? '-20px' : '10px' ">search</mat-icon>
  <mat-icon *ngIf='search' (click)='clearSearch()'  matTooltip='clear' class='clear-icon'>clear</mat-icon>
  <input matInput (keydown.enter)='searchReset()' [(ngModel)]="search" placeholder="Search"  #input (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
</div>

      
    <!-- STORE PRODUCTS -->
        <table mat-table [dataSource]='orders' matSort>
    
            <ng-container matColumnDef="placed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Placed</th>
            <td mat-cell *matCellDef="let order" >
              <p *ngIf="order.effective_date && current_api_call == 'orders' ">{{order.effective_date.substring(0,10)}} </p> 
              <p *ngIf="order.effective_date && current_api_call == 'orders' " class='order-time'>{{order.effective_date.substring(11,19)}} </p> 
              <p *ngIf="order.order_date && current_api_call == 'carts' " >{{order.order_date.substring(0,10)}} </p> 
              <p *ngIf="order.order_date && current_api_call == 'carts' " class='order-time'>{{order.order_date.substring(14,19) }} </p> 
            </td>
          </ng-container>
    
          <ng-container matColumnDef="store">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Store</th>
            <td mat-cell *matCellDef="let order"> 
              <p>{{order.store_name}}</p>
            </td>
          </ng-container>

          <ng-container matColumnDef="customer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
            <td mat-cell *matCellDef="let order"> 
              <span class="customer">
                <mat-icon>person</mat-icon>
                <p *ngIf='order.customer_name'>{{order.customer_name}}</p>
                <p *ngIf='!order.customer_name'>No Customer Name</p>
              </span>
            </td>
          </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
              <td mat-cell *matCellDef="let order">
                <p *ngIf="order.status && order.status == 'PAID' "          id='paid-status'          class='status'> {{order.status}}</p>
                <p *ngIf="order.status && order.status == 'COMPLETED' "     id='completed-status'     class='status'> {{order.status}}</p>
                <p *ngIf="order.status && order.status == 'DISPATCHED' "    id='dispatched-status'    class='status'> {{order.status}}</p>
                <p *ngIf='!order.customer_name'>No Store Name</p>
              </td>
            </ng-container>

            <ng-container matColumnDef="origin">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Origin</th>
              <td mat-cell *matCellDef="let order">
                <p *ngIf='order.customer_name'>{{order.origin}}</p>
                <p *ngIf='!order.customer_name'>No origin recorded</p>
              </td>
            </ng-container>

          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class='text-align'> Number</th>
            <td mat-cell *matCellDef="let order" class='text-align'>
              <p *ngIf='order.customer_name'>{{order.customer_phone}}</p>
              <p *ngIf='!order.customer_name'>No Phone number</p>
            </td>
          </ng-container>


          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class='text-align'>Amount</th>
            <td mat-cell *matCellDef="let order" class='text-align'>
              <p *ngIf='order.amount'> R{{order.amount | number:"1.2-2" }} </p>
              <p *ngIf='!order.amount'> No amount </p>
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="Columns"></tr>
          <tr mat-row class='pointer' routerLink='/orders/{{order.ID}}' *matRowDef="let order; columns: Columns;"></tr>
        </table>
  
      <div class='mobile'>
        <mat-expansion-panel hideToggle  *ngFor='let order of orders '>
          <mat-expansion-panel-header >
            <mat-panel-title id='mat-title'>
              <p id='name'>{{order.customer_name}}</p>
              <mat-icon routerLink='/orders/{{order.ID}}'>edit</mat-icon>
            </mat-panel-title>
          </mat-expansion-panel-header>
              <mat-title class='title'>Store:</mat-title>
              <p>{{order.store_name}}</p>
            <span class="flex">
              <mat-title>Amount:</mat-title>
              <p>	{{order.amount}} </p>
            </span>
            <span class="flex">
              <mat-title>Status:</mat-title>
              <p>	{{order.status}} </p>
            </span>
          </mat-expansion-panel>
      </div>
  
      <div id="pager" >
        <div class="page"> 
    
    
        <div> Per page:</div> 
        <span class="silo-filter pagination">
          <button mat-button [matMenuTriggerFor]="paginate">
            <span>{{limit}}</span>
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #paginate="matMenu" >
            <button mat-menu-item (click)="setLimit(10)">10</button>
            <button mat-menu-item (click)="setLimit(20)">20</button>
            <button mat-menu-item (click)="setLimit(50)">50</button>
          </mat-menu>
        </span>
    
        <div (click)='start()' matTooltip="Skip to start" class='action' *ngIf='page > 1'>back to start</div>
        <p (click)='previous()' class='action'>previous</p>
        <div>{{page}}</div>
          <p (click)='next()' class='action'>next</p>
          <div> </div>
        </div>
      </div>
