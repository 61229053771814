<div *ngIf="removePicker" id="shade"></div>

<div id="dialog">
  <div class="dialog" *ngIf="removePicker">
    <span>
      <p>Are you sure you want to delete this picker?</p>
      <mat-icon class="action" (click)="removePickerDialog()">close</mat-icon>
    </span>
    <button mat-flat-button class="button" (click)="removePickerFromOrder()">
      Confirm
    </button>
  </div>
</div>

<div class="cart" *ngIf="cart">
  <!-- ------------------ -->
  <!--  ORDER LINE ITEMS  -->
  <!-- ------------------ -->

  <div class="top-action-buttons" *ngIf="!isDisChemUser">
    <button
      mat-flat-button
      class="button driver-button"
      (click)="goToLink(cart.ID)"
    >
      driver panel
    </button>
    <button
      mat-flat-button
      class="button"
      (click)="createInvoiceDialog()"
      *ngIf="
        cart.status == 'COMPLETED' ||
        cart.status == 'DISPATCHED' ||
        cart.status == 'PAID'
      "
    >
      Create Invoice
    </button>
  </div>

  <div
    class="cart-flex"
    [style.width]="auth.expand ? 'calc(100vw - 245px)' : 'calc(100vw - 145px)'"
  >
    <!-- ------------------ -->
    <!-- CUSTOMER INFO CARD -->
    <!-- ------------------ -->

    <div class="customer">
      <div class="store action customer-name" (click)="navigateStore()">
        <h3>{{ cart.store_name }} &nbsp;</h3>
        <mat-icon *ngIf="!isDisChemUser" matTooltip="go to store"
          >arrow_forward</mat-icon
        >
      </div>

      <hr />

      <h5>Contact Info</h5>
      <div class="contact">
        <mat-icon>person</mat-icon>
        <p>{{ cart.customer_name }}</p>
      </div>
      <div class="contact">
        <mat-icon class="material-icons-outlined">email</mat-icon>
        <p>{{ cart.customer_email }}</p>
      </div>
      <div class="customer-name action">
        <div class="contact">
          <mat-icon class="material-icons-outlined">phone</mat-icon>
          <p>{{ cart.customer_phone }}</p>
        </div>
        <mat-icon
          *ngIf="!isDisChemUser"
          matTooltip="go to customer profile"
          routerLink="/users/{{ cart.uid }}"
          >arrow_forward</mat-icon
        >
      </div>
      <hr />

      <h5>Shipping details</h5>
      <div class="shipping">
        <div class="contact">
          <mat-icon>location_on</mat-icon>
          <p>{{ cart.customer_address }}</p>
        </div>
        <span *ngIf="cart.silo_id == 5">
          <div class="contact">
            <mat-icon></mat-icon>
            <p>{{ cart.customer_province }}</p>
          </div>
          <div class="contact">
            <mat-icon></mat-icon>
            <p>{{ cart.customer_city }}</p>
          </div>
          <div class="contact">
            <mat-icon></mat-icon>
            <p>{{ cart.customer_suburb }}</p>
          </div>
          <div class="contact">
            <mat-icon></mat-icon>
            <p>{{ cart.customer_postcode }}</p>
          </div>
        </span>
        <div class="contact">
          <mat-icon>chat</mat-icon>
          <p *ngIf="cart.customer_instruction" class="customer-instruction">
            {{ cart.customer_instruction }}
            <span
              class="waybill-number action update-instruction"
              (click)="updateInstructionDialog()"
              >update instruction</span
            >
          </p>
          <p *ngIf="!cart.customer_instruction">
            no instruction given
            <span
              *ngIf="!isDisChemUser"
              class="waybill-number action"
              (click)="updateInstructionDialog()"
              >update instruction</span
            >
          </p>
        </div>
        <div class="contact" *ngIf="cart.silo_id == 5">
          <mat-icon>receipt_long</mat-icon>
          <p *ngIf="cart.waybill_number">
            {{ cart.waybill_number }}
            <span class="waybill-number">waybill number</span>
          </p>
          <p *ngIf="!cart.waybill_number">
            no waybill number
            <span
              *ngIf="cart.status == 'PAID'"
              (click)="createWaybill()"
              class="waybill-number action"
              >create waybill</span
            >
          </p>
        </div>
      </div>

      <hr *ngIf="!market_cart" />
      <h5 *ngIf="!market_cart">Cart highlights</h5>
      <div class="cart-highlights">
        <div class="highlight">
          <div class="contact" matTooltip="picker name" *ngIf="!market_cart">
            <mat-icon>touch_app</mat-icon>
            <p *ngIf="cart.picker_name">{{ cart.picker_name }}</p>
            <p *ngIf="!cart.picker_name">Picker not assigned</p>
            <span
              class="remove-picker"
              (click)="removePickerDialog()"
              *ngIf="cart.picker_name && !isDisChemUser"
              >remove</span
            >
          </div>
          <div class="contact" matTooltip="cart pin" *ngIf="!market_cart">
            <mat-icon>key</mat-icon>
            <p *ngIf="cart.pin">{{ cart.pin }}</p>
            <p *ngIf="!cart.pin">pin not found</p>
          </div>
          <div class="contact" matTooltip="driver name" *ngIf="!market_cart">
            <mat-icon>sports_motorsports</mat-icon>
            <p *ngIf="cart.driver_name">{{ cart.driver_name }}</p>
            <p *ngIf="!cart.driver_name">
              no driver assigned to order
              <span
                *ngIf="
                  (!isDisChemUser && cart.status == 'COMPLETED') ||
                  (!isDisChemUser && cart.status == 'DISPATCHED')
                "
                class="waybill-number action"
                (click)="manualTripDialog()"
                >log manual trip</span
              >
            </p>
          </div>
          <div
            class="contact"
            matTooltip="reason for manual trip"
            *ngIf="!market_cart"
          >
            <mat-icon>add_comment</mat-icon>
            <p *ngIf="cart.driver_reason">{{ cart.driver_reason }}</p>
            <p *ngIf="!cart.driver_reason">no reason provided</p>
          </div>
        </div>
        <div class="highlight">
          <div class="contact" matTooltip="cart amount" *ngIf="!market_cart">
            <mat-icon id="rand">R</mat-icon>
            <p>{{ cart.amount }}</p>
          </div>
          <div
            class="contact"
            matTooltip="driver distance"
            *ngIf="!market_cart"
          >
            <mat-icon>moped</mat-icon>
            <p>{{ cart.distance }} m</p>
          </div>
          <div
            class="contact"
            matTooltip="picking duration"
            *ngIf="!market_cart"
          >
            <mat-icon class="material-icons-outlined">timer</mat-icon>
            <p>{{ picking_duration }} m</p>
          </div>
        </div>
      </div>
    </div>

    <!-- ------------------ -->
    <!--  PAYMENT DETAILS   -->
    <!-- ------------------ -->
    <div class="cart-payment-tracking">
      <div
        class="payment-summary"
        [style.height]="market_cart ? '220px' : '325px'"
      >
        <h3>Payment Summary</h3>
        <div class="payment">
          <p class="type">Card total</p>
          <p
            *ngIf="
              cart.status == 'PICKING' || cart.status == 'PLACED';
              else cardTotal
            "
            class="amount"
          >
            pending
          </p>
          <ng-template #cardTotal class="amount"
            >R {{ cart.amount_card | number: "1.2-2" }}</ng-template
          >
        </div>
        <div class="payment">
          <p class="type">Credit total</p>
          <p
            *ngIf="
              cart.status == 'PICKING' || cart.status == 'PLACED';
              else creditTotal
            "
            class="amount"
          >
            pending
          </p>
          <ng-template #creditTotal class="amount"
            >R {{ cart.amount_credits | number: "1.2-2" }}</ng-template
          >
        </div>
        <div class="payment" *ngFor="let item of paymentItems">
          <p class="type">
            <span *ngIf="item.item_type == 'promo_code_discount'"
              >promocode -
              <span
                *ngIf="
                  item.item_type == 'promo_code_discount' && item.price == 0
                "
                >N/A</span
              >
            </span>
            {{ item.name }}
          </p>
          <p class="amount">{{ item.price }}</p>
        </div>
        <div class="payment total">
          <p class="type">total amount</p>
          <p class="amount">R {{ cart.amount | number: "1.2-2" }}</p>
        </div>
      </div>

      <div class="payment-buttons" *ngIf="!isDisChemUser">
        <span
          matTooltip="{{ status.info }}"
          [ngClass]="statusoverride == status.api_call ? 'selected' : ''"
          (click)="setStatus(status)"
          *ngFor="let status of status"
          >{{ status.display_name }}
        </span>
      </div>

      <span class="cancel-reason" *ngIf="cart && cart.cancel_reason !== ''">
        Cancelled Reason: &nbsp; {{ cart.cancel_reason }}</span
      >
    </div>
  </div>
</div>

<app-user-messages
  *ngIf="picker_uid"
  [picker]="picker_uid"
  [picker_name]="picker_name"
  [cart]="id"
></app-user-messages>
