import { StoreDetailComponent } from './../store-detail/store-detail.component';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MessagesService } from 'src/app/services/messages.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-store-details',
  templateUrl: './store-details.component.html',
  styleUrls: ['./store-details.component.scss'],
})
export class StoreDetailsComponent implements OnInit {
  page = 1;
  limit = 10;
  search = '';
  retries = 0;

  id;
  url;
  silo;
  name;
  mall;
  email;
  malls;
  silos;
  phone;
  store;
  image;
  slogan;
  vat_num;
  silo_id;
  retailer;
  api_link;
  latitude;
  retailers;
  longitude;
  store_name;
  retailer_id;
  loop_branch_code;
  liquor_license_number;

  warehouse_code
  delivery_partner;
  delivery_partners = ['loop', 'picup'];
  trip_event_trigger
  trip_event_triggers = ["PLACED", "PROCESSING", "PAID", "READY FOR COLLECTION"]

  constructor(
    public auth: AuthService,
    public message: MessagesService,
    public api: ApiService,
    public route: ActivatedRoute,
    public s: StoreDetailComponent
  ) {}

  ngOnInit(): void {
    this.id = JSON.parse(this.route.snapshot.paramMap.get('id'));
    this.getStore();
    this.getSilos();
  }

  getSilos() {
    this.api.getList('catelog', `silos`).subscribe(
      (silos: any) => {
        this.silos = silos;
      },
      (error) => {
        this.message.showSnack(error.error);
      }
    );
  }

  async getStore() {
    this.api.get('catelog', `stores`, this.id).subscribe(
      (data: any) => {
        this.image = data.banner_image;
        this.name = data.name;
        this.slogan = data.about;
        this.phone = data.phone;
        this.email = data.email;
        this.silo = data.silo_id;
        this.api_link = data.api_link;
        this.vat_num = data.vat_number;
        this.liquor_license_number = data.liquor_license_number;
        this.loop_branch_code = data.loop_branch_code;
        this.url = data.url;
        this.retailer = data.retailer_name;
        this.retailer_id = data.retailer_id;
        this.latitude = data.latitude;
        this.longitude = data.longitude;
        this.delivery_partner = data.delivery_partner;
        this.store = data;
        this.store_name = data.name;
        this.warehouse_code = data.warehouse_code
        this.trip_event_trigger = data.trip_event_trigger

        this.getRetailers();
      },
      (error: any) => {
        this.message.showSnack(error.error);
        if (this.retries < 3) {
          if (error.status !== 401) {
            this.retries++;
            this.getStore();
          }
        }
      }
    );
  }

  getRetailers() {
    this.api
      .getList(
        'catelog',
        `retailers?page=${this.page}&per_page=${1000}&search=${this.search}`
      )
      .subscribe((retailers: any) => {
        this.retailers = retailers;

        this.retailer = this.store.retailer_name;
      });
  }

  // ========================
  //     STORE PATCH
  // ========================

  editName = false;
  editEmail = false;
  editPhone = false;
  editVat = false;
  editLiquor = false;
  editApi = false;
  editLoop = false;
  editSilo = false;
  editRetailer = false;
  editSlogan = false;
  editUrl = false;
  editLatitude = false;
  editLongitude = false;
  editWarehouseCode = false;
  editDelPrtnr = false;
  editTripEventTrigger = false;

  allFields = false;

  cancelUdpate(i) {
    this.allFields = false;

    this.name = this.store.name;
    this.editName = false;

    this.email = this.store.email;
    this.editEmail = false;

    this.phone = this.store.phone;
    this.editPhone = false;

    this.vat_num = this.store.vat_number;
    this.editVat = false;

    this.liquor_license_number = this.store.liquor_license_number;
    this.editLiquor = false;

    this.api_link = this.store.api_link;
    this.editApi = false;

    this.loop_branch_code = this.store.loop_branch_code;
    this.editLoop = false;

    this.silo_id = this.store.silo_id;
    this.editSilo = false;

    this.retailer = this.store.retailer_name;
    this.editRetailer = false;

    this.slogan = this.store.about;
    this.editSlogan = false;

    this.url = this.store.url;
    this.editUrl = false;

    this.latitude = this.store.latitude;
    this.editLatitude = false;

    this.longitude = this.store.longitude;
    this.editLongitude = false;

    this.editWarehouseCode = this.store.editWarehouseCode;
    this.editWarehouseCode = false;

    this.delivery_partner = this.store.delivery_partner;
    this.editDelPrtnr = false;

    this.trip_event_trigger = this.store.trip_event_trigger;
    this.editTripEventTrigger = false;
  }

  updateStore(i) {
    if (i == 'delivery') {
      this.editDelPrtnr = true;
    }

    if (i == 'name') {
      this.editName = true;
    }

    if (i == 'email') {
      this.editEmail = true;
    }
    if (i == 'phone') {
      this.editPhone = true;
    }
    if (i == 'vat') {
      this.editVat = true;
    }
    if (i == 'liquor') {
      this.editLiquor = true;
    }
    if (i == 'api') {
      this.editApi = true;
    }
    if (i == 'loop') {
      this.editLoop = true;
    }
    if (i == 'silo') {
      this.editSilo = true;
    }
    if (i == 'retailer') {
      this.editRetailer = true;
    }
    if (i == 'slogan') {
      this.editSlogan = true;
    }
    if (i == 'url') {
      this.editUrl = true;
    }
    if (i == 'latitude') {
      this.editLatitude = true;
    }
    if (i == 'longitude') {
      this.editLongitude = true;
    }
    if (i == 'warehouse_code'){
      this.editWarehouseCode = true
    }

    if (i == 'trigger_event') {
      this.editTripEventTrigger = true;
    }
  }

  updateAllFields() {
    this.allFields = !this.allFields;

    this.editName = true;
    this.editEmail = true;
    this.editPhone = true;
    this.editVat = true;
    this.editLiquor = true;
    this.editApi = true;
    this.editLoop = true;
    this.editSilo = true;
    this.editRetailer = true;
    this.editSlogan = true;
    this.editUrl = true;
    this.editLatitude = true;
    this.editLongitude = true;
    this.editWarehouseCode = true;
    this.editDelPrtnr = true;
  }

  patch() {
    let store = {
      id: this.id,
      name: this.name,
      about: this.slogan,
      email: this.email,
      phone: this.phone,
      liquor_license_number: this.liquor_license_number,
      url: this.url,
      vat_number: this.vat_num,
      retailer_id: this.retailer_id.id,
      silo_id: this.silo,
      api_link: this.api_link,
      loop_branch_code: this.loop_branch_code,
      warehouse_code: this.warehouse_code,
      delivery_partner: this.delivery_partner,
      trip_event_trigger: this.trip_event_trigger
    };

    this.api.patch('catelog', `stores`, store).subscribe(      (store: any) => {
        this.message.showSnack('Sucessfully updated store');
        this.getStore();
        this.cancelUdpate(event);
        this.allFields = false;
        this.s.getStore();
      },
      (error) => {
        this.message.showSnack(error);
      }
    );
  }
}
