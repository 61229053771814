<div class="header">
  <h3>Update asset</h3>
  <mat-icon (click)="closeDialog()" class="action">clear</mat-icon>
</div>
<hr />

<form #asset = 'ngForm' (ngSubmit)='updateAsset(asset.value)'>
  <mat-form-field appearance="outline" class="field">
    <mat-label>Asset name</mat-label>
    <input
      type="text"
      matInput
      [(ngModel)]="name"
      placeholder="Scooter"
      name="name"
      required
    />
  </mat-form-field>

  <mat-form-field appearance="outline" class="field">
    <mat-label>Asset registration</mat-label>
    <input
      type="text"
      matInput
      [(ngModel)]="registration"
      placeholder="cy12345"
      name="registration_number"
    />
  </mat-form-field>

  <mat-form-field appearance="outline" class="field">
    <mat-label>Asset description</mat-label>
    <input
      type="text"
      matInput
      [(ngModel)]="description"
      placeholder="125cc"
      name="description"
    />
  </mat-form-field>

  <mat-form-field appearance="outline" class="field">
    <mat-label>Asset value</mat-label>
    <input
      type="number"
      matInput
      [(ngModel)]="value"
      placeholder="10000"
      name="value"
    />
  </mat-form-field>

  <div class="save">
    <button mat-flat-button class="button">submit</button>
  </div>
</form>
