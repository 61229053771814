import { MiscService } from './../misc/misc.service';
import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  payments
  batches
  batchPayment
  payment_methods

  constructor(public misc: MiscService) { }

  async getPayments(){
    await this.misc.apiCall(`payments`, `admin/payments`).then(
      (res: any) => {
        this.payments = res.data
      }
    )
  }

  async getPaymentBatches(page){
    await this.misc.apiCall(`payments`, `admin/batches?page=${page}`).then(
      (res: any) => {
        this.batches = res.batches
      }
    )
  }


  async downloadPaymentFile(id,fileName){ 

    var date = new Date().toISOString().slice(0, 10); 

    await this.misc.exportCall(`payments`, `admin/batches/${id}/download`).then(
      (res: any) => {
        var blob = new Blob([res], {type: 'text/csv;charset=utf-8;'})

        var downloadURL = window.URL.createObjectURL(blob)
        var link = document.createElement('a')
        link.href = downloadURL
        console.log(res)
        link.download = `Payouts-${fileName}-${date}.csv`
        link.click()
      }
    )
  }

  async getPaymentBatch(id){
    await this.misc.apiCall(`payments`, `admin/batches/${id}/payments`).then(
      (res: any) => {
        this.batchPayment = res.payments
      }
    )
  }

  async getPaymentMethods(){
    await this.misc.apiCall('payments', `admin/payment_methods`).then(
      (res: any) => {
        this.payment_methods = res['payment_methods']
      }
    )
  }

  async CreatePaymentMethod(obj){
    await this.misc
    .apiPost('payments', `admin/payment_methods`, obj)
    .then((res: any) => {
      // console.log(res)
      return;
    });
  }

  updatePaymentMethod(id, obj){
    this.misc.apiPatch('payments', `admin/payment_methods/${id}`, obj).then((res: any) => {
      return
    })
  }
}
