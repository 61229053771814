import { Component, OnInit } from '@angular/core'
import { ApiService } from 'src/app/services/api.service'
import { MessagesService } from 'src/app/services/messages.service'
import * as _moment from 'moment'
import { AuthService } from 'src/app/services/auth.service'

@Component({
  selector: 'app-public-holidays',
  templateUrl: './public-holidays.component.html',
  styleUrls: ['./public-holidays.component.scss']
})
export class PublicHolidaysComponent implements OnInit {
  Columns = [ 'holiday', 'date', 'open', 'close', 'edit']

  id
  day
  silo
  silos
  holiday
  silo_id
  page = 1
  openValue
  closeValue
  limit = 10
  retries = 0
  edit = false
  holidays = []
  create = false
  delete = false
  state = 'insert'


  constructor(private api: ApiService,
              private message: MessagesService,
              public auth: AuthService) { }

  ngOnInit(): void {
    this.getHolidays()
  }

  // =================
  //      TOGGLE
  // =================

  toggleCreate(){
    this.create = !this.create
  }

  toggleEdit(hour){
    this.edit = !this.edit
    this.holiday = hour
  }

  deleteCheck(hour){
    this.delete = !this.delete
    this.holiday = hour
  }
  
  // =================
  //      SILOS
  // =================

  getSilos(){
    return new Promise(resolve => {
      this.api.getList("catelog","silos").subscribe((data:any)=>{
        if(!this.silo){
        this.silo = data[0]
        this.silo.id = data[0].id
        }
        this.silos = data
        resolve("success")
      })
  })

}

siloChanged(silo){
  this.silo = silo
  this.holidays = []
  this.getHolidays()
}

  // =================
  //      HOURS
  // =================

  async getHolidays(){
    await this.getSilos()
    let holiday = []
    this.api.getList(`fulfilment`, `admin/holidays?page=${this.page}&per_page=50`).subscribe((data: any) => {
      for (var i = 0; i < data.holidays.length; i++){
        if(this.silo.id == data.holidays[i].silo_id){
          holiday.push(data.holidays[i])
          this.holidays = holiday
        }
      }
    }, error => {
      this.message.showSnack(error.error)
      if(this.retries < 3 && error.status !== 401){
        this.retries++
        this.getHolidays()
      }
    })
  }

  updateHours(hour){
    let date = _moment(hour.date).format('YYYY-MM-DD');

    let newHours = {
      silo_id: this.holiday.silo_id,
      name: hour.day,
      opens_at: hour.opens,
      closes_at: hour.closes,
      date: date
    }

    this.api.patch(`fulfilment`, `admin/holidays/${this.holiday.ID}`, newHours).subscribe((hours: any) => {
      this.message.showSnack(`Successfully updated trading hours for ${hours.day_name}`);
      this.getHolidays()
      this.toggleEdit(hour)
    }, error => {
      this.message.showSnack(error.statusText);
    })
  }


  createHoliday(holiday){
    let date = _moment(holiday.date).format('YYYY-MM-DD');

    let newHoliday = {
      silo_id: holiday.silo,
      name: holiday.day,
      opens_at: holiday.opens,
      closes_at: holiday.closes,
      date: date,
    }

    console.log(newHoliday)

    this.api.post("fulfilment","admin/holidays", newHoliday).subscribe((data:any)=>{
      this.message.showSnack(`Successfully created public holiday trading hour`);
      this.getHolidays()
      this.toggleCreate()
    },error=>{
      this.message.showSnack(error.statusText)
    });
  }

  deleteHour(){
    this.api.delete(`fulfilment`, `admin/trading_hours/${this.holiday.ID}`).subscribe((hour:any) => {
      this.message.showSnack(`Successfully removed trading hour`)
      this.getHolidays()
      this.deleteCheck(hour)
    }, error => {
      this.message.showSnack(error.statusText);
    })
  }

  setLimit(limit){
  }

  next(){
    this.page++
    this.getHolidays()
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.getHolidays()
    }
  }

}
