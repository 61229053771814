import { ApiService }                               from 'src/app/services/api.service'
import { AuthService }                              from './../../../services/auth.service'
import { MessagesService }                          from 'src/app/services/messages.service'
import { Router, ActivatedRoute }                   from '@angular/router'
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Component, Inject, OnInit, EventEmitter  } from '@angular/core'

@Component({
  selector: 'app-mall-stores',
  templateUrl: './mall-stores.component.html',
  styleUrls: ['./mall-stores.component.scss']
})
export class MallStoresComponent implements OnInit {
  Columns = ['name', 'address', 'email', 'phone', 'active']


  id
  stores

  page  = 1
  limit = 20

  constructor(public api: ApiService,
              public message: MessagesService,
              public route: ActivatedRoute,
              public auth: AuthService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.id = JSON.parse(this.route.snapshot.paramMap.get('id'))
    this.getMallStores()
  }
  

  getMallStores(){
    this.api.getList('catelog', `malls/${this.id}/stores`).subscribe((stores: any) => {
      this.stores = stores
      // console.log(stores)
    })
  }

  removeStoreFromMall(store){
    this.api.delete('catelog', `malls/${store.id}/stores`).subscribe((data: any) => {
      // console.log(data)
      this.getMallStores()
    })
  }

  // =================
  //   PAGINATION
  // =================

  setLimit(limit){

  }

  next(){

  }

  previous(){

  }

  start(){

  }


  // ====================
  //   STORE ADD DIALOG
  // ====================


    // open dialog for banner update
    addStoreDialog(){
      const dialog = this.dialog.open(StoreAddDialog, {
        width: '950px',
        height: '600px',
        data: {
          mall: this.id
        }
      })
  
      dialog.afterClosed().subscribe(() => {
        this.getMallStores()
      })
    }

}

  // ============================
  //      STORE ADD DIALOG
  // ============================

  @Component({
    selector: 'store-add-dialog',
    templateUrl: 'store-add-dialog.html',
    styleUrls: ['./store-add.component.scss']
  })
  export class StoreAddDialog {
    Columns = ['name', 'address', 'add']


    // get mall stores after success
    onAdd = new EventEmitter()

    inputTouch = false

    onFocus(){
      this.inputTouch = true
    }
  
    onBlur(){
      this.inputTouch = false
    }

    id
    stores
    page      = 1
    limit     = 6
    search    = ''
    silo_id   = 20
    storesArr = []

  
  
  
    constructor(@Inject(MAT_DIALOG_DATA) 
                public data: { mall, }, 
                private api : ApiService, 
                private message: MessagesService,  
                public dialogRef: MatDialogRef<StoreAddDialog>) {
  
    }
  
    ngOnInit(): void {
      this.id = this.data.mall
      this.getStores()
    }

    closeDialog() {
      this.dialogRef.close()
      this.onAdd.emit()
    }
    
  // ========================
  //    GET STORES TO ADD
  // ========================

  getStores(){
      this.api.getList('catelog', `stores?silo_id=${this.silo_id}&page=${this.page}&per_page=${this.limit}&search=${this.search}`)
      .subscribe((stores:any) =>{
        this.stores = stores
      }, error => {
        this.message.showSnack(error.error)
      })
    }

    toggleSelection(cat) {

      var storeRemove = this.storesArr.map(item => item.id).indexOf(cat.id)
  
      if(storeRemove !== -1){
        this.storesArr.splice(storeRemove, 1);
      } else if(storeRemove === -1){
        this.storesArr.push(cat);
      }
   }

   addStores(){
     let stores = []

     this.storesArr.forEach(store => {
       let s = {
         store_id: store.id
       }
       stores.push(s)
     })

       this.api.post('catelog', `malls/${this.id}/stores`, stores).subscribe((data: any) => {
         this.message.showSnack('succesfully added stores to mall')
        //  console.log(data)
         this.closeDialog()
       })
    }

  }
