import { Component, EventEmitter, Inject,OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LoopBranchMapService } from 'src/app/services/loopmap/loopbranchmap.service';
import { MiscService } from 'src/app/services/misc/misc.service';
import { FormsModule } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MessagesService } from 'src/app/services/messages.service';
@Component({
  selector: 'app-list-branchmap',
  templateUrl: './list-branchmap.component.html',
  styleUrls: ['./list-branchmap.component.scss']
})
export class ListBranchmapComponent implements OnInit {
  postInfo = false;
  inputTouch = false
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public misc: MiscService,
    public dialog: MatDialog,
    public message: MessagesService,
    public auth: AuthService,
    public branchservice:LoopBranchMapService) { 

    }
    Columns = [ 'LoopId', 'PartnerBranchID']
  ngOnInit(): void {
    this.branchservice.getLoopBranchMaps();
  }
  

  onFocus(){
    this.inputTouch = true
    this.branchservice.getLoopBranchMaps();
  }

  onBlur(){
    this.inputTouch = false
  }
  drop(item){
    
  }
  updateBranchMap(branchmap) {
    const dialog = this.dialog.open(UpdateBranchMapDialog, {
      width: '850px',
      height: '600px',
      data: {
        branchmap: branchmap,
      },
    });
    dialog.afterClosed().subscribe(() => {
      this.branchservice.getLoopBranchMaps()
    });
  }
  
  searchReset(){
    console.log("go Search")
    console.log(this.branchservice.search)
    this.branchservice.page = 1
    this.branchservice.getLoopBranchMaps();
    localStorage.setItem('BranchMapPage', JSON.stringify(this.branchservice.page))
  }

  removeBranchMap(branchmap) {
    const dialog = this.dialog.open(RemoveBranchMapDialog, {
      width: '550px',
      height: '200px',
      data: {
        branchmap: branchmap,
        
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.branchservice.getLoopBranchMaps()
    });
  }
  clearSearch(){
    this.branchservice.page = 1
    this.branchservice.search = ''
    this.branchservice.getLoopBranchMaps();
    localStorage.setItem('BranchMapPage', JSON.stringify(this.branchservice.page))
  }
  setLimit(limit){
    this.branchservice.limit = limit
    this.branchservice.getLoopBranchMaps();
    localStorage.setItem('limit', JSON.stringify(limit))
  }

  next(){
    if(this.branchservice.branchmapsList.length < this.branchservice.limit){
      this.message.showSnack('no more stores to show')
    } else {
    if(this.branchservice.branchmapsList.length !== 0){
    this.branchservice.page++
      this.branchservice.getLoopBranchMaps();
  }
}
  localStorage.setItem('BranchMapPage', JSON.stringify(this.branchservice.page));
  }

  previous(){
      if( this.branchservice.page > 1){
        this.branchservice.page--;
        this.branchservice.getLoopBranchMaps();
    }
    localStorage.setItem('BranchMapPage', JSON.stringify(this.branchservice.page));
  }

  start(){
    this.branchservice.page = 1;
    this.branchservice.getLoopBranchMaps();
    localStorage.setItem('BranchMapPage', JSON.stringify(this.branchservice.page));

  }
}

// ============================
//    ASSET UPDATE DIALOG
// ============================

@Component({
  selector: 'update-branchmap-dialog',
  templateUrl: 'update-branchmap-dialog.html',
  styleUrls: ['../../../components/dialogs/update-dialog.component.scss'],
})
export class UpdateBranchMapDialog {
  id;
  loopID;
  type;
  partnerID;
  postInfo = false;
  branchmap;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public brancMap: { branchmap },
    public service: LoopBranchMapService,
    public dialogRef: MatDialogRef<UpdateBranchMapDialog>
  ) {}

  ngOnInit(): void {
    console.log(this.brancMap.branchmap);
    this.id =  this.brancMap.branchmap.ID;
    this.loopID = this.brancMap.branchmap.loop_id;
    this.partnerID = this.brancMap.branchmap.partner_branch_id;
      
  }

  // get section info after success
  onAdd = new EventEmitter();

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  update(){
    let obj = {loop_id:this.loopID,partner_branch_id:this.partnerID,ID:this.id};
    this.service.updateLoopBranchMap(obj,this.id);
    this.closeDialog();
  }
}

//remove Dialog
@Component({
  selector: 'delete-dialog',
  templateUrl: '../../../components/dialogs/delete-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class RemoveBranchMapDialog {
  inputTouch = false;
  heading = `BranchMap`;
  body = `Are you sure you want to delete this Branchmap?`;

  branchmap;

  // get section info after success
  onAdd = new EventEmitter();

  onFocus() {
    this.inputTouch = true;
  }

  onBlur() {
    this.inputTouch = false;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { branchmap },
    // private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<RemoveBranchMapDialog>,
    public service: LoopBranchMapService,
  ) {}

  ngOnInit(): void {
    this.branchmap = this.data.branchmap;
  }

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  removeItem() {
    this.service.deleteLoopbranchMap(this.branchmap.ID);
    this.closeDialog();
  }
}
