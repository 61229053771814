import { MessagesService } from 'src/app/services/messages.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { Component, Inject, OnInit, EventEmitter  } from '@angular/core'
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop'


@Component({
  selector: 'app-category-subcategories',
  templateUrl: './category-subcategories.component.html',
  styleUrls: ['./category-subcategories.component.scss']
})
export class CategorySubcategoriesComponent implements OnInit {

  id
  subcategories
  category

  constructor(public api: ApiService,
              public auth: AuthService,
              public route: ActivatedRoute,
              public message: MessagesService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getSubCategories()
    this.getCategory()
  }

  getSubCategories(){
    this.api.getList("catelog",`categories/${this.id}/categories?per_page=${100}`)
    .subscribe((data:any)=>{
      console.log(data)
      this.subcategories = data
    })
  }
  
  getCategory(){
    this.api.getItem("catelog","categories",this.id).subscribe((data:any)=>{
      this.category = data
    })
  }

  // =================
//   DRAG AND DROP
// =================

    drop(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.subcategories, event.previousIndex, event.currentIndex)

      var list=[]

      this.subcategories.forEach(category=>{
        list.push(category.id)
      })
      
      let categories = {
        table: 'categories',
        ids: list
      }

      this.api.patch("catelog","sort", categories).subscribe(data=>{
        this.message.showSnack('Succesfully updated positions of categories')
        this.getSubCategories()
      },error=>{ })
    }

// =================
//   REMOVE CATEGORY 
// =================

    removeItem(item){
      const dialog = this.dialog.open(removeSubCategoryItem, {
        width: '550px',
        height: '200px',
        data: {
          category: item
        }
      })

      dialog.afterClosed().subscribe(() => {
        this.getSubCategories()
      })
    }

// =================
//   ADD CATEGORY 
// =================

  addItem(){
    const dialog = this.dialog.open(addSubCategoryDialog, {
      width: '800px',
      height: 'calc(100vh - 300px)',
      data: {
        category: this.category
      }
    })

    dialog.afterClosed().subscribe(() => {
      this.getSubCategories()
    })
   }

}


// ============================
//    REMOVE DIALOG
// ============================

@Component({
  selector: 'subCat-delete-dialog',
  templateUrl: '../../../../components/dialogs/delete-dialog.html',
  styleUrls: ['../../../../components/dialogs/delete-dialog.component.scss']
})

export class removeSubCategoryItem {

  
  inputTouch  = false
  heading        = `Sub-Category`
  body        = `Are you sure you want to remove this item from this category? You can always add it again later.`


  category

  // get section info after success
  onAdd       = new EventEmitter()
  

  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: { category }, 
              private api : ApiService, 
              private message: MessagesService,  
              public dialogRef: MatDialogRef<removeSubCategoryItem>) {

  }

  ngOnInit(): void {
    this.category = this.data.category
  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

  removeItem(){

    this.api.delete('catelog', `categories/${this.category.id}`).subscribe((category: any) => {
      this.message.showSnack(`Successfully removed sub-category ${this.category.name} from ${this.category.name}`)
      this.closeDialog()
    }, error => {
      this.message.showSnack(error.error)
    })

  
  }

}


// ==============
//   EDIT POST  
// ==============
@Component({
  selector: 'category-subcategories-add',
  templateUrl: 'category-subcategories-add.html',
  styleUrls: ['../../../products/product/product-detail/update-product-dialog.component.scss']
})

export class addSubCategoryDialog {

  cat
  name

  // get section info after success
  onAdd       = new EventEmitter()


  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: { category }, 
              private api : ApiService, 
              private message: MessagesService,  
              public dialogRef: MatDialogRef<addSubCategoryDialog>) {
  }

  ngOnInit(): void {
    this.cat = this.data.category
  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }


  createCategory(){
    let subcategory = [
      {
        name: this.name,
        parent_id: this.cat.id,
        silo_id: this.cat.silo_id,
        image: this.cat.image,
        position: 1,
        linked_key: JSON.stringify(Date.now()),
        active: true
      }
    ]

    // console.log(subcategory)

    this.api.post(`catelog`, `categories`, subcategory).subscribe((subcat: any) => {
      this.message.showSnack(`Successfully added Sub-category ${subcat.name} to ${this.name}`)
      this.closeDialog()
    }, error => {
      this.message.showSnack(error.error)
    })
  }

}