import { Injectable } from '@angular/core';

import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class PagerService {
  public search:String='';
  public resource:String ="products"
  public data=[];
  public page=1;
  public limit=20;
  public route="";
  public isList=false;
  private service='';
  public visible=true;

  public mitems =[
    // {label:'Products',path:'/products'},
    // {label:'Categories',path:'/categories'},
    // {label:'Silos',path:'/silos'},
    // {label:'Retailers',path:'/retailers'},
    // {label:'Retailers',path:'/retailers'},
  ]

  constructor(
    private api:ApiService
  ) {

  }

  list(service,resource){
    this.visible=true;
    this.service =service;
    if(this.resource != resource){
      this.data=[];
      this.search='';
    }
   this.isList =true;
    //this.data=[];
    // this.setSubMenus(resource);
    var url = this.resource + "?page="+this.page+"&per_page="+this.limit;
    if(this.search!=''){
      var url = this.resource + "?search="+this.search.toLowerCase()+"&page="+this.page+"&per_page="+this.limit;
    }
    this.resource =  resource;

    this.api
    .getList(service, url)
    .subscribe((result:any)=>{
     // alert(this.data.length);
      this.data = result;
      console.log(this.data)
    })
  }

  setSubMenus(resource){
  }
}
