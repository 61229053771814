import { AuthService } from './../../../services/auth.service';
import { ActivatedRoute }                           from '@angular/router'
import { ApiService }                               from 'src/app/services/api.service'
import { Component, Inject, OnInit, EventEmitter }                        from '@angular/core'
import { MessagesService }                          from 'src/app/services/messages.service'
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'


@Component({
  selector: 'app-store-products',
  templateUrl: './store-products.component.html',
  styleUrls: ['./store-products.component.scss']
})

export class StoreProductsComponent implements OnInit {
  Columns: string[] = ['image', 'name', 'size', 'was_price', 'price', 'qty', 'add']

  inputTouch = false

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }

  id
  qty
  price
  products
  storeProducts

  page    = 1
  limit   = 10
  search  = ''
  retries = 0
  
  constructor(public api : ApiService,
              public route: ActivatedRoute,
              public message: MessagesService,
              public dialog: MatDialog,
              public auth: AuthService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    localStorage.setItem('storeTab', 'products')

    if(localStorage.getItem('storeProductsPage')){
      this.page = JSON.parse(localStorage.getItem('storeProductsPage'))
    }

    if(localStorage.getItem('limit')){
      this.limit = JSON.parse(localStorage.getItem('limit'))
    }

    if(localStorage.getItem('storeProductsSearch')){
      this.search = localStorage.getItem('storeProductsSearch')
    }

    this.getStoreProducts()

  }


  getStoreProducts(){
    localStorage.setItem('storeProductsSearch', this.search)
    this.api.getList('catelog', `stores/${this.id}/products?page=${this.page}&per_page=${this.limit}&search=${this.search}`).subscribe((products: any) => {
      this.storeProducts = products

      }, error => {
        this.message.showSnack(error.error)
        if(this.retries < 3){
          if(error.status !== 401){
            this.retries++
            this.getStoreProducts()
          }
        }
      })
   }

   clearSearch(){
    this.page = 1
    this.search = ''
    this.getStoreProducts()
 }

 searchReset(){
  this.page = 1
  this.getStoreProducts()
  localStorage.setItem('storeProductsPage', JSON.stringify(this.page))
}

  //  =====================
  //      PAGINATION
  // ======================

  setLimit(limit){
    this.limit = limit 
    this.getStoreProducts()
    localStorage.setItem('limit', JSON.stringify(limit))
  }

  next(){
    if(this.storeProducts.length < this.limit){
      this.message.showSnack('no more products to show')
    } else {
    this.page++
      this.getStoreProducts()

    }
  localStorage.setItem('storeProductsPage', JSON.stringify(this.page))
  }

  previous(){
    if(this.page > 1){
      this.page--
        this.getStoreProducts()
    }
    localStorage.setItem('storeProductsPage', JSON.stringify(this.page))
  }

  start(){
    this.page = 1;
      this.getStoreProducts()

    localStorage.setItem('storeProductsPage', JSON.stringify(this.page))
  }



  deleteDialog(product){
    const dialog = this.dialog.open(deleteStoreProductDialog, {
      width: '550px',
      height: '200px',
      data: {
        product: product
      }
    })

    dialog.afterClosed().subscribe(() => {
      this.getStoreProducts()
    })
  }

  addDialog(product){
    const dialog = this.dialog.open(editStoreProductDialog, {
      width: '800px',
      height: '450px',
      data: {
        product: product
      }
    })

    dialog.afterClosed().subscribe(() => {
      this.getStoreProducts()
    })
  }

  addStoreProduct(){
    const dialog = this.dialog.open(addStoreProductDialog, {
      width: '1000px',
      height: 'calc(100vh - 150px)',
      data: {
        store: this.id
      }
    })

    dialog.afterClosed().subscribe(() => {
      this.getStoreProducts()
    })
  }

}

// ============================
//    PRODCUT REMOVE DIALOG
// ============================

@Component({
  selector: 'delete-dialog',
  templateUrl: '../../../components/dialogs/delete-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss']
})

export class deleteStoreProductDialog {
  
  heading        = `product from store`
  body           = `Are you sure you want to remove this product from this store? You can always add it again later.`

  product

    // get section info after success
    onAdd       = new EventEmitter()

    constructor(@Inject(MAT_DIALOG_DATA) 
                public data: { product }, 
                public api: ApiService ,
                public message: MessagesService,
                public dialogRef: MatDialogRef<deleteStoreProductDialog>) {}

    ngOnInit(): void {
      this.product = this.data.product
    }

    closeDialog() {
      this.dialogRef.close()
      this.onAdd.emit()
    }

    removeItem(){
      this.api.delete('catelog', `storeproducts/${this.product.store_product_id}`).subscribe((data: any) => {
        this.message.showSnack(`Succesfully removed product from store`)
        this.closeDialog()
      })
    }
    
}

// ============================
//    PRODUCT EDIT DIALOG
// ============================

@Component({
  selector: 'store-product-edit-dialog',
  templateUrl: './store-product-edit-dialog.html',
  styleUrls: ['../../../components/dialogs/update-dialog.component.scss']
})

export class editStoreProductDialog {

  id
  qty
  price
  product
  promo_price

  promoProduct = false

  // get section info after success
  onAdd       = new EventEmitter()  

    constructor(@Inject(MAT_DIALOG_DATA) 
                public data: { product }, 
                public api: ApiService ,
                public message: MessagesService,
                public route: ActivatedRoute,
                public dialogRef: MatDialogRef<editStoreProductDialog>) {}

    ngOnInit(): void {
      this.id = this.route.snapshot.paramMap.get('id')
      this.product = this.data.product
      this.price   = this.data.product.price
      this.qty     = this.data.product.qty
    }

    closeDialog() {
      this.dialogRef.close()
      this.onAdd.emit()
    }

    saveStoreProductInfo(){
    let storeProduct = [
        {
          id:  this.product.store_product_id,
          product_id: this.product.product_id,
          store_id: this.product.store_id,
          qty: JSON.parse(this.qty),
          price: JSON.parse(this.price),
          was_price: this.price
        }
      ]

      if(this.promoProduct){
        storeProduct[0].price = JSON.parse(this.promo_price)
      }


      // console.log(storeProduct)

      this.api.patch('catelog', `storeproducts`, storeProduct).subscribe((data: any) => {
        this.message.showSnack(`Succesfully update store product`)
        this.closeDialog()
      })

    }

}


@Component({
  selector: 'store-product-add-dialog',
  templateUrl: './store-product-add.html',
  styleUrls: ['../../pocket-mall/mall-stores/store-add.component.scss']
})

export class addStoreProductDialog {

  Columns = ['image', 'name', 'add']

  id
  qty
  silo
  silos
  products
  product
  price

  page      = 1
  limit     = 6
  search    = ''
  silo_id   = 1
  confirm   = false

  inputTouch = false



  // get section info after success
  onAdd       = new EventEmitter()
  
  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }


  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: { store, }, 
              private api : ApiService, 
              private message: MessagesService,  
              public dialogRef: MatDialogRef<addStoreProductDialog>) {

  }

  ngOnInit(): void {
    this.id         = this.data.store
    this.getSilos()
    this.getProducts()
  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

  clearSearch(){
    this.search = ''
    this.getProducts()
  }

// =================
//      SILOS
// =================

  getSilos(){
    return new Promise (resolve => {
      this.api.getList("catelog","silos").subscribe((silos:any)=>{
      this.silos = silos
      
      // set current silo
      silos.forEach(silo => {
        if(silo.tag == 'liquor'){
          this.silo = silo
        }
      })

        this.getProducts()
        resolve('success')
      })
    })
  }


  setSilo(asilo){
    this.silo      = asilo
    this.silo_id   = asilo.id
    this.getProducts()
  }

  // ========================
  //    GET PRODUCTS TO ADD
  // ========================

  getProducts(){
    this.api.getList('catelog', `products?page=${this.page}&per_page=${this.limit}&silo_id=${this.silo_id}&search=${this.search}`).subscribe((products: any) => {
      this.products = products
      })
   }


  confirmProduct(product){
    this.confirm   = !this.confirm
    this.product = product
    this.price   = this.product.price
  }


  addProducts(i){
    let product = [
      {
        price: JSON.parse(this.price),
        product_id: this.product.id,
        qty: JSON.parse(this.qty),
        store_id: JSON.parse(this.id)
      }
    ]
    
      this.api.post('catelog', 'storeproducts', product).subscribe((categories: any) => {
        this.message.showSnack('Succesfully added Product to store')
        this.closeDialog()
      }, error => {
        this.message.showSnack(error.error)
        console.log(product)
      })
  }


// =================
//    PAGINATION
// =================

  next(){
    this.page++ 
    this.getProducts()
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.getProducts()
    }
  }

  start(){
    this.page = 1
    this.getProducts()
  }
}