<div class="heading">
  <div></div>
  <h4>Edit Post Details</h4>
  <mat-icon (click)="closeDialog()">clear</mat-icon>
</div>

<div class="edit-fields">
  <mat-form-field class="field" appearance="outline">
    <mat-label>name</mat-label>
    <input
      type="text"
      matInput
      [(ngModel)]="title"
      placeholder="The greatest cheesecake"
      name="title"
    />
  </mat-form-field>

  <mat-form-field class="field" appearance="outline">
    <mat-label>description</mat-label>
    <textarea
      type="text"
      matInput
      [(ngModel)]="body"
      placeholder="this is a description"
      name="body"
    ></textarea>
  </mat-form-field>

  <mat-form-field
    class="silos-field"
    appearance="outline"
    class="field"
    *ngIf="!isDisChemUser"
  >
    <mat-label>silo</mat-label>
    <mat-select [(ngModel)]="silo_id" name="silo_id" class="select-panel">
      <mat-option *ngFor="let silo of silos" [value]="silo.id">
        {{silo.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="save">
  <button mat-flat-button class="button cancel" (click)="closeDialog()">
    Cancel
  </button>
  <button
    mat-flat-button
    class="button"
    (click)="updatePost()"
  >
    Save
  </button>
</div>
