<div class="heading">
    <div></div>
    <h4>Edit store product</h4>
    <!-- <mat-icon (click)='closeDialog()' >clear</mat-icon> -->
</div>

<div class="edit-fields">
              
    <mat-form-field class='half-field' appearance='outline'>
        <mat-label>product quantity</mat-label>
        <input type="text" matInput [(ngModel)]="qty" placeholder="50" name='qty' required>
    </mat-form-field>
    
    <mat-form-field appearance="outline" class='half-field'>
        <mat-label *ngIf='!promoProduct'> product price</mat-label>
        <mat-label *ngIf='promoProduct'> original price</mat-label>
        <input matInput placeholder="99.99" [(ngModel)]="price" name='price' type='number' required>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf='promoProduct' class='half-field'>
        <mat-label>promotional price</mat-label>
        <input matInput placeholder="89.99" [(ngModel)]="promo_price" name='promo_price' type='number' required>
    </mat-form-field>

    <p class='checkbox'><mat-checkbox [(ngModel)]="promoProduct">This is a promotional product</mat-checkbox></p>

</div>


<div class="save">
    <button mat-flat-button class='button cancel' (click)='closeDialog()'> Cancel</button>
    <button mat-flat-button class='button' (click)='saveStoreProductInfo()'> Save</button>
</div>