import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  constructor(private api: ApiService,
              private message: MessagesService,
              public auth: AuthService) { }

  ngOnInit(): void {
    this.getData()


    if(window.innerWidth > 1100){
      this.width = window.innerWidth/2 - 100
      } else {
        this.width = window.innerWidth - 50
      }
    }
  
    // chart responsiveness
    onResize(event){
      var w = event.target.innerWidth
  
  
      if(w > 1100){
        this.width = w/2 - 100
      } else {
        this.width = w - 50
      }
    }

  data = 0

  title  = 'Browser market shares at a specific website, 2014'
  type   = 'PieChart'
  width  = 800
  height = 300

  // TOP STATISTICS
  topStats = []

  // ORDERS
  ordersPerMonth = []
  ordersPerDay   = [] //last thirty
  stores         = [] // TOP TEN STORES
  retailers      = [] //TOTAL ORDERS FOR EACH RETAILER
  retailer_carts = []
  retailer_views = []
  retailer_paids = []

  // CUSTOMER DATA
  quenchUsersSignUpPM  = []
  quenchUsersSignUpPD  = []
  disChemUsersSignUpPM = []
  disChemUsersSignUpPD = []

  // TRACKING DATA
  tracking              = []
  trackingStats         = []
  banners               = []
  retailer_top_products = []

  // CHART OPTION SETTINGS
  quench = {
    pointSize: 5,
    curveType: 'function',
    colors: ['#ff4081', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
    chartArea:{left:50,top:10,right:10,bottom:60 ,width: '100%', height: '100%'},
  }

  dischem = {
    pointSize: 5,
    curveType: 'function',
    colors: ['#14b366'],
    chartArea:{left:50,top:10,right:10,bottom:60 ,width: '100%', height: '100%'},
  }

  retailer_average_delivery_times = []

  getData(){

    // =================
    // FULFILMENT STATS
    // =================

    this.api.getList("fulfilment","dashboard/quench_stats").subscribe((result:any)=>{

      this.data++
      result = result.data

      let totalOrders = {
        name: 'Total Quench Orders',
        data: result.total.toLocaleString(),
        image: '/assets/images/total_quench_orders.svg'
      }

      let average_order_amount = {
        name: 'Average Order Amount',
        data: `R ${result.average_order_amount.toLocaleString()}`,
        image: '/assets/images/average_shopping_amount.svg'
      }

      result.months.forEach((month:any)=>{
        this.ordersPerMonth.push([month.effective_date.substring(0,10), month.total ])
      })
      this.ordersPerMonth = this.ordersPerMonth.slice(1) // remove once fulfilment fixed

      result.days.forEach((month:any)=>{
        this.ordersPerDay.push([month.effective_date.substring(0,10), month.total ])
      })
      this.ordersPerDay = this.ordersPerDay.reverse()

      this.stores = result.stores
      this.retailers = result.retailers

      this.trackingStats.unshift(average_order_amount)
      this.trackingStats.unshift(totalOrders)

    })

    // =================
    //    AUTH STATS
    // =================

    this.api.getList(`auth`, `admin/reports/users`).subscribe((result: any) => {

      this.data++


      // -----------------------
      // Quench customer data
      // -----------------------

      // TOTAL CUSTOMERS
      // ------------------------------------------------------------------------------
      this.api.getList('auth', `admin/users`).subscribe((users: any) => {

        let totalUsers = {
          name: 'Total quench customers',
          data: users.total.toLocaleString(),
          image: '/assets/images/total_quench_customers.svg'
        }

        this.topStats.splice(1, 0, totalUsers)

      }, error => {
        this.message.showSnack(error.error)
      })


      // SIGN UP AND LOGIN DATA
      // ------------------------------------------------------------------------------
      result.signups_per_month.forEach(signup => {
        this.quenchUsersSignUpPM.push([signup.month.substring(0,10), signup.total])
      })
      result.signups_per_day.forEach(signup => {
        this.quenchUsersSignUpPD.push([signup.day.substring(0,10), signup.total])
      })

      let quenchUserLogins = {
        name: 'Unique app logins (30 days)',
        data: result.logins_last_30_days.toLocaleString(),
        image: '/assets/images/unique_app_logins.svg'
      }
      let quenchActiveUpdates = {
        name: 'Unique active customers (30 days)',
        data: result.active_updates_last_30_days.toLocaleString(),
        image: '/assets/images/unique_active_customers.svg'
      }

    // =================
    //  DIS-CHEM STATS
    // =================

      result.signups_per_month_dischem.forEach(signup => {
        this.disChemUsersSignUpPM.push([signup.month.substring(0,10), signup.total])
      })
      if(result.signups_per_day_dischem !== null){
      result.signups_per_day_dischem.forEach(signup => {
        this.disChemUsersSignUpPD.push([signup.day.substring(0,10), signup.total])
      })
    }

      let disChemCustomerCount = {
        name: 'Dis-Chem Total Customers',
        data: result['dis-chem_count'].toLocaleString(),
        image: '/assets/images/dischem_total_customers.svg'
      }

      this.topStats.push(quenchUserLogins, quenchActiveUpdates, disChemCustomerCount)
 
    })

    // =================
    //  TRACKING STATS
    // =================

    this.api.getList(`tracking`, `admin/dashboard`).subscribe((result: any) => {

      let average_shopping_time
      let total_active_sessions
      let view_to_cart
      let cart_to_paid
      let top_product_retailer

      result.data.forEach(element => {
        if(element.hasOwnProperty('average_shopping_time')){
          average_shopping_time = element.average_shopping_time
        }
        if(element.hasOwnProperty('total_active_sessions')){
          total_active_sessions = element.total_active_sessions
        }
        if(element.hasOwnProperty('conversion')){
          view_to_cart = element.conversion.view_to_cart
          cart_to_paid = element.conversion.cart_to_paid
        }
        if(element.hasOwnProperty('top_product_retailer')){
          top_product_retailer = element.top_product_retailer
        }
      })

      this.data++

      // console.log(result)

      top_product_retailer.forEach(retail => {
        if(retail['retailer'].cart !== null){
          this.retailer_carts.push(retail["retailer"].cart)
        }
        if(retail['retailer'].view !== null){
          this.retailer_views.push(retail["retailer"].view)
        }
        if(retail['retailer'].paid !== null){
          this.retailer_paids.push(retail["retailer"].paid)
        }
      })

      // product stats
      let viewed = {
        name: 'Viewed',
        data: result.data[0].product.view
      }

      let cart = {
        name: 'Cart',
        data: result.data[0].product.cart
      }
      let paid = {
        name: 'Paid',
        data: result.data[0].product.paid
      }

      // shopping stats 
      let avgShopTime = {
        name: 'Average shopping time',
        data: average_shopping_time,
        image: '/assets/images/shop_session.svg',
        tag: 'time'
      }

      let activeSessions = {
        name: 'total active sessions',
        data: total_active_sessions,
        image: '/assets/images/active_sessions.svg'
      }

      let viewToCart = {
        name: 'conversion rate view to cart',
        data: view_to_cart,
        image: '/assets/images/view_to_cart.svg',
        tag: 'conversion'
      }

      let cartToPaid = {
        name: 'conversion rate cart to paid',
        data: cart_to_paid,
        image: '/assets/images/cart_to_paid.svg',
        tag: 'conversion'
      }

      this.tracking.push(viewed, cart, paid)
      this.banners = result.data[1].banner
      this.trackingStats.push(avgShopTime, activeSessions, viewToCart, cartToPaid)

      // get silos
      this.api.getList(`catelog`, `silos`).subscribe((silos: any) => {
        let banners = []
        this.banners.forEach(banner => {
          silos.forEach(silo => {
            if(banner.id == silo.id){
              let bannerData = {
                name: silo.name,
                data: banner.silo
              }
              banners.push(bannerData)
              this.banners = banners
            }
          })
        })
      })
    })
    
  }


  refreshDo(){
    this.api.getList("fulfilment","migrations/import_latest_orders").subscribe((result:any)=>{
      this.getData()
    })
  }

}
