import { AuthService } from 'src/app/services/auth.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { MessagesService } from '../messages.service';

@Injectable()
export class RoleGaurd implements CanActivate {
  role;
  finance_role;

  constructor(public auth: AuthService, private router: Router, public message: MessagesService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem('dis-chem-role')) {
      this.role = JSON.parse(localStorage.getItem('dis-chem-role'));
      this.auth.dis_chem_role = this.role;
    }

    if (localStorage.getItem('finance-role')) {
      this.finance_role = JSON.parse(localStorage.getItem('finance-role'));
      this.auth.finance_role = this.role;
    }

    // check for dischem user & finance role and navigate accordingly
    if (this.role) {
      this.router.navigate(['/carts']);
      this.message.showSnack('Redirecting!  You do not have permissions!')
    } else if 
   
    (!this.auth.dis_chem_role || !this.role ) {
      this.auth.dis_chem_role = false;
      return true;
    }
  }

}

@Injectable()

export class FinanceGaurd implements CanActivate {
  finance_role;

  constructor(public auth: AuthService, private router: Router, public message: MessagesService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    if (localStorage.getItem('finance-role')) {
      this.finance_role = JSON.parse(localStorage.getItem('finance-role'));
      this.auth.finance_role = this.finance_role;
    }

    // check for dischem user & finance role and navigate accordingly
    if (!this.finance_role) {
      this.message.showSnack('Redirecting!  You do not have permissions!')
      this.router.navigate(['/carts']);
    } else if (this.auth.finance_role || this.finance_role ) {
      this.auth.finance_role = false;
      return true;
    }
  }
  
}
