<div class="create-card">
  <div class="header">
    <h3>Add New bank</h3>
    <mat-icon routerLink="/banks">clear</mat-icon>
  </div>

  <form [formGroup]="firstForm">
    <mat-form-field appearance="outline" class="field">
      <mat-label>Bank name</mat-label>
      <input
        type="text"
        matInput
        ngModel
        placeholder="Absa"
        name="name"
        required
        formControlName="name"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="field">
      <mat-label>Branch code</mat-label>
      <input
        type="text"
        matInput
        ngModel
        placeholder="632005"
        name="branch_code"
        required
        formControlName="branch_code"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="field">
      <mat-label>swift code</mat-label>
      <input
        type="text"
        matInput
        ngModel
        placeholder="ABSAZAJJ"
        name="swift_code"
        required
        formControlName="swift_code"
      />
    </mat-form-field>
    
    <mat-form-field appearance="outline" class="field">
      <mat-label>account_number_regex</mat-label>
      <input
        type="text"
        matInput
        ngModel
        placeholder=""
        name="account_number_regex"
        required
        formControlName="account_number_regex"
      />
    </mat-form-field>

    <div class="save">
        <button
          mat-flat-button
          class="button"
          (click)="AddBank()"
        >
          submit
        </button>
      </div>
  </form>
</div>
