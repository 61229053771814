import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-incentive',
  templateUrl: './incentive.component.html',
  styleUrls: ['./incentive.component.scss']
})
export class IncentiveComponent implements OnInit {

  drivers:any=[];
  user_accounts:any=[];
  id:string;
  search:string="";
  page=1;
  constructor(
    private api:ApiService,
    public route: ActivatedRoute,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.getDrivers()

    this.id = this.route.snapshot.paramMap.get('id');
    this.getIncentiveDrivers()
 
  }

  getDrivers(){

    this.api.getList("auth","admin/drivers?page="+this.page).subscribe((data:any)=>{
     
      this.user_accounts = data.users;
    });

  }

  getIncentiveDrivers(){

    this.api.getList("godrive","admin/incentives/"+this.id+"/drivers").subscribe((data:any)=>{
 
      this.drivers = data.drivers;
    });
  }
  searchUser(){

    this.page=1;
    this.api.getList("auth","admin/drivers?search="+this.search+"&page="+this.page).subscribe((data:any)=>{
      console.log(JSON.stringify(data))
      this.user_accounts = data.users;
    });

  }
  clearSearch(){
    this.search=""
  }
  insertDriver(user:any){


    var data = [
      {
        "driver_id":user.ID,
        "driver_name":user.first_name + " " + user.last_name,
        "incentive_id":this.id,
      }
    ] 

    this.api.post("godrive","admin/incentives/"+this.id+"/drivers",data).subscribe((data:any)=>{
 
      this.getIncentiveDrivers()
    });
  }

  removeDriver(driver:any){

    this.api.delete("godrive","admin/incentives/driver/"+driver.ID).subscribe((data:any)=>{
 
      this.getIncentiveDrivers()
    });
  }

  pre(){
    if(this.page < 2){
      return
    }
    this.page--;
    this.getDrivers()
  }
  next(){
    this.page++;
    this.getDrivers()
  }

  showUser(id:any){

    localStorage.setItem('userTab','payments');
    this.router.navigateByUrl("/users/"+id)

  }
 

}
