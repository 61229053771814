<div class="header">
  <h3> All Asset types</h3>
  <mat-icon (click)="closeDialog()" class="action">clear</mat-icon>
</div>
<hr>

<!-- assets type table -->
<table
  mat-table
  [dataSource]="assets.asset_types"
  matSort
>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
    <td mat-cell *matCellDef="let asset_type">
      <p>{{ asset_type.name }}</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
    <td mat-cell *matCellDef="let asset_type">
      <p>{{ asset_type.description }}</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td mat-cell *matCellDef="let asset_type">
      <mat-icon (click)="updateAssetType(asset_type)">edit</mat-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="asset_type_columns"  style="display: none;"></tr>
  <tr
    mat-row
    class="action"
    *matRowDef="let asset_type; columns: asset_type_columns"
  ></tr>
</table>

<div class="save">
  <button mat-flat-button (click)="createAssetType()">New Asset type</button>
</div>
