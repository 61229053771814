import { ApiService }                               from 'src/app/services/api.service'
import { ActivatedRoute }                           from '@angular/router'
import { MessagesService }                          from 'src/app/services/messages.service'
import { Component, Inject, OnInit, EventEmitter  } from '@angular/core'
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit {
  Columns = ['default', 'address', 'CreatedAt', 'label', 'latitude', 'longitude', 'googleID', 'street', 'suburb', 'city', 'province', 'postal']

  id
  page = 1
  limit = 10
  addresses = []

  constructor(
    public route: ActivatedRoute,
    public api: ApiService,
    public message: MessagesService,
    public dialog : MatDialog,
  ) { }


  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getUserAddresses();
  }

  getUserAddresses(){
    this.api.getList('auth', `admin/users/${this.id}/addresses?page=${this.page}&per_page=${this.limit}`)
    .subscribe((addresses: any) => {
      console.log(addresses)
      this.addresses = addresses.addresses
    })
  }

  setLimit(limit){
    this.limit = limit
    this.getUserAddresses();
  }

  next(){
    if(this.addresses.length < this.limit){
      this.message.showSnack('no more addresses to show')
    } else {
    this.page++
    this.getUserAddresses();
    }
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.getUserAddresses();
    }
  }

// ============================
//  SHOW ADDRESS DETAILS
// ============================

    addressDialog(address){
      const dialog = this.dialog.open(addressInfoDialog, {
        width: '900px',
        height: 'calc(100vh - 100px)',
        data: {
          address: address,
        }
      })

    }


}

@Component({
  selector: 'delete-dialog',
  templateUrl: '/address-info-dialog.html',
  styleUrls: ['/address-info.component.scss']
})

export class addressInfoDialog {

  address


  // get section info after success
  onAdd       = new EventEmitter()


  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: { address }, 
              private api : ApiService, 
              private message: MessagesService,  
              public dialogRef: MatDialogRef<addressInfoDialog>) {

  }

  ngOnInit(): void {
    this.address = this.data.address
  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

}
