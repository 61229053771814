<div class="title">
    <h1>Fees</h1>
    <button mat-raised-button   class="button" (click)="add()">ADD FEE</button>
    </div>

<table>

    <tr>
        <th>
            ID
        </th>
        <th>
            FROM
        </th>
        <th>
            TO
        </th>
        <th>
            Amount
        </th>
        <th>
             
        </th>
        <th>
             
        </th>
    </tr>
    <tr  *ngFor="let fee of fees">
        <td>{{fee.ID}}</td> 
        <td>{{fee.from_distance}}</td>
        <td>{{fee.to_distance}}</td>
        <td>{{fee.amount}}</td>
      
        <td><mat-icon (click)="edit(fee)" class="material-icons-outlined">edit</mat-icon></td>
        <td><mat-icon (click)="delete(fee)" class="material-icons-outlined">delete</mat-icon></td>
    </tr>
</table>
<div class="from" [style.right]="showForm ? '0':'-400px'">
    <div class="title">

        <h4>Fee</h4>
        <mat-icon (click)="close()">close</mat-icon>
    </div>
    <label>From Distance</label>   
    <input [(ngModel)]="fromDistance" type="number" > 
    <label>To Distance</label>   
    <input  [(ngModel)]="toDistance"  type="number"> 
    <label> Amount</label>   
    <input  [(ngModel)]="amount"  type="number"> 

    <button mat-raised-button *ngIf="id==''" class="button" (click)="save()">CREATE</button>
    <button mat-raised-button  *ngIf="id!=''"   class="button" (click)="update()">UPDATE</button><br>
    
</div>