<div class="heading">
    <div></div>
    <h4>Edit Category Details</h4>
    <mat-icon (click)='closeDialog()' >clear</mat-icon>
</div>

<div class="edit-fields">
    <mat-form-field class='field' appearance='outline'>
        <mat-label>name</mat-label>
        <input type="text" matInput [(ngModel)]='name' placeholder="The greatest cheesecake" name='name'>
      </mat-form-field>

      <mat-form-field class='silos-field' appearance='outline' class='field'>
        <mat-label>silo</mat-label>
        <mat-select [(ngModel)]='silo_id' name="silo_id" class='select-panel'>
            <mat-option *ngFor="let silo of silos" [value]="silo.id" >
                {{silo.name}}
            </mat-option>
        </mat-select>
      </mat-form-field> 

      <mat-form-field class='field' appearance='outline'>
        <mat-label>linked key</mat-label>
        <input type="text" matInput [(ngModel)]='linked_key' placeholder="292092" name='title'>
      </mat-form-field>
</div>

<div class="save">
    <button mat-flat-button class='button cancel' (click)='closeDialog()'> Cancel</button>
    <button mat-flat-button class='button' (click)='updateCategory()'> Save</button>
</div>