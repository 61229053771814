<table mat-table [dataSource]="accounts.account_assets" matSort>
    <ng-container matColumnDef="asset_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
        <td mat-cell *matCellDef="let asset">
          <p *ngIf="asset.asset_name">{{ asset.asset_name}}</p>
          <p *ngIf="!asset.asset_name" class="empty">Assets not specified</p>
        </td>
    </ng-container>

    <ng-container matColumnDef="asset_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset ID</th>
        <td mat-cell *matCellDef="let asset">
          <p *ngIf="asset.asset_id">{{ asset.asset_id}}</p>
          <p *ngIf="!asset.asset_id" class="empty">asset_id not specified</p>
        </td>
    </ng-container>
    <ng-container matColumnDef="start_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start date</th>
        <td mat-cell *matCellDef="let asset">
          <p *ngIf="asset.start_date">{{ asset.start_date.substring(0,10) }}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="end_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>End date</th>
        <td mat-cell *matCellDef="let asset">
          <p *ngIf="asset.end_date">{{ asset.end_date.substring(0,10) }}</p>
        </td>
      </ng-container>
    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
        <td mat-cell *matCellDef="let asset">
          <p *ngIf="asset.amount">{{ asset.amount}}</p>
          <p *ngIf="!asset.amount" class="empty">Amount not specified</p>
        </td>
    </ng-container>

    <ng-container matColumnDef="frequency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Frequency</th>
        <td mat-cell *matCellDef="let asset">
          <p *ngIf="asset.frequency">{{ asset.frequency}}</p>
          <p *ngIf="!asset.frequency" class="empty">Frequency not specified</p>
        </td>
    </ng-container>
    <ng-container matColumnDef="no_of_payments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>No of payments</th>
        <td mat-cell *matCellDef="let asset">
          <p *ngIf="asset.no_of_payments">{{ asset.no_of_payments}}</p>
          <p *ngIf="!asset.no_of_payments" class="empty">No of payments not specified</p>
        </td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let asset">
          <p *ngIf="asset.status">{{ asset.status}}</p>
          <p *ngIf="!asset.status" class="empty">status not specified</p>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row class="action" *matRowDef="let asset; columns: Columns"></tr>
  </table>
