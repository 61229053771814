<!-- <p *ngIf="no_trading_hours === true">store-trading-hours works!</p> -->

<!-- {{ trading_hours | json }} -->

<div *ngIf='no_trading_hours === true' class='no-data'>
    <img src="assets/images/undraw_No_data.svg" alt="">
    <h3>No Trading Hours set</h3>
  </div>

<span *ngIf="edit_trading === false">
<table *ngIf="trading_hours.length > 0" mat-table [dataSource]='trading_hours' class="mat-elevation-z8">

    <!-- Day Name -->
    <ng-container matColumnDef="day_name" >
      <th mat-header-cell *matHeaderCellDef> Day Name </th>
      <td mat-cell *matCellDef="let trading_hour"> <p style="padding-left: 10px;">{{trading_hour.day_name}}</p> </td>
    </ng-container>
  
    <!-- Opens At -->
    <ng-container matColumnDef="opens_at">
      <th mat-header-cell *matHeaderCellDef>Opens At </th>
      <td mat-cell *matCellDef="let trading_hour"> {{trading_hour.opens_at}} </td>
    </ng-container>

    <!-- Closes At -->
    <ng-container matColumnDef="closes_at">
        <th mat-header-cell *matHeaderCellDef>Closes At </th>
        <td mat-cell *matCellDef="let trading_hour"> {{trading_hour.closes_at}} </td>
    </ng-container>

    <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let trading_hour"> <mat-icon matTooltip="Edit Hours" (click)="edit(trading_hour)">mode_edit</mat-icon> </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</span>

<span *ngIf="edit_trading === true">
  <div class="edit_box">
   <div class="edit_row">
    <mat-icon style="margin: 0px 0px 0px 150px;" matTooltip="Cancel" (click)="close()">close</mat-icon>
    </div>
    <div class="edit_row">
        <mat-form-field>
            <input [(ngModel)]="opens_at" ngModel matInput placeholder="Opening Time" value="{{opens_at}}">
        </mat-form-field>
        </div>
    <div class="edit_row">
    <mat-form-field>
        <input [(ngModel)]="closes_at" ngModel matInput placeholder="Closing Time" value="{{closes_at}}">
    </mat-form-field>
    </div>
    <div class="edit_row">
        <button class="button"  (click)="updateHours()" mat-flat-button>Update Hours</button>
    </div>
  </div>
</span>

<div class="button-bar">
    <button class="button" *ngIf="trading_hours.length === 0" (click)="createTradingHours()" mat-flat-button>Populate</button>
    <button class="button" *ngIf="trading_hours.length > 0" (click)="resetTradingHours()" mat-flat-button>Reset to Default Hours</button>
    <br>
    <br>
    <button *ngIf="trading_hours.length > 0" (click)="deleteTradingHours()" mat-flat-button>delete hours</button>
</div>

<!-- <button (click)="createTradingHours()" mat-button>Populate</button> -->
 