import { Component, OnInit } from '@angular/core';

import { PagerService } from '../../../services/pager.service';
@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit {


  constructor(
    public pager:PagerService
  ) {
//
  }

  ngOnInit(): void {
    // this.pager.list("catelog","suppliers");
  }


}
