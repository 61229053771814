<table mat-table [dataSource]="reason.reasons" matSort >

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
      <td mat-cell *matCellDef="let reason">
        <p>{{ reason.name }}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>description</th>
      <td mat-cell *matCellDef="let reason">
        <p>{{ reason.description }}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let reason">
        <mat-icon (click)="updateReason(reason)">edit</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let reason">
        <mat-icon (click)="deleteReason(reason)">delete_outline</mat-icon>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row class="action" *matRowDef="let reason; columns: Columns"></tr>

  </table>

  <div id="pager">
    <div class="page">
      <button
        id="{{ auth.expand ? 'create-expand' : 'create' }}"
        mat-flat-button
        class="button"
        routerLink="/create-reasons"
      >
        New
      </button>
    </div>
  </div>