import { Router }             from '@angular/router'
import { DatePipe, formatDate }           from '@angular/common';
import * as _moment           from 'moment'
import { ApiService }         from 'src/app/services/api.service'
import { MessagesService }    from 'src/app/services/messages.service'
import { Component, Inject, OnInit, EventEmitter }  from '@angular/core'
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'




@Component({
  selector: 'app-create-promotional',
  templateUrl: './create-promotional.component.html',
  styleUrls: ['./create-promotional.component.scss']
})
export class CreatePromotionalComponent implements OnInit {
  displayedColumns: string[] = ['image', 'name', 'size', 'price', 'add']


  silos
  siloValue
  promoproduct
  
  page            = 1
  limit           = 10
  search          = ''
  product         = false
  active          = false
  products        = []
  multiple_uses   = false
  first_order     = false

  constructor(public api: ApiService,
              public message: MessagesService,
              public router: Router,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getSilos()
  }

  getSilos(){
    this.api.getList('catelog', `silos`).subscribe((silos: any) => {
      this.silos = silos
    }, error => {
      this.message.showSnack(error.error)
    })
  }

  createPromoCode(promo){

    const format = 'yyyy-MM-dd'
    const locale = 'en-US';

    let sd = formatDate(promo.start_date, format, locale)
    let ed = formatDate(promo.end_date, format, locale)

    promo.silo.forEach(silo => {
     
      let promocode = {
        silo_id: silo,
        code: promo.code,
        description: promo.description,
        amount: promo.amount ,
        min_order_amount: promo.min_order_amount ,
        quantity: promo.quantity,
        active: this.active,
        starting_date: sd,
        ending_date: ed,
        use_multiple_times: this.multiple_uses,
        first_order: this.first_order
      }

      if(this.promoproduct){
        promocode['product_id'] = this.promoproduct.id
      }

      this.api.post("fulfilment","admin/promocodes", promocode).subscribe((promo: any) => {
        this.message.showSnack(`Successfully created promo code ${promo.data.code}`)
        this.router.navigate(['/promos'])
      }, error => {
        this.message.showSnack(error.error)
      })
    })

  }

  // ====================
  //  ADD PRODUCT DIALOG
  // ====================


    // open dialog for banner update
    addSectionItems(){
      const dialog = this.dialog.open(PromoProductDialog, {
        width: '1200px',
        height: '88vh',
      })
  
      dialog.afterClosed().subscribe((result: any) => {
        if(result){
        this.promoproduct = result.data
        }
      })
    }

    cancelProduct(){
      this.promoproduct = ''
      this.product = false
    }
}


// ============================
//    STORE REMOVE DIALOG
// ============================

@Component({
  selector: 'promo-product-add',
  templateUrl: '/promotional-product-add.html',
  styleUrls: ['../../../pocket-mall/mall-stores/store-add.component.scss']
})

export class PromoProductDialog {
  Columns: string[] = ['image', 'name', 'size', 'price', 'check']

  
  inputTouch  = false

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }


  item
  silos
  section
  searchData
  silo_id = 1

  promoproduct

  search = ''
  page = 1
  limit = 6
  // get section info after success
  onAdd       = new EventEmitter()
  

  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: { section, product }, 
              private api : ApiService, 
              private message: MessagesService,  
              public dialogRef: MatDialogRef<PromoProductDialog>) {

  }

  ngOnInit(): void {
    this.getSilos()
    this.getProducts()
  }

  getSilos(){
    this.api.getList('catelog', `silos`).subscribe((silos: any) => {
      let all = {
        id: 0,
        name: 'All',
      }

      silos.unshift(all)
      this.silos = silos
    }, error => {
      this.message.showSnack(error.error)
    })
  }

  chosenProduct(product){
    this.promoproduct = product
  }

  cancelProduct(){
    this.promoproduct = ''
  }


  closeDialog() {
    this.dialogRef.close({data:this.promoproduct})
    this.onAdd.emit()
  }

  getProducts(){
    this.api.getList('catelog', `products?page=${this.page}&per_page=${this.limit}&search=${this.search}&silo_id=${this.silo_id}`).subscribe((products: any) => {
      this.searchData = products
      }, error => {
      this.message.showSnack(error.error)
      })
  }

  next(){
    this.page++
    this.getProducts()
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.getProducts()
    }
  }

  start(){
    this.page = 1
    this.getProducts()
  }
}

