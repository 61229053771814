import { AuthService } from 'src/app/services/auth.service';
import { MessagesService } from './../../../../services/messages.service';
import { PusherService } from './../../../../services/pusher.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-user-messages',
  templateUrl: './user-messages.component.html',
  styleUrls: ['./user-messages.component.scss'],
})
export class UserMessagesComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @Input() picker: any;
  @Input() cart: any;
  @Input() picker_name: any;

  id;
  user = {
    first_name: '',
    last_name: '',
  };

  getChats = false;

  messages = [];
  margin;
  num = 56;

  lastMessageInChat = '';

  reply;
  rows = 1;

  cart_id;

  resource = ``;
  replyresource = `admin/messages`;
  isDisChemUser = false;

  chat = false;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    public pusherService: PusherService,
    public message: MessagesService,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    this.checkAdminRights();
    this.margin = `${this.num}px`;
  }

  checkAdminRights() {
    // if dischem role only
    if (
      localStorage.getItem('dis-chem-role') &&
      localStorage.getItem('dis-chem-role') == 'true'
    ) {
      this.id = this.picker;
      this.isDisChemUser = true;
      this.resource = `dischem/carts/${this.cart}/messages`;
      this.replyresource = `dischem/messages`;
      // this.showMessages();
      this.user.first_name = this.picker_name;

      // for all admin users
    } else {
      this.resource = `admin/carts/${this.cart}/messages`;

      setTimeout(() => {
        if (this.picker) {
          this.id = this.picker;
          this.cart_id = this.cart;
          this.user.first_name = this.picker_name;
        } else {
          this.id = this.route.snapshot.paramMap.get('id');
          this.getUserBasicInfo();
        }

        this.listToPusher();
      }, 800);
    }
  }

  textareaExpand(e) {
    let margin = `${this.num}px`;

    // console.log(e.length)

    if (e.length < 38) {
      this.rows = 1;
      this.num = 56;
      this.scrollToBottom();
    }

    if (e.length > 38) {
      this.rows = 5;
      this.num = 130;
      this.scrollToBottom();
    }

    if (e.length > 193) {
      this.rows = 8;
      this.num = 170;
      this.scrollToBottom();
    }

    this.margin = margin;
  }

  toggleChatState() {
    this.chat = !this.chat;
    this.scrollToBottom();
  }

  getUserBasicInfo() {
    this.api.get('auth', `admin/users`, this.id).subscribe((data: any) => {
      this.user.first_name = data.user.first_name;
      this.user.last_name = data.user.last_name;
    });
  }

  showMessages() {
    if (this.messages.length == 0) {
      this.getChats = true
      if (this.picker) {
        this.getPickerMessages();
      } else {
        this.getMessages();
      }
    }
  }

  getMessages() {
    this.api.getList('fulfilment', `admin/messages/${this.id}`).subscribe(
      (data: any) => {
        // console.log(data.data)
        let dates = [];
        let messages = [];

        data.data.forEach((message) => {
          const date = new Date(message.CreatedAt).toDateString();

          if (dates.includes(date)) {
            messages.forEach((object) => {
              if (object.date.includes(date)) {
                object.messages.push(message);
              }
            });
          } else {
            dates.push(date);
            let newDate = {
              date: date,
              messages: [message],
            };
            messages.push(newDate);
          }
        });

        // get last message in messages arr
        let length = data.data.length - 1;
        this.lastMessageInChat = data.data[length];

        this.messages = messages;

        setTimeout(() => {
          this.scrollToBottom();
        }, 300);
      },
      (error) => {
        this.chat = false;
      }
    );
  }

  // chat to picker about particular cart
  getPickerMessages() {
    // console.log(this.resource)
    this.api.getList('fulfilment', this.resource).subscribe(
      (data: any) => {
        // console.log(data)
        let dates = [];
        let messages = [];

        data.data.forEach((message) => {
          // console.log(message)
          const date = new Date(message.CreatedAt).toDateString();

          if (dates.includes(date)) {
            messages.forEach((object) => {
              if (object.date.includes(date)) {
                object.messages.push(message);
              }
            });
          } else {
            dates.push(date);
            let newDate = {
              date: date,
              messages: [message],
            };
            messages.push(newDate);
          }
        });

        // get last message in messages arr
        let length = data.data.length - 1;
        this.lastMessageInChat = data.data[length];

        this.messages = messages;

        setTimeout(() => {
          this.scrollToBottom();
        }, 300);
      },
      (error) => {
        this.chat = false;
      }
    );
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  refreshMessages(){
    if (this.picker) {
      this.getPickerMessages();
    } else {
      this.getMessages();
    }
  }

  adminReply() {
    this.rows = 1;
    this.margin = `${56}px`;

    let message = {
      uid: this.id,
      sender_name: `${this.user.first_name} ${this.user.last_name}`,
      content: this.reply,
      cart_id: this.cart,
    };

    // console.log(this.replyresource)
    // console.log(message)

    if (this.picker) {
      message.uid = this.picker;
    }

    this.api.post('fulfilment', this.replyresource, message).subscribe(
      (data: any) => {
        this.reply = '';
        this.listToPusher();
        this.refreshMessages();
      },
      (error) => {
        this.message.showSnack(error.statusText);
      }
    );
  }

  listToPusher() {
    this.pusherService.channel.bind('message-status-event', (data) => {
      this.refreshMessages();
    });
  }
}
