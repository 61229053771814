import { Injectable } from '@angular/core';
import { MiscService } from './../misc/misc.service';
import { ApiService } from 'src/app/services/api.service';
import { MessagesService } from './../messages.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Injectable({
  providedIn: 'root',
})
export class PostsService {
  search = '';
  posts;
  post;
  retries = 0;
  postproducts;
  postdata;

  constructor(
    public api: ApiService,
    public misc: MiscService,
    public message: MessagesService
  ) {}

  getPost(id) {
    let isDischemUser = localStorage.getItem('dis-chem-role');
    let apicall = '';

    if (isDischemUser) {
      apicall = `dischem/posts`;
    } else {
      apicall = `posts`;
    }

    this.api.getItem('catelog', `${apicall}`, id).subscribe((data: any) => {
      let img = data.image;
      if (img !== '') {
        let desktop = '_desktop';
        let position = img.lastIndexOf('.');
        data['desktopImage'] = [
          img.slice(0, position),
          desktop,
          img.slice(position),
        ].join('');

        this.post = data;
        this.getPostData();
      }
    });
  }

  async getPosts() {
    let isDischemUser = localStorage.getItem('dis-chem-role');
    let apicall = '';

    if (isDischemUser) {
      apicall = 'dischem/posts';
    } else {
      await this.misc.getSilos();
      apicall = `posts`;
    }

    localStorage.setItem('postSearch', this.search);
    // this.posts = [];
    let posts = [];
    this.api
      .getList('catelog', `${apicall}?per_page=${100}&search=${this.search}`)
      .subscribe(
        (data: any) => {
          for (var i = 0; i < data.length; i++) {
            if (data[i].silo_id == this.misc.silo.id) {
              posts.push(data[i]);
              this.posts = posts;
            }
          }
        },
        (error) => {
          this.message.showSnack(error.error);
          if (this.retries < 3 && error.status !== 401) {
            this.retries++;
            this.getPosts();
          }
        }
      );
  }

  clearSearch() {
    this.search = '';
    this.getPosts();
  }

  // ===============
  //    POSTITEMS
  // ===============

  getPostData() {
    let isDischemUser = localStorage.getItem('dis-chem-role');
    let apicall = '';

    if (isDischemUser) {
      apicall = `dischem/posts/${this.post.id}/products`;
    } else {
      apicall = `posts/${this.post.id}/postsproducts`;
    }

    this.api
      .getList('catelog', `${apicall}?page=${1}&per_page=${100}`)
      .subscribe((data: any) => {
        this.postdata = data;
      });
  }

  // ===================
  //   DRAG AND DROP
  // =================

  drop(event: CdkDragDrop<string[]>) {
    let isDischemUser = localStorage.getItem('dis-chem-role');
    let apicall = ``;

    moveItemInArray(this.posts, event.previousIndex, event.currentIndex);

    var list = [];
    this.posts.forEach((post) => {
      if (post.silo_id == this.misc.silo.id) {
        list.push(post.id);
      }
    });

    if (isDischemUser) {
      apicall = `dischem/sort`;
    } else {
      apicall = `sort`;
    }

    this.api
      .patch('catelog', `${apicall}`, {
        table: 'posts',
        ids: list,
      })
      .subscribe(
        (data) => {
          this.message.showSnack('Posts position updated');
          this.getPosts();
        },
        (error) => {
          console.log(error);
        }
      );
  }


  SortProducts(event: CdkDragDrop<string[]>) {
    let isDischemUser = localStorage.getItem('dis-chem-role');
    let apicall = ``;


    moveItemInArray(this.postdata, event.previousIndex, event.currentIndex);

    var list = [];
    this.postdata.forEach((product) => {
      list.push(product.post_product_id)
    });


    if (isDischemUser) {
      apicall = `dischem/sort`;
    } else {
      apicall = `sort`;
    }

    this.api
      .patch('catelog', `${apicall}`, {
        table: 'post_products',
        ids: list,
      })
      .subscribe(
        (data) => {
          this.message.showSnack('Product position updated');
          this.getPostData();
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
