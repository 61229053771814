import { AuthService } from 'src/app/services/auth.service';
import { PusherService } from 'src/app/services/pusher.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cart-tracking',
  templateUrl: './cart-tracking.component.html',
  styleUrls: ['./cart-tracking.component.scss']
})
export class CartTrackingComponent implements OnInit {

  id
  cart
  actions
  nucleusActions


  statuses            = []
  Actions             = ['action', 'item',  'name']
  pickedItems         = []
  productItems        = []
  progressValue       = 0
  unfulfilledItems    = []
  out_of_stock_items  = []
  alt_products        = []
  resource            = 'admin/carts'
  isDisChemUser       = false


  constructor(public api: ApiService,
              public route: ActivatedRoute,
              public pusher: PusherService,
              public auth: AuthService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')

    if(this.auth.dis_chem_role || localStorage.getItem('dis-chem-role') && localStorage.getItem('dis-chem-role') == 'true'){
      this.isDisChemUser = true
      this.resource = `dischem/carts`
    } else {
      this.resource = "admin/carts"
      
    }

    this.getCart()
    this.getCartInfo()
    this.getCartActions()
    this.listToPusher()

  }

  goToLink(url: string){
    window.open(`https://drive.quench.mobi/orders/${url}/fulfilment`, "_blank")
  }

  getCart(){

    this.api.get('fulfilment', this.resource, this.id).subscribe((cart: any) => {

      this.nucleusActions = cart.tracking
      
      this.cart = cart.cart

      // console.log(cart)

      cart.items.forEach(item => {
        if(item.picked == false && item.item_type == 'product' ){
          this.unfulfilledItems.push(item)
        } else if (item.picked == false && item.item_type == 'alternative_product'){
          this.alt_products.push(item)
        }else if (item.stock_out == true){
          this.out_of_stock_items.push(item)
        } else if (item.picked == true){
          this.pickedItems.push(item)
        }
      })
    })
  }

  // actions for cart
  getCartActions(){
    this.api.getList('fulfilment', `app/carts/${this.id}/actions`).subscribe((data:any) => {
      if(data.data.length !== 0){
      this.actions = data.data
      }
    })
  }

  // tracking
  getCartInfo(){
    this.api.get('fulfilment', this.resource, this.id).subscribe((data:any) => {
      let count = 0
      // console.log(data)
      data.statuses.forEach(status => {
        if(status.time){
          count++
          let value = 100/8*count
          this.progressValue = value
        }
      })
      this.statuses = data.statuses
    })
  }

  listToPusher(){
    this.pusher.channel.bind('cart-event-'+this.id, data => {

      // clear data and populate again
      this.pickedItems        = []
      this.unfulfilledItems   = []
      this.out_of_stock_items = []
      this.alt_products       = []
      
      this.getCart()
      this.getCartActions()
      this.getCartInfo()
    })
  }

  // ngOnDestroy(): void {
  //   this.pusher.channel.unsubscribe()
  // }




}
