import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

 
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MiscService } from 'src/app/services/misc/misc.service';

export interface Incentive {
  id: string;
  name: string;
  action: string;
  when: string;
  price: number;
  description: string;
  thumbRating: number;
  imageUrl: string;
}

@Component({
  selector: 'app-incentives',
  templateUrl: './incentives.component.html',
  styleUrls: ['./incentives.component.scss']
})
export class IncentivesComponent implements OnInit {

  incentives:any=[];
  driverActions=["order.delivered","order.pickup","driver.online" ];
  frequencies=["Daily","Weekly", "Driver Action"];
  whenOptions=[  "Before","After","On","Between"];
  
  types =[  "incentive","guarantee" ];

  incentive_type="incentive"
  when="before"
  action="order.delivered"
  showForm=false;
  id:string="";


  incentiveForm = new FormGroup({ 
    name: new FormControl('' , [Validators.required, Validators.minLength(5)]),
    silo_id: new FormControl(''),
    driver_action: new FormControl(''  ),
    type: new FormControl('', ), 
    when: new FormControl('', ), 
    start_at: new FormControl(''), 
    end_at: new FormControl('' ), 
    amount: new FormControl('' ),
    rule_name: new FormControl('' ),
    active_from: new FormControl('' ),
    active_until: new FormControl('' ),
    status: new FormControl('' ), 
    all_drivers: new FormControl(false ),
    silo_specific: new FormControl('' ),
  });

  constructor(
    private api:ApiService,
    public misc: MiscService
  ) { }

  ngOnInit(): void {
    this.misc.getSilos()
    this.listIncentives()
  }

  listIncentives(){
 
    this.api.getList("godrive","admin/incentives?page="+this.page).subscribe((response:any)=>{
     
      this.incentives = response.incentives
    })
  }

  incentive;
  silo_specific = false;

  onToggle(e){
    if (e.checked == false){
      this.incentiveForm.value.silo_id = ""
    }
  }

  setData(){

    if(this.incentiveForm.value.type == "guarantee"){
      return {  
        name: this.incentiveForm.value.name,
        amount: this.incentiveForm.value.amount,
        type:this.incentiveForm.value.type,
        active_from:this.incentiveForm.value.active_from,
        active_until:this.incentiveForm.value.active_until,
      }
    }

    return {  
      name: this.incentiveForm.value.name,
      type:this.incentiveForm.value.type,
      driver_action:this.incentiveForm.value.driver_action,
      when:this.incentiveForm.value.when,
      start_at:  this.incentiveForm.value.start_at, 
      end_at:  this.incentiveForm.value.end_at, 
      amount: this.incentiveForm.value.amount,
      active_from:this.incentiveForm.value.active_from,
      active_until:this.incentiveForm.value.active_until,
      all_drivers: this.incentiveForm.value.all_drivers,
      silo_id: this.incentiveForm.value.silo_id
    }

  }

  save(){    

   this.incentive =  this.setData()

   if (this.incentive.silo_id > 0){
    this.incentive.silo_id = JSON.stringify(this.incentive.silo_id)
   }

    if (this.id==""){
      this.api.post("godrive","admin/incentives",  this.incentive ).subscribe((response:any)=>{
        this.showForm = false
        this.listIncentives()
        this.close()
      })
    } else {

      this.api.patch("godrive","admin/incentives/"+this.id,  this.incentive ).subscribe((response:any)=>{
        this.showForm = false;
        this.id="";
        this.listIncentives()
        this.close()
      })
    }
  }

  delete(incentive:any){
    this.api.delete("godrive","admin/incentives/"+incentive.ID).subscribe((response:any)=>{
 
      this.listIncentives()
    })
  }

  edit(incentive:any){
  
    this.selectWhen(incentive.when)
    this.selectType(incentive.type)
  
    this.incentiveForm.patchValue({
      name: incentive.name  ,
      type: incentive.type,
      driver_action:incentive.driver_action,
      when:incentive.when,  
      amount:incentive.amount, 
      start_at: incentive.start_at,
      end_at: incentive.end_at,
      active_from:incentive.active_from,
      active_until:incentive.active_until, 
    })
    
    this.when = incentive.when
    this.action = incentive.driver_action
    this.id = incentive.ID
    this.showForm = true 

  } 
  limit=10;
  page=1
  showEndTime=false;

  selectWhen(v){
    this.when =v
    if(this.when=="Between"){
      this.showEndTime =true
    } else {
      this.showEndTime =false
    }
  }

  selectType(v){
    this.incentive_type =v 
  }

  selectAction(v){
    this.action =v 
  }

  add(){
    //set id = "" for post action
    this.id="";
    this.showForm = !this.showForm
  }

  close(){
    this.showForm = false

    this.incentiveForm.setValue({
      name: "",
      silo_id: "",
      driver_action: "",
      type: "", 
      when: "", 
      start_at: "", 
      end_at: "", 
      amount: "",
      rule_name: "",
      active_from: "",
      active_until: "",
      status: "", 
      all_drivers: false,
      silo_specific: "",
    })

    this.incentive_type = 'incentive'

    console.log(this.incentive_type)
  }

  setLimit(i){

  }
  previous(){

    this.page--
    this.listIncentives()
  }
  next(){
    this.page++
    this.listIncentives()

  }
  start(){
    if (this.page > 1){

      this.page=1
      this.listIncentives()
    }

  }

}
