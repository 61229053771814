import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { AgmMap, MapsAPILoader } from '@agm/core';
import { MessagesService } from 'src/app/services/messages.service';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-new-zone',
  templateUrl: './new-zone.component.html',
  styleUrls: ['./new-zone.component.scss']
})

export class NewZoneComponent implements OnInit {
  @ViewChild(AgmMap) AGMmap: any
  @ViewChild('searchLocation') searchElementRef: ElementRef;
  zone:any 
  selectedCity:any;
  citySearch = new FormControl();

  constructor(
    public zonesService: ZonesService,
    public router: Router,
    public messageService: MessagesService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) { 

    this.mapsAPILoader.load().then(() => {
      this.zonesService.lat = -29.630621
      this.zonesService.lng = 24.088321
      this.zonesService.zones = []
      this.zonesService.mapsLoaded = true;
      this.zonesService.loading = false
    });

    this.citySearch.valueChanges.subscribe(newValue => {
      let searchCity:string = newValue
      if(searchCity != ''){
       if(this.filterCities(searchCity) != []){
        this.zonesService.filteredCities = this.filterCities(searchCity)
        if(this.zonesService.filteredCities.length <= 0){
          this.messageService.showSnack('No cities found, please try again')
         }
       }   
      }
     });
  }

  ngOnInit(): void {
  }

  filterCities(search: string) {
 return this.zonesService.cities.filter(city => city.city.toLowerCase().includes(search.toLowerCase()))
  }

  lookupByCity(selectedCity){
    let matchingCity = false;
    this.zonesService.cities.forEach((city:any) => {
        if(city.city == selectedCity && !matchingCity){
          matchingCity = true
          selectedCity = city
          this.zonesService.city = city.city
        }
    });
    this.zonesService.zoom = 9
    this.zonesService.lat = Number(selectedCity.lat)
    this.zonesService.lng = Number(selectedCity.lng)
  }
  

  ngOnDestroy(): void {
    this.zonesService.toggleAdd = false
    this.zonesService.edit = false;
    this.zonesService.addingZone = false;
  }

  editToggle(){
    this.zonesService.edit = !this.zonesService.edit
    this.zonesService.addingZone = false;
    this.removePolygons()
    this.getZone()
  }

  selectRegion(){
    if(this.zonesService.selectedPlace.name == "All"){
      this.zonesService.zoom = 5
    } else {
      this.zonesService.zoom = 9
    }
    this.zonesService.lat = this.zonesService.selectedPlace.lat
    this.zonesService.lng = this.zonesService.selectedPlace.lng
  }

  addPolygon(){
    this.zonesService.addingZone = true
    this.zonesService.newPolygon = [
      {
        "lat": this.zonesService.lat,
        "lng": this.zonesService.lng
      },
      {
        "lat": this.zonesService.lat - 0.1,
        "lng": this.zonesService.lng - 0.1
      },
      {
        "lat": this.zonesService.lat - 0.1,
        "lng": this.zonesService.lng + 0.1
      },
      {
        "lat": this.zonesService.lat,
        "lng": this.zonesService.lng
      }
    ]

    this.zonesService.polygon = new google.maps.Polygon({
      paths: this?.zonesService?.newPolygon,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      editable: true,
      draggable: true,
    });

    this.zonesService.polygon.getPaths().forEach((path:any) => {

      google.maps.event.addListener(path, 'insert_at', function () {

        let geopoints = []

        const len = path.getLength();
        for (let i = 0; i < len; i++) {
          geopoints.push(
            path.getAt(i).toJSON()
          )
        }
        // this.polygon.paths = geopoints;
        // console.log(geopoints)
        localStorage.setItem('geopoints', JSON.stringify(geopoints))
      })

      google.maps.event.addListener(path, 'set_at', function () {

        let geopoints = []

        const len = path.getLength();
        for (let i = 0; i < len; i++) {
          geopoints.push(
            path.getAt(i).toJSON()
          )
        }

        localStorage.setItem('geopoints', JSON.stringify(geopoints))

      })

      google.maps.event.addListener(path,'set_at', (event:any) => {
          let geopoints = []

        const len = path.getLength();
        for (let i = 0; i < len; i++) {
          geopoints.push(
            path.getAt(i).toJSON()
          )
        }

        localStorage.setItem('geopoints', JSON.stringify(geopoints))
      })

      google.maps.event.addListener(path,'insert_at', (event:any) => {
        let geopoints = []

        const len = path.getLength();
        for (let i = 0; i < len; i++) {
          geopoints.push(
            path.getAt(i).toJSON()
          )
        }

        localStorage.setItem('geopoints', JSON.stringify(geopoints))
      })
    
    })

    this.zonesService.polygon?.setMap(this.AGMmap);
  }

  navSingleZone(zone:any){
    let zoneId = zone.id
    localStorage.setItem('activeZone', JSON.stringify(zone))
    setTimeout(() => {
      this.router.navigate([`zone/${zoneId}`])
    }, 500);
  }

  savePolygon(){
    this.zonesService.lat = this.zonesService.newPolygon[0].lat;
    this.zonesService.lng = this.zonesService.newPolygon[0].lng;

      this.zonesService.newPolygon = JSON.parse(localStorage.getItem('geopoints'))

      for(var newGeopoint of this.zonesService.newPolygon){
        // console.log(newGeopoint)
        newGeopoint["latitude"] =  Number(JSON.stringify(newGeopoint.lat).slice(0,10))
        newGeopoint["longitude"] = Number(JSON.stringify(newGeopoint.lng).slice(0,10))
        delete newGeopoint.lat
        delete newGeopoint.lng
      }

      if(this.zonesService.newPolygon[this.zonesService.newPolygon.length-1].latitude != this.zonesService.newPolygon[0].latitude){
        this.zonesService.newPolygon = this.zonesService.newPolygon.concat(this.zonesService.newPolygon[0])
      }
  
      if(this.zonesService.selectedArea != "" && this.zonesService.selectedArea.length > 3){
        this.zonesService.saveZone(this.zonesService.newPolygon, this.zonesService.city, this.zonesService.selectedArea)
        .then((res:any) => {
            if(res?.zone){
              let zone = res?.zone
              zone["id"] = zone.ID
              delete zone.ID
              zone["geopoints"] = this.zonesService.newPolygon
              this.navSingleZone(zone);
              this.messageService.showSnack('Zone added succesfully.')
            }
        })
        .catch((err:any) => {
          console.log(err)
          if(err.status == 200){
            // this.editToggle();
            this.zonesService.polygon.setMap(null)
            this.zonesService.loading = true;
            setTimeout(() => {
              localStorage.removeItem('zones')
                this.getZone();
            }, 2000);
            this.messageService.showSnack(err.statusText)
          } else if (err.status == 500){
            this.messageService.showSnack(err.statusText)
          }
        })
      } else {
        this.messageService.showSnack('Area cannot be blank.')
      }

  }

  drawPolygons(){
    this.zonesService?.zones?.forEach((zone:any, i) => {
      if(this.zonesService.zones[i] != undefined){
        
        this.zonesService.zones[i].geopoints.forEach((geopoint:any) => {
          geopoint['lat'] = geopoint.latitude
          geopoint['lng'] = geopoint.longitude
        });
  
        this.zonesService.polygon = new google.maps.Polygon({
          paths: this.zonesService.zones[i].geopoints,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
        })
    
        this.zonesService.polygon.strokeColor = '#000'
        this.zonesService.polygon.fillColor = '#000'
      
        this.zonesService.polygons.push(this.zonesService.polygon)
        this.zonesService.polygon?.setMap(this.AGMmap)
       }
    })
  }

  setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.zonesService.lat = position.coords.latitude;
        this.zonesService.lng = position.coords.longitude;
      });
    }
  }

  getZone(){

    if(localStorage.getItem('activeZone') && localStorage.getItem('activeZone') != ""){
      this.zone = JSON.parse(localStorage.getItem('activeZone'))
    }

    this.zonesService.zoom = 10
    this.zonesService.zones = []
    this.zonesService.zones.push(this.zone)

    // console.log(this.zonesService.zones)
    this.zonesService.lat = this.zone.geopoints[0].lat
    this.zonesService.lng = this.zone.geopoints[0].lng

    this.zonesService.loading = false;

    this.drawPolygons()

    if(this.zonesService.edit){
      this.editPolygons()
      } else {
        this.drawPolygons()
     }

  }

  onMapReady(map) {

    this.AGMmap = map

    setTimeout(() => {
      this.drawPolygons()
    }, 800)

  }

  editPolygons(){

    let zones = []
    
    this.zonesService?.zones?.forEach(zone => {

      zone?.geopoints?.forEach((geopoint,i) => {

        let zone = this.zonesService?.zones[i]

        if(this.zonesService.zones[i] != undefined){

          this.zonesService.polygon = new google.maps.Polygon({
            paths: this.zonesService.zones[i].geopoints,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            editable: true,
            draggable: true,
          })
  
          this.zonesService.polygons.push(this.zonesService.polygon)
          this.zonesService.polygon?.setMap(this.AGMmap)
      
            this.zonesService.polygon.getPaths().forEach(path => {
              google.maps.event.addListener(path, 'insert_at', function () {
                    
                let geopoints = []
                const len = path.getLength();
                for (let i = 0; i < len; i++) {
                  geopoints.push(
                    path.getAt(i).toJSON()
                  )
                }
      
                var isZone = zones.map(z => z.id).indexOf(zone.id)
      
                if(isZone === -1){
                  zones.push(zone)
                  zones[zones.length -1].geopoints = geopoints
                } else {
                  zones[isZone].geopoints = geopoints
                }
      
                localStorage.setItem('zones', JSON.stringify(zones))
      
              })
            })
            
        }
      }
      );      
    });
  }

  deleteZone(zone:any){
    this.zonesService.deleteZone(zone.id)
    .then((res:any) => {
      console.log(res)
      this.messageService.showSnack('Zone deleted.')
      this.router.navigate(['zones-list'])
    })
    .catch((err:any) => {
      console.log(err)
      this.messageService.showSnack(`There has been an error. status code: ${err.status_code}`)
    })
  }

  updatePolygon(){
    let zones = JSON.parse(localStorage.getItem('zones'))

    zones.forEach(z => {
 
      z.geopoints.forEach((geopoint:any) => {
        geopoint.lat =  Number(JSON.stringify(geopoint.lat).slice(0,10))
        geopoint.lng = Number(JSON.stringify(geopoint.lng).slice(0,10))
        geopoint["latitude"] =  Number(JSON.stringify(geopoint.lat).slice(0,10))
        geopoint["longitude"] = Number(JSON.stringify(geopoint.lng).slice(0,10))
      });

      let zone = {
        id: z.id,
        geopoints: z.geopoints
      }

      this.zone.geopoints = z.geopoints
      localStorage.setItem('activeZone', JSON.stringify(this.zone))

      this.zonesService.updateZone(zone)
      .then((res:any) => {
        if(zones.length < 2){
          this.messageService.showSnack('Succesfully updated zone.')
        } 
        else {
         this.messageService.showSnack('Succesfully updated zones.')
        }
       setTimeout(() => {
         localStorage.removeItem('zones')
         this.redrawPolygons()
       }, 2000);
      })
      .catch((err:any) => {
        console.log(err)
        this.messageService.showSnack('Geofence not updated, points are incorrect. Please try again.')
      })
    })
   }

   redrawPolygons(){
    this.zonesService.polygons.forEach((poly:any) => {
      poly.setMap(null)
    })
    this.zonesService.edit = false
    this.getZone()
  }

  removePolygons(){
    this.zonesService.polygons.forEach((poly:any) => {
      poly.setMap(null)
    })
  }

}
