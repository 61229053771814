import { MessagesService } from 'src/app/services/messages.service';
import { ApiService } from 'src/app/services/api.service';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { MiscService } from './../../../services/misc/misc.service';
import { DriversService } from './../../../services/drivers/drivers.service';
import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-listdrivers',
  templateUrl: './listdrivers.component.html',
  styleUrls: ['./listdrivers.component.scss'],
})
export class ListdriversComponent implements OnInit {
  // Columns = [
  //   'name',
  //   'email',
  //   'phone',
  //   // 'emp_code',
  //   'bank',
  //   'branch_code',
  //   'account_name',
  //   'account_number',
  //   'mobile_pay',
  //   'mobile_verified',
  //   'fleet_driver',
  //   'more',
  // ];

  Columns = ['name', 'email', 'phone', 'last active', 'verified']


  constructor(
    public driver: DriversService,
    public misc: MiscService,
    public auth: AuthService,
    public dialog: MatDialog,
    public message: MessagesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.driver.getDrivers();
  }

  updateMobileVerificationState(driver) {
    // pass all fields in order that booleans do not default to false
    let s = {
      ID: driver.ID,
      mobile_verified: driver.mobile_verified,
      pay_to_mobile: driver.pay_to_mobile,
      is_fleet_driver: driver.is_fleet_driver,
    };

    let updated = this.driver.updateDriver(s);
    updated.then((res) => {
      this.message.showSnack('Succesfully updated driver details');
    });
  }

  updateFleetDriverState(driver) {
    // pass all fields in order that booleans do not default to false
    let s = {
      ID: driver.ID,
      mobile_verified: driver.mobile_verified,
      pay_to_mobile: driver.pay_to_mobile,
      is_fleet_driver: driver.is_fleet_driver,
    };

    let updated = this.driver.updateDriver(s);
    updated.then((res) => {
      this.message.showSnack('Succesfully updated driver details');
    });
  }

  // =======================
  //  DRIVER DELETE  DIALOG
  // =======================

  // open dialog for banner update
  removeDriver(driver) {
    const dialog = this.dialog.open(RemoveDriverDialog, {
      width: '550px',
      height: '200px',
      data: {
        driver: driver,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.driver.getDrivers();
    });
  }

  // =======================
  //      NAV TO USER 
  // =======================
  navigateRoute(user){
    this.router.navigate([`/users/${user.ID}`])
    localStorage.setItem('customer_navigation', 'drivers')
  }
  // =======================
  //  DRIVER EDIT  DIALOG
  // =======================

  // open dialog for banner update
  editDriver(driver) {
    const dialog = this.dialog.open(EditDriverDialog, {
      width: '800px',
      height: '600px',
      data: {
        driver: driver,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.driver.getDrivers();
    });
  }
}

@Component({
  selector: 'delete-dialog',
  templateUrl: '../../../components/dialogs/delete-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class RemoveDriverDialog {
  inputTouch = false;
  heading = `driver`;
  body = `Are you sure you want to remove this driver?`;

  // get section info after success
  onAdd = new EventEmitter();

  onFocus() {
    this.inputTouch = true;
  }

  onBlur() {
    this.inputTouch = false;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { driver },
    private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<RemoveDriverDialog>,
    public driver: DriversService
  ) {}

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  removeItem() {
    let deleted = this.driver.deleteDriver(this.data.driver);
    // console.log(deleted)
    // this.closeDialog();
  }
}

@Component({
  selector: 'update-driver-dialog',
  templateUrl: './update-driver-dialog.html',
  styleUrls: ['../../../components/dialogs/update-dialog.component.scss'],
})
export class EditDriverDialog {
  // get section info after success
  onAdd = new EventEmitter();

  d;
  mobile_payment;

  mobile_pay_options = ['mobile', 'bank'];
  vehicle_types = [
    {
      icon: 'moped',
      name: 'scooter',
    },
    {
      icon: 'two_wheeler',
      name: 'motorbike',
    },
    {
      icon: 'local_shipping',
      name: 'tuk-tuk',
    },
    {
      icon: 'directions_car',
      name: 'car',
    },
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { driver },
    private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<EditDriverDialog>,
    public driver: DriversService
  ) {}

  ngOnInit(): void {
    this.d = Object.assign({}, this.data.driver);

    this.mobile_payment = this.data.driver.pay_to_mobile;

    if (this.data.driver.pay_to_mobile) {
      this.d.pay_to_mobile = 'mobile';
    } else {
      this.d.pay_to_mobile = 'bank';
    }
  }

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  updatePaymentMethod(method) {
    if (method == 'bank') {
      this.mobile_payment = false;
    } else if (method == 'mobile') {
      this.mobile_payment = true;
    }
  }

  updateDriver() {
    if (this.d.pay_to_mobile == 'mobile') {
      this.d.pay_to_mobile = true;
    } else {
      this.d.pay_to_mobile = false;
    }

    let updated = this.driver.updateDriver(this.d);
    updated.then((res) => {
      this.message.showSnack('Succesfully updated driver details');
      this.closeDialog();
    });
  }
}
