<div id='geofences'>
    <span id='explain'>
        <p>To edit or delete a geofence click the <span><img src='../../../../assets/zoning images/open-hands.png' alt=""></span>icon on the map in order to drag the points</p>
        <p>To create a new geofence click the <span><img src='../../../../assets/zoning images/blob-icon.png' alt=""></span>icon on the map and draw a new geofence</p>
    </span>
    <!-- <span>
        <button mat-raised-button class='button' (click)='submitGeofence()'>Create new Geofence</button>
        <button mat-raised-button class='button' (click)='updateGeofence()'>Update Geofence</button>
        <button mat-raised-button class='button' (click)='deleteGeofence()'>Delete Geofence</button>
    </span> -->
</div>

<agm-map [latitude]="latitude" [longitude]="longitude" [zoom]=zoom   (mapReady)="onMapReady($event)" >
    <agm-marker [latitude]="latitude" [longitude]="longitude" ></agm-marker>
</agm-map>

<div id="pager" >
    <span [style.left]="auth.expand ? '220px' : '70px' ">
        <button mat-flat-button class='button' (click)='submitGeofence()'>Create new Geofence</button>
        <button mat-flat-button class='button' (click)='updateGeofence()'>Update Geofence</button>
        <button mat-flat-button class='button' (click)='deleteGeofence()'>Delete Geofence</button>
    </span>
</div>