 <div class="create-card"  >
  
    <div class="header">
      <h3>update Promotional Code</h3>
      <mat-icon (click)='closeDialog()'>clear</mat-icon>
    </div>
  
    <form #promo = 'ngForm' (ngSubmit)='updatePromoCode(promo.value)'>
              
        <mat-form-field class='field' appearance='outline'>
            <mat-label>PromoCode</mat-label>
            <input type="text" matInput [(ngModel)]="code" placeholder="Festive100" name='code' required>
        </mat-form-field>
  
        <mat-form-field class='field' appearance='outline'>
            <mat-label>Description</mat-label>
            <textarea type="text" matInput [(ngModel)]="desc" placeholder="Get R100 off today only" name='description' required></textarea>
        </mat-form-field>
  
        <div class="form flex-items">
          <mat-form-field  appearance='outline'>
            <mat-label>amount</mat-label>
            <input type="number" matInput [(ngModel)]="amount" placeholder="100" name='amount' required >
          </mat-form-field>
          <mat-form-field  appearance='outline'>
            <mat-label>quantity</mat-label>
            <input type="number" matInput [(ngModel)]="qty" placeholder="12345678" name='quantity' required>
          </mat-form-field>
          <mat-form-field  appearance='outline'>
            <mat-label>min amount</mat-label>
            <input type="number" matInput [(ngModel)]="min_am" placeholder="900g" name='min_order_amount' maxlength="15" >
          </mat-form-field>
        </div> 
  
        <mat-form-field appearance="outline" class='field'>
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate name='start_date' placeholder="Start date" [(ngModel)]="start">
            <input matEndDate name='end_date' placeholder="End date" [(ngModel)]="end">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field> 
  
        <!-- <div class='promoproduct' *ngIf='promoproduct'>
          <h5>You are adding the following product to this promocode</h5>
          <img src={{promoproduct.image}} onerror="this.src='assets/placeholders/placeholder-img.png' ">
          <p> Add {{promoproduct.name}} to promocode?</p>
          <button mat-flat-button (click)='cancelProduct()' >cancel</button>
      </div> -->
  
          <div class="btn">
            <button mat-flat-button class='button' type='submit' >save</button>      
          </div>
  
    </form>
  </div>