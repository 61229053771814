import { Component, Inject, OnInit, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { ContractsService } from 'src/app/services/contracts/contracts.service';

@Component({
  selector: 'app-list-contracts',
  templateUrl: './list-contracts.component.html',
  styleUrls: ['./list-contracts.component.scss']
})
export class ListContractsComponent implements OnInit {
  Columns = [
    'name',
    'duration',
    'amount',
    'frequency',
    'no_of_payments',
    // 'status',
    'user_id',
    'asset_id',
    'edit'
  ]

  constructor(public contracts: ContractsService, public auth: AuthService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.contracts.getContracts()
  }

  setLimit(limit){
    this.contracts.limit = limit
    this.contracts.getContracts()
  }
  next(){
    this.contracts.page++
    this.contracts.getContracts()
  }
  previous(){
    if(this.contracts.page > 1){
      this.contracts.page--
      this.contracts.getContracts()
    }
  }

  updateContract(contract) {
    const dialog = this.dialog.open(UpdateContractDialog, {
      width: '850px',
      height: '600px',
      data: {
        contract: contract,
      },
    });

    dialog.afterClosed().subscribe(() => {
      setTimeout(() => {
        this.contracts.getContracts();
      }, 1000);
    });
  }
}

// ============================
//    ASSET UPDATE DIALOG
// ============================

@Component({
  selector: 'update-contracts-dialog',
  templateUrl: 'update-contracts-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class UpdateContractDialog {
  name;
  start_date;
  end_date;
  frequency;
  no_of_payments;
  amount
  user_id

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { contract },
    private contracts: ContractsService,
    public dialogRef: MatDialogRef<UpdateContractDialog>
  ) {}

  ngOnInit(): void {
    this.name = this.data.contract.name;
    this.start_date = this.data.contract.start_date;
    this.end_date = this.data.contract.end_date;
    this.frequency = this.data.contract.frequency;
    this.no_of_payments = this.data.contract.no_of_payments;
    this.amount = this.data.contract.amount;
    this.user_id = this.data.contract.user_id;
  }

  // get section info after success
  onAdd = new EventEmitter();

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  updateContract(contract) {
    this.contracts.updateContract(this.data.contract.ID, contract);
    this.closeDialog();
  }
}
