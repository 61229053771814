<div class="header">
    <h3>Create New Payment Method</h3>
    <mat-icon (click)="closeDialog()" class="action">clear</mat-icon>
  </div>
  <hr />
  
  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="firstForm">
      <form [formGroup]="firstForm">
        <mat-form-field appearance="outline" class="field">
          <mat-label>Payment method name</mat-label>
          <input
            type="text"
            matInput
            ngModel
            placeholder="mobile"
            name="name"
            required
            formControlName="name"
          />
        </mat-form-field>
  
        <mat-form-field appearance="outline" class="field">
          <mat-label>Payment method description</mat-label>
          <input
            type="text"
            matInput
            ngModel
            placeholder="give some more details here"
            name="description"
            formControlName="description"
          />
        </mat-form-field>
  
        <div class="save">
          <button
            mat-flat-button
            class="button"
            matStepperNext
            (click)="submitPaymentMethod()"
          >
            submit
          </button>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
  