<div class="back action" routerLink="/products">
    <mat-icon>arrow_back</mat-icon>
    <p>products</p>
    <p class='product-name' *ngIf='product'> {{product.name}}</p>
  </div>


<div class="tabs">
    <div (click)='selectTab(i)' *ngFor="let tab of tabs; let i = index" [class]="selectedTab == tab.name ? 'selectedTab' : '' ">
      <div id='tab'>
        <p id='tab-name'>{{tab.name}}</p>
      </div>
    </div>
  </div>



  <app-product *ngIf="selectedTab == 'product'"></app-product>
  <app-product-categories *ngIf="selectedTab == 'categories'"></app-product-categories>
  <app-product-stores *ngIf="selectedTab == 'stores'"></app-product-stores>
