<table class="table">
    <thead>
      <tr>
        <th class='header' id='sort'>Sort</th>
        <th class='header' id='icon'>Icon</th>
        <th class='header' >Name</th>
        <th class='header' id='add' ></th>
      </tr>
    </thead>
    <tbody cdkDropList (cdkDropListDropped)="drop($event)">
      <tr  class='action' *ngFor="let category of subcategories" cdkDrag cdkDragLockAxis="y">
        <th class='sort'><p><mat-icon>sort</mat-icon></p></th>
        <th routerLink='/categories/{{category.id}}' class='icon'><img class='img' src={{category.image}} alt=""></th>
        <th routerLink='/categories/{{category.id}}'><p>{{ category.name }}</p></th>
        <th class='del'><mat-icon (click)='removeItem(category)'>delete_outline</mat-icon></th>
      </tr>
    </tbody>
  </table>

  <div id="pager" >
    <div class="page"> 

     <button id="{{auth.expand ? 'create-expand': 'create'}}" mat-flat-button class='button' (click)="addItem()" >add subcategory</button>

    </div>
  </div>
