import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MiscService } from '../misc/misc.service';

@Injectable({
  providedIn: 'root',
})
export class ReasonsService {
  reasons;

  constructor(public misc: MiscService, public router: Router) {}

  async getReasons() {
    await this.misc.apiCall(`payments`, `admin/reasons`).then((res: any) => {
      // console.log(res)
      this.reasons = res['reasons'];
    });
  }

  async createReason(obj){
    await this.misc.apiPost(`payments`, 'admin/reasons', obj).then((res: any) => {
      return 
    })
  }

  updateReason(id, obj){
    this.misc.apiPatch('payments', `admin/reasons/${id}`, obj).then((res: any) => {
      return
    })
  }

  deleteReason(id){
    this.misc.apiDelete('payments', `admin/reasons/${id}`).then((res: any) => {
      return
    })
  }
}
