<div class="search">
  <mat-icon (click)='getUserTransactions()' matTooltip='search' class='search-icon' [style.left]="inputTouch ? '-20px' : '10px' ">search</mat-icon>
  <mat-icon *ngIf='search' (click)='clearSearch()'  matTooltip='clear' class='clear-icon'>clear</mat-icon>
  <input matInput (keydown.enter)='getUserTransactions()' [(ngModel)]="search" placeholder="Search"  #input (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
</div>

<!-- <p class='tag'>*hover over amount to see the amount type </p> -->

<div class="transactions">
  <div class="transaction" *ngFor='let trans of sortedTransactions'>
    <div class="date">
      <p class='date'  >{{trans.date}}</p>
    </div>
    <div class="transaction-info" *ngFor='let transaction of trans.transactions'>
      <!-- mat icon according to transaction type -->
      <mat-icon class='wallet' *ngIf="transaction.type == 'Wallet' ">account_balance_wallet</mat-icon>
      <mat-icon class='card' *ngIf="transaction.type == 'Card' ">credit_card</mat-icon>
      <mat-icon class='payment' *ngIf="transaction.type == 'Payment' ">receipt</mat-icon>
      <mat-icon class='refund' *ngIf="transaction.type == 'Refund' ">undo</mat-icon>

      <p class='trans-type'>{{transaction.type}}
        <span class='time'>{{transaction.CreatedAt | date: 'HH:mm'}}</span>
      </p>
      <p class='trans-amount' matTooltip='credit amount: {{transaction.credit_amount}}' *ngIf='transaction.credit_amount'>R  {{transaction.amount | number:"1.2-2" }}</p>
      <p class='trans-amount' matTooltip='card amount: {{transaction.card_amount}}' *ngIf='transaction.card_amount'>R  {{transaction.amount | number:"1.2-2" }}</p>
      <p class='trans-amount' *ngIf="!transaction.card_amount && !transaction.credit_amount && transaction.amount" [ngClass]="transaction.amount < 0 ? 'deducted' : 'added' ">R  {{transaction.amount | number:"1.2-2" }}</p>
      <p class='trans-amount none' *ngIf="!transaction.amount" >R  no amount </p>

      <p class="trans-note" *ngIf="transaction.note" >{{transaction.note}}</p>
      <p class='trans-note none' *ngIf='!transaction.note'>no note provided</p>

      <p class="trans-note" *ngIf='transaction.reference'>{{transaction.reference}}</p>
      <p class="trans-note action" id='refund' *ngIf='!transaction.reference && transaction.peach_id' (click)='openRefundDialog(transaction)'>issue refund
        <br>
        <span class='peach_id' matTooltip='peach id'>{{transaction.peach_id}}</span>
      </p>
      <p class="trans-note none" *ngIf='!transaction.reference && !transaction.peach_id'>no reference</p>
      <p class="trans-note action" *ngIf='transaction.order_reference'  matTooltip="{{transaction.order_reference}}" routerLink="/cart/{{transaction.cart_id}}">go to order</p>
      <p class="trans-note none" *ngIf='!transaction.order_reference' >no order reference</p>
      <p class="trans-note" *ngIf='transaction.status' >{{transaction.status}}</p>
      <p class="trans-note none" *ngIf='!transaction.status' >no status</p>
    </div>

    <hr>
  </div>
</div>

<div id="pager" >
  <div class="page"> 

  <div> Per page:</div> 
  <span class="silo-filter pagination">
    <button mat-button [matMenuTriggerFor]="paginate">
      <span>{{limit}}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #paginate="matMenu" >
      <button mat-menu-item (click)="setLimit(10)">10</button>
      <button mat-menu-item (click)="setLimit(20)">20</button>
      <button mat-menu-item (click)="setLimit(50)">50</button>
    </mat-menu>
  </span>

  <div (click)='start()' matTooltip="Skip to start" class='action' *ngIf='page > 1'>back to start</div>
  <p (click)='previous()' class='action'>previous</p>
  <div>{{page}}</div>
    <p (click)='next()' class='action'>next</p>
    <div> </div>
  </div>
</div>