<div class="messages" *ngIf='user'  >
    <div class="chat-header" (click)='toggleChatState()' [style.bottom]="chat ? '48px': '0' " >
      <h4 *ngIf='picker && getChats; else dontGetPickerMessages'>{{user.first_name}} {{user.last_name}} <span class='picker-tag'> picker</span></h4>
      <h4 *ngIf='!picker && getChats; else dontGetUserMessages'>{{user.first_name}} {{user.last_name}} </h4>
      <ng-template #dontGetPickerMessages>
        <h4 *ngIf="picker"> view messages</h4>
      </ng-template>
      <ng-template #dontGetUserMessages>
        <h4 *ngIf="!picker"> {{user.first_name}} {{user.last_name}}</h4>
      </ng-template>
      <mat-icon *ngIf='chat'>remove</mat-icon>
      <mat-icon *ngIf='!chat' (click)="showMessages()">expand_less</mat-icon>
    </div>
    <div class="chat-body" [style.height]="chat ? 'calc(100vh - 250px)': '0' " [style.bottom]="chat ? '48px': '0' "  #scrollMe >
        <div class="dates" *ngFor='let message of messages'>
            <div class="date">
                <p>{{message.date}}</p>
            </div>
            <div *ngFor="let chatMessage of message.messages; let last = last"
            [class]="chatMessage.is_user ? 'chat-message chat-user':'chat-message chat-admin' "
            [style.margin-bottom]="chatMessage.ID == lastMessageInChat.ID ? margin : '' "
            >
                <span class='message'>
                    <p class='content'>{{chatMessage.content}}</p>
                    <p class='time' *ngIf='chatMessage.is_user'>{{chatMessage.CreatedAt.substring(11,16)}}</p>
                    <p class='time' *ngIf='!chatMessage.is_user'>{{chatMessage.read}}</p>
                </span>
            </div> 
        </div>
    </div>

    <div class="chat-reply" [style.bottom]="chat ? '0' : '-300px' ">
        <div class="compose">
            <textarea
                [(ngModel)]="reply"
                class='text-area'
                rows='{{rows}}'
                (ngModelChange)="textareaExpand($event)">
            </textarea>
            <mat-icon [style.color]="reply ? 'var(--quenchPink)' : '' " (click)="adminReply()"  class='action'>send</mat-icon>
        </div>
    </div>

</div>