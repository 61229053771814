import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeliveriesService } from 'src/app/services/deliveries/deliveries.service';

@Component({
  selector: 'app-list-single-delivery',
  templateUrl: './list-single-delivery.component.html',
  styleUrls: ['./list-single-delivery.component.scss']
})
export class ListSingleDeliveryComponent implements OnInit {

  id

  constructor(public route: ActivatedRoute, public deliveries: DeliveriesService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.deliveries.getSingleDelivery(this.id)
  }

}
