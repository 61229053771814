<div class="heading">
    <h3>update instruction</h3>
    <mat-icon (click)='closeDialog()'>clear</mat-icon>
  </div>


<div class="edit-fields">
  <mat-form-field class='field' appearance='outline'>
    <mat-label>mall name</mat-label>
    <input type="text" matInput [(ngModel)]="instruction"  placeholder="please call 202 at gate" name='instruction' required>
  </mat-form-field>
</div>



  <div class="save">
    <button mat-flat-button class='button' (click)='updateInstruction()'>Confirm</button>
</div>