<div class="split_tables">
  <!-- assets table -->
  <table mat-table [dataSource]="banks.banks" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let bank">
        <p *ngIf="bank.name">{{ bank.name }}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="branch_code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Branch Code</th>
      <td mat-cell *matCellDef="let bank">
        <p>{{ bank.branch_code }}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="swift_code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Swift code</th>
      <td mat-cell *matCellDef="let bank">
        <p *ngIf="bank.swift_code">{{ bank.swift_code }}</p>
        <p *ngIf="!bank.swift_code" class="empty">no swift code provided</p>
      </td>
    </ng-container> 
    <ng-container matColumnDef="account_number_regex">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Number Regex</th>
      <td mat-cell *matCellDef="let bank">
        <p  >{{ bank.account_number_regex }}</p> 
      </td>
    </ng-container>
 

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let bank">
        <mat-icon (click)="updateBank(bank)">edit</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row class="action" *matRowDef="let bank; columns: Columns"></tr>
  </table>
</div>
1
<div id="pager">
    <div class="page">
      <button
        id="{{ auth.expand ? 'create-expand' : 'create' }}"
        mat-flat-button
        class="button"
        routerLink="/create-banks"
      >
        New
      </button>
    </div>
  </div>