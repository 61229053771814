<div class="dashboard">

    <td class="charts" (window:resize)="onResize($event)">
    
        <div class="chart" >
        <h3>Total Market Orders For Month Of {{month}}</h3>

        <google-chart #chart *ngIf="currentMonth.length > 0"
                        [type]="'LineChart'"
                        [data]="currentMonth"
                        [options]="quench"
                        [width]="width"
                        [height]="height"
                        id='average-desktop'
                                    >
        </google-chart>

        </div>

        <div class="chart" >
            <h3>Market orders for last 30 days</h3>
    
            <google-chart #chart *ngIf="last_30_days.length > 0"
                            [type]="'LineChart'"
                            [data]="last_30_days"
                            [options]="quench"
                            [width]="width"
                            [height]="height"
                            id='average-desktop'
                                        >
            </google-chart>
    
        </div>
    </td>

    <!-- retailer stats -->

    <h5>Retailer Top Cart Products</h5>
    <td class="store-stats">
  
      <div *ngFor ="let data of retailer_carts" class="store" id='retailer-stats'>
        <div class='table'>
          <table>
            <tr *ngFor='let product of data; let odd = odd' [style.background]="odd ? '' : '#f2f2f2' ">
              <td>{{product.name}}</td>
              <td >{{product.total}}</td>
            </tr>
          </table>
        </div>
      </div>
    </td>
  
    <h5>Retailer Top Viewed Products</h5>
    <td class="store-stats">
      <div *ngFor ="let data of retailer_views" class="store" id='retailer-stats'>
        <div class='table'>
          <table>
            <tr *ngFor='let prods of data; let odd = odd'[style.background]="odd ? '' : '#f2f2f2' "  >
              <td>{{prods.name}}</td>
              <td >{{prods.total}}</td>
            </tr>
          </table>
        </div>
      </div>
    </td>
  
    <h5>Retailer Top Paid Products</h5>
    <td class="store-stats">
      <div *ngFor ="let data of retailer_paid" class="store" id='retailer-stats'>
        <div class='table'>
          <table>
            <tr *ngFor='let prods of data; let odd = odd'[style.background]="odd ? '' : '#f2f2f2' "  >
              <td>{{prods.name}}</td>
              <td >{{prods.total}}</td>
            </tr>
          </table>
        </div>
      </div>
    </td>
  
</div>
