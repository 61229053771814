<div class="add-card"  >
  
    <div class="header">
      <h3>add categories to product</h3>
      <mat-icon (click)='closeDialog()'>clear</mat-icon>
    </div>

    <div class="top">
        <div class="search" >
            <mat-icon (click)='getCategories()' matTooltip='search' class='search-icon' [style.left]="inputTouch ? '-20px' : '10px' ">search </mat-icon>
            <mat-icon *ngIf='search' (click)='clearSearch()'  matTooltip='clear' class='clear-icon'>clear</mat-icon>
            <input matInput (keydown.enter)='getCategories()' [(ngModel)]="search" placeholder="Search Categories"  #input (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
        </div>

    </div>
      
        <table  mat-table [dataSource]='searchData' matSort >

            <!-- <mat-spinner class='spinner'></mat-spinner>  -->

            <ng-container matColumnDef="icon">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
            <td mat-cell *matCellDef="let item" routerLink='/items/{{item.id}}' id='item-name'> 
                <img class='img' src={{item.image}} alt="" onerror="this.src='assets/placeholders/placeholder-img.png' ">
            </td>
            </ng-container>

            <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let item" routerLink='/items/{{item.id}}'> 
                <p > {{item.name}} </p>
            </td>
            </ng-container>

            <ng-container matColumnDef="add">
            <th mat-header-cell  *matHeaderCellDef> </th>
            <td mat-cell  *matCellDef="let item" > 
                <mat-icon (click)='toggleSelection(item)'>add_circle</mat-icon>
            </td>
        </ng-container>

            <tr mat-header-row *matHeaderRowDef="Columns"></tr>
            <tr mat-row class='action'  *matRowDef="let item; columns: Columns;"></tr>
        </table>


        <div class="add-list">
            <mat-chip-list aria-label="Fish selection">
                <p *ngIf='catArr.length > 0'>adding :</p>
            <mat-chip id='selectedCat' #c="matChip" id='chosen-category' [value]="item" *ngFor='let item of catArr'  >
                <p > {{item.name}} </p>
                <mat-icon matChipRemove (click)='toggleSelection(item)'>cancel</mat-icon>
            </mat-chip>
            </mat-chip-list>
        </div>


    <div class="btn">
        <button mat-flat-button class='button' (click)='addCategoriesToProduct()'>Confirm</button>
    </div>
  
</div>
  

