import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss']
})
export class MarketComponent implements OnInit {

  width           = 800
  height          = 400
  currentMonth    = []
  last_30_days    = []
  retailer_sales  = []


  retailer_carts  = []
  retailer_views  = []
  retailer_paid  = []


  month

  service = 'fulfilment'

  marketStatsApiCalls = [
    {
      name: 'current_month',
      api_call: 'dashboard/silos/5/current_month'
    },
    {
      name: 'last_30_days',
      api_call: 'dashboard/silos/5/last30days'
    },
    {
      name: 'today',
      api_call: 'dashboard/today'
    },
  ]

      // CHART OPTION SETTINGS
      quench = {
        pointSize: 5,
        curveType: 'function',
        colors: ['#ff4081', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
        chartArea:{left:50,top:10,right:10,bottom:60 ,width: '100%', height: '100%'},
      }

  constructor(public api: ApiService,
              public message: MessagesService) { }

  ngOnInit(): void {
    let date = new Date()
    this.month = date.toLocaleString('default', { month: 'long' })


    this.getMarketStats()

    if(window.innerWidth > 1100){
      this.width = window.innerWidth - 110
      } else {
        this.width = window.innerWidth - 50
      }
  }

  // chart responsiveness
  onResize(event){
     var w = event.target.innerWidth
      if(w > 1100){
          this.width = w - 110
        } else {
          this.width = w - 50
       }
    }


  getMarketStats(){

    // this.marketStatsApiCalls.forEach(stat => {
    //   this.api.getList(this.service, stat.api_call).subscribe((data: any) => {
    //     console.log(data)
    //   })
    // })

    this.api.getList(this.service, `dashboard/silos/5/current_month`).subscribe((result: any) => {
      // console.log(result)
      let reverseDays = result.days.reverse()
      reverseDays.forEach(day => {
        this.currentMonth.push([`${day.effective_date.substring(8,10)} ${this.month} `, day.total ])
      })
    })

    this.api.getList(this.service, `dashboard/silos/5/last30days`).subscribe((data: any) => {
      let reverseDays = data.days.reverse()
      reverseDays.forEach(day => {
        this.last_30_days.push([`${day.effective_date.substring(5,7)}/${day.effective_date.substring(8,10)} `, day.total ])
      })
    })

    this.api.getList(this.service, `dashboard/today`).subscribe((result: any) => {
    //  console.log(result)
    })

    this.api.getList(`tracking`, `admin/dashboard`).subscribe((result: any) => {

      // console.log(result)
      
      // top products per ratailer
        let top_product_retailer

          result.data.forEach(element => {
          if(element.hasOwnProperty('top_product_retailer')){
          top_product_retailer = element.top_product_retailer
          }
          })
           
          top_product_retailer.forEach(retail => {
            if(retail['retailer'].cart !== null){
              this.retailer_carts.push(retail["retailer"].cart)
            }
            if(retail['retailer'].view !== null){
              this.retailer_views.push(retail["retailer"].view)
            }
            if(retail['retailer'].paid !== null){
              this.retailer_paid.push(retail["retailer"].paid)
            }
        })
    })

  }

}
