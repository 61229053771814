<div class="header">
    <h3>Update Bank</h3>
    <mat-icon (click)="closeDialog()" class="action">clear</mat-icon>
  </div>
  <hr />
  
  <form #asset = 'ngForm' (ngSubmit)='updateBank(asset.value)'>
    <mat-form-field appearance="outline" class="field">
      <mat-label>Asset name</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="name"
        placeholder="Absa"
        name="name"
        required
      />
    </mat-form-field>
  
    <mat-form-field appearance="outline" class="field">
      <mat-label>Branch Code</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="branch_code"
        placeholder="632005"
        name="branch_code"
      />
    </mat-form-field>
  
    <mat-form-field appearance="outline" class="field">
      <mat-label>Swift Code</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="swift_code"
        placeholder="NEDSZAJJ"
        name="swift_code"
      />
    </mat-form-field>
  
   
  
    <mat-form-field appearance="outline" class="field">
      <mat-label>account_number_regex</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="account_number_regex"
        placeholder="account_number_regex"
        name="account_number_regex"
      />
    </mat-form-field>
  
  
    <div class="save">
      <button mat-flat-button class="button">submit</button>
    </div>
  </form>
  