import { Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { MessagesService } from 'src/app/services/messages.service'
import { environment as devEnv } from '../../../../environments/environment'


import { ApiService } from '../../../services/api.service'
@Component({
  selector: 'app-add-retailer',
  templateUrl: './add-retailer.component.html',
  styleUrls: ['./add-retailer.component.scss']
})
export class AddRetailerComponent implements OnInit {

  vat
  name
  phone
  user_id
  retailer
  latitude
  longitude
  retailer_id
  physical_address


  shade        = false
  imageFormat  = false
  retailerInfo = false


  

  imageFile: {resource: string, file: any, name: string}



  image = '../assets/placeholders/logo-placeholder.png'
  



  constructor(
    private api:ApiService,
    public message: MessagesService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.getUser()
  }

  getUser(){
    this.api.getList(`auth`, `user/profile`).subscribe((data: any) => {
      this.user_id = data.user.ID
    })
  }

  back(){
    this.retailerInfo = false
  }


  onAutocompleteSelected(e){
    this.name             = e.name
    this.phone            = e.formatted_phone_number
    this.latitude         = e.geometry.location.lat()
    this.longitude        = e.geometry.location.lng()
    this.physical_address = e.formatted_address
  }

  submitRetailerInfo(i){

    if(i.name && this.physical_address && i.email && i.phone){
      this.retailerInfo = true
      this.retailer = i
    }
  }

  submitRetailer(){
    this.retailer.physical_address = this.physical_address
    this.retailer['retailer_user'] = this.user_id
    this.retailer['image']         = 'image'

    this.api.post('catelog', `retailers`, [this.retailer]).subscribe((retailer: any) => {
      this.retailer_id = retailer[0].id
      this.imageUpload()
    }, error => {
      this.message.showSnack(error.error)
    })
  }



 // =====================
  //   RETAILER LOGO
  // =====================


  imagePreview(event) {
    if (event.target.files && event.target.files[0]) {
        let reader = new FileReader()

        reader.onload = (_event: any) => {
            this.imageFile = {
                resource: _event.target.result,
                file: event.srcElement.files[0],
                name: event.srcElement.files[0].name
            }

            if(this.imageFile.name.includes('png') || this.imageFile.name.includes('jpg') || this.imageFile.name.includes('jpeg') ||  this.imageFile.name.includes('webp') ){
              this.imageFile.name = 'image.png'
              this.imageFormat = true
              this.image = this.imageFile.resource
          } else {
            this.message.showSnack(`oops!   that is an unsupported image type`)
          }

        }
        reader.readAsDataURL(event.target.files[0])
    }
  }


  imageUpload(){
    this.shade = true
    this.message.showSnack('Creating Retailer')
    setTimeout(() =>{
      this.message.showSnack(`Uploading image`)
    }, 4000)
    var myHeaders = new Headers()

    myHeaders.append("Authorization", "Bearer " + this.api.token)

    var formdata = new FormData()

    formdata.append("file", this.imageFile.file, this.imageFile.name)
    formdata.append("body", "{ \"resource\":\"retailers\", \"resource_id\":"+ this.retailer_id +",\"x_dim\":1, \"y_dim\":1}")

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata
    }


     // Dev environment

     if(!devEnv.production){
        fetch("https://api.api-dev.quench.org.za/catalogue/v1/image", requestOptions)
        .then(response => response.text())
        .then(result =>
          setTimeout(() => {
            this.message.showSnack(`Succesfully created retailer`)
            this.router.navigate([`/retailers/${this.retailer_id}`])
          }, 10000))
        .catch(error => console.log('error', error))
    }
      // production environment

    else if(devEnv.production){
      fetch("https://api.quench.org.za/catalogue/v1/image", requestOptions)
      .then(response => response.text())
      .then(result =>
        setTimeout(() => {
          this.message.showSnack(`Succesfully created retailer`)
          this.router.navigate([`/retailers/${this.retailer_id}`])
        }, 10000))
      .catch(error => console.log('error', error))
    }
  }


}
