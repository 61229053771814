<!-- Search -->

<div class="search">
  <label for="search"></label>
  <mat-icon class='search-icon' [style.left]="inputTouch ? '-20px' : '10px' ">search</mat-icon>
    <input  matInput #input autofocus [(ngModel)]="search" placeholder="Search" (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
</div>

<!-- Export chips -->

<mat-chip-list aria-label="Fish selection">
  <mat-chip  *ngFor='let chip of exports' 
            (click)='setExportType(chip)' 
            [ngClass]="chip == current ? 'current' : '' " >{{chip}}
  </mat-chip>
</mat-chip-list>


<!-- Export Data Form -->

<div class="center-page">
  <h4>Download {{current}} Data</h4>
    <mat-form-field appearance="fill">
      <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker" >
            <input  matInput  matStartDate placeholder="Start date" [(ngModel)]='date_from' readonly>
            <input matInput id='end-date' matEndDate placeholder="End date" [(ngModel)]='date_to' readonly> 
        </mat-date-range-input>
      <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker class='test' #picker></mat-date-range-picker>
    </mat-form-field>  
    
    <!-- TimeRangePicker -->

    <div class="headers" *ngIf='date_from && date_to'>
      <p>Start Time</p>
      <p>End Time</p>
    </div>

    <div class="times" *ngIf='date_from && date_to'>
      <div class="time">
        <span class="arrows">
          <mat-icon (click)='startHourIncrease()'>keyboard_arrow_up</mat-icon>
          <p><u *ngIf='start_hour < 10'>0</u>{{start_hour}}</p>
          <mat-icon id='bottom-button' (click)='startHourDecrease()'>expand_more</mat-icon>
        </span>
        <span id='colon'>:</span>
        <span class="arrows">
          <mat-icon (click)='startMinuteIncrease()'>keyboard_arrow_up</mat-icon>
          <p><u *ngIf='start_minute < 10'>0</u>{{start_minute}}</p>
          <mat-icon id='bottom-button' (click)='startMinuteDecrease()'>expand_more</mat-icon>
        </span>
      </div>

      <div class="time">
        <span id='colon'>-</span>
      </div>

      <div class="time">
        <span class="arrows">
          <mat-icon (click)='endHourIncrease()'>keyboard_arrow_up</mat-icon>
          <p><u *ngIf='end_hour < 10'>0</u>{{end_hour}}</p>
          <mat-icon id='bottom-button' (click)='endHourDecrease()'>expand_more</mat-icon>
        </span>
        <span id='colon'>:</span>
        <span class="arrows">
          <mat-icon (click)='endMinuteIncrease()'>keyboard_arrow_up</mat-icon>
          <p><u *ngIf='end_minute < 10'>0</u>{{end_minute}}</p>
          <mat-icon id='bottom-button' (click)='endMinuteDecrease()'>expand_more</mat-icon>
        </span>
      </div>

    </div>   
    <!-- <mat-checkbox  type="text" name='send' matInput ngModel >Search</mat-checkbox> -->

      <div class="btn">
        <button mat-flat-button (click)='export()' class='button'>Download CSV</button>
        <p id='search'>* search at the top for specific results</p>
      </div>
</div>