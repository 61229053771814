import { Component, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { EarningsService } from 'src/app/services/earnings/earnings.service';
import { MiscService } from 'src/app/services/misc/misc.service';

@Component({
  selector: 'app-list-earnings',
  templateUrl: './list-earnings.component.html',
  styleUrls: ['./list-earnings.component.scss'],
})
export class ListEarningsComponent implements OnInit {
  Columns = [
    'date',
    'user_name',
    'description',
    'status',
    'earning_type',
    'amount',
  ];

  page = 1;
  limit = 10;
  search = '';

  constructor(
    public earnings: EarningsService,
    public auth: AuthService,
    public dialog: MatDialog,
    public misc: MiscService
  ) {}

  ngOnInit(): void {
    this.earnings.getEarningTypes();
    this.earnings.getEarnings(this.page, this.limit, this.search);
  }

  searchReset() {
    this.earnings.getEarnings(this.page, this.limit, this.search);
  }

  // earning type view dialog
  viewEarningTypes() {
    const dialog = this.dialog.open(viewEarningTypesDialog, {
      width: '800px',
      height: '600px',
    });
  }


  setLimit(limit) {
    this.limit = limit;
    this.earnings.getEarnings(this.page, this.limit, this.search);
  }
  next() {
    this.page++;
    this.earnings.getEarnings(this.page, this.limit, this.search);
  }
  previous() {
    if (this.page > 1) {
      this.page--;
      this.earnings.getEarnings(this.page, this.limit, this.search);
    }
  }
  start() {
    this.page = 1;
    this.earnings.getEarnings(this.page, this.limit, this.search);
  }
}

// ============================
//   VIEW ASSET TYPE  DIALOG
// ============================
@Component({
  selector: 'list-earning-types',
  templateUrl: 'list-earning-types.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class viewEarningTypesDialog {
  earnings_type_columns = ['name', 'description'];

  constructor(
    public earnings: EarningsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<viewEarningTypesDialog>
  ) {}

  ngOnInit(): void {
    this.earnings.getEarningTypes();
  }

  onAdd = new EventEmitter();

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  // earning type create dialog
  createEarningType() {
    const dialog = this.dialog.open(createEarningTypeDialog, {
      width: '650px',
      height: '250px',
    });

    dialog.afterClosed().subscribe(() => {
      this.earnings.getEarningTypes();
    });
  }
}

// ============================
//  CREATE ASSET TYPE  DIALOG
// ============================

@Component({
  selector: 'create-earning-type-dialog',
  templateUrl: 'create-earning-type-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class createEarningTypeDialog {
  constructor(
    private earnings: EarningsService,
    public dialogRef: MatDialogRef<createEarningTypeDialog>
  ) {}

  firstForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl(''),
  });

  onAdd = new EventEmitter();

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  submitEarningType() {
    // console.log(this.firstForm.value)
    this.earnings.createEarningTypes(this.firstForm.value);
    this.closeDialog();
  }
}
