<div class="search">
    <mat-icon
      (click)="searchReset()"
      matTooltip="search"
      class="search-icon"
      [style.left]="misc.inputTouch ? '-20px' : '10px'"
      >search</mat-icon
    >
    <mat-icon
      *ngIf="users.dischem_users_search"
      (click)="clearSearch()"
      matTooltip="clear"
      class="clear-icon"
      >clear</mat-icon
    >
    <input
      (keydown.enter)="searchReset()"
      matInput
      [(ngModel)]="users.dischem_users_search"
      placeholder="Search dischem users"
      #input
      (blur)="misc.onBlur()"
      (focus)="misc.onFocus()"
      [style.left]="misc.inputTouch ? '10px' : '35px'"
    />
  </div>
  
  <table mat-table [dataSource]="users.dischem_users" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let admin" (click)="navigateRoute(admin)">
        {{ admin.first_name }} {{ admin.last_name }}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let admin" (click)="navigateRoute(admin)">
        {{ admin.email }}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>phone</th>
      <td mat-cell *matCellDef="let admin">{{ admin.phone }}</td>
    </ng-container>
  
    <ng-container matColumnDef="verified">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Verified</th>
      <td mat-cell *matCellDef="let admin">
        <mat-icon *ngIf="admin.active; else inactive">verified_user</mat-icon>
        <ng-template #inactive><mat-icon>gpp_bad</mat-icon> </ng-template>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row *matRowDef="let admin; columns: Columns" class="action"></tr>
  </table>
  
  <div class="mobile">
    <mat-expansion-panel hideToggle *ngFor="let admin of users.dischem_users">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ admin.first_name }} {{ admin.last_name }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <span class="flex">
        <mat-title>email:</mat-title>
        <p>{{ admin.email }}</p>
      </span>
      <span class="flex">
        <mat-title>Phone:</mat-title>
        <p>{{ admin.phone }}</p>
      </span>
      <span class="flex">
        <mat-title>Verified</mat-title>
        <p><mat-icon *ngIf="admin.active">verified_user</mat-icon></p>
      </span>
    </mat-expansion-panel>
  </div>
  
  <div id="pager">
    <div class="page">
      <div>Per page:</div>
      <span class="silo-filter pagination">
        <button mat-button [matMenuTriggerFor]="paginate">
          <span>{{ users.dischem_users_limit }}</span>
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #paginate="matMenu">
          <button mat-menu-item (click)="setLimit(10)">10</button>
          <button mat-menu-item (click)="setLimit(20)">20</button>
          <button mat-menu-item (click)="setLimit(50)">50</button>
        </mat-menu>
      </span>
  
      <div
        (click)="start()"
        matTooltip="Skip to start"
        class="action"
        *ngIf="users.dischem_users_page > 1"
      >
        back to start
      </div>
      <p (click)="previous()" class="action">previous</p>
      <div>{{ users.dischem_users_page }}</div>
      <p (click)="next()" class="action">next</p>
      <div></div>
    </div>
  </div>
  