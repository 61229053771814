<div class="back action" routerLink="/categories">
  <mat-icon>arrow_back</mat-icon>
  <p>categories</p>
</div>

<div class="detail" *ngIf='category'>

  <div class="images">
    <img class='image' src="{{category.image}}" alt="">
    <span class='edit-images-button action' (click)="updateCategoryImage()">update icon</span>
  </div>

  <hr>
    <div class="title">
      <div class="update">
        <h4>{{category.name}}</h4>
        <mat-slide-toggle [(ngModel)]="category.active" (change)="toggleCategory(category)"></mat-slide-toggle> 
      </div>
        <span class='update-button action' (click)="editCategory()">update </span>
    </div>
    <span class='silo'><p> {{silo}}</p></span>
    <span class='linked-key'  *ngIf='parent'>in
      <p  id='linked-key' 
          class='action' 
          routerLink='/categories/{{category.parent_id}}'
          matTooltip='go to parent category'> 
          {{parent}}</p>
    </span>
    <span class='linked-key' >Linked Key: <p id='key' >{{linked_key}}</p></span>
  <hr>

  <div class="menus">
    <div class="menu action" 
    *ngFor="let menu of menus" 
    [style.border-bottom]="menu == currentMenu ? '1px solid grey' : '' "
    (click)="selectMenu(menu)">{{menu}}</div>
  </div>

  <app-category-products *ngIf="currentMenu == 'products' || currentMenu == 'stores' "></app-category-products>
  <app-category-subcategories *ngIf="currentMenu == 'subcategories' "></app-category-subcategories>


</div>
