<div class="back action" routerLink="/malls">
    <mat-icon>arrow_back</mat-icon>
    <p>malls</p>
  </div>
  
  <div class="tabs">
    <div (click)='selectTab(i)' *ngFor="let tab of tabs; let i = index" [class]="selectedTab == tab.name ? 'selectedTab' : '' ">
      <div id='tab'>
        <p id='tab-name'>{{tab.name}}</p>
      </div>
    </div>
  </div>
  
  <app-mall-detail *ngIf="selectedTab == 'detail'"></app-mall-detail>
  <app-mall-stores *ngIf="selectedTab == 'stores'"></app-mall-stores>
  <app-mall-zones *ngIf="selectedTab == 'zones'"></app-mall-zones>
  <app-mall-sections *ngIf="selectedTab == 'sections'"></app-mall-sections>

  