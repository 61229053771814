import { Injectable } from '@angular/core'
import { JwtHelperService } from "@auth0/angular-jwt"
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { environment as devEnv } from '../../environments/environment'
import { observable, Observable, throwError } from 'rxjs'
import { Router } from '@angular/router'
import { AuthService } from './auth.service'


@Injectable({
  providedIn: 'root'
})

export class ApiService {

  // comment

  private authUrl
  private catelogUrl
  private paymentsUrl
  private fulfilmentUrl
  private trackingUrl

  private godriveUrl


  private isExpired
  private expirationDate
  public  devEnv = devEnv
  private apiKey="QuenchMobi"
  public  refresh = localStorage.getItem("REFRESH")
  public  token = localStorage.getItem("AUTH_TOKEN")

  constructor(
    private http: HttpClient,
    public helper: JwtHelperService,
    public router: Router,
  ) { 

    if(!devEnv.production){
    
      this.catelogUrl  = 'https://api.api-dev.quench.org.za/catalogue/v1/'
      this.authUrl= 'https://api.api-dev.quench.org.za/auth/v1/' 
      this.paymentsUrl = 'https://api.api-dev.quench.org.za/pay/v1/'    
      this.fulfilmentUrl = 'https://api.api-dev.quench.org.za/fulfilment/v1/'
      this.trackingUrl = "https://api.api-dev.quench.org.za/tracking/v1/"
      this.godriveUrl = "https://api.api-dev.quench.org.za/godrive/v1/" 
 
    }
    
    else if(devEnv.production){
      this.catelogUrl  = 'https://api.quench.org.za/catalogue/v1/'
      this.authUrl = 'https://api.quench.org.za/auth/v1/'
      this.paymentsUrl = 'https://api.quench.org.za/pay/v1/'
      this.fulfilmentUrl = 'https://api.quench.org.za/fulfilment/v1/'
      this.trackingUrl = 'https://api.quench.org.za/tracking/v1/'
      this.godriveUrl = "https://api.quench.org.za/godrive/v1/"

    }

    // this.isExpired = helper.isTokenExpired(this.token)
    // this.expirationDate = helper.getTokenExpirationDate(this.token)

    localStorage.setItem(`AUTH_TOKEN`, localStorage.getItem('REFRESH'))

    this.post('auth', `user/refresh`, this.refresh).subscribe((data: any) => {
      localStorage.setItem(`AUTH_TOKEN`, data.data.token)
      localStorage.setItem(`REFRESH`, data.data.refresh)
    }, error => {
      
    })

  }


  getBaseUrl(service){
    var base=''

    if(service =='catelog'){
      base = this.catelogUrl
    }

    if(service =='fulfilment'){
      base = this.fulfilmentUrl
    }

    if(service =='auth'){
      base = this.authUrl
    }

    if(service =='payments'){
      base = this.paymentsUrl
    }

    if(service == 'tracking'){
      base = this.trackingUrl
    }
    if(service == 'godrive'){
      base = this.godriveUrl
    }
    return base
  }

  getLiveness(service){
    var base = `https://api.api-dev.quench.org.za/`
    var url = `${base}${service}`
    // console.log(url)

    return this.http.get(url)
  }

  getList(service,resource){
    var base = this.getBaseUrl(service)
    var url = `${base}${resource}`
    const headers = this.setHeaders(service)
    // console.log(url)

    return this.http.get(url, {headers})
  }

  export(service, resource){
    var base = this.getBaseUrl(service)
    var headers = this.setHeaders(service)
    var url = `${base}${resource}`

   return this.http.get(url ,{headers, responseType: 'csv' as any})
   }

  get(service,resource,id){
    var base = this.getBaseUrl(service)
    const headers = this.setHeaders(service)
    // console.log(base+resource)

    return this.http.get(base+resource+'/'+id,{headers})
  }

  getItem(service,resource,id){
    const headers = this.setHeaders(service)
    return this.http.get(this.catelogUrl+resource+'/'+id,{headers})
  }

  post(service, resource, data){
    var base = this.getBaseUrl(service)
    const headers = this.setHeaders(service)
    var url = `${base}${resource}`
    // console.log(url)

    return this.http.post(url, data , {headers})

   }

   patch(service, resource, data){
    var base  = this.getBaseUrl(service)
    const headers = this.setHeaders(service)
    var url = `${base}${resource}`

    return this.http.patch(url, data, {headers: headers})
  }


   delete(service,resource ){
    var base = this.getBaseUrl(service)
    const headers = this.setHeaders(service)
    // console.log(base+resource)

    return this.http.delete(base+resource,{headers})
   }



  // =================
  //  LOCAL QUERIES
  // =================

   getLocal(resource){
    var base  ="http://localhost:8080/catalogue/v1/"

    var url = `${base}${resource}`
    const headers = this.setHeaders("catelog")

    return this.http.get(url,{headers})
   }

   postLocal(service, resource, data){
    var base  =`http://localhost:8080/${service}/v1/`
    const headers = this.setHeaders(service)
    var url = `${base}${resource}`
    console.log(url)

    return this.http.post(url, data , {headers})

   }

   patchLocal( resource, data){
    var base  ="http://localhost:8080/catalogue/v1/"
    const headers = this.setHeaders("catelog")
    var url = `${base}${resource}`

    return this.http.patch(url, data, {headers: headers})
  }

   deleteLocal(resource ){
    var base  ="http://localhost:8080/catalogue/v1/"
    const headers = this.setHeaders("catelog")
    // console.log(base+resource)

    return this.http.delete(base+resource,{headers})
   }


  //  ========================
  //        SET HEADERS
  // =========================

  getDirections(id){
    return this.http.get(this.catelogUrl+'orders/'+id+'/directions')
  }

  setHeaders(service){

    if(service == 'catelog'){
      const token = localStorage.getItem("AUTH_TOKEN")
      
      return {
      'Authorization': 'Bearer '+ token,
      'Content-Type': 'application/json',
      'x-api-key':this.apiKey
     }
    }

    if(service == 'godrive'){
      const token = localStorage.getItem("AUTH_TOKEN")
      
      return {
      'Authorization': 'Bearer '+ token,
      'Content-Type': 'application/json',
      'x-api-key':this.apiKey
     }
    }
    if(service == 'auth'){
      // change here
      const token = localStorage.getItem("AUTH_TOKEN")

      // let token = this.token

      return {
       'Authorization': 'Bearer '+ token,
       'Content-Type': 'application/json',
      }
     }

     if(service == 'fulfilment'){
       const token = localStorage.getItem("AUTH_TOKEN")

       return {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json',
       }
      }

      if(service == 'payments'){
        const token = localStorage.getItem("AUTH_TOKEN")
  
        return {
         'Authorization': 'Bearer '+ token,
         'Content-Type': 'application/json',
        }
      }

      if(service == 'tracking'){
        const token = localStorage.getItem("AUTH_TOKEN")
  
        return {
         'Authorization': 'Bearer '+ token,
         'Content-Type': 'application/json',
        }
  
      }

      if(service == 'godrive'){
        const token = localStorage.getItem("AUTH_TOKEN")
  
        return {
         'Authorization': 'Bearer '+ token,
         'Content-Type': 'application/json',
        }
  
      }

  }
}
