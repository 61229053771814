<div class="header">
    <h3>You have been inactive for  {{heading}}</h3>
    <mat-icon class='action' (click)='closeDialog()'>clear</mat-icon>
</div>

<hr>

<p>{{body}}</p>


<div class="btn">
    <button mat-flat-button class='button' (click)='closeDialog()'>ok</button>
</div>