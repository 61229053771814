import { MessagesService } from 'src/app/services/messages.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, Inject, OnInit, EventEmitter }  from '@angular/core'
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-category-products',
  templateUrl: './category-products.component.html',
  styleUrls: ['./category-products.component.scss']
})
export class CategoryProductsComponent implements OnInit {
  displayedColumns: string[] = ['image', 'name', 'price', 'action']


  id
  data
  silo_id
  page = 1
  category
  limit = 10

  constructor(public route: ActivatedRoute,
              public api: ApiService,
              public auth: AuthService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getCategory()
  }

  getCategory(){
    this.api.getItem("catelog","categories",this.id).subscribe((data:any)=>{
      this.category = data
      this.silo_id = data.silo_id
      this.getCategoryData(data.silo_id)
    })
  }

  getCategoryData(silo){
    if(silo == 5){
        this.api.getList(`catelog`, `categories/${this.id}/stores?page=${this.page}&per_page=${this.limit}`).subscribe((data: any) => {
        this.data = data
      })   
     } else {
      this.api.getList(`catelog`, `categories/${this.id}/products?page=${this.page}&per_page=${this.limit}`).subscribe((data: any) => {
        if(data !== null){
        this.data = data
        }
      })
    }
  }

  addItems(){
    const dialog = this.dialog.open(addCategoryProduct, {
      width: '92vw',
      height: '80vh',
      data: {
        category: this.category,
      }
    })

    dialog.afterClosed().subscribe(() => {
      this.getCategoryData(this.silo_id)
    })
  }

  removeItem(item){
    const dialog = this.dialog.open(removeCategoryItem, {
      width: '550px',
      height: '200px',
      data: {
        item: item,
        category: this.category
      }
    })

    dialog.afterClosed().subscribe(() => {
      this.getCategoryData(this.silo_id)
    })
  }

  // ===============
  //   PAGINATION
  // ===============

  start(){
    this.page = 1
    this.getCategoryData(this.silo_id)

  }

  setLimit(limit){
    this.limit = limit
    this.getCategoryData(this.silo_id)
    localStorage.setItem('limit', JSON.stringify(limit))
  }

  next(){
    this.page++
    this.getCategoryData(this.silo_id)
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.getCategoryData(this.silo_id)
    }
  }

}


// ============================
//    REMOVE DIALOG
// ============================

@Component({
  selector: 'delete-dialog',
  templateUrl: '../../../../components/dialogs/delete-dialog.html',
  styleUrls: ['../../../../components/dialogs/delete-dialog.component.scss']
})

export class removeCategoryItem {

  
  inputTouch  = false
  heading        = `item from category`
  body        = `Are you sure you want to remove this item from this category? You can always add it again later.`


  item
  category

  // get section info after success
  onAdd       = new EventEmitter()
  

  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: { item, category }, 
              private api : ApiService, 
              private message: MessagesService,  
              public dialogRef: MatDialogRef<removeCategoryItem>) {

  }

  ngOnInit(): void {
    this.item    = this.data.item
    this.category = this.data.category

    console.log(this.item)
    console.log(this.category)
  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

  removeItem(){
    if(this.category.silo_id == 5){
      this.api.delete(`catelog`, `storecategories/${this.item.store_category_id}`).subscribe((store: any) => {
        this.message.showSnack(`Successfully removed store ${this.item.name} from ${this.category.name}`)
        this.closeDialog()
      }, error => {
        this.category,this.message.showSnack(error.error)
      })
    } 
      else {
      this.api.delete(`catelog`, `productcategories/${this.item.productcategory_id}`).subscribe((product: any) => {
        this.message.showSnack(`Successfully removed product ${this.item.name} from ${this.category.name}`)
        this.closeDialog()
      }, error => {
        this.category,this.message.showSnack(error.error)
      })
    }
  
  }

}


@Component({
  selector: 'category-product-add-dialog',
  templateUrl: 'category-add-products.html',
  // styleUrls: ['../../../pocket-mall/mall-stores/store-add.component.scss']
})

export class addCategoryProduct {
  Columns = ['image', 'name', 'add']

  
  inputTouch  = false

  category

  retailers
  searchData
  retailer_id = 0
  retailerSearch = ''

  item
  section
  search = ''

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }

  page = 1
  limit = 10
  silo_id = 0
  silos

  // get section info after success
  onAdd       = new EventEmitter()
  

  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: { category}, 
              private api : ApiService, 
              private message: MessagesService,  
              public dialogRef: MatDialogRef<addCategoryProduct>) {

  }

  ngOnInit(): void {
    this.category = this.data.category
    this.getRetailers()
    this.getSilos()
    this.sectionSearch()
  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

  // =====================
  //    APPLY FILTER
  // =====================

  selectRetailer(){
    this.page = 1
    this.getProducts() 
  }

  getRetailers(){
    this.api.getList('catelog', `retailers?page=${this.page}&per_page=${100}&search=${this.retailerSearch}`)
    .subscribe((retailers: any) => {

      let all = {
        id: 0,
        name: 'All',
      }

      retailers.unshift(all)
      this.retailers = retailers
    }, error => {
      this.message.showSnack(error.error)
    })
  }

  selectSilo(){
    this.page = 1
    this.sectionSearch()
  }

  getSilos(){
    this.api.getList('catelog', `silos`).subscribe((silos: any) => {
      let all = {
        id: 0,
        name: 'All',
      }

      silos.unshift(all)
      this.silos = silos
    }, error => {
      this.message.showSnack(error.error)
    })
  }

  // ========================
  //    GET ITEMS TO ADD
  // ========================

  sectionSearch(){
    if(this.category.silo_id === 5){
      this.getStores()
    } else {
      this.getProducts()
    }
  }

      getProducts(){
        this.api.getList('catelog', `products?page=${this.page}&per_page=${this.limit}&search=${this.search}&retailer_id=${this.retailer_id}`).subscribe((products: any) => {
          this.searchData = products
          })
       }

       getStores(){
        this.api.getList('catelog', `stores?page=${this.page}&per_page=${this.limit}&search=${this.search}&silo_id=${this.silo_id}`).subscribe((stores:any) =>{
          this.searchData = stores
        })
      }

       searchReset(){
         this.page = 1
         this.sectionSearch()
       }

  // ========================
  //  ARRAY OF ITEMS TO ADD
  // ========================

    itemsArr = []

    toggleSelection(cat) {

      var storeRemove = this.itemsArr.map(item => item.id).indexOf(cat.id)

      if(storeRemove !== -1){
        this.itemsArr.splice(storeRemove, 1);
      } else if(storeRemove === -1){
        this.itemsArr.push(cat);
    }
  }

    addProductsToCategory(){
      
      let newItemsArr = []

      this.itemsArr.forEach(item => {
        const data = {
          category_id: JSON.parse(this.category.id),
        }

        if(this.category.silo_id === 5){
          data['store_id']= item.id
        } else {
          data['product_id'] = item.id
        }
    
        newItemsArr.push(data)
      })

      if(this.category.silo_id === 5){
        this.api.post(`catelog`, `storecategories`, newItemsArr).subscribe((store: any) => {
          this.closeDialog()
          this.message.showSnack(`Succesfully added stores to ${this.category.name}`)
        })
      } else {
        this.api.post('catelog', `productcategories`, newItemsArr).subscribe(
          (prod: any) => {
            this.message.showSnack(`Succesfully added product to ${this.category.name}`)
            this.closeDialog()
          },
          (error) => {
            this.message.showSnack(error.error)
            // console.log(error)
          })
      }

    }

  setLimit(limit){
    this.limit = limit
    this.sectionSearch()
  }

  start(){
    this.page = 1
    this.sectionSearch()
  }

  next(){
    this.page++
    this.sectionSearch()
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.sectionSearch()
    }
  }
  
}