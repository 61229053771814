// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast'
import { Component, OnInit } from '@angular/core'
import { MessagesService } from 'src/app/services/messages.service'
import { ApiService } from '../../../services/api.service'

@Component({
  selector: 'app-fees',
  templateUrl: './fees.component.html',
  styleUrls: ['./fees.component.scss']
})
export class FeesComponent implements OnInit {

  fee
  id =""
  delete
  amount
  fees = []
  silos = []
  page = 1
  fee_type
  fromValue
  retries = 0
  silo_id = 2
  limit = 10
  untilValue
  unit_label = "Number of ..."
  state = 'insert'
  feeTypes = [{
    'name':'Driver Fee',
    'value':'driver_fee',
  },{
    'name':'Picker Fee',
    'value':'picker_fee',
  }]
  
  
  constructor(private api:ApiService,
              private message: MessagesService) { }

  ngOnInit(): void {
    this.api.getList("catelog","silos").subscribe((data:any)=>{
      this.silos =data
    })
    this.getFees()
  }

  deleteCheck(fee){
    this.delete = !this.delete
    this.fee = fee
  }

  siloChanged(){
    this.getFees();
  }

  getFees(){
    this.api.getList("fulfilment","admin/fees?silo_id="+this.silo_id).subscribe((data:any)=>{
      // console.log(data);
      this.fees = data.data
    },error=>{
      this.message.showSnack(error.error)
        if(this.retries < 3 && error.status !== 401){
          this.retries++
          this.getFees()
        }
    })
  }

  editFee(fee){
    this.id = fee.ID
    this.state = 'update'
    this.silo_id = fee.silo_id,
    this.fee_type = fee.fee_type
    this.untilValue = fee.until_value
    this.amount = fee.amount
  }

  updateFee(){
    this.api.patch("fulfilment","admin/fees/"+this.id,{
      "until_value":this.untilValue,
      "amount":this.amount
    }).subscribe((data:any)=>{
      this.getFees();

    this.state='insert';
    },error=>{
      alert(JSON.stringify(error));
    });
  }

  changeType(){
    if(this.fee_type=="picker_fee"){
      this.unit_label="Number of items picked"
    } else {
      this.unit_label="Number of Kilometers"
    }
  }

  createFee(){
    this.api.post("fulfilment","admin/fees",{
      "silo_id":this.silo_id,
      "fee_type":this.fee_type,
      "from_value":this.fromValue,
      "until_value":this.untilValue,
      "amount":this.amount

    }).subscribe((data:any)=>{
      this.message.showSnack(`Successfully created fee`);
      this.getFees();
    },error=>{
      // console.log(error)
      this.message.showSnack(error.statusText);
    });
  }

  deleteFee(){
    this.api.delete(`fulfilment`, `admin/fees/${this.fee.ID}`).subscribe((fee:any)=>{
      this.message.showSnack(`Succesfully removed fee`);
      this.deleteCheck(fee);
      this.getFees();
    });
  }

  setLimit(limit){}
  next(){};
  previous(){};

}
