<div class="stores">

<div class="tabs">
  <div (click)='selectTab(i)' *ngFor="let tab of tabs; let i = index" [class]="selectedTab == tab.name ? 'selectedTab' : '' ">
    <div id='tab'>
      <p id='tab-name'>{{tab.name}}</p>
    </div>
  </div>
</div>

<app-detail *ngIf="selectedTab == 'detail' "></app-detail>
<app-retailer-products *ngIf="selectedTab == 'products' "></app-retailer-products>
<app-retailer-stores *ngIf="selectedTab == 'stores' "></app-retailer-stores>
<app-retailer-admins *ngIf="selectedTab == 'admins' "></app-retailer-admins>

</div>
