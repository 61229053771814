<div *ngIf='delete' id="shade"></div>

<!-- <div class="search">
      <input matInput (input)='searchFaqs($event.target.value)' placeholder="search" #input>
  </div> -->

  <div class='stores'>

    <div *ngIf=!edit>

    <table  mat-table [dataSource]='faqs' matSort>

      <ng-container matColumnDef="question">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Question </th>
        <td mat-cell *matCellDef="let faq" id='title'><p>  {{faq.title}} </p> </td>
      </ng-container>

      <ng-container matColumnDef="body">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Answer </th>
        <td mat-cell *matCellDef="let faq" ><p id='faq'> {{faq.body}} </p></td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
        <td mat-cell *matCellDef="let faq" id='edit' (click)='toggleEdit(faq)'> <mat-icon>edit</mat-icon> </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
        <td mat-cell *matCellDef="let faq" (click)='deleteCheck(faq)'> <mat-icon>delete_outlined</mat-icon> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="Columns"></tr>
      <tr mat-row  
          class='action'
          *matRowDef="let faq; columns: Columns;"></tr>
    </table>

  </div>

  <div *ngIf='edit'>
    <form>
      <span>
        <h3>Update FAQ</h3>
        <mat-icon class='action' (click)='toggleEdit()'>close</mat-icon>
      </span>
      <mat-form-field>
        <mat-label>Question</mat-label>
        <input type="text" matInput name='title' [(ngModel)]="faq.title" >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Answer</mat-label>
        <textarea type='text' name='body' matInput  [(ngModel)]='faq.body' cols="10" rows="8"></textarea>
      </mat-form-field>
      <div class="btn">
        <button class='button' mat-raised-button (click)='updateFaq()'>Update FAQ</button>
      </div>
    </form>
  </div>

    <div class='mobile'>
      <mat-expansion-panel hideToggle  *ngFor='let faq of faqs '>
        <mat-expansion-panel-header >
          <mat-panel-title>
            {{faq.title}}
          </mat-panel-title>
        </mat-expansion-panel-header>
          <span class="flex">
            <mat-title>Heading:</mat-title>
            <p>{{faq.heading}}</p>
          </span>
          <span class="flex">
            <mat-title>Answer:</mat-title>
            <p>	{{faq.body}} </p>
          </span>
        </mat-expansion-panel>
    </div>

    <div id="pager" >

      <button mat-flat-button class='button'id="{{auth.expand ? 'create-expand': 'create'}}" routerLink='/faq-create'>New</button>

      <div class="page"> 

      <div> Per page:</div>
      <div (click)="setLimit(10)" class="limit" >10</div>
      <div (click)="setLimit(15)" class="limit" >15</div>
      <div (click)="setLimit(20)" class="limit" >20</div>
      <div (click)="setLimit(50)" class="limit" >50</div>
      <div>Page:</div>
      <mat-icon (click)='previous()'>skip_previous</mat-icon>
      <div>{{page}}</div>
        <mat-icon (click)='next()'>skip_next</mat-icon>
        <div> </div>
      </div>
    </div>

    <div id="dialog">
      <div class='dialog' *ngIf='delete'>
        <span>
          <p>Are you sure you want to remove this item?</p>
          <mat-icon class='action' (click)='deleteCheck()'>close</mat-icon>
        </span>
        <button mat-flat-button class='button' (click)='deleteFaq()' >Confirm</button>
      </div>
  </div>

