import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from './../../../services/api.service'
import { MessagesService } from './../../../services/messages.service'
import { environment as devEnv } from '../../../../environments/environment'

@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss']
})
export class ProductCreateComponent implements OnInit {
  Columns = ['icon', 'name', 'add']


  constructor(public api: ApiService,
              public message: MessagesService,
              public route: ActivatedRoute,
              public router: Router,) { }

              
  product
  retailers
  product_id
  categories
  allCategories
  selectedValue
  
  
  page          = 1
  limit         = 15
  shade         = false
  search        = ''
  productInfo   = false
  product_type  = 'standard'
  product_image = `assets/placeholders/placeholder-img.png`


  imageFile: {resource: string, file: any, name: string}


  ngOnInit(): void {
    this.getSilos()
    this.getRetailers()
    this.getCategories()
  }

  selectProductType(){
    if(this.product_type == 'market'){
      this.silo_id = 5
      this.getCategories()
    } else {
      this.silo_id = 0
      this.silo = {}
    }
  }
  // ============================
  //   GET RETAILERS FOR PRODUCT
  // ============================

  getRetailers(){
    this.api.getList('catelog', `retailers?page=${this.page}&per_page=${100}&search=${this.search}`).subscribe((retailers:any) => {
      this.retailers = retailers
    })
  }



  // ===================
  //   PRODUCT DETAILS
  // ===================

  price
  width
  height
  length
  weight
  max_qty

  // testing for non numerical values 
  dimensionsRoundUp(){
    var regExp = /[a-zA-Z]/g
    var specialChars = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/

    // test for any special or alpabetical characters
    if(this.max_qty){
      if(regExp.test(this.max_qty)){
        this.max_qty = this.max_qty.replace(regExp, "")
      } else if(specialChars.test(this.max_qty)){
        this.max_qty = this.max_qty.replace(specialChars, "")
      }
    }
    

    if(this.length){
    this.length = JSON.stringify(Math.floor(this.length))
    }
    if(this.width){
      this.width  = JSON.stringify(Math.floor(this.width))
    }
    if(this.height){
      this.height = JSON.stringify(Math.floor(this.height))
    }
  }


  submitProductInfo(i){
    
    if(i.rebate == ''){
      i.rebate = 0
    }

    if(i.sku_code == ''){
      i.sku_code = 'sku code'
    }

    let product = [
      {
        name: i.name,
        description: i.description,
        price: i.price,
        size: i.size,
        barcode: i.barcode,
        sku_code: i.sku_code,
        linked_key: JSON.stringify(Date.now()),
        retailer_id: 0,
        category_id: i.category.id,
        weight: 1,
        max_quantity: 100,
        otc: i.otc
      }
    ]

    if(this.max_qty){
      product[0].max_quantity = JSON.parse(this.max_qty)
    }

    if(this.weight){
      product[0].weight = JSON.parse(this.weight)
    }

    if(this.product_type == 'market'){
      product[0]['height']      = i.height
      product[0]['length']      = i.length
      product[0]['width']       = i.width

      product[0]['retailer_id'] = i.retailer.id
    }

    this.product = product
    if(product[0].name && product[0].price && product[0].barcode){

      // check for additional required fields if market product - weight, heigth, length, width
      if(this.product_type == 'standard'){
        this.productInfo = true  
      } else if(this.product_type == 'market' && this.width && this.length && this.height && this.weight){
        this.productInfo = true
      }

    } 
  }

  backToProductInfo(){
    this.productInfo = false
  }

  // ====================
  //   CREATE PRODUCT
  // ====================

  submitProduct(){
    // console.log(this.product)
    this.api.post('catelog', `products`, this.product).subscribe((data: any) => {
      // console.log(data)
      this.shade = true
      this.product_id = data[0].id
      this.message.showSnack(`Successfully created Product ${data[0].name}`)

      let parentCategory = [{
        product_id: JSON.parse(this.product_id),
        category_id: this.category.id
      }]

      this.getSubCategories()

      // add main category to productcategories table
      this.api.post('catelog', 'productcategories', parentCategory).subscribe((categories: any) => {
      }, error => {
        this.message.showSnack(error.error)
      })

      this.imageUpload()
                
    }, error => {
      this.message.showSnack(error.error)
    })   
  }

  // =====================
  //   PRODUCT IMAGE
  // =====================

  imageAttempt = false

  imagesPreview(event) {
    if (event.target.files && event.target.files[0]) {
        let reader = new FileReader()

        reader.onload = (_event: any) => {
            this.imageFile = {
                resource: _event.target.result,
                file: event.srcElement.files[0],
                name: event.srcElement.files[0].name
            }
            this.imageFile.name = 'image.png'
            this.product_image = this.imageFile.resource
        }
        this.imageAttempt = true
        reader.readAsDataURL(event.target.files[0])
    }
  }

  imageUpload(){
    setTimeout(() => {
      this.message.showSnack('uploading image')
    }, 4000)
    var myHeaders = new Headers()

    myHeaders.append("Authorization", "Bearer " + this.api.token)

    var formdata = new FormData()

    formdata.append("file", this.imageFile.file, this.imageFile.name)
    formdata.append("body", "{ \"resource\":\"products\", \"resource_id\":"+ this.product_id +",\"x_dim\":1, \"y_dim\":1}")
    // console.log("body", "{ \"resource\":\"products\", \"resource_id\":"+ this.product_id +",\"x_dim\":1, \"y_dim\":1}")

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata
    }

    // Dev environment

    if(!devEnv.production){
    fetch("https://api.api-dev.quench.org.za/catalogue/v1/image", requestOptions)
    .then(response => response.text())
    .then(result => {
      setTimeout(() => {
        this.message.showSnack('Image Uploaded')
        this.shade = false
        // this.router.navigate([`/products/${this.product_id}`])
        this.addCategories = true

      }, 9000)
    })
      
    .catch(error => console.log('error', error))
    
    }

    // production environment
    else if(devEnv.production){
      fetch("https://api.quench.org.za/catalogue/v1/image", requestOptions)
      .then(response => response.text())
      .then(result =>
        setTimeout(() => {
          this.message.showSnack('Image Uploaded')
          this.shade = false
          // this.router.navigate([`/products/${this.product_id}`])
          this.addCategories = true
        }, 9000))
      .catch(error => console.log('error', error))
    }
  }


  // ======================
  //     CATEGORIES
  // ======================

  silo
  silos
  silo_id 
  category

  silo_name      = 'liquor'
  extraCount     = 1
  categoriesArr  = []
  addCategories  = false
  
  
  getSilos(){
    this.api.getList('catelog', 'silos').subscribe((silos: any) => {

      for(var i = 0; i < silos.length; i++){
        if(silos[i].name == 'Market'){
          silos.splice(i, 1)
        }
      }

      this.silos = silos
    }, error => {
      this.message.showSnack(error.error)
    })
  }

  selectSilo(silo){
    this.silo_name = silo.name
    this.silo_id = silo.id
    this.getCategories()
}


  skipCategoryAdd(){
    this.router.navigate([`/products/${this.product_id}`])
  }

  getCategories(){
    if(this.selectedValue || this.silo_id){
    if(this.search.length == 0){
      this.api.getList("catelog",`silos/${this.silo_id}/categories?page=${this.page}&per_page=${10}`).subscribe((data:any)=>{
      this.categories = data
    }) 
    } else {
      this.api.getList("catelog",`categories?search=${this.search}&page${this.page}&per_page=${this.limit}`).subscribe((data:any)=>{
        this.categories = data
      })  
    }
  }
   }
   
   getSubCategories(){
    this.api.getList("catelog",`categories/${this.category.id}/categories?per_page=${100}`)
    .subscribe((data:any)=>{
      this.categories = data
    })
  }


   toggleSelection(chip) {
    chip.toggleSelected()

    var categoryRemove = this.categoriesArr.map(item => item.id).indexOf(chip.value.id)

    if(categoryRemove !== -1){
      this.categoriesArr.splice(categoryRemove, 1)
    } else if(categoryRemove === -1 && this.categoriesArr.length < 10){
      this.categoriesArr.push(chip.value)
    } else if(this.categoriesArr.length == 10){
      this.extraCount++
    }
 }



  addProductCategories(){
    let categories = []
    this.categoriesArr.forEach(item =>{
      let category = {
        product_id: JSON.parse(this.product_id),
        category_id: item.id
      }
      categories.push(category)
    })


    this.api.post('catelog', 'productcategories', categories).subscribe((categories: any) => {
      this.message.showSnack('Succesfully added categories to Product')
      this.router.navigate([`/products/${this.product_id}`])
    }, error => {
      this.message.showSnack(error.error)
    })
  }

}

