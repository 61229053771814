import { ApiService }         from 'src/app/services/api.service'
import { AuthService }        from 'src/app/services/auth.service'
import { Component, OnInit }  from '@angular/core'

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.scss']
})
export class SchedulingComponent implements OnInit {

  constructor(private api: ApiService,
              private auth: AuthService) { }

  ngOnInit(): void {
  }

}
