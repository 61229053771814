<div class="create-card">
  <div class="header">
    <h3>Create New Asset</h3>
    <mat-icon routerLink="/assets">clear</mat-icon>
  </div>

  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="firstForm">
      <form [formGroup]="firstForm">
        <mat-form-field appearance="outline" class="field">
          <mat-label>Asset name</mat-label>
          <input
            type="text"
            matInput
            ngModel
            placeholder="Jonway 125cc"
            name="name"
            required
            formControlName="name"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="field">
          <mat-label>Registration number</mat-label>
          <input
            type="text"
            matInput
            ngModel
            placeholder="cy123"
            name="registration_number"
            required
            formControlName="registration_number"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="field">
          <mat-label>Description</mat-label>
          <input
            type="text"
            matInput
            ngModel
            placeholder="Scooter"
            name="description"
            formControlName="description"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="field">
          <mat-label>Value</mat-label>
          <input
            type="number"
            matInput
            ngModel
            placeholder="Scooter"
            name="value"
            required
            formControlName="value"
          />
          <mat-error
            *ngIf="
              firstForm.controls['value'].invalid &&
              (firstForm.controls['value'].dirty ||
                firstForm.controls['value'].touched)
            "
            >Please enter a valid value
          </mat-error>
          <span matPrefix class="code">zar </span>
        </mat-form-field>

        <mat-form-field class="field" appearance="outline">
          <mat-label>Asset Type </mat-label>
          <mat-select
            ngModel
            name="asset_type_id"
            class="select-panel"
            (selectionChange)="pickAssetType($event.value)"
            required
            formControlName="asset_type_id"
          >
            <mat-select-trigger class="selection">
              &nbsp;{{ type.name }}
            </mat-select-trigger>
            <mat-option *ngFor="let type of asset_types" [value]="type">
              {{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="save">
          <button
            mat-flat-button
            class="button"
            matStepperNext
            (click)="submitAsset()"
          >
            submit
          </button>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>
