<div class="add-card"  >
  
    <div class="header">
      <h3>add store/stores to mall</h3>
      <mat-icon (click)='closeDialog()'>clear</mat-icon>
    </div>

    <div class="top">
        <div class="search" >
            <mat-icon (click)='getStores()' matTooltip='search' class='search-icon' [style.left]="inputTouch ? '-20px' : '10px' ">search </mat-icon>
            <mat-icon *ngIf='search' (click)='clearSearch()'  matTooltip='clear' class='clear-icon'>clear</mat-icon>
            <input matInput (keydown.enter)='getStores()' [(ngModel)]="search" placeholder="Search"  #input (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
        </div>
    </div>
    

      
        <table  mat-table [dataSource]='stores' matSort >

            <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let store" routerLink='/stores/{{store.id}}' id='store-name'> <p>{{store.name}} </p></td>
            </ng-container>

            <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
            <td mat-cell *matCellDef="let store" routerLink='/stores/{{store.id}}'> 
                <p> {{store.physical_address}} </p>
            </td>
            </ng-container>

            <ng-container matColumnDef="add">
            <th mat-header-cell  *matHeaderCellDef> add </th>
            <td mat-cell  *matCellDef="let store" > 
                <mat-icon (click)='toggleSelection(store)'>add_circle</mat-icon>
            </td>
        </ng-container>

            <tr mat-header-row *matHeaderRowDef="Columns"></tr>
            <tr mat-row class='action'  *matRowDef="let store; columns: Columns;"></tr>
        </table>


        <div class="add-list">
            <mat-chip-list aria-label="Fish selection">
                <p *ngIf='storesArr.length > 0'>adding :</p>
            <mat-chip id='selectedCat' #c="matChip" id='chosen-category' [value]="store" *ngFor='let store of storesArr'  >{{store.name}}
                <mat-icon matChipRemove (click)='toggleSelection(store)'>cancel</mat-icon>
            </mat-chip>
            </mat-chip-list>
        </div>


    <div class="btn">
        <button mat-flat-button class='button' (click)='addStores()'>Confirm</button>
    </div>
  
</div>
  

