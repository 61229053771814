<!-- <ngx-star-rating formControlName="totalRating" [id]="'rating'"></ngx-star-rating> -->

<h4 *ngIf=reviews> Total Rating: 
    <p  class='rating' *ngIf=reviews [style.color]="totalRating < 4 ? 'red' : 'green' ">{{totalRating | number:"1.1-1" }}</p>
    <p>({{total_ratings}} reviews)</p>
</h4>

<div class="stars">
    <mat-icon *ngIf='half' >star_half</mat-icon>
    <mat-icon *ngIf='!half'>star</mat-icon>
    <mat-icon *ngIf='half_one' >star_half</mat-icon>
    <mat-icon *ngIf='!half_one  && totalRating > 1'>star</mat-icon>
    <mat-icon *ngIf='half_two' >star_half</mat-icon>
    <mat-icon *ngIf='!half_two  && totalRating > 2'>star</mat-icon>
    <mat-icon *ngIf='half_three' >star_half</mat-icon>
    <mat-icon *ngIf='!half_three && totalRating > 3'>star</mat-icon>
    <mat-icon *ngIf='half_four ' >star_half</mat-icon>
    <mat-icon *ngIf='!half_four && totalRating > 4'>star</mat-icon>
</div>


<table  mat-table [dataSource]='reviews' matSort>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
        <td mat-cell *matCellDef="let review" routerLink='/users/{{review.uid}}'>
            <p *ngIf='review.name'> {{review.name}} </p>
            <p *ngIf='review.name == " " || !review.name'  matTooltip={{review.uid}}> Customer </p>
        </td>
    </ng-container>

    <ng-container matColumnDef="platform">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Platform </th>
        <td mat-cell *matCellDef="let review" > {{review.platform}} </td>
    </ng-container>

    <ng-container matColumnDef="platform">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Platform </th>
        <td mat-cell *matCellDef="let review" > {{review.platform}} <br><br>{{review.version}}</td>
    </ng-container>

    <ng-container matColumnDef="comment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > <p class='comment'>Comment</p> </th>
        <td mat-cell *matCellDef="let review"> <p class='comment'>{{review.comment}}</p> </td>
    </ng-container>

    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let review">
            <p>{{review.CreatedAt | date }}</p>
        </td>
    </ng-container>

    <ng-container matColumnDef="rating">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class='rating'> Rating </th>
        <td mat-cell *matCellDef="let review">
            <p class='rating' [style.color]="review.rating < 4 ? 'red' : '' " >{{review.rating}}</p>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row 
        class='action'
        *matRowDef="let notification; columns: columns;">
    </tr>
</table>

<div id="pager" >
    <div class="page"> 

    <div> Per page:</div> 
    <span class="silo-filter pagination">
      <button mat-button [matMenuTriggerFor]="paginate">
        <span>{{limit}}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #paginate="matMenu" >
        <button mat-menu-item (click)="setLimit(10)">10</button>
        <button mat-menu-item (click)="setLimit(20)">20</button>
        <button mat-menu-item (click)="setLimit(50)">50</button>
      </mat-menu>
    </span>

    <div (click)='start()' matTooltip="Skip to start" class='action' *ngIf='page > 1'>back to start</div>
    <p (click)='previous()' class='action'>previous</p>
    <div>{{page}}</div>
      <p (click)='next()' class='action'>next</p>
      <div> </div>
    </div>
  </div>

