import {
  Component,
  HostListener,
  OnInit,
  EventEmitter,
  Inject,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { PagerService } from '../../../services/pager.service';
import { MessagesService } from '../../../services/messages.service';

import { PusherService } from '../../../services/pusher.service';
import { AuthService } from 'src/app/services/auth.service';
import { Subject } from 'rxjs';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'app-carts',
  templateUrl: './carts.component.html',
  styleUrls: ['./carts.component.scss'],
})
export class CartsComponent implements OnInit {



  // =====================
  // CHECK USER INACTIVITY
  // =====================

  timeoutId;
  userInactive: Subject<any> = new Subject();
  sub;
  

  @HostListener('window:keydown')
  @HostListener('window:mousedown')
  @HostListener('window:mousemove')
  @HostListener('window:mousedown')
  @HostListener('window:wheel')
  @HostListener('window:mouseup')
  checkUserActivity() {
    clearTimeout(this.timeoutId);
    this.checkTimeOut();
  }

  checkTimeOut() {
    this.timeoutId = setTimeout(() => this.userInactive.next(), 60000 * 30);
  }

  subscribeToInactiveUser() {
    this.sub = this.userInactive.subscribe((message) => {
      this.reActivate();
    });
  }

  reActivate() {
    this.sub.unsubscribe();
    this.unListPusher();
    const dialog = this.dialog.open(RedirectInactivityDialog, {
      width: '550px',
      height: '200px',
    });

    dialog.afterClosed().subscribe(() => {
      this.subscribeToInactiveUser()
      this.listToPusher();
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  
  // ============================
  //    CART AND TABLE LABELS
  // ============================
  
  background = `var(--quenchPink)`;
  isDisChemUser = false;
  
  Columns = [
    'placed',
    'completed',
    'schedule',
    'store',
    'customer',
    'order_num',
    'driver',
    'status',
    'cancel_reason',
    'origin',
    'phone',
    'distance',
    'amount',
  ];
  statuses = [
    'ALL',
    'PLACED',
    'REPLACE',
    'PAID',
    'READY FOR COLLECTION',
    'DISPATCHED',
    'COMPLETED',
    'CANCELLED',
    'RETURNED',
    'FAILED',
  ];

  // =================
  //   SEARCH CHECK
  // =================
  onFocus() {
    this.inputTouch = true;
  }

  onBlur() {
    this.inputTouch = false;
  }

  clearSearch() {
    this.page = 1;
    this.search = '';
    if (this.isDisChemUser) {
      this.getDisChemCarts();
    } else {
      this.getCarts();
    }
    localStorage.setItem('cartsPage', JSON.stringify(this.page));
  }

  search = '';
  inputTouch = false;

  // =================
  //  CART VARIABLES
  // =================

  empty;
  status;
  page = 1;
  limit = 10;
  carts = [];
  astatus = 'ALL';
  mobileSearch = false;
  silos;
  silo_id = '0';
  silo_name = 'Silos';

  constructor(
    public api: ApiService,
    public pager: PagerService,
    private pusherService: PusherService,
    public message: MessagesService,
    public auth: AuthService,
    public dialog: MatDialog
  ) {
    this.subscribeToInactiveUser();
  }

  ngOnInit(): void {

    // check to see if silo filter is applied
    if (localStorage.getItem('carts-silo')) {
      this.silo_id = JSON.parse(localStorage.getItem('carts-silo')).id;
      this.silo_name = JSON.parse(localStorage.getItem('carts-silo')).name;
    }

    if (localStorage.getItem('cartsPage')) {
      this.page = JSON.parse(localStorage.getItem('cartsPage'));
    }

    if (localStorage.getItem('limit')) {
      this.limit = JSON.parse(localStorage.getItem('limit'));
    }

    if (localStorage.getItem('cartsStatus')) {
      this.astatus = localStorage.getItem('cartsStatus');
    } else {
      this.astatus = 'ALL';
    }

    if (localStorage.getItem('cartSearch')) {
      this.search = localStorage.getItem('cartSearch');
    }

    if (
      this.auth.dis_chem_role ||
      (localStorage.getItem('dis-chem-role') &&
        localStorage.getItem('dis-chem-role') == 'true')
    ) {
      this.isDisChemUser = true;
      this.background = `#35c27e`;

      this.getDisChemCarts();
    } else {
      this.getSilos();
    }

    this.listToPusher();
  }

  // =======================
  //  GET SILOS FOR FILTER
  // =======================

  getSilos() {
    this.api.getList('catelog', 'silos').subscribe(
      (silos: any) => {
        let all = {
          id: 0,
          name: 'All',
        };
        silos.unshift(all);
        this.silos = silos;
        this.getCarts();
      },
      (error) => {
        this.message.showSnack(error.error);
      }
    );
  }

  selectSilo(silo) {
    if (silo.id == 0) {
      this.silo_name = 'Silos';
      this.silo_id = '0';
    } else {
      this.silo_id = JSON.stringify(silo.id);
      this.silo_name = silo.name;
    }
    this.getCarts();

    localStorage.setItem('carts-silo', JSON.stringify(silo));
  }

  // =================
  //  FILTER STATUSES
  // =================

  setStatus(status) {
    this.search = '';
    this.page = 1;
    localStorage.removeItem('cartSearch');
    localStorage.setItem('cartsPage', JSON.stringify(this.page));
    this.astatus = status;

    if (this.isDisChemUser) {
      this.getDisChemCarts();
    } else {
      this.getCarts();
    }
    localStorage.setItem('cartsStatus', status);
  }

  // =================
  //       CARTS
  // =================

  retries = 0;

  getCarts() {
    localStorage.setItem('cartSearch', this.search);
    let state = this.astatus;

    if (this.astatus == 'ALL') {
      state = '';
    }

    if (this.silo_id == '0') {
      this.silo_id = '';
    }

    this.api
      .getList(
        'fulfilment',
        `admin/carts?status=${state}&page=${this.page}&per_page=${this.limit}&search=${this.search}&silo_id=${this.silo_id}`
      )
      .subscribe(
        (carts: any) => {
          // console.log(carts)

          if (carts.data.length === 0 && this.page > 1) {
            this.page--;
            this.getCarts();

            localStorage.setItem('cartsPage', JSON.stringify(this.page));
          } else if (carts.data.length === 0 && this.search.length > 1) {
            this.message.showSnack('No results found');
          }

          this.carts = carts.data;
        },
        (error) => {
          this.message.showSnack(error.error);
          if (this.retries < 3 && error.status !== 401) {
            this.getCarts();
            this.retries++;
          }
        }
      );
  }

  // =================
  //  DISCHEM  CARTS
  // =================

  getDisChemCarts() {
    localStorage.setItem('cartSearch', this.search);
    let state = this.astatus;

    if (this.astatus == 'ALL') {
      state = '';
    }

    if (this.astatus == 'DELIVERED') {
      state = 'COMPLETED';
    }

    if (this.silo_id == '0') {
      this.silo_id = '';
    }

    this.api
      .getList(
        'fulfilment',
        `dischem/carts?status=${state}&page=${this.page}&per_page=${this.limit}&search=${this.search}`
      )
      .subscribe(
        (carts: any) => {
          // console.log(carts)

          if (carts.data.length === 0 && this.page > 1) {
            this.page--;
            this.getCarts();

            localStorage.setItem('cartsPage', JSON.stringify(this.page));
          } else if (carts.data.length === 0 && this.search.length > 1) {
            this.message.showSnack('No results found');
          }

          this.carts = carts.data;
        },
        (error) => {
          this.message.showSnack(error.error);
          if (this.retries < 3 && error.status !== 401) {
            this.getCarts();
            this.retries++;
          }
        }
      );
  }

  searchReset() {
    this.page = 1;
    if (this.isDisChemUser) {
      this.getDisChemCarts();
    } else {
      this.getCarts();
    }
    localStorage.setItem('cartsPage', JSON.stringify(this.page));
  }

  // =================
  //   LIVE UPDATE
  // =================

  listToPusher() {
    this.pusherService.channel.bind(
      'cart-status-event',
      (data) => {
        if (this.isDisChemUser) {
          this.getDisChemCarts();
        } else {
          this.getCarts();
        }
      },
      (error) => {
        // console.log(error)
      }
    );
  }

  unListPusher() {
    this.pusherService.channel.unbind('cart-status-event');
  }

  // =================
  //   PAGINATION
  // =================

  setLimit(limit) {
    this.limit = limit;

    if (this.isDisChemUser) {
      this.getDisChemCarts();
    } else {
      this.getCarts();
    }

    localStorage.setItem('limit', JSON.stringify(limit));
  }

  next() {
    if (this.carts.length >= this.limit) {
      this.page++;

      if (this.isDisChemUser) {
        this.getDisChemCarts();
      } else {
        this.getCarts();
      }
    } else {
      this.message.showSnack('No more carts to show');
    }

    localStorage.setItem('cartsPage', JSON.stringify(this.page));
  }

  previous() {
    if (this.page > 1) {
      this.page--;

      if (this.isDisChemUser) {
        this.getDisChemCarts();
      } else {
        this.getCarts();
      }
    }

    localStorage.setItem('cartsPage', JSON.stringify(this.page));
  }

  start() {
    this.page = 1;
    if (this.isDisChemUser) {
      this.getDisChemCarts();
    } else {
      this.getCarts();
    }
    localStorage.setItem('cartsPage', JSON.stringify(this.page));
  }
}

// ============================
//    ASSET REMOVE DIALOG
// ============================

@Component({
  selector: 'inactive-dialog',
  templateUrl: 'inactivity-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class RedirectInactivityDialog {
  inputTouch = false;
  heading = `30 minutes or more`;
  body = `Please refresh the page to continue`;

  // get section info after success
  onAdd = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { asset },
    public dialogRef: MatDialogRef<RedirectInactivityDialog>
  ) {}

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }
}
