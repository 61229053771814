    <div class="header">
      <h3>Create New Asset type</h3>
      <mat-icon (click)="closeDialog()" class="action">clear</mat-icon>
    </div>
  <hr>
    <mat-horizontal-stepper [linear]="true" #stepper>
      <mat-step [stepControl]="firstForm">
        <form [formGroup]="firstForm">
          <mat-form-field appearance="outline" class="field">
            <mat-label>Asset type name</mat-label>
            <input
              type="text"
              matInput
              ngModel
              placeholder="Scooter"
              name="name"
              required
              formControlName="name"
            />
          </mat-form-field>
  
          <mat-form-field appearance="outline" class="field">
            <mat-label>Asset description</mat-label>
            <input
              type="text"
              matInput
              ngModel
              placeholder="125cc"
              name="description"
              formControlName="description"
            />
          </mat-form-field>
  
          <div class="save">
            <button
              mat-flat-button
              class="button"
              matStepperNext
              (click)="submitAssetType()"
            >
              submit
            </button>
          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  