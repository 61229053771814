<div >

<div class="back action" routerLink="/carts">
    <mat-icon>arrow_back</mat-icon>
    <p>orders</p>
</div>

<div class="tabs">
    <div (click)='selectTab(i)' *ngFor="let tab of tabs; let i = index" [class]="selectedTab == tab.name ? 'selectedTab' : '' " >
      <div id='tab'>
        <p id='tab-name'>{{tab.name}}</p>
      </div>
    </div>
  </div>

  <span *ngIf='cart' class='navbar' >

    <div class="order">
      <h3><span class=order-tag> order </span>#{{cart.order_number}}</h3>
      <p class="order-status">{{cart.status}}</p>
      <span class="order-date">
          |
          <mat-icon>event</mat-icon>
          <p>{{cart.order_date}}</p>
          <p> &nbsp; -  {{cart.origin}}</p>
      </span>
      <div class="scheduled-tag" *ngIf="cart.is_schedule"><span class="tag"  *ngIf="cart.is_schedule">scheduled</span>  <span> | {{cart.schedule_end_time.substring(0,10) }} |</span> {{cart.schedule_start_time.substring(11,16) }} - {{cart.schedule_end_time.substring(11,16) }} </div>
      
      <p class='customer'>{{cart.customer_name}}</p>
      
    </div>

  </span>

  <app-cart-overview *ngIf="selectedTab == 'overview'"></app-cart-overview>
  <app-cart *ngIf="selectedTab == 'shipping & payment'"></app-cart>
  <app-cart-tracking *ngIf="selectedTab == 'actions & tracking'"></app-cart-tracking>
  <app-nucleus-waybill-tracking *ngIf="selectedTab == 'api requests'"></app-nucleus-waybill-tracking>

  <!-- take out api requests -->



</div>