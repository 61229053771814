<div class="heading">
    <div></div>
    <h4>Edit Branch Map</h4>
    <mat-icon (click)="closeDialog()">clear</mat-icon>
  </div>
  
  <div class="edit-fields" >
    <mat-form-field class="field" appearance="outline" >
      <mat-label>Loop ID</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="loopID"
        placeholder="1AbC"
        name="loop_id"
        required
      />
    </mat-form-field>
    <mat-form-field class="field" appearance="outline">
        <mat-label>Branch Partner ID</mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="partnerID"
          placeholder="Bc2"
          name="partner_branch_id"
          required
        />
      </mat-form-field>
  </div>
  
  <div class="save">
    <button mat-flat-button class="button cancel" (click)="closeDialog()">
      Cancel
    </button>
    <button
    mat-flat-button
    class="button"
    (click)="update()"
  >
    Save
  </button>
  </div>
  