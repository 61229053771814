import { Component, OnInit } from '@angular/core'

import { MessagesService } from '../../../services/messages.service'
import { Router ,ActivatedRoute} from '@angular/router'
import { ApiService } from '../../../services/api.service'
import { PagerService } from '../../../services/pager.service'
import { PusherService } from '../../../services/pusher.service'

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  Columns = ['name', 'price', 'quantity', 'picked', 'oos', 'time']
  Actions = ['action', 'item', 'name']
  nucleusTracking = ['action']


  id
  slips
  order
  origin
  actions
  statuses
  count = 0
  cart = []
  destination
  progressValue
  nucleusActions


  picker_uid
  statusoverride

  market_cart         = false
  paymentItems        = []
  productItems        = []
  pickedItems         = []
  unfulfilledItems    = []
  out_of_stock_items  = []

  
  
  constructor(
    public messages:MessagesService,
    private router: Router,
    private api: ApiService,
    public pager:PagerService,
    private route:ActivatedRoute,
  ) { }

  ngOnInit(): void {
      this.id = this.route.snapshot.paramMap.get('id')
      this.getOrder()
  }

  // ==================
  //      ORDER
  // =================

  getOrder(){
    this.api.get('fulfilment', `admin/orders`, this.id).subscribe((order: any) => {
      // console.log(order)
      this.order = order.order
      this.cart = order.items
      this.slips = order.slips
      this.nucleusActions = order.tracking

      this.destination = {
        lat: order.order.customer_latitude,
        lng: order.order.customer_longitude
      }
      this.origin = {
        lat: order.order.store_latitude,
        lng: order.order.store_longitude
      }


      if(order.order.silo_id == 5){
        this.market_cart = true
      }

      order.items.forEach(item => {
        // console.log(item)
        if(item.item_type == 'product'){
          this.pickedItems.push(item)
        } else if(item.item_type == 'out_of_stock'){
          this.out_of_stock_items.push(item)
        }

    })

      // get order statuses
      if(this.order.cart_id){
      this.api.get('fulfilment', `admin/carts/`, this.order.cart_id).subscribe((data:any) => {
        let count = 0
        data.statuses.forEach(status => {
          if(status.time){
            count++
            let value = 100/8*count
            this.progressValue = value
          }
        })
        this.statuses = data.statuses
      })
    }

      // get order actions
    if(this.order.cart_id){
      this.api.getList('fulfilment', `app/carts/${this.order.cart_id}/actions`).subscribe((data:any) => {
      this.actions = data.data
    })
  }

    }, error => {
      this.messages.showSnack(error.error)
      if(this.count < 3){
        if(error.status !== 401){
          this.count++
          this.getOrder()
          this.messages.showSnack(error.error)
        }
      }
    })
  }

  goToLink(url: string){
    window.open(`https://drive.quench.mobi/orders/${url}/fulfilment`, "_blank")
    // console.log(url)
  }


}
