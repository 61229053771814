<div class="search">
  <mat-icon (click)='searchReset()' matTooltip='search' class='search-icon' [style.left]="inputTouch ? '-20px' : '10px' ">search </mat-icon>
  <mat-icon *ngIf='search' (click)='clearSearch()'  matTooltip='clear' class='clear-icon'>clear</mat-icon>
  <input matInput (keydown.enter)='searchReset()' [(ngModel)]="search" placeholder="Search"  #input (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
</div>

  <div class='stores'>

    <div class="silos">
      <div (click)="setSilo(asilo)" *ngFor="let asilo of silos" [class]="asilo.name == silo.name ?  'isBold' : '' ">{{asilo.tag}}</div>
    </div>

    <!-- <div class="retailers">
      <div *ngFor="let asilo of silos" [class]="asilo == silo ?  'isBold' : '' ">{{asilo.tag}}</div>
    </div> -->

    <!-- STORE PRODUCTS -->
      <table  mat-table [dataSource]='stores' matSort>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let store" routerLink='/stores/{{store.id}}' id='store-name'> <p>{{store.name}} </p></td>
        </ng-container>

        <!-- Address Column -->
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
          <td mat-cell *matCellDef="let store" routerLink='/stores/{{store.id}}'> 
            <p> {{store.physical_address}} </p>
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Emal </th>
          <td mat-cell *matCellDef="let store" routerLink='/stores/{{store.id}}'> {{store.email}} </td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
          <td mat-cell *matCellDef="let store" routerLink='/stores/{{store.id}}'> {{store.phone}} </td>
        </ng-container>

        <ng-container matColumnDef="active">
          <th mat-header-cell  *matHeaderCellDef> Active </th>
          <td mat-cell  *matCellDef="let store" >      
            <mat-slide-toggle [(ngModel)]="store.active" (change)="toggleStoreStatus(store)"></mat-slide-toggle>
          </td>
      </ng-container>


        <tr mat-header-row *matHeaderRowDef="Columns"></tr>
        <tr mat-row class='action'  *matRowDef="let store; columns: Columns;"></tr>
      </table>

    <div class='mobile'>
      <mat-expansion-panel hideToggle  *ngFor='let store of stores '>
        <mat-expansion-panel-header >
          <mat-panel-title>
            {{store.name}}
          </mat-panel-title>
        </mat-expansion-panel-header>
          <span class="flex">
            <mat-title>Address:</mat-title>
            <p>{{store.physical_address}}</p>
          </span>
          <span class="flex">
            <mat-title>Email:</mat-title>
            <p>	{{store.email}} </p>
          </span>
          <span class="flex">
            <mat-title>Phone:</mat-title>
            <p>	{{store.phone}}}</p>
          </span>
        </mat-expansion-panel>
    </div>

    <div id="pager" >
      <div class="page"> 

        <button mat-flat-button class='button' id="{{auth.expand ? 'create-expand': 'create'}}" routerLink='/create-store'>New</button>
  
      <div> Per page:</div> 
      <span class="silo-filter pagination">
        <button mat-button [matMenuTriggerFor]="paginate">
          <span>{{limit}}</span>
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #paginate="matMenu" >
          <button mat-menu-item (click)="setLimit(10)">10</button>
          <button mat-menu-item (click)="setLimit(20)">20</button>
          <button mat-menu-item (click)="setLimit(50)">50</button>
        </mat-menu>
      </span>
  
      <div (click)='start()' matTooltip="Skip to start" class='action' *ngIf='page > 1'>back to start</div>
      <p (click)='previous()' class='action'>previous</p>
      <div>{{page}}</div>
        <p (click)='next()' class='action'>next</p>
        <div> </div>
      </div>
    </div>
  

