import { AuthService } from '../../../../services/auth.service';
import { Component, Inject, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { MessagesService } from '../../../../services/messages.service';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-shoppers',
  templateUrl: './shoppers.component.html',
  styleUrls: ['./shoppers.component.scss'],
})
export class ShoppersComponent implements OnInit {
  Columns = ['name', 'store','active', 'last_active', 'email', 'phone', 'more', 'chat', 'map'];

  id;
  page = 1;
  limit = 10;
  store;
  shoppers;
  no_shoppers = false;

  shopper;
  delete = false;

  constructor(
    public messages: MessagesService,
    private api: ApiService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public auth: AuthService,
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    if (localStorage.getItem('shoppersPage')) {
      this.page = JSON.parse(localStorage.getItem('shoppersPage'));
    }

    if (localStorage.getItem('limit')) {
      this.limit = JSON.parse(localStorage.getItem('limit'));
    }

    this.getShoppers();
    this.getStore();
  }

  getStore() {
    this.api.getItem('catelog', 'stores', this.id).subscribe((data: any) => {
      this.store = data;
    });
  }

  getShoppers() {
    this.api
      .getList(
        'fulfilment',
        `stores/${this.id}/shoppers?page=${this.page}&per_page=${this.limit}`
      )
      .subscribe(
        (data: any) => {
          if (data.data.length < 1) {
            this.no_shoppers = true;
          } else {
            this.shoppers = data.data;
            this.no_shoppers = false;
          }
        },
        (error) => {
          alert(JSON.stringify(error));
        }
      );
  }

  // =================
  //   PAGINATION
  // =================

  setLimit(limit) {
    this.limit = limit;
    this.getShoppers();
    localStorage.setItem('limit', JSON.stringify(limit));
  }

  next() {
    if (this.shoppers.length < this.limit) {
      this.messages.showSnack('no more stores to show');
    } else {
      if (this.shoppers.length !== 0) {
        this.page++;
        this.getShoppers();
      }
    }
    localStorage.setItem('shoppersPage', JSON.stringify(this.page));
  }

  previous() {
    if (this.page > 1) {
      this.page--;
      this.getShoppers();
    }
    localStorage.setItem('shoppersPage', JSON.stringify(this.page));
  }

  start() {
    this.page = 1;
    this.getShoppers();
    localStorage.setItem('shoppersPage', JSON.stringify(this.page));
  }

  deleteDialog(shopper) {
    const dialog = this.dialog.open(deleteStoreShopperDialog, {
      width: '550px',
      height: '200px',
      data: {
        shopper: shopper,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.getShoppers();
    });
  }

  addShopperDialog() {
    const dialog = this.dialog.open(addStoreShopperDialog, {
      width: '1200px',
      height: '88vh',
      data: {
        id: this.id,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.getShoppers();
    });
  }

  viewPickerLocation(picker) {
    const dialog = this.dialog.open(viewPickerLocationDialog, {
      width: '1200px',
      height: '550px',
      data: {
        picker: picker,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.getShoppers();
    });
  }
}

@Component({
  selector: 'delete-dialog',
  templateUrl: './shoppers-add-dialog.html',
  styleUrls: ['../../../pocket-mall/mall-stores/store-add.component.scss'],
})
export class addStoreShopperDialog {
  Columns = ['name', 'email', 'active', 'add'];

  id;
  store;
  searchData;

  page = 1;
  limit = 6;
  search = '';
  itemsArr = [];

  inputTouch: boolean = false;
  onAdd = new EventEmitter();

  onFocus() {
    this.inputTouch = true;
  }

  onBlur() {
    this.inputTouch = false;
  }

  clearSearch() {
    this.search = '';
    this.getUsers();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { id },
    public api: ApiService,
    public message: MessagesService,
    public dialogRef: MatDialogRef<addStoreShopperDialog>
  ) {}

  ngOnInit(): void {
    this.id = this.data.id;
    this.getStoreInfo();
    this.getUsers();
  }

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  getUsers() {
    this.api
      .getList(
        'auth',
        `admin/users?page=${this.page}&per_page=${this.limit}&search=${this.search}`
      )
      .subscribe((users: any) => {
        this.searchData = users.users;
      });
  }

  getStoreInfo() {
    this.api.get('catelog', `stores`, this.id).subscribe((data: any) => {
      this.store = data;
    });
  }

  toggleSelection(cat) {
    var userRemove = this.itemsArr.map((item) => item.ID).indexOf(cat.ID);

    if (userRemove !== -1) {
      this.itemsArr.splice(userRemove, 1);
    } else if (userRemove === -1) {
      this.itemsArr.push(cat);
    }
  }

  addPickerToStore() {
    this.itemsArr.forEach((item) => {
      let shopper = {
        uid: item.ID,
        name: item.first_name + ' ' + item.last_name,
        phone: item.phone,
        email: item.email,
        store_name: this.store.name,
      };

      this.api
        .post('fulfilment', `stores/${this.id}/shoppers`, shopper)
        .subscribe((data: any) => {
          this.message.showSnack(`Succesfully added shoppers to store`);
          this.closeDialog();
        });
    });
  }

  next() {
    this.page++;
    this.getUsers();
  }

  previous() {
    if (this.page > 1) {
      this.page--;
      this.getUsers();
    }
  }

  start() {
    this.page = 1;
    this.getUsers();
  }
}

@Component({
  selector: 'delete-dialog',
  templateUrl: '../../../../components/dialogs/delete-dialog.html',
  styleUrls: ['../../../../components/dialogs/delete-dialog.component.scss']
})
export class deleteStoreShopperDialog {
  inputTouch = false;
  heading = `shopper from store`;
  body = `Are you sure you want to delete this shopper from this store? You can always add them again later`;

  shopper;

  // get section info after success
  onAdd = new EventEmitter();

  onFocus() {
    this.inputTouch = true;
  }

  onBlur() {
    this.inputTouch = false;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { shopper },
    private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<deleteStoreShopperDialog>
  ) {}

  ngOnInit(): void {
    this.shopper = this.data.shopper;
  }

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  removeItem() {
    this.api
      .delete('fulfilment', `stores/shoppers/${this.shopper.ID}`)
      .subscribe((data: any) => {
        this.message.showSnack(`succesfully removed shopper from store`);
        this.closeDialog();
      });
  }
}

@Component({
  selector: 'picker-location-dialog',
  templateUrl: 'picker-location/picker-location-dialog.html',
  styleUrls: ['picker-location/picker-location.scss']
})
export class viewPickerLocationDialog {
  inputTouch = false;
  heading = `shopper from store`;
  body = `Are you sure you want to delete this shopper from this store? You can always add them again later`;

  picker;
  zoom = 14

  // get section info after success
  onAdd = new EventEmitter();

  onFocus() {
    this.inputTouch = true;
  }

  onBlur() {
    this.inputTouch = false;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { picker },
    public dialogRef: MatDialogRef<viewPickerLocationDialog>
  ) {}

  ngOnInit(): void {
    this.picker = this.data.picker;
  }

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }


}