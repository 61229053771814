import { ApiService } from './services/api.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';
import { environment as Env, environment } from '../environments/environment';

// JWT
import { JwtModule } from '@auth0/angular-jwt';

// Modules
import { RoleGaurd } from './services/authentication/role.gaurd';
import { FinanceGaurd } from './services/authentication/role.gaurd';

import { AppComponent } from './app.component';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { GoogleChartsModule } from 'angular-google-charts';
import { NgxSpinnerModule } from 'ngx-spinner';
// import { AgmDirectionModule }              from 'agm-direction';   // agm-direction // DO NOT USE THIS UNNECESSARILY - IT COSTS MONEY FOR EACH REQUEST

// Angular Material
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete'; 


// Components
import { CartComponent } from './pages/carts/cart/cart/cart.component';
import { PostComponent } from './pages/posts/post/post.component';
import { FeesComponent } from './components/admin/fees/fees.component';
import { UserComponent } from './pages/users/user/user-page/user.component';
import { PushComponent } from './pages/marketing/push/push.component';
import { FaqsComponent } from './components/admin/fees/faq/faqs/faqs.component';
import { UsersComponent } from './pages/users/users.component';
import { SilosComponent } from './pages/silos/silos/silos.component';
import { CardsComponent } from './pages/users/user/cards/cards.component';
import { PostsComponent } from './pages/posts/posts/posts.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LoginComponent } from './pages/login/login.component';
import { MallsComponent } from './pages/pocket-mall/malls/malls.component';
import { OrderComponent } from './pages/orders/order/order.component';
import { CartsComponent, RedirectInactivityDialog } from './pages/carts/carts/carts.component';
import { StoreComponent } from './pages/stores/store/store.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { AdminsComponent } from './pages/users/admins/admins/admins.component';
import { SectionComponent } from './pages/sections/section/section.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { ProductComponent } from './pages/products/product/product-detail/product.component';
import { AddStoreComponent } from './pages/stores/add-store/add-store.component';
import { SectionsComponent } from './pages/sections/sections-list/sections.component';
import { ProductsComponent } from './pages/products/products/products.component';
import { ShoppersComponent } from './pages/stores/pickers/listPickers/shoppers.component';
import { RetailerComponent } from './pages/retailers/retailer/retailer.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { UserDetailComponent } from './pages/users/user/user-detail/user-detail.component';
import { StorelistComponent } from './pages/stores/storelist/storelist.component';
import { DashboardComponent } from './pages/dashboard/dashboard-navigate/dashboard.component';
import { SuppliersComponent } from './pages/suppliers/suppliers/suppliers.component';
import { MallZonesComponent } from './pages/pocket-mall/mall-zones/mall-zones.component';
import { AddressesComponent } from './pages/users/user/addresses/addresses.component';
import { OpenCartsComponent } from './pages/carts/open-carts/open-carts.component';
import { FaqCreateComponent } from './components/admin/fees/faq/faq-create/faq-create.component';
import { UserOrdersComponent } from './pages/users/user/user-orders/user-orders.component';
import { CategoriesComponent } from './pages/categories/categories/categories.component';
import { CreatePostComponent } from './pages/posts/create-post/create-post.component';
import { MallStoresComponent } from './pages/pocket-mall/mall-stores/mall-stores.component';
import { SchedulingComponent } from './pages/scheduling/scheduling.component';
import { MallDetailComponent } from './pages/pocket-mall/mall-detail/mall-detail.component';
import { MallCreateComponent } from './pages/pocket-mall/mall-create/mall-create.component';
import { SiloCreateComponent } from './pages/silos/silo-create/silo-create.component';
import { FavouritesComponent } from './pages/users/user/favourites/favourites.component';
import { StoreDetailComponent } from './pages/stores/store-detail/store-detail.component';
import { AddRetailerComponent } from './pages/retailers/add-retailer/add-retailer.component';
import { AddCategoryComponent } from './pages/categories/add-category/add-category.component';
import { StoreOrdersComponent } from './pages/stores/store-orders/store-orders.component';
import { TransactionsComponent } from './pages/users/user/transactions/transactions.component';
import { AllGeofencesComponent } from './pages/all-geofences/all-geofences.component';
import { CartOverviewComponent } from './pages/carts/cart/cart-overview/cart-overview.component';
import { StoreDetailsComponent } from './pages/stores/store-details/store-details.component';
import { MallSectionsComponent } from './pages/pocket-mall/mall-sections/mall-sections.component';
import { PromotionalsComponent } from './pages/marketing/promotional/promotionals/promotionals.component';
import { CartTrackingComponent } from './pages/carts/cart/cart-tracking/cart-tracking.component';
import { TradingHoursComponent } from './pages/settings/trading-hours/trading-hours.component';
import { StoreAddressComponent } from './pages/stores/store-address/store-address.component';
import { UserMessagesComponent } from './pages/users/user/user-messages/user-messages.component';
import { OrdersExportsComponent } from './pages/dashboard/exports/exports.component';
import { RetailersListComponent } from './pages/retailers/retailers-list/retailers-list.component';
import { ProductCreateComponent } from './pages/products/product-create/product-create.component';
import { StoreProductsComponent } from './pages/stores/store-products/store-products.component';
import { MallNavigationComponent } from './pages/pocket-mall/mall-navigation/mall-navigation.component';
import { CartNavigationComponent } from './pages/carts/cart/cart-navigation/cart-navigation.component';
import { RetailerDetailComponent } from './pages/retailers/retailer-detail/retailer-detail.component';
import { RetailerStoresComponent } from './pages/retailers/retailer-stores/retailer-stores.component';
import { PublicHolidaysComponent } from './pages/settings/public-holidays/public-holidays.component';
import { RetailerAdminsComponent } from './pages/retailers/retailer-admins/retailer-admins.component';
import { DashboardDailyComponent } from './pages/dashboard/dashboard-daily/dashboard-daily.component';
import { DashboardOrdersComponent } from './pages/dashboard/dashboard-orders/dashboard-orders.component';
import { ProductNavigateComponent } from './pages/products/product/product-navigate/product-navigate.component';
import { StoreGeofencingComponent } from './pages/stores/store-geofencing/store-geofencing.component';
import { StoreCategoriesComponent } from './pages/stores/store-categories/store-categories.component';
import { RetailerProductsComponent } from './pages/retailers/retailer-products/retailer-products.component';
import { StoreTradingHoursComponent } from './pages/stores/store-trading-hours/store-trading-hours.component';
import { ProductCategoriesComponent } from './pages/products/product/product-categories/product-categories.component';
import { CreatePromotionalComponent } from './pages/marketing/promotional/create-promotional/create-promotional.component';
import { RetailerProductCreateComponent } from './pages/retailers/retailer-product-create/retailer-product-create.component';
import { NucleusWaybillTrackingComponent } from './pages/carts/cart/nucleus-waybill-tracking/nucleus-waybill-tracking.component';
import { MatPaginator } from '@angular/material/paginator';
//Dialogs

import { RefundFialog } from './pages/users/user/transactions/transactions.component';
import { ResultsDialog } from './pages/carts/cart/nucleus-waybill-tracking/nucleus-waybill-tracking.component';
import { SalesComponent } from './pages/dashboard/sales/sales.component';
import { StoreAddDialog } from './pages/pocket-mall/mall-stores/mall-stores.component';
import { MarketComponent } from './pages/dashboard/market/market.component';
import { ReviewsComponent } from './pages/marketing/reviews/reviews.component';
import { RemoveMallDialog } from './pages/pocket-mall/malls/malls.component';
import { addressInfoDialog } from './pages/users/user/addresses/addresses.component';
import { OverviewComponent } from './pages/dashboard/overview/overview.component';
import { TrackingComponent } from './pages/dashboard/tracking/tracking.component';
import { editSectionDialog } from './pages/sections/sections-list/sections.component';
import { ProductDimensions } from './pages/products/product/product-detail/product.component';
import { bannerUpdateDialog } from './pages/pocket-mall/mall-detail/mall-detail.component';
import { RemoveSectionDialog } from './pages/sections/sections-list/sections.component';
import { PushCreateComponent } from './pages/marketing/push/push-create/push-create.component';
import { ProductCategoriesAdd } from './pages/products/product/product-categories/product-categories.component';
import { CreateSectionComponent } from './pages/sections/create-section/create-section.component';
import { PromoProductDialog } from './pages/marketing/promotional/create-promotional/create-promotional.component';

import {
  ListdriversComponent,
  RemoveDriverDialog,
  EditDriverDialog,
} from './pages/drivers/listdrivers/listdrivers.component';
import {
  PauseStoreDialog,
  StoreTimeSlots,
} from './pages/stores/store-detail/store-detail.component';
import {
  promoUpdateDialog,
  promoDeleteDialog,
} from './pages/marketing/promotional/promotionals/promotionals.component';
import {
  addCategoryProduct,
  removeCategoryItem,
} from './pages/categories/category/category-products/category-products.component';
import {
  SectionItemsDialog,
  RemoveSectionItemsDialog,
} from './pages/sections/section/section.component';
import {
  addStoreShopperDialog,
  deleteStoreShopperDialog,
  viewPickerLocationDialog,
} from './pages/stores/pickers/listPickers/shoppers.component';
import {
  mallAddSectionsDialog,
  RemoveMallSectionDialog,
} from './pages/pocket-mall/mall-sections/mall-sections.component';
import {
  InvoiceDialog,
  InstructionDialog,
  ManualTripDialog,
  cancelOrderReasonDialog
} from './pages/carts/cart/cart/cart.component';
import {
  CategoryComponent,
  editCategoryDialog,
  categoryImageUpdateDialog,
} from './pages/categories/category/category/category.component';
import {
  deleteStoreProductDialog,
  editStoreProductDialog,
  addStoreProductDialog,
} from './pages/stores/store-products/store-products.component';
import {
  CategorySubcategoriesComponent,
  removeSubCategoryItem,
  addSubCategoryDialog,
} from './pages/categories/category/category-subcategories/category-subcategories.component';
import {
  ProductImage,
  ProductStoresDialog,
  productDeleteDialog,
  ProductEditDialog,
} from './pages/products/product/product-detail/product.component';
import {
  updateWalletDialog,
  updateRolesDialog,
  deleteLoyaltyDialog,
  addLoyaltyDialog,
} from './pages/users/user/user-detail/user-detail.component';
import {
  removePostProductDialog,
  addPostProductsDialog,
  editPostDialog,
  updateImagesDialog,
} from './pages/posts/post/post.component';
import { CategoryProductsComponent } from './pages/categories/category/category-products/category-products.component';
import { CreateDriversComponent } from './pages/drivers/create-drivers/create-drivers.component';
import { ListBatchesComponent, viewPaymentMethodsDialog, createPaymentMethodDialog, editPaymentMethodDialog } from './pages/payments/list-batches/list-batches.component';
import {
  ListPaymentBatchComponent,
  viewBankDetailsDialog,
  
} from './pages/payments/list-payments/list-payment-batch.component';
import { PaymentCaptureComponent } from './pages/payments/payment-capture/payment-capture/payment-capture.component';
import { ListAssetsComponent, createAssetTypedialog, viewAssetTypesDialog, deleteAssetDialog, UpdateAssetDialog, UpdateAsserTypeDialog } from './pages/assets/list-assets/list-assets.component';
import { ListEarningsComponent, viewEarningTypesDialog, createEarningTypeDialog } from './pages/earnings/list-earnings/list-earnings.component';
import { CreateAssetsComponent } from './pages/assets/create-assets/create-assets/create-assets.component';
import { ListReasonsComponent, UpdateReasonDialog, DeleteReasonDialog } from './pages/reasons/list-reasons/list-reasons.component';
import { CreateReasonComponent } from './pages/reasons/create-reason/create-reason.component';
import { ListBanksComponent, UpdateBankDialog } from './pages/banks/list-banks/list-banks.component';
import { CreateBanksComponent } from './pages/banks/create-banks/create-banks.component';
import { CreateEarningComponent } from './pages/earnings/create-earning/create-earning.component';
import { ListContractsComponent, UpdateContractDialog } from './pages/contracts/list-contracts/list-contracts.component';
import { CreateContractComponent } from './pages/contracts/create-contract/create-contract.component';
import { ListAccountsComponent } from './pages/accounts/list-accounts/list-accounts.component';
import { ListUserAccountsComponent } from './pages/accounts/list-user-accounts/list-user-accounts.component';
import { AccountsNavigateComponent } from './pages/accounts/accounts-navigate/accounts-navigate.component';
import { SummaryComponent } from './pages/payments/user-accounts/summary/summary.component';
import { AccountsComponent } from './pages/payments/user-accounts/accounts/accounts.component';
import { EarningsComponent } from './pages/payments/user-accounts/earnings/earnings.component';
import { PaymentsComponent } from './pages/payments/user-accounts/payments/payments.component';
import { AssetsComponent } from './pages/payments/user-accounts/assets/assets.component';
import { NavigateUserAccountsComponent } from './pages/payments/user-accounts/navigate-user-accounts/navigate-user-accounts.component';
import { FinancesComponent } from './pages/users/user/finances/finances.component';
import { ZonesListComponent } from './pages/zones/zones-list/zones-list.component';
import { ZonesMapComponent } from './pages/zones/zones-map/zones-map.component';
import { ZoneComponent } from './pages/zones/zone/zone.component';

import { IncentivesComponent } from './pages/payments/incentives/incentives.component';
import { DistantFeesComponent } from './pages/payments/distant-fees/distant-fees.component';
import { CreateBranchmapComponent } from './pages/branchmap/create-branchmap/create-branchmap.component';
import { ListBranchmapComponent, RemoveBranchMapDialog, UpdateBranchMapDialog } from './pages/branchmap/list-branchmap/list-branchmap.component';
import { EditBranchmapComponent } from './pages/branchmap/edit-branchmap/edit-branchmap.component';

import { PartnersComponent, UpdatePartnerDialog } from './pages/settings/partners/partners.component';
import { PartnersCreateComponent } from './pages/settings/partners-create/partners-create.component';
import { NewZoneComponent } from './pages/zones/new-zone/new-zone.component';
import { ListDeliveriesComponent } from './pages/deliveries/list-deliveries/list-deliveries.component';
import { IncentiveComponent } from './pages/payments/incentive/incentive.component';
import { ListSingleDeliveryComponent } from './pages/deliveries/list-single-delivery/list-single-delivery.component';
import { CreateFinancesComponent } from './pages/users/user/create-finances/create-finances.component';
import { DischemUsersComponent } from './pages/users/dischem-users/dischem-users.component';
 
enableProdMode();

export function tokenGetter() {
  return localStorage.getItem('AUTH_TOKEN');
}

@NgModule({
  declarations: [
    UpdateBranchMapDialog,
    EditBranchmapComponent,
    ListBranchmapComponent,
    CreateBranchmapComponent,
    RefundFialog,
    AppComponent,
    ProductImage,
    PostComponent,
    ZonesListComponent,
    ListDeliveriesComponent,
    ZoneComponent,
    ZonesMapComponent,
    FeesComponent,
    FaqsComponent,
    CartComponent,
    PushComponent,
    InvoiceDialog,
    UserComponent,
    ResultsDialog,
    LoginComponent,
    UsersComponent,
    PostsComponent,
    StoreComponent,
    MallsComponent,
    CartsComponent,
    StoreAddDialog,
    SalesComponent,
    CardsComponent,
    StoreTimeSlots,
    SilosComponent,
    editPostDialog,
    OrderComponent,
    OrdersComponent,
    MarketComponent,
    AdminsComponent,
    SectionComponent,
    ProductComponent,
    SidenavComponent,
    RemoveMallDialog,
    EditDriverDialog,
    ReviewsComponent,
    addLoyaltyDialog,
    PauseStoreDialog,
    ManualTripDialog,
    UpdateBankDialog,
    AddStoreComponent,
    ShoppersComponent,
    SectionsComponent,
    ProductsComponent,
    CategoryComponent,
    deleteAssetDialog,
    ProductEditDialog,
    MessagesComponent,
    OverviewComponent,
    TrackingComponent,
    addressInfoDialog,
    RetailerComponent,
    ProductDimensions,
    editSectionDialog,
    promoDeleteDialog,
    InstructionDialog,
    promoUpdateDialog,
    updateRolesDialog,
    UpdateAssetDialog,
    StorelistComponent,
    MallZonesComponent,
    DashboardComponent,
    SuppliersComponent,
    bannerUpdateDialog,
    RemoveDriverDialog,
    removeCategoryItem,
    editCategoryDialog,
    OpenCartsComponent,
    AddressesComponent,
    updateWalletDialog,
    MallZonesComponent,
    FaqCreateComponent,
    DeleteReasonDialog,
    SectionItemsDialog,
    addCategoryProduct,
    updateImagesDialog,
    UpdateReasonDialog,
    PromoProductDialog,
    UserDetailComponent,
    FavouritesComponent,
    UserOrdersComponent,
    CreatePostComponent,
    deleteLoyaltyDialog,
    MallDetailComponent,
    ProductStoresDialog,
    productDeleteDialog,
    CategoriesComponent,
    MallStoresComponent,
    SiloCreateComponent,
    UpdatePartnerDialog,
    MallCreateComponent,
    RemoveSectionDialog,
    ListdriversComponent,
    StoreOrdersComponent,
    AddCategoryComponent,
    UpdateContractDialog,
    ProductCategoriesAdd,
    viewAssetTypesDialog,
    StoreDetailComponent,
    addSubCategoryDialog,
    AddRetailerComponent,
    TradingHoursComponent,
    addStoreShopperDialog,
    TransactionsComponent,
    CartTrackingComponent,
    addStoreProductDialog,
    mallAddSectionsDialog,
    createAssetTypedialog,
    viewBankDetailsDialog,
    UpdateAsserTypeDialog,
    addPostProductsDialog,
    removeSubCategoryItem,
    addStoreProductDialog,
    PromotionalsComponent,
    RetailersListComponent,
    StoreProductsComponent,
    viewEarningTypesDialog,
    editStoreProductDialog,
    OrdersExportsComponent,
    ProductCreateComponent,
    RetailerAdminsComponent,
    RetailerDetailComponent,
    editPaymentMethodDialog,
    CartNavigationComponent,
    createEarningTypeDialog,
    removePostProductDialog,
    RemoveMallSectionDialog,
    cancelOrderReasonDialog,
    RetailerStoresComponent,
    MallNavigationComponent,
    PublicHolidaysComponent,
    StoreCategoriesComponent,
    viewPaymentMethodsDialog,
    RedirectInactivityDialog,
    StoreGeofencingComponent,
    deleteStoreShopperDialog,
    RemoveSectionItemsDialog,
    viewPickerLocationDialog,
    DashboardOrdersComponent,
    deleteStoreProductDialog,
    createPaymentMethodDialog,
    categoryImageUpdateDialog,
    RetailerProductsComponent,
    CreatePromotionalComponent,
    StoreTradingHoursComponent,
    RetailerProductCreateComponent,
    NucleusWaybillTrackingComponent,
    ProductNavigateComponent,
    ProductCategoriesComponent,
    CartOverviewComponent,
    MallSectionsComponent,
    CreateSectionComponent,
    StoreDetailsComponent,
    StoreAddressComponent,
    SchedulingComponent,
    AllGeofencesComponent,
    DashboardDailyComponent,
    UserMessagesComponent,
    PushCreateComponent,
    CategoryProductsComponent,
    CategorySubcategoriesComponent,
    CreateDriversComponent,
    ListBatchesComponent,
    ListPaymentBatchComponent,
    PaymentCaptureComponent,
    ListAssetsComponent,
    ListEarningsComponent,
    CreateAssetsComponent,
    ListReasonsComponent,
    CreateReasonComponent,
    ListBanksComponent,
    RemoveBranchMapDialog,
    CreateBanksComponent,
    CreateEarningComponent,
    ListContractsComponent,
    CreateContractComponent,
    ListAccountsComponent,
    ListUserAccountsComponent,
    AccountsNavigateComponent,
    SummaryComponent,
    AccountsComponent,
    EarningsComponent,
    PaymentsComponent,
    AssetsComponent,
    NavigateUserAccountsComponent,
    FinancesComponent,
    IncentivesComponent,
    DistantFeesComponent,
    PartnersComponent,
    PartnersCreateComponent,
    ZoneComponent,
    NewZoneComponent,IncentiveComponent, ListSingleDeliveryComponent,
    CreateFinancesComponent,
    DischemUsersComponent, 
  ],
  imports: [
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    MatTabsModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatChipsModule,
    MatTableModule,
    MatBadgeModule,
    MatDialogModule,
    MatButtonModule,
    ClipboardModule,
    MatSelectModule,
    AppRoutingModule,
    MatCardModule,
    MatRadioModule,
    HttpClientModule,
    MatTooltipModule,
    MatSidenavModule,
    NgxSpinnerModule,
    MatCheckboxModule,
    MatFormFieldModule,
    // AgmDirectionModule, // DO NOT ENABLE
    MatExpansionModule,
    GoogleChartsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: Env.googlemapskey,
      libraries: ['places', 'drawing', 'geometry'],
      apiVersion: 'quarterly',
    }),
    DragDropModule,
    MatSnackBarModule,
    MatGoogleMapsAutocompleteModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [
          'https://api.api-dev.quench.org.za/catalogue/v1/app/',
          'https://api.api-dev.quench.org.za/auth/v1/',
          'https://api.api-dev.quench.org.za/pay/v1/',
          'https://api.api-dev.quench.org.za/fulfilment/v1/',
          'https://api.quench.org.za/catalogue/v1/app/',
          'https://api.quench.org.za/auth/v1/',
          'https://api.quench.org.za/pay/v1/',
          'https://api.quench.org.za/fulfilment/v1/',
        ],
        disallowedRoutes: [''],
      },
    }),
  ],
  providers: [ApiService, RoleGaurd, FinanceGaurd],
  bootstrap: [AppComponent],
})
export class AppModule {}
