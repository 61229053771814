<div class="add-card"  >
  
    <div class="header">
      <h3>add picker to store</h3>
      <mat-icon (click)='closeDialog()'>clear</mat-icon>
    </div>

    <div class="top" style="margin: 25px 0;">
        <div class="search" >
            <mat-icon (click)='getUsers()' matTooltip='search' class='search-icon' [style.left]="inputTouch ? '-20px' : '10px' ">search </mat-icon>
            <mat-icon *ngIf='search' (click)='clearSearch()'  matTooltip='clear' class='clear-icon'>clear</mat-icon>
            <input matInput (keydown.enter)='getUsers()' [(ngModel)]="search" placeholder="Search users"  #input (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
        </div>
    </div>
      
        <table  mat-table [dataSource]='searchData' matSort >

            <!-- <mat-spinner class='spinner'></mat-spinner>  -->

            <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name </th>
            <td mat-cell *matCellDef="let item" > 
                <p>{{item.first_name}} {{item.last_name}}</p>
            </td>
            </ng-container>

            <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>email </th>
            <td mat-cell *matCellDef="let item" > 
                <p>{{item.email}}</p>
            </td>
            </ng-container>

            <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
            <td mat-cell *matCellDef="let item" > 
                <p *ngIf='item.active'  class='active'><mat-icon>check</mat-icon> </p>
                <p *ngIf='!item.active' class='inactive'><mat-icon>clear</mat-icon> </p>
            </td>
            </ng-container>

            <ng-container matColumnDef="add">
            <th mat-header-cell  *matHeaderCellDef> </th>
            <td mat-cell  *matCellDef="let item" > 
                <mat-icon (click)='toggleSelection(item)'>add_circle_outlined</mat-icon>
            </td>
        </ng-container>

            <tr mat-header-row *matHeaderRowDef="Columns"></tr>
            <tr mat-row class='action'  *matRowDef="let item; columns: Columns;"></tr>
        </table>


        <div class="add-list">
            <mat-chip-list aria-label="Fish selection">
                <p *ngIf='itemsArr.length > 0'>adding :</p>
            <mat-chip id='selectedCat' #c="matChip" id='chosen-category' [value]="item" *ngFor='let item of itemsArr'  >
                <p > {{item.first_name}} {{item.last_name}} </p>
                <mat-icon matChipRemove (click)='toggleSelection(item)'>cancel</mat-icon>
            </mat-chip>
            </mat-chip-list>
        </div>


        <div class="btn">
            <div (click)='start()' matTooltip="Skip to start" class='action' *ngIf='page > 1'>back to start</div>
            <p (click)='previous()' class='action'>previous</p>
            <div>{{page}}</div>
              <p (click)='next()' class='action'>next</p>
              <div> </div>
            <button mat-flat-button class='button ' (click)='addPickerToStore()'>Confirm</button>
        </div>

  
</div>
  

