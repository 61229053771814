<div id="shade" *ngIf='delete'></div>

  <div class='stores'>


    <div *ngIf='!edit'>
      <table  mat-table [dataSource]='promocodes' matSort>

        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
          <td mat-cell *matCellDef="let promo" > <p>{{promo.code}}</p> </td>
        </ng-container>

        <ng-container matColumnDef="start_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </th>
          <td mat-cell *matCellDef="let promo"> <p>{{promo.starting_date}}</p> </td>
        </ng-container>

        <ng-container matColumnDef="end_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> End Date </th>
          <td mat-cell *matCellDef="let promo"> <p>{{promo.ending_date}}</p> </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
          <td mat-cell *matCellDef="let promo">  <p>{{promo.amount}}</p>  </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
          <td mat-cell *matCellDef="let promo">  <p class='description'>{{promo.description}}</p>  </td>
        </ng-container>

        <ng-container matColumnDef="product">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Product ID </th>
          <td mat-cell *matCellDef="let promo" >  
            <p >{{promo.product_id}}</p>  
            <p class='promo-prod'>{{promo.product}}</p>  
          </td>
        </ng-container>

        <ng-container matColumnDef="silo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Silo </th>
          <td mat-cell *matCellDef="let promo">  
            <p >{{promo.silo_id}} </p>  
            <p class='promo-prod'>{{promo.silo}} </p>  
          </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantity </th>
          <td mat-cell *matCellDef="let promo"> <p>{{promo.quantity}}</p>  </td>
        </ng-container>

        <ng-container matColumnDef="min_amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Min Amount </th>
          <td mat-cell *matCellDef="let promo">  <p>{{promo.min_order_amount}}</p>  </td>
        </ng-container>

        <ng-container matColumnDef="multiple-use">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> multiple use </th>
          <td mat-cell *matCellDef="let promo"> <mat-icon class='active' *ngIf='promo.use_multiple_times'>check</mat-icon>  </td>
        </ng-container>

        <ng-container matColumnDef="first order">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> First Order </th>
          <td mat-cell *matCellDef="let promo"> <mat-icon class='active' *ngIf='promo.first_order'>check</mat-icon>  </td>
        </ng-container>


        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
          <td mat-cell *matCellDef="let promo"> 
            <mat-icon *ngIf='promo.active' class='active'>check</mat-icon>
            <mat-icon *ngIf='!promo.active' class='inactive'>clear</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
          <td mat-cell *matCellDef="let promo"> 
              <mat-icon matTooltip='more' [matMenuTriggerFor]="menu">more_horiz</mat-icon> 
              <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)='updatePromoDialog(promo)'> 
                    <mat-icon>edit</mat-icon>
                    <span>edit</span>
                  </button>
                  <button mat-menu-item  (click)='deletePromo(promo)'>
                    <mat-icon>delete_outline</mat-icon>
                    <span>delete</span>
                  </button>
                  <button mat-menu-item (click)="togglePromoStatus(promo)">
                    <mat-icon class='material-icons-outlined' *ngIf='promo.active'>toggle_off</mat-icon>
                    <span *ngIf='promo.active'>deactivate promo</span>
                    <mat-icon class='material-icons-outlined' *ngIf='!promo.active'>toggle_on</mat-icon>
                    <span *ngIf='!promo.active'>activate promo</span>
                  </button>
              </mat-menu>
          </td>
      </ng-container>

        <tr mat-header-row *matHeaderRowDef="Columns"></tr>
        <tr mat-row 
            class='action'
            *matRowDef="let promo; columns: Columns;"></tr>
      </table>
    </div>

    <div id="pager" >
      <div class="page"> 

        <button id='add-btn' mat-flat-button class='button' id="{{auth.expand ? 'create-expand': 'create'}}" routerLink='/create-promo'> New</button>
      </div>
    </div>
  


    <div class='mobile'>
      <mat-expansion-panel hideToggle  *ngFor='let promo of promocodes '>
        <mat-expansion-panel-header >
          <mat-panel-title>
            {{promo.name}}
          </mat-panel-title>
        </mat-expansion-panel-header>
          <span class="flex">
            <mat-title>Image:</mat-title>
            <p><img src={{promo.image}} alt=""></p>
          </span>
          <span class="flex">
            <mat-title>size:</mat-title>
            <p>	{{promo.size}} </p>
          </span>
          <span class="flex">
            <mat-title>Price:</mat-title>
            <p>	{{promo.price}}</p>
          </span>
        </mat-expansion-panel>
    </div>


  <!-- ================ -->
  <!--  DELETE DIALOG   -->
  <!-- ================ -->

  <div id="dialog">
    <div class='dialog' *ngIf='delete'>
      <span>
        <p>Are you sure you want to delete this promotional code?</p>
        <mat-icon class='action' (click)='deleteDialog()'>close</mat-icon>
      </span>
      <button mat-flat-button class='button' (click)='deletePromo()' >Confirm</button>
    </div>
  </div>

</div>
