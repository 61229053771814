<div class="back action" routerLink="/sections">
  <mat-icon>arrow_back</mat-icon>
  <p>sections</p>
</div>

<div class="create-card">
  <div class="header">
    <h3>create new section</h3>
    <mat-icon routerLink="/sections">clear</mat-icon>
  </div>

  <form #section="ngForm" (ngSubmit)="createSection(section.value)">
    <mat-form-field class="field" appearance="outline">
      <mat-label>section title</mat-label>
      <input
        type="text"
        matInput
        ngModel
        placeholder="gouda cheese"
        name="title"
        required
      />
    </mat-form-field>

    <mat-form-field class="field" appearance="outline" *ngIf="!isDisChemUser">
      <mat-label>Silo</mat-label>
      <mat-select [(ngModel)]="silo_id" name="silo_id">
        <mat-option *ngFor="let silo of misc.silos" [value]="silo.id">
          {{ silo.tag }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="field" appearance="outline">
      <mat-label>Type</mat-label>
      <mat-select ngModel name="type">
        <mat-option *ngFor="let type of types" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="btn">
      <button
        mat-flat-button
        class="button"
        type="submit"
      >
        save
      </button>
    </div>
  </form>
</div>
