<div class="header">
    <h3> All Payment methods</h3>
    <mat-icon (click)="closeDialog()" class="action">clear</mat-icon>
  </div>
  <hr>
  
  
  <!-- earnings type table -->
  <table mat-table [dataSource]="payments.payment_methods" matSort >
  
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let payment_method">
        <p>{{ payment_method.name }}</p>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let payment_method">
        <p>{{ payment_method.description }}</p>
      </td>
    </ng-container>


     
    
    <ng-container matColumnDef="account_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Number</th>
      <td mat-cell *matCellDef="let payment_method">
        <p [title]="payment_method.from_account_id">{{ payment_method.account_number }}</p>
      </td>
    </ng-container>
   
  
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let payment_method"> 
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="payment_methods_columns" ></tr>
    <tr
      mat-row
      class="action"
      *matRowDef="let payment_method; columns: payment_methods_columns"
    ></tr>
  </table>
  
  <!-- <div class="save">
    <button mat-flat-button (click)="createEarningType()">New Earning type</button>
  </div>
   -->