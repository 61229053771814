import { MessagesService } from './../messages.service';
import { MiscService } from './../misc/misc.service';
import { ApiService } from 'src/app/services/api.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SectionsService {
  data;
  section;
  sections = [];
  retries = 0;
  search = '';

  constructor(
    public api: ApiService,
    public misc: MiscService,
    public message: MessagesService
  ) {}

  // ===============
  //  GET SECTIONS
  // ===============

  getSection(id){
    let isDischemUser = localStorage.getItem('dis-chem-role');
    let apicall = ''
    if(isDischemUser){
      apicall = `dischem/sections/${id}`
    } else {
      apicall = `sections/${id}`
    }

    this.api.getList('catelog', `${apicall}`).subscribe((section: any) => {
      this.section = section;
      this.listSectionInfo();
    });
  }


  async getSections() {
    let isDischemUser = localStorage.getItem('dis-chem-role');
    let apicall = '';

    if (isDischemUser) {
      apicall = 'dischem/sections';
      this.misc.silo = { id: 6, name: "Deliverd", active: true, tag: "deliverd" }
    } else {
      await this.misc.getSilos();
      apicall = "sections"
    }


    this.api
    .getList(
      'catelog',
      `${apicall}?page=${1}&per_page=${100}&search=${this.search}`
    )
    .subscribe(
      (data: any) => {
        var sections = [];

        data.forEach((section) => {
          if (section.silo_id == this.misc.silo.id) {
            sections.push(section);
          }
        });
        this.sections = sections;
      },
      (error) => {
        this.message.showSnack(error.error);
        if (this.retries < 3 && error.status !== 401) {
          this.retries++;
          this.getSections();
        }
      }
    );
  }

  // ===============
  //  TOGGLE STATUS
  // ===============

  toggleSectionStatus(section) {
    let isDischemUser = localStorage.getItem('dis-chem-role');

    let sect = {
      id: section.id,
      active: false,
    };

    if (!section.active) {
      sect.active = true;
    }

    let apicall = '';

    if (isDischemUser) {
      apicall = `dischem/sections`;

    } else {
      apicall = `sections`;
    }

    this.api.patch('catelog', apicall, sect).subscribe(
      (section: any) => {
        this.message.showSnack(`Succesfully updated ${section.title} status`);
        this.getSections();
      },
      (error) => {
        this.message.showSnack(error.error);
      }
    );
  }

  // ===============
  //  SECTION INFO
  // ===============

  listSectionInfo() {
    let isDischemUser = localStorage.getItem('dis-chem-role');
    let apicall = '';

    if (isDischemUser) {

      // for dischem sections
      if (this.section.type == 'products') {
        apicall = `dischem/sections/${this.section.id}/products`;
      } else if (this.section.type == 'posts') {
        apicall = `dischem/sections/${this.section.id}/posts`;
      }

    } else {
      // for admin sections
      if (this.section.type == 'products') {
        apicall = `sections/${this.section.id}/sectionsproducts`;
      } else if (this.section.type == 'posts') {
        apicall = `sections/${this.section.id}/sectionsposts`;
      } else if (this.section.type == 'stores') {
        apicall = `sections/${this.section.id}/sectionsstores`;
      }

    }

    this.api.getList("catelog",`${apicall}?page=${1}&per_page=${100}`)
    .subscribe((data:any)=>{
      // console.log(data)
      this.data = data
    })
  }

  deleteSection(section){
    let isDischemUser = localStorage.getItem('dis-chem-role');
    let apicall = ''

    if(isDischemUser){
      apicall = `dischem/sections/${section.id}`
    } else {
      apicall = `sections/${section.id}`
    }

    this.api.delete(`catelog`, `${apicall}`).subscribe((data: any) => {
      this.message.showSnack(`succesfully removed section`)
    })
  }


  // ===============
  //  DROP EVENTS
  // ===============

  // -------------------------
  //    SECTIONS CDKDRAG
  // -------------------------

  drop(event: CdkDragDrop<string[]>) {
    let isDischemUser = localStorage.getItem('dis-chem-role');
    let apicall = ''

    moveItemInArray(this.sections, event.previousIndex, event.currentIndex);

    var list = [];
    this.sections.forEach((section) => {
      list.push(section.id);
    });

    if(isDischemUser){
      apicall = `dischem/sort`

    } else {
      apicall = 'sort'
    }

    this.api
    .patch('catelog', `${apicall}`, {
      table: 'sections',
      ids: list,
    })
    .subscribe(
      (data) => {
        this.message.showSnack('Updating Sections');
        this.getSections();
      },
      (error) => {}
    );
  }

    // -------------------------
  //  SECTION PRODUCT CDKDRAG
  // -------------------------

  dropProduct(event: CdkDragDrop<string[]>) {
    let isDischemUser = localStorage.getItem('dis-chem-role');

    let table = 'section_products';

    if (this.section.type == 'posts') {
      table = 'section_posts';
    } else if (this.section.type == 'stores') {
      table = 'section_stores';
    }

    moveItemInArray(this.data, event.previousIndex, event.currentIndex);

    var list = [];
    if (this.section.type == 'products') {
      this.data.forEach((sectionproduct) => {
        list.push(sectionproduct.section_product_id);
      });
    } else if (this.section.type == 'posts') {
      this.data.forEach((sectionproduct) => {
        list.push(sectionproduct.section_post_id);
      });
    } else if (this.section.type == 'stores') {
      this.data.forEach((sectionproduct) => {
        list.push(sectionproduct.section_store_id);
      });
    }

    let sort = {
      table: table,
      ids: list,
    };

    let apicall = ``

    if(isDischemUser){
      apicall = `dischem/sort`
    } else {
      apicall = `sort`
    }

    this.api.patch('catelog', `${apicall}`, sort).subscribe(
      (data) => {
        this.message.showSnack('Updated section item position');
        this.getSection(this.section.id);
      },
      (error) => {
        this.message.showSnack(JSON.stringify(error));
      }
    );
    }
}
