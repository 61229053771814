<hr>
<div class="name action">
  <h4>Store Address Details</h4>
  <!-- <span class='update-all-button' matTooltip='update multiple fields'  *ngIf='!allFields'>update</span> -->
</div>
<hr>

<span *ngIf='store'>

  <div class="flex-store-details">

    <!-- latitude -->
    <div class="flex-item" *ngIf='!allFields'>
        <div >
        <p class='field'  >latitude</p>
        <p *ngIf='store.latitude' >{{store.latitude}}</p>
        <p *ngIf='!store.latitude' >n/a</p>
        </div>
      </div>
  
      <!-- longitude -->
      <div class="flex-item" *ngIf='!allFields'>
        <div >
        <p  class='field'  >longitude</p>
        <p *ngIf='store.longitude' >{{store.longitude}}</p>
        <p *ngIf='!store.longitude' >n/a</p>
        </div>
      </div>

    <!-- street -->
    <div class="flex-item" *ngIf='store.silo_id == 5'>
      <div >
      <p *ngIf='!editStreet || allFields' class='field'  >street</p>
      <p *ngIf='store.street && !editStreet' >{{store.street}}</p>
      <p *ngIf='!store.street && !editStreet' >n/a</p>
      <input matInput type="text"  matInputAutofocus  [(ngModel)]='street' *ngIf='editStreet' placeholder="11 piet retief">
      </div>
      <!-- <button mat-flat-button *ngIf='editStreet && !allFields' (click)='patch()'>update</button>
      <mat-icon (click)='updateMarketDetails("street")' *ngIf='!editStreet'>edit</mat-icon>
      <mat-icon (click)='cancelUdpate("street")' *ngIf='editStreet && !allFields'>close</mat-icon> -->
    </div>

    <!-- building -->
    <div class="flex-item" *ngIf='store.silo_id == 5'>
      <div >
      <p *ngIf='!editBuilding || allFields' class='field'  >building</p>
      <p *ngIf='store.building && !editBuilding' >{{store.building}}</p>
      <p *ngIf='!store.building && !editBuilding' >n/a</p>
      <input matInput type="text"  matInputAutofocus  [(ngModel)]='building' *ngIf='editBuilding' placeholder="Carpe Diem A">
      </div>
      <!-- <button mat-flat-button *ngIf='editBuilding && !allFields' (click)='patch()'>update</button>
      <mat-icon (click)='updateMarketDetails("building")' *ngIf='!editBuilding'>edit</mat-icon>
      <mat-icon (click)='cancelUdpate("building")' *ngIf='editBuilding && !allFields'>close</mat-icon> -->
    </div>

    <!-- province -->
    <div class="flex-item" *ngIf='store.silo_id == 5'>
      <div >
      <p *ngIf=' !editProvince || allFields' class='field'  >province</p>
      <p *ngIf='store.province && !editProvince' >{{store.province}}</p>
      <p *ngIf='!store.province && !editProvince' >n/a</p>
      <input matInput type="text"  matInputAutofocus  [(ngModel)]='province' *ngIf='editProvince' >
      </div>
      <!-- <button mat-flat-button *ngIf='editProvince && !allFields' (click)='patch()'>update</button>
      <mat-icon (click)='updateMarketDetails("province")' *ngIf='!editProvince'>edit</mat-icon>
      <mat-icon (click)='cancelUdpate("province")' *ngIf='editProvince && !allFields'>close</mat-icon> -->
    </div>

    <!-- city -->
    <div class="flex-item" *ngIf='store.silo_id == 5'>
      <div >
      <p *ngIf='!editLiquor || allFields' class='field'  >city</p>
      <p *ngIf='store.city && !editLiquor' >{{store.city}}</p>
      <p *ngIf='!store.city && !editLiquor' >n/a</p>
      <input matInput type="text"  matInputAutofocus  [(ngModel)]='city' *ngIf='editLiquor' >
      </div>
      <!-- <button mat-flat-button *ngIf='editLiquor && !allFields'(click)='patch()' >update</button>
      <mat-icon (click)='updateMarketDetails("liquor")' *ngIf='!editLiquor'>edit</mat-icon>
      <mat-icon (click)='cancelUdpate("liquor")' *ngIf='editLiquor && !allFields'>close</mat-icon> -->
    </div>

    <!-- suburb -->
    <div class="flex-item" *ngIf='store.silo_id == 5'>
      <div >
      <p *ngIf='!editApi || allFields' class='field'  >suburb</p>
      <p *ngIf='store.suburb && !editApi' >{{store.suburb}}</p>
      <p *ngIf='!store.suburb && !editApi' >n/a</p>
      <input matInput type="text"  matInputAutofocus  [(ngModel)]='suburb' *ngIf='editApi' >
      </div>
      <!-- <button mat-flat-button *ngIf='editApi && !allFields' (click)='patch()'>update</button>
      <mat-icon (click)='updateMarketDetails("api")' *ngIf='!editApi'>edit</mat-icon>
      <mat-icon (click)='cancelUdpate("api")' *ngIf='editApi && !allFields'>close</mat-icon> -->
    </div>

    <!-- postal -->
    <div class="flex-item" *ngIf='store.silo_id == 5'>
      <div >
      <p *ngIf=' !editLoop || allFields' class='field'  >postal</p>
      <p *ngIf='store.postal && !editLoop' >{{store.postal}}</p>
      <p *ngIf='!store.postal && !editLoop' >n/a</p>
      <input matInput type="text"  matInputAutofocus  [(ngModel)]='postal' *ngIf='editLoop' >
      </div>
      <!-- <button mat-flat-button *ngIf='editLoop && !allFields' (click)='patch()'>update</button>
      <mat-icon (click)='updateMarketDetails("loop")' *ngIf='!editLoop'>edit</mat-icon>
      <mat-icon (click)='cancelUdpate("loop")' *ngIf='editLoop && !allFields'>close</mat-icon> -->
    </div>

        <!-- full address -->
        <div class="flex-item full-address" *ngIf='!allFields'>
            <div >
            <p class='field'  >physical_address</p>
            <p *ngIf='store.physical_address && !physical_address' >{{store.physical_address}}</p>
            <p *ngIf='!store.physical_address && !physical_address' >n/a</p>
            </div>
            <!-- <button mat-flat-button *ngIf='physical_address' (click)='patch()'>update</button>
            <mat-icon (click)='updateMarketDetails("api")' *ngIf='!physical_address'>edit</mat-icon>
            <mat-icon (click)='cancelUdpate("api")' *ngIf='physical_address'>close</mat-icon> -->
          </div>

  </div>

  <!-- <div class="buttons" *ngIf='allFields'>
    <button mat-flat-button (click)='cancelUdpate($event)'>cancel</button>
    <button mat-flat-button class='button' (click)='patch()'>update</button>
  </div> -->

</span>
