import { Component, Inject, OnInit, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
})
export class PartnersComponent implements OnInit {
  Columns = [
    'name',
    'callback_url',
    'max_delivery_distance',
    'active',
    'edit_partner',
    'copy_api_key'
  ];
  //used for view
  partners;
  
  edit = false

  // used for edit:
  id;
  partner;
  name;
  max_delivery_distance;
  callback_url;
  //used for delete
  delete;

  //used for pagination
  limit = 10;
  page = 1;
  constructor(
    private api: ApiService,
    private message: MessagesService,
    public auth: AuthService,
    public route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getPartners();
  }

  getPartners() {
    this.api
      .getList(
        'godrive',
        `admin/partners?page=${this.page}&per_page=${this.limit}`
      )
      .subscribe((partners: any) => {
        this.partners = partners.Partners;
        console.log(this.partners)
      });
  }

  copyApiKey(){
    this.message.showSnack("api key copied to clipboard")
  }


  togglePartner(p){
    this.api
    .patch('godrive', `admin/partners/` + p.ID, {
      active: p.active
    })
    .subscribe(
      (data: any) => {
        this.getPartners();
        this.message.showSnack('succesfully updated partner')
      },
      (error) => {
        console.log(error);
      }
    );
  }


  updatePartner(partner) {
    const dialog = this.dialog.open(UpdatePartnerDialog, {
      width: '850px',
      height: '600px',
      data: {
        partner: partner,
      },
    });

    dialog.afterClosed().subscribe(() => {
        this.getPartners();
        this.message.showSnack('succesfully updated partner')
    });
  }


  setLimit(limit) {
    this.limit = limit;
    this.getPartners();
  }
  next() {
    if (this.partners.length < this.limit) {
      this.message.showSnack('no more partners to show');
    } else {
      this.page++;
      this.getPartners();
    }
  }
  previous() {
    if (this.page > 1) {
      this.page--;
      this.getPartners();
    }
  }
}



@Component({
  selector: 'update-partner-dialog',
  templateUrl: 'update-partner-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class UpdatePartnerDialog {
  name;
  callback_url
  max_delivery_distance;


  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { partner },
    public api: ApiService,
    public dialogRef: MatDialogRef<UpdatePartnerDialog>
  ) {}

  ngOnInit(): void {
    this.name = this.data.partner.name;
    this.callback_url = this.data.partner.callback_url;
    this.max_delivery_distance = this.data.partner.max_delivery_distance;
  }

  // get section info after success
  onAdd = new EventEmitter();

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  updateReason(partner) {
    this.api
      .patch('godrive', `admin/partners/` + this.data.partner.ID, {
        name: this.name,
        max_delivery_distance: this.max_delivery_distance,
        callback_url: this.callback_url,
      })
      .subscribe(
        (data: any) => {
          this.closeDialog();
        },
        (error) => {
          alert(JSON.stringify(error));
        }
      );
  }
}