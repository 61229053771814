<span *ngIf="sections.section">
  <div class="back action" routerLink="/sections">
    <mat-icon>arrow_back</mat-icon>
    <p>sections</p>
  </div>

  <div class="header">
    <h3>{{ sections.section.title }}</h3>
    <div class="table-header">
      <p>{{ sections.section.type }}</p>
    </div>
    <hr />
  </div>

  <table class="table">
    <thead>
      <tr>
        <th class="header" id="sort">Sort</th>
        <th class="header" id="icon"></th>
        <th class="header" id="icon">Name</th>
        <th class="header"></th>
      </tr>
    </thead>
    <tbody cdkDropList (cdkDropListDropped)="sections.dropProduct($event)">
      <tr
        class="action"
        *ngFor="let item of sections.data"
        cdkDrag
        cdkDragLockAxis="y"
      >
        <th class="sort">
          <p><mat-icon>sort</mat-icon></p>
        </th>
        <th class="image">
          <img
            class="img"
            src="{{ item.image }}"
            alt=""
            onerror="this.src='assets/placeholders/placeholder-img.png' "
          />
        </th>
        <th
          *ngIf="
            sections.section.type == 'products' ||
              sections.section.type == 'stores';
            else postTitle
          "
        >
          <p>{{ item.name }}</p>
        </th>
        <ng-template #postTitle
          ><th>
            <p>{{ item.title }}</p>
          </th>
        </ng-template>
        <th>
          <mat-icon
            class="product-delete"
            (click)="removeSectionItems(item)"
            matTooltip="remove item from section"
            >delete_outline</mat-icon
          >
        </th>
      </tr>
    </tbody>
  </table>

  <div id="pager">
    <button
      mat-flat-button
      class="button"
      id="{{ auth.expand ? 'create-expand' : 'create' }}"
      (click)="addSectionItems()"
    >
      add items to section
    </button>
  </div>
</span>
