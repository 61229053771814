<div class="heading">
    <div></div>
    <h4>Edit Product Details</h4>
    <!-- <mat-icon (click)='closeDialog()' >clear</mat-icon> -->
</div>

<div class="edit-fields">
    <div class="fields ">
      <mat-form-field appearance="outline" class='field'>
        <mat-label>name</mat-label>
        <input matInput placeholder="coca-cola 2L" [(ngModel)]="name" >
      </mat-form-field>
      <mat-form-field appearance="outline" class='field'>
        <mat-label>description</mat-label>
        <input matInput placeholder="this is the coolest product on earth" [(ngModel)]="description" >
      </mat-form-field>
      <span class='flex-items'>
        <mat-form-field appearance="outline" >
          <mat-label>size</mat-label>
          <input matInput placeholder="2L" [(ngModel)]="size" >
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>price</mat-label>
          <input matInput placeholder="R200" [(ngModel)]="price" type='number'>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>sku code</mat-label>
          <input matInput placeholder="q22d12" [(ngModel)]="sku" >
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>barcode</mat-label>
          <input matInput placeholder="123456789" [(ngModel)]="barcode" >
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>maximum quantity allowed</mat-label>
          <input matInput placeholder="5" [(ngModel)]="max_quantity" (blur)="dimensionsRoundUp()">
        </mat-form-field>
        <mat-form-field appearance="outline" >
          <mat-label>otc</mat-label>
          <input matInput placeholder="12321" [(ngModel)]="otc" (blur)="dimensionsRoundUp()">
        </mat-form-field>
      </span>
    </div>
    <mat-form-field appearance="outline" class='field' >
        <mat-label>Retailer</mat-label>
        <mat-select [(ngModel)]="retailer" name="retailer" required>
          <input class='retailer-search' type="text" placeholder="Search Retailers" [(ngModel)]="search" (input)='getRetailers()' >
            <mat-option *ngFor="let retailer of retailers" [value]="retailer.id">
                {{retailer.name}}
            </mat-option>
        </mat-select>
      </mat-form-field>  
</div>

<div class="save">
    <button mat-flat-button class='button cancel' (click)='closeDialog()'> Cancel</button>
    <button mat-flat-button class='button' (click)='updateProduct()'> Save</button>
</div>