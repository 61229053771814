<br><br>
<table>
  <tr>
    <th> </th>
    <th>Name</th>
    <th>Address</th>
    <th>Silo</th>
  </tr>
   <tr *ngFor="let store of pager.data">
    <td>{{store.id}}</td>
    <td>{{store.name}}</td>
    <td>{{store.physical_address}}</td>
    <td>{{store.silo_id}}</td>
   </tr>
</table>
