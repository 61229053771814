<form *ngIf='!resetPassword' class="login-form">
  <img src="../../../assets/images/undraw_Login_re_4vu2.svg" alt="">

  <mat-form-field class="example-full-width">
    <mat-label>Email Address</mat-label>
    <input matInput placeholder="Email Address" name="email"  [(ngModel)]="email"  #ctrl="ngModel" autofocus>
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label>Password</mat-label>
    <input matInput placeholder="Password" [type]="showPassword ? 'text' : 'password'"  name="password" [(ngModel)]="password" #ctrl="ngModel">
    <mat-icon (click)='toggleShowPassword()' *ngIf='!showPassword' class='password_hide'>visibility_off</mat-icon>
    <mat-icon (click)='toggleShowPassword()' *ngIf='showPassword'>visibility</mat-icon>
  </mat-form-field>

  <button mat-flat-button color="accent" (click)="login()">Login</button>

  <!-- <p id='reset'>Forgot Password?
    <span class='action' (click)='forgotPassword()'>
      Reset it here
    </span>
  </p> -->
</form>


