import { Component, OnInit } from '@angular/core';
import { AccountsService } from 'src/app/services/accounts/accounts.service';

@Component({
  selector: 'app-list-accounts',
  templateUrl: './list-accounts.component.html',
  styleUrls: ['./list-accounts.component.scss'],
})
export class ListAccountsComponent implements OnInit {
  Columns = [
    'user_id',
    'bank_id',
    'account_name',
    'account_number',
    'branch_code',
    'verified',
    'default_account',
  ];
  page=1;
  limit=10;
  constructor(public accounts: AccountsService) {}

  ngOnInit(): void {
    this.getAccounts()
  } 

  getAccounts(){

    this.accounts.getAccounts(this.page)
  }
  setLimit(i){
    this.limit=i
  }
  previous(){
    if(this.page < 2){
      return
    }
    this.page--;
    this.getAccounts()

  }
  next(){
    this.page++;
    this.getAccounts()
  }
}
