<div *ngIf='delete' id="shade"></div>

<div *ngIf='!edit'>
    <div *ngIf='!create'>

        <div class="stores">

            <div class="silos">
                <div (click)='siloChanged(asilo)'  *ngFor="let asilo of silos"  [class]="asilo.name == silo.name ?  'isBold' : '' ">{{asilo.name}}</div>
            </div>
            

            <table  mat-table [dataSource]='hours' matSort>

                <ng-container matColumnDef="day">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Day</th>
                    <td mat-cell *matCellDef="let trading"> {{trading.day}} </td>
                </ng-container>

                <ng-container matColumnDef="day_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Day Name</th>
                    <td mat-cell *matCellDef="let trading"> {{trading.day_name}} </td>
                </ng-container>
        
                <ng-container matColumnDef="open">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Opens At</th>
                <td mat-cell *matCellDef="let trading"> {{trading.opens_at}} </td>
                </ng-container>
        
                <ng-container matColumnDef="close">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Closes At</th>
                <td mat-cell *matCellDef="let trading"> {{trading.closes_at}} </td>
                </ng-container>
        
                <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                <td mat-cell *matCellDef="let trading"><mat-icon (click)="toggleEdit(trading)">edit</mat-icon></td>
                </ng-container>
                
                <ng-container matColumnDef="remove">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
                    <td mat-cell *matCellDef="let trading"> <mat-icon (click)='deleteCheck(trading)'>delete_outlined</mat-icon></td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="Columns"></tr>
                <tr mat-row 
                    class='action'
                    *matRowDef="let trading; columns: Columns;"></tr>
            </table>
        </div>

        <div id="pager" >

            <button id="{{auth.expand ? 'create-expand': 'create'}}" mat-flat-button class='button' (click)='toggleCreate()'>New</button>
    
            <!-- <div class="page"> 
    
            <div> Per page:</div>
            <div (click)="setLimit(10)" class="limit" >10</div>
            <div (click)="setLimit(15)" class="limit" >15</div>
            <div (click)="setLimit(20)" class="limit" >20</div>
            <div (click)="setLimit(50)" class="limit" >50</div>
            <div>Page:</div>
            <mat-icon (click)='previous()'>skip_previous</mat-icon>
            <div>{{page}}</div>
            <mat-icon (click)='next()'>skip_next</mat-icon>
            <div> </div>
            </div> -->
        </div>
    </div>

    <div *ngIf='create'>
        <form #hour = 'ngForm' (ngSubmit)='createHours(hour.value)'>
            <span>
            <h3>Create new Trading Hour</h3>
            <mat-icon class='action' (click)='toggleCreate()'>close</mat-icon>
            </span>
            <mat-form-field >
                <mat-label>day</mat-label>
                <mat-select [(ngModel)]="day" name="day">
                    <mat-option *ngFor="let day of days" [value]="day">
                    {{day.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
            <mat-label>Opens At</mat-label>
            <input type="text" name='opens' matInput ngModel  >
            </mat-form-field>
            <mat-form-field>
            <mat-label>Closes At</mat-label>
            <input type='text' name='closes' matInput ngModel>
            </mat-form-field>
            <mat-form-field >
                <mat-label>Silo</mat-label>
                <mat-select [(ngModel)]="siloValue" name="silo">
                    <mat-option *ngFor="let silo of silos" [value]="silo.id">
                    {{silo.tag}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="btn">
            <button class='button' mat-flat-button type='submit'>Create new Trading Hour</button>
            </div>
        </form>
    </div>
</div>

<div *ngIf='edit'>
    <form #hours = 'ngForm' (ngSubmit)='updateHours(hours.value)'>
        <span>
        <h3>Update Trading Hour</h3>
        <mat-icon class='action' (click)='toggleEdit()'>close</mat-icon>
        </span>
        <mat-form-field >
            <mat-label>day</mat-label>
            <mat-select [(ngModel)]="day" name='day' >
                <mat-option *ngFor="let day of days" [value]="day">
                {{day.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
        <mat-label>Opens At</mat-label>
        <input type="text" 
               name='opens' 
               matInput 
               [(ngModel)]='hour.opens_at' 
               name='opens' >
        </mat-form-field>
        <mat-form-field>
        <mat-label>Closes At</mat-label>
        <input type='text' 
               name='closes' 
               matInput 
               [(ngModel)]='hour.closes_at' 
               name='closes'>
        </mat-form-field>
        <mat-form-field >
            <mat-label>Silo</mat-label>
            <mat-select [(ngModel)]="hour.silo_id" name="silo">
                <mat-option *ngFor="let silo of silos" [value]="silo.id">
                {{silo.tag}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="btn">
        <button class='button' mat-flat-button type='submit'>Update Trading Hour</button>
        </div>
    </form>
</div>

    <!-- ================== -->
    <!--   REMOVE PRODUCT   -->
    <!-- ================== -->

    <div id="dialog">
        <div class='dialog' *ngIf='delete'>
          <span>
            <p>Are you sure you want to remove this item?</p>
            <mat-icon class='action' (click)='deleteCheck()'>close</mat-icon>
          </span>
          <button mat-flat-button class='button' (click)='deleteHour()' >Confirm</button>
        </div>
    </div>