<table
  mat-table
  [dataSource]="deliveries?.deliveries"
  matSort
  class="asset_table"
>
  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Placed</th>
    <td mat-cell *matCellDef="let delivery">
      <P *ngIf="delivery.created_at">{{
        delivery.created_at.substring(0, 10)
      }}</P>
      <P *ngIf="delivery.created_at" class="order-time">{{
        delivery.created_at.substring(11, 19)
      }}</P>
      <P *ngIf="!delivery.created_at">No Order Date</P>
    </td>
  </ng-container>

  <ng-container matColumnDef="customer_name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name</th>
    <td mat-cell *matCellDef="let delivery">
      <p *ngIf="delivery.customer_name">{{ delivery.customer_name }}</p>
      <p *ngIf="!delivery.customer_name" class="empty">no customer name</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="customer_contact">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Contact</th>
    <td mat-cell *matCellDef="let delivery">
      <p *ngIf="delivery.customer_phone">{{ delivery.customer_phone }}</p>
      <p *ngIf="!delivery.customer_phone" class="empty">no customer contact</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="pick_up_address">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Pick Up Address</th>
    <td mat-cell *matCellDef="let delivery">
      <p
        *ngIf="delivery.pick_up_address"
        matTooltip="latitude {{ delivery.pick_up_latitude }}&#10; longitude {{
          delivery.pick_up_longitude
        }}"
      >
        {{ delivery.pick_up_address }}
      </p>
      <p *ngIf="!delivery.pick_up_address" class="empty">no pick up address</p>
      <!-- <p>latitude: {{delivery.pick_up_latitude}}</p>
      <p>longitude: {{delivery.pick_up_longitude}}</p> -->
    </td>
  </ng-container>

  <ng-container matColumnDef="drop_off_address">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Drop Up Address</th>
    <td mat-cell *matCellDef="let delivery">
      <p
        *ngIf="delivery.drop_off_address"
        matTooltip="latitude {{ delivery.drop_off_latitude }}&#10; longitude {{
          delivery.drop_off_longitude
        }}"
      >
        {{ delivery.drop_off_address }}
      </p>
      <p *ngIf="!delivery.drop_off_address" class="empty">no pick up address</p>
      <!-- <p>latitude: {{delivery.pick_up_latitude}}</p>
      <p>longitude: {{delivery.pick_up_longitude}}</p> -->
    </td>
  </ng-container>

  <ng-container matColumnDef="delivery_note">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Note</th>
    <td mat-cell *matCellDef="let delivery">
      <p *ngIf="delivery.delivery_note">{{ delivery.delivery_note }}</p>
      <p *ngIf="!delivery.delivery_note" class="empty">no note provided</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="store_reference">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Store reference</th>
    <td mat-cell *matCellDef="let delivery">
      <p *ngIf="delivery.store_reference">{{ delivery.store_reference }}</p>
      <p *ngIf="!delivery.store_reference" class="empty">no store reference</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="quote_id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Quote reference</th>
    <td mat-cell *matCellDef="let delivery">
      <p *ngIf="delivery.quote_id">{{ delivery.quote_id }}</p>
      <p *ngIf="!delivery.quote_id" class="empty">no quote id</p>
    </td>
  </ng-container>


  <ng-container matColumnDef="distance">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class='text-align'>Distance</th>
    <td mat-cell *matCellDef="let delivery" class='text-align'>
      <p *ngIf='delivery.distance'> {{delivery.distance}} m</p> 
      <p *ngIf='!delivery.distance'>No Distance</p> 
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="Columns"></tr>
  <tr mat-row class="action" *matRowDef="let delivery; columns: Columns" routerLink="/list-single-delivery/{{delivery.ID}}"></tr>
</table>
