<div class="heading">
    <div></div>
    <h4>Update Driver Details</h4>
    <mat-icon (click)="closeDialog()">clear</mat-icon>
  </div>
  
  <div class="edit-fields">
    <mat-form-field class="field" appearance="outline">
      <mat-label>driver name</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="d.name"
        placeholder="gouda cheese"
        name="title"
        required
      />
    </mat-form-field>
    <mat-form-field class="field" appearance="outline">
        <mat-label>driver email</mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="d.email"
          placeholder="John_Smith@gmail.com"
          name="email"
          required
        />
      </mat-form-field>
      <mat-form-field class="field" appearance="outline">
        <mat-label>driver contact </mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="d.mobile"
          placeholder="0834567821"
          name="mobile"
          required
        />
      </mat-form-field>
      <mat-form-field class="field" appearance="outline">
        <mat-label>driver employee code </mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="d.employee_code"
          placeholder="1234"
          name="employee_code"
          required
        />
      </mat-form-field>
      <!-- <mat-form-field class="field" appearance="outline">
        <mat-label>driver loop id </mat-label>
        <input
          type="text"
          matInput
          ngModel
          placeholder="389324h3"
          name="loop_driver_id"
          required
        />
      </mat-form-field>
      <mat-form-field class="field"  appearance="outline">
        <mat-label>Driver Vehicle Type </mat-label>
        <mat-select ngModel name="vehicle_type" class="select-panel">
          <mat-option *ngFor="let type of vehicle_types" [value]="type.name">
              <span class="flex">
                  <mat-icon>{{type.icon}}</mat-icon>
                  {{ type.name }}
              </span>
          </mat-option>
        </mat-select>
      </mat-form-field> -->
      <mat-form-field class="field"  appearance="outline">
        <mat-label>Payment method </mat-label>
        <mat-select  [(ngModel)]="d.pay_to_mobile" name="pay_to_mobile" class="select-panel" (selectionChange)="updatePaymentMethod(d.pay_to_mobile)">
          <mat-option *ngFor="let option of mobile_pay_options" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field  appearance="outline" *ngIf="!mobile_payment">
        <mat-label>Bank Name </mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="d.bank_name"
          placeholder="Absa"
          name="bank_name"
          required
        />
      </mat-form-field>
      <mat-form-field  appearance="outline"  *ngIf="!mobile_payment">
        <mat-label> Branch Code </mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="d.branch_code"
          placeholder="001234"
          name="branch_code"
          required
        />
      </mat-form-field>
      <mat-form-field  appearance="outline" *ngIf="!mobile_payment">
        <mat-label> Account Name </mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="d.account_name"
          placeholder="fnb aspire"
          name="account_name"
          required
        />
      </mat-form-field>
      <mat-form-field  appearance="outline" *ngIf="!mobile_payment">
        <mat-label> Account Number </mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="d.account_number"
          placeholder="3456 2718 2765 2712"
          name="account_number"
          required
        />
      </mat-form-field>
  
  
  <div class="save">
    <button mat-flat-button class="button cancel" (click)="closeDialog()">
      Cancel
    </button>
    <button
      mat-flat-button
      class="button"
      (click)="updateDriver()"
    >
      Save
    </button>
  </div>
  