
<div class="title">

    <mat-icon [routerLink]="'../'" class="material-icons-outlined">arrow_back</mat-icon>
    <h2>Incentive Details</h2>
</div>

<div class="incentive-selector">

    <div>
        <h3>Incentive Drivers</h3> 
        <table>
            <tr>
                <th>
                   Name
                </th> 
                <th>
                    ID
                </th>
                <th>
                    UserID
                </th>
                <th>
                    Remove
                </th>
            </tr>
            <tr *ngFor="let driver of drivers">
                <td class="pointer" (click)="showUser(driver.driver_id)">{{driver.driver_name}}</td> 
                <td >{{driver.ID}}</td>
                <td>{{driver.driver_id}}</td> 
                <td >
                    
                    <mat-icon (click)="removeDriver(driver)" class="material-icons-outlined">delete</mat-icon>
                </td>
            </tr>
        </table> 
    </div>
    <div>
        <div class="title">

            <h3>Available Drivers</h3>
            <input   (change)="searchUser()" [(ngModel)]="search" placeholder="Search driver">
            <mat-icon *ngIf="search==''" (click)="searchUser()" class="material-icons-outlined">search</mat-icon>
            <mat-icon  *ngIf="search!=''" (click)="clearSearch()" class="material-icons-outlined">close</mat-icon>
        </div>
        
        <table>
            <tr>
                <th>
                    UserID
                </th>
                <th>
                   Name
                </th>
                <th>
                   Mobile
                </th>
            </tr>
            <tr *ngFor="let user of user_accounts"> 
                <td class="pointer" (click)="showUser(user.user_id)">{{user.first_name}} {{user.last_name}}</td>
                <td>{{user.phone}}</td>
                <td>{{user.email}}</td>
                <td> 
                    <mat-icon (click)="insertDriver(user)" class="material-icons-outlined">add</mat-icon>
                </td>
            </tr>
        </table> 

        <div class="title">
            <div>Page</div>
            <mat-icon (click)="pre()" class="material-icons-outlined">navigate_before</mat-icon>
            <span>{{page}}</span>
            <mat-icon (click)="next()" class="material-icons-outlined">navigate_next</mat-icon>
        </div>
    </div>
</div>