import { ApiService } from 'src/app/services/api.service';
import { MiscService } from './../misc/misc.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DriversService {
  page = 1;
  limit = 10;
  search = '';
  drivers;

  operating_cities
  allCities 

  constructor(public misc: MiscService, public api: ApiService,) {}

  async getDrivers() {
    let drivers = await this.misc.apiCall(
      'auth',
      `admin/drivers?page=${this.page}&per_page=${this.limit}&search=${this.search}`
    );

    this.drivers = drivers['users'];

    if(drivers['users'] == null){
      this.drivers = []
    }

  }

  async listDriverCities(){
    let cities = await this.misc.apiCall(
      'godrive',
      `cities`
    )

    this.operating_cities = cities["cities"]
    this.allCities = cities["cities"]
  }

  searchCities(e){
    let capitalised_search = e.charAt(0).toUpperCase() + e.slice(1);
    const matching_cities = this.allCities.filter(city => city.city.includes(capitalised_search));
    this.operating_cities = matching_cities
  }


  async deleteDriver(driver) {
    await this.api
      .delete('fulfilment', `admin/drivers/${driver.ID}`)
      .subscribe((data: any) => {
      });

    // return deleted
  }

  async updateDriver(driver) {
    let updated = await this.api
      .patch(`fulfilment`, `admin/drivers/${driver.ID}`, driver)
      .toPromise();
    return updated;
  }

  searchReset() {
    this.page = 1;
    this.getDrivers();
  }

  clearSearch() {
    this.search = '';
    this.getDrivers();
  }

  setLimit(limit) {
    this.limit = limit;
    this.getDrivers();
  }

  previous() {
    if (this.page > 1) {
      this.page--;
      this.getDrivers();
    }
  }

  next() {
    this.page++;
    this.getDrivers();
  }

  start() {
    this.page = 1;
    this.getDrivers();
  }
}
