import { Injectable } from '@angular/core';
import { MessagesService } from '../messages.service';
import { MiscService } from '../misc/misc.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  dischem_users_page = 1
  dischem_users_limit = 10
  dischem_users_search = ""

  dischem_users

  constructor(private misc: MiscService, public message: MessagesService) { }

  async getDischemUsers(){
    await this.misc.apiCall(`auth`, `admin/dischem?page=${this.dischem_users_page}&per_page=${this.dischem_users_limit}&search=${this.dischem_users_search}`).then((res: any) => {
      this.dischem_users = res['users'];

      if (res.users == null && this.dischem_users_page > 1){
        this.message.showSnack("no more users to show")
        this.dischem_users_page--
        this.getDischemUsers()
      }
    });
  }
}
