<!-- ------------------ -->
<!--  PRODUCT DETAILS --> 
<!-- ------------------ -->
<span *ngIf='cart'>

    <div class="actions" >

        <div class="top-action-buttons">
            <!-- <button mat-flat-button class='button driver-button' (click)="goToLink(cart.ID)">driver panel</button> -->
        </div>

        <div class="actions-header action"  >
            <h3>CART ACTIONS & TRACKING</h3>
        </div>
  

        <!-- =================== -->
        <!--   CART TRACKING     -->
        <!-- =================== -->

        <div class="statuses">
            <div class='cart-status' *ngFor='let status of statuses'>
              <h5>{{status.title}}</h5>
                <mat-icon *ngIf='status.time' class='check'>check_circle</mat-icon>
                <mat-icon *ngIf='!status.time' class='check'>radio_button_unchecked</mat-icon>
                <mat-progress-bar *ngIf='status.time'  mode="determinate" value=100></mat-progress-bar>
                <p *ngIf='status.time'>{{status.time}}</p>
              </div>
          </div>

          <div class="products" >

              <div class="product-statuses">

                <!-- picked items -->
      
                  <div class="product-statuses" *ngIf='pickedItems.length > 0'>
                      <div class="picked product-status" >
                          <mat-icon>circle</mat-icon>
                          <p>picked</p>
                      </div>
                      <div class="product" *ngFor='let product of pickedItems' [style.margin-bottom]="product.otc > 0 ? '30px' : '' ">
                        <img src={{product.image_url}} alt="" onerror="this.src='assets/placeholders/placeholder-img.png' ">
                        <div class="name-size">
                            <div class="flex">
                            <h5 class='name'>{{product.name}}</h5>
                            <span class='qty'>{{product.quantity}}x</span>
                            <span class='price'>R {{product.price | number:"1.2-2"}}</span>
                        </div>
                            <span class='size'>{{product.size}}</span>
                        </div>
                        <span class="otc-tag" *ngIf="product.otc > 0 ">otc</span>
                      </div>
                  </div>
      
                  <!-- unpicked items -->
      
                  <div class="product-statuses" *ngIf='unfulfilledItems.length > 0' >
                      <div class="unfulfilled product-status" >
                          <mat-icon>circle</mat-icon>
                          <p>Not picked</p>
                      </div>
                      <div class="product" 
                            *ngFor='let product of unfulfilledItems' 
                            id="{{product.item_type == 'alternative_product' ? 'alternative': ''}}"
                            [style.margin-bottom]="product.otc > 0 ? '30px' : '' ">

                        <img src={{product.image_url}} alt="" onerror="this.src='assets/placeholders/placeholder-img.png' ">
                        <div class="name-size">
                            <div class="flex">
                            <h5 class='name'>{{product.name}}</h5>
                            <span class='qty'>{{product.quantity}}x</span>
                            <span class='price'>R {{product.price | number:"1.2-2"}}</span>
                        </div>
                            <span class='size'>{{product.size}} </span>
                        </div>
                        <span class="otc-tag" *ngIf="product.otc  > 0 ">otc</span>
                    </div>
                  </div>

                  <div class="product-statuses" *ngIf='alt_products.length > 0' >
                    <div class="alt-product product-status" >
                        <mat-icon>circle</mat-icon>
                        <p>alternative products</p>
                    </div>
                    <div class="product" *ngFor='let product of alt_products' [style.margin-bottom]="product.otc > 0 ? '30px' : '' " >
                            <img src={{product.image_url}} alt="" onerror="this.src='assets/placeholders/placeholder-img.png' ">
                            <div class="name-size">
                                <div class="flex">
                                <h5 class='name'>{{product.name}}</h5>
                                <span class='qty'>{{product.quantity}}x</span>
                                <span class='price'>R {{product.price | number:"1.2-2"}}</span>
                            </div>
                                <span class='size'>{{product.size}}</span>
                            </div>
                        <span class="otc-tag" *ngIf="product.otc > 0 ">otc</span>
                     </div>
                    </div>
      
                  <!-- out of stock items -->
      
                  <div class="product-statuses"  *ngIf='out_of_stock_items.length > 0' >
                      <div class="out-of-stock product-status">
                          <mat-icon>circle</mat-icon>
                          <p>out of stock </p>
                      </div>
                      <div class="product" *ngFor='let product of out_of_stock_items' [style.margin-bottom]="product.otc > 0 ? '30px' : '' ">
                            <img src={{product.image_url}} alt="" onerror="this.src='assets/placeholders/placeholder-img.png' ">
                            <div class="name-size">
                                <div class="flex">
                                <h5 class='name'>{{product.name}}</h5>
                                <span class='qty'>{{product.quantity}}x</span>
                                <span class='price'>R {{product.price | number:"1.2-2"}}</span>
                            </div>
                                <span class='size'>{{product.size}}</span>
                            </div>
                        <span class="otc-tag" *ngIf="product.otc > 0 ">otc</span>
                      </div>
                  </div>
              </div>
      
      
              <div class="cart-actions">
              <!-- ------------------ -->
              <!--    CART ACTIONS    --> 
              <!-- ------------------ -->

                  <div class="product-statuses"  >
                      <div class="product-status">
                          <mat-icon>touch_app</mat-icon>
                          <p>Actions </p>
                      </div>
                      <div class="product" *ngFor='let action of actions' >
                            <div class="name-size">
                                <div class="flex">
                                    <p class='action'>{{action.action}}</p>
                                    <p  class='action'>{{action.CreatedAt | date: 'MMM d, y - h:mm a'}}</p>
                                </div>
                                <div class="flex">
                                    <span class='size' *ngIf='action.item_name'>{{action.item_name}}</span>
                                    <span class='size' *ngIf='!action.item_name'>no item recorded</span>
                                    <span class='size'>{{action.name}}</span>
                                </div>
                            </div>

                      </div>
                  </div>
              </div>
    </div>

    <!-- ------------------ -->
    <!--  NUCLEUS TRACKING  --> 
    <!-- ------------------ -->

    <div class="actions-header nucleus-section"  *ngIf='cart.silo_id == 5'>
        <h3>NUCLEUS TRACKING DATA</h3>
      </div>
  
      <div class="statuses" *ngIf='cart.silo_id == 5'>
          <div class='cart-status' *ngFor='let action of nucleusActions'>
            <div class="action-label">
                <h5>{{action.action}}</h5>
                <p class='action-time'>{{action.time | date: " hh:mm aa"}}</p>
            </div>
              <mat-icon *ngIf='action.time' class='check'>check_circle</mat-icon>
              <mat-icon *ngIf='!action.time' class='check'>radio_button_unchecked</mat-icon>
              <mat-progress-bar *ngIf='action.time'  mode="determinate" value=100></mat-progress-bar>
              <p *ngIf='action.time'>{{action.time | date: "MM/dd/yy "}} </p>
           </div>
    </div>

