import { Router } from '@angular/router';
import { MessagesService } from 'src/app/services/messages.service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-push-create',
  templateUrl: './push-create.component.html',
  styleUrls: ['./push-create.component.scss']
})
export class PushCreateComponent implements OnInit {

  constructor(public api: ApiService,
              public message: MessagesService,
              public router: Router) { }

  ngOnInit(): void {
  }

  createNotification(push){
    let notification = {
    title: push.title,
    body: push.body,
  }

  this.api.post(`fulfilment`, `admin/notifications`, notification).subscribe((note: any) => {
    this.message.showSnack(`succesfully added new push notification`)
    this.router.navigate(["/push"])
  }, error => {
    // console.log(error)
    this.message.showSnack(error.statusText)
  })
}

}
