// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  catalogue: "https://api.api-dev.quench.org.za/catalogue/v1/",
  pay: "https://api.api-dev.quench.org.za/pay/v1/",
  fulfilment: "https://api.api-dev.quench.org.za/fulfilment/v1/",
  authentication: "https://api.api-dev.quench.org.za/auth/v1/",
  drive: "https://api.api-dev.quench.org.za/godrive/v1/",
  apiKey: "QuenchMobi",
  production: false,
  googlemapskey: 'AIzaSyADV0-6WD0wuSn-y1JCRMP55nPltMIRsw8',
  pusher: {
    app_id: '1205737',
    key: '7abdcf8d2d5c57135391',
    secret: "ef57764fc840277762e6",
    cluster: 'eu',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
