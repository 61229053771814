<div *ngIf="no_shoppers" class="no-data">
  <img src="assets/images/undraw_No_data.svg" alt="" />
  <h3>No pickers for this store set</h3>
</div>
<div class="btn">
  <button
    class="button"
    *ngIf="no_shoppers"
    mat-flat-button
    (click)="addShopperDialog()"
  >
    add
  </button>
</div>

<div *ngIf="!no_shoppers">
  <table mat-table [dataSource]="shoppers" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let shopper">
        <p class="p" class="action">{{ shopper.name }}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="store">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Store</th>
      <td mat-cell *matCellDef="let shopper">{{ shopper.store_name }}</td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
      <td mat-cell *matCellDef="let shopper" >
        <mat-icon *ngIf=" store && shopper.available && store.id == shopper.current_store_id; else notAvailable" class="true">check</mat-icon>
        <ng-template #notAvailable>
          <mat-icon class="false">clear</mat-icon>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="last_active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>last active at</th>
      <td mat-cell *matCellDef="let shopper">
          <div class="time">
            <P>{{ shopper.last_active_at.substring(0, 10) }}</P>
            <P class="order-time">{{
              shopper.last_active_at.substring(11, 16)
            }}</P>
          </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let shopper">{{ shopper.name }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let shopper">{{ shopper.email }}</td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>phone</th>
      <td mat-cell *matCellDef="let shopper">{{ shopper.phone }}</td>
    </ng-container>

    <ng-container matColumnDef="more">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let shopper">
        <mat-icon class="action" (click)="deleteDialog(shopper)"
          >delete_outline</mat-icon
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="chat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let shopper">
        <mat-icon
          class="action"
          matTooltip="chat to picker"
          routerLink="/users/{{ shopper.uid }}"
          >chat_bubble_outline</mat-icon
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="map">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let shopper" (click)="viewPickerLocation(shopper)">
        <mat-icon matTooltip="view picker on map" class="action"
          >location_on</mat-icon
        >
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row *matRowDef="let shopper; columns: Columns"></tr>
  </table>

  <div id="pager">
    <button
      id="{{ auth.expand ? 'create-expand' : 'create' }}"
      mat-flat-button
      class="button"
      (click)="addShopperDialog()"
    >
      add shoppers to store
    </button>

    <div class="page"></div>
  </div>
</div>

