<!-- <div class="search">
    <input matInput  #input>
</div> -->
    
    <div class='stores'>
  
    <!-- STORE PRODUCTS -->
      <table mat-table [dataSource]='orders' matSort>

        <ng-container matColumnDef="placed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Placed</th>
          <td mat-cell *matCellDef="let order" matTooltip='go to order' >
            <P *ngIf='order.order_date'>{{order.order_date.substring(0,10) }}</P> 
            <P *ngIf='order.order_date' class='order-time'>{{order.order_date.substring(14,19) }}</P> 
            <P *ngIf='!order.order_date'>No Order Date</P> 
          </td>
        </ng-container>
  
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> order Number</th>
          <td mat-cell *matCellDef="let order" matTooltip='go to order'> <p> # {{order.order_number}}</p> </td>
        </ng-container>
  
        <ng-container matColumnDef="store">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Store</th>
          <td mat-cell *matCellDef="let order" matTooltip='go to store' routerLink='/stores/{{order.store_id}}'> {{order.store_name}}</td>
        </ng-container>
      
  
        <ng-container matColumnDef="origin">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Origin</th>
          <td mat-cell *matCellDef="let order" > {{order.origin}}</td>
        </ng-container>
      
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
            <td mat-cell *matCellDef="let order" > {{order.amount}} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let order" > 
              <p *ngIf="order.status && order.status == 'CANCELLED' "       id='cancelled-status'    class='status'> {{order.status}}</p>
              <p *ngIf="order.status && order.status == 'PAID' "            id='paid-status'         class='status'> {{order.status}}</p>
              <p *ngIf="order.status && order.status == 'DISPATCHED' "      id='dispatched-status'   class='status'> {{order.status}}</p>
              <p *ngIf="order.status && order.status == 'COMPLETED' "       id='completed-status'   class='status'> {{order.status}}</p>
              <p *ngIf='!order.status'> No Status</p> 
            </td>
          </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="Columns"></tr>
        <tr mat-row class='action' routerLink='/orders/{{order.ID}}' *matRowDef="let order; columns: Columns;" ></tr>
      </table>
  
      <div class='mobile'>
        <mat-expansion-panel hideToggle  *ngFor='let order of orders '>
          <mat-expansion-panel-header >
            <mat-panel-title>
              {{order.full_address}}
            </mat-panel-title>
          </mat-expansion-panel-header>
            <span class="flex">
              <mat-title>label:</mat-title>
              <p>{{order.label}}</p>
            </span>
            <span class="flex">
              <mat-title>default:</mat-title>
              <p>	{{order.default_address}} </p>
            </span>
          </mat-expansion-panel>
      </div>
  
  
      <div id="pager" >
        <div class="page">  
  
        <div> Per page:</div>
        <div (click)="setLimit(10)" class="limit" >10</div>
        <div (click)="setLimit(15)" class="limit" >15</div>
        <div (click)="setLimit(20)" class="limit" >20</div>
        <div (click)="setLimit(50)" class="limit" >50</div>
        <div>Page:</div>
        <mat-icon (click)='previous()'>skip_previous</mat-icon>
        <div>{{page}}</div>
          <mat-icon (click)='next()'>skip_next</mat-icon>
          <div> </div>
      </div>
    </div>