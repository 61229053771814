<div class="back action" routerLink="/drivers">
  <mat-icon>arrow_back</mat-icon>
  <p>drivers</p>
</div>

<div class="create-card">
  <div class="header">
    <h3>Create new Driver</h3>
    <mat-icon routerLink="/drivers">clear</mat-icon>
  </div>

  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="firstForm">
      <form [formGroup]="firstForm">
        <div class="name">
          <mat-form-field appearance="outline" class="field">
            <mat-label>driver first name</mat-label>
            <input
              type="text"
              matInput
              ngModel
              placeholder="John Smith"
              name="name"
              required
              formControlName="first_name"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="field">
            <mat-label>driver last name</mat-label>
            <input
              type="text"
              matInput
              ngModel
              placeholder="John Smith"
              name="name"
              required
              formControlName="last_name"
            />
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline" class="field">
          <mat-label>driver Email </mat-label>
          <input
            type="email"
            matInput
            ngModel
            placeholder="John@gmail.com"
            name="email"
            required
            formControlName="email"
          />
          <mat-error
            *ngIf="
              firstForm.controls['email'].invalid &&
              (firstForm.controls['email'].dirty ||
                firstForm.controls['email'].touched)
            "
            >Please enter a valid email</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="field">
          <mat-label>driver Contact </mat-label>
          <input
            type="text"
            matInput
            ngModel
            placeholder="711234567"
            name="mobile"
            required
            formControlName="mobile"
            minlength="9"
            maxlength="9"
          />
          <mat-error
            *ngIf="
              firstForm.controls['mobile'].invalid &&
              (firstForm.controls['mobile'].dirty ||
                firstForm.controls['mobile'].touched)
            "
            >Please enter a valid contact</mat-error
          >
          <span matPrefix class="phone_code">+27 </span>
        </mat-form-field>

        <mat-form-field class="field" appearance="outline">
          <mat-label>Driver Vehicle Type </mat-label>
          <mat-select
            ngModel
            name="vehicle_type"
            class="select-panel"
            (selectionChange)="pickDriverVehicleType($event.value)"
            required
            formControlName="vehicle_type"
          >
            <mat-select-trigger class="selection">
              <mat-icon>{{ type.icon }}</mat-icon
              >&nbsp;{{ type.name }}
            </mat-select-trigger>
            <mat-option *ngFor="let type of vehicle_types" [value]="type">
              <mat-icon>{{ type.icon }}</mat-icon
              >{{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="field">
          <mat-label>Preferred work location</mat-label>
          <input
            type="string"
            matInput
            [(ngModel)]="preferred_location_city"
            placeholder="please select a city"
            name="driver"
            required
            [matAutocomplete]="auto"
            (input)="drivers.searchCities($event.target.value)"
            formControlName="city"
          />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option
              *ngFor="let city of drivers.operating_cities"
              [value]="city"
              (click)="selectPreferredLocation(city)"
            >
              <span style="width: 35%"> {{ city.city }} </span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>


        <mat-form-field appearance="outline" class="field">
          <mat-label>driver password </mat-label>
          <input
            id="password"
            type="text"
            matInput
            ngModel
            placeholder="Quench#1"
            name="loop_password"
            required
            formControlName="loop_password"
          />
          <mat-hint
            >password must include at least one of the following special
            characters (! @ # $ % ^ & *) and at least one number</mat-hint
          >
        </mat-form-field>

        <div class="save">
          <button
            mat-flat-button
            class="button"
            matStepperNext
            (click)="submitDriverInfo()"
          >
            Next
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step>
      <div class="driver-overview">
        <h5>Review driver details</h5>

        <p *ngIf="driver">
          <span>Driver Name:</span>
          <span class="confirm-detail"
            >{{ driver.first_name }} {{ driver.last_name }}</span
          >
        </p>

        <p *ngIf="driver">
          <span>Driver Email:</span>
          <span class="confirm-detail">{{ driver.email }}</span>
        </p>

        <p *ngIf="driver">
          <span>Driver Contact:</span>
          <span class="confirm-detail">{{ driver.mobile }}</span>
        </p>

        <p *ngIf="driver">
          <span>Vehicle Type </span
          ><span class="confirm-detail"> {{ type.name }}</span>
        </p>

        <p *ngIf="driver">
          <span>work location </span
          ><span class="confirm-detail"> {{ preferred_location }}</span>
        </p>
      </div>

      <div>
        <div class="back save">
          <button mat-flat-button class="button" matStepperPrevious>
            Back
          </button>
        </div>
        <div class="save">
          <button
            mat-flat-button
            class="button"
            matStepperNext
            (click)="submitDriver()"
          >
            Save
          </button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
