import { ApiService }                               from 'src/app/services/api.service'
import { AuthService }                              from 'src/app/services/auth.service'
import { ActivatedRoute }                           from '@angular/router'
import { MessagesService }                          from 'src/app/services/messages.service'
import { Component, OnInit, EventEmitter, Inject }  from '@angular/core'
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'


@Component({
  selector: 'app-product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.scss']
})
export class ProductCategoriesComponent implements OnInit {
  Columns = ['icon', 'name', 'add']

  id
  product
  categories
  
  page  = 1
  limit = 10

  constructor(public api: ApiService,
              public message: MessagesService,
              public route: ActivatedRoute,
              public auth: AuthService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getProductCategories()
    this.getProduct()
  }

  // ----------------------
  //   PRODUCT CATEGORIES
  // ----------------------

  getProductCategories() {
    this.api
      .getList('catelog', `products/${this.id}/categories?page=${this.page}&per_page=${this.limit}`).subscribe((categories: any) => {
          this.categories = categories
        },
        (error) => {
          this.message.showSnack(error.error)
        }
      )
  }

  setLimit(limit){
    this.limit = limit
    this.getProductCategories()
  }

  next(){
    this.page++
    this.getProductCategories()
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.getProductCategories()
    }
  }

  start(){
    this.page = 1
    this.getProductCategories()
  }

  // ==========================
  //   CATEGOERIES ADD DIALOG
  // ==========================


  getProduct(){
    this.api.getItem('catelog', 'products', this.id).subscribe((product: any) => {
      this.product = product
    })
  }

    // open dialog for banner update
    addProductCategories(){
      const dialog = this.dialog.open(ProductCategoriesAdd, {
        width: '1000px',
        height: '650px',
        data: {
          product: this.product
        }
      })
  
      dialog.afterClosed().subscribe(() => {
        this.getProductCategories()
      })
    }


}



// ============================
//    CATEGORIES ADD DIALOG
// ============================

@Component({
  selector: 'product-add-categories-dialog',
  templateUrl: 'product-categories-dialog.html',
  styleUrls: ['../../../pocket-mall/mall-stores/store-add.component.scss']
})

export class ProductCategoriesAdd {
  Columns = ['icon', 'name', 'add']

  product
  searchData
  
  page        = 1
  limit       = 6
  search      = ''
  inputTouch  = false

  // get section info after success
  onAdd       = new EventEmitter()
  
  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: { product, }, 
              private api : ApiService, 
              private message: MessagesService,  
              public dialogRef: MatDialogRef<ProductCategoriesAdd>) {

  }

  ngOnInit(): void {
    this.product = this.data.product
    this.getCategories()
  }

  getCategories(){
    this.api.getList("catelog", `categories?page=${this.page}&per_page=${this.limit}&search=${this.search}`)
    .subscribe((data:any)=>{
      this.searchData = data
    }, error => {
      this.message.showSnack(error.error)
    })
  }

// ========================
//  ARRAY OF ITEMS TO ADD
// ========================

  catArr = []

    toggleSelection(cat) {

      var storeRemove = this.catArr.map(item => item.id).indexOf(cat.id)

      if(storeRemove !== -1){
        this.catArr.splice(storeRemove, 1);
      } else if(storeRemove === -1){
        this.catArr.push(cat);
    }
  }


  addCategoriesToProduct(){
    let newItemsArr = []

    this.catArr.forEach(item => {
      let data = 
      {
        "category_id": item.id,
        "product_id": this.product.id
      }
      newItemsArr.push(data)
    })

    this.api.post('catelog', `productcategories`, newItemsArr).subscribe((data: any) => {
      this.message.showSnack(`succesfulle added product to categories`)
      this.closeDialog()
    })
  }

}