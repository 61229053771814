<div class="header">
    <h3>Update Reason</h3>
    <mat-icon (click)="closeDialog()" class="action">clear</mat-icon>
  </div>
  <hr />
  
  <form #asset = 'ngForm' (ngSubmit)='updateReason(asset.value)'>
    <mat-form-field appearance="outline" class="field">
      <mat-label> Reason</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="name"
        placeholder="cancelled delivery"
        name="name"
        required
      />
    </mat-form-field>
  
    <mat-form-field appearance="outline" class="field">
      <mat-label>Description</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="description"
        placeholder="order was cancelled"
        name="description"
      />
    </mat-form-field>
  
    <div class="save">
      <button mat-flat-button class="button">submit</button>
    </div>
  </form>
  