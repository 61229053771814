import { Component, OnInit } from '@angular/core';
import { DeliveriesService } from 'src/app/services/deliveries/deliveries.service';

@Component({
  selector: 'app-list-deliveries',
  templateUrl: './list-deliveries.component.html',
  styleUrls: ['./list-deliveries.component.scss']
})
export class ListDeliveriesComponent implements OnInit {

  constructor(public deliveries: DeliveriesService) { }

  Columns = [
    "created",
    "customer_name",
    "customer_contact",
    "pick_up_address",
    "drop_off_address",
    "delivery_note",
    "store_reference",
    "quote_id",
    "distance"
  ]

  ngOnInit(): void {
    this.deliveries.getAllDeliveries()
  }

}
