<div class="sub-menus-container">
  <div class="sub-menus" [style.margin-left]="auth.expand ? '225px' : '75px' ">
    <div class="sub-menu action" *ngFor='let submenu of submenus' (click)='navigateDashboard(submenu)'>
      <mat-icon class='material-icons-outlined'>{{submenu.icon}}</mat-icon>
      <p [style.font-weight]="selected_sub_menu == submenu.name ? 'bold' : '' ">{{submenu.name}}</p>
    </div>
  </div>
  </div>


  <app-overview *ngIf="selected_sub_menu == 'overview' "></app-overview>
  <app-dashboard-daily *ngIf="selected_sub_menu == 'daily/weekly' "></app-dashboard-daily>
  <app-sales *ngIf="selected_sub_menu == 'sales'"></app-sales>
  <app-market *ngIf="selected_sub_menu == 'market'"></app-market>
  <app-dashboard-orders *ngIf="selected_sub_menu == 'orders'"></app-dashboard-orders>
