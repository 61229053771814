import { ApiService }              from '../../../services/api.service'
import { ActivatedRoute }          from '@angular/router'
import { MessagesService }         from '../../../services/messages.service'
import { Component, OnInit ,Input} from '@angular/core'

@Component({
  selector: 'app-store-orders',
  templateUrl: './store-orders.component.html',
  styleUrls: ['./store-orders.component.scss']
})
export class StoreOrdersComponent implements OnInit {
  Columns = ['placed', 'store', 'customer', 'order_num', 'status', 'origin', 'phone','distance', 'amount']

  inputTouch = false

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }

  @Input('storeId')
  storeId
  store_orders

  search = ''

  page      = 1
  limit     = 10
  retries   = 0
  no_orders = false
  
  constructor(

    public messages:MessagesService,
    private api: ApiService,
    public route: ActivatedRoute
  ) { }


  ngOnInit(): void {
    this.storeId = this.route.snapshot.paramMap.get('id')
    this.getStoreOrders();
    localStorage.setItem('storeTab', 'orders')
  }

  getStoreOrders(){
      this.api.getList("fulfilment", `admin/stores/${this.storeId}/orders?page=${this.page}&per_page=${this.limit}&search=${this.search}`)
      .subscribe((data:any)=>{
        console.log(data)
        if(data.data.length == 0){
          this.no_orders = true
        } else {
        this.store_orders = data["data"]
        } 
      
        },error=>{
          this.messages.showSnack(error.error)
          if(this.retries < 3){
            if(error.status !== 401){
              this.retries++
              this.getStoreOrders()
            }
          }
      })
  }

  setLimit(limit){
    this.limit = limit;
    this.getStoreOrders()
  }

  next(){
    this.page++
    this.getStoreOrders()
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.getStoreOrders();
    }
  }

  start(){
    this.page = 1
    this.getStoreOrders()
  }
}
