import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountsService } from 'src/app/services/accounts/accounts.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  Columns = [
    // 'name',
    // 'email',
    'accounts',
    'assets',
    'earnings',
    'payments',
  ];

  constructor(public route: ActivatedRoute, public accounts: AccountsService) { }

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id')
    this.accounts.getUserAccountSummary(id)
    console.log(this.accounts.account_summary)

  }

}
