<div *ngIf='product' class="detail" >

  <span class='otc-tag' *ngIf='product.otc'>otc product</span>

  <div class="image"  >
    <img src='{{product.image}}' alt="" onerror="this.src='assets/placeholders/logo-placeholder.png' "  >
  </div>  

  <div class="btn">
    <button mat-flat-button (click)='editProductImage()'>update image</button>
  </div>


  <div class="headings">
    <div class="name">
      <h2>{{product.name}}</h2>
      <mat-icon matTooltip='more' [matMenuTriggerFor]="menu" class='action'>more_horiz</mat-icon> 
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)='deleteProduct()'>
          <mat-icon >delete_outline</mat-icon>
          <span >delete product</span>
        </button>
        <button mat-menu-item (click)='addStoresToProductDialog()'>
          <mat-icon class='material-icons-outlined'>store_front</mat-icon>
          <span >add product to stores </span>
        </button>
      </mat-menu>
    </div>
    <p class='description'>{{product.description}}</p>
  </div>

  <!-- ============================ -->
  <!--      PRODUCT DETAILS         -->
  <!-- ============================ -->

  <hr>
    <div class="name action">
      <h4>Product Details</h4>
      <mat-icon (click)='editProductDialog()'>edit</mat-icon>
    </div>
  <hr>

  <div class="center">

    <div class="info">
        <div class="info-box">
          <p class='field'>size</p>
          <p *ngIf='product.size'>{{product.size }}</p>
          <p *ngIf='!product.size'>n/a</p>
        </div>
        <div class="info-box">
          <p class='field'>price</p>
          <p *ngIf='product.price'>R {{product.price | number:"1.2-2" }}</p>
          <p *ngIf='!product.price'>n/a</p>
        </div>
        <div class="info-box">
          <p class='field'>sku code</p>
          <p *ngIf='product.sku_code'>{{product.sku_code}}</p>
          <p *ngIf='!product.sku_code'>n/a</p>
        </div>
        <div class="info-box">
          <p class='field'>barcode</p>
          <p *ngIf='product.barcode'>{{product.barcode}}</p>
          <p *ngIf='!product.barcode'>n/a</p>
        </div>
        <div class="info-box" *ngIf='product.otc'>
          <p class='field'>otc</p>
          <p *ngIf='product.otc'>{{product.otc}}</p>
          <p *ngIf='!product.otc'>n/a</p>
        </div>
        <div class="info-box">
          <p class='field'>retailer</p>
          <p *ngIf='product.retailer_id'>{{product.retailer_id}} - {{retailer}} </p>
          <p *ngIf='!product.retailer_id'>n/a</p>
        </div>
        <div class="info-box">
          <p class='field'>maximum quantity allowed</p>
          <p *ngIf='product.max_quantity'>{{product.max_quantity}} </p>
          <p *ngIf='!product.max_quantity'>n/a</p>
        </div>
    </div>

  </div>

  <!-- <div class="btn">
    <button mat-flat-button class='button' class='delete'>delete product</button>
  </div> -->

  <!-- ============================ -->
  <!--      PRODUCT DIMENSIONS      -->
  <!-- ============================ -->

    <hr>
      <div class="name action">
        <h4>Product Dimensions</h4>
        <mat-icon (click)='editProductDiamensions()'>edit</mat-icon>
      </div>
    <hr>

    <div class="center">

      <div class="info">
          <div class="info-box">
            <p class='field'>height</p>
            <p *ngIf='product.height'>{{product.height}}cm</p>
            <p *ngIf='!product.height'>n/a</p>
          </div>
          <div class="info-box">
            <p class='field'>width</p>
            <p *ngIf='product.width'>{{product.width}}cm</p>
            <p *ngIf='!product.width'>n/a</p>
          </div>
          <div class="info-box">
            <p class='field'>length</p>
            <p *ngIf='product.length'>{{product.length}}cm</p>
            <p *ngIf='!product.length'>n/a</p>
          </div>
          <div class="info-box">
            <p class='field'>weight</p>
            <p *ngIf='product.weight'>{{product.weight}}kg</p>
            <p *ngIf='!product.weight'>n/a</p>
          </div>
      </div>
    </div>