<div class='action' id="shade" *ngIf='edit' ></div>


<div *ngIf='mall' class="detail" >


  <div class="name">
    <h1>{{mall.name}}</h1>
    <div class="actions">
      <!-- <mat-icon class=action (click)='editMall()'>edit</mat-icon> -->
      <!-- <button mat-flat-button>update image</button> -->
      <button mat-flat-button class='button' (click)='editMall()'>update mall</button>
    </div>
  </div>


  <div class="info" >

    <div class="image">
      <div class="vertical-center">
        <img src='{{mall.image}}' alt="" onerror="this.src='assets/images/error_image_1500.png' " id='image'>
      </div>
      <button mat-flat-button  (click)=' updateBannerDialog()'>edit banner image</button>

    </div>

      <div class="fields">
          <div class="field">
              <mat-icon class='material-icons-outlined'>location_on</mat-icon>
              <p *ngIf='mall.physical_address'>{{mall.physical_address}}</p>
              <p *ngIf='!mall.physical_address'>no address</p>
          </div>
          <div class="field">
              <mat-icon class='material-icons-outlined'>description</mat-icon>
              <p *ngIf='mall.description'>{{mall.description}}</p>
              <p *ngIf='!mall.description'>no description</p>
          </div>
          <div class="field">
              <mat-icon class='material-icons-outlined'>email</mat-icon>
              <p *ngIf='mall.email'>{{mall.email}}</p>
              <p *ngIf='!mall.email'>no email</p>
          </div>
          <div class="field">
              <mat-icon class='material-icons-outlined'>phone</mat-icon>
              <p *ngIf='mall.phone'>{{mall.phone}}</p>
              <p *ngIf='!mall.phone'>no phone</p>
          </div>
          <div class="field">
              <mat-icon class='material-icons-outlined'>info</mat-icon>
              <p *ngIf='mall.longitude'>{{mall.longitude}} <span class='lat-lng'>longitude</span></p>
              <p *ngIf='!mall.longitude'>n/a</p>
          </div>
          <div class="field">
              <mat-icon class='material-icons-outlined'>info</mat-icon>
              <p *ngIf='mall.latitude'>{{mall.latitude}}<span class='lat-lng'>latitude</span></p>
              <p *ngIf='!mall.latitude'>n/a</p>
          </div>
      </div>

  </div>









    <!-- <div class="image" id='banner'>
        <img src='{{mall.image}}' alt="" onerror="this.src='assets/images/error_image_1500.png' "  class='banner'>
        <mat-icon (click)='editImage()' class='action'>edit</mat-icon>
    </div>  

      <div class="headings">
        <div class="name">
          <h2>{{mall.name}}</h2>
          <mat-slide-toggle [(ngModel)]="mall.active" (change)="toggleMallStatus()"></mat-slide-toggle>
        </div>
      </div> -->

    <!-- ============================= -->
    <!--        MALL DETAILS           -->
    <!-- ============================= -->

    <!-- <hr>
    <div class="name action">
      <h4>Mall Details</h4>
      <mat-icon (click)='editMall()'>edit</mat-icon>
    </div>
  <hr> -->

    <!-- <div class="center">

    <div class="info">
        <div class="info-box">
            <p class='field'>email</p>
            <p *ngIf='mall.email'>{{mall.email}}</p>
            <p *ngIf='!mall.email'>n/a</p>
        </div>
        <div class="info-box">
            <p class='field'>phone</p>
            <p *ngIf='mall.phone'>{{mall.phone}}</p>
            <p *ngIf='!mall.phone'>n/a</p>
        </div>
        <div class="info-box">
            <p class='field'>about</p>
            <p *ngIf='mall.about'>{{mall.description}}</p>
            <p *ngIf='!mall.about'>n/a</p>
        </div>
        <div class="info-box">
            <p class='field'>latitude</p>
            <p *ngIf='mall.latitude'>{{mall.latitude}}</p>
            <p *ngIf='!mall.latitude'>n/a</p>
        </div>
        <div class="info-box">
            <p class='field'>longitude</p>
            <p *ngIf='mall.longitude'>{{mall.longitude}} </p>
            <p *ngIf='!mall.longitude'>n/a</p>
        </div>

    </div> -->


  <!-- ============================ -->
  <!--    EDIT MALL DETAILS        -->
  <!-- ============================ -->

  <div class="edit" *ngIf='edit'>

    <div class="heading">
      <div></div>
      <h4>Edit mall Details</h4>
      <mat-icon (click)='editMall()' >clear</mat-icon>
    </div>
  
        <div class="edit-fields">
        <div class="fields ">
            <mat-form-field appearance="outline">
            <mat-label>mall name</mat-label>
            <input matInput placeholder="blue root mall" [(ngModel)]="name" >
            <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
            </mat-form-field>
            <mat-form-field appearance="outline">
            <mat-label>description</mat-label>
            <input matInput placeholder="a very cool mall" [(ngModel)]="desc" >
            <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
            </mat-form-field>
            <mat-form-field appearance="outline">
            <mat-label>email</mat-label>
            <input matInput placeholder="johndoe@gmail.com" [(ngModel)]="email" >
            <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
            </mat-form-field>
            <mat-form-field appearance="outline">
            <mat-label>phone</mat-label>
            <input matInput placeholder="073 123 8978" [(ngModel)]="phone" >
            <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
            </mat-form-field>
            <mat-form-field appearance="outline" class='field'>
              <mat-label>current address</mat-label>
              <input matInput 
                    placeholder="12 bob street" 
                    [(ngModel)]="address" 
                    >
              <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
              </mat-form-field>
            <mat-form-field appearance="outline" class='field'>
              <mat-label>address lookup</mat-label>
              <input matInput 
                    placeholder="12 bob street" 
                    [(ngModel)]="address" 
                    matGoogleMapsAutocomplete
                    type="address,place"
                    country="za"
                    (onAutocompleteSelected)="onAutocompleteSelected($event)"
                    >
              <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
              </mat-form-field>
        </div>


        <div class="save">
            <button mat-flat-button class='button cancel' (click)='editMall()'> Cancel</button>
            <button mat-flat-button class='button' (click)='updateMall()'> Save</button>
        </div>
      
        </div>
    </div>

</div>