import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { AgmMap, MapsAPILoader } from '@agm/core';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { MessagesService } from 'src/app/services/messages.service';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular-material-extensions/google-maps-autocomplete';

@Component({
  selector: 'app-zone',
  templateUrl: './zone.component.html',
  styleUrls: ['./zone.component.scss']
})

export class ZoneComponent implements OnInit {
  @ViewChild(AgmMap) AGMmap: any
  zone:any
  zoneArea:any;
  updateNotify = false;
  zoneAreaContorl = new FormControl();
  citySearch = new FormControl();
  constructor(
    public zonesService: ZonesService,
    public router: Router,
    public messageService: MessagesService,
    private mapsAPILoader: MapsAPILoader
    ) { 
      this.mapsAPILoader.load().then(() => {
        this.zonesService.loading = true;
        this.zonesService.mapsLoaded = true;
        if(localStorage.getItem('activeZone') && localStorage.getItem('activeZone') != ""){
          this.zone = JSON.parse(localStorage.getItem('activeZone'))
          this.getZone();
          }
        }
      );
      this.citySearch.valueChanges.subscribe(newValue => {
        let searchCity:string = newValue
        if(searchCity != ''){
         if(this.filterCities(searchCity) != []){
          this.zonesService.filteredCities = this.filterCities(searchCity)
          if(this.zonesService.filteredCities.length <= 0){
            this.messageService.showSnack('No cities found, please try again')
           }
         }   
        }
       });
       this.zoneAreaContorl.valueChanges.subscribe((zoneAreaText:any) => {
        if(zoneAreaText != this.zone.area){
          this.zone.area = zoneAreaText
          this.updateNotify = true
          this.messageService.showSnack('Please update before leaving.')
        }
       })
    }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false
    }
  }

  toggleAreaEdit(){
    this.zonesService.editAreaToggle = !this.zonesService.editAreaToggle
    this.editPolygons()
    this.editToggle()
  }

  filterCities(search: string) {
    return this.zonesService.cities.filter((city:any) => city.city.toLowerCase().includes(search.toLowerCase()))
  }

  ngOnDestroy(): void {
    localStorage.removeItem('activeZone')
  }

  editToggle(){
    this.zonesService.edit = !this.zonesService.edit
    this.zonesService.addingZone = false;
    this.removePolygons()
    this.getZone()
  }

  drawPolygons(){
    this.zonesService.zones.forEach((zone:any, i) => {
      if(this.zonesService.zones[i] != undefined){
        
        this.zonesService.zones[i].geopoints.forEach((geopoint:any) => {
          geopoint['lat'] = geopoint.latitude
          geopoint['lng'] = geopoint.longitude
        });
  
        this.zonesService.polygon = new google.maps.Polygon({
          paths: this.zonesService.zones[i].geopoints,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
        })
    
        this.zonesService.polygon.addListener('click', (event) => {

        })
  
        this.zonesService.polygon.addListener('dblclick', (event) => {
          // console.log(event);
          this.editToggle()
        })
    
        this.zonesService.polygon.strokeColor = '#000'
        this.zonesService.polygon.fillColor = '#000'
      
        this.zonesService.polygons.push(this.zonesService.polygon)
        this.zonesService.polygon?.setMap(this.AGMmap)
       }
    })
  }

  lookupByCity(selectedCity){
    let matchingCity = false;
    this.zonesService.cities.forEach((city:any) => {
        if(city.city == selectedCity && !matchingCity){
          matchingCity = true
          selectedCity = city
        }
    })

    this.zonesService.zoom = 9
    this.zonesService.lat = Number(selectedCity.lat)
    this.zonesService.lng = Number(selectedCity.lng)

  }

  getZone(){
    this.zonesService.zoom = 10
    this.zonesService.zones = []
    this.zonesService.zones.push(this.zone)
    this.zonesService.lat = this.zone.geopoints[0].latitude
    this.zonesService.lng = this.zone.geopoints[0].longitude
    this.zonesService.loading = false;
    this.zoneArea = this.zone.area
    this.drawPolygons()
     if(this.zonesService.edit){
      this.editPolygons()
      } else {
        this.drawPolygons()
     }
  }

  onMapReady(map) {

    this.AGMmap = map

    setTimeout(() => {
      this.drawPolygons()
    }, 800)

  }

  editPolygons(){

    let zones = []
    
    this.zonesService?.zones?.forEach(zone => {

      zone?.geopoints?.forEach((geopoint,i) => {

        let zone = this.zonesService?.zones[i]

        if(this.zonesService.zones[i] != undefined){

          this.zonesService.polygon = new google.maps.Polygon({
            paths: this.zonesService.zones[i].geopoints,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            editable: true,
            draggable: true,
          })
  
          this.zonesService.polygons.push(this.zonesService.polygon)
          this.zonesService.polygon?.setMap(this.AGMmap)
      
            this.zonesService.polygon.getPaths().forEach(path => {
              google.maps.event.addListener(path, 'insert_at', function () {
                    
                let geopoints = []
          
                const len = path.getLength();

                for (let i = 0; i < len; i++) {
                  geopoints.push(
                    path.getAt(i).toJSON()
                  )
                }   

                localStorage.setItem('geopoints', JSON.stringify(geopoints))

                zone.geopoints = geopoints

                var isZone = zones.map(z => z.id).indexOf(zone.id)
    
                if(isZone === -1){
                 zones.push(zone)
                 zones[zones.length -1].geopoints = geopoints
                }

                localStorage.setItem('zones', JSON.stringify(zones))
      
              })
        
                google.maps.event.addListener(path, 'set_at', function () {
      
                  let geopoints = []
          
                  const len = path.getLength();
  
                  for (let i = 0; i < len; i++) {
                    geopoints.push(
                      path.getAt(i).toJSON()
                    )
                  }   
                
                  // console.log(geopoints)

                  localStorage.setItem('geopoints', JSON.stringify(geopoints))

                  zone.geopoints = geopoints

                  var isZone = zones.map(z => z.id).indexOf(zone.id)
      
                  if(isZone === -1){
                   zones.push(zone)
                   zones[zones.length -1].geopoints = geopoints
                  }

                  localStorage.setItem('zones', JSON.stringify(zones))

                })
            })
  
        }
      }
      );      
    }
    );
  }

  // gets address from input
  async onLocationSelected(location: Location) {
    this.zonesService.zoom = 17
    this.zonesService.lat = location.latitude;
    this.zonesService.lng = location.longitude;
  }

  deleteZone(zone:any){
    this.zonesService.deleteZone(zone.id)
      .then((res:any) => {
        console.log(res)
        this.messageService.showSnack('Zone deleted.')
        localStorage.removeItem('activeZone')
        this.router.navigate(['zones-list'])
      })
      .catch((err:any) => {
        console.log(err)
        this.messageService.showSnack(`There has been an error. status code: ${err.status_code}`)
      })
  }

  updatePolygon(){
    let zones = JSON.parse(localStorage.getItem('zones'))

    if(zones != [] && zones != null){
      zones.forEach(z => {
 
        z.geopoints.forEach((geopoint:any) => {
          geopoint.lat =  Number(JSON.stringify(geopoint.lat).slice(0,10))
          geopoint.lng = Number(JSON.stringify(geopoint.lng).slice(0,10))
          geopoint["latitude"] =  Number(JSON.stringify(geopoint.lat).slice(0,10))
          geopoint["longitude"] = Number(JSON.stringify(geopoint.lng).slice(0,10))
        });
  
        if(z.geopoints[z.geopoints.length-1].lat != z.geopoints[0].lat){
          z.geopoints = z.geopoints.concat(z.geopoints[0])
        }
  
        let zone = {
          id: z.id,
          geopoints: z.geopoints
        }
  
        this.zone.geopoints = z.geopoints
        localStorage.setItem('activeZone', JSON.stringify(this.zone))
  
      this.zonesService.updateZone(zone)
        .then((res:any) => {
  
          if(zones.length < 2){
            this.messageService.showSnack('Succesfully updated zone.')
          } 
          else {
           this.messageService.showSnack('Succesfully updated zones.')
          }
  
         setTimeout(() => {
           localStorage.removeItem('zones')
           this.redrawPolygons()
         }, 2000);
  
        })
        .catch((err:any) => {
  
          console.log(err)
          this.messageService.showSnack('Geofence not updated, points are incorrect. Please try again.')
  
        })
      })
    }
 
   }

   redrawPolygons(){
    this.zonesService.polygons.forEach((poly:any) => {
      poly.setMap(null)
    })
    this.zonesService.edit = false
    this.getZone()
  }

  removePolygons(){
    this.zonesService.polygons.forEach((poly:any) => {
      poly.setMap(null)
    })
  }

}
