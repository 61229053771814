<div class="header">
    <h3>Bank Details</h3>
    <mat-icon class="action" (click)="closeDialog()">clear</mat-icon>
  </div>
  <hr />
  
  <div class="body">
    <p>
      <span class="field">Bank name:</span>
      <span class="field-info" *ngIf="driver.bank_name"
        >{{ driver.bank_name }}</span
      >
      <span class="field-info" *ngIf="!driver.bank_name"
        >bank details not provided</span
      >
    </p>
    <p>
      <span class="field">Branch code:</span>
      <span class="field-info" *ngIf="driver.branch_code"
        >{{ driver.branch_code }}</span
      >
      <span class="field-info" *ngIf="!driver.branch_code"
        >bank details not provided</span
      >
    </p>
    <p>
      <span class="field">Account name:</span>
      <span class="field-info" *ngIf="driver.account_name"
        >{{ driver.account_name }}</span
      >
      <span class="field-info" *ngIf="!driver.account_name"
        >bank details not provided</span
      >
    </p>
    <p>
      <span class="field">Account number:</span>
      <span class="field-info" *ngIf="driver.account_number"
        >{{ driver.account_number }}</span
      >
      <span class="field-info" *ngIf="!driver.account_number"
        >bank details not provided</span
      >
    </p>
  </div>
  
  <div class="btn">
    <button mat-flat-button (click)="closeDialog()">done</button>
  </div>
  