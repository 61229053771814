<div class="messages">

  <div class="user-list">
    <h3>Messages <span *ngIf='count' class='count'>({{count}})</span></h3>

    <div class="search">
      <mat-icon (keydown.enter)='searchReset()' matTooltip='search' class='search-icon' [style.left]="inputTouch ? '-20px' : '10px' ">search</mat-icon>
      <mat-icon *ngIf='search' (click)='clearSearch()'  matTooltip='clear' class='clear-icon'>clear</mat-icon>
      <input matInput (keydown.enter)='searchReset()' [(ngModel)]="search" placeholder="Search"  #input (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
    </div>

    <div class="users">

      <div *ngFor='let user of users' (click)='listUserMessages(user)' [ngClass]="selected == user.uid ? 'selected user-container' : 'user-container action' ">
        <div class="user">
          <span class="logo" [style.background]="selected == user.uid  ? '#4c66cc' : '' "> {{user.sender_name.substring(0,1)}} </span>
          <span class="name-message">
            <h4 class="name">{{user.sender_name}} <span class='picker-tag' *ngIf='user.cart_id'>picker</span> </h4>
            <p [ngClass]="user.not_read > 0 ? 'message-preview-unread message-preview' : 'message-preview' ">{{user.content}}</p>
          </span>
        </div>
        <div class="time-count">
          <div class="time">{{user.CreatedAt | date:'HH:mm'}}</div>
          <span class="message-count" *ngIf='user.not_read > 0'>{{user.not_read}}</span>
          <!-- <span routerLink="/cart/{{user.cart_id}}">{{user.cart_id}}</span> -->
        </div>
      </div>
    </div>

  </div>


  <div class="compose">
    <div class="chat-header"  *ngIf='selectedUser; else noSelectedUser'>
      <div class="logo">{{selectedUser.sender_name.substring(0,1)}}</div>
      <div class="name-message">
        <h4 class="name action" matTooltip='navigate to user' routerLink='/users/{{selectedUser.uid}}'>{{selectedUser.sender_name}} 
          <span class='picker-tag' *ngIf='cart_id'>picker</span> 
        </h4>
        <p class="message-preview origin" *ngIf='selectedUser.origin'>{{selectedUser.origin}}</p>
        <p class="message-preview origin" *ngIf='!selectedUser.origin'>origin not recorded</p>
      </div>
      <div class='cart-navigate action' routerLink='/cart/{{selectedUser.cart_id}}' *ngIf='selectedUser.cart_id'>
        <button mat-flat-button class='button'>go to cart</button>
      </div>
    </div>

      <ng-template #noSelectedUser>
        <div class="chat-header">
          <h4 class="name no-name"> select conversation to get started</h4>
        </div>
      </ng-template>

      <div class="chat-body"  #scrollMe    >
        <span *ngIf='selectedUserMessages; else noSelectedUserMessages'>
          <div class="dates" *ngFor='let message of selectedUserMessages'>
              <div class="date">
                  <p>{{message.date}}</p>
              </div>
              <div *ngFor="let chatMessage of message.messages; let last = last "
                    [class]="chatMessage.is_user ? 'chat-message chat-user':'chat-message chat-admin' "
                    [style.margin-bottom]="chatMessage.ID == lastMessageInChat.ID ? margin : '' "
                    >
                  <span class='message'>
                      <p class='content'>{{chatMessage.content}} 
                        <mat-icon class='action' 
                        matTooltip='go to cart' 
                        *ngIf='chatMessage.cart_id'
                        routerLink='/cart/{{chatMessage.cart_id}}'>shopping_cart_checkout</mat-icon>
                      </p>
                      <p class='time' *ngIf='chatMessage.is_user'>{{chatMessage.CreatedAt.substring(11,16)}}</p>
                      <p class='time' *ngIf='!chatMessage.is_user'>{{chatMessage.read}}</p>
                  </span>
              </div> 
          </div>
        </span>

        <ng-template #noSelectedUserMessages>
            <div class="no-chat-selected">
              <img src="assets/placeholders/chat.png" alt="">
            </div>
        </ng-template>
    </div>

    <div class="chat-reply" >
      <div class="compose-container">
          <textarea
              [(ngModel)]="reply"
              class='text-area'
              rows='{{rows}}'
              (ngModelChange)="textareaExpand($event)">
          </textarea>
          <mat-icon [style.color]="reply ? 'var(--quenchPink)' : '' " (click)="adminReply()"  class='action'>send</mat-icon>
      </div>
    </div>

  </div>
</div>

<div id="pager" >
  <div class="page"> 

  <div> Per page:</div> 
  <span class="silo-filter pagination">
    <button mat-button [matMenuTriggerFor]="paginate">
      <span>{{limit}}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #paginate="matMenu" >
      <button mat-menu-item (click)="setLimit(10)">10</button>
      <button mat-menu-item (click)="setLimit(20)">20</button>
      <button mat-menu-item (click)="setLimit(50)">50</button>
    </mat-menu>
  </span>

  <div (click)='start()' matTooltip="Skip to start" class='action' *ngIf='page > 1'>back to start</div>
  <p (click)='previous()' class='action'>previous</p>
  <div>{{page}}</div>
    <p (click)='next()' class='action'>next</p>
    <div> </div>
  </div>
</div>


