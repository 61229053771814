import { AuthService } from './../../../services/auth.service';
import { ActivatedRoute }    from '@angular/router'
import { Component, OnInit, Inject, EventEmitter  } from '@angular/core'
import { MessagesService }   from 'src/app/services/messages.service'
import { ApiService }        from 'src/app/services/api.service'
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'



@Component({
  selector: 'app-mall-sections',
  templateUrl: './mall-sections.component.html',
  styleUrls: ['./mall-sections.component.scss']
})
export class MallSectionsComponent implements OnInit {
  Columns = ['title', 'type', 'active', 'more']

  id
  sections = []

  constructor(public api    : ApiService,
              public route  : ActivatedRoute,
              public message: MessagesService,
              public auth   : AuthService,
              public dialog: MatDialog
              ) { }

  ngOnInit(): void {
    this.id = JSON.parse(this.route.snapshot.paramMap.get('id'))
    this.getMallSections()
  }

  getMallSections(){
    this.api.getList('catelog', `malls/${this.id}/sections`).subscribe((sections: any) => {
        this.sections = sections
    })
  }

  toggleSectionStatus(section){

    let sect = {
      id: section.section_id,
      active: false
    }

    if(!section.active){
      sect.active = true
    }

    this.api.patch('catelog', `sections`, sect).subscribe((section: any) => {
      if(section.active){
      this.message.showSnack(`Succesfully activated ${section.title}`)
      } else {
      this.message.showSnack(`Successfully de-activated ${section.title}`)
      } 
      this.getMallSections()
      }, error => {
      this.message.showSnack(error.error)
    })
  }


  // =======================
  //   SECTIONS REMOVE DIALOG
  // =======================


    // open dialog for banner update
    removeMallSection(section){
      const dialog = this.dialog.open(RemoveMallSectionDialog, {
        width: '550px',
        height: '200px',
        data: {
          section: section
        }
      })
  
      dialog.afterClosed().subscribe(() => {
        this.getMallSections()
      })
    }


  // ====================
  // SECTIONS ADD DIALOG
  // ====================


    // open dialog for banner update
    addSectionsToMall(){
      const dialog = this.dialog.open(mallAddSectionsDialog, {
        width: '1000px',
        height: '650px',
        data: {
          mall: this.id
        }
      })
  
      dialog.afterClosed().subscribe(() => {
        this.getMallSections()
      })
    }
}

// ============================
//    SECTION REMOVE DIALOG
// ============================

@Component({
  selector: 'delete-dialog',
  templateUrl: '../../../components/dialogs/delete-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss']
})

export class RemoveMallSectionDialog {

  
  inputTouch  = false
  heading        = `section from mall`
  body        = `Are you sure you want to remove this item from this mall? You can always add it again later.`


  item
  section

  // get section info after success
  onAdd       = new EventEmitter()
  
  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }


  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: { section }, 
              private api : ApiService, 
              private message: MessagesService,  
              public dialogRef: MatDialogRef<RemoveMallSectionDialog>) {

  }

  ngOnInit(): void {
    this.section = this.data.section

  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

  removeItem(){
    // console.log(this.section)

    this.api.delete('catelog', `malls/sections/${this.section.id}`).subscribe((data: any) => {
      this.message.showSnack('succesfully removed section from mall')
      this.closeDialog()
    })
  
  }

}



// ============================
//      STORE ADD DIALOG
// ============================

@Component({
  selector: 'mall-add-sections-dialog',
  templateUrl: 'mall-add-section-dialog.html',
  styleUrls: ['../../../components/dialogs/add-items-dialog.component.scss']
})

export class mallAddSectionsDialog {
  Columns = ['name', 'type', 'add']

  mall
  
  sections    = []
  page        = 1
  limit       = 1000
  search      = ''
  silo_id     = 6
  inputTouch  = false

  // get section info after success
  onAdd       = new EventEmitter()
  
  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }


  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: { mall }, 
              private api : ApiService, 
              private message: MessagesService,  
              public dialogRef: MatDialogRef<mallAddSectionsDialog>) {

  }

  ngOnInit(): void {
    this.mall = this.data.mall
    // console.log(this.mall)
    this.getSections()
  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

  // ====================
  //  GET SECTIONS TO ADD
  // ====================

  getSections(){
    this.api.getList("catelog",`sections?page=${this.page}&per_page=${this.limit}&search=${this.search}`).subscribe((data:any)=>{
      var sections = []

      data.forEach(section => {
        if(section.silo_id == this.silo_id){
          sections.push(section)
        }
      })
      this.sections = sections
    }, error => {
      this.message.showSnack(error.error)
    })
  }


// ========================
//  ARRAY OF ITEMS TO ADD
// ========================

  itemsArr = []

  toggleSelection(cat) {

    var storeRemove = this.itemsArr.map(item => item.id).indexOf(cat.id)

    if(storeRemove !== -1){
      this.itemsArr.splice(storeRemove, 1);
    } else if(storeRemove === -1){
      this.itemsArr.push(cat);
  }
}

  addItems(){
    let newItemsArr = []

    this.itemsArr.forEach(item => {
      let data = {
        section_id: item.id
      }
      newItemsArr.push(data)
    })

      this.api.post('catelog', `malls/${this.mall}/sections`, newItemsArr).subscribe((section: any) => {
        this.message.showSnack('succesfully added sections to mall')
        this.closeDialog()
      })
  }

}



