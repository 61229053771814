<div class="cart" *ngIf='order'>

  <div class="back action" routerLink="/orders">
    <mat-icon>arrow_back</mat-icon>
    <p>orders</p>
  </div>

   <!-- ------------------ -->
   <!--  ORDER LINE ITEMS  --> 
   <!-- ------------------ -->

  <div class="order">
    <h3><span class=order-tag> order </span>#{{order.order_number}}</h3>
    <p class="order-status">{{order.status}}</p>
    <span class="order-date">
        |
        <mat-icon>event</mat-icon>
        <p>{{order.order_date}}</p>
        <p> &nbsp; -  {{order.origin}}</p>
    </span>


    <div class="top-action-buttons">
      <button mat-flat-button class='button driver-button fetch-data-btn' routerLink='/cart/{{order.cart_id}}' >go to Cart</button>
      <button mat-flat-button class='button driver-button'                (click)="goToLink(order.ID)">driver panel</button>
      <!-- <button mat-flat-button class='button'                              (click)='createInvoiceDialog()' *ngIf="order.status == 'COMPLETED' || order.status == 'DISPATCHED' || order.status == 'PAID' ">Create Invoice</button> -->
    </div>
  </div>


  <div class="cart-flex">

    <!-- ------------------ -->
    <!-- CUSTOMER INFO CARD --> 
    <!-- ------------------ -->

    <div class="customer">
        <div class="store action customer-name" routerLink="/stores/{{order.store_id}}">
            <h3>{{order.store_name}} &nbsp; </h3>
            <mat-icon matTooltip='go to store' >arrow_forward</mat-icon>
        </div>

        <hr>

        <h5>Contact Info</h5>
        <div class="contact">
            <mat-icon>person</mat-icon>
            <p>{{order.customer_name}}</p>
        </div>
        <div class="contact">
            <mat-icon class='material-icons-outlined'>email</mat-icon>
            <p>{{order.customer_email}}</p>
        </div>   
        <div class="customer-name action" >
            <div class="contact">
                <mat-icon class='material-icons-outlined'>phone</mat-icon>
                <p>{{order.customer_phone}}</p>
            </div>
            <mat-icon matTooltip='go to customer profile' routerLink='/users/{{order.uid}}'>arrow_forward</mat-icon>
        </div> 
        <hr>

        <h5>Shipping details</h5>
        <div class="shipping">
            <div class="contact">
                <mat-icon>location_on</mat-icon>
                <p>{{order.customer_address}}</p>
            </div>
            <span *ngIf="order.silo_id == 5">
                <div class="contact">
                    <mat-icon></mat-icon>
                    <p *ngIf='order.customer_province'>{{order.customer_province}}</p>
                    <!-- <p *ngIf='!order.customer_province'>no province provided</p> -->
                </div>
                <div class="contact">
                    <mat-icon></mat-icon>
                    <p *ngIf='order.customer_city'>{{order.customer_city}}</p>
                    <!-- <p *ngIf='!order.customer_city'>no city provided</p> -->
                </div>
                <div class="contact">
                    <mat-icon></mat-icon>
                    <p *ngIf='order.customer_suburb'>{{order.customer_suburb}}</p>
                    <!-- <p *ngIf='!order.customer_suburb'>no suburb provided</p> -->
                </div>
                <div class="contact">
                    <mat-icon></mat-icon>
                    <p *ngIf='order.customer_postcode'>{{order.customer_postcode}}</p>
                    <!-- <p *ngIf='!order.customer_postcode'>no postcode provided</p> -->
                </div>
            </span>
            <div class="contact">
                <mat-icon>chat</mat-icon>
                <p *ngIf='order.customer_instruction'>{{order.customer_instruction}}</p>
                <p *ngIf='!order.customer_instruction'>no instruction given</p>
            </div>
        </div>

        <hr *ngIf='!market_cart'>
        <h5 *ngIf='!market_cart'>Cart highlights</h5>
        <div class="cart-highlights">
            <div class="highlight">
                <div class="contact" matTooltip='picker name' *ngIf='!market_cart'>
                    <mat-icon>touch_app</mat-icon>
                    <p *ngIf='order.picker_name'>{{order.picker_name}}</p>
                    <p *ngIf='!order.picker_name'>Picker not assigned</p>
                    <span class='remove-picker' (click)='removePickerDialog()' *ngIf='order.picker_name'>remove</span>
                </div>
                <div class="contact" matTooltip='picker name' *ngIf='!market_cart'>
                    <mat-icon>key</mat-icon>
                    <p>{{order.pin}}</p>
                </div>
            </div>
            <div class="highlight">
                <div class="contact" matTooltip='picker name' *ngIf='!market_cart'>
                    <mat-icon id='rand'>R</mat-icon>
                    <p>{{order.amount}}</p>
                </div>
                <div class="contact" matTooltip='picker name' *ngIf='!market_cart'>
                    <mat-icon>moped</mat-icon>
                    <p>{{order.distance}} m</p>
                </div> 
            </div>
        </div>
    </div>

    <!-- ------------------ -->
    <!--  PAYMENT DETAILS   --> 
    <!-- ------------------ -->
    <div class="cart-payment-tracking">

        <div class="payment-summary" [style.height]="market_cart ? '220px' : '325px' " >


            <h3>Payment Summary</h3>
            <div class="payment">
                <p class="type">Card total</p>
                <p class="amount">R {{order.amount_card | number:"1.2-2" }}</p>
            </div>
            <div class="payment">
                <p class="type">Credit total</p>
                <p class="amount">R {{order.amount_credits | number:"1.2-2" }}</p>
            </div>
            <div class="payment" *ngFor='let item of paymentItems'>
                <p class="type">
                    <span *ngIf="item.item_type == 'promo_code_discount' ">promocode  -  
                        <span *ngIf="item.item_type == 'promo_code_discount' && item.price == 0 ">N/A</span> 
                    </span>
                    {{item.name}} 
                </p>
                <p class="amount">R {{item.price}}</p>
            </div>
            <div class="payment total">
                <p class="type ">total amount</p>
                <p class="amount">R {{order.amount | number:"1.2-2" }}</p>
            </div>
          </div>

          <div class="payment-buttons">
            <span
            [ngClass]="statusoverride == status.api_call ? 'selected' : '' "
            (click)="setStatus(status)"
            *ngFor="let status of status"
            >{{status.display_name}}
          </span>
      
          </div>

     </div>
  </div>

    <!-- ------------------ -->
    <!--  PRODUCT DETAILS --> 
    <!-- ------------------ -->
    <div class="actions" >

      <div class="actions-header "  >
          <h3>CART ACTIONS & TRACKING</h3>
      </div>


      <!-- =================== -->
      <!--   CART TRACKING     -->
      <!-- =================== -->

      <div class="statuses">
          <div class='cart-status' *ngFor='let status of statuses'>
            <h5>{{status.title}}</h5>
              <mat-icon *ngIf='status.time' class='check'>check_circle</mat-icon>
              <mat-icon *ngIf='!status.time' class='check'>radio_button_unchecked</mat-icon>
              <mat-progress-bar *ngIf='status.time'  mode="determinate" value=100></mat-progress-bar>
              <p *ngIf='status.time'>{{status.time}}</p>
            </div>
        </div>

        <div class="products" >

            <div class="product-statuses">
    
                <!-- unpicked items -->
    
                <div class="product-statuses" *ngIf='unfulfilledItems.length > 0'>
                    <div class="unfulfilled product-status" >
                        <mat-icon>circle</mat-icon>
                        <p>Not picked</p>
                    </div>
                    <div class="product" *ngFor='let product of unfulfilledItems'>
                            <img src={{product.image_url}} alt="" onerror="this.src='assets/placeholders/placeholder-img.png' ">
                            <div class="flex-items">
                                <div class="name-size">
                                    <h5 class='name'>{{product.name}}</h5>
                                    <p class='size'>{{product.size}}</p>
                                </div>
                                <p class='line-item' id='product-quant'>{{product.quantity}}x</p>
                                <p class='line-item' id='item-price'>R {{product.price | number:"1.2-2"}}</p>
                            </div>
                    </div>
                </div>
    
                <!-- picked items -->
    
                <div class="product-statuses" *ngIf='pickedItems.length > 0'>
                    <div class="picked product-status" >
                        <mat-icon>circle</mat-icon>
                        <p>picked</p>
                    </div>
                    <div class="product" *ngFor='let product of pickedItems' >
                            <img src={{product.image_url}} alt="" onerror="this.src='assets/placeholders/placeholder-img.png' ">
                            <div class="flex-items">
                                <div class="name-size">
                                    <h5 class='name'>{{product.name}}</h5>
                                    <p class='size'>{{product.size}}</p>
                                </div>
                                <p class='line-item' id='product-quant'>{{product.quantity}}x</p>
                                <p class='line-item' id='item-price'>R {{product.price | number:"1.2-2"}}</p>
                            </div>
                            <p class='size' id='picked-time' *ngIf='product.picked_time'>{{product.picked_time}} 
                                <span *ngIf="product.picked_time < '12' ">am</span>
                                <span *ngIf="product.picked_time > '12' ">pm</span>
                            </p>
                            <p class='size' id='picked-time' *ngIf='!product.picked_time'>time not recorded </p>
                    </div>
                </div>
    
                <!-- out of stock items -->
    
                <div class="product-statuses"  *ngIf='out_of_stock_items.length > 0' >
                    <div class="out-of-stock product-status">
                        <mat-icon>circle</mat-icon>
                        <p>out of stock </p>
                    </div>
                    <div class="product" *ngFor='let product of out_of_stock_items' >
                            <img src={{product.image_url}} alt="" onerror="this.src='assets/placeholders/placeholder-img.png' ">
                            <div class="flex-items" >
                                <div class="name-size">
                                    <h5 class='name'>{{product.name}}</h5>
                                    <p class='size'>{{product.size}}</p>
                                </div>
                                <p class='line-item' id='product-quant'>{{product.quantity}}x</p>
                                <p class='line-item' id='item-price'>R {{product.price | number:"1.2-2"}}</p>
                            </div>
                    </div>
                </div>
            </div>
    
    
            <div class="cart-actions">
            <!-- ------------------ -->
            <!--    CART ACTIONS    --> 
            <!-- ------------------ -->

                <div class="product-statuses"  >
                    <div class="product-status">
                        <mat-icon>touch_app</mat-icon>
                        <p>Actions </p>
                    </div>
                    <div class="product" *ngFor='let action of actions' >
                            <div class="" >
                                <div class="name-size" id='action-item'>
                                    <h5 class='name' *ngIf='action.item_name'>{{action.item_name}}</h5>
                                    <h5 class='name' *ngIf='!action.item_name'>no item recorded</h5>
                                </div>
                                <p class='line-item' id='action-action'>{{action.action}}</p>
                                <p class='line-item' id='time' >{{action.CreatedAt | date: 'MMM d, y - h:mm a'}}</p>
                                <p class='line-item' id='action-name' >{{action.name}}</p>
                            </div>
                    </div>
                </div>
            </div>

        </div>


    <!-- ------------------ -->
    <!--  NUCLEUS TRACKING  --> 
    <!-- ------------------ -->

    <div class="actions-header nucleus-section"  *ngIf='order.silo_id == 5'>
      <h3>NUCLEUS TRACKING DATA</h3>
    </div>

    <div class="statuses" *ngIf='order.silo_id == 5'>
        <div class='status' *ngFor='let action of nucleusActions'>
            <div class="action-label">
                <h5>{{action.action}}</h5>
                <p class='action-time'>{{action.time | date: " hh:mm aa"}}</p>
            </div>
            <mat-icon *ngIf='action.time' class='check'>check_circle</mat-icon>
            <mat-icon *ngIf='!action.time' class='check'>radio_button_unchecked</mat-icon>
            <mat-progress-bar *ngIf='action.time'  mode="determinate" value=100></mat-progress-bar>
            <p *ngIf='action.time'>{{action.time | date: "MM/dd/yy"}}</p>
         </div>
    </div>

  </div>


</div>




