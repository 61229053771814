import { Component, Inject, OnInit, EventEmitter } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { AssetsService } from 'src/app/services/assets/assets.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-list-assets',
  templateUrl: './list-assets.component.html',
  styleUrls: ['./list-assets.component.scss'],
})
export class ListAssetsComponent implements OnInit {
  Columns = ['name', 'registration', 'asset_type', 'desc', 'value', 'edit'];

  page = 1;
  limit = 10;
  search = '';

  constructor(
    public assets: AssetsService,
    public auth: AuthService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.assets.getAssets(this.page, this.limit, this.search);
    this.assets.getAssetTypes();
  }

  // asset type view dialog
  viewAssetTypes() {
    const dialog = this.dialog.open(viewAssetTypesDialog, {
      width: '800px',
      height: '600px',
    });

    dialog.afterClosed().subscribe(() => {
      this.assets.getAssets(this.page, this.limit, this.search);
    });
  }

  setLimit(limit) {
    this.limit = limit;
    this.assets.getAssets(this.page, this.limit, this.search);
  }
  next() {
    this.page++;
    this.assets.getAssets(this.page, this.limit, this.search);
  }
  previous() {
    if (this.page > 1) {
      this.page--;
      this.assets.getAssets(this.page, this.limit, this.search);
    }
  }
  start() {
    this.page = 1;
    this.assets.getAssets(this.page, this.limit, this.search);
  }

  // asset type create dialog
  createAssetType() {
    const dialog = this.dialog.open(createAssetTypedialog, {
      width: '650px',
      height: '250px',
    });

    dialog.afterClosed().subscribe(() => {
      this.assets.getAssets(this.page, this.limit, this.search);
    });
  }

  deleteAsset(asset) {
    const dialog = this.dialog.open(deleteAssetDialog, {
      width: '550px',
      height: '200px',
      data: {
        asset: asset,
      },
    });

    dialog.afterClosed().subscribe(() => {
      setTimeout(() => {
        this.assets.getAssets(this.page, this.limit, this.search);
      }, 1000);
    });
  }

  updateAsset(asset) {
    const dialog = this.dialog.open(UpdateAssetDialog, {
      width: '850px',
      height: '600px',
      data: {
        asset: asset,
      },
    });

    dialog.afterClosed().subscribe(() => {
      setTimeout(() => {
        this.assets.getAssets(this.page, this.limit, this.search);
      }, 1000);
    });
  }
}

// ============================
//   VIEW ASSET TYPE  DIALOG
// ============================
@Component({
  selector: 'view-asset-types-dialog',
  templateUrl: 'view-asset-types-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class viewAssetTypesDialog {
  asset_type_columns = ['name', 'description', 'edit'];

  constructor(
    public assets: AssetsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<viewAssetTypesDialog>
  ) {}

  ngOnInit(): void {
    this.assets.getAssetTypes();
  }

  onAdd = new EventEmitter();

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  // asset type create dialog
  createAssetType() {
    const dialog = this.dialog.open(createAssetTypedialog, {
      width: '650px',
      height: '250px',
    });

    dialog.afterClosed().subscribe(() => {
      this.assets.getAssetTypes();
    });
  }

  updateAssetType(type) {
    const dialog = this.dialog.open(UpdateAsserTypeDialog, {
      width: '650px',
      height: '250px',
      data: { type: type },
    });

    dialog.afterClosed().subscribe(() => {
      this.assets.getAssetTypes();
    });
  }
}

// ============================
//  CREATE ASSET TYPE  DIALOG
// ============================

@Component({
  selector: 'create-asset-type-dialog',
  templateUrl: 'create-asset-type-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class createAssetTypedialog {
  constructor(
    private asset: AssetsService,
    public dialogRef: MatDialogRef<createAssetTypedialog>
  ) {}

  firstForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl(''),
  });

  onAdd = new EventEmitter();

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  submitAssetType() {
    // console.log(this.firstForm.value)
    this.asset.createAssetType(this.firstForm.value);
    this.closeDialog();
  }
}

// ============================
//    ASSET REMOVE DIALOG
// ============================

@Component({
  selector: 'delete-dialog',
  templateUrl: '../../../components/dialogs/delete-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class deleteAssetDialog {
  inputTouch = false;
  heading = `asset`;
  body = `Are you sure you want to remove this asset? You can always add it again later.`;

  // get section info after success
  onAdd = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { asset },
    private assets: AssetsService,
    public dialogRef: MatDialogRef<deleteAssetDialog>
  ) {}

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  removeItem() {
    // console.log(this.data.asset)
    this.assets.removeAsset(this.data.asset.ID);
    this.closeDialog();
  }
}

// ============================
//    ASSET UPDATE DIALOG
// ============================

@Component({
  selector: 'update-asset-dialog',
  templateUrl: 'update-asset-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class UpdateAssetDialog {
  name;
  registration;
  description;
  value;
  asset_type;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { asset },
    private assets: AssetsService,
    public dialogRef: MatDialogRef<UpdateAssetDialog>
  ) {}

  ngOnInit(): void {
    this.name = this.data.asset.name;
    this.description = this.data.asset.description;
    this.registration = this.data.asset.registration_number;
    this.value = this.data.asset.value;
    // this.ass = this.data.asset.name
  }

  // get section info after success
  onAdd = new EventEmitter();

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  updateAsset(asset) {
    this.assets.updateAsset(this.data.asset.ID, asset);
    this.closeDialog();
  }
}

// ============================
//  UPDATE ASSET TYPE  DIALOG
// ============================

@Component({
  selector: 'update-asset-type-dialog',
  templateUrl: 'update-asset-type-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class UpdateAsserTypeDialog {
  name;
  description;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { type },
    private asset: AssetsService,
    public dialogRef: MatDialogRef<UpdateAsserTypeDialog>
  ) {}

  ngOnInit(): void {
    this.name = this.data.type.name;
    this.description = this.data.type.description;
  }

  onAdd = new EventEmitter();

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  updateAssetType(type) {
    this.asset.updateAssetType(this.data.type.ID, type);
    this.closeDialog();
  }
}
