import { ApiService }                               from 'src/app/services/api.service'
import { PagerService }                             from '../../../../services/pager.service'
import { MessagesService }                          from 'src/app/services/messages.service'
import { Router ,ActivatedRoute}                    from '@angular/router'
import { environment as devEnv }                    from '../../../../../environments/environment'
import { Component, Inject, OnInit, EventEmitter  } from '@angular/core'
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  ProductColumns = ['icon', 'name', 'add']
  StoreColumns = ['name', 'add']

  id
  product
  retailer = "retailer not found"
  retailers
  allCategories
  
  add               = false
  edit              = false
  page              = 1
  shade             = false
  limit             = 10
  search            = ''
  stores            = []
  addPage           = 1
  addLimit          = 10
  newImage          = false
  storeAdd          = false
  searching         = false
  categories        = []
  allProducts       = false
  UpdateAllProducts = false

  // product model
  sku
  size
  name
  price
  image
  width
  height
  length
  weight
  rebate
  linked
  barcode
  category
  description
  max_quantity


  constructor(
    public  router    : Router,
    private api       : ApiService,
    public  pager     : PagerService,
    public  dialog    : MatDialog,
    private route     : ActivatedRoute,
    public  message   : MessagesService,
  ) {}

  ngOnInit(): void {
    this.pager.setSubMenus('products')
    this.id = this.route.snapshot.paramMap.get('id')
    this.getProduct()
  }

  toggleAllEdit() {
    this.allProducts = !this.allProducts;
  }

  toggleEdit() {
    if(this.newImage){
      this.image = this.product.image
    }
    this.edit = !this.edit
  }

  async getProduct() {
    this.api
      .getItem('catelog', 'products', this.id)
      .subscribe((product: any) => {
        // console.log(product)

        this.product      = product

        this.name         = product.name
        this.description  = product.description
        this.price        = product.price
        this.size         = product.size
        this.barcode      = product.barcode
        this.sku          = product.sku_code
        this.category     = product.category_id
        this.rebate       = product.rebate
        this.linked       = product.linked_key
        this.image        = product.image
        this.width        = product.width
        this.height       = product.height
        this.length       = product.length
        this.weight       = product.weight
        this.max_quantity = product.max_quantity

        this.getRetailers()

      })
  }


getRetailers(){
  this.api.getList('catelog', `retailers?page=${this.page}&per_page=${100}`).subscribe((retailers:any) => {
    this.retailers = retailers
    
      retailers.forEach(retailer => {
        if(this.product.retailer_id == retailer.id){
          this.retailer = retailer.name
        }
      })
    })
  }

// ============================
//  EDIT PRODUCT DETAILS DIALOG
// ============================

  editProductDialog(){
    const dialog = this.dialog.open(ProductEditDialog, {
      width: '800px',
      height: '450px',
      data: {
        product: this.product,
      }
    })

    dialog.afterClosed().subscribe(() => {
      this.getProduct()
    })
  }


// ================================
//  EDIT PRODUCT DIMENSIONS DIALOG
// ================================

  editProductDiamensions(){
    const dialog = this.dialog.open(ProductDimensions, {
      width: '800px',
      height: '450px',
      data: {
        product: this.product,
      }
    })

    dialog.afterClosed().subscribe(() => {
      this.getProduct()
    })
  }

// ============================
//  EDIT PRODUCT IMAGE DIALOG
// ============================

  editProductImage(){
    const dialog = this.dialog.open(ProductImage, {
      width: '600px',
      height: '350px',
      data: {
        product: this.product,
      }
    })

    dialog.afterClosed().subscribe(() => {
      this.getProduct()
    })
  }

// =======================
//  ADD PRODUCT TO STORES
// =======================

  addStoresToProductDialog(){
    const dialog = this.dialog.open(ProductStoresDialog, {
      width: '1000px',
      height: '600px',
      data: {
        product: this.product,
      }
    })

    dialog.afterClosed().subscribe(() => {
      this.getProduct()
    })
  }

// ============================
//  DELETE PRODUCT
// ============================

  deleteProduct(){
    const dialog = this.dialog.open(productDeleteDialog, {
      width: '550px',
      height: '200px',
      data: {
        product: this.product,
      }
    })

    dialog.afterClosed().subscribe(() => {
      this.router.navigate(['/products'])
    })
  }

}



// =======================
//   EDIT PRODUCT DIALOG
// =======================


@Component({
  selector: 'update-product-dialog',
  templateUrl: 'update-product-dialog.html',
  styleUrls: ['./update-product-dialog.component.scss']
})
export class ProductEditDialog {

    // getProduct after succesfull update
    onAdd = new EventEmitter()

    product
    retailer
    retailers

    // product model
    sku
    otc
    size
    name
    price
    linked
    barcode
    description
    max_quantity

    limit  = 6
    search = ''



  constructor(@Inject(MAT_DIALOG_DATA) public data: { product}, private api : ApiService, public dialogRef: MatDialogRef<ProductEditDialog> , public message: MessagesService) {

  }

  ngOnInit(): void {
    this.product      = this.data.product
    this.sku          = this.data.product.sku_code
    this.otc          = this.product.otc
    this.size         = this.data.product.size
    this.name         = this.data.product.name
    this.price        = this.data.product.price
    this.linked       = this.data.product.linked_key
    this.barcode      = this.data.product.barcode
    this.description  = this.data.product.description
    this.retailer     = this.product.retailer_id 
    this.max_quantity = this.product.max_quantity

    this.getRetailers()
  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }


    // testing for non numerical values 
    dimensionsRoundUp(){
      var regExp = /[a-zA-Z]/g
      var specialChars = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/
  
      // test for any special or alpabetical characters
      if(regExp.test(this.max_quantity)){
        this.max_quantity = this.max_quantity.replace(regExp, "")
      } else if(specialChars.test(this.max_quantity)){
        this.max_quantity = this.max_quantity.replace(specialChars, "")
      }
    }
  


  getRetailers(){
    this.api.getList('catelog', `retailers?per_page=${this.limit}&search=${this.search}`)
    .subscribe((retailers: any) => {
      this.retailers = retailers
    })
  }

  updateProduct(){
    let product = {
      id: JSON.parse(this.product.id),
      name: this.name,
      description: this.description,
      price: this.price,
      size: this.size,
      barcode: this.barcode,
      sku_code: this.sku,
      retailer_id: this.retailer,
      linked_key: this.linked,
      max_quantity: JSON.parse(this.max_quantity),
      otc: this.otc
    }

    this.api.patch('catelog', `products`, product).subscribe((data: any) => {
      this.message.showSnack(`Succesfully updated product details`)
      this.closeDialog()
    })
  }

}


// =================================
//   EDIT PRODUCT DIMENSIONS DIALOG
// =================================

@Component({
  selector: 'product-dimensions',
  templateUrl: 'update-product-dimensions.html',
  styleUrls: ['./update-product-dialog.component.scss']
})
export class ProductDimensions {

    // getProduct after succesfull update
    onAdd = new EventEmitter()

    product
    // product model
    width
    length
    height
    weight




  constructor(@Inject(MAT_DIALOG_DATA) public data: { product}, private api : ApiService, public dialogRef: MatDialogRef<ProductEditDialog> , public message: MessagesService) {

  }

  ngOnInit(): void {
    this.product = this.data.product

    if(this.data.product.length){
    this.length  = JSON.parse(this.data.product.length)
    }

    if(this.data.product.width){
      this.width = JSON.parse(this.data.product.width)
    }    

    if(this.data.product.height){
      this.height = JSON.parse(this.data.product.height)
    }

    if(this.data.product.weight){
      this.weight = this.data.product.weight
    }

    this.dimensionsRoundUp()
  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

  dimensionsRoundUp(){

    if(this.length){
    this.length = JSON.stringify(Math.floor(this.length))
    }
    if(this.width){
      this.width = JSON.stringify(Math.floor(this.width))
      }
      if(this.height){
        this.height = JSON.stringify(Math.floor(this.height))
      }
  }


  updateProduct(){
    if(this.length && this.width && this.height && this.weight){
    let product = {
      id: JSON.parse(this.product.id),
      length  : this.length,
      width   : this.width,
      height  : this.height,
      weight  : this.weight,
    }

    // console.log(product)

    this.api.patch('catelog', `products`, product).subscribe((data: any) => {
      this.message.showSnack(`Succesfully updated product details`)
      this.closeDialog()
    })
  }

  }

}


// =======================
//   UPDATE PRODUCT IMAGE
// =======================

@Component({
  selector: 'product-image-update',
  templateUrl: 'update-product-image.html',
  styleUrls: ['./update-product-image.component.scss']
})
export class ProductImage {

    // getProductImage after succesfull update
    onAdd = new EventEmitter()

    id
    image
    imageFile: { resource: string, file: any, name: string }

  constructor(@Inject(MAT_DIALOG_DATA) public data: { product}, private api : ApiService, public dialogRef: MatDialogRef<ProductEditDialog> , public message: MessagesService) {

  }

  ngOnInit(): void {
    this.image  = this.data.product.image
    this.id     = this.data.product.id
  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

  imageFormat = false

  imagesPreview(event) {
    if (event.target.files && event.target.files[0]) {
        let reader = new FileReader()

        reader.onload = (_event: any) => {
            this.imageFile = {
                resource: _event.target.result,
                file: event.srcElement.files[0],
                name: event.srcElement.files[0].name
            }

            if(this.imageFile.name.includes('png')  || 
              this.imageFile.name.includes('jpg')   || 
              this.imageFile.name.includes('jpeg')  ||  
              this.imageFile.name.includes('webp')  ||  
              this.imageFile.name.includes('JPG')   ||  
              this.imageFile.name.includes('PNG')   || 
              this.imageFile.name.includes('JPEG')  
              ){
                this.imageFile.name = 'image.png'
                this.imageFormat = true
                this.image = this.imageFile.resource

            } else {
              this.message.showSnack(`oops!   that is an unsupported image type`)
            }
        }
        reader.readAsDataURL(event.target.files[0])
    }
  }

  updateImage(){

    this.message.showSnack('updating product image')

    var myHeaders = new Headers()

    myHeaders.append("Authorization", "Bearer " + this.api.token)

    var formdata = new FormData()

    formdata.append("file", this.imageFile.file, this.imageFile.name)
    formdata.append("body", "{ \"resource\":\"products\", \"resource_id\":"+ this.id +",\"x_dim\":1, \"y_dim\":1}")
    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata
    }


    if(!devEnv.production){
      fetch("https://api.api-dev.quench.org.za/catalogue/v1/image", requestOptions)
        .then(response => response.text())
        .then(result =>
          setTimeout(() => {
            this.message.showSnack(`successfully updated product image`)
            setTimeout(() => {
              this.closeDialog()
            }, 3100)
          }, 6000))
        .catch(error => {
          this.message.showSnack(error.error)
        })
      }

      if(devEnv.production){
        fetch("https://api.quench.org.za/catalogue/v1/image", requestOptions)
          .then(response => response.text())
          .then(result =>
            setTimeout(() => {
              this.message.showSnack(`successfully updated product image`)
              setTimeout(() => {
                this.closeDialog()
              }, 3100)
            }, 6000))
          .catch(error => {
            this.message.showSnack(error.error)
          })
        }
    }

}


// ============================
//      STORE ADD DIALOG
// ============================

  @Component({
    selector: 'store-add-dialog',
    templateUrl: './product-store-add-dialog.html',
    styleUrls: ['../../../pocket-mall/mall-stores/store-add.component.scss']
  })
  export class ProductStoresDialog {
    Columns = ['name', 'address', 'add']

    store_amount = ''

    // get mall stores after success
    onAdd = new EventEmitter()

    inputTouch = false

    onFocus(){
      this.inputTouch = true
    }
  
    onBlur(){
      this.inputTouch = false
    }

    id
    qty
    silo
    silos
    stores
    product
    price
    promo_price

    promoProduct = false

    page      = 1
    limit     = 6
    search    = ''
    silo_id   = 1
    confirm   = false
    storesArr = []

  
  
  
    constructor(@Inject(MAT_DIALOG_DATA) 
                public data: { product, }, 
                private api : ApiService, 
                private message: MessagesService,  
                public dialogRef: MatDialogRef<ProductStoresDialog>) {
  
    }
  
    ngOnInit(): void {
      this.id           = this.data.product.id
      this.product      = this.data.product
      this.price        = this.data.product.price
      this.promo_price  = this.data.product.price
      this.getSilos()
    }

    closeDialog() {
      this.dialogRef.close()
      this.onAdd.emit()
    }

    clearSearch(){
      this.search = ''
      this.getStores()
    }

    StoreAmountChange(){
      this.store_amount = ''
      this.confirm      = false
      this.limit        = 6
      this.storesArr    = []
    }

    radioChange(){
    if(this.store_amount == 'individualStores'){
      this.getStores()
      } else {
        this.limit = 500
        this.getStores()
      }
    }
  // =================
  //      SILOS
  // =================

  getSilos(){
    return new Promise (resolve => {
      this.api.getList("catelog","silos").subscribe((silos:any)=>{
      this.silos = silos
       
      // set current silo
      silos.forEach(silo => {
        if(silo.tag == 'liquor'){
          this.silo = silo
        }
      })

        this.getStores()
        resolve('success')
      })
    })
  }
  

  setSilo(asilo){
    this.silo      = asilo
    this.silo_id   = asilo.id
    this.storesArr = []
    this.getStores()
  }

  // ========================
  //    GET STORES TO ADD
  // ========================

  getStores(){
      this.api.getList('catelog', `stores?silo_id=${this.silo_id}&page=${this.page}&per_page=${this.limit}&search=${this.search}`)
      .subscribe((stores:any) =>{
        this.stores = stores

        if(this.store_amount == 'allstores'){
          this.stores.forEach(store => {
            this.storesArr.push(store.id)
          })
        }

      }, error => {
        this.message.showSnack(error.error)
      })
    }

    toggleSelection(cat) {

      var storeRemove = this.storesArr.map(item => item.id).indexOf(cat.id)
  
      if(storeRemove !== -1){
        this.storesArr.splice(storeRemove, 1);
      } else if(storeRemove === -1){
        this.storesArr.push(cat);
      }
   }

   confirmPromo(){
     this.confirm    = !this.confirm
   }
 

   addStores(i){
     let stores = []

    if(i.qty && i.price){

      this.storesArr.forEach(item =>{
        let storeProduct = {
          store_id: item,
          product_id: this.id,
          qty: i.qty,
          price: this.price,
          was_price: this.price
        }
        if(this.promoProduct){
          storeProduct.price = this.promo_price
        }
        if(typeof item === 'object'){
          storeProduct.store_id = item.id
        }
        stores.push(storeProduct)
      })
      

      // console.log(stores)

      this.api.post('catelog', 'storeproducts', stores).subscribe((stores: any) => {
        // console.log(stores)
        this.message.showSnack('Succesfully added Product to stores')
        this.closeDialog()
      }, error => {
        this.message.showSnack(error.error)
      })
    }
  }

  start(){
    this.page = 1
    this.getStores()
  }
  previous(){
    if(this.page > 1){
      this.page--
      this.getStores()
    }
  }
  next(){
    this.page++
    this.getStores()
  }

}

@Component({
  selector: 'delete-dialog',
  templateUrl: '../../../../components/dialogs/delete-dialog.html',
  styleUrls: ['../../../../components/dialogs/delete-dialog.component.scss']
})

export class productDeleteDialog {

  
  inputTouch  = false
  heading     = `product`
  body        = `Are you sure you want to delete this product? This action cannot be undone.`

  product

  // get section info after success
  onAdd       = new EventEmitter()
  
  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }


  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: { product }, 
              private api : ApiService, 
              private message: MessagesService,  
              public dialogRef: MatDialogRef<productDeleteDialog>) {

  }

  ngOnInit(): void {
    this.product = this.data.product

  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

  removeItem(){
    this.api.delete('catelog', `products/${this.product.id}`).subscribe((data: any) => {
      this.message.showSnack(`succesfully deleted product`)
      this.closeDialog()
    })
  }

}