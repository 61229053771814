<h4>
  {{address.label}}
  <span class="default" *ngIf="address.default_address">default</span>
</h4>

<div class="address-details">
  <div class="flex-item" *ngFor="let address of address | keyvalue">
      <p class="field">{{address.key}}</p>
      <p class="value">{{address.value}}</p>
  </div>
</div>
