<div class="header">
    <h3>Update Asset Type</h3>
    <mat-icon (click)="closeDialog()" class="action">clear</mat-icon>
  </div>
  <hr />
  
  <form #type = 'ngForm' (ngSubmit)='updateAssetType(type.value)'>
    <mat-form-field appearance="outline" class="field">
      <mat-label>Asset Type Name</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="name"
        placeholder="tuk tuk"
        name="name"
        required
      />
    </mat-form-field>

  
    <mat-form-field appearance="outline" class="field">
      <mat-label>Asset Type Description</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="description"
        placeholder="for bigger deliveries"
        name="description"
      />
    </mat-form-field>
  
  
    <div class="save">
      <button mat-flat-button class="button">submit</button>
    </div>
  </form>
  