

<div class="header">
    <h3>update category icon</h3>
    <mat-icon class='action' (click)='closeDialog()'>clear</mat-icon>
</div>

<hr>

<div class="image">
    <img class='edit-image' src="{{image}}" alt="">
    <span class="edit-images-button action" onclick="document.querySelector('#mobileInputFile').click()">update Icon</span>
    <input type="file" id="mobileInputFile" (change)="imagesPreview($event)" style="display: none;">
</div>


<div class="btn">
    <button mat-flat-button (click)='closeDialog()'>cancel</button>
    <button mat-flat-button class='button' (click)='updateImage()'>save</button>
</div>