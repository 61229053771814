<div class="heading">
  <h3>Log Manual Trip</h3>
  <mat-icon (click)="closeDialog()">clear</mat-icon>
</div>

<form #trip="ngForm" (ngSubmit)="submitManualTrip(trip.value)">
  <div class="edit-fields">
    <mat-form-field appearance="outline" class="field">
      <mat-label>Driver name</mat-label>
      <input
        type="string"
        matInput
        [(ngModel)]="driver_name"
        placeholder="John Doe"
        name="driver"
        required
        [matAutocomplete]="auto"
        (input)="getDrivers($event)"
      />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option
          *ngFor="let driver of drivers"
          [value]="driver"
          (click)="selectDriver(driver)"
        >
          <div style="  display: flex; justify-content: space-between;">
            <span style="width: 35%;" > {{ driver.first_name }} {{driver.last_name}} </span>
            <span style="width: 35%;"> {{ driver.email }} </span>
            <span style="width: 35%;  text-align: end;" > {{ driver.phone }} </span>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="field" appearance="outline">
      <mat-label>driver loop id (if applicable)</mat-label>
      <input
        type="text"
        matInput
        ngModel
        placeholder="4a-9368-4250-8f14-a90915a0caeb"
        name="driver_loop_id"
      />
    </mat-form-field>

    <mat-form-field class="field" appearance="outline">
      <mat-label>driver contact</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="mobile"
        placeholder="0834567897"
        name="mobile"
        required
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="field">
      <mat-label>Reason for manual capture</mat-label>
      <mat-select
        [(ngModel)]="reason"
        name="reason"
        class="select-panel"
        (selectionChange)="selectReason(reason)"
      >
        <mat-option *ngFor="let reason of manual_trip_reasons" [value]="reason">
          <p *ngIf="reason">{{reason.reason}}</p>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="field" appearance="outline" *ngIf="other">
      <mat-label>Please give more details</mat-label>
      <input
        ngModel
        type="text"
        matInput
        placeholder="driver had an accident"
        name="other_reason"
        required
      />
    </mat-form-field>
  </div>

  <div class="save">
    <button mat-flat-button class="button" type="submit">Confirm</button>
  </div>
</form>
