<table  mat-table [dataSource]='stores' matSort >

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let store" routerLink='/stores/{{store.store_id}}' id='store-name'> <p>{{store.name}} </p></td>
    </ng-container>

    <!-- Address Column -->
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
      <td mat-cell *matCellDef="let store" routerLink='/stores/{{store.store_id}}'> 
        <p> {{store.physical_address}} </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Emal </th>
      <td mat-cell *matCellDef="let store" routerLink='/stores/{{store.store_id}}'> {{store.email}} </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
      <td mat-cell *matCellDef="let store" routerLink='/stores/{{store.store_id}}'> {{store.phone}} </td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell  *matHeaderCellDef> </th>
      <td mat-cell  *matCellDef="let store" >      
        <mat-icon matTooltip='drop store from mall' (click)='removeStoreFromMall(store)'>remove_circle_outline</mat-icon>
      </td>
  </ng-container>


    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row class='action'  *matRowDef="let store; columns: Columns;"></tr>
  </table>


  <div id="pager"  >

    <button mat-flat-button class='button' id="{{auth.expand ? 'create-expand': 'create'}}" (click)='addStoreDialog()'>add mall store</button>

    <div class="page"> 

    <div> Per page:</div>
    <div (click)="setLimit(10)" class="limit" >10</div>
    <div (click)="setLimit(15)" class="limit" >15</div>
    <div (click)="setLimit(20)" class="limit" >20</div>
    <div (click)="setLimit(50)" class="limit" >50</div>
    <div>Page:</div>
    <mat-icon  matTooltip="Skip to start" (click)='start()'>arrow_left</mat-icon>
    <mat-icon (click)='previous()'>skip_previous</mat-icon>
    <div>{{page}}</div>
      <mat-icon (click)='next()'>skip_next</mat-icon>
      <div> </div>
    </div>
  </div>