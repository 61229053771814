import { AgmMap  } from '@agm/core'
import { ActivatedRoute } from '@angular/router'
import { Component, OnInit, ViewChild } from '@angular/core'
import { ApiService } from './../../../services/api.service'
import { AuthService } from './../../../services/auth.service'
import { MessagesService } from './../../../services/messages.service'


declare const google: any


@Component({
  selector: 'app-mall-zones',
  templateUrl: './mall-zones.component.html',
  styleUrls: ['./mall-zones.component.scss']
})
export class MallZonesComponent implements OnInit {

  id

  zoom = 14
  latitude
  longitude
  retries = 0

  constructor(public api: ApiService,
              public route: ActivatedRoute,
              public auth: AuthService,
              public message: MessagesService) { }

  ngOnInit(): void {
    this.id = JSON.parse(this.route.snapshot.paramMap.get('id'))
    // console.log(this.id)
    this.getMall()
    this.getMallZones()
  }

  getMall(){
    this.api.getList(`catelog`, `malls/${this.id}`).subscribe((mall: any) => {
      this.latitude   = mall.latitude
      this.longitude  = mall.longitude
    })
    }

  getMallZones(){
    return new Promise(resolve => {
      this.api.getList('catelog', `zones/${this.id}/mall`).subscribe((geopoints: any) => {
        // console.log(geopoints)
        for(var i = 0; i < geopoints.length; i++){
          this.geopoints.push(geopoints[i])
          this.geopoints = this.geopoints.reverse()
        }
        resolve('success')
        }, error => {
          this.message.showSnack(error.error)
          if(this.retries <3){
            if(error.status !== 401){
              this.retries++
              this.getMallZones()
            }
          }
        })
      })
  }











  // =======================
  //      SET POLYGON
  // =======================


  geopoints    = []
  pointList    = []
  selectedArea = 0


  drawingManager: any
  selectedShape: any
  @ViewChild(AgmMap) map: any


  // listen for events on map
  onMapReady(map) {
    this.map = map

    this.drawPolygon()
  }

  
  // Construct the polygon
​ async drawPolygon(){
  await this.getMallZones()

    for(var i = 0; i < this.geopoints.length; i++){
    let zone = this.geopoints[i]
    let polygon


    polygon = new google.maps.Polygon({
      paths: this.geopoints[i].geopoints,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      editable: true,
      draggable: true,
    })

    polygon.setMap(this.map)

              
  polygon.getPaths().forEach(function (path, index) {
    google.maps.event.addListener(path, 'insert_at', function () {
      localStorage.setItem('zoneId', JSON.stringify(zone.id))

      let geopoints = []
      const len = path.getLength();
      for (let i = 0; i < len; i++) {
        geopoints.push(
          path.getAt(i).toJSON()
        )

        localStorage.setItem('geopoints', JSON.stringify(geopoints))
      }
    })

    // add points
    google.maps.event.addListener(path, 'set_at', function () {
      localStorage.setItem('zoneId', JSON.stringify(zone.id))

      let geopoints = []
      const len = path.getLength();
      for (let i = 0; i < len; i++) {
        geopoints.push(
          path.getAt(i).toJSON()
        )

        localStorage.setItem('geopoints', JSON.stringify(geopoints))
      } 
    })

    })

    }

    ​this.initDrawingManager(this.map)

  }

  // =======================
  //     NEW GEOFENCE
  // =======================

  initDrawingManager = (map: any) => {
    const self = this;
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon'],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);
    google.maps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const paths = event.overlay.getPaths();
          for (let p = 0; p < paths.getLength(); p++) {
            google.maps.event.addListener(
              paths.getAt(p),
              'set_at',
              () => {
                if (!event.overlay.drag) {
                  // console.log('setting points')
                  self.updatePointList(event.overlay.getPath());
                }
              }
            );
            google.maps.event.addListener(
              paths.getAt(p),
              'insert_at',
              () => {
                // console.log('setting points')
                self.updatePointList(event.overlay.getPath());
              }
            );
            google.maps.event.addListener(
              paths.getAt(p),
              'remove_at',
              () => {
                self.updatePointList(event.overlay.getPath());
              }
            );
          }
          self.updatePointList(event.overlay.getPath());
          this.selectedShape = event.overlay;
          this.selectedShape.type = event.type;

        }
        if (event.type !== google.maps.drawing.OverlayType.MARKER) {
          // Switch back to non-drawing mode after drawing a shape.
          self.drawingManager.setDrawingMode(null)
        }
      }
    );
  }

  updatePointList(path) {
    this.pointList = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      this.pointList.push(
        path.getAt(i).toJSON()
      )
    }
    this.selectedArea = google.maps.geometry.spherical.computeArea(
      path
    )
  }

  // =======================
  //     NEW GEOFENCE
  // =======================

  submitGeofence(){
    this.pointList.push(this.pointList[0])
    let zone = [
      {
        mall_id: this.id,
        city: 'Cape Town',
        area: 'Stellenbosch',
        geopoints: this.pointList
      }
    ]

    this.api.post('catelog', 'zones', zone).subscribe((zone: any) => {
      this.message.showSnack('Successfully added mall zone')
      setTimeout(() =>{
        location.reload()
      }, 2000)
    }, error => {
      this.message.showSnack(error.error)
    })
  }

  // =======================
  //    UPDATE GEOFENCE
  // =======================

  updateGeofence(){
    let zone_id = JSON.parse(localStorage.getItem('zoneId'))
    let updatedGeopoints = JSON.parse(localStorage.getItem('geopoints'))
    let zone = {
      id: zone_id,
      mall_id: this.id,
      geopoints: updatedGeopoints
    }

    this.api.patch(`catelog`, 'zones', zone).subscribe((zone: any) => {
      this.message.showSnack('Succesfully updated mall zone');
      localStorage.removeItem('zoneId')
      setTimeout(() => {
        location.reload()
      }, 2000);
      localStorage.removeItem('geopoints')
    }, error => {
      this.message.showSnack(`something went wrong, please try again`)
      setTimeout(() => {
        location.reload()
      }, 2500)
    })
  }

  // =======================
  //    DELETE GEOFENCE
  // =======================

  deleteGeofence(){
    let zone_id = JSON.parse(localStorage.getItem('zoneId'))

    this.api.delete(`catelog`, `zones/${zone_id}`).subscribe((zone: any) => {
      this.message.showSnack('Succesfully deleted mall zone');
      localStorage.removeItem('zoneId')
      setTimeout(() => {
        location.reload()
      }, 2000);
      localStorage.removeItem('geopoints')
    }, error => {
      // console.log(error);
      this.message.showSnack(error.error)
    })
  }
}
