import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MessagesService } from 'src/app/services/messages.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-store-address',
  templateUrl: './store-address.component.html',
  styleUrls: ['./store-address.component.scss']
})
export class StoreAddressComponent implements OnInit {

  id
  store
  
  latitude
  longitude
  
  city
  street
  postal
  suburb
  building
  province
  
  retries = 0

  constructor(public auth: AuthService,
              public message: MessagesService,
              public api: ApiService,
              public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = JSON.parse(this.route.snapshot.paramMap.get('id'))
    this.getStore()
  }

  async getStore(){
    this.api.get('catelog', `stores`, this.id).subscribe((data: any) => {

      this.store = data

      this.city      = data.city
      this.street    = data.street
      this.suburb    = data.suburb
      this.postal    = data.postal
      this.latitude  = data.latitude
      this.building  = data.building
      this.province  = data.province
      this.longitude = data.longitude
      


    }, (error:any) => {
      this.message.showSnack(error.error)
      if(this.retries < 3){
        if(error.status !== 401){
          this.retries++
          this.getStore()
        }
      }
    })
  }


  // ========================
  //     ADDRESS PATCH
  // ========================

  editStreet    = false
  editBuilding  = false
  editProvince  = false
  editCity      = false
  editSuburb    = false
  editPostal    = false

  physical_address = false

  allFields = false

  cancelUdpate(i){
    this.allFields = false
    
    this.street     = this.store.street
    this.editStreet = false

    this.building     = this.store.building
    this.editBuilding = false

    this.province     = this.store.province
    this.editProvince = false

    this.city     = this.store.city
    this.editCity = false

    this.suburb     = this.store.suburb
    this.editSuburb = false

    this.postal     = this.store.postal
    this.editPostal = false
  }

  updateMarketDetails(i){

    if(i == 'street'){
      this.editStreet = true
    }
    if(i == 'building'){
      this.editBuilding = true
    }
    if(i == 'province'){
      this.editProvince = true
    }
    if(i == 'city'){
      this.editCity = true
    }
    if(i == 'suburb'){
      this.editSuburb = true
    }
    if(i == 'postal'){
      this.editPostal = true
    }
  }

  updateAllFields(){
    this.allFields = !this.allFields 

    this.editBuilding  = true
    this.editCity      = true
    this.editPostal    = true
    this.editProvince  = true
    this.editStreet    = true
    this.editSuburb    = true
  }

  // retreive nucleus address details

  // ========================
  //   MARKET ADDRESS PATCH
  // ========================

  address_update = false;

  editAddress(){
    this.address_update = !this.address_update

    this.lookupCity(this.province)
    
  }

  provinces = [ 
    "Eastern Cape",
    "Free State",
    "Gauteng",
    "Kwazulu natal",
    "Limpopo",
    "Mpumalanga",
    "Northern Cape",
    "North West",
    "Western Cape",
  ]

  address   = 'address'
  // province = 'province'


  cities = []
  noCities = false

  lookupCity(province){
    this.province = province
 
     var input = {
       province: this.province,
       city: '',
       suburb: ''
     }
 
     this.api.post("fulfilment", `address/lookup`, input).subscribe((data:any)=>{
       this.cities = data.cities
       this.noCities = false
 
        
     },error=>{
      this.message.showSnack('no cities were found')
     })
   }

}
