import { Component, OnInit } from '@angular/core'
import { ApiService } from 'src/app/services/api.service'
import { AuthService } from 'src/app/services/auth.service'
import { MessagesService } from 'src/app/services/messages.service'

@Component({
  selector: 'app-trading-hours',
  templateUrl: './trading-hours.component.html',
  styleUrls: ['./trading-hours.component.scss']
})
export class TradingHoursComponent implements OnInit {
  Columns = ['day', 'day_name', 'open', 'close', 'edit', 'remove']

  id
  day
  hour
  silo
  silos
  silo_id
  page = 1
  openValue
  hours = []
  limit = 10
  closeValue
  retries = 0
  edit = false
  delete = false
  create = false
  holiday = false


  days = [
    {
      id: 0,
      name: 'Sunday'
    },
    {
      id: 1,
      name: 'Monday'
    },
    {
      id: 2,
      name: 'Tuesday'
    },
    {
      id: 3,
      name: 'Wednesday'
    },
    {
      id: 4,
      name: 'Thursday'
    },
    {
      id: 5,
      name: 'Friday'
    },
    {
      id: 6,
      name: 'Saturday'
    }
  ]



  constructor(private api: ApiService,
              private message: MessagesService,
              public auth: AuthService) { }

  ngOnInit(): void {
    this.getSilos()
    this.getHours()
  }


  // =================
  //      TOGGLE
  // =================

  toggleCreate(){
    this.create = !this.create
  }

  toggleEdit(hour){
    this.edit = !this.edit
    this.hour = hour
    this.day = hour.day_name
  }

  deleteCheck(hour){
    this.delete = !this.delete
    this.hour = hour
  }
  // =================
  //      SILOS
  // =================

  getSilos(){
    return new Promise(resolve => {
      this.api.getList("catelog","silos").subscribe((data:any)=>{
        let cs = {
          id: -1,
          name: "Customer Support",
          tag: "Customer Support"
        }

        data.unshift(cs)
        if(!this.silo){
        this.silo = data[0]
        this.silo.id = data[0].id
        }
        this.silos = data
        resolve("success")
      })
  })

}

siloChanged(silo){
  this.hours = []
  this.silo = silo
  this.getHours()
}

  // =================
  //      HOURS
  // =================

  async getHours(){
    await this.getSilos();
    let hour = [];
    this.api.getList(`fulfilment`, `admin/trading_hours?per_page=1000`).subscribe((hours: any) => {

      console.log(hours)
      console.log(this.silo)

      for (var i = 0; i < hours.hours.length; i++){

        if(this.silo.id == hours.hours[i].silo_id){
          console.log(hours.hours[i])
          hour.push(hours.hours[i])
          this.hours = hour
        }
      }
    }, error => {
      this.message.showSnack(error.error)
      if(this.retries < 3 && error.status !== 401){
        this.retries++
        this.getHours()
      }
    })
  }

  createHours(hour){
    let newHours = {
      silo_id: hour.silo,
      day_name: hour.day.name,
      opens_at: hour.opens,
      closes_at: hour.closes,
      day: hour.day.id
    }

    console.log(newHours)


    this.api.post("fulfilment","admin/trading_hours", newHours).subscribe((data:any)=>{
      this.message.showSnack(`Successfully created Trading hour`)
      this.getHours();
      this.toggleCreate()
    },error=>{
      this.message.showSnack(error.statusText);
    });
  }

  updateHours(hour){
    let newHours = {
      silo_id: this.hour.silo_id,
      day_name: hour.day.name,
      opens_at: hour.opens,
      closes_at: hour.closes,
      day: hour.day.id
    }

    this.api.patch(`fulfilment`, `admin/trading_hours/${this.hour.ID}`, newHours).subscribe((hours: any) => {
      this.message.showSnack(`Successfully updated trading hours for ${hours.day_name}`);
      this.getHours();
      this.toggleEdit(hour)
    }, error => {
      this.message.showSnack(error.statusText)
    })
  }

  deleteHour(){
    this.api.delete(`fulfilment`, `admin/trading_hours/${this.hour.ID}`).subscribe((hour:any) => {
      this.message.showSnack(`Successfully removed trading hour`)
      this.getHours();
      this.deleteCheck(hour);
    }, error => {
      this.message.showSnack(error.statusText)
    })
  }

  setLimit(limit){}
  next(){}
  previous(){}

}
