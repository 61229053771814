import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Driver } from 'selenium-webdriver/chrome';
import { ApiService } from 'src/app/services/api.service';
import { BanksService } from 'src/app/services/banks/banks.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-create-finances',
  templateUrl: './create-finances.component.html',
  styleUrls: ['./create-finances.component.scss']
})
export class CreateFinancesComponent implements OnInit {

  constructor(
    public api: ApiService,
    public message: MessagesService,
    public router: Router,
    public route: ActivatedRoute,
    public banks: BanksService
  ) {}

 
  user_id;
  driver;
  bank;
  other = false;
  bank_name;
  account_name;
  account_number;
  branch_code;
  verified;
  default_account;
  bank_id;
  pattern="[0-9]{9}$"
  accNumberPlaceholder="Enter account number"
   firstForm = new UntypedFormGroup({
    bank_name: new UntypedFormControl(''),
    account_name: new UntypedFormControl(''),
    account_number: new UntypedFormControl(''),
    branch_code: new UntypedFormControl('') 
    }); 
  payout_type: string;
  payout_types = ["EFT", "eWallet"];
  displayBankSetup = false;
  displayEwalletSetup = false;


    get number() { return this.firstForm.get('account_number'); }

    ngOnInit() : void {
      this.user_id = this.route.snapshot.paramMap.get('id');
      this.banks.getBanks() 
    }   

    submitDriverBankInfo(){
      this.driver = {...this.firstForm.value}; 
      this.driver.user_id = this.user_id;
    if (this.payout_type === "eft") {
      this.driver.bank_id = this.driver.bank_name.ID;
      this.driver.bank_name = this.driver.bank_name.name;
      this.driver.branch_code = this.driver.branch_code;
    }else{
      this.driver.bank_name = ""
      this.driver.branch_code = ""
      this.driver.bank_id = "null"
    }
      this.driver.account_name = this.driver.account_name;
      this.driver.account_number = this.driver.account_number;
      this.driver.account_type = this.payout_type;
  }

    createDriverBank(){
      this.api.post('payments',`admin/accounts`,this.driver).subscribe((data:any)=>{
        this.message.showSnack(`Succesfully added bank account`);
        this.router.navigate([`users/${this.driver.user_id}`]);
      }, error => {
        this.message.showSnack(error.error.message)
      });
    }

    autofillBranchCode(bank) {
      this.branch_code = bank.branch_code;
      this.pattern = bank.account_number_regex;
      this.setAccountNumberPlaceholder(bank.account_number_regex)
      if (bank.name == 'other') {
        this.other = true;
      } else {
        this.other = false;
      }
    }

    setAccountNumberPlaceholder(regex){
      if(this.payout_type != "ewallet"){
      var length = regex.replace("[0-9]{","").replace("}$","").replace(","," or ")
      
      this.accNumberPlaceholder = "Enter a number of length " + length
      }

    }
  
  handlePayoutTypeChange(type: string) {
    type = type.toString().toLowerCase()
    this.displayBankSetup = false;
    this.displayEwalletSetup = false;
    this.payout_type = type;
    switch (type) {
      case "eft":
        this.accNumberPlaceholder = "Enter account number";
        this.displayBankSetup = true;
        break;
      case "ewallet":
        this.pattern = String.raw`\+?[{2}][{7}][0-9]{9}$`
        this.accNumberPlaceholder = "Enter valid mobile number e.g +27xxxxxxxxx";
        this.displayEwalletSetup = true;
    }
  }
}