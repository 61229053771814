import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {
  Columns = ['default_card', 'brand', 'holder', 'number', 'expiry', 'created', 'valid']

  id
  cards
  page = 1
  limit = 10
  retries = 0

  constructor(public api: ApiService,
              public route: ActivatedRoute,
              public message: MessagesService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getUserCards();
  }

  getUserCards(){
    this.api.getList('payments', `admin/users/${this.id}/cards?page=${this.page}&per_page=${this.limit}`).subscribe((cards: any) => {
      this.cards = cards.cards
    }, error => {
      this.message.showSnack(error.error)
      if(this.retries < 3){
        if(error.status !== 401){
          this.retries++
          this.getUserCards()
        }
      }
    })
  }

  setLimit(limit){
    this.limit = limit
    this.getUserCards();
  }

  next(){
    this.page++
    this.getUserCards();
  }

  previous(){
    if(this.page > 1){
      this.page--
      this.getUserCards();
    }
  }
}
