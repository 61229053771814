import { MiscService } from './../../../services/misc/misc.service';
import { SectionsService } from './../../../services/sections/sections.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { PagerService } from 'src/app/services/pager.service';
import { ActivatedRoute } from '@angular/router';
import { MessagesService } from 'src/app/services/messages.service';
import { Component, Inject, OnInit, EventEmitter } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent implements OnInit {
  background = `var(--quenchPink)`;
  isDischemUser = false;

  id;
  data;
  section;

  constructor(
    public pager: PagerService,
    public messages: MessagesService,
    private api: ApiService,
    public auth: AuthService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public sections: SectionsService
  ) {}

  ngOnInit(): void {
    if (
      this.auth.dis_chem_role ||
      (localStorage.getItem('dis-chem-role') &&
        localStorage.getItem('dis-chem-role') == 'true')
    ) {
      this.isDischemUser = true;
      this.background = `#35c27e`
    }

    this.id = this.route.snapshot.paramMap.get('id');

    this.sections.getSection(this.id);
  }

  // remove array data
  ngOnDestroy(): void {
    this.sections.data = [];
  }

  // =======================
  //   ITEMS REMOVE DIALOG
  // =======================

  // open dialog for banner update
  removeSectionItems(product) {
    const dialog = this.dialog.open(RemoveSectionItemsDialog, {
      width: '550px',
      height: '200px',
      data: {
        product: product,
        section: this.sections.section,
        isdischemUser: this.isDischemUser,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.sections.listSectionInfo();
    });
  }

  // ====================
  //   ITEMS ADD DIALOG
  // ====================

  // open dialog for banner update
  addSectionItems() {
    const dialog = this.dialog.open(SectionItemsDialog, {
      width: '92vw',
      height: '80vh',
      data: {
        section: this.sections.section,
        isdischemUser: this.isDischemUser,
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.sections.listSectionInfo();
    });
  }
}

// ============================
//    STORE REMOVE DIALOG
// ============================

@Component({
  selector: 'delete-dialog',
  templateUrl: '../../../components/dialogs/delete-dialog.html',
  styleUrls: ['../../../components/dialogs/delete-dialog.component.scss'],
})
export class RemoveSectionItemsDialog {
  inputTouch = false;
  heading = `product from section`;
  body = `Are you sure you want to remove this item from this section? You can always add it again later.`;

  item;
  section;

  // get section info after success
  onAdd = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { section; product; isdischemUser },
    private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<RemoveSectionItemsDialog>
  ) {}

  ngOnInit(): void {
    this.item = this.data.product;
    this.section = this.data.section;
  }

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  removeItem() {
    if (this.data.isdischemUser) {
      // console.log('dc');
      this.removeDcItem();
    } else {
      // console.log('admin');
      this.removeAdminItem();
    }
  }

  removeAdminItem() {
    if (this.section.type == 'products') {
      this.api
        .delete('catelog', 'sectionproducts/' + this.item.section_product_id)
        .subscribe(
          (data: any) => {
            this.closeDialog();
            this.message.showSnack(
              `Succesfully removed ${this.item.name} from section  ${this.section.title}`
            );
          },
          (error) => {
            this.message.showSnack(error.error);
          }
        );
    } else if (this.section.type == 'posts' || this.section.type == 'banners') {
      this.api
        .delete(`catelog`, `sectionposts/${this.item.section_post_id}`)
        .subscribe(
          (post: any) => {
            this.closeDialog();
            this.message.showSnack(
              `Successfully removed ${this.item.title} from ${this.section.title}`
            );
          },
          (error) => {
            this.message.showSnack(error.error);
          }
        );
    } else if (this.section.type == 'stores') {
      this.api
        .delete(`catelog`, `sectionstores/${this.item.section_store_id}`)
        .subscribe(
          (store: any) => {
            this.closeDialog();
            this.message.showSnack(
              `Successfully removed ${this.item.name} from ${this.section.title}`
            );
          },
          (error) => {
            this.message.showSnack(error.error);
          }
        );
    }
  }

  removeDcItem() {
    if (this.section.type == 'products') {
      this.api
        .delete(
          `catelog`,
          `dischem/sections/products/${this.item.section_product_id}`
        )
        .subscribe(
          (data: any) => {
            this.closeDialog();
            this.message.showSnack(
              `Succesfully removed ${this.item.name} from section  ${this.section.title}`
            );
          },
          (error) => {
            this.message.showSnack(error.error);
          }
        );
    } else if (this.section.type == 'posts') {
      this.api
        .delete(
          `catelog`,
          `dischem/sections/posts/${this.item.section_post_id}`
        )
        .subscribe(
          (post: any) => {
            this.closeDialog();
            this.message.showSnack(
              `Successfully removed ${this.item.title} from ${this.section.title}`
            );
          },
          (error) => {
            this.message.showSnack(error.error);
          }
        );
    }
  }
}

// =====================
//     ADD DIALOG
// =====================

@Component({
  selector: 'section-add-items-dialog',
  templateUrl: 'section-add-items.html',
  styleUrls: ['../../pocket-mall/mall-stores/store-add.component.scss'],
})
export class SectionItemsDialog {
  Columns = ['image', 'name', 'add'];

  silos;
  section;
  retailers;
  searchData;

  background = `var(--quenchPink)`;


  page = 1;
  limit = 10;
  search = '';
  silo_id = 0;
  retailer_id = 0;
  retailerSearch = '';

  // get section info after success
  onAdd = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { section; isdischemUser },
    private api: ApiService,
    private message: MessagesService,
    public dialogRef: MatDialogRef<SectionItemsDialog>,
    public misc: MiscService
  ) {}

  ngOnInit(): void {
    if(this.data.isdischemUser){
      this.background = `var(--dischemGreen)`
    }
    this.section = this.data.section;
    this.actions();
  }

  actions() {
    if (this.data.isdischemUser) {
      this.dischemSearch();
    } else {
      this.sectionSearch();
      this.getSilos();
      this.getRetailers();
    }
  }

  closeDialog() {
    this.dialogRef.close();
    this.onAdd.emit();
  }

  // ========================
  //    GET ITEMS TO ADD
  // ========================

  dischemSearch() {
    if (this.section.type == 'products') {
      this.getDischemProducts();
    } else if (this.section.type == 'posts') {
      this.getDischemPosts();
    }
  }

  sectionSearch() {
    if (this.section.type == 'products') {
      this.getProducts();
    } else if (this.section.type == 'posts') {
      this.getPosts();
    } else if (this.section.type == 'stores') {
      this.getStores();
    }
  }

  // ----------------
  //      ADMIN
  // ----------------

  // get products to add
  getProducts() {
    this.api
      .getList(
        'catelog',
        `products?page=${this.page}&per_page=${this.limit}&search=${this.search}&silo_id=${this.silo_id}&retailer_id=${this.retailer_id}`
      )
      .subscribe((products: any) => {
        this.searchData = products;
      });
  }

  getPosts() {
    let posts = [];
    this.api
      .getList(
        'catelog',
        `posts?page=${this.page}&per_page=${this.limit}&search=${this.search}&silo_id=${this.silo_id}`
      )
      .subscribe((data: any) => {
        this.searchData = data;
      });
  }

  getStores() {
    this.api
      .getList(
        'catelog',
        `stores?per_page=${this.limit}&search=${this.search}&silo_id=${this.silo_id}`
      )
      .subscribe((stores: any) => {
        this.searchData = stores;
      });
  }

  // ----------------
  //     DISCHEM
  // ----------------

  getDischemProducts() {
    this.api
      .getList(
        `catelog`,
        `dischem/products?page=${this.page}&per_page=${this.limit}&search=${this.search}`
      )
      .subscribe((data: any) => {
        console.log(data);
        this.searchData = data;
      });
  }

  getDischemPosts() {
    this.api
      .getList(
        `catelog`,
        `dischem/posts?page=${this.page}&per_page=${this.limit}&search=${this.search}`
      )
      .subscribe((data: any) => {
        this.searchData = data;
      });
  }

  // =====================
  //    APPLY FILTER
  // =====================

  selectRetailer() {
    this.page = 1;
    this.getProducts();
  }

  getRetailers() {
    this.api
      .getList(
        'catelog',
        `retailers?page=${this.page}&per_page=${100}&search=${
          this.retailerSearch
        }`
      )
      .subscribe(
        (retailers: any) => {
          let all = {
            id: 0,
            name: 'All',
          };

          retailers.unshift(all);
          this.retailers = retailers;
        },
        (error) => {
          this.message.showSnack(error.error);
        }
      );
  }

  selectSilo() {
    this.page = 1;
    this.sectionSearch();
  }

  getSilos() {
    this.api.getList('catelog', `silos`).subscribe(
      (silos: any) => {
        let all = {
          id: 0,
          name: 'All',
        };

        silos.unshift(all);
        this.silos = silos;
      },
      (error) => {
        this.message.showSnack(error.error);
      }
    );
  }

  // ========================
  //  ARRAY OF ITEMS TO ADD
  // ========================

  itemsArr = [];

  toggleSelection(cat) {
    var storeRemove = this.itemsArr.map((item) => item.id).indexOf(cat.id);

    if (storeRemove !== -1) {
      this.itemsArr.splice(storeRemove, 1);
    } else if (storeRemove === -1) {
      this.itemsArr.push(cat);
    }
  }

  itemstoAdd;

  addItemsToSection() {
    let newItemsArr = [];

    this.itemsArr.forEach((item) => {
      let data = {
        active: true,
        end_date: '2020-01-02T17:40:18.172744+02:00',
        id: 0,
        position: 1,
        section_id: this.section.id,
        start_date: '2021-01-02T17:40:18.172744+02:00',
      };

      if (this.section.type == 'products') {
        data['product_id'] = item.id;
        newItemsArr.push(data);
      } else if (this.section.type == 'posts') {
        data['post_id'] = item.id;
        newItemsArr.push(data);
      } else if (this.section.type == 'stores') {
        data['store_id'] = item.id;
        newItemsArr.push(data);
      }
    });

    this.itemstoAdd = newItemsArr;

    if (this.data.isdischemUser) {
      this.addDischemItems();
    } else {
      this.addAdminItems();
    }
  }

  addAdminItems() {
    if (this.section.type == 'products') {
      this.api
        .post('catelog', 'sectionproducts', this.itemstoAdd)
        .subscribe((data: any) => {
          this.message.showSnack(
            `added item/s to section ${this.section.title}`
          );
          this.closeDialog();
        });
    } else if (this.section.type == 'posts') {
      this.api
        .post('catelog', `sectionposts`, this.itemstoAdd)
        .subscribe((data: any) => {
          this.message.showSnack(
            `added item/s to section ${this.section.title}`
          );
          this.closeDialog();
        });
    } else if (this.section.type == 'stores') {
      this.api
        .post(`catelog`, `sectionstores`, this.itemstoAdd)
        .subscribe((data: any) => {
          this.message.showSnack(
            `added item/s to section ${this.section.title}`
          );
          this.closeDialog();
        });
    }
  }

  addDischemItems() {
    if (this.section.type == 'products') {
      this.api
        .post(`catelog`, 'dischem/sections/products', this.itemstoAdd)
        .subscribe((data: any) => {
          this.message.showSnack(
            `added item/s to section ${this.section.title}`
          );
          this.closeDialog();
        });
    } else if (this.section.type == 'posts') {
      this.api
        .post(`catelog`, 'dischem/sections/posts', this.itemstoAdd)
        .subscribe((data: any) => {
          this.message.showSnack(
            `added item/s to section ${this.section.title}`
          );
          this.closeDialog();
        });
    }
  }

  // ====================
  //     PAGINATION
  // ====================

  setLimit(limit) {
    this.limit = limit;
    if (this.data.isdischemUser) {
      this.dischemSearch();
    } else {
      this.sectionSearch();
    }
  }

  start() {
    this.page = 1;
    if (this.data.isdischemUser) {
      this.dischemSearch();
    } else {
      this.sectionSearch();
    }
  }

  next() {
    this.page++;
    if (this.data.isdischemUser) {
      this.dischemSearch();
    } else {
      this.sectionSearch();
    }
  }

  previous() {
    if (this.page > 1) {
      this.page--;
      if (this.data.isdischemUser) {
        this.dischemSearch();
      } else {
        this.sectionSearch();
      }
    }
  }
}
