<div class="zones">

    <div class="filter-flex">

        <mat-form-field appearance='outline' class='field'>
            <mat-label>Region</mat-label>
            <mat-select  [(ngModel)]="selectedRegion"  class='select-panel' (selectionChange)="selectRegion()">
                <mat-option *ngFor="let area of areas" [value]="area" >
                    {{area.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance='outline' class='field'>
            <mat-label>Select Silo</mat-label>
            <mat-select  [(ngModel)]="selectedSilo"  class='select-panel' (selectionChange)="siloSelect()">
                <mat-option *ngFor="let silo of silos" [value]="silo" >
                    {{silo.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </div>
    
    <div class="color-schemes">
        <p class='dischem'>dis-chem - <span>green</span></p>
        <p class='woolworths'>woolworths - <span>black</span></p>
        <p class='deliverd'>deliverD - <span>blue</span></p>
        <p class='liquor'>liquor/other - <span>red</span></p>
        <p class='liquor instruction' >*select a specific silo to start editing zones</p>
    </div>

    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [scrollwheel]="false" (mapReady)="onMapReady($event)" ></agm-map>

    <div class="actions">
        <button mat-flat-button class='button' (click)='editToggle()' *ngIf='!edit && showEditButton'>edit</button>
        <button mat-flat-button  (click)='editToggle()' *ngIf='edit'>cancel</button>
        <button mat-flat-button class='button' (click)='updatePolygon()' *ngIf='edit'>update</button>
    </div>
    
</div>

