<!-- <div class="stores"> -->

  <table  mat-table  
          [dataSource]='stores' >
  
    <!-- Icon Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef > Name </th>
      <td mat-cell  *matCellDef="let store"><p>{{store.name}}</p> </td>
    </ng-container>
  
  
    <!-- Name Column -->
    <ng-container matColumnDef="address">
      <th mat-header-cell  *matHeaderCellDef> Address </th>
      <td mat-cell  *matCellDef="let store" > <p >{{store.physical_address}}</p> </td>
    </ng-container>
  
  
    <!-- Add Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell  *matHeaderCellDef> Email</th>
      <td mat-cell  *matCellDef="let store"><p> {{store.email}} </p></td>
    </ng-container>

    <ng-container matColumnDef="phone">
        <th mat-header-cell  *matHeaderCellDef> Phone</th>
        <td mat-cell  *matCellDef="let store" ><p>{{store.phone}} </p></td>
      </ng-container>
      
    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row *matRowDef="let store; columns: Columns;" routerLink='/stores/{{store.id}}' class='action'></tr>
  </table>
  
  
  <div id="pager" >

    <button mat-flat-button class='button' id='create' routerLink='/create-store'>New</button>

    <div class="page">  

    <div> Per page:</div>
    <div (click)="setLimit(10)" class="limit" >10</div>
    <div (click)="setLimit(15)" class="limit" >15</div>
    <div (click)="setLimit(20)" class="limit" >20</div>
    <div (click)="setLimit(50)" class="limit" >50</div>
    <div>Page:</div>
    <mat-icon (click)='previous()'>skip_previous</mat-icon>
    <div>{{page}}</div>
      <mat-icon (click)='next()'>skip_next</mat-icon>
      <div> </div>
    </div>
  </div>
  
  