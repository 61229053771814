import { RoleGaurd } from './authentication/role.gaurd';
import { Inject, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { MessagesService } from '../services/messages.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnInit {
  dis_chem_role = false;
  finance_role  = false

  public geopoints;

  public user;
  public remember = false;
  public expand = false;
  public isLoggedIn = false;

  constructor(
    private api: ApiService,
    private message: MessagesService,
    public router: Router,
    public role: RoleGaurd,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') == 'YES'
    ) {
      this.isLoggedIn = true;
    }
  }

  ngOnInit(): void {
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') == 'YES'
    ) {
      this.isLoggedIn = true;
    }
  }

  isDischemUser(roles) {
    if (roles.includes('Dis-Chem') && !roles.includes('Admin')) {
      this.dis_chem_role = true;
      localStorage.setItem('dis-chem-role', 'true');
    }
    return this.dis_chem_role;
  }

  isFinanceUser(roles){
    if(roles.includes("Finance")&& roles.includes('Admin')){
      this.finance_role = true
      localStorage.setItem('finance-role', "true")
    } 
    // else {
      // localStorage.setItem('finance-role', "false")
    // }
    return this.finance_role
  }

  checkIfAdmin(roles) {
    var isAdmin = false;
    roles.forEach((rol) => {
      if (rol == 'Admin' || rol == 'Dis-Chem') {
        isAdmin = true;
      }
    });
    return isAdmin;
  }

  login(email, password) {
    let user = {
      email: email,
      password: password,
      remember: true,
    };

    this.api.post('auth', 'user/login', user).subscribe(
      (data: any) => {
        this.isDischemUser(data.data.roles);
        this.isFinanceUser(data.data.roles)
        // console.log(data.data.roles)

        if (this.checkIfAdmin(data.data.roles)) {
          // console.log(data)
          this.isLoggedIn = true;
          localStorage.setItem('LOGGEDIN', 'YES');
          localStorage.setItem('AUTH_TOKEN', data.data.token);
          localStorage.setItem('REFRESH', data.data.refresh);
          if (!this.dis_chem_role) {
            this.api.getList('auth', 'user/profile').subscribe((user: any) => {
              this.user = `${user.user.first_name} ${user.user.last_name}`;
              this.message.showSnack(`Welcome Back ${user.user.first_name}`);
              localStorage.setItem(
                `user`,
                `${user.user.first_name} ${user.user.last_name}`
              );
            });
          }

          this.router.navigate(['/dashboard']);
        } else {
          this.message.showSnack(
            'Your login is valid, but you do not have admin permissions'
          );
        }
      },
      (error: any) => {
        this.message.showSnack(error.error.message);
      }
    );
  }


  // removeStyle( ){
  //   const head = this.document.getElementsByTagName('head')[0];
  //   let last = head.lastChild
  //   head.removeChild(last)
  // }

  logout() {
    this.isLoggedIn = false;
    this.dis_chem_role = false;
    localStorage.clear();
    this.role.role = false;
  }
}
