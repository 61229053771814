import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit {

  constructor(private api: ApiService,
              private message: MessagesService,
              public auth: AuthService) { }


  ngOnInit(): void {
    
    this.getSaleStats()

    if(window.innerWidth > 1100){
      this.width = window.innerWidth - 110
      this.retailer_width = 500
      
      } else {
        this.width = window.innerWidth - 50
        this.retailer_width = window.innerWidth - 60
      }
    }
  
    // chart responsiveness
    onResize(event){
      var w = event.target.innerWidth
  
  
      if(w > 1100){
        this.width = w - 110
        this.retailer_width = 500
      } else {
        this.width = w - 50
        this.retailer_width = w - 60
      }
  }

  retailers
  retailer_turnover  = []
  retailer_monthly_turnover = []


  average_hourly_orders = []

 width  = 800
 height = 400


 retailer_width = 500

    // CHART OPTION SETTINGS
    quench = {
      pointSize: 5,
      curveType: 'function',
      colors: ['#ff4081', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
      chartArea:{left:50,top:10,right:10,bottom:60 ,width: '100%', height: '100%'},
    }

  getSaleStats(){
    this.api.getList(`fulfilment`, `dashboard/sales`).subscribe((result: any) => {

      // average orders per hour
      result.avg_orders_per_hour.forEach((hour:any)=>{
        this.average_hourly_orders.push([`${hour.hour.substring(0,5)} `, hour.average_orders_placed ])
      })

      // retailers monthly turnover
      result.retailer_turnover_month.forEach(item => {

        let newItem = {
          id: item.retailer_id,
          turnovers: []
        }

        this.api.getList('catelog', `retailers?per_page=100`).subscribe((data: any) => {
          data.forEach(retailer => {
            if(retailer.id == item.retailer_id){
              newItem['retailer'] = retailer.name
              newItem['image'] = retailer.image
            }
          })
        })


        item.turnover.forEach(turnover => {
          newItem.turnovers.push([`${turnover.effective_date.substring(0,10)}`, turnover.total_turnover])
        })

        this.retailer_monthly_turnover.push(newItem)
      })

      
      // retailer turnovers
      result.retailer_turnover_total_orders.forEach(item => {
        this.api.getList('catelog', `retailers?per_page=${100}`)
        .subscribe((retailers: any) => {
          retailers.forEach(retailer => {
            if(retailer.id == item.retailer.id){
              item.retailer['retailer'] = retailer.name
              item.retailer['image'] = retailer.image
              this.retailer_turnover.push(item.retailer)
            }
          })
        })
      })

    })
  }

}
