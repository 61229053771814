// import * as _moment                                 from 'moment'
import { ApiService }                               from 'src/app/services/api.service'
import { AuthService }                              from 'src/app/services/auth.service'
import { MessagesService }                          from 'src/app/services/messages.service'
import { Component, Inject, OnInit, EventEmitter }  from '@angular/core'
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { formatDate } from '@angular/common'


@Component({
  selector: 'app-promotionals',
  templateUrl: './promotionals.component.html',
  styleUrls: ['./promotionals.component.scss']
})
export class PromotionalsComponent implements OnInit {
  Columns = ['code', 'start_date', 'end_date', 'amount', 'description','product', 'silo', 'quantity', 'min_amount', 'multiple-use', 'first order', 'active', 'action']

  silos
  promo
  promocodes

  page    = 1
  limit   = 10
  edit    = false
  retries = 0
  delete  = false

  constructor(public api: ApiService,
              public message: MessagesService,
              public dialog: MatDialog,
              public auth: AuthService) { }

  ngOnInit(): void {
    this.getPromoCodes()
  }

  toggleEdit(promo){
    this.edit = !this.edit
    this.promo = promo
  }

  deleteDialog(promo){
    this.promo = promo
    this.delete = !this.delete
  }


  getPromoCodes(){
    this.api.getList("fulfilment",`admin/promocodes?page=${this.page}&amp:per_page=${this.limit}`).subscribe((data:any)=>{
      // let promos = []
      this.promocodes = data.data
      // console.log(data)
      // promos.push(data.data[0])

      // for(var i = 0; i < data.data.length; i++){
      //   if(promos.filter(n => n.code === data.data[i].code).length > 0){
      //     const first = (element) => element == data.data[i].code
      //     console.log(promos.findIndex(first))
      //   } else {
      //     promos.push(data.data[i])
      //   } 
      //   this.promocodes = promos
      // }
      data.data.forEach(promo => {
        this.api.getItem('catelog', 'products', promo.product_id).subscribe((data:any) => {
          promo["product"] = data.name
        })
      })

      this.getSilos()

    },error=>{
      this.message.showSnack(error.error)
      if(this.retries < 3 && error.status !== 401){
        this.retries++
        this.getPromoCodes()
      }
    })
  }

  togglePromoStatus(promo){

    if(promo.active){
      promo.active = false
    } else {
      promo.active = true
    }

    // console.log(promo)


    this.api.patch('fulfilment', `admin/promocodes/${promo.ID}`, promo ).subscribe((promotion: any) => {
      if(promotion.data.active){
      this.message.showSnack(`Successfully activated promo code ${promotion.data.code}`)
      } else {
      this.message.showSnack(`Successfully de-activated promo code ${promotion.data.code}`)
      }
    }, error => {
      this.message.showSnack(error.error.error)
    })
  }


  getSilos(){
    this.api.getList('catelog', 'silos').subscribe((silos: any) => {
      this.silos = silos

      this.promocodes.forEach(promo => {
        silos.forEach(silo => {
          if(silo.id == promo.silo_id){
            promo['silo'] = silo.name
          }
        })
      })
    }, error => {
      this.message.showSnack(error.error)
    })
  }

// ============================
//  UPDATE PROMO DIALOG
// ============================

    updatePromoDialog(promo){
        const dialog = this.dialog.open(promoUpdateDialog, {
          width: 'max-content',
          height: '70vh',
          data: {
            promo: promo,
          }
        })
    
        dialog.afterClosed().subscribe((result: any) => {
          this.getPromoCodes()
        })
      }

// ============================
//  DELETE PROMO DIALOG
// ============================

  deletePromo(promo){
    const dialog = this.dialog.open(promoDeleteDialog, {
      width: '550px',
      height: '200px',
      data: {
        promo: promo,
      }
    })

    dialog.afterClosed().subscribe(() => {
      this.getPromoCodes()
    })
  }
}


@Component({
  selector: 'promo-product-add',
  templateUrl: '/promotional-update.html',
  styleUrls: ['promotionals.component.scss']
})

export class promoUpdateDialog {
  Columns: string[] = ['image', 'name', 'size', 'price', 'check']

  
  inputTouch  = false

  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }

  code
  desc
  amount  
  silos
  qty 
  min_am 
  silo_id 
  siloValue
  date_range
  active = true
  start
  end
  promo
  // get section info after success
  onAdd         = new EventEmitter()
  promoproduct  = false 
  first_order   = false
  multiple_uses = false
  product       = false
  

  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: { promo }, 
              private api : ApiService, 
              private message: MessagesService,  
              public dialogRef: MatDialogRef<promoUpdateDialog>,
              public dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.promo = this.data.promo
    this.code = this.data.promo.code
    this.desc = this.data.promo.description
    this.amount = this.data.promo.amount
    this.qty = this.data.promo.quantity
    this.min_am = this.data.promo.min_order_amount
    this.start = this.data.promo.starting_date
    this.end = this.data.promo.ending_date
  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

  updatePromoCode(promo){
    const format = 'yyyy-MM-dd'
    const locale = 'en-US';

    let sd = formatDate(this.start, format, locale)
    let ed = formatDate(this.end, format, locale)
    
    let promocode = {
      code: promo.code,
      description: promo.description,
      amount: promo.amount ,
      min_order_amount: promo.min_order_amount ,
      quantity: promo.quantity,
      starting_date: sd,
      ending_date: ed,
      silo_id: this.promo.silo_id
    }

    this.api.patch("fulfilment", `admin/promocodes/${this.promo.ID}`, promocode).subscribe((promo: any) => {
      this.message.showSnack(`Succesfully update promo ${this.promo.code}`)
      this.closeDialog()
    }, error => {
      this.message.showSnack(error.error.error)
    })
  }

}

@Component({
  selector: 'delete-dialog',
  templateUrl: '../../../../components/dialogs/delete-dialog.html',
  styleUrls: ['../../../../components/dialogs/delete-dialog.component.scss']
})

export class promoDeleteDialog {

  
  inputTouch  = false
  heading     = `promocode`
  body        = `Are you sure you want to delete this promo code? This action cannot be undone.`

  promo

  // get section info after success
  onAdd       = new EventEmitter()
  
  onFocus(){
    this.inputTouch = true
  }

  onBlur(){
    this.inputTouch = false
  }


  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: { promo }, 
              private api : ApiService, 
              private message: MessagesService,  
              public dialogRef: MatDialogRef<promoDeleteDialog>) {

  }

  ngOnInit(): void {
    this.promo = this.data.promo

  }

  closeDialog() {
    this.dialogRef.close()
    this.onAdd.emit()
  }

  removeItem(){
    this.api.delete("fulfilment",`admin/promocodes/${this.promo.ID}`).subscribe((promo:any)=>{
          this.message.showSnack(` SuccessFully removed ${promo.data.name} `)
          this.closeDialog()
        }, error => {
          this.message.showSnack(error.error.error)
        })
      }

}
