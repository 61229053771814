<div class="silos" *ngIf="!isDischemUser">
  <div
    (click)="setSilo(asilo)"
    *ngFor="let asilo of misc.silos"
    [class]="asilo.name == misc.silo.name ? 'isBold' : ''"
  >
    {{ asilo.name }}
  </div>
</div>

<!-- ================== -->
<!--     SECTIONS       -->
<!-- ================== -->

<table class="table">
  <thead>
    <tr>
      <th class="header" id="sort">Sort</th>
      <th class="header" id="icon">Title</th>
      <th class="header">Type</th>
      <th class="header">Active</th>
      <th class="header"></th>
    </tr>
  </thead>
  <tbody cdkDropList (cdkDropListDropped)="sections.drop($event)">
    <tr
      class="action"
      *ngFor="let section of sections.sections"
      cdkDrag
      cdkDragLockAxis="y"
    >
      <th class="sort">
        <p><mat-icon>sort</mat-icon></p>
      </th>
      <th routerLink="/sections/{{ section.id }}">
        <p>{{ section.title }}</p>
      </th>
      <th>{{ section.type }}</th>
      <th>
        <p *ngIf="section.active" class="active"><mat-icon>check</mat-icon></p>
        <p *ngIf="!section.active" class="inactive">
          <mat-icon>clear</mat-icon>
        </p>
      </th>
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <th class="more">
        <mat-icon matTooltip="more" [matMenuTriggerFor]="menu" class="action"
          >more_horiz</mat-icon
        >
        <mat-menu #menu="matMenu">
          <button mat-menu-item routerLink="/sections/{{ section.id }}">
            <mat-icon class="material-icons-outlined">arrow_forward</mat-icon>
            <span>view section info </span>
          </button>
          <button mat-menu-item (click)="sections.toggleSectionStatus(section)">
            <mat-icon class="material-icons-outlined" *ngIf="section.active"
              >toggle_off</mat-icon
            >
            <span *ngIf="section.active">deactivate section</span>
            <mat-icon class="material-icons-outlined" *ngIf="!section.active"
              >toggle_on</mat-icon
            >
            <span *ngIf="!section.active">activate section</span>
          </button>
          <button mat-menu-item (click)="editSection(section)">
            <mat-icon class="material-icons-outlined">edit</mat-icon>
            <span>edit section </span>
          </button>
          <button mat-menu-item (click)="removeSection(section)">
            <mat-icon>delete_outline</mat-icon>
            <span>delete section</span>
          </button>
        </mat-menu>
      </th>
    </tr>
  </tbody>
</table>

<!-- New Section button -->

<div id="pager">
  <button
    mat-flat-button
    class="button"
    id="{{ auth.expand ? 'create-expand' : 'create' }}"
    routerLink="/sections_create"
  >
    New
  </button>
  <div class="page"></div>
</div>
