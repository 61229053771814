

  <div *ngIf='!add'>
    
    <table  mat-table  [dataSource]='products'>
    
      <!-- Image Column -->
      <ng-container matColumnDef="image" >
        <th mat-header-cell *matHeaderCellDef class='product-image' >  </th>
        <td mat-cell  *matCellDef="let product" class='product-image'> <img  src={{product.image}} alt="" onerror="this.src='assets/placeholders/placeholder-img.png' "> </td>
      </ng-container>
    
    
      <!-- Title Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell  *matHeaderCellDef> Name </th>
        <td mat-cell  *matCellDef="let product" id='title'> <p>{{product.name}}</p> </td>
      </ng-container>
    
      <!-- Post Column -->
      <ng-container matColumnDef="size">
        <th mat-header-cell  *matHeaderCellDef> Size </th>
        <td mat-cell  *matCellDef="let product"> <p  id='product' > {{product.size}} </p></td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell  *matHeaderCellDef> Price </th>
        <td mat-cell  *matCellDef="let product"> <p  id='product' > {{product.price}} </p></td>
      </ng-container>

      <ng-container matColumnDef="add">
        <th mat-header-cell  *matHeaderCellDef> <mat-icon class='action' (click)='toggleAdd()'>add_circle</mat-icon> </th>
        <td mat-cell  *matCellDef="let product" > </td>
      </ng-container>
        
    
      <tr mat-header-row *matHeaderRowDef="Columns"></tr>
      <tr mat-row  id='drag' class='action' routerLink='/products/{{product.product_id}}' *matRowDef="let product; columns: Columns;"></tr>
    </table>

    </div>

    <div *ngIf='add'>
  
      <mat-form-field  *ngIf='add'>
        <mat-label>Search</mat-label>
        <input matInput (input)='searchProducts($event.target.value)' #input>
      </mat-form-field>
    
    
    <table  mat-table  
    [dataSource]='allProducts'>
    
    <!-- Title Column -->
    <ng-container matColumnDef="image">
    <th mat-header-cell *matHeaderCellDef > </th>
    <td mat-cell  *matCellDef="let product"> <img src={{product.image}} alt=""> </td>
    </ng-container>
    
    
    <!-- Silo Column -->
    <ng-container matColumnDef="name">
    <th mat-header-cell  *matHeaderCellDef> Name </th>
    <td mat-cell  *matCellDef="let product" > <p>{{product.name}}</p> </td>
    </ng-container>
    
    
    <ng-container matColumnDef="size">
      <th mat-header-cell  *matHeaderCellDef>Size</th>
      <td mat-cell  *matCellDef="let product" >{{product.size}} </td>
     </ng-container>
  
     <ng-container matColumnDef="price">
      <th mat-header-cell  *matHeaderCellDef>Price</th>
      <td mat-cell  *matCellDef="let product" > {{product.price}}</td>
     </ng-container>
  
     <ng-container matColumnDef="add">
      <th mat-header-cell  *matHeaderCellDef> <mat-icon class='action' (click)='toggleAdd()'>close</mat-icon> </th>
      <td mat-cell  *matCellDef="let product" > <mat-icon (click)='addRetailerProduct(product)'>add_circle</mat-icon> </td>
    </ng-container>
      
    
    <tr mat-header-row *matHeaderRowDef="Columns"></tr>
    <tr mat-row  class='action' *matRowDef="let product; columns: Columns;"></tr>
    </table>
  </div>
  
  
  <div id="pager" >
      
            <button mat-flat-button class='button' id='create' routerLink='/create-retailer-product/{{id}}'>New</button>

      <div class="page"> 
    
      <div> Per page:</div>
      <div (click)="setLimit(10)" class="limit" >10</div>
      <div (click)="setLimit(15)" class="limit" >15</div>
      <div (click)="setLimit(20)" class="limit" >20</div>
      <div (click)="setLimit(50)" class="limit" >50</div>
      <div>Page:</div>
      <mat-icon (click)='previous()'>skip_previous</mat-icon>
      <div>{{page}}</div>
        <mat-icon (click)='next()'>skip_next</mat-icon>
        <div> </div>
      </div>
    </div>
