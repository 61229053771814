<div class="header">
    <h3>Update Contract</h3>
    <mat-icon (click)="closeDialog()" class="action">clear</mat-icon>
  </div>
  <hr />
  
  <form #contract = 'ngForm' (ngSubmit)='updateContract(contract.value)'>
    <mat-form-field appearance="outline" class="field">
      <mat-label>Contract name</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="name"
        placeholder="Scooter"
        name="name"
        required
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="field">
        <mat-label>Contract Date Range</mat-label>
        <mat-date-range-input [rangePicker]="picker" >
          <input
            matStartDate
            name="start_date"
            placeholder="Start date"
            [(ngModel)]="start_date"
            />
          <input
            matEndDate
            name="end_date"
            placeholder="End date"
            [(ngModel)]="end_date"
            />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
  
    <mat-form-field appearance="outline" class="field">
      <mat-label>Contract frequency</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="frequency"
        placeholder="monthly"
        name="frequency"
      />
    </mat-form-field>
  
    <mat-form-field appearance="outline" class="field">
      <mat-label> number of payments</mat-label>
      <input
        type="number"
        matInput
        [(ngModel)]="no_of_payments"
        placeholder="14"
        name="no_of_payments"
      />
    </mat-form-field>
  
    <mat-form-field appearance="outline" class="field">
      <mat-label>Contract payment value</mat-label>
      <input
        type="number"
        matInput
        [(ngModel)]="amount"
        placeholder="1000"
        name="amount"
      />
    </mat-form-field>
  
    <mat-form-field appearance="outline" class="field">
      <mat-label>Contract User ID</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="user_id"
        placeholder="121fds8fsd9fds89dsf"
        name="user_id"
      />
    </mat-form-field>
  
    <div class="save">
      <button mat-flat-button class="button">submit</button>
    </div>
  </form>
  