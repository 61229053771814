<span *ngIf="category">
  <table mat-table [dataSource]='data' matSort >

      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
        <td mat-cell *matCellDef="let data" id='img'> <img id='prod-img' src={{data.image}} alt=""> </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
        <td mat-cell *matCellDef="let data"> <p>{{ data.name }}</p></td>
      </ng-container>

        <ng-container matColumnDef="price" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header ><p *ngIf="silo_id !== 5"> </p> </th>
            <td mat-cell *matCellDef="let data"> <p >{{ data.price }}</p></td>
        </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
        <td mat-cell *matCellDef="let data" (click)='removeItem(data)'> <mat-icon>delete_outline</mat-icon></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row 
          class='action'
          *matRowDef="let data; columns: displayedColumns;">
      </tr>

  </table>


  <div id="pager" >
    <div class="page"> 

     <button id="{{auth.expand ? 'create-expand': 'create'}}" mat-flat-button class='button' (click)="addItems()" *ngIf="silo_id !== 5">add products</button>
     <button id="{{auth.expand ? 'create-expand': 'create'}}" mat-flat-button class='button' (click)="addItems()"*ngIf="silo_id === 5" >add stores</button>

    <div> Per page:</div> 
    <span class="silo-filter pagination">
      <button mat-button [matMenuTriggerFor]="paginate">
        <span>{{limit}}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #paginate="matMenu" >
        <button mat-menu-item (click)="setLimit(10)">10</button>
        <button mat-menu-item (click)="setLimit(20)">20</button>
        <button mat-menu-item (click)="setLimit(50)">50</button>
      </mat-menu>
    </span>

    <div (click)='start()' matTooltip="Skip to start" class='action' *ngIf='page > 1'>back to start</div>
    <p (click)='previous()' class='action'>previous</p>
    <div>{{page}}</div>
      <p (click)='next()' class='action'>next</p>
      <div> </div>
    </div>
  </div>

</span>