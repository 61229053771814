import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accounts-navigate',
  templateUrl: './accounts-navigate.component.html',
  styleUrls: ['./accounts-navigate.component.scss']
})
export class AccountsNavigateComponent implements OnInit {

  current_page = "drivers"

  account_pages = [  {
      name: "drivers",
      route: "/list-user-accounts"
    },
    {
      name: "drivers bank accounts",
      route: "/list-accounts"
    },
  
  ]


  selectTab(tab){
    this.current_page = tab.name
    localStorage.setItem('accountPage', tab.name);
  }


  constructor() { }

  ngOnInit(): void {
    if(localStorage.getItem('accountPage')){
      this.current_page = localStorage.getItem('accountPage');
    } else {
      this.current_page = 'drivers'
    }
  }

}
