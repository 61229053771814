import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MessagesService } from 'src/app/services/messages.service';


@Component({
  selector: 'app-store-trading-hours',
  templateUrl: './store-trading-hours.component.html',
  styleUrls: ['./store-trading-hours.component.scss']
})
export class StoreTradingHoursComponent implements OnInit {

  edit_trading: boolean = false;
  displayedColumns: string[] = ['day_name', 'opens_at', 'closes_at', 'edit'];
  id;
  edit_id;
  no_trading_hours: boolean = false;
  opens_at;
  closes_at;
  trading_hours = [];

  constructor( private api: ApiService,
               private route: ActivatedRoute,
               private message: MessagesService ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getTradingHours()
  }

  async getTradingHours(){
    // await this.ngOnInit()
    this.api.getList('fulfilment', `admin/stores/${this.id}/trading_hours`).subscribe((data:any) => {
      // console.log(data)
      this.trading_hours = [];
      data.data.forEach(trading_hour => {
        this.trading_hours.push(trading_hour)
      });
      // this.trading_hours = data.data
      // this.trading_hours.push(data.data)
      setTimeout(() => {
        if(data.total === 0){
          this.no_trading_hours = true;
        }
      }, 200);
    }, (error:any) => {
      this.message.showSnack(error.error.message)
      console.log(error)
    });
    // console.log(this.trading_hours)
  }

  createTradingHours(){
    this.api.getList('fulfilment', `admin/stores/${this.id}/generate_hours`).subscribe((data:any) => {
      this.getTradingHours()
      this.no_trading_hours = false;
      // console.log(data)
    })
  }

  resetTradingHours(){
    this.api.getList('fulfilment', `admin/stores/${this.id}/generate_hours`).subscribe((data:any) => {
      this.getTradingHours()
      this.message.showSnack('Hours have been reset')
      // console.log(data)
    },(error:any) => {
      console.log(error)
    })
  }

  deleteTradingHours(){
    this.api.delete('fulfilment', `admin/stores/${this.id}/remove_trading_hours`).subscribe((data:any) => {
      this.getTradingHours()
      this.message.showSnack('Hours have been deleted')
      // console.log(data)
    },(error:any) => {
      console.log(error)
    })
  }

  updateHours(){
    // console.log(data)
    if(this.edit_id != ''){
      this.edit_trading = false;
      let hours = {
        opens_at: this.opens_at,
        closes_at: this.closes_at
      }

      this.api.patch('fulfilment', `admin/store_trading_hours/${this.edit_id}`, hours).subscribe((hours: any) => {
        // console.log(hours)
        this.message.showSnack('Successfully updated trading hours for day');
        setTimeout(() => {
          this.getTradingHours();
        }, 1000);
      }, error => {
        this.message.showSnack(error.statusText)
      });
    }

  }

  edit(data){
     // console.log(data)
    this.edit_trading = true;
    this.edit_id = data.ID
    this.opens_at = data.opens_at
    this.closes_at = data.closes_at
  }

  close(){
    // console.log(data)
   this.edit_trading = false;
 }


}
