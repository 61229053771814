import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-retailer-product-create',
  templateUrl: './retailer-product-create.component.html',
  styleUrls: ['./retailer-product-create.component.scss']
})
export class RetailerProductCreateComponent implements OnInit {

  id;
  page = 1;
  limit = 10;
  silo_id = 5
  product_id;
  imageFile: {resource: string, file: any, name: string};
  categories;

  selectedValue;

  constructor(public api: ApiService,
              public message: MessagesService,
              public route: ActivatedRoute,
              public router: Router) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getCategories();
  }

  getCategories(){
    this.api.getList('catelog', `app/categories?page=1&per_page=15`).subscribe((categories: any) => {
      console.log(categories);
      this.categories = categories;
    })
  }

  associateProduct(){
    return new Promise(resolve => {
      let product = [
        {
        product_id: this.product_id,
        retailer_id: JSON.parse(this.id)
      }
    ]
  
    this.api.post('catelog', `retailerproducts`, product).subscribe((product: any) => {
      console.log(product);
      this.message.showSnack('Succesfully added product to retailer');
    }, error => {
      console.log(error);
      this.message.showSnack(error.error);
    });
      resolve('success')
    });

  }

  async submitProduct(i){
    let product = [
      {
        name: i.name,
        description: i.description,
        price: JSON.parse(i.price),
        size: i.size,
        barcode: i.barcode,
        sku_code: i.sku,
        linked_key: JSON.stringify(Math.random()),
        category_id: i.category,
        rebate: JSON.parse(i.rebate),
        retailer_id: JSON.parse(this.id),
        image: 'https://images.unsplash.com/photo-1509440159596-0249088772ff?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8YnJlYWR8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
      }
    ]

    console.log(product)

    this.api.post('catelog', `products`, product).subscribe((data: any) => {
      this.product_id = data[0].id;
      this.message.showSnack('Successfully created a new Product');


      setTimeout(() => {
        this.associateProduct();
        
        let body: string = "{ \"resource\":\"products\", \"resource_id\":" + this.product_id + ",\"x_dim\":1, \"y_dim\":1}"

        var myHeaders = new Headers();

        myHeaders.append("Authorization", "Bearer " + this.api.token);

        var formdata = new FormData();

        formdata.append("file", this.imageFile.file, this.imageFile.name);
        formdata.append("body", "{ \"resource\":\"products\", \"resource_id\":"+ this.product_id +",\"x_dim\":1, \"y_dim\":1}");

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata
        };

      fetch("https://api.api-dev.quench.org.za/catalogue/v1/image", requestOptions)
        .then(response => response.text())
        .then(result =>
          setTimeout(() => {
            this.message.showSnack('Image Uploaded');
            this.router.navigate([`/retailers/${this.id}`]);
          }, 200))
        .catch(error => console.log('error', error));
          console.log(FormData)
        }, 2500);
      }, error => {
        console.log(error);
        this.message.showSnack(error.error)
      })
  }

  imagesPreview(event) {
    if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();

        reader.onload = (_event: any) => {
            this.imageFile = {
                resource: _event.target.result,
                file: event.srcElement.files[0],
                name: event.srcElement.files[0].name
            };
        };
        reader.readAsDataURL(event.target.files[0]);
    }
  }

}
