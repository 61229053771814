<div class="back action" routerLink="/posts">
  <mat-icon>arrow_back</mat-icon>
  <p>posts</p>
</div>

<div class="create-card"  >

  <div class="header">
    <h3>create new post</h3>
    <mat-icon routerLink="/posts">clear</mat-icon>
  </div>

    <!-- ---------------------- -->
    <!--  COLLECT PRODUCT INFO  --> 
    <!-- ---------------------- -->

  <div class="first-form"  *ngIf='!postInfo'>
    <form #post = 'ngForm' (ngSubmit)='submitPostInfo(post.value)'>

      <mat-form-field class='field' appearance='outline'>
        <mat-label>name</mat-label>
        <input type="text" matInput [(ngModel)]='title' placeholder="The greatest cheesecake" name='title'>
      </mat-form-field>

      <mat-form-field class='field' appearance='outline'>
        <mat-label>description</mat-label>
        <textarea type="text" matInput [(ngModel)]='body' placeholder="this is a description" name='body'></textarea>
      </mat-form-field>

      <mat-form-field class='silos-field' appearance='outline' *ngIf="!isDischemUser">
        <mat-label>silo</mat-label>
        <mat-select [(ngModel)]='silo_id' name="silo_id" class='select-panel'>
            <mat-option *ngFor="let silo of silos" [value]="silo.id" >
                {{silo.name}}
            </mat-option>
        </mat-select>
      </mat-form-field> 

      <div class="btn">
        <button mat-flat-button class='button' type='submit' disabled="{{title && body ? false : true}}">next</button>     
      
      </div>

    </form>
  </div>

  <div class="second-form image-form" [style.left]="postInfo  ? '0' : '900px' " *ngIf='postInfo && !mobileImageSubmit'>
    <mat-icon class='action arrow' (click)='backToPostInfo()'>arrow_back</mat-icon>
    <h3>Almost there! Please upload a mobile image for this post</h3>
    <div class='image'>
      <img class="mobile-image" src='{{mobileImage}}'>
      <br>
      <button class="custom-file-upload" mat-button type="button" onclick="document.querySelector('#exampleInputFile').click()">Upload Image</button>
      <input type="file" id="exampleInputFile" (change)="mobileImagePreview($event)" style="display: none;">
    </div>

    <div class="btn product-info-button" *ngIf="isDischemUser">
      <button mat-flat-button class='button' (click)='submitPost()' >
        <span *ngIf='!pulse'>save</span>
        <ngx-spinner
        type = 'ball-pulse'
        [fullScreen]='false'
        bdColor=''
        color='var(--quenchPink)'
        size='small'
        ></ngx-spinner>
      </button>
    </div>

    <div class="btn product-info-button" *ngIf="!isDischemUser">
      <button mat-flat-button class='button' (click)='submitMobileImage()' disabled="{{mobileAttempt ? false : true}}">next</button>
    </div>
  </div>

  <div class="third-form image-form" *ngIf='mobileImageSubmit && postInfo'>
    <mat-icon class='action arrow' (click)='submitMobileImage()'>arrow_back</mat-icon>
    <h3>Almost there! Please upload a desktop image for this post</h3>
    <div class='image'>
      <img class="desktop-image" src='{{desktopImage}}'>
      <br>
      <button class="custom-file-upload" mat-button type="button" onclick="document.querySelector('#exampleInputFile').click()">Upload Image</button>
      <input type="file" id="exampleInputFile" (change)="desktopImagePreview($event)" style="display: none;">
    </div>

    <div class="btn product-info-button">
      <button mat-flat-button class='button' (click)='submitPost()' disabled="{{desktopAttempt ? false : true}}">
        <span *ngIf='!pulse'>save</span>
        <ngx-spinner
        type = 'ball-pulse'
        [fullScreen]='false'
        bdColor=''
        color='var(--quenchPink)'
        size='small'
        ></ngx-spinner>
      </button>
    </div>
  </div>




</div>
