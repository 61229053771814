import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountsService } from 'src/app/services/accounts/accounts.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {

  Columns = [
    'user_id',
    'name',
    'mobile',
    'email',
    'status',
    'verified',
    'is_fleet_driver',
    'fleet_id'
  ]
  constructor(public route: ActivatedRoute, public accounts: AccountsService) { }

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id')
    console.log("getUserAccountSummary: ",this.accounts.getUserAccountSummary(id))
     this.accounts.getUserAccountSummary(id)
  }

}
