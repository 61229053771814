import { Router } from '@angular/router';
import { MessagesService } from 'src/app/services/messages.service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';

import { MatIconRegistry } from '@angular/material/icon';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DriversService } from 'src/app/services/drivers/drivers.service';

@Component({
  selector: 'app-create-drivers',
  templateUrl: './create-drivers.component.html',
  styleUrls: ['./create-drivers.component.scss'],
})
export class CreateDriversComponent implements OnInit {
  constructor(
    public api: ApiService,
    public message: MessagesService,
    public router: Router,
    public drivers: DriversService
  ) {}

  ngOnInit(): void {
    this.drivers.listDriverCities();
  }

  bank;
  driver;
  payment;
  payment_type;
  preferred_location;
  preferred_location_city;

  other = false;
  isLinear = false;
  branch_code = '';
  driver_info = false;
  pay_to_mobile = true;
  is_fleet_driver = false;
  mobile_verified = false;

  // password expression
  regExps: { [key: string]: RegExp } = {
    password: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
    mobile: /^(?:(?:[1-9][0-9]*)|0)$/,
  };

  firstForm = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    mobile: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(this.regExps.mobile),
    ]),
    vehicle_type: new UntypedFormControl('', [Validators.required]),
    city: new UntypedFormControl('', [Validators.required]),
    loop_password: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(this.regExps.password),
    ]),
  });

  selectPreferredLocation(location) {
    this.preferred_location = location.city;
    this.preferred_location_city = location.city;
  }

  thirdForm = new UntypedFormGroup({});

  submitDriverInfo() {
    this.driver = { ...this.firstForm.value };
    this.driver.vehicle_type = this.type.name;

    this.driver.mobile = this.driver.mobile.replace(/^/, '+27');
    console.log(this.driver);
  }

  vehicle_types = [
    {
      icon: 'bolt',
      name: 'E-bike',
    },
    {
      icon: 'two_wheeler',
      name: 'Bike',
    },
    {
      icon: 'local_shipping',
      name: 'tuk-tuk',
    },
    {
      icon: 'directions_car',
      name: 'car',
    },
    {
      icon: 'local_shipping',
      name: 'Bakkie',
    },
  ];

  type = {
    icon: '',
    name: '',
  };

  pickDriverVehicleType(e) {
    this.type.icon = e.icon;
    this.type.name = e.name;
  }

  submitDriver() {
    // console.log(this.driver);
    this.api.post('fulfilment', `admin/drivers`, this.driver).subscribe(
      (data: any) => {
        // console.log(data);
        this.message.showSnack(
          `Succesfully added driver ${this.driver.first_name}  ${this.driver.last_name}`
        );
        this.router.navigate([`drivers`]);
      },
      (error) => {
        // console.log("error")
        this.message.showSnack(error.error.message);
      }
    );
  }
}
