<div class="add-card"  >
  
    <div class="header">
      <h3>add product to store</h3>
      <mat-icon (click)='closeDialog()'>clear</mat-icon>
    </div>

    <div *ngIf='!confirm'>

        <span >

            <div class="top">
                <div class='filter' >
                    <mat-form-field class='filter-field'>
                    <mat-label>Filter silo</mat-label>
                    <mat-select  [(ngModel)]="silo_id">
                        <mat-option (click)='setSilo(silo)' *ngFor="let silo of silos" [value]="silo.id">
                        {{silo.name}}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                </div>

                <div class="search" >
                    <mat-icon (click)='getProducts()' matTooltip='search' class='search-icon' [style.left]="inputTouch ? '-20px' : '10px' ">search </mat-icon>
                    <mat-icon *ngIf='search' (click)='clearSearch()'  matTooltip='clear' class='clear-icon'>clear</mat-icon>
                    <input matInput (keydown.enter)='getProducts()' [(ngModel)]="search" placeholder="Search"  #input (blur)="onBlur()" (focus)="onFocus()" [style.left]="inputTouch ? '10px' : '35px' ">
                </div>

            </div>

            <p *ngIf="store_amount == 'allstores' " class='all-stores-check'>Are you sure you want to add this product to all {{silo.name}} stores? </p>
            
            <table >
                <thead>
                    <tr >
                        <th ></th>
                        <th ></th>
                        <th ></th>
                    </tr>
                </thead>
                <tbody>
                <tr class='action' *ngFor="let product of products">
                    <th ><img src="{{product.image}}" alt=""></th>
                    <th ><p>{{product.name}}</p></th>
                    <th ><p><mat-icon (click)='confirmProduct(product)'>add_circle_outlined</mat-icon> </p></th>
                </tr>
                </tbody>
            </table>

        </span>

        <div id="pager" >
            <div class="page">         
        
            <div (click)='start()' matTooltip="Skip to start" class='action' *ngIf='page > 1'>back to start</div>
            <p (click)='previous()' class='action'>previous</p>
            <div>{{page}}</div>
              <p (click)='next()' class='action'>next</p>
              <div> </div>
            </div>
          </div>
    </div>

    <form #product = 'ngForm' (ngSubmit)='addProducts(product.value)' *ngIf='confirm' >

        <div class="back action" (click)='confirmProduct(product)'>
            <mat-icon>arrow_back</mat-icon>
            <p>back</p>
        </div>

        <h3 class='confirm'>Confirm product price and quantity</h3>

        <div class="center">
            <mat-form-field appearance="outline">
                <mat-label>quantity</mat-label>
                <input matInput placeholder="50" [(ngModel)]="qty" name='qty' type='number' required >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>promoPrice</mat-label>
                <input matInput placeholder="100" [(ngModel)]="price" name='price' type='number' required>
            </mat-form-field>
        </div>

        <div class="btn">
            <button mat-flat-button class='button confirm-bottom' type='submit'>Confirm</button>
        </div>
    </form>


</div>



  

