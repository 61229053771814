<div class="back action" routerLink="/reasons">
  <mat-icon>arrow_back</mat-icon>
  <p>reasons</p>
</div>

<div class="create-card">
  <div class="header">
    <h3>Create new Driver</h3>
    <mat-icon routerLink="/reasons">clear</mat-icon>
  </div>

  <form [formGroup]="firstForm">
    <mat-form-field appearance="outline" class="field">
      <mat-label>Reason</mat-label>
      <input
        type="text"
        matInput
        ngModel
        placeholder="redelivery"
        name="name"
        required
        formControlName="name"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="field">
      <mat-label>Description </mat-label>
      <input
        type="email"
        matInput
        ngModel
        placeholder="order was not correct"
        name="email"
        required
        formControlName="description"
      />
    </mat-form-field>
    <div class="btn ">
        <button mat-flat-button class='button' (click)='submitReason()' >submit</button>
      </div>
  </form>
</div>
